import './FeedNewsStory.scss';

import React from 'react';

import Card from "../../Card";
import NewsStory from "../../../types/NewsStory";
import Avatar from "../../Avatar";
import moment from "moment/moment";
import Icon from "../../Icon";
import buildImageSrc from "../../../utils/buildImageSrc";
import A from "../../A";
import NewsStoryTableMoreMenu from "../../../containers/Schools/School/NewsStories/NewsStoryTableMoreMenu";

type Props = {
    onEdit: () => void
    post: NewsStory
}

const FeedNewsStory: React.FC<Props> = ({
    onEdit,
    post,
}) => {

    const generateTimeStampForPost = (status: 'X' | 'A' | 'W') => {
        switch(status) {
            case "W":
                return (
                    <div className="c-feed-thread__date">
                        <div className="c-feed-thread__date-text">
                            <strong>News Story</strong> <br />
                            Drafted {moment(post.createdAt).format('ddd, MMM DD, YYYY [•] hh:mm A')}
                        </div>
                        <Icon type="clock" className="c-feed-thread__date-icon"/>
                    </div>
                );
            case "X":
                return (

                    <div className="c-feed-thread__date">
                        <div className="c-feed-thread__date-text">
                            <strong>News Story</strong> <br />
                            Scheduled {moment(post.publishAt).format('ddd, MMM DD, YYYY [•] hh:mm A')}
                        </div>
                        <Icon type="document-regular" className="c-feed-thread__date-icon"/>
                    </div>
                );
            case "A":
            default:
                return (
                    <div className="c-feed-thread__date">
                        <div className="c-feed-thread__date-text">
                            <strong>News Story</strong> <br />
                            Published {moment(post.publishedAt || post.createdAt).format('ddd, MMM DD, YYYY [•] hh:mm A')}
                        </div>
                    </div>
                );
        }
    };

    return (
        <Card className="c-feed-news-story c-feed-item">
            <div className="c-feed-thread__header">
                {post.profile && (
                    <Avatar artifact={post.profile.artifact}/>
                )}

                <div className="c-feed-thread__data c-feed-post__data">
                    {post.profile && (
                        <div
                            className="c-feed-thread__name"
                        >
                            {post.profile.firstName} {post.profile.lastName}
                        </div>
                    )}

                    <div className="c-feed-thread__date">
                        {generateTimeStampForPost(post.status)}
                    </div>
                </div>

                {onEdit && (
                    <NewsStoryTableMoreMenu
                        item={post}
                        setShowNewContentModal={onEdit}
                    />
                )}
            </div>

            <div className="c-feed-thread__content">
                <div className="c-feed-thread__title">
                    {post.title}
                </div>

                {post.artifacts && post.artifacts[0] && (
                    <>
                        {post.artifacts[0].type === 'image' && (
                            <img className="c-feed-thread__image" src={buildImageSrc(post.artifacts[0], 'original')}
                                 alt={post.title}/>
                        )}
                        {post.artifacts[0].type === 'video' && (
                            <video className="c-feed-thread__image" controls src={post.artifacts[0].videoMp4Url}/>
                        )}
                    </>
                )}

                <A href={post.linkUrl} target="_blank" className="c-feed-news-story__link">
                    Read Story
                    <Icon type="external-link" />
                </A>
            </div>
        </Card>
);
};

export default FeedNewsStory;
