import '../../ContentForms.scss'

import React, { useEffect, useState } from 'react';
import { validated } from "react-custom-validation";
import clone from "clone";

import GivingOpportunity from "../../../../types/GivingOpportunity";
import Validation from "../../../../types/Validation";

import { isRequired } from "../../../../utils/validations";
import { handleTextChange } from "../../../../utils/handle-changes";

import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from "../../../../store/reducers";
import { saveGivingOpportunity, deleteGivingOpportunity, setGivingOpportunity, clearGivingOpportunity, getGivingCategories } from "../../../../store/slices/giving";

import Button, { ButtonThemes, ButtonTypes } from "../../../../components/Button";
import ButtonRow from "../../../../components/ButtonRow";
import FileUpload from "../../../../components/FileUpload";
import Form from "../../../../components/Form";
import FormRow from "../../../../components/FormRow";
import H4 from "../../../../components/H4";
import Modal from "../../../../components/Modal";
import SuccessModal from "../../../../components/Modal/SuccessModal";
import Textbox from "../../../../components/Textbox";
import Dropdown from "../../../../components/Dropdown";

import DropdownOption from "../../../../types/DropdownOption";

type Props = {
    close?: Function
    givingOpportunity: GivingOpportunity
    $field: Function
    $fieldEvent: Function
    $validation: {
        name: Validation
    }
    $submit: Function
}

const CreateEditGivingOpportunityForm: React.FC<Props> = ({
   close,
   givingOpportunity,
   $field,
   $fieldEvent,
   $validation,
   $submit
}) => {
    const dispatch = useAppDispatch();
    const { givingCategories, isSavingGivingOpportunity, isDeletingGivingOpportunity } = useTypedSelector(store => store.giving);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([])
    const [isUploadingMainArtifact, setIsUploadingMainArtifact] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        return () => {
            setTimeout(() => dispatch(clearGivingOpportunity()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const options: DropdownOption[] = [{label: 'Select a category', value: ''}];
        givingCategories.forEach((cat) => {
            options.push({
                label: cat.name,
                value: cat.givingCategoryId,
            });
        });
        setCategoryOptions(options);
    }, [givingCategories]);

    const handleChange = (name, value) => {
        let clonedGivingOpportunity = clone(givingOpportunity);
        clonedGivingOpportunity[name] = value;
        dispatch(setGivingOpportunity(clonedGivingOpportunity));
    };

    const handleCategoryChange = (option: DropdownOption) => {
        handleChange('givingCategoryId', option.value);
    }

    const handleDelete = async () => {
        try {
            await dispatch(deleteGivingOpportunity({opportunityId: givingOpportunity.givingOpportunityId})).unwrap();
            $fieldEvent('reset');
            dispatch(getGivingCategories({}));
            close && close();
        } catch (err) {
            console.warn('CreateEditGivingOpportunityForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const onUploadStart = () => {
        setIsUploadingMainArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange('artifactId', artifactId);
        }
        setIsUploadingMainArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingMainArtifact(false);
    };

    const onDelete = () => {
        handleChange('artifactId', null);
    };

    const handleSave = async (isValid) => {
        if(isValid) {
            try {
                await dispatch(saveGivingOpportunity({givingOpportunity})).unwrap()
                setShowSuccessModal(true);
                setTimeout(() => {
                    dispatch(clearGivingOpportunity());
                    $fieldEvent('reset');
                })
            } catch(err) {
                console.warn('err from creating post', err);
            }
        }
    };

    return (
        <Form className="content-form">
            <H4>
                {givingOpportunity.givingOpportunityId ? 'Edit ' : 'Create a '}Fund
            </H4>

            <FormRow>
                <FileUpload
                    acceptsVideo={false}
                    currentArtifact={givingOpportunity.artifact || null}
                    onDelete={onDelete}
                    name="artifactId"
                    onStart={onUploadStart}
                    onSuccess={onUploadSuccess}
                    onFailure={onUploadFailure}
                />
            </FormRow>
            <FormRow>
                <Textbox
                    id="txtName"
                    label="Name"
                    name="name"
                    required
                    type="text"
                    validation={$validation.name}
                    value={givingOpportunity.name || ''}
                    {...$field('title', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingMainArtifact}
                    id="txtDescription"
                    label="Description"
                    name="description"
                    required
                    type="textarea"
                    value={givingOpportunity.description || ''}
                    {...$field('description', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Dropdown
                    name="category"
                    onChange={handleCategoryChange}
                    options={categoryOptions}
                    value={givingOpportunity.givingCategoryId}
                />
            </FormRow>




            <ButtonRow>
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                    showActivityIndicator={isSavingGivingOpportunity}
                    type={ButtonTypes.Submit}
                >
                    Save
                </Button>

                {givingOpportunity.givingOpportunityId && (
                    <Button
                        className="destructive"
                        onClick={() => setShowDeleteConfirmationModal(true)}
                        theme={ButtonThemes.Link}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getGivingCategories({}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
            >
                <p>
                    Your oppurtunity has been saved.
                </p>
                <p>
                    It may take a minute for your opportunity to show in feeds and lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingGivingOpportunity}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this opportunity?
            </Modal>

        </Form>
    );
}

function createEditGivingOpportunityFormValidationConfig(props) {
    const { name }: GivingOpportunity = props.givingOpportunity;

    return {
        fields: [ 'name' ],
        validations: {
            name: [
                [isRequired, name]
            ],
        }
    }
}
export default validated(createEditGivingOpportunityFormValidationConfig)(CreateEditGivingOpportunityForm);
