import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import Group from "../../types/Group";
import GroupsMetadata from "../../types/GroupsMetadata";
import IError from "../../types/IError";
import Metadata from "../../types/Metadata";
import Profile from "../../types/Profile";
import { RootState } from '../reducers';
import Thread from "../../types/Thread";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import { generateGroup, generateGroupMembersMetadata, generateGroupsMetadata } from "../../utils/generators";
import { buildErrorObject } from "../../utils/errors";
import { isArrayNullOrEmpty } from "../../utils/utils";
import { clonePostWithDeletedComment, clonePostWithNewComment } from "../../utils/reducerHelpers";

import { addError } from "./errors";
import { saveThreadComment } from './threads';

type ApproveOrRejectMemberRequestProps = {
    action: 'accept' | 'decline'
    forumTopicId?: number
    forumTopicProfileId: number
    schoolId?: number
}

export const approveOrRejectMemberRequest = createAsyncThunk(
    'groups/approveOrRejectMemberRequest',
    async ({action, forumTopicId, forumTopicProfileId, schoolId}: ApproveOrRejectMemberRequestProps, {dispatch, getState}) => {
        if(!forumTopicId) {
            forumTopicId = (getState() as RootState).groups.group.forumTopicId;
        }

        if(!schoolId) {
            schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        try {
            await new Request((getState() as RootState).auth.token).put(PATHS.groups.approveOrRejectMemberRequest(schoolId, forumTopicId, forumTopicProfileId, action));
            return { action, forumTopicProfileId };
        } catch(err) {
            console.log('approve or reject member request err', err);
            err.friendlyMessage = 'Error approving or rejecting this users request to join your group. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);


type DeleteGroupProps = {
    group?: Group
}

export const deleteGroup = createAsyncThunk(
    'groups/deleteGroup',
    async ({group}: DeleteGroupProps = {}, {dispatch, getState}) => {
        const {tenantId} = (getState() as RootState).schools.activeSchool;
        try {
            if(!group) {
                group = (getState() as RootState).groups.group;
            }
            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.groups.delete(tenantId, group.forumTopicId));
            console.log(res);
            return res;
        } catch(err) {
            console.log('delete group err', err);
            err.friendlyMessage = 'Error deleting the group. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetGroupProps = {
    forumTopicId?: number
    schoolId?: number
}

export const getGroup = createAsyncThunk(
    'groups/getGroup',
    async ({forumTopicId, schoolId}: GetGroupProps = {}, {dispatch, getState}) => {
        try {
            if(!forumTopicId) {
                forumTopicId = (getState() as RootState).groups.group.forumTopicId;
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.groups.getById(schoolId, forumTopicId));
            const group = res.data.data;
            return {group};
        } catch(err) {
            console.log('getGroup error', err);
            err.friendlyMessage = 'Error getting this group. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetGroupFeedProps = {
    forumTopicId?: number
    groupFeedMetadata?: Metadata
    isUpdate?: boolean
    schoolId?: number
}

export const getGroupFeed = createAsyncThunk(
    'groups/getGroupFeed',
    async ({forumTopicId, groupFeedMetadata, isUpdate, schoolId}: GetGroupFeedProps = {}, {dispatch, getState}) => {
        try {
            if(!forumTopicId) {
                forumTopicId = (getState() as RootState).groups.group.forumTopicId;
            }

            if(!groupFeedMetadata) {
                groupFeedMetadata = clone((getState() as RootState).groups.groupFeedMetadata);
            } else {
                groupFeedMetadata = {...groupFeedMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            groupFeedMetadata.forum_topic_id = forumTopicId;

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.groups.getThreads(schoolId, buildQueryString(groupFeedMetadata)));
            let groupFeed = res.data.data.items;
            groupFeedMetadata.total = res.data.data.meta.total;
            return {groupFeed, groupFeedMetadata};
        } catch(err) {
            console.log('getGroupFeed error', err);
            err.friendlyMessage = 'Error getting this groups feed. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetGroupMembersActiveProps = {
    forumTopicId?: number
    groupMembersActiveMetadata?: Metadata
    isUpdate?: boolean
    schoolId?: number
}

export const getGroupMembersActive = createAsyncThunk(
    'groups/getGroupMembersActive',
    async ({forumTopicId, groupMembersActiveMetadata, isUpdate, schoolId}: GetGroupMembersActiveProps = {}, {dispatch, getState}) => {
        try {
            if(!forumTopicId) {
                forumTopicId = (getState() as RootState).groups.group.forumTopicId;
            }

            if(!groupMembersActiveMetadata) {
                groupMembersActiveMetadata = clone((getState() as RootState).groups.groupMembersActiveMetadata);
            } else {
                groupMembersActiveMetadata = {...groupMembersActiveMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.groups.getMembersActive(schoolId, forumTopicId, buildQueryString(groupMembersActiveMetadata)));
            let groupMembersActive = res.data.data.items;
            groupMembersActiveMetadata.total = res.data.data.meta.total;
            return {groupMembersActive, groupMembersActiveMetadata};
        } catch(err) {
            console.log('getGroupMembersActive error', err);
            err.friendlyMessage = 'Error getting this groups members. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetGroupMembersRecentProps = {
    forumTopicId?: number
    groupMembersRecentMetadata?: Metadata
    isUpdate?: boolean
    schoolId?: number
}

export const getGroupMembersRecent = createAsyncThunk(
    'groups/getGroupMembersRecent',
    async ({forumTopicId, groupMembersRecentMetadata, isUpdate, schoolId}: GetGroupMembersRecentProps = {}, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!forumTopicId) {
                forumTopicId = (getState() as RootState).groups.group.forumTopicId;
            }

            if(!groupMembersRecentMetadata) {
                groupMembersRecentMetadata = clone((getState() as RootState).groups.groupMembersRecentMetadata);
            } else {
                groupMembersRecentMetadata = {...groupMembersRecentMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.groups.getMembersRecent(schoolId, forumTopicId, buildQueryString(groupMembersRecentMetadata)));
            let groupMembersRecent = res.data.data.items;
            groupMembersRecentMetadata.total = res.data.data.meta.total;
            return {groupMembersRecent, groupMembersRecentMetadata};
        } catch(err) {
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage: 'Error retrieving recently joined members.'
            });
            return rejectWithValue(errorObject);
        }
    }
);

type GetGroupMembersPendingInvitedProps = {
    forumTopicId?: number
    groupMembersPendingInvitedMetadata?: Metadata
    isUpdate?: boolean
    schoolId?: number
}

export const getGroupMembersPendingInvited = createAsyncThunk(
    'groups/getGroupMembersPendingInvited',
    async ({forumTopicId, groupMembersPendingInvitedMetadata, isUpdate, schoolId}: GetGroupMembersPendingInvitedProps = {}, {dispatch, getState}) => {
        try {
            if(!forumTopicId) {
                forumTopicId = (getState() as RootState).groups.group.forumTopicId;
            }

            if(!groupMembersPendingInvitedMetadata) {
                groupMembersPendingInvitedMetadata = clone((getState() as RootState).groups.groupMembersPendingInvitedMetadata);
            } else {
                groupMembersPendingInvitedMetadata = {...groupMembersPendingInvitedMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.groups.getMembersPendingInvited(schoolId, forumTopicId, buildQueryString(groupMembersPendingInvitedMetadata)));
            let groupMembersPendingInvited = res.data.data.items;
            groupMembersPendingInvitedMetadata.total = res.data.data.meta.total;
            return {groupMembersPendingInvited, groupMembersPendingInvitedMetadata};
        } catch(err) {
            console.log('GetGroupMembersPendingInvited error', err);
            err.friendlyMessage = 'Error getting this groups invited members. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetGroupMembersPendingRequestedProps = {
    forumTopicId?: number
    groupMembersPendingRequestedMetadata?: Metadata
    isUpdate?: boolean
    schoolId?: number
}

export const getGroupMembersPendingRequested = createAsyncThunk(
    'groups/GetGroupMembersPendingInvited',
    async ({forumTopicId, groupMembersPendingRequestedMetadata, isUpdate, schoolId}: GetGroupMembersPendingRequestedProps = {}, {dispatch, getState}) => {
        try {
            if(!forumTopicId) {
                forumTopicId = (getState() as RootState).groups.group.forumTopicId;
            }

            if(!groupMembersPendingRequestedMetadata) {
                groupMembersPendingRequestedMetadata = clone((getState() as RootState).groups.groupMembersPendingRequestedMetadata);
            } else {
                groupMembersPendingRequestedMetadata = {...groupMembersPendingRequestedMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.groups.getMembersPendingRequested(schoolId, forumTopicId, buildQueryString(groupMembersPendingRequestedMetadata)));
            let groupMembersPendingRequested = res.data.data.items;
            groupMembersPendingRequestedMetadata.total = res.data.data.meta.total;
            return {groupMembersPendingRequested, groupMembersPendingRequestedMetadata};
        } catch(err) {
            console.log('GetGroupMembersPendingRequested error', err);
            err.friendlyMessage = 'Error getting this groups requested members. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetGroupsProps = {
    isUpdate?: boolean
    schoolId?: number
    groupsMetadata?: GroupsMetadata
}

export const getGroups = createAsyncThunk(
    'groups/getGroups',
    async ({isUpdate, schoolId, groupsMetadata}: GetGroupsProps = {}, {dispatch, getState}) => {
        try {
            if(!groupsMetadata) {
                groupsMetadata = clone((getState() as RootState).groups.groupsMetadata);
            } else {
                groupsMetadata = {...groupsMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.groups.get(schoolId, buildQueryString(groupsMetadata)));
            let groups = res.data.data.items;
            groupsMetadata.total = res.data.data.meta.total;
            return {groups, groupsMetadata};
        } catch(err) {
            console.log('getGroups error', err);
            err.friendlyMessage = 'Error getting the list of groups. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

type InviteProfileProps = {
    forumTopicId?: number
    profileId: number
    schoolId?: number
}

export const inviteProfile = createAsyncThunk(
    'groups/inviteProfile',
    async ({forumTopicId, profileId, schoolId}: InviteProfileProps, {dispatch, getState}) => {
        if(!forumTopicId) {
            forumTopicId = (getState() as RootState).groups.group.forumTopicId;
        }

        if(!schoolId) {
            schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        try {
            const res = await new Request((getState() as RootState).auth.token).post(PATHS.groups.inviteProfileToGroup(schoolId, forumTopicId), {profileId});
            return res;
        } catch(err) {
            console.log('invite profile err', err);
            err.friendlyMessage = 'Error inviting that user to your group. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type InviteAllStudentsToGroupProps = {
    forumTopicId?: number
    schoolId?: number
}

export const inviteAllStudentsToGroup = createAsyncThunk(
    'groups/inviteAllStudentsToGroup',
    async ({forumTopicId, schoolId}: InviteAllStudentsToGroupProps, {dispatch, getState, rejectWithValue}) => {
        if(!forumTopicId) {
            forumTopicId = (getState() as RootState).groups.group.forumTopicId;
        }

        if(!schoolId) {
            schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        try {
            const res = await new Request((getState() as RootState).auth.token).post(PATHS.groups.inviteAllStudentsToGroup(schoolId, forumTopicId));
            return res;
        } catch(err) {
            let message = 'Error inviting users to your group. Please try again.';
            let friendlyMessage = 'Error inviting users to your group. Please try again.';
            console.log(err.response)
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            const myError = {message, friendlyMessage}
            return rejectWithValue(myError);
        }
    }
);

type InviteEmailListProps = {
    forumTopicId?: number
    emailCsv: string
    schoolId?: number
}

export const inviteEmailList = createAsyncThunk(
    'groups/inviteEmailList',
    async ({forumTopicId, emailCsv, schoolId}: InviteEmailListProps, {dispatch, getState, rejectWithValue}) => {
        if(!forumTopicId) {
            forumTopicId = (getState() as RootState).groups.group.forumTopicId;
        }

        if(!schoolId) {
            schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        // Remove spaces
        emailCsv = emailCsv.replace(/\s/g, '');

        // lowercase the whole thing
        emailCsv = emailCsv.toLowerCase();

        try {
            const res = await new Request((getState() as RootState).auth.token).post(PATHS.groups.inviteEmailListToGroup(schoolId, forumTopicId), {emails: emailCsv});
            return res;
        } catch(err) {
            let message = 'Error inviting users to your group. Please try again.';
            let friendlyMessage = 'Error inviting users to your group. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            const myError = {message, friendlyMessage}
            return rejectWithValue(myError);
        }
    }
);

type InviteStudentsToGroupProps = {
    forumTopicId?: number
    profileIds: Array<number>
    schoolId?: number
}

export const inviteStudentsToGroup = createAsyncThunk(
    'groups/inviteStudentsToGroup',
    async ({forumTopicId, profileIds, schoolId}: InviteStudentsToGroupProps, {dispatch, getState, rejectWithValue}) => {
        if(isArrayNullOrEmpty(profileIds)) {
            rejectWithValue({friendlyMessage: 'You must select students to invite.'});
        }

        if(!forumTopicId) {
            forumTopicId = (getState() as RootState).groups.group.forumTopicId;
        }

        if(!schoolId) {
            schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        console.log('profileIds', profileIds)

        try {
            const res = await new Request((getState() as RootState).auth.token).post(PATHS.groups.inviteStudentsToGroup(schoolId, forumTopicId), { profileIds });
            return res;
        } catch(err) {
            let message = 'Error inviting users to your group. Please try again.';
            let friendlyMessage = 'Error inviting users to your group. Please try again.';
            console.log(err.response)
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            const myError = {message, friendlyMessage}
            return rejectWithValue(myError);
        }
    }
);

type JoinGroupProps = {
    forumTopicId?: number
    schoolId?: number
}

export const joinGroup = createAsyncThunk(
    'groups/joinGroup',
    async ({forumTopicId, schoolId}: JoinGroupProps, {dispatch, getState}) => {
        if(!forumTopicId) {
            forumTopicId = (getState() as RootState).groups.group.forumTopicId;
        }

        if(!schoolId) {
            schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        try {
            let res = await new Request((getState() as RootState).auth.token).post(PATHS.groups.join(schoolId, forumTopicId));
            return res.data.data;
        } catch(err) {
            console.log('join group err', err);
            err.friendlyMessage = 'Error joining this group. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);


type LeaveGroupProps = {
    forumTopicId?: number
    schoolId?: number
}

export const leaveGroup = createAsyncThunk(
    'groups/leaveGroup',
    async ({forumTopicId, schoolId}: LeaveGroupProps, {dispatch, getState}) => {
        if(!forumTopicId) {
            forumTopicId = (getState() as RootState).groups.group.forumTopicId;
        }

        if(!schoolId) {
            schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        try {
            let res = await new Request((getState() as RootState).auth.token).delete(PATHS.groups.leave(schoolId, forumTopicId));
            return res.data.data;
        } catch(err) {
            console.log('leave group err', err);
            err.friendlyMessage = 'Error leaving this group. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);


type RemoveMemberProps = {
    forumTopicId?: number
    forumTopicProfileId: number
    schoolId?: number
}

export const removeMember = createAsyncThunk(
    'groups/removeMember',
    async ({forumTopicId, forumTopicProfileId, schoolId}: RemoveMemberProps, {dispatch, getState}) => {
        if(!forumTopicId) {
            forumTopicId = (getState() as RootState).groups.group.forumTopicId;
        }

        if(!schoolId) {
            schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        try {
            await new Request((getState() as RootState).auth.token).delete(PATHS.groups.removeMember(schoolId, forumTopicId, forumTopicProfileId));
            return { forumTopicProfileId };
        } catch(err) {
            console.log('remove member request err', err);
            err.friendlyMessage = 'Error removing that member. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type SaveGroupProps = {
    group?: Group
    shouldPersistGroup?: boolean
}
export const saveGroup = createAsyncThunk(
    'groups/saveGroup',
    async({ group, shouldPersistGroup }: SaveGroupProps, {dispatch, getState}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId } } } = (getState() as RootState);

        if(!group) {
            group = (getState() as RootState).groups.group;
        }

        let path = PATHS.groups.create(tenantId);
        let request = new Request(token);
        let reqFunc = request.post;

        if(group.forumTopicId) {
            path = PATHS.groups.update(tenantId, group.forumTopicId);
            reqFunc = request.put;
        } else {
            group = {
                ...group,
                tenantId
            }
        }

        try {
            await reqFunc(path, group);
            return { group, shouldPersistGroup };
        } catch(err) {
            console.log('saveGroup error', err);
            err.friendlyMessage = 'Error saving the group. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

interface GroupsState {
    approveOrRejectMemberRequestError: IError
    deleteGroupError: IError
    getGroupError: IError
    getGroupFeedError: IError
    getGroupMembersActiveError: IError
    getGroupMembersRecentError: IError
    getGroupMembersPendingInvitedError: IError
    getGroupMembersPendingRequestedError: IError
    getGroupsError: IError
    group: Group
    groupFeed: Array<Thread>
    groupMembersActive: Array<Profile>
    groupMembersRecent: Array<Profile>
    groupMembersPendingInvited: Array<Profile>
    groupMembersPendingRequested: Array<Profile>
    groups: Array<Group>
    groupFeedMetadata: Metadata
    groupMembersActiveMetadata: Metadata
    groupMembersRecentMetadata: Metadata
    groupMembersPendingInvitedMetadata: Metadata
    groupMembersPendingRequestedMetadata: Metadata
    groupsMetadata: GroupsMetadata
    inviteAllStudentsToGroupError: IError
    inviteEmailListCsv: string,
    inviteEmailListError: IError,
    inviteProfileError: IError
    inviteStudentsError: IError
    isApprovingOrRejectingMemberRequest: boolean
    isDeletingGroup: boolean
    isGettingGroup: boolean
    isGettingGroupFeed: boolean
    isGettingGroupMembersActive: boolean
    isGettingGroupMembersRecent: boolean
    isGettingGroupMembersPendingInvited: boolean
    isGettingGroupMembersPendingRequested: boolean
    isGettingGroups: boolean
    isInvitingAllStudentsToGroup: boolean
    isInvitingEmailList: boolean,
    isInvitingProfile: boolean
    isInvitingStudents: boolean
    isJoiningGroup: boolean
    isLeavingGroup: boolean
    isRemovingMember: boolean
    isSavingGroup: boolean
    joinGroupError: IError
    leaveGroupError: IError
    removeMemberError: IError
    saveGroupError: IError
    searchTerm: string,
}

const initialState: GroupsState = {
    group: generateGroup(),
    groupFeed: [],
    groupFeedMetadata: {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: ''
    },
    groupMembersActive: [],
    groupMembersActiveMetadata: generateGroupMembersMetadata(),
    groupMembersRecent: [],
    groupMembersRecentMetadata: {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'created_at',
        search: '',
    },
    groupMembersPendingInvited: [],
    groupMembersPendingInvitedMetadata: {
        page_size: 100,
        page_num: 0,
        order: 'desc',
        sort: 'created_at',
        search: '',
    },
    groupMembersPendingRequested: [],
    groupMembersPendingRequestedMetadata: {
        page_size: 100,
        page_num: 0,
        order: 'desc',
        sort: 'created_at',
        search: '',
    },
    groups: [],
    groupsMetadata: generateGroupsMetadata(),
    inviteEmailListCsv: '',
    searchTerm: '',
    isApprovingOrRejectingMemberRequest: false,
    isDeletingGroup: false,
    isGettingGroup: false,
    isGettingGroupFeed: false,
    isGettingGroupMembersActive: false,
    isGettingGroupMembersRecent: false,
    isGettingGroupMembersPendingInvited: false,
    isGettingGroupMembersPendingRequested: false,
    isGettingGroups: false,
    isInvitingAllStudentsToGroup: false,
    isInvitingEmailList: false,
    isInvitingProfile: false,
    isInvitingStudents: false,
    isJoiningGroup: false,
    isLeavingGroup: false,
    isRemovingMember: false,
    isSavingGroup: false,
    approveOrRejectMemberRequestError: undefined,
    deleteGroupError: undefined,
    getGroupError: undefined,
    getGroupFeedError: undefined,
    getGroupMembersActiveError: undefined,
    getGroupMembersRecentError: undefined,
    getGroupMembersPendingInvitedError: undefined,
    getGroupMembersPendingRequestedError: undefined,
    getGroupsError: undefined,
    inviteAllStudentsToGroupError: undefined,
    inviteEmailListError: undefined,
    inviteProfileError: undefined,
    inviteStudentsError: undefined,
    joinGroupError: undefined,
    leaveGroupError: undefined,
    removeMemberError: undefined,
    saveGroupError: undefined,
};

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        clearGroup: (state) => {
            state.group = generateGroup();
            state.groupFeed = [];
            state.groupMembersActive = [];
            state.groupMembersActiveMetadata = generateGroupMembersMetadata();
            state.groupMembersPendingInvited = [];
            state.groupMembersPendingRequested = [];
        },
        clearGroupsMetadata: (state) => {
            state.groupsMetadata = generateGroupsMetadata();
            state.searchTerm = '';
        },
        clearInviteAllStudentsToGroupError: (state) => {
            state.inviteAllStudentsToGroupError = undefined;
        },
        setGroup: (state, action) => {
            state.group = action.payload;
        },
        setGroupsMetadata: (state, action) => {
            state.groupsMetadata = action.payload;
        },
        setInviteEmailListCsv: (state, action) => {
            state.inviteEmailListCsv = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        updateGroupFeed: (state, action) => {
            const feed = clone(state.groupFeed);
            feed.unshift(action.payload);
            state.groupFeed = feed;
        },
        addCommentToGroupFeed: (state, action) => {
            let clonedFeed = clone(state.groupFeed);
            if (clonedFeed.length === 0) {
                return;
            }
            let matchedIndex = clonedFeed.findIndex(i => i.postId === action.payload.threadId);
            const newPost = clonePostWithNewComment(clonedFeed[matchedIndex], action.payload.parentPostCommentId, action.payload);
            clonedFeed[matchedIndex] = newPost;
            state.groupFeed = clonedFeed;
        },
        deleteCommentInGroupFeed: (state, action) => {
            let clonedFeed = clone(state.groupFeed);
            if (clonedFeed.length === 0) {
                return;
            }
            let matchedIndex = clonedFeed.findIndex(i => i.postId === action.payload.threadId);
            const newPost = clonePostWithDeletedComment(clonedFeed[matchedIndex], action.payload.postCommentId);
            clonedFeed[matchedIndex] = newPost;
            state.groupFeed = clonedFeed;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(approveOrRejectMemberRequest.pending, (state) => {
            state.approveOrRejectMemberRequestError = undefined;
            state.isApprovingOrRejectingMemberRequest = true;
        });
        addCase(approveOrRejectMemberRequest.fulfilled, (state, action) => {
            state.isDeletingGroup = false;

            let pendingMembers = clone(state.groupMembersPendingRequested);
            let group = clone(state.group);
            if(action.payload.action === 'accept') {
                group.memberCount = group.memberCount + 1;
            }
            group.requestedCount = group.requestedCount - 1;
            state.group = group;

            pendingMembers = pendingMembers.filter((m) => m.forumTopicProfileId !== action.payload.forumTopicProfileId);
            state.groupMembersPendingRequested = pendingMembers;
            state.isApprovingOrRejectingMemberRequest = false;
        });
        addCase(approveOrRejectMemberRequest.rejected, (state, action) => {
            state.approveOrRejectMemberRequestError = action.error as IError;
            state.isApprovingOrRejectingMemberRequest = false;
        });

        addCase(deleteGroup.pending, (state) => {
            state.deleteGroupError = undefined;
            state.isDeletingGroup = true;
        });
        addCase(deleteGroup.fulfilled, (state, action) => {
            state.isDeletingGroup = false;
            state.group = generateGroup();
        });
        addCase(deleteGroup.rejected, (state, action) => {
            state.deleteGroupError = action.error as IError;
            state.isDeletingGroup = false;
        });

        addCase(getGroup.pending, (state, action) => {
            state.getGroupError = undefined;
            state.isGettingGroup = true;
        });
        addCase(getGroup.fulfilled, (state, action) => {
            state.group = action.payload.group;
            state.isGettingGroup = false;
        });
        addCase(getGroup.rejected, (state, action) => {
            state.getGroupError = action.error;
            state.isGettingGroup = false;
        });

        addCase(getGroupFeed.pending, (state, action) => {
            state.getGroupFeedError = undefined;
            state.isGettingGroupFeed = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.groupFeedMetadata) {
                state.groupFeedMetadata = action.meta.arg.groupFeedMetadata;
            }
        });
        addCase(getGroupFeed.fulfilled, (state, action) => {
            state.groupFeed = action.payload.groupFeed;
            state.groupFeedMetadata = action.payload.groupFeedMetadata;
            state.isGettingGroupFeed = false;
        });
        addCase(getGroupFeed.rejected, (state, action) => {
            state.getGroupFeedError = action.error;
            state.isGettingGroupFeed = false;
        });

        addCase(getGroupMembersActive.pending, (state, action) => {
            state.getGroupMembersActiveError = undefined;
            state.isGettingGroupMembersActive = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.groupMembersActiveMetadata) {
                state.groupMembersActiveMetadata = action.meta.arg.groupMembersActiveMetadata;
            }
        });
        addCase(getGroupMembersActive.fulfilled, (state, action) => {
            state.groupMembersActive = action.payload.groupMembersActive;
            state.groupMembersActiveMetadata = action.payload.groupMembersActiveMetadata;
            state.isGettingGroupMembersActive = false;
        });
        addCase(getGroupMembersActive.rejected, (state, action) => {
            state.getGroupMembersActiveError = action.error;
            state.isGettingGroupMembersActive = false;
        });

        addCase(getGroupMembersRecent.pending, (state, action) => {
            state.getGroupMembersRecentError = undefined;
            state.isGettingGroupMembersRecent = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.groupMembersRecentMetadata) {
                state.groupMembersRecentMetadata = action.meta.arg.groupMembersRecentMetadata;
            }
        });
        addCase(getGroupMembersRecent.fulfilled, (state, action) => {
            state.groupMembersRecent = action.payload.groupMembersRecent;
            state.groupMembersRecentMetadata = action.payload.groupMembersRecentMetadata;
            state.isGettingGroupMembersRecent = false;
        });
        addCase(getGroupMembersRecent.rejected, (state, action) => {
            state.getGroupMembersRecentError = action.payload as IError;
            state.isGettingGroupMembersRecent = false;
        });

        addCase(getGroupMembersPendingInvited.pending, (state, action) => {
            state.getGroupMembersPendingInvitedError = undefined;
            state.isGettingGroupMembersPendingInvited = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.groupMembersPendingInvitedMetadata) {
                state.groupMembersActiveMetadata = action.meta.arg.groupMembersPendingInvitedMetadata;
            }
        });
        addCase(getGroupMembersPendingInvited.fulfilled, (state, action) => {
            state.groupMembersPendingInvited = action.payload.groupMembersPendingInvited;
            state.groupMembersPendingInvitedMetadata = action.payload.groupMembersPendingInvitedMetadata;
            state.isGettingGroupMembersPendingInvited = false;
        });
        addCase(getGroupMembersPendingInvited.rejected, (state, action) => {
            state.getGroupMembersPendingInvitedError = action.error;
            state.isGettingGroupMembersPendingInvited = false;
        });

        addCase(getGroupMembersPendingRequested.pending, (state, action) => {
            state.getGroupMembersPendingRequestedError = undefined;
            state.isGettingGroupMembersPendingRequested = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.groupMembersPendingRequestedMetadata) {
                state.groupMembersPendingRequestedMetadata = action.meta.arg.groupMembersPendingRequestedMetadata;
            }
        });
        addCase(getGroupMembersPendingRequested.fulfilled, (state, action) => {
            state.groupMembersPendingRequested = action.payload.groupMembersPendingRequested;
            state.groupMembersPendingRequestedMetadata = action.payload.groupMembersPendingRequestedMetadata;
            state.isGettingGroupMembersPendingRequested = false;
        });
        addCase(getGroupMembersPendingRequested.rejected, (state, action) => {
            state.getGroupMembersPendingRequestedError = action.error;
            state.isGettingGroupMembersPendingRequested = false;
        });

        addCase(getGroups.pending, (state, action) => {
            state.getGroupsError = undefined;
            state.isGettingGroups = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.groupsMetadata) {
                state.groupsMetadata = action.meta.arg.groupsMetadata;
            }
        });
        addCase(getGroups.fulfilled, (state, action) => {
            state.groups = action.payload.groups;
            state.groupsMetadata = action.payload.groupsMetadata;
            state.isGettingGroups = false;
        });
        addCase(getGroups.rejected, (state, action) => {
            state.getGroupsError = action.error;
            state.isGettingGroups = false;
        });

        addCase(inviteAllStudentsToGroup.pending, (state) => {
            state.inviteAllStudentsToGroupError = undefined;
            state.isInvitingAllStudentsToGroup = true;
        });
        addCase(inviteAllStudentsToGroup.fulfilled, (state, action) => {
            state.isInvitingAllStudentsToGroup = false;
        });
        addCase(inviteAllStudentsToGroup.rejected, (state, action) => {
            console.log(action);
            state.inviteAllStudentsToGroupError = action.payload as IError;
            state.isInvitingAllStudentsToGroup = false;
        });

        addCase(inviteEmailList.pending, (state) => {
            state.inviteEmailListError = undefined;
            state.isInvitingEmailList = true;
        });
        addCase(inviteEmailList.fulfilled, (state, action) => {
            state.isInvitingEmailList = false;
        });
        addCase(inviteEmailList.rejected, (state, action) => {
            state.inviteEmailListError = action.payload as IError;
            state.isInvitingEmailList = false;
        });

        addCase(inviteProfile.pending, (state) => {
            state.inviteProfileError = undefined;
            state.isInvitingProfile = true;
        });
        addCase(inviteProfile.fulfilled, (state, action) => {
            state.isInvitingProfile = false;
        });
        addCase(inviteProfile.rejected, (state, action) => {
            state.inviteProfileError = action.error as IError;
            state.isInvitingProfile = false;
        });

        addCase(inviteStudentsToGroup.pending, (state) => {
            state.inviteStudentsError = undefined;
            state.isInvitingStudents = true;
        });
        addCase(inviteStudentsToGroup.fulfilled, (state, action) => {
            state.isInvitingStudents = false;
        });
        addCase(inviteStudentsToGroup.rejected, (state, action) => {
            console.log(action);
            state.inviteStudentsError = action.payload as IError;
            state.isInvitingStudents = false;
        });

        addCase(joinGroup.pending, (state) => {
            state.joinGroupError = undefined;
            state.isJoiningGroup = true;
        });
        addCase(joinGroup.fulfilled, (state, action) => {
            state.group = action.payload;
            state.isJoiningGroup = false;

        });
        addCase(joinGroup.rejected, (state, action) => {
            state.joinGroupError = action.error as IError;
            state.isJoiningGroup = false;
        });

        addCase(leaveGroup.pending, (state) => {
            state.leaveGroupError = undefined;
            state.isLeavingGroup = true;
        });
        addCase(leaveGroup.fulfilled, (state, action) => {
            state.group = action.payload;
            state.isLeavingGroup = false;
        });
        addCase(leaveGroup.rejected, (state, action) => {
            state.leaveGroupError = action.error as IError;
            state.isLeavingGroup = false;
        });

        addCase(removeMember.pending, (state) => {
            state.removeMemberError = undefined;
            state.isRemovingMember = true;
        });
        addCase(removeMember.fulfilled, (state, action) => {

            let invitedMembers = clone(state.groupMembersPendingInvited);
            let members = clone(state.groupMembersActive);

            invitedMembers = invitedMembers.filter((m) => m.forumTopicProfileId !== action.payload.forumTopicProfileId);
            members = members.filter((m) => !m.forumTopicProfile || m.forumTopicProfile.forumTopicProfileId !== action.payload.forumTopicProfileId);

            state.groupMembersPendingInvited = invitedMembers;
            state.groupMembersActive = members;
            state.isRemovingMember = false;

        });
        addCase(removeMember.rejected, (state, action) => {
            state.removeMemberError = action.error as IError;
            state.isRemovingMember = false;
        });

        addCase(saveGroup.pending, (state) => {
            state.saveGroupError = undefined;
            state.isSavingGroup = true;
        });
        addCase(saveGroup.fulfilled, (state, action) => {
            state.isSavingGroup = false;
            state.group = action.payload.shouldPersistGroup ? action.payload.group : generateGroup();
        });
        addCase(saveGroup.rejected, (state, action) => {
            state.saveGroupError = action.error as IError;
            state.isSavingGroup = false;
        });

        addCase(saveThreadComment.fulfilled, (state, action) => {
            console.log('THREAD SAAVED HERE', action)
            let clonedFeed = clone(state.groupFeed);
            if (clonedFeed.length === 0) {
                return;
            }
            let matchedIndex = clonedFeed.findIndex(i => i.postId === action.payload.threadId);
            if(matchedIndex != null) {
                const newPost = clonePostWithNewComment(clonedFeed[matchedIndex], action.payload.parentPostCommentId, action.payload);
                clonedFeed[matchedIndex] = newPost;
            }
            state.groupFeed = clonedFeed;
        });
    }
});

export const { addCommentToGroupFeed, clearGroup, clearGroupsMetadata, clearInviteAllStudentsToGroupError, deleteCommentInGroupFeed, setSearchTerm, setGroup, setGroupsMetadata, setInviteEmailListCsv, updateGroupFeed } = groupsSlice.actions;

export default groupsSlice.reducer;
