import './GridProfilePromotion.scss';

import React from 'react';

import classNames from 'classnames';

import ProfilePromotion from '../../../types/ProfilePromotion';

import Avatar from '../../Avatar';
import Card from '../../Card';
import H6 from '../../H6';
import { ProfileTypes, PromotionStatuses } from '../../../utils/enums';

type Props = {
    item: ProfilePromotion
    moreMenuComponent?: Function
    onClick?: Function
}

const GridProfilePromotion: React.FC<Props> = ({
   item,
   moreMenuComponent,
   onClick,
}) => {
    const classes = classNames('c-grid-profile-promotion c-grid-list__item',);
    const renderProfileType = (profileType: ProfileTypes) => profileType.charAt(0).toUpperCase() + profileType.slice(1);
    const renderPromotionStatus = (promotionStatus: PromotionStatuses) => promotionStatus.split('_').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');

    return (
        <Card
            className={classes}
            onClick={() => onClick && onClick(item)}
        >
            <Avatar
                artifact={item.profile.artifact}
            />

            <div className="c-grid-profile-promotion__content c-grid-list__item__content">
                <H6 className="c-grid-profile-promotion__title c-grid-list__item__title">
                    {item.profile.firstName} {item.profile.lastName}
                </H6>

                {item.currentType && (
                    <div className="c-grid-profile-promotion__title c-grid-list__item__description">
                        Current type: {renderProfileType(item.currentType)}
                    </div>
                )}

                {item.updatedType && (
                    <div className="c-grid-profile-promotion__title c-grid-list__item__description">
                        Requested type: {renderProfileType(item.updatedType)}
                    </div>
                )}

                {item.promotionStatus && (
                    <div className="c-grid-profile-promotion__title c-grid-list__item__description">
                        Status: {renderPromotionStatus(item.promotionStatus)}
                    </div>
                )}

            </div>

            {moreMenuComponent && moreMenuComponent(item)}
        </Card>
    );
};

export default GridProfilePromotion;
