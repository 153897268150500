import ClassNote from '../types/ClassNote';
import Deal from "../types/Deal";
import EventPost from "../types/EventPost";
import IFile from "../types/IFile";
import MarketplaceItem from "../types/MarketplaceItem";
import Thread from "../types/Thread";
import NewsStory from '../types/NewsStory';

export const resizeImage = async (file: IFile, maxSize: number = 800, mimetype: string = 'image/jpeg') => {
    return new Promise((resolve, reject) => {
        try {
            let reader = new FileReader();
            reader.onload = function (readerEvent) {
                let image = new Image();
                image.onload = function (imageEvent) {
                    // Resize the image
                    let canvas = document.createElement('canvas'),
                        mime = mimetype,
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > maxSize) {
                            height *= maxSize / width;
                            width = maxSize;
                        }
                    } else {
                        if (height > maxSize) {
                            width *= maxSize / height;
                            height = maxSize;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    canvas.toBlob((blob) => {
                        let filename = file.name ? file.name : `image.jpg`;
                        const extension = filename.split('.').pop();
                        if (extension === 'webp') {
                            filename += '.jpg';
                        }
                        const resizedImage = new File([blob], filename, {
                            type: mime,
                            lastModified: Date.now()
                        }); //output image as a file
                        resolve(resizedImage);
                    }, mime, .6);
                };
                image.src = readerEvent.target.result as string;
            };
            reader.readAsDataURL(file);
        } catch(err) {
            reject(err);
        }
    });
};

export const getImageDimensions = async (file: IFile) => {
    return new Promise((resolve, reject) => {
        try {
            let img = document.createElement('img');
            let blob = URL.createObjectURL(file);
            img.src = blob;
            img.onload = () => {
                console.log(img.height, img.width);
                file.height = img.height;
                file.width = img.width;
                resolve(file);
            };
        } catch(err) {
            reject(err);
        }
    });
}

export const buildArtifactIdsArray = (post: ClassNote | Deal | EventPost | MarketplaceItem | Thread | NewsStory) => {
    let artifactIds = post.artifacts?.map((a) => a.artifactId);
    if((!artifactIds || artifactIds.length === 0) && post.artifact) {
        artifactIds = [post.artifact.artifactId];
    }
    return artifactIds;
}
