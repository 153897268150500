import './MainNav.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from "react-router";

import Capability from "../../../types/Capability";

import { isObjectNullOrEmpty } from '../../../utils/utils';
import { CAPABILITIES, multipleCapabilitiesUserCan, ROLES, userCan } from '../../../utils/roles';
import { ProfileTypes } from "../../../utils/enums";

import { clearActiveSchool } from "../../../store/slices/schools";
import { useAppDispatch } from "../../../store";
import { useTypedSelector } from '../../../store/reducers';

import A from '../../A';
import Avatar from "../../Avatar";
import Badge from "../../Badge";
import Button, { ButtonThemes } from '../../Button';
import Icon from '../../Icon';
import GlobalProfileDropdownSelect from '../GlobalProfileDropdownSelect';
const version = require('../../../../package.json').version;

interface NavLink {
    activeTest?: Function
    badge?: number
    capability?: Capability
    capabilities?: Array<Capability>
    icon: string
    text: string
    to: string
}

const MainNav = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();
    const { pathname } = useLocation();

    const { profile, roles } = useTypedSelector((state) => state.auth);
    const { activeSchool } = useTypedSelector((state) => state.schools);
    const { moderationCount } = useTypedSelector((state) => state.moderation);
    const hasMultipleTenants = roles?.length > 1 || roles[0].type === 'super_admin';

    const [showSubmenu, setShowSubmenu] = useState(activeSchool?.tenantId != null);

    const LEFT_LINKS: Array<NavLink> = [
        {
            capability: CAPABILITIES.VIEW_ENGINEERING,
            icon: 'navigation-engineering',
            text: 'Engineering',
            to: '/engineering',
        }, {
            capability: CAPABILITIES.VIEW_SUPER_ADMIN,
            icon: 'navigation-dashboard',
            text: 'Dashboard',
            to: '/schools/all/dashboard',
        }, {
            capability: CAPABILITIES.VIEW_ACTIVITY_REPORT_GENERATOR,
            icon: 'document-add',
            text: 'Activity Report',
            to: '/report-generator'
        }/*, {
            capability: CAPABILITIES.VIEW_SUPER_ADMIN,
            icon: 'navigation-activity-feed',
            text: 'Activity Feed',
            to: '/schools/all/feed',
        }*/, {
            capabilities: [CAPABILITIES.VIEW_SUPER_ADMIN, CAPABILITIES.MULTIPLE_TENANTS],
            icon: 'navigation-tenants',
            text: 'Schools',
            to: '/schools/list'
        }, {
            capability: CAPABILITIES.VIEW_SUPER_ADMIN,
            icon: 'navigation-sponsorships',
            text: 'Sponsorships',
            to: '/schools/all/sponsorships',
        },{
            capabilities: [CAPABILITIES.VIEW_SUPER_ADMIN, CAPABILITIES.VIEW_GIVING],
            icon: 'navigation-giving',
            text: 'Giving',
            to: '/giving',
        }/*, {
            capability: CAPABILITIES.VIEW_SUPER_ADMIN,
            icon: '',
            text: 'Partners',
            to: '/schools/all/partners',
        }, {
            capability: CAPABILITIES.VIEW_SUPER_ADMIN,
            icon: '',
            text: 'Users',
            to: '/schools/all/admins',
        }, {
            capability: CAPABILITIES.VIEW_SUPER_ADMIN,
            icon: '',
            text: 'Analytics',
            to: '/schools/all/analytics',
        }, {
            capability: CAPABILITIES.VIEW_SUPER_ADMIN,
            icon: '',
            text: 'Push Notifications',
            to: '/schools/all/push-notifications',
        }, {
            capability: CAPABILITIES.VIEW_SUPER_ADMIN,
            icon: '',
            text: 'Alerts',
            to: '/schools/all/alerts',
        }*/
    ];

    const RIGHT_LINKS: Array<NavLink> = useMemo(
        () => {
            if(pathname.includes(`/${ProfileTypes.Student}/`) && activeSchool.studentActive) {
                return [{
                        capability: CAPABILITIES.VIEW_SCHOOL_DASHBOARD,
                        icon: 'navigation-dashboard',
                        text: 'Dashboard',
                        to: `/school/${activeSchool.tenantId}/student/dashboard`,
                    }, {
                        capability: CAPABILITIES.VIEW_SCHOOL_FEED,
                        icon: 'navigation-activity-feed',
                        text: 'Activity Feed',
                        to: `/school/${activeSchool.tenantId}/student/feed`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/notification'),
                        capability: CAPABILITIES.MANAGE_PUSH_NOTIFICATIONS,
                        icon: 'navigation-notifications',
                        text: 'Notifications',
                        to: `/school/${activeSchool.tenantId}/student/notifications`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/group') && !pathname.includes('/students'),
                        capability: CAPABILITIES.MANAGE_GROUPS,
                        icon: 'navigation-groups',
                        text: 'Groups',
                        to: `/school/${activeSchool.tenantId}/student/groups`,
                    }, {
                        capability: CAPABILITIES.MANAGE_AMBASSADORS,
                        icon: '',
                        text: 'Ambassadors',
                        to: `/school/${activeSchool.tenantId}/student/ambassadors`,
                    }/*, {
                        capability: CAPABILITIES.MANAGE_PARTNERS,
                        text: 'Partners',
                        to: `/school/${activeSchool.tenantId}/student/partners`,
                    }*/, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/discussions'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-discussions',
                        text: 'Discussions',
                        to: `/school/${activeSchool.tenantId}/student/discussions`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/deal'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-deals',
                        text: 'Deals',
                        to: `/school/${activeSchool.tenantId}/student/deals`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/event') && !pathname.includes('/students'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-events',
                        text: 'Events',
                        to: `/school/${activeSchool.tenantId}/student/events`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/marketplace'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-marketplace',
                        text: 'Marketplace',
                        to: `/school/${activeSchool.tenantId}/student/marketplace`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/news'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-news',
                        text: 'News Stories',
                        to: `/school/${activeSchool.tenantId}/student/news`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/faq'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-faq',
                        text: 'FAQ',
                        to: `/school/${activeSchool.tenantId}/student/faq`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/resources'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-resources',
                        text: 'Resources',
                        to: `/school/${activeSchool.tenantId}/student/resources`,
                    }, /*{
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/news'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-news',
                        text: 'News Stories',
                        to: `/school/${activeSchool.tenantId}/student/news`,
                    }, */{
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/landing-page'),
                        capability: CAPABILITIES.MANAGE_LANDING_PAGES,
                        icon: 'landing-page',
                        text: 'Landing Pages',
                        to: `/school/${activeSchool.tenantId}/student/landing-pages`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && (pathname.includes('/students') || pathname.includes('/imports')),
                        capability: CAPABILITIES.MANAGE_STUDENTS,
                        icon: 'navigation-users',
                        text: 'Students',
                        to: `/school/${activeSchool.tenantId}/student/students`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/messages'),
                        capability: CAPABILITIES.DM,
                        icon: 'navigation-dms',
                        text: 'Direct Messages',
                        to: `/school/${activeSchool.tenantId}/student/messages`,
                    }, {
                    capability: CAPABILITIES.VIEW_TENANT_REPORT_GENERATOR,
                    icon: 'document-add',
                    text: 'Activity Report',
                    to: `/school/${activeSchool.tenantId}/student/report-generator`,
                }
                ];
            } else if(pathname.includes(`/${ProfileTypes.Alumni}/`) && activeSchool.alumniActive) {
                return [
                    {
                        capability: CAPABILITIES.VIEW_SCHOOL_FEED,
                        icon: 'navigation-activity-feed',
                        text: 'Activity Feed',
                        to: `/school/${activeSchool.tenantId}/alumni/feed`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/giving'),
                        capability: CAPABILITIES.VIEW_SCHOOL_FEED,
                        icon: 'navigation-giving',
                        text: 'Giving',
                        to: `/school/${activeSchool.tenantId}/alumni/giving`,
                    },{
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/notification'),
                        capability: CAPABILITIES.MANAGE_PUSH_NOTIFICATIONS,
                        icon: 'navigation-notifications',
                        text: 'Notifications',
                        to: `/school/${activeSchool.tenantId}/alumni/notifications`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/classNotes'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-classnote',
                        text: 'Class Notes',
                        to: `/school/${activeSchool.tenantId}/alumni/classNotes`,
                    }/*,{
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/discussions'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-discussions',
                        text: 'Discussions',
                        to: `/school/${activeSchool.tenantId}/alumni/discussions`,
                    }*/, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/event'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-events',
                        text: 'Events',
                        to: `/school/${activeSchool.tenantId}/alumni/events`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/news'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-news',
                        text: 'News Stories',
                        to: `/school/${activeSchool.tenantId}/alumni/news`,
                    }/*, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/faq'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-faq',
                        text: 'FAQ',
                        to: `/school/${activeSchool.tenantId}/alumni/faq`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/resources'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-resources',
                        text: 'Resources',
                        to: `/school/${activeSchool.tenantId}/alumni/resources`,
                    }*/, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/alumni/list'),
                        capability: CAPABILITIES.MANAGE_STUDENTS,
                        icon: 'navigation-users',
                        text: 'Alumni',
                        to: `/school/${activeSchool.tenantId}/alumni/list`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/messages'),
                        capability: CAPABILITIES.DM,
                        icon: 'navigation-dms',
                        text: 'Direct Messages',
                        to: `/school/${activeSchool.tenantId}/alumni/messages`,
                    }
                ];
            } else if(pathname.includes(`/${ProfileTypes.Admit}/`) && activeSchool.admitActive) {
                return [
                    {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/dashboard'),
                        capability: CAPABILITIES.MANAGE_STUDENTS,
                        icon: 'navigation-dashboard',
                        text: 'Dashboard',
                        to: `/school/${activeSchool.tenantId}/admit/dashboard`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/notification'),
                        capability: CAPABILITIES.MANAGE_PUSH_NOTIFICATIONS,
                        icon: 'navigation-notifications',
                        text: 'Notifications',
                        to: `/school/${activeSchool.tenantId}/admit/notifications`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/event'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-events',
                        text: 'Events',
                        to: `/school/${activeSchool.tenantId}/admit/events`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/group') && !pathname.includes('/students'),
                        capability: CAPABILITIES.MANAGE_GROUPS,
                        icon: 'navigation-groups',
                        text: 'Groups',
                        to: `/school/${activeSchool.tenantId}/admit/groups`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/news'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-news',
                        text: 'News Stories',
                        to: `/school/${activeSchool.tenantId}/admit/news`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/faq'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-faq',
                        text: 'FAQ',
                        to: `/school/${activeSchool.tenantId}/admit/faq`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/resources'),
                        capability: CAPABILITIES.VIEW_CONTENT_TABLE,
                        icon: 'navigation-resources',
                        text: 'Resources',
                        to: `/school/${activeSchool.tenantId}/admit/resources`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/admit/list'),
                        capability: CAPABILITIES.MANAGE_STUDENTS,
                        icon: 'navigation-users',
                        text: 'Admits',
                        to: `/school/${activeSchool.tenantId}/admit/list`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/messages'),
                        capability: CAPABILITIES.DM,
                        icon: 'navigation-dms',
                        text: 'Direct Messages',
                        to: `/school/${activeSchool.tenantId}/admit/messages`,
                    }
                ]
            } else if (pathname.includes(`/${ProfileTypes.Admin}/`)) {
                return [
                    {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/admins'),
                        capability: CAPABILITIES.MANAGE_PEOPLE,
                        icon: 'navigation-admins',
                        text: 'Admins',
                        to: `/school/${activeSchool.tenantId}/admin/admins`,
                    },
                    {
                        badge: moderationCount,
                        capability: CAPABILITIES.MODERATE_CONTENT,
                        icon: 'flag',
                        text: 'Flagged Content',
                        to: `/school/${activeSchool.tenantId}/admin/flagged`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/promotion'),
                        capability: CAPABILITIES.MANAGE_STUDENTS,
                        icon: 'navigation-users',
                        text: 'Promotions',
                        to: `/school/${activeSchool.tenantId}/admin/promotion`,
                    }, {
                        activeTest: (pathname) => pathname.includes('/school/') && pathname.includes('/details'),
                        capability: CAPABILITIES.EDIT_SCHOOL_DETAILS,
                        icon: 'edit',
                        text: 'Edit School Details',
                        to: `/school/${activeSchool.tenantId}/admin/details`,
                    },
                ]
            }

            return [];
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [activeSchool, pathname]
    );

    useEffect(() => {
        setShowSubmenu(params?.schoolId != null && activeSchool.tenantId != null);
    }, [activeSchool.tenantId, params?.schoolId]);

    const handleBackPress = () => {
        //eventually navigate back to some parent screen
        history.push('/schools/list');

        setTimeout(() => dispatch(clearActiveSchool()), 500);
    };

    return (
        <nav className={`c-main-nav ${!hasMultipleTenants ?'a-main-nav--only-one-tenant' : ''} ${showSubmenu ? 'open-submenu' : ''}`}>
            {!isObjectNullOrEmpty(profile) && (
                <div className="c-main-nav__user">
                    <Avatar artifact={profile.artifact} />

                    <div className="c-main-nav__user-name">
                        {profile.firstName} {profile.lastName}
                    </div>

                    {profile.position && (
                        <div className="c-main-nav__user-title">
                            {profile.position}
                        </div>
                    )}

                    {userCan(CAPABILITIES.VIEW_SUPER_ADMIN) && (
                        <GlobalProfileDropdownSelect/>
                    )}
                </div>
            )}

            <div className="c-main-nav__inner">
                <div className="c-main-nav__left">
                    {LEFT_LINKS.map((link) => {
                        if((!link.capability && !link.capabilities) || userCan(link.capability) || multipleCapabilitiesUserCan(link.capabilities)) {
                            return (
                                <A
                                    className={(link.activeTest && link.activeTest(pathname)) || pathname === link.to ? 'a-link--active' : ''}
                                    key={link.to}
                                    onClick={() => document.body.classList.remove('open-menu')}
                                    to={link.to}
                                >
                                    {link.icon && (
                                        <Icon type={link.icon} />
                                    )}

                                    {link.text}
                                </A>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>

                {!isObjectNullOrEmpty(activeSchool) && (
                    <div className="c-main-nav__right">
                        {hasMultipleTenants && (
                            <Button
                                className="c-main-nav__back-button"
                                noStyles
                                onClick={handleBackPress}
                            >
                                <Icon type="arrow-left" /> Back
                            </Button>
                        )}

                        {RIGHT_LINKS.map((link: NavLink) => {
                            if(!link.capability || userCan(link.capability)) {
                                return (
                                    <A
                                        className={(link.activeTest && link.activeTest(pathname)) || pathname === link.to ? 'a-link--active' : ''}
                                        key={link.to}
                                        onClick={() => document.body.classList.remove('open-menu')}
                                        to={link.to}
                                    >
                                        {link.icon && (
                                            <Icon type={link.icon} />
                                        )}

                                        {link.badge != null && link.badge > 0 && (
                                            <Badge text={link.badge.toString()} />
                                        )}

                                        {link.text}
                                    </A>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                )}
            </div>

            <div className="c-main-nav__signout-button-container">
                <Button
                    className="c-main-nav__signout-button"
                    theme={ButtonThemes.Light}
                    to="/auth/logout"
                >
                    <Icon type="signout" /> Sign Out
                </Button>
            </div>

            <div className="c-main-nav__version-container">
                v {version}
            </div>
        </nav>
    );
};

export default MainNav;
