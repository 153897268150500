import './GridGroup.scss';

import React from 'react';
import classNames from "classnames";

import Group from "../../../types/Group";

import buildImageSrc from "../../../utils/buildImageSrc";

import Avatar from "../../Avatar";
import Card from "../../Card";
import FeedFlaggedBanner from "../../Feed/FeedFlaggedBanner";
import H6 from "../../H6";

type Props = {
    item: Group
    moreMenuComponent?: Function
    onClick?: Function
    onClickText?: string
}

const GridGroup: React.FC<Props> = ({
    item,
    moreMenuComponent,
    onClick,
}) => {
    const containerClasses = classNames(
        'c-grid-group c-grid-list__item',
        {
            'a-grid-list__item--flagged' : item.flagged,
            'a-grid-item__item--removed-by-mods': item.removedByMods,
        }
    );

    const imageClasses = classNames(
        'c-grid-thread__image-container c-grid-list__item__image-container',
        {
            'item--removed-by-mods': item.removedByMods,
            'item--super-approved': item.superApproval,
        }
    );

    return (
        <Card
            className={containerClasses}
            onClick={() => {
                if(!onClick) {
                    return null;
                }

                onClick(item)
            }}
        >
            <div className={imageClasses}>
                <div className="c-grid-group__image c-grid-list__item__image" style={item.artifact ? {backgroundImage: `url(${buildImageSrc(item.artifact, '667')})`} : {backgroundImage: 'url("/img/abuzz-group-placeholder.svg")'}} />

                <FeedFlaggedBanner item={item} />
            </div>

            <div className="c-grid-group__content c-grid-list__item__content">
                <H6 className="c-grid-group__title c-grid-list__item__title">
                    {item.name}
                </H6>

                <div className="c-grid-group__member-count c-grid-list__item__description">
                    {item.memberCount} member{item.memberCount !== 1 ? 's' : ''}
                </div>

                {item.ownerProfile && (
                    <div className="c-grid-group__profile c-grid-list__item__profile">
                        <Avatar artifact={item.ownerProfile.artifact} />

                        {item.ownerProfile.firstName} {item.ownerProfile.lastName}
                    </div>
                )}
            </div>

            {moreMenuComponent(item)}
        </Card>
    );
};

export default GridGroup;
