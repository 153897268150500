import './SystemHealth.scss';

import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import clone from 'clone';

import { HealthCheckStatus } from '../../../../types/HealthCheckStatus';
import SystemHealth from '../../../../types/SystemHealth';

import { getPaginatedSystemHealth } from '../../../../store/slices/engineering';
import { useTypedSelector } from '../../../../store/reducers';
import { useAppDispatch } from '../../../../store';

import ActivityIndicator from '../../../../components/ActivityIndicator';
import H4 from '../../../../components/H4';
import NewContentModal from '../../../../components/NewContentModal';
import SystemHealthServicesTable from '../SystemHealthServicesTable';
import Table from '../../../../components/Table';
import TableColumn from '../../../../types/TableColumn';
import Tooltip, { TooltipSide } from '../../../../components/Tooltip';

const SystemHealthTable: React.FC = () => {
    const dispatch = useAppDispatch();

    const {systemHealth, isGettingSystemHealth, systemHealthMetadata} = useTypedSelector(store => store.engineering);
    const [healthCheck, setHealthCheck] = useState<SystemHealth | null>(null);
    const [showSystemHealthServicesTable, setShowSystemHealthServicesTable] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getPaginatedSystemHealth({})).unwrap();
            }
            catch (err) {
                console.log('SystemHealthTable initialize err', err);
            }
        };
        initialize();
    }, []);

    const calculateServicesChecked = (services: any[]) => {
        let total = 0;

        services.forEach((service) => {
            total += service.dependencies.length; //count all dependencies
            total++; //count the entire service as well
        });

        return total;
    };

    const renderDateInPast = (date: Date) => {
        let dateString = '';
        const secondsAgo = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

        switch (true) {
            case secondsAgo < 60:
                dateString = 'less than a minute ago';
                break;
            case secondsAgo === 3600:
                dateString = '1 minute ago';
                break;
            case secondsAgo < 3600:
                dateString = `${Math.floor(secondsAgo / 60)} minutes ago`;
                break;
            case secondsAgo === 86400:
                dateString = '1 hour ago';
                break;
            case secondsAgo < 86400:
                dateString = `${Math.floor(secondsAgo / 3600)} hours ago`;
                break;
            case secondsAgo === 604800:
                dateString = '1 day ago';
                break;
            case secondsAgo < 604800:
                dateString = `${Math.floor(secondsAgo / 86400)} days ago`;
                break;
            default:
                dateString = 'Some time go';
        }

        return dateString;
    };

    const formatDate = (date: Date) => {
        const dateInPast = renderDateInPast(date);

        const timestamp = moment(date).format('MM/DD/YY - hh:mm:ss A');

        return timestamp + ' (' + dateInPast + ')';
    };

    const renderStatus = (services: Array<any>) => {
        let errors = 0;

        services.forEach((service) => {
            if (service.status !== HealthCheckStatus.OK) {
                errors++;
            }
            if (service.dependencies) {
                service.dependencies.forEach((dependency) => {
                    if (dependency.status !== HealthCheckStatus.OK) {
                        errors++;
                    }
                });
            }
        });
        return errors === 0 ? '🟢' : '🔴'
    };

    const columns = useMemo(
        () => {
            let columnsArray: Array<TableColumn> = [
                {
                    Header: 'Last Run',
                    accessor: (row) => formatDate(row.timestamp),
                }, {
                    Header: 'Services Checked',
                    accessor: (row) => calculateServicesChecked(row.services),
                }, {
                    Header: 'Status',
                    accessor: (row) => renderStatus(row.services),
                },
            ];

            return columnsArray;
        },
        [],
    );

    const onMetadataChange = async (changes, isUpdate) => {
        try {
            let clonedMetadata = clone(systemHealthMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes,
            };

            return await dispatch(getPaginatedSystemHealth({isUpdate, systemHealthMetadata: clonedMetadata}));
        } catch (err) {
            console.log('SqsMessagesTable onMetadataChange err', err);
        }
    };

    return isGettingSystemHealth ? <ActivityIndicator/> : (
        <>
            <div className="system_health__title-container">
                <H4>System Health Dashboard</H4>
                <div className="system-health__tooltip">
                    <Tooltip icon="info" side={TooltipSide.Right}>
                        <p>
                            This dashboard shows the status of all services and their dependencies. Click on a row to
                            view the details of that health check.
                        </p>
                    </Tooltip>
                </div>
            </div>
            <Table
                onRowClick={(arg0) => {
                    setHealthCheck(arg0.original);
                    setShowSystemHealthServicesTable(true);
                }}
                getData={onMetadataChange}
                columns={columns}
                data={systemHealth}
                pageNumber={systemHealthMetadata.page_num}
                pageSize={systemHealthMetadata.page_size}
                totalNumberOfItems={systemHealthMetadata.total}
            />

            <NewContentModal
                width="wide"
                show={showSystemHealthServicesTable}
                close={() => setShowSystemHealthServicesTable(false)}
                contentLabel="Health Check Details"
            >
                <SystemHealthServicesTable systemHealth={healthCheck}/>
            </NewContentModal>
        </>
    );
};


export default SystemHealthTable;
