import React, { useMemo } from 'react';

import EventsMetadata from "../../../types/EventsMetadata";
import Metadata from "../../../types/Metadata";

import TableFilterMenu from "../TableFilterMenu";
import TableFilterMenuOption from "../../../types/TableFilterMenuOption";
import { useTypedSelector } from "../../../store/reducers";
import { useRouteMatch } from "react-router";
import { ProfileTypes } from "../../../utils/enums";

type Props = {
    metadata?: EventsMetadata | Metadata
    onMetadataChange: (changes: any, isUpdate?: boolean) => void
}

const TableAuthorFilter: React.FC<Props> = ({
    metadata,
    onMetadataChange,
}) => {
    const { params } = useRouteMatch() as { params: { profileType: string } };

    const { userId: myUserId } = useTypedSelector((state) => state.auth.profile);

    const options: Array<TableFilterMenuOption> = useMemo(
        () => {
            return (
                [{
                    isActive: !metadata.admin_content_only && !metadata.student_content_only && !metadata.my_posts,
                    onSelect: () => {
                        onMetadataChange({admin_content_only: false, student_content_only: false, my_posts: false, created_by_user_id: null, page_num: 0}, false);
                    },
                    text: "Everyone",
                }, {
                    isActive: metadata.admin_content_only && !metadata.student_content_only && !metadata.my_posts,
                    onSelect: () => {
                        onMetadataChange({admin_content_only: true, student_content_only: false, student_visibility: params.profileType === ProfileTypes.Student, alumni_visibility: params.profileType === ProfileTypes.Alumni, my_posts: false, created_by_user_id: null, page_num: 0}, false);
                    },
                    text: "Admins Only",
                }, {
                    isActive: !metadata.admin_content_only && metadata.student_content_only && !metadata.my_posts,
                    onSelect: () => {
                        onMetadataChange({admin_content_only: false, student_content_only: true, student_visibility: params.profileType === ProfileTypes.Student, alumni_visibility: params.profileType === ProfileTypes.Alumni, my_posts: false, created_by_user_id: null, page_num: 0}, false);
                    },
                    text: `${params.profileType === ProfileTypes.Student ? 'Students' : 'Alumni'} Only`,
                }, {
                    isActive: !metadata.admin_content_only && !metadata.student_content_only && metadata.my_posts,
                    onSelect: () => {
                        onMetadataChange({admin_content_only: false, student_content_only: false, my_posts: true, created_by_user_id: myUserId, page_num: 0}, false);
                    },
                    text: "Me",
                }]
            );
        }, [metadata, myUserId, onMetadataChange]
    );

    return (
        <TableFilterMenu
            label="Author"
            options={options}
        />
    );
};

export default TableAuthorFilter;
