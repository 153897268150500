import './GridEvent.scss';

import React from 'react';
import moment from 'moment';
import classNames from "classnames";

import EventPost from "../../../types/EventPost";

import buildImageSrc from "../../../utils/buildImageSrc";

import Avatar from "../../Avatar";
import Card from "../../Card";
import CardVideo from "../../CardVideo";
import FeedFlaggedBanner from "../../Feed/FeedFlaggedBanner";
import FlaggedIcon from "../../Icon/FlaggedIcon";
import H6 from "../../H6";

type Props = {
    disablePending?: boolean
    item: EventPost
    moreMenuComponent?: Function
    onClick?: Function
}

const GridEvent: React.FC<Props> = ({
    disablePending,
    item,
    moreMenuComponent,
    onClick,
}) => {
    const isNotActive = item.status !== 'A' && disablePending;

    const containerClasses = classNames(
        'c-grid-event c-grid-list__item',
        {
            'a-grid-list__item--inactive': isNotActive,
            'a-grid-list__item--flagged' : item.flagged,
            'a-grid-item__item--removed-by-mods': item.removedByMods,
        }
    );

    const imageClasses = classNames(
        'c-grid-event__image-container c-grid-list__item__image-container',
        {
            'item--removed-by-mods': item.removedByMods,
            'item--super-approved': item.superApproval,
        }
    );

    const renderTimes = () => {
        let dateString = moment(item.startAt).format('MMM D, YYYY [•] h:mma');

        if(item.endAt) {
            const startAtDate = moment(item.startAt).format('MM/DD/YYYY');
            const endAtDate = moment(item.endAt).format('MM/DD/YYYY');

            if(startAtDate === endAtDate) {
                dateString += ` - ${moment(item.endAt).format('h:mma')}`;
            } else {
                dateString += ` - ${moment(item.endAt).format('MMM D, YYYY [•] h:mma')}`;
            }
        }

        return dateString;
    };

    return (
        <Card
            className={containerClasses}
            onClick={() => {
                if(isNotActive || !onClick) {
                    return null;
                }

                onClick(item);
            }}
        >
            <div className={imageClasses}>
                <div className="c-grid-list__item__image" style={item.artifacts && item.artifacts[0] ? {backgroundImage: `url(${buildImageSrc(item.artifacts[0], '667')})`} : null} >
                    {item.artifacts && item.artifacts[0] && item.artifacts[0].videoMp4Url && (
                        <CardVideo videoUrl={item.artifacts[0].videoMp4Url} />
                    )}
                </div>
                <FeedFlaggedBanner item={item} />
            </div>

            <div className="c-grid-event__content c-grid-list__item__content">
                <H6 className="c-grid-event__title c-grid-list__item__title">
                    {item.title}
                </H6>

                {item.startAt && (
                    <div className="c-grid-event__times c-grid-list__item__description">
                        {renderTimes()}
                    </div>
                )}

                {item.profile && (
                    <div className="c-grid-event__profile c-grid-list__item__profile">
                        <Avatar artifact={item.profile.artifact} />

                        {item.profile.firstName} {item.profile.lastName}
                    </div>
                )}
            </div>

            {item.flagged && (
                <FlaggedIcon className="c-grid-list__item__flagged" />
            )}

            {!item.removedByMods && moreMenuComponent(item)}
        </Card>
    );
};

export default GridEvent;
