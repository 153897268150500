import DropdownOption from "../types/DropdownOption";

export const handleCheckboxChange = (handleChangeFunc: Function, e: InputEvent, objectName?: string) => {
    return handleChangeFunc((e.target as HTMLInputElement).name, (e.target as HTMLInputElement).checked, objectName);
};

export const handleDateChange = (handleChangeFunc: Function, name: string, objectName?: string, date?: Date | string) => {
    return handleChangeFunc(name, date, objectName);
};

export const handleSelectChange = (handleChangeFunc: Function, name: string, objectName?: string, $fieldEvent?: Function, selectedValue?: DropdownOption) => {
    let newValue = null;
    if (selectedValue != null) {
        newValue = selectedValue.value;
    }

    handleChangeFunc(name, newValue, objectName);

    if ($fieldEvent != null) {
        $fieldEvent('change', name);
    }
};

export const handleTextChange = (handleChangeFunc: Function, e: Event, objectName?: string) => {
    return handleChangeFunc((e.target as HTMLInputElement).name, (e.target as HTMLInputElement).value, objectName);
};
