import './CircularProgressBar.scss';

import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import FormValidationMessage from "../FormValidationMessage";

type Props = {
    className?: string
    length: number
    limit: number
    postCountText?: string
    warningText?: JSX.Element | string
}

const GREEN = '#1DB954';
const RED = '#EC4A5E';

const CircularProgressBar: React.FC<Props> = ({
    className,
    length,
    limit,
    postCountText,
    warningText,
}) => {
    const [characterPercentage, setCharacterPercentage] = useState(0);
    const [progressColor, setProgressColor] = useState(null);

    useEffect(() => {
        if (limit) {
            let percent = length / limit * 100;
            if (percent >= 100) {
                percent = 100;
            }
            setCharacterPercentage(percent);

            let color = GREEN;
            if (percent > 75) {
                color = 'orange';
            }
            if (percent === 100) {
                color = RED;
            }
            setProgressColor(color)
        }
    }, [length, limit]);

    return (
        <div className={`o-circular-progress-bar ${className ? className : ''}`}>
            <div className="o-circular-progress-bar--inner">
                <div className="c-circular-progress-bar">
                    <CircularProgressbar
                        value={characterPercentage}
                        strokeWidth={15}
                        styles={buildStyles({
                            textColor: RED,
                            pathColor: progressColor
                        })}
                    />
                </div>

                <div className="o-circular-progress-bar__text">
                    <strong>{length}</strong> of <strong>{limit}</strong> {postCountText || ''}
                </div>
            </div>

            {warningText && limit < length && (
                <FormValidationMessage className="o-circular-progress-bar__error">
                    {warningText}
                </FormValidationMessage>
            )}
        </div>
    );
};

export default CircularProgressBar;
