import './ViewGroupMembersPendingRequested.scss';

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useRouteMatch } from "react-router";

import {
    approveOrRejectMemberRequest,
    getGroupMembersPendingRequested
} from "../../../../../../../store/slices/groups";
import { useAppDispatch } from "../../../../../../../store";
import { useTypedSelector } from "../../../../../../../store/reducers";

import ActivityIndicator from "../../../../../../../components/ActivityIndicator";
import Avatar from "../../../../../../../components/Avatar";
import Button, { ButtonSizes, ButtonThemes } from "../../../../../../../components/Button";

const ViewGroupMembersPendingRequested: React.FC = () => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState(false);
    const [action, setAction] = useState(null);
    const [memberToAcceptOrReject, setMemberToAcceptOrReject] = useState(null);

    const { groupMembersPendingRequested, isApprovingOrRejectingMemberRequest, isGettingGroupMembersPendingRequested } = useTypedSelector((state) => state.groups);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getGroupMembersPendingRequested({schoolId: params?.schoolId, forumTopicId: params?.forumTopicId})).unwrap();
            } catch(err) {
                console.log('ViewGroupMembersPendingRequested initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleApproveOrReject = async (member, action) => {
        try {
            setMemberToAcceptOrReject(member);
            setAction(action);
            await dispatch(approveOrRejectMemberRequest({
                forumTopicId: params.forumTopicId,
                forumTopicProfileId: member.forumTopicProfileId,
                schoolId: params.schoolId,
                action
            })).unwrap();
        } catch(err) {
            console.log('ViewGroupMembersPendingRequested handleApproveOrReject err', err);
        } finally {
            setMemberToAcceptOrReject(null);
            setAction(null);
        }
    };

    return (
        <div className="view-group-members-invited">
            {isGettingGroupMembersPendingRequested || !initialized ? (
                <ActivityIndicator />
            ) : (
                <div className="pending-members-list">
                    {groupMembersPendingRequested.map((member) => (
                        <div className="pending-members-list__row">
                            <div className="pending-members-list__row__user-data-container">
                                <Avatar artifact={member.profile?.artifact} />

                                <div className="pending-members-list__row__user-data">
                                    <div className="pending-members-list__row__name">
                                        {member.profile?.firstName} {member.profile?.lastName}
                                    </div>

                                    <div className="pending-members-list__row__description">
                                        {member.profile?.firstName} {member.profile?.lastName}
                                    </div>
                                </div>
                            </div>

                            <div className="pending-members-list__row__date">
                                {member.createdAt && moment(member.createdAt).format('[Requested] MMM D, YYYY')}
                            </div>

                            <div className="pending-members-list__row__actions">
                                <Button
                                    disabled={isApprovingOrRejectingMemberRequest}
                                    onClick={() => handleApproveOrReject(member, 'decline')}
                                    showActivityIndicator={isApprovingOrRejectingMemberRequest && action === 'decline' && memberToAcceptOrReject?.forumTopicProfileId === member.forumTopicProfileId}
                                    size={ButtonSizes.Small}
                                    theme={ButtonThemes.Secondary}
                                >
                                    Deny
                                </Button>

                                <Button
                                    disabled={isApprovingOrRejectingMemberRequest}
                                    onClick={() => handleApproveOrReject(member, 'accept')}
                                    showActivityIndicator={isApprovingOrRejectingMemberRequest && action === 'accept' && memberToAcceptOrReject?.forumTopicProfileId === member.forumTopicProfileId}
                                    size={ButtonSizes.Small}
                                    theme={ButtonThemes.Secondary}
                                >
                                    Approve
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ViewGroupMembersPendingRequested;
