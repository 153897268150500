import './FormError.scss';

import React from 'react';

import IError from "../../types/IError";

import { isObjectNullOrEmpty } from '../../utils/utils';

import FormRow from '../FormRow';
import FormValidationMessage from '../FormValidationMessage';

type Props = {
    error: IError
}

const FormError: React.FC<Props> = ({
    error
}) => {
    if(isObjectNullOrEmpty(error)) {
        return null;
    }

    return (
        <FormRow className="c-form-error">
            <FormValidationMessage>
                {error.friendlyMessage || error.message}
            </FormValidationMessage>
        </FormRow>
    );
};

export default FormError;
