import './FeedProfile.scss';

import React, { useMemo } from 'react';
import classNames from "classnames";
import clone from 'clone';

import {useHistory, useRouteMatch} from "react-router";

import Profile from "../../../types/Profile";

import { isArrayNullOrEmpty, isObjectNullOrEmpty, renderHometown } from "../../../utils/utils";
import { sanitizeDetails } from "../../../utils/elements";

import Avatar from "../../Avatar";
import Card from "../../Card";
import H6 from "../../H6";
import ParagraphsFromNewlines from "../../ParagraphsFromNewlines";
import StudentsTableMoreMenu from "../../../containers/Schools/School/SchoolStudents/ListStudents/StudentsTableMoreMenu";

type Props = {
    className?: string,
    profile: Profile
}

const FeedProfile: React.FC<Props> = ({
    className,
    profile,
}) => {
    const match = useRouteMatch();
    const history = useHistory();

    const classes = classNames(
        'c-feed-profile c-feed-item',
        className,
        {
            'a-feed-item--flagged': profile.flagged,
            'a-feed-item--removed-by-mods': profile.removedByMods,
            'a-feed-item--super-approved': profile.superApproval,
        }
    );

    const student = useMemo(
        () => {
            let clonedProfile = clone(profile);
            clonedProfile = sanitizeDetails(clonedProfile);
            return clonedProfile;
        }, [profile]
    );

    return (
        <Card className={classes}>
            <header className="c-feed-profile__header">
                <div role="link"  onClick={() => history.push(`/school/${match?.params?.schoolId}/students/${profile.profileId}/posts`)}>
                    <Avatar artifact={profile.artifact} />
                </div>

                <div className="c-feed-profile__header-content" role="link" onClick={() => history.push(`/school/${match?.params?.schoolId}/students/${profile.profileId}/posts`)}>
                    <H6>
                        {student.firstName} {student.lastName}
                    </H6>

                    <a href={`mailto:${student.emailAddress}`}>{student.emailAddress}</a>
                </div>

                {!student.removedByMods && (
                    <StudentsTableMoreMenu
                        item={student}
                    />
                )}
            </header>

            <div className="c-feed-profile__data">
                <div className="c-feed-profile__data-item">
                    <strong>Class Year</strong>

                    {isArrayNullOrEmpty(student.gradyear) || !student.gradyear[0] ? '--' : `Class of ${student.gradyear[0].displayText || student.gradyear[0].description}`}
                </div>

                <div className="c-feed-profile__data-item">
                    <strong>Hometown</strong>

                    {isObjectNullOrEmpty(student.location) ? '--' : renderHometown(student.location)}
                </div>

                <div className="c-feed-profile__data-item">
                    <strong>Major</strong>

                    {isArrayNullOrEmpty(student.major) ? '--' : student.major.map((m) => m.displayText || m.description).join(', ')}
                </div>

                <div className="c-feed-profile__data-item">
                    <strong>Minor</strong>

                    {isArrayNullOrEmpty(student.minor) ? '--' : student.minor[0].displayText || student.minor[0].description}
                </div>
            </div>

            {student.about && (
                <div className="c-feed-profile__bio">
                    <strong>Bio</strong>

                    <ParagraphsFromNewlines
                        className="c-feed-profile__bio-text"
                        linkify
                        text={student.about}
                    />
                </div>
            )}
        </Card>
    );
};

export default FeedProfile;
