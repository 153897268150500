import './FormValidationMessage.scss';

import React from 'react';

type Props = {
    children: React.ReactNode
    className?: string
}

const FormValidationMessage: React.FC<Props> = ({
    children,
    className = ''
}) => (
    <div className={`c-validation-message ${className}`}>
        {children}
    </div>
);

export default FormValidationMessage;
