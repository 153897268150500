import React from "react";

import moment from "moment";

import Avatar from "../../../../../../../components/Avatar";
import Card from "../../../../../../../components/Card";
import {DetailModalProps} from "../../index";
import FormRow from "../../../../../../../components/FormRow";
import FormColumn from "../../../../../../../components/FormColumn";
import H6 from "../../../../../../../components/H6";

const TopDealDetailModal: React.FC<DetailModalProps> = ({data}) => {
    const formatDate = (date: Date) => {
        return moment(date).format('MMMM DD, YYYY');
    };

    return (
        <>
            <Card>
                <div className='dashboard-card__detail-main-col'>
                    <H6>
                        {data.post.title}
                    </H6>

                    <Avatar artifact={data.post.artifacts[0]} />
                </div>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Published
                        </div>

                        <div className='dashboard-card__detail-row'>
                            {formatDate(data.post.publishedAt)}
                        </div>
                    </FormColumn>

                    <FormColumn>
                        <div>
                            <div className='dashboard-card__detail-row-muted'>
                                School
                            </div>

                            <div className='dashboard-card__detail-row'>
                                {data.post.tenant.name}
                            </div>
                        </div>
                    </FormColumn>
                </FormRow>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div>
                            <div className='dashboard-card__detail-row-muted'>
                                Description
                            </div>

                            <div className='dashboard-card__detail-row'>
                                {data.post.description || 'Unknown'}
                            </div>
                        </div>
                    </FormColumn>

                    <FormColumn>
                        <div>
                            <div className='dashboard-card__detail-row-muted'>
                                Location
                            </div>

                            <div className='dashboard-card__detail-row'>
                                {data.post.location.name || 'Unknown'}
                            </div>
                        </div>
                    </FormColumn>
                </FormRow>
            </Card>
            <FormRow columnCount={3}>
                <FormColumn>
                    <Card>
                        <H6>
                            Total Tap Count
                        </H6>

                        {data.tapCount}
                    </Card>
                </FormColumn>

                <FormColumn>
                    <Card>
                        <H6>
                            Unique Tap Count
                        </H6>

                        {data.uniqueTapCount}
                    </Card>
                </FormColumn>

                <FormColumn>
                    <Card>
                        <H6>
                            Saves
                        </H6>

                        {data.saveCount}
                    </Card>
                </FormColumn>
            </FormRow>
            <FormRow columnCount={2}>
                <FormColumn>
                    <Card>
                        <H6>
                            Redemptions
                        </H6>

                        {data.redeemCount}
                    </Card>
                </FormColumn>

                <FormColumn>
                    <Card>
                        <H6>
                            Response Metric
                        </H6>

                        {data.responseMetric}
                    </Card>
                </FormColumn>
            </FormRow>
        </>
    )
};

export default TopDealDetailModal;
