//import './AttachedNewsStory.scss';

import React from 'react';
import buildImageSrc from "../../../../../../utils/buildImageSrc";
import Button, { ButtonSizes, ButtonThemes } from "../../../../../../components/Button";
import NewsStory from "../../../../../../types/NewsStory";

type Props = {
    handleEditClick?: () => void
    newsStory: NewsStory
}

const AttachedNewsStory: React.FC<Props> = ({
    handleEditClick,
    newsStory,
}) => {
    return (
        <div className="c-content-summary">
            {newsStory.artifacts?.length > 0 && (
                <div className="c-content-summary__artifact" style={{backgroundImage: `url(${buildImageSrc(newsStory.artifacts[0], '220')})`}} />
            )}

            <div className="c-content-summary__text">
                {newsStory.title && (
                    <div className="c-content-summary__title">
                        {newsStory.title}
                    </div>
                )}

                {newsStory.linkUrl && (
                    <div className="c-content-summary__description">
                        {newsStory.linkUrl}
                    </div>
                )}
            </div>

            {handleEditClick && (
                <Button
                    onClick={handleEditClick}
                    size={ButtonSizes.Small}
                    theme={ButtonThemes.Light}
                >
                    Edit
                </Button>
            )}
        </div>
    );
};

export default AttachedNewsStory;
