import './ColorPicker.scss';

import React from 'react';
import { SketchPicker } from 'react-color';

import Button from "../Button";

type Props = {
    color?: string
    className?: string
    handleChange: Function
    handleDone: Function
    show: boolean
}

const ColorPicker: React.FC<Props> = ({
    color,
    className= '',
    handleChange,
    handleDone,
    show,
}) => {
    if(!show) {
        return null;
    }

    return (
        <>
            <div
                className="c-color-picker--background"
                onClick={() => handleDone()}
            />

            <div className={`c-color-picker ${className}`}>
                <SketchPicker
                    color={color}
                    disableAlpha
                    onChangeComplete={handleChange}
                />

                <Button
                    onClick={handleDone}
                >
                    Done
                </Button>
            </div>
        </>
    );
};

export default ColorPicker;
