import './SelectDisplayType.scss';

import React from 'react';

import { PUSH_NOTIFICATION_DESTINATION_TYPES, PUSH_NOTIFICATION_DISPLAY_TYPES } from "../../../../../../utils/constants";

import Checkbox from "../../../../../../components/Checkbox";
import Icon from "../../../../../../components/Icon";

type Props = {
    className?: string
    destination?: string
    displayType?: string
    onClick: Function
}

const SelectDisplayType: React.FC<Props> = ({
    className = '',
    destination,
    displayType,
    onClick
}) => {
    const isInactive = destination === PUSH_NOTIFICATION_DESTINATION_TYPES.PUSH;
    return (
        <div className={`c-select-display-type ${isInactive ? 'a-select-display-type--inactive' : ''} ${className}`}>
            <div className="c-select-display-type__option c-select-display-type__option--info">
                <Checkbox
                    checked={!isInactive && displayType === PUSH_NOTIFICATION_DISPLAY_TYPES.INFO}
                    id="chkDisplayTypeGeneralInfo"
                    label={(<div className="c-select-display__checkbox-label"><Icon type="info" /> General Info / Reminder</div>)}
                    name="displayType"
                    onChange={() => onClick(PUSH_NOTIFICATION_DISPLAY_TYPES.INFO)}
                />

                <div
                    className="c-select-display-type__display c-select-display-type__display--general-info"
                    onClick={() => onClick(PUSH_NOTIFICATION_DISPLAY_TYPES.INFO)}
                >
                    <div className="c-select-display-type__display-icon">
                        <Icon type="info" />
                    </div>

                    <div className="c-select-display-type__display-content">
                        <div className="c-select-display-type__display-name">
                            INFO
                        </div>

                        This is an example of a general style in-app notification message.
                    </div>
                </div>
            </div>

            <div className="c-select-display-type__option c-select-display-type__option--warning">
                <Checkbox
                    checked={!isInactive && displayType === PUSH_NOTIFICATION_DISPLAY_TYPES.WARNING}
                    id="chkDisplayTypeWarning"
                    label={(<div className="c-select-display__checkbox-label"><Icon type="alert" /> Warning Alert</div>)}
                    name="displayType"
                    onChange={() => onClick(PUSH_NOTIFICATION_DISPLAY_TYPES.WARNING)}
                />

                <div
                    className="c-select-display-type__display c-select-display-type__display--warning"
                    onClick={() => onClick(PUSH_NOTIFICATION_DISPLAY_TYPES.WARNING)}
                >
                    <div className="c-select-display-type__display-icon">
                        <Icon type="alert" />
                    </div>

                    <div className="c-select-display-type__display-content">
                        <div className="c-select-display-type__display-name">
                            ALERT
                        </div>

                        This is an example of an alert style in-app notification message.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectDisplayType;
