import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from 'clone';

import IError from '../../types/IError';
import Metadata from '../../types/Metadata';
import Profile from '../../types/Profile';

import { generateProfile, generateSchoolsMetadata } from '../../utils/generators';
import PATHS, { buildQueryString } from '../../utils/paths';
import Request from '../../utils/request';

import { RootState } from '../reducers';
import { buildErrorObject } from '../../utils/errors';
import { addError } from './errors';
import { setActiveSchool } from './schools';

type DeleteGlobalProfileProps = {
    profileId: number
}

export const deleteGlobalProfile = createAsyncThunk(
    'globalProfiles/deleteGlobalProfile',
    async ({profileId}: DeleteGlobalProfileProps, {getState, rejectWithValue, dispatch}) => {
        try {
            await new Request(
                (getState() as RootState).auth.token,
            ).delete(PATHS.globalProfiles.delete(profileId));

            return profileId;
        } catch (err) {
            console.log('deleteGlobalProfile', err);
            const friendlyMessage = 'Error deleting the global profile. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

export const getGlobalProfiles = createAsyncThunk(
    'globalProfiles/getGlobalProfiles',
    async (
        _, {getState, rejectWithValue, dispatch}) => {
        try {

            const result = await new Request(
                (getState() as RootState).auth.token,
            ).get(PATHS.globalProfiles.search());

            let items = result.data.data.items;

            return {items};
        } catch (err) {
            console.log('getGlobalProfiles', err);
            const friendlyMessage = 'Error getting the list of global profiles. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type GetGlobalProfileByIdProps = {
    profileId: number;
}

export const getGlobalProfileById = createAsyncThunk(
    'globalProfiles/getGlobalProfileById',
    async ({profileId}: GetGlobalProfileByIdProps, {getState, rejectWithValue, dispatch}) => {
        try {
            const result = await new Request(
                (getState() as RootState).auth.token,
            ).get(PATHS.globalProfiles.getById(profileId));

            return result.data.data;
        } catch (err) {
            console.log('getGlobalProfileById err', err);
            const friendlyMessage = 'Error getting the global profile. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type SaveGlobalProfileProps = {
    profile: Profile;
}

export const saveGlobalProfile = createAsyncThunk(
    'globalProfiles/saveGlobalProfile',
    async ({profile}: SaveGlobalProfileProps, {getState, rejectWithValue, dispatch}) => {
        try {
            const result = await new Request(
                (getState() as RootState).auth.token,
            ).post(PATHS.globalProfiles.update(profile.profileId), profile);

            return result.data.data;
        } catch (err) {
            console.log('saveGlobalProfile err', err);
            const friendlyMessage = 'Error saving the global profile. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type SaveActiveGlobalProfileProps = {
    profileId: number;
}

export const saveActiveGlobalProfile = createAsyncThunk(
    'globalProfiles/saveActiveGlobalProfile',
    async ({profileId}: SaveActiveGlobalProfileProps, {getState, rejectWithValue, dispatch}) => {
        try {

            const result = await new Request(
                (getState() as RootState).auth.token,
            ).post(PATHS.globalProfiles.setActive(profileId));

            const {activeSchool} = (getState() as RootState).schools;

            if (activeSchool) {
                const clonedActiveSchool = clone(activeSchool);
                clonedActiveSchool.postAsProfile = result.data.data;
                dispatch(setActiveSchool(clonedActiveSchool));
            }

            return result.data.data;
        } catch (err) {
            console.log('saveActiveGlobalProfile err', err);
            const friendlyMessage = 'Error saving the active global profile. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type GlobalProfilesState = {
    activeGlobalProfile: Profile;
    deleteGlobalProfileError?: IError;
    globalProfile: Profile;
    globalProfiles: Profile[];
    isDeletingGlobalProfile: boolean;
    isGettingGlobalProfile: boolean;
    isGettingGlobalProfiles: boolean;
    isUpdatingGlobalProfile: boolean;
    isUpdatingActiveGlobalProfile: boolean;
    getGlobalProfileError?: IError;
    getGlobalProfilesError?: IError;
    updateGlobalProfileError?: IError;
    updateActiveGlobalProfileError?: IError;
}

const initialState: GlobalProfilesState = {
    activeGlobalProfile: generateProfile(),
    globalProfile: generateProfile(),
    globalProfiles: [],
    isDeletingGlobalProfile: false,
    isGettingGlobalProfile: false,
    isGettingGlobalProfiles: false,
    isUpdatingActiveGlobalProfile: false,
    isUpdatingGlobalProfile: false,
};

export const GlobalProfilesSlice = createSlice({
    name: 'globalProfiles',
    initialState,
    reducers: {
        setActiveGlobalProfile: (state, action) => {
            state.activeGlobalProfile = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteGlobalProfile.pending, (state) => {
            state.isDeletingGlobalProfile = true;
            state.deleteGlobalProfileError = undefined;
        });
        addCase(deleteGlobalProfile.fulfilled, (state) => {
            state.isDeletingGlobalProfile = false;
        });
        addCase(deleteGlobalProfile.rejected, (state, action) => {
            state.isDeletingGlobalProfile = false;
            state.deleteGlobalProfileError = action.payload;
        });

        addCase(getGlobalProfiles.pending, (state) => {
            state.isGettingGlobalProfiles = true;
            state.getGlobalProfilesError = undefined;
        });
        addCase(getGlobalProfiles.fulfilled, (state, action) => {
            state.isGettingGlobalProfiles = false;
            state.globalProfiles = action.payload.items;
        });
        addCase(getGlobalProfiles.rejected, (state, action) => {
            state.isGettingGlobalProfiles = false;
            state.getGlobalProfilesError = action.payload;
        });

        addCase(getGlobalProfileById.pending, (state) => {
            state.isGettingGlobalProfile = true;
            state.getGlobalProfileError = undefined;
        });
        addCase(getGlobalProfileById.fulfilled, (state, action) => {
            state.isGettingGlobalProfile = false;
            state.globalProfile = action.payload;
        });
        addCase(getGlobalProfileById.rejected, (state, action) => {
            state.isGettingGlobalProfile = false;
            state.getGlobalProfileError = action.payload;
        });

        addCase(saveGlobalProfile.pending, (state) => {
            state.isUpdatingGlobalProfile = true;
            state.updateGlobalProfileError = undefined;
        });
        addCase(saveGlobalProfile.fulfilled, (state, action) => {
            state.isUpdatingGlobalProfile = false;
            state.globalProfile = action.payload;
        });
        addCase(saveGlobalProfile.rejected, (state, action) => {
            state.isUpdatingGlobalProfile = false;
            state.updateGlobalProfileError = action.payload;
        });

        addCase(saveActiveGlobalProfile.pending, (state) => {
            state.isUpdatingActiveGlobalProfile = true;
            state.updateGlobalProfileError = undefined;
        });
        addCase(saveActiveGlobalProfile.fulfilled, (state, action) => {
            state.isUpdatingActiveGlobalProfile = false;
            state.activeGlobalProfile = action.payload;
        });
        addCase(saveActiveGlobalProfile.rejected, (state, action) => {
            state.isUpdatingActiveGlobalProfile = false;
            state.updateActiveGlobalProfileError = action.payload;
        });
    },
});

export const {setActiveGlobalProfile} = GlobalProfilesSlice.actions;

export default GlobalProfilesSlice.reducer;
