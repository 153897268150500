import './TableSearch.scss';

import React from 'react';

import Button, { ButtonTypes } from '../Button';
import Form from '../Form';
import Icon from '../Icon';
import Textbox from '../Textbox';

type Props = {
    handleChange: Function
    handleClear: Function
    handleSubmit: Function
    placeholder?: string
    searchTerm: string
    size?: 'small'
}

const TableSearch: React.FC<Props> = ({
    handleChange,
    handleClear,
    handleSubmit,
    placeholder,
    searchTerm = '',
    size
}) => {
    return (
        <Form className={`c-table-search ${size === 'small' ? 'a-table-search--small' : ''}`}>

            <Textbox
                id="txtSearch"
                name="searchTerm"
                onChange={(e) => handleChange(e.target.value)}
                placeholder={placeholder}
                size={size}
                value={searchTerm}
            />

            <Button
                className="c-table-search__button"
                noStyles
                onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                type={ButtonTypes.Submit}
            >
                <Icon type="search" />
            </Button>

                <Button
                    className="c-table-search__clear-button"
                    noStyles
                    onClick={handleClear}
                    type={ButtonTypes.Button}
                    disabled={!searchTerm}
                >
                    <Icon type="x" />
                </Button>

        </Form>
    );
};

export default TableSearch;
