import React from 'react';
import {Route, Switch} from 'react-router';

import EditIntegrationEvent from './Events/EditIntegrationEvent';
import IntegrationsEventsList from './Events/EventsList';

const Integrations: React.FC = () => {
    return (
        <div className="integrations">
            <Switch>
                <Route path="/school/:schoolId/:profileType/integrations/event/:postStagingId/edit" component={EditIntegrationEvent} />
                <Route path="/school/:schoolId/:profileType/integrations/events/list" component={IntegrationsEventsList} />
            </Switch>
        </div>
    );
};

export default Integrations;
