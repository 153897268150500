import '../../ContentForms.scss';
import './CreateEditThreadForm.scss';

import React, { useEffect, useState } from 'react';
import { validated } from "react-custom-validation";
import clone from "clone";

import Group from "../../../../types/Group";
import Thread from "../../../../types/Thread";
import Validation from "../../../../types/Validation";

import { isRequired, isRequiredIfTrue } from "../../../../utils/validations";
import { handleCheckboxChange, handleDateChange, handleTextChange } from "../../../../utils/handle-changes";
import { generatePoll } from '../../../../utils/generators';
import { PrivacyTypes } from "../../../../utils/enums";
import { TOPIC_MEMBERSHIP_TYPES } from "../../../../utils/constants";

import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";
import { clearThread, deleteThread, getThreads, saveThread, setThread } from "../../../../store/slices/threads";

import AttachContentModal from "../../Notifications/CreateEditNotification/components/AttachContentModal";
import AttachedForumTopic from "../../Notifications/CreateEditNotification/components/AttachedForumTopic";
import Button, { ButtonSizes, ButtonThemes, ButtonTypes } from "../../../../components/Button";
import ButtonRow from "../../../../components/ButtonRow";
import Checkbox from "../../../../components/Checkbox";
import FileUpload from "../../../../components/FileUpload";
import Form from "../../../../components/Form";
import FormRow from "../../../../components/FormRow";
import FormValidationMessage from "../../../../components/FormValidationMessage";
import H4 from "../../../../components/H4";
import Icon from "../../../../components/Icon";
import Label from "../../../../components/Label";
import Modal from "../../../../components/Modal";
import PostingAsCard from "../../../../components/PostingAsCard";
import SuccessModal from "../../../../components/Modal/SuccessModal";
import Textbox from "../../../../components/Textbox";
import VisibilityCard from '../../../../components/VisibilityCard';
import Datepicker from "../../../../components/Datepicker";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import { useRouteMatch } from "react-router";

type Props = {
    close?: Function
    thread: Thread
    forumTopic?: Group
    $field: Function
    $fieldEvent: Function
    $validation: {
        forumTopic: Validation
        pollOptions: Validation
        pollQuestion: Validation
        title: Validation
    }
    $submit: Function
}

const CreateEditThreadForm: React.FC<Props> = ({
    close,
    thread,
    forumTopic,
    $field,
    $fieldEvent,
    $validation,
    $submit
}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [isUploadingMainArtifact, setIsUploadingMainArtifact] = useState(false);
    const [showAttachContentModal, setShowAttachContentModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const { profile: authUser } = useTypedSelector((state) => state.auth);
    const { activeSchool } = useTypedSelector((state) => state.schools);
    const { isSavingThread, isDeletingThread } = useTypedSelector((state) => state.threads);

    useEffect(() => {
        let initChanges = {};
        if(!thread.postId) {
            initChanges[`${params.profileType}Visibility`] = true;
        }

        if(!thread.artifactIds || thread.artifactIds.length === 0) {
            let artifactIds = thread.artifacts?.map((a) => a.artifactId);
            if((!artifactIds || artifactIds.length === 0) && thread.artifact) {
                artifactIds = [thread.artifact.artifactId];
            }
            initChanges['artifactIds'] = artifactIds;
        }

        if (forumTopic) {
            initChanges['forumTopic'] = forumTopic;
        }

        handleChange(null, null, initChanges);

        return () => {
            setTimeout(() => dispatch(clearThread()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddPollOption = () => {
        let newPostObj = clone(thread);
        const lastIndex = newPostObj.poll.options[newPostObj.poll.options.length - 1].index;
        let newOption = {text: '', index: lastIndex + 1};
        newPostObj.poll.options.push(newOption);
        dispatch(setThread(newPostObj));
    }

    const handleChange = (name?: string, value?: any, changes?: any) => {
        const applyChange = (name, value, obj) => {
            if(name === 'includePoll') {
                if(value === true) {
                    if(!obj.poll) {
                        obj.poll = generatePoll();
                    }
                } else {
                    delete obj.poll;
                }
                obj.includePoll = value;
            } else if(name === 'pollQuestion') {
                obj.poll.questionText = value;
            } else if(name === 'hideResponses') {
                obj.poll.hideResponses = value;
            } else if(name === 'status') {
                obj.status = value;

                if(value === 'A') {
                    delete obj.publishAt;
                }
            } else if(name === 'endAt') {
                obj.poll.endAt = value;
            } else {
                obj[name] = value;
            }

            return obj;
        }

        let clonedThread = clone(thread);

        if(name) {
            clonedThread = applyChange(name, value, clonedThread);
        } else {
            Object.keys(changes).forEach((key) => {
                clonedThread = applyChange(key, changes[key], clonedThread);
            });
        }

        dispatch(setThread(clonedThread));
    };

    const handleDelete = async () => {
        setShowDeleteConfirmationModal(false);
        try {
            console.log('trying')
            await dispatch(deleteThread({thread}));
            $fieldEvent('reset');
            dispatch(getThreads({}));
            close && close();
        } catch (err) {
            console.log('error deleteing post', err)
        }
        console.log('done')
    }

    const handleDeletePollOption = (index) => {
        let newPostObj = clone(thread);

        newPostObj.poll.options.splice(index, 1);
        if (newPostObj.poll.options.length < 2) {
            newPostObj.poll.options.push({text: ''})
        }
        dispatch(setThread(newPostObj));
    }

    const handlePollOptionChange = (index, event) => {
        let newPostObj = clone(thread);
        newPostObj.poll.options[index].text = event.target.value;
        dispatch(setThread(newPostObj));
    }


    const handleSave = async (isValid) => {
        if(isValid) {
            try {
                await dispatch(saveThread({})).unwrap();
                setShowSuccessModal(true);
                $fieldEvent('reset');
            } catch(err) {
                console.log('err from creating post', err);
            }
        }
    };

    const onUploadStart = () => {
        setIsUploadingMainArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange('artifactIds', [artifactId]);
        }
        setIsUploadingMainArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingMainArtifact(false);
    };

    const onDelete = () => {
        handleChange('artifactIds', []);
    };

    return (
        <Form className="content-form thread-form">
            <H4>
                {thread.postId ? 'Edit ' : 'Create a '}Discussion
            </H4>

            <FormRow>
                <PostingAsCard
                    profile={thread.profile || activeSchool.postAsProfile}
                    user={!thread.profile || thread.profile === activeSchool.postAsProfile ? authUser : null}
                />
            </FormRow>

            <FormRow>
                <FileUpload
                    acceptsVideo={true}
                    currentArtifact={thread.artifacts?.[0] || null}
                    name="artifactIds"
                    onDelete={onDelete}
                    onStart={onUploadStart}
                    onSuccess={onUploadSuccess}
                    onFailure={onUploadFailure}
                />
            </FormRow>

            <FormRow>
                <Label>
                    Group <span className="c-label__required-asterisk">*</span>
                </Label>

                {thread.forumTopic ? (
                    <div className="content-form__selected-group">
                        <AttachedForumTopic
                            hideEditButton={thread.postId != null}
                            forumTopic={thread.forumTopic}
                            handleEditButtonClick={() => setShowAttachContentModal(true)}
                        />
                    </div>
                ) : (
                    <div className="content-form__select-group">
                        <Button
                            onClick={() => setShowAttachContentModal(true)}
                            size={ButtonSizes.Small}
                            theme={ButtonThemes.Light}
                        >
                            Select a group
                        </Button>
                    </div>
                )}

                {$validation.forumTopic && $validation.forumTopic.show ? <FormValidationMessage>{$validation.forumTopic.error.reason}</FormValidationMessage> : null}
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtTitle"
                    label="Title"
                    name="title"
                    required
                    type="text"
                    validation={$validation.title}
                    value={thread.title || ''}
                    {...$field('title', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtDescription"
                    label="Description"
                    name="description"
                    onChange={event => handleTextChange(handleChange, event)}
                    type="textarea"
                    value={thread.description || ''}
                />
            </FormRow>

            <FormRow>
                <Checkbox
                    disabled={thread.postId != null}
                    label="Include Poll?"
                    checked={thread.includePoll || (thread.postId != null && thread.poll != null)}
                    id='chkIncludePoll'
                    name="includePoll"
                    onChange={(e) => handleCheckboxChange(handleChange, e)}
                />
            </FormRow>

            {(thread.includePoll || (thread.postId != null && thread.poll != null)) && (
                <>
                    <FormRow>
                        <Textbox
                            disabled={thread.postId != null}
                            id="txtPollQuestion"
                            label="Poll Question"
                            name="pollQuestion"
                            required
                            validation={$validation.pollQuestion}
                            value={thread.poll?.questionText || ''}
                            {...$field('pollQuestion', (event) => handleTextChange(handleChange, event))}
                        />
                    </FormRow>
                    <FormRow>
                        <Label>Options</Label>
                        {thread.poll?.options?.map((option, index) => (
                            <div className="content-form-poll__option" key={`threadOption_${index}`}>
                                <Textbox
                                    disabled={thread.postId != null}
                                    id="txtPollQuestion"
                                    name="pollQuestion"
                                    onChange={(event) => handlePollOptionChange(index, event)}
                                    value={option.text || ''}
                                    validation={index < 2 ? $validation.pollOptions : null}
                                />

                                {thread.postId == null && (
                                    <Button
                                        className="content-form-poll__option-delete"
                                        theme={ButtonThemes.Link}
                                        onClick={() => {
                                            handleDeletePollOption(index);
                                        }}
                                    >
                                        <Icon type="x" />
                                    </Button>
                                )}
                            </div>
                        ))}

                        {thread.postId == null && (
                            <Button
                                onClick={() => handleAddPollOption()}
                                size={ButtonSizes.Small}
                                theme={ButtonThemes.Light}
                                type={ButtonTypes.Button}
                            >
                                Add an Option
                            </Button>
                        )}

                        <ToggleSwitch
                            checked={thread.poll.hideResponses}
                            className="thread-form__hide-responses-toggle"
                            label="Hide Responses in App?"
                            name="hideResponses"
                            onClick={() => handleChange('hideResponses', !thread.poll.hideResponses)}
                        />
                    </FormRow>

                    <FormRow>
                        <Datepicker
                            dateFormat="MM/dd/yyyy hh:mma"
                            id="dateEndAt"
                            label="Stop voting at (Leave blank to allow voting for 14 days)"
                            minDate={new Date()}
                            name="endAt"
                            onChange={(newDate) => handleDateChange(handleChange, 'endAt', null, newDate)}
                            selected={thread.poll.endAt || null}
                            showTimeSelect
                            timeIntervals={15}
                        />
                    </FormRow>
                </>
            )}

            <VisibilityCard
                handleChange={handleChange}
                post={thread}
            />

            <ButtonRow>
                <Button
                    showActivityIndicator={isSavingThread}
                    type={ButtonTypes.Submit}
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                >
                    Save
                </Button>

                {thread.postId && (
                    <Button
                        theme={ButtonThemes.Link}
                        className="destructive"
                        onClick={() => setShowDeleteConfirmationModal(true)}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getThreads({}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
            >
                <p>
                    Your discussion has been saved.
                </p>
                <p>
                    It may take a minute for your discussion to show in feeds and lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingThread}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this thread?
            </Modal>

            <AttachContentModal
                close={() => setShowAttachContentModal(false)}
                disableFilter={({original}) => original.myForumTopicProfile?.membershipStatus !== TOPIC_MEMBERSHIP_TYPES.ACTIVE && original.privacyType !== PrivacyTypes.Public}
                handleSelect={(topic) => handleChange('forumTopic', topic)}
                show={showAttachContentModal}
                type="forumTopic"
            />
        </Form>
    );
}

function createEditThreadFormValidationConfig(props) {
    const { forumTopic, poll, title, postId } = props.thread;

    let options,
        questionText;

    if(poll) {
        options = poll.options;
        questionText = poll.questionText;
    }

    const optionsArePresent = options?.filter((o) => o.text != null && o.text !== '').length >= 2 ? true : null;

    return {
        fields: ['forumTopic', 'pollOptions', 'pollQuestion', 'title'],
        validations: {
            forumTopic: [
                [isRequiredIfTrue, forumTopic, !postId, 'Please select a group to post in']
            ],
            pollOptions: [
                [isRequiredIfTrue, optionsArePresent, poll != null, 'At least two options are required for a poll']
            ],
            pollQuestion: [
                [isRequiredIfTrue, questionText, poll != null, 'A question is required for a poll']
            ],
            title: [
                [isRequired, title]
            ],
        }
    }
}
export default validated(createEditThreadFormValidationConfig)(CreateEditThreadForm);
