import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from 'clone';

import ChatMessage from "../../types/ChatMessage";
import ClassNote from '../../types/ClassNote';
import Comment from '../../types/Comment';
import Deal from "../../types/Deal";
import EventPost from "../../types/EventPost";
import FlaggedContentItem from "../../types/FlaggedContentItem";
import Group from "../../types/Group";
import IError from "../../types/IError";
import MarketplaceItem from "../../types/MarketplaceItem";
import Metadata from "../../types/Metadata";
import ModerationData from "../../types/ModerationData";
import Profile from "../../types/Profile";
import Thread from "../../types/Thread";

import { buildErrorObject } from "../../utils/errors";
import { generateFlaggedContentMetadata } from "../../utils/generators";
import { ModerationStatuses } from "../../utils/enums";
import PATHS, { buildQueryString } from "../../utils/paths";
import Request from "../../utils/request";

import { addError } from "./errors";
import { RootState } from '../reducers';



type GetFlaggedContentProps = {
    flaggedContentMetadata?: Metadata
    isUpdate?: boolean
    schoolId?: number
}

export const getFlaggedContent = createAsyncThunk(
    'moderation/getFlaggedContent',
    async ({flaggedContentMetadata, isUpdate, schoolId}: GetFlaggedContentProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!flaggedContentMetadata) {
                flaggedContentMetadata = clone((getState() as RootState).moderation.flaggedContentMetadata);
            } else {
                flaggedContentMetadata = {...flaggedContentMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            if(isUpdate) {
                flaggedContentMetadata.page_num = flaggedContentMetadata.page_num + 1;
            } else {
                flaggedContentMetadata.page_num = 0;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.moderation.getFlaggedContent(schoolId, buildQueryString(flaggedContentMetadata)));
            let flaggedContent = res.data.data.items;
            if(isUpdate) {
                flaggedContent = (getState() as RootState).moderation.flaggedContent.concat(flaggedContent);
            }

            return {flaggedContent, flaggedContentMetadata, isAtEnd: res.data.data.items.length === 0};
        } catch(err) {
            console.log('getFlaggedContent', err);
            const friendlyMessage = 'Error getting flaggedContent. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
);



type GetModerationCountProps = {
    schoolId: number | string
}

export const getModerationCount = createAsyncThunk(
    'moderation/getModerationCount',async ({schoolId}: GetModerationCountProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            let res = await new Request((getState() as RootState).auth.token).get(PATHS.moderation.getCount(schoolId));

            return { moderationCount: res.data.data.count };
        } catch(err) {
            console.log('getModerationCount error', err);
            const friendlyMessage = 'Error getting a count of items needing moderation. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);



type ModerateContentProps = {
    item?: ChatMessage | ClassNote | Comment | Deal | EventPost | Group | MarketplaceItem | Profile | Thread
    moderationData: ModerationData
    schoolId: number | string
}

export const moderateContent = createAsyncThunk(
    'moderation/moderateContent',
    async ({item, moderationData, schoolId}: ModerateContentProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            moderationData = {...moderationData};

            let comment: Comment,
                group: Group,
                groupChatMessage: ChatMessage,
                post: ClassNote | Deal | EventPost | MarketplaceItem | Thread,
                profile: Profile;

            //@ts-ignore
            if(item.postCommentId) {
                moderationData.postCommentId = (item as Comment).postCommentId;
                comment = clone(item);
                if(moderationData.moderationStatus === ModerationStatuses.Approved || moderationData.moderationStatus === ModerationStatuses.SuperApproval) {
                    comment.flagged = false;
                    comment.flaggedCount = 0;
                    comment.superApproval = moderationData.moderationStatus === ModerationStatuses.SuperApproval;
                } else if(moderationData.moderationStatus === ModerationStatuses.Removed) {
                    comment.removedByMods = true;
                }
            //@ts-ignore
            } else if(item.postId) {
                //@ts-ignore
                if(item.focusComment) {
                    //@ts-ignore
                    moderationData.postCommentId = item.focusComment.postCommentId;
                    //post = {...item};
                    post = clone(item);
                    if(moderationData.moderationStatus === ModerationStatuses.Approved || moderationData.moderationStatus === ModerationStatuses.SuperApproval) {
                        post.focusComment.flagged = false;
                        post.focusComment.flaggedCount = 0;
                        post.focusComment.superApproval = moderationData.moderationStatus === ModerationStatuses.SuperApproval;
                    } else if(moderationData.moderationStatus === ModerationStatuses.Removed) {
                        post.focusComment.removedByMods = true;
                    }
                } else {
                    //@ts-ignore
                    moderationData.postId = item.postId;
                    post = {...item};
                    if(moderationData.moderationStatus === ModerationStatuses.Approved || moderationData.moderationStatus === ModerationStatuses.SuperApproval) {
                        post.flagged = false;
                        post.flaggedCount = 0;
                        post.superApproval = moderationData.moderationStatus === ModerationStatuses.SuperApproval;
                    } else if(moderationData.moderationStatus === ModerationStatuses.Removed) {
                        post.removedByMods = true;
                    }
                }
                //@ts-ignore
            } else if(item.forumTopicMessageId) {
                //@ts-ignore
                moderationData.forumTopicMessageId = item.forumTopicMessageId;
                groupChatMessage = {...item};
                if(moderationData.moderationStatus === ModerationStatuses.Approved || moderationData.moderationStatus === ModerationStatuses.SuperApproval) {
                    groupChatMessage.flagged = false;
                    groupChatMessage.flaggedCount = 0;
                    groupChatMessage.superApproval = moderationData.moderationStatus === ModerationStatuses.SuperApproval;
                } else if(moderationData.moderationStatus === ModerationStatuses.Removed) {
                    groupChatMessage.removedByMods = true;
                }
                //@ts-ignore
            } else if(item.forumTopicId) {
                //@ts-ignore
                moderationData.forumTopicId = item.forumTopicId;
                group = {...item};
                if(moderationData.moderationStatus === ModerationStatuses.Approved || moderationData.moderationStatus === ModerationStatuses.SuperApproval) {
                    group.flagged = false;
                    group.flaggedCount = 0;
                    group.superApproval = moderationData.moderationStatus === ModerationStatuses.SuperApproval;
                } else if(moderationData.moderationStatus === ModerationStatuses.Removed) {
                    group.removedByMods = true;
                }
                //@ts-ignore
            } else if (item.uuid) {
                //@ts-ignore
                const postCommentId = parseInt(item.uuid);
                if (isNaN(postCommentId)) {
                    throw new Error("Invalid postCommentId");
                }

                moderationData.postCommentId = postCommentId;
                //@ts-ignore
            } else if(item.profileId) {
                //@ts-ignore
                moderationData.profileId = item.profileId;
                profile = {...item};
                if(moderationData.moderationStatus === ModerationStatuses.Approved || moderationData.moderationStatus === ModerationStatuses.SuperApproval) {
                    profile.flagged = false;
                    profile.flaggedCount = 0;
                    profile.superApproval = moderationData.moderationStatus === ModerationStatuses.SuperApproval;
                } else if(moderationData.moderationStatus === ModerationStatuses.Removed) {
                    profile.removedByMods = true;
                }
            }

            await new Request((getState() as RootState).auth.token).post(PATHS.moderation.moderateContent(schoolId), moderationData);
            if(!(getState() as RootState).moderation.isGettingModerationCount) {
                dispatch(getModerationCount({schoolId}));
            }
            return { comment, group, groupChatMessage, moderationData, post, profile };
        } catch(err) {
            console.log('moderateContent error', err);
            const friendlyMessage = 'Error moderating content. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
);


interface ModerationState {
    flaggedContent: Array<FlaggedContentItem>
    flaggedContentMetadata: Metadata
    getFlaggedContentError?: IError
    getModerationCountError?: IError
    isGettingFlaggedContent: boolean
    isGettingModerationCount: boolean
    isModeratingContent: boolean
    moderateContentError?: IError
    moderationCount: number
}

const initialState: ModerationState = {
    flaggedContent: [],
    flaggedContentMetadata: generateFlaggedContentMetadata(),
    moderationCount: 0,
    isGettingFlaggedContent: false,
    isGettingModerationCount: false,
    isModeratingContent: false,
    getFlaggedContentError: undefined,
    getModerationCountError: undefined,
    moderateContentError: undefined,
};

export const moderationSlice = createSlice({
    name: 'moderation',
    initialState,
    reducers: {

    },
    extraReducers: ({addCase}) => {
        addCase(getFlaggedContent.pending, (state, action) => {
            state.getFlaggedContentError = undefined;
            state.isGettingFlaggedContent = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.flaggedContentMetadata) {
                state.flaggedContentMetadata = action.meta.arg.flaggedContentMetadata;
            }
        });
        addCase(getFlaggedContent.fulfilled, (state, action) => {
            state.isGettingFlaggedContent = false;
            state.flaggedContent = action.payload.flaggedContent;
            state.flaggedContentMetadata = action.payload.flaggedContentMetadata;
        });
        addCase(getFlaggedContent.rejected, (state, action) => {
            state.getFlaggedContentError = action.error;
            state.isGettingFlaggedContent = false;
        });

        addCase(getModerationCount.pending, (state, action) => {
            state.getModerationCountError = undefined;
            state.isGettingModerationCount = true;
        });
        addCase(getModerationCount.fulfilled, (state, action) => {
            state.isGettingModerationCount = false;
            state.moderationCount = action.payload.moderationCount;
        });
        addCase(getModerationCount.rejected, (state, action) => {
            state.getModerationCountError = action.error;
            state.isGettingModerationCount = false;
        });

        addCase(moderateContent.pending, (state, action) => {
            state.moderateContentError = undefined;
            state.isModeratingContent = true;
        });
        addCase(moderateContent.fulfilled, (state, action) => {
            state.isModeratingContent = false;

            let clonedContent = clone(state.flaggedContent);

            if(action.payload.comment) {
                const foundIndex = clonedContent.findIndex((cc) => cc.postCommentId === action.payload.comment.postCommentId);
                if(foundIndex != null) {
                    clonedContent[foundIndex] = action.payload.comment;
                }
            } else if(action.payload.groupChatMessage) {
                const foundIndex = clonedContent.findIndex((cc) => cc.forumTopicMessageId === action.payload.groupChatMessage.forumTopicMessageId);
                if(foundIndex != null) {
                    clonedContent[foundIndex] = action.payload.groupChatMessage;
                }
            } else if(action.payload.group) {
                const foundIndex = clonedContent.findIndex((cc) => cc.forumTopicId === action.payload.group.forumTopicId);
                if(foundIndex != null) {
                    clonedContent[foundIndex] = action.payload.group;
                }
            } else if(action.payload.profile) {
                const foundIndex = clonedContent.findIndex((cc) => cc.profileId === action.payload.profile.profileId);
                if(foundIndex != null) {
                    clonedContent[foundIndex] = action.payload.profile;
                }
            } else if(action.payload.post?.focusComment) {
                const foundIndex = clonedContent.findIndex((cc) => cc.postCommentId === action.payload.post.focusComment.postCommentId);
                if(foundIndex != null) {
                    clonedContent[foundIndex] = action.payload.post;
                }
            } else if(action.payload.post) {
                const foundIndex = clonedContent.findIndex((cc) => cc.postId === action.payload.post.postId);
                if(foundIndex != null) {
                    clonedContent[foundIndex] = action.payload.post;
                }
            }

            state.flaggedContent = clonedContent;
        });
        addCase(moderateContent.rejected, (state, action) => {
            state.moderateContentError = action.error;
            state.isModeratingContent = false;
        });
    }
});

export default moderationSlice.reducer;
