import './FeedMarketplaceItem.scss';

import React from 'react';
import classNames from "classnames";

import MarketplaceItem from "../../../types/MarketplaceItem";

import buildImageSrc from '../../../utils/buildImageSrc';
import { formatAsDollarAmount } from '../../../utils/utils';

import Badge from '../../Badge';
import Card from '../../Card';
import FeedViewsRow from '../components/FeedViewsRow';
import MarketplaceItemTableMoreMenu from "../../../containers/Schools/School/SchoolMarketplaceItems/MarketplaceItemTableMoreMenu";

type Props = {
    className?: string
    onEdit?: Function
    post: MarketplaceItem
}

const FeedMarketplaceItem: React.FC<Props> = ({
    className = '',
    onEdit,
    post,
}) => {
    const renderImage = () => {
        const artifact = post.artifacts && post.artifacts[0] ? post.artifacts[0] : post.artifact;

        return (
            <div className="c-feed-marketplace-item__image" style={artifact ? { backgroundImage: `url(${buildImageSrc(artifact, '220')})` } : null} />
        )
    };

    const classes = classNames(
        'c-feed-marketplace-item c-feed-item',
        className,
        {
            'a-feed-item--flagged': post.flagged,
            'a-feed-item--removed-by-mods': post.removedByMods,
            'a-feed-item--super-approved': post.superApproval,
        }
    );

    return (
        <Card className={classes}>
            <div className="c-feed-marketplace-item__content">
                {renderImage()}

                <div className="c-feed-marketplace-item__data c-feed-post__data">
                    <div className="c-feed-marketplace-item__title c-feed-post__title">
                        {post.title}
                    </div>

                    <div className="c-feed-marketplace-item__price">
                        {formatAsDollarAmount(post.price)}

                        {post.freeItem === 'Y' && <Badge color="green" text="Free" />}

                        {post.soldAt && <Badge color="red" text="Sold" />}
                    </div>

                    <FeedViewsRow viewCount={post.totalViews} saveCount={post.saveCount} />
                </div>

                {onEdit && (
                    <MarketplaceItemTableMoreMenu
                        item={post}
                        setShowNewContentModal={onEdit}
                    />
                )}
            </div>
        </Card>
    );
};

export default FeedMarketplaceItem;
