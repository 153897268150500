import React, { useMemo } from 'react';

import { deleteEvent, setEvent } from "../../../../../store/slices/events";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import EventPost from "../../../../../types/EventPost";
import TableMoreMenu from "../../../../../components/Table/TableMoreMenu";
import { useHistory, useRouteMatch } from "react-router";

type Props = {
    getAfterDelete?: () => void
    item: EventPost
    setShowNewContentModal: (arg0: boolean) => void
}

const EventTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    item,
    setShowNewContentModal,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch() as { params: { profileType: string, schoolId: string } };

    const { isDeletingEvent } = useTypedSelector((state) => state.events);

    const handleDelete = async (post) => {
        try {
            await dispatch(deleteEvent({event: post})).unwrap();

            if(getAfterDelete) {
                getAfterDelete();
            }
        } catch(err) {
            console.log('EventTableMoreMenu handleDelete err', err);
        }
    };

    const handleEditEvent = (item) => {
        dispatch(setEvent(item));
        setShowNewContentModal(true);
    };

    return (
        <TableMoreMenu
            customOptions={[
                {
                    icon: 'navigation-users',
                    onClick: () => {
                        dispatch(setEvent(item));
                        history.push(`/school/${params.schoolId}/${params.profileType}/event/${item.postId}/attendees`)
                    },
                    text: 'Attendees and Check-in',
                },
            ]}
            isDeleting={isDeletingEvent}
            item={item}
            getAfterModerate={getAfterDelete}
            onDeleteClick={() => handleDelete(item)}
            onEditClick={() => handleEditEvent(item)}
            showAttachToPushNotificationOption={item.publishedAt != null || item.status === 'A'}
        />
    );
};

export default EventTableMoreMenu;
