import './Pill.scss';

import React from 'react';

type Props = {
    type: string
}

const Pill: React.FC<Props> = ({
    type
}) => {
    return (
        <div className={`c-pill a-pill--${type.toLowerCase()}`}>
            {type}
        </div>
    );
};

export default Pill;
