import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import clone from 'clone';

import { getStudentGroupChatMessages } from "../../../../../../store/slices/students";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import ActivityIndicator from "../../../../../../components/ActivityIndicator";
import FeedGroupChatMessage from "../../../../../../components/Feed/FeedGroupChatMessage";
import H6 from "../../../../../../components/H6";
import TableFlaggedOnlyToggle from "../../../../../../components/Table/TableFlaggedOnlyToggle";
import FeedFlaggedBanner from "../../../../../../components/Feed/FeedFlaggedBanner";

const ViewStudentChats: React.FC = () => {
    const dispatch = useAppDispatch();

    const [atEnd, setAtEnd] = useState<boolean>(false);
    const [isInfiniteScrolling, setIsInfiniteScrolling] = useState<boolean>(false);

    const { isGettingStudentGroupChatMessages, student, studentGroupChatMessages, studentGroupChatMessagesByMonth, studentGroupChatMessagesMetadata } = useTypedSelector((state) => state.students);

    const handleFlaggedFilterChange = async (filterByFlaggedOnly) => {
        try {
            let clonedMetadata = clone(studentGroupChatMessagesMetadata);
            clonedMetadata.flagged = filterByFlaggedOnly;
            clonedMetadata.page_num = 0;
            let res = await dispatch(getStudentGroupChatMessages({profileId: student.profileId, schoolId: params.tenantId, studentGroupChatMessagesMetadata: clonedMetadata})).unwrap();
            setAtEnd(res.isAtEnd);
        } catch(err) {
            console.log('ViewStudentEvents handleFlaggedFilterChange err', err);
        }
    };

    const handleInfiniteScroll = async () => {
        if(isGettingStudentGroupChatMessages || isInfiniteScrolling || atEnd) {
            return;
        }

        try {
            setIsInfiniteScrolling(true);
            let res: any = await dispatch(getStudentGroupChatMessages({isUpdate: true, profileId: student.profileId, schoolId: params.tenantId})).unwrap();
            setAtEnd(res.isAtEnd);
        } catch(err) {
            console.log('ViewStudentChats handleInfiniteScroll err', err);
            setAtEnd(true);
        } finally {
            setIsInfiniteScrolling(false);
        }
    };

    return (
        <div className="view-student__posts view-student__content">
            <TableFlaggedOnlyToggle
                onToggleChange={handleFlaggedFilterChange}
                value={studentGroupChatMessagesMetadata.flagged}
            />

            {isGettingStudentGroupChatMessages ? (
                <div className="view-student__activity-indicator">
                    <ActivityIndicator />
                </div>
            ) : (
                <>
                    {studentGroupChatMessages.length === 0 ? (
                        <div className="school-feed__empty">
                            There's nothing here.
                        </div>
                    ) : (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleInfiniteScroll}
                            hasMore={!atEnd}
                        >
                            {Object.keys(studentGroupChatMessagesByMonth).map((month) => (
                                <div className="view-student__content__month" key={month}>
                                    <H6>
                                        {studentGroupChatMessagesByMonth[month].label}
                                    </H6>

                                    {studentGroupChatMessagesByMonth[month].messages.map((message) => (
                                        <React.Fragment key={message.forumTopicMessageId}>
                                            <FeedFlaggedBanner item={message} />
                                            <FeedGroupChatMessage message={message} />
                                        </React.Fragment>
                                     ))}
                                </div>
                            ))}

                            {isInfiniteScrolling && (
                                <div className="school-feed__infinite-scrolling-indicator">
                                    <ActivityIndicator color="white" />
                                </div>
                            )}

                            {atEnd && !isInfiniteScrolling && (
                                <div className="school-feed__at-end">
                                    End of feed
                                </div>
                            )}
                        </InfiniteScroll>
                    )}
                </>
            )}
        </div>
    );
};

export default ViewStudentChats;
