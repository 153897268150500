import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import clone from 'clone';
import Tenant from '../../../types/Tenant';
import { useAppDispatch } from '../../../store';
import { useTypedSelector } from '../../../store/reducers';
import { getSchools, setSearchTerm } from '../../../store/slices/schools';
import TableColumn from '../../../types/TableColumn';
import Modal from '../../../components/Modal';
import TableFilterRow from '../../../components/Table/TableFilterRow';
import TableSearch from '../../../components/TableSearch';
import Table from '../../../components/Table';

type SelectSchoolModalProps = {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    handleSelectSchool: (item: Tenant) => void;
};

const SelectSchoolModal: React.FC<SelectSchoolModalProps> = ({
     show,
     setShow,
     handleSelectSchool
 }) => {
    const dispatch = useAppDispatch();

    const {schools, isGettingSchools, schoolsMetadata, searchTerm} = useTypedSelector((state) => state.schools);

    useEffect(() => {
        const intialize = async () => {
            await dispatch(getSchools({
                isUpdate: false,
                schoolsMetadata: {
                    page_num: 0,
                    page_size: 100,
                    sort: 'name',
                    order: 'asc',
                    search: '',
                }
            })).unwrap();
        }
        intialize();

    }, []);

    const columns: TableColumn[] = [{
        accessor: 'name',
        Header: 'Name',
    }, {
        accessor: 'givingActive',
        Header: 'Active',
        Cell: ({value}) => {
            return value && value === true ? 'Yes' : 'No';
        },
    }];

    const handleSelect = (item: Tenant) => {
        handleSelectSchool(item);
    }

    const onMetadataChange = async (changes, isUpdate) => {
        try {
            let clonedMetadata = clone(schoolsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getSchools({isUpdate, schoolsMetadata: clonedMetadata}));
        } catch(err) {
            console.log('SchoolsList onMetadataChange err', err);
        }

    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <Modal
            show={show}
            confirmButtonDisabled={schools.length === 0}
            confirmButtonText="Continue"
            confirmButtonOnClick={() => setShow(false)}
            title="Select A School To Update Giving Status"
            className={'wide'}
        >
            <TableFilterRow>
                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search School Names"
                    searchTerm={searchTerm}
                    size="small"
                />
            </TableFilterRow>

            <Table
                columns={columns}
                data={schools}
                getData={onMetadataChange}
                pageNumber={schoolsMetadata.page_num}
                pageSize={schoolsMetadata.page_size}
                totalNumberOfItems={schoolsMetadata.total}
                showPagination={false}
                disablePending={isGettingSchools}
                onRowClick={(item) => {
                    handleSelect(item.original);
                }}
            />
        </Modal>
    )
}

export default SelectSchoolModal;
