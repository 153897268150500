import React from 'react';

import Group from "../../../../../../types/Group";
import { useHistory, useRouteMatch } from "react-router";

import { isObjectNullOrEmpty } from "../../../../../../utils/utils";

import { deleteGroup, setGroup } from "../../../../../../store/slices/groups";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import TableMoreMenu from "../../../../../../components/Table/TableMoreMenu";

type Props = {
    getAfterDelete?: () => void
    item: Group
    setShowNewContentModal: (shouldShow: boolean) => void
}

const GroupTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    item,
    setShowNewContentModal,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const match = useRouteMatch();

    const { isDeletingGroup } = useTypedSelector((state) => state.groups);

    const isSystemGroup = isObjectNullOrEmpty(item.elementDetail) === false;

    const handleDelete = async (groupToDelete) => {
        try {
            await dispatch(deleteGroup({group: groupToDelete})).unwrap();

            if(getAfterDelete) {
                getAfterDelete();
            }
        } catch(err) {
            console.log('GroupTableMoreMenu handleDelete err', err);
        }
    };

    const handleEdit = (item) => {
        dispatch(setGroup(item));
        setShowNewContentModal(true);
    };

    return (
        <TableMoreMenu
            getAfterModerate={getAfterDelete}
            hideModerationOptions={isSystemGroup && !item.flagged}
            isDeleting={isDeletingGroup}
            item={item}
            onDeleteClick={!isSystemGroup ? () => handleDelete(item) : null}
            onEditClick={!isSystemGroup ? () => handleEdit(item) : null}
            onViewClick={() => history.push(`/school/${match?.params?.schoolId}/${match?.params?.profileType}/group/${item.forumTopicId}/activity`)}
            showAttachToPushNotificationOption={!isSystemGroup}
            type="group"
        />
    );
};

export default GroupTableMoreMenu;
