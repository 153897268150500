export enum AdmissionStatus {
    Admit = 'admit',
    Declined = 'declined',
    Promoted = 'promoted',
}

export enum ApprovalStatusLabels {
    admit = 'Admit',
    declined = 'Declined',
    promoted = 'Promoted to Student',
}

export enum BeforeOrAfter {
    After = 'after_id',
    Before = 'before_id',
}

export enum GroupCreatorTypes {
    ContentCreator = 'content_creator',
    SchoolAdmin = 'school_admin',
    Student = 'student',
    SuperAdmin = 'super_admin',
    TenantAdmin = 'tenant_admin',
    TenantTeam = 'tenant_team',
}

export enum GroupMembershipTypes {
    Active = 'active',
    Declined = 'declined',
    Denied = 'denied',
    Invited = 'invited',
    Requested = 'requested',
}

export enum ListTypes {
    Grid = 'grid',
    Table = 'table',
}

export enum ModerationStatuses {
    Approved = 'approved',              // implies mod reviewed the flag, and said "it's fine keep it live"
    Removed = 'removed',                // implies mod reviewed the flag and said "this is no bueno, let's remove it from the app"
    SuperApproval = 'super_approval',   // implies mod reviewed the flag, and said "This is fine, and will always be fine. If other profiles flag it in the future, let's ignore it"
}

export enum PostStatuses {
    Active = 'A',
    Draft = 'W',
    Scheduled = 'X',
}

export enum PostTypes {
    ClassNote = 'S',
    Deal = 'D',
    Event = 'E',
    MarketplaceItem = 'I',
    NewsStory = 'N',
    Thread = 'T',
}

export enum PostTypeLabels {
    S = 'Class Note',
    D = 'Deal',
    E = 'Event',
    I = 'Marketplace Item',
    N = 'News Story',
    T = 'Thread',
}

export enum PrivacyTypes {
    Private = 'private',
    Public = 'public',
}

export enum ProfileTypes {
    Admin = 'admin',
    Admit = 'admit',
    Alumni = 'alumni',
    Student = 'student',
    Global = 'global',
}

export enum ProfileStatuses {
    Active = 'A',
    Pending = 'P',
}

export enum ProfileStatusLabels {
    A = 'Active',
    P = 'Pending',
}

export enum ResourceTypes {
    DisabilityServices = 'disability_services',
    Employment = 'employment',
    FinancialAid = 'financial_aid',
    HealthForms = 'health_forms',
    Housing = 'housing',
    KeyDates = 'key_dates',
    MealPlans = 'meal_plans',
    SafetyInformation = 'safety_information',
}

export enum Sizes {
    Large = 'large',
    Small = 'small',
}

export enum WebsocketStatuses {
    Connected = 'connected',
    ConnectError = 'connect_error',
    ConnectFailed = 'connect_failed',
    Disconnected = 'disconnected',
    NotInitialized = 'not_initialized',
}

export enum PromotionStatuses {
    PendingAcceptance = 'pending_acceptance',
    PendingApproval = 'pending_approval',
    Promoted = 'Promoted',
}

export enum YesOrNo {
    Yes = 'Y',
    No = 'N',
}

export enum Platforms {
    Web = 'web',
    Mobile = 'mobile',
    Both = 'both',
}
