import './Conversation.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from "react-router";
import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../../../../../utils/utils";

import ConversationType from "../../../../../../types/Conversation";

import {
    clearConversation,
    clearNewMessage,
    clearNewRecipient,
    getConversation,
    getConversations,
    markAsRead,
    resetConversationPagination,
    sendMessage,
    updateMessages,
    subscribeToMessages,
} from "../../../../../../store/slices/directMessages";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import ActivityIndicator from "../../../../../../components/ActivityIndicator";
import Button from "../../../../../../components/Button";
import ConversationNewMessage from "./components/ConversationNewMessage";
import ConversationThread from "./components/ConversationThread";
import FileUploadErrorModal from "../../../../../../components/Modal/FileUploadErrorModal";
import H5 from "../../../../../../components/H5";
import Icon from "../../../../../../components/Icon";

const Conversation: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const messageRef = useRef<HTMLSpanElement>();

    const [atEnd, setAtEnd] = useState(false);

    const [isInfiniteScrolling, setIsInfiniteScrolling] = useState(false);

    const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);

    const {
        conversation,
        isGettingConversation,
        isSendingMessage,
        newRecipient,
        websocketStatus,
    } = useTypedSelector((state) => state.directMessages);




    useEffect(() => {
        const initialize = async () => {

            try {
                let res = await dispatch(getConversation({conversationId: params.conversationId, schoolId: params.schoolId})).unwrap();
                setAtEnd(res.messages.length < 7);
                dispatch(subscribeToMessages({conversationId: params.conversationId}));
                dispatch(markAsRead({conversationId: params.conversationId, schoolId: params.schoolId}));
            } catch (err) {
                console.warn('Conversation initialize err', err);
            }
        }

        if(params?.conversationId) {
            initialize();
        } else {
            console.warn('No conversation ID. Cannot initialize conversation.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.conversationId]);

    useEffect(() => {
        return () => {
            dispatch(clearNewMessage());
            dispatch(clearNewRecipient());
            dispatch(clearConversation());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const recipient = useMemo(
        () => {
            if(!isArrayNullOrEmpty(conversation?.recipients)){
                return conversation.recipients.find((r) => r.isMe !== 'Y');
            } else if(!isObjectNullOrEmpty(newRecipient)) {
                return {isNewConversation: true, profile: newRecipient};
            } else {
                return null;
            }
        },
        [conversation.recipients, newRecipient]
    );

    const handleInfiniteScroll = async () => {
        if(atEnd || isInfiniteScrolling || isGettingConversation) {
            return;
        }

        setIsInfiniteScrolling(true);

        try {
            let res = await dispatch(updateMessages({beforeOrAfter: 'before', hideSpinner: true})).unwrap();
            setAtEnd(res.atEnd);
        } catch(err) {
            console.warn('Conversation handleInfiniteScroll err', err);
        } finally {
            setIsInfiniteScrolling(false);
        }
    };

    const handleSendMessage = async (e) => {
        try {
            e.preventDefault();
            let res: {conversation?: ConversationType} = await dispatch(sendMessage({schoolId: params?.schoolId})).unwrap();
            dispatch(resetConversationPagination());
            dispatch(getConversations({hideSpinner: true, schoolId: params?.schoolId, profileType: params.profileType}));
            if(!isObjectNullOrEmpty(res.conversation)) {
                setAtEnd(true);
            }
            if(history.location.pathname.includes('new')) {
                history.push(`/school/${params?.schoolId}/${params.profileType}/messages/conversation/${res.conversation.conversationId}`);
            }
            if (messageRef.current) {
                messageRef.current.innerText = '';
            }
        } catch(err) {
            console.warn('Conversation handleSendMessage err', err);
        }
    }

    return (
        <div className="conversation">
            {(!isObjectNullOrEmpty(conversation) || !isObjectNullOrEmpty(recipient)) && (
                <H5 className="conversation__header">
                    <Button
                        className="conversation__header-back-button"
                        noStyles
                        onClick={() => {
                            history.push(`/school/${params?.schoolId}/${params.profileType}/messages`);

                            setTimeout(() => dispatch(clearConversation()), 500);
                        }}
                    >
                        <Icon type="arrow-left" />
                    </Button>

                    <div className="conversation__header-name">
                        {recipient?.profile?.firstName} {recipient?.profile?.lastName}
                    </div>
                </H5>
            )}

            {isObjectNullOrEmpty(conversation) && !isGettingConversation && (!newRecipient) ? (
                <div className="conversation__empty">
                    No conversation selected. Click on a conversation in the left column to get started.
                </div>
            ) : (
                <>
                    {isGettingConversation ? (
                        <div className="conversation__activity-indicator">
                            <ActivityIndicator />
                        </div>
                    ) : (
                        <>
                            <ConversationThread
                                atEnd={atEnd}
                                handleInfiniteScroll={handleInfiniteScroll}
                                isInfiniteScrolling={isInfiniteScrolling}
                                messages={conversation.messages}
                            />

                            {websocketStatus !== 'connected' && conversation.conversationId && (
                                <div className='c-group-chat__status'>
                                    <Icon type="info" />
                                    <p>Trying to reconnect ({websocketStatus})...</p>
                                </div>
                            )}

                            <ConversationNewMessage
                                onSendMessage={handleSendMessage}
                                isSendingMessage={isSendingMessage}
                            />

                        </>
                    )}
                </>
            )}


            <FileUploadErrorModal
                close={() => setShowUploadErrorModal(false)}
                show={showUploadErrorModal}
            />
        </div>
    );
};

export default Conversation;
