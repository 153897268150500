import React, { useEffect, useState } from 'react';
import Button, { ButtonThemes } from '../Button';

type EmailButtonProps = {
    email: string;
    subject?: string;
    body?: string;
}

const EmailButton: React.FC<EmailButtonProps> = ({
    email,
    subject,
    body
}) => {
    const [mailToString, setMailToString] = useState<string>('');

    useEffect(() => {
        let mailToString = `mailto:${email}`;

        if (subject) {
            mailToString = mailToString + `?subject=${subject}`;
        }

        if (body) {
            mailToString = mailToString + `?body=${body}`;
        }

        setMailToString(mailToString);
    }, [email, subject, body]);


    return (
        <Button
            theme={ButtonThemes.Link}
            noStyles
        >
            <a
                href={mailToString}
            >
                {email}
            </a>
        </Button>
    );
};

export default EmailButton;
