import './GivingCategoryTable.scss';

import React, { useMemo } from 'react';
import TableColumn from '../../../../../types/TableColumn';

import Table from '../../../../../components/Table';
import GivingCategory from '../../../../../types/GivingCategory';
import TableRowImage from '../../../../../components/Table/TableRowImage';
import Avatar from '../../../../../components/Avatar';

type GivingCategoryTableProps = {
    categories: GivingCategory[]
}

const GivingCategoryTable: React.FC<GivingCategoryTableProps> = ({categories}) => {
    const columns = useMemo(
        () => {
            let columnsArray: Array<TableColumn> = [
                {
                    Header: 'Category',
                    Cell: ({row: { original }}) => (
                        <div className="giving-category-table__category">
                            <Avatar artifact={original.artifact} />

                            <div className="giving-category-table__name">
                                {original.name}
                            </div>
                        </div>
                    ),
                    hasImage: true,
                    sortBy: 'name'
                },
                {
                    Header: 'Description',
                    accessor: (d) => d.description,
                },
                {
                    Header: 'Featured',
                    accessor: (d) => d.description,
                },
                {
                    Header: 'Assigned Funds',
                    accessor: (d) => d.opportunityCount || 0,
                },
            ];

            return columnsArray;
        }, []);

    const data = useMemo(
        () => {
            return categories;
        }, [categories]);

    return (
        <div>
            <Table
                columns={columns}
                data={data}
                getData={() => {}}
                pageNumber={0}
                pageSize={1}
                showPagination={false}
                totalNumberOfItems={categories.length}
            />
        </div>
    );
}

export default GivingCategoryTable;
