import './ConversationMessage.scss';

import React from 'react';
import moment from 'moment';

import { isObjectNullOrEmpty } from "../../../../../../../../utils/utils";
import buildImageSrc from "../../../../../../../../utils/buildImageSrc";

import Avatar from "../../../../../../../../components/Avatar";
import DateComponent from '../../../../../../../../components/Date';
import ParagraphsFromNewlines from "../../../../../../../../components/ParagraphsFromNewlines";
import Message from "../../../../../../../../types/Message";

type Props = {
    className?: string
    message: Message
}

const ConversationMessage: React.FC<Props> = ({
    className = '',
    message
}) => {
    if(isObjectNullOrEmpty(message)) {
        return null;
    }

    const { artifact, isOwner, sender } = message;

    return (
        <div className={`c-message ${className}`}>
            {artifact && (
                <div className={`c-message__row c-message__image-row ${message.isOwner ? 'a-message__row--owner' : ''}`}>
                    <DateComponent
                        date={moment(message.createdAt).format()}
                        dontAppendText
                    />

                    <div className="c-message__image" style={{aspectRatio: `${artifact.width / artifact.height}`}}>
                        <img
                            alt="Was sent as part of a message"
                            className="c-message__image__image"
                            width={artifact.width}
                            height={artifact.height}
                            style={{aspectRatio: `${artifact.width/artifact.height}`}}
                            src={buildImageSrc(artifact, '812')}
                        />
                    </div>

                    {!isOwner && (
                        <Avatar
                            artifact={sender.artifact}
                            className="c-message__avatar"
                        />
                    )}
                </div>
            )}

            {message.parts.map((m, i) => {
                if(!m.body) {
                    return null;
                }

                return (
                    <div key={i} className={`c-message__row ${message.isOwner ? 'a-message__row--owner' : ''}`}>
                        {message.isOwner ? (
                            <div className={'c-message__viewed_at-container'}>
                                <div className="c-message__viewed_at-text">
                                    {message.viewed && message.viewedAt ? 'Seen' : 'Sent'}
                                </div>
                                <DateComponent
                                    date={message.viewed && message.viewedAt ? message.viewedAt : message.createdAt || new Date().toISOString}
                                    dontAppendText
                                />
                            </div>
                            ) : (
                            <DateComponent
                                date={message.createdAt || new Date().toISOString}
                                dontAppendText
                            />
                        )}

                        <div className={`c-message__text-container ${isOwner ? 'a-message__text-container--owner' : ''}`}>
                            <ParagraphsFromNewlines
                                linkify
                                text={m.body}
                            />
                        </div>

                        {!isOwner && (
                            <Avatar
                                artifact={sender.artifact}
                                className="c-message__avatar"
                            />
                        )}
                    </div>
                )
            })}
        </div>
    );
};

export default ConversationMessage;
