import React, { useState } from 'react';

import { useAppDispatch } from '../../../../../../store';
import { useTypedSelector } from '../../../../../../store/reducers';
import { setShowTopItemsDetailModal } from '../../../../../../store/slices/superAdminDashboard';

import ActivityIndicator from '../../../../../../components/ActivityIndicator';
import Avatar from '../../../../../../components/Avatar';
import Button, { ButtonThemes } from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import { CardType, DashboardCardProps } from '../../../index';
import DashboardCardModal from '../../DashboardCardModal';
import FormColumn from '../../../../../../components/FormColumn';
import FormRow from '../../../../../../components/FormRow';
import H5 from '../../../../../../components/H5';

const TopItemsCard: React.FC<DashboardCardProps> = ({cardData, initialized, isGettingDashboard}) => {
    const [detailData, setDetailData] = useState(null);
    const dispatch = useAppDispatch();

    const {showTopItemsDetailModal} = useTypedSelector(state => state.superAdminDashboard);
    const {title, subtitle, subtitles, data} = cardData;

    const handleClick = (value: any) => {
        dispatch(setShowTopItemsDetailModal(value));
    };

    return (
        <Card
            key={cardData.title}
            className="dashboard-card__card"
        >
            <div className="dashboard-card__row">
                <H5>
                    {title}
                </H5>
            </div>
            {isGettingDashboard && (
                <ActivityIndicator size={'large'}/>
            )}
            {initialized && !isGettingDashboard && (
                <>
                    <FormRow
                        columnCount={4}
                        className="dashboard-card__detail-row"
                    >
                        <FormColumn className="dashboard-card__detail-subtitle-col--primary">
                            {subtitle}
                        </FormColumn>

                        {subtitles.map(st => (
                            <FormColumn
                                key={st}
                                className="dashboard-card__detail-subtitle-col"
                            >
                                {st}
                            </FormColumn>
                        ))}
                    </FormRow>
                    {data && data.length > 0 && data.map(item => (
                        <FormRow
                            key={item.post.postId}
                            columnCount={4}
                            className="dashboard-card__detail-row"
                        >
                            <FormColumn className="dashboard-card__detail-main-col">
                                <div className="dashboard-card__detail-row">
                                    <Avatar
                                        className="dashboard-card__detail-row-avatar"
                                        artifact={item.post.artifacts[0]}
                                    />
                                    <div>
                                        <div>
                                            {item.post.title}
                                        </div>

                                        <div className="dashboard-card__detail-row-muted">
                                            {(item.post?.profile?.firstName && item.post?.profile?.lastName) ? (
                                                item.post.profile.firstName + ' ' + item.post.profile.lastName
                                            ) : (
                                                'Business Name Here'
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </FormColumn>

                            <FormColumn className="dashboard-card__detail-stat-col">
                                {item.post?.price && item.post.price > 0 ? ('$' + item.post.price) : ('FREE')}
                            </FormColumn>

                            <FormColumn className="dashboard-card__detail-stat-col details">
                                <Button
                                    theme={ButtonThemes.Link}
                                    onClick={() => {
                                        handleClick(!showTopItemsDetailModal);
                                        setDetailData(item);
                                    }}>
                                    Details
                                </Button>
                            </FormColumn>
                        </FormRow>
                    ))}
                    {detailData && (
                        <DashboardCardModal
                            type={CardType.TopItems}
                            data={detailData}
                        />
                    )}
                </>
            )}
        </Card>
    );
};

export default TopItemsCard;
