import './Label.scss';

import React from 'react';
import classNames from 'classnames';

type Props = {
    children: React.ReactNode
    className?: string
    disabled?: boolean
    htmlFor?: string
    isWrappingLabel?: boolean
}

const Label: React.FC<Props> = ({
    children,
    className,
    disabled,
    htmlFor,
    isWrappingLabel,
    ...otherProps
}) => {
    let classes = classNames(
        'c-label',
        className,
        {
            'a-label--disabled': disabled === true
        }
    );

    if(htmlFor != null || isWrappingLabel === true) {
        return (
            <label
                {...otherProps}
                className={classes}
                htmlFor={htmlFor}
            >
                {children}
            </label>
        );
    } else {
        return (
            <div className={classes}>
                {children}
            </div>
        )
    }
};

export default Label;
