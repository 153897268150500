import * as React from 'react'

import * as moment from 'moment';
import {useState} from 'react';
import {VictoryArea, VictoryAxis, VictoryChart, VictoryLabel, VictoryScatter} from 'victory';

import ActivityIndicator from "../../../../../../components/ActivityIndicator";
import Card from "../../../../../../components/Card";
import {IChartTimeSeriesData} from '../../../../Dashboard/components/DashboardCards/DashboardCharts';
import H5 from "../../../../../../components/H5";
import H6 from "../../../../../../components/H6";


type Props = {
    chart: Array<IChartTimeSeriesData>,
    total: number
}

const StatsTimeSeriesChartGrid:React.FC<Props> = ({chart, total}) => {
    const [datumDate, setDatumDate] = useState(null);
    const [datumCount, setDatumCount] = useState(null);

    const CHART_DOMAIN_PADDING: any = {x: 20, y: [20, 0]};

    const CHART_HEIGHT = 400;
    const CHART_WIDTH = 800;
    const CHART_PADDING = {left: 25, right: 10, top: 5, bottom: 20};

    const X_AXIS_STYLE = {
        axis: {stroke: '#000000'},
        tickLabels: {fill: '#000000', textAlign: 'left'},
        grid: {stroke: '#cccccc'},
    };
    const Y_AXIS_STYLE = {
        axis: {stroke: '#cccccc'},
        axisLabel: {fill: '#000000', angle: 0},
        tickLabels: {fill: '#000000'},
        grid: {stroke: '#cccccc'}
    };

    const X_AXIS_TICK_LABEL_COMPONENT = (
        <VictoryLabel style={{
            fill: '#000000',
            fontSize: 8,
            textAlign: 'left',
            fontWeight: () => {
                return 'normal';
            }
        }}/>
    );
    const Y_AXIS_TICK_LABEL_COMPONENT = (
        <VictoryLabel style={{
            fill: '#000000',
            fontSize: 8,
            textAlign: 'left',
            fontWeight: () => {
                return 'normal';
            }
        }}/>
    );

    const AREA_STYLE = {data: {stroke: '#0057FF', fill: '#E0E8FD'}};

    const X_AXIS_TICK_FORMAT = (t) => moment(t).format('MM/DD');

    const getDateFromDatumDate = (value, chart) => {
        const date = chart[value === 0 ? value : (value - 1)].t;
        return formatDate(date);
    };

    const formatDate = (date) => {
        return moment(date).format('MMM DD, YYYY');
    };

    const getCountAverage = (chart) => {
        const counts = chart.map(row => row.value);
        return counts.reduce((a, b) => a + b) / counts.length;
    };

    const clearDatumData = () => {
        setDatumCount(null);
        setDatumDate(null);
    };


    return (
        <Card>
            <div>
                <H5 className='school-stats__time-series-card-title'>
                    Profile Activity Chart
                </H5>
                <H6 className='school-stats__time-series-card-title'>
                    Total Activities In Time Window: { total ? total.toLocaleString('en-US') :  <ActivityIndicator />}
                </H6>
                <H6 className='school-stats__time-series-card-title'>
                    Average Activities: {chart && chart.length > 0 && (getCountAverage(chart).toFixed(0))}
                </H6>
            </div>
            {chart && chart.length > 0 && (
            <Card className='school-stats__time-series-datum-card'>
                <div className='school-stats__time-series-datum-container'>
                    <div className='school-stats__time-series-datum-row'>
                        <div>
                            <H6 className='school-stats__time-series-datum-item'>
                                Chart Date
                            </H6>
                        </div>
                        <div>
                            <H6 className='school-stats__time-series-datum-item'>
                                Activity Count
                            </H6>
                        </div>
                    </div>
                    <div className='school-stats__time-series-datum-row'>
                        <div className='school-stats__time-series-datum-item'>
                            {datumDate ? getDateFromDatumDate(datumDate, chart) : 'Today'}
                        </div>

                        <div className='school-stats__time-series-datum-item'>
                            {datumCount === null ? (
                                (chart && chart.length > 0 ? chart[chart.length - 1].value : 0)
                            ) : (
                                datumCount
                            )}
                        </div>
                    </div>
                </div>
            </Card>
            )}

            {chart && chart.length > 0 && (
                <Card>
                    <VictoryChart
                        domainPadding={CHART_DOMAIN_PADDING}
                        height={CHART_HEIGHT}
                        width={CHART_WIDTH}
                        padding={CHART_PADDING}
                        maxDomain={{
                            y: chart.map(row => row.value).sort((a, b) => b - a)[0] + 2,
                            x: chart.length - 1
                        }}
                    >
                        <VictoryAxis
                            tickValues={chart.map(row => row.t)}
                            tickCount={20}
                            tickFormat={X_AXIS_TICK_FORMAT}
                            tickLabelComponent={X_AXIS_TICK_LABEL_COMPONENT}
                            style={X_AXIS_STYLE}
                        />

                        <VictoryAxis
                            crossAxis
                            dependentAxis
                            orientation="left"
                            tickFormat={(int) => parseInt(int)}
                            tickLabelComponent={Y_AXIS_TICK_LABEL_COMPONENT}
                            style={Y_AXIS_STYLE}
                        />

                        <VictoryArea
                            data={chart.map(row => row.value)}
                            style={AREA_STYLE}
                        />

                        <VictoryScatter
                            data={chart.map(row => row.value)}
                            labels={() => null}
                            style={{
                                labels: {
                                    fill: '#E0E8FD',
                                }
                            }}
                            size={2}
                            events={[{
                                target: "data",
                                eventHandlers: {
                                    onClick: () => {
                                        clearDatumData()
                                        return [
                                            {
                                                target: "labels",
                                                mutation: (props) => {
                                                    setDatumCount(props.datum._y)
                                                    setDatumDate(props.datum._x)
                                                }
                                            }
                                        ];
                                    }
                                }
                            }]}
                        />
                    </VictoryChart>

                    <div className='school-stats__time-series-card-title'>
                        {formatDate(chart[0].t) + ' To ' + formatDate(chart[chart.length - 1].t)}
                    </div>
                </Card>
            )}
        </Card>
    )
}

export default StatsTimeSeriesChartGrid;
