import React from "react";

import moment from "moment";

import Card from "../../../../../../../components/Card";
import {DetailModalProps} from "../../index";
import H6 from "../../../../../../../components/H6";
import FormColumn from "../../../../../../../components/FormColumn";
import FormRow from "../../../../../../../components/FormRow";

const TopDmSchoolsDetailModal: React.FC<DetailModalProps> = ({data}) => {
    const formatDate = (date: Date) => {
        return moment(date).format('MMMM DD, YYYY');
    };

    console.log("Incoming data: ", data)

    return (
        <>
            <Card>
                <H6 className='dashboard-card__detail-main-col'>
                    {data?.tenant?.name}
                </H6>

                <FormRow>
                    <div className='dashboard-card__detail-row-muted'>
                        Launched
                    </div>

                    {data.tenant?.launchDate ? formatDate(data.tenant.launchDate) : 'Not Launched'}
                </FormRow>
            </Card>
            <Card>
                <H6>
                    Details
                </H6>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Conversations
                        </div>

                        <p>
                            {data.conversationCount}
                        </p>
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Location
                        </div>

                        <p>
                            {data.tenant.city + ', ' + data.tenant.state}
                        </p>
                    </FormColumn>
                </FormRow>
            </Card>
        </>
    )
}

export default TopDmSchoolsDetailModal;
