import './GlobalProfileDropdownSelect.scss';

import React, { useEffect, useState } from 'react';

import {
    getGlobalProfiles,
    saveActiveGlobalProfile,
} from '../../../store/slices/globalProfiles';
import { useAppDispatch } from '../../../store';
import { useTypedSelector } from '../../../store/reducers';

import DropdownOption from '../../../types/DropdownOption';

import ActivityIndicator from '../../ActivityIndicator';
import Dropdown from '../../Dropdown';

const GlobalProfileDropdownSelect: React.FC = () => {
    const dispatch = useAppDispatch();

    const {
        activeGlobalProfile,
        globalProfiles,
        isGettingGlobalProfiles,
    } = useTypedSelector((state) => state.globalProfiles);

    const [selected, setSelected] = useState<{
        label: string,
        value: string | number,
    }>({label: 'Select a profile', value: ''});

    useEffect(() => {
        const initialize = async () => {
            try {
                if (!isGettingGlobalProfiles) {
                    await dispatch(getGlobalProfiles());

                    if (activeGlobalProfile) {
                        setSelected({
                            label: activeGlobalProfile.firstName + ' ' + activeGlobalProfile.lastName,
                            value: activeGlobalProfile.profileId,
                        });
                    }

                }
            } catch (err) {
                console.log('GlobalProfileDropdownSelect init err', err);
            }
        };

        initialize();
    }, []);

    useEffect(() => {
        if (activeGlobalProfile) {
            setSelected({
                label: activeGlobalProfile.firstName + ' ' + activeGlobalProfile.lastName,
                value: activeGlobalProfile.profileId,
            });
        }
    }, [activeGlobalProfile]);

    const handleSelect = async (option: DropdownOption) => {
        if (!option.value) {
            return;
        }

        try {
            await dispatch(saveActiveGlobalProfile({profileId: option.value as number})).unwrap();
        } catch (err) {
            console.log('GlobalProfileDropdownSelect handleSelect err', err);
        }
    };

    const options: DropdownOption[] = [{label: 'Select a profile', value: ''}];
    globalProfiles.forEach((gp) => {
        options.push({
            label: gp.firstName + ' ' + gp.lastName,
            value: gp.profileId,
        });
    });

    if (!isGettingGlobalProfiles && (!globalProfiles || globalProfiles.length === 0)) {
        return null;
    }

    return (
        <div className="c-global-profile-dropdown-select__container">
            {isGettingGlobalProfiles ? (
                <ActivityIndicator/>
            ) : (
                <Dropdown
                    className={'c-global-profile-dropdown-select__dropdown'}
                    name={'global-profile-dropdown'}
                    onChange={handleSelect}
                    options={options}
                    menuPlacement={'bottom'}
                    label={'Posting As'}
                    naked
                    placeholder={selected.label}
                    value={selected.value}
                />
            )}
        </div>
    );
};

export default GlobalProfileDropdownSelect;
