import React from "react";

import Avatar from "../../../../../../../components/Avatar";
import Card from "../../../../../../../components/Card";
import {DetailModalProps} from "../../index";
import FormRow from "../../../../../../../components/FormRow";
import FormColumn from "../../../../../../../components/FormColumn";
import H6 from "../../../../../../../components/H6";

const RecentlyJoinedUserDetailModal: React.FC<DetailModalProps> = ({data}) => {
    return (
        <>
            <Card>
                <div className='dashboard-card__detail-main-col'>
                    <H6>
                        {data.profile.firstName + ' ' + data.profile.lastName}
                    </H6>

                    <Avatar artifact={data.profile.artifact} />
                </div>
                <FormRow>
                    <div className='dashboard-card__detail-row-muted'>
                        About
                    </div>

                    <p>
                        {data.profile.about}
                    </p>
                </FormRow>
            </Card>
            <Card>
                <H6>
                    Details
                </H6>
                <FormRow columnCount={4}>
                    <FormColumn className='dashboard-card__detail-row-muted'>
                        School
                    </FormColumn>

                    <FormColumn className='dashboard-card__detail-row-muted'>
                        Major
                    </FormColumn>

                    <FormColumn className='dashboard-card__detail-row-muted'>
                        Minor
                    </FormColumn>

                    <FormColumn className='dashboard-card__detail-row-muted'>
                        Class Year
                    </FormColumn>
                </FormRow>
                <FormRow columnCount={4}>
                    <FormColumn className='dashboard-card__detail-stat-col'>
                        {data.profile.tenant.name}
                    </FormColumn>

                    <FormColumn className='dashboard-card__detail-stat-col'>
                        {data.profile?.details ? data.profile.details.filter(detail => detail?.element?.code === 'major')[0]?.elementDetail?.code : 'Unknown'}
                    </FormColumn>

                    <FormColumn className='dashboard-card__detail-stat-col'>
                        {data.profile?.details ? data.profile.details.filter(detail => detail?.element?.code === 'minor')[0]?.elementDetail?.code : 'Unknown'}
                    </FormColumn>

                    <FormColumn className='dashboard-card__detail-stat-col'>
                        {data.profile?.details ? data.profile.details.filter(detail => detail?.element?.code === 'gradyear')[0]?.elementDetail?.code : 'Unknown'}
                    </FormColumn>
                </FormRow>
            </Card>
            <br/>
            <FormRow columnCount={1}>
                <FormColumn>
                    <Card>
                        <H6>
                            Activity Count
                        </H6>

                        {data.activityCount}
                    </Card>
                </FormColumn>
            </FormRow>
        </>
    )
};

export default RecentlyJoinedUserDetailModal;
