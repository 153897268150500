import './FormRow.scss';

import React from 'react';
import classNames from 'classnames';

type Props = {
    children: React.ReactNode
    className?: string
    columnCount?: number
    layout?: 'one-third'
}

const FormRow: React.FC<Props> = ({
    children,
    className = '',
    columnCount,
    layout
}) => {
    return (
        <div className={classNames(
            'c-form-row',
            layout ? `a-form-row--layout--${layout}` : null,
            className,
            {
                'has-columns': columnCount != null,
                'column-count--3': columnCount === 3,
                'column-count--4': columnCount === 4
            })}>
            {children}
        </div>
    );
}

export default FormRow;
