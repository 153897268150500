import './TableFilterRowDivider.scss';

import React from 'react';

type Props = {
    className?: string
}

const TableFilterRowDivider: React.FC<Props> = ({
    className = ''
}) => {
    return (
        <div className={`c-table-filter-row-divider ${className}`} />
    );
};

export default TableFilterRowDivider;
