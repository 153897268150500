import './Date.scss';

import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

type Props = {
    date: Date | string
    dontAppendText?: boolean
}

const Date: React.FC<Props> = ({
    date,
    dontAppendText
}) => {
    const [refreshSwitch, setRefreshSwitch] = useState(false);

    const refreshTimer = useRef(null);

    useEffect(() => {
        if(date) {
            refreshTimer.current = setTimeout(refreshDisplay, 10000);
        }

        return () => clearTimeout(refreshTimer.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshDisplay = () => {
        setRefreshSwitch(!refreshSwitch);
        refreshTimer.current = setTimeout(refreshDisplay, 10000);
    };

    const renderDate = () => {
        const dateString = moment(date).fromNow();
        const dateArray = dateString.split(' ');
        let newDateString = `${translateValue(dateArray[0])}${/month|months/.test(dateArray[1]) ? 'mo' : dateArray[1][0]}${dontAppendText !== true ? ` ${dateArray[2]}` : ''}`;
        if(dateString === 'a few seconds ago' || dateString === 'ina few' || dateString === 'ina' || dateString === 'in a few seconds') {
            newDateString = 'now';
        }
        return newDateString;
    }

    const translateValue = (val) => {
        if(/a|an/.test(val)) {
            return 1
        }

        return val;
    };

    if(!date) {
        return null;
    }

    return (
        <div className="c-date">
            {renderDate()}
        </div>
    );
};

export default Date;
