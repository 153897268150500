import 'react-toggle/style.css';
import './ToggleSwitch.scss';

import React from 'react';
import Toggle from 'react-toggle';
import classNames from 'classnames';

import Label from '../Label';

type Props = {
    checked: boolean
    className?: string
    disabled?: boolean
    label: string | React.ReactElement
    labelPosition?: 'left'
    name: string
    offText?: string
    onClick: Function
    onText?: string
}

const ToggleSwitch: React.FC<Props> = ({
    checked,
    className = '',
    disabled,
    label,
    labelPosition,
    name,
    offText,
    onClick,
    onText
}) => {
    const handleClick = () => {
        if (disabled) {
            return;
        }

        onClick();
    };

    return (
        <div className={classNames(
            'c-toggle-switch',
            className,
            {'a-toggle-switch--left-label': labelPosition === 'left'}
        )}>
            <Label
                disabled={disabled}
                isWrappingLabel
            >
                <Toggle
                    checked={checked}
                    name={name}
                    onChange={handleClick}
                    icons={{
                        checked: <On text={onText}/>,
                        unchecked: <Off text={offText}/>
                    }}
                />
                <span>{label}</span>
            </Label>
        </div>
    );
}

const On = ({text}) => <div>{text || ' '}</div>;

const Off = ({text}) => <div>{text || ' '}</div>;

export default ToggleSwitch;
