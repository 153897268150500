import './SchoolStats.scss';

import * as React from 'react'

import {useTypedSelector} from "../../../../store/reducers";
import {getProfileActivityTimeSeriesStat} from "../../../../store/slices/timeSeriesStat";
import {useAppDispatch} from "../../../../store";
import {useEffect, useRef, useState} from 'react';
import {useRouteMatch} from "react-router";
import * as moment from "moment";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import Button, { ButtonTypes } from "../../../../components/Button";
import Card from "../../../../components/Card";
import Datepicker from "../../../../components/Datepicker";
import DetailStats from "./Details";
import H5 from "../../../../components/H5";
import StatsTimeSeriesChartGrid from "./TimeSeries/StatsTimeSeriesChartGrid";

const SchoolStats: React.FC = () => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();
    const datepickerRef = useRef(null);

    const [searchStartDate, setSearchStartDate] = useState(moment().subtract(2, "months").format('yyyy-MM-DD'))
    const [searchEndDate, setSearchEndDate] = useState(moment().format('yyyy-MM-DD'));
    const [mostActiveDate, setMostActiveDate] = useState(null);
    const [totalActivityCount, setTotalActivityCount] = useState(null)
    const [loadedChart, setTimeSeriesChart] = useState(null);


    const {timeSeriesChart, isGettingTimeSeriesChartData} = useTypedSelector(state => state.presentationTimeSeriesData);

    useEffect(() => {
        if (isGettingTimeSeriesChartData) {
            return;
        }

        if (timeSeriesChart.length > 0 && !isGettingTimeSeriesChartData) {
            setTimeSeriesChart(timeSeriesChart);
        }
        return function cleanup() {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSeriesChart]);

    useEffect(() => {
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const initialize = async () => {
        if (searchStartDate && searchEndDate) {
            try {
                const res = await dispatch(getProfileActivityTimeSeriesStat({tenantId: match?.params?.schoolId, startAt: searchStartDate, endAt: searchEndDate})).unwrap();
                handleStateChanges(res)
            } catch (err) {
                console.log(err);
            }
        } else if (searchStartDate && !searchEndDate) {
            try {
                const res = await dispatch(getProfileActivityTimeSeriesStat({tenantId: match?.params?.schoolId, startAt: searchStartDate})).unwrap();
                handleStateChanges(res)
            } catch (err) {
                console.log(err);
            }
        } else if (!searchStartDate && searchEndDate) {
            try {
                const res = await dispatch(getProfileActivityTimeSeriesStat({tenantId: match?.params?.schoolId, endAt: searchEndDate})).unwrap();
                handleStateChanges(res)
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleStateChanges = (res) => {
        const activityCounts = res.chart.map(row => row.value).sort((a, b) => b - a);
        const highestActivity = activityCounts[0];
        const date = moment(res.chart.filter(row => row.value === highestActivity)[0].t).format('yyyy-MM-DD');
        setTotalActivityCount(activityCounts.reduce((a, b) => a + b, 0));
        handleMostActiveDateChange(date);
    }

    const handleMostActiveDateChange = (date) => {
        setMostActiveDate(date);
    }

    const handleStartDateChange = (date) => {
        const formatted = moment(date).format('yyyy-MM-DD');
        setSearchStartDate(formatted);
    }

    const handleEndDateChange = (date) => {
        const formatted = moment(date).format('yyyy-MM-DD');
        setSearchEndDate(formatted);
    }

    const handleClick = () => {
        setTimeSeriesChart(null);
        setMostActiveDate(null);
        initialize();
    }

    return (
        <>
            <Card className='school-stats__time-series-card'>
                <Card className='school-stats__options'>
                    <H5 className='school-stats__time-series-card-title'>
                        Profile Activity Chart Options
                    </H5>
                    <div className='school-stats__date-grid'>
                        <div className='school-stats__date'>
                            <br/>
                            <div className='school-stats__date-title'>Select a Start Date:</div>
                            <Datepicker
                                className='school-stats__date-datepicker'
                                dateFormat="MM-dd-yyyy"
                                id="dateToSearch"
                                name="searchDate"
                                onChange={(newDate) => handleStartDateChange(newDate)}
                                placeholderText="mm-dd-yyyy"
                                required
                                selected={moment(searchStartDate).startOf("day").toDate()}
                                showMonthDropdown
                                showYearDropdown
                                thisRef={datepickerRef}
                            />
                            <br/>
                        </div>
                        <div className='school-stats__date'>
                            <br/>
                            <div className='school-stats__date-title'>Select an End Date:</div>
                            <Datepicker
                                className='school-stats__date-datepicker'
                                dateFormat="MM-dd-yyyy"
                                id="searchEndDate"
                                name="searchEndDate"
                                onChange={(newDate) => handleEndDateChange(newDate)}
                                placeholderText="mm-dd-yyyy"
                                required
                                selected={moment(searchEndDate).startOf("day").toDate()}
                                showMonthDropdown
                                showYearDropdown
                                thisRef={datepickerRef}
                            />
                            <br/>
                        </div>
                    </div>
                    <div className='school-stats__button'>
                        <Button
                            onClick={() => handleClick()}
                            type={ButtonTypes.Button}
                        >
                            Search
                        </Button>
                    </div>
                </Card>


                {isGettingTimeSeriesChartData && (
                    <Card>
                        <H5 className='school-stats__time-series-card-title'>
                            Profile Activity Chart
                        </H5>
                        <div className='school-stats__loading'>
                            <p className='school-stats__loading-title'>Loading</p>
                            <ActivityIndicator/>
                        </div>
                    </Card>
                )}
                {!isGettingTimeSeriesChartData && loadedChart && (
                    <StatsTimeSeriesChartGrid
                        chart={loadedChart}
                        total={totalActivityCount}
                    />
                )}
            </Card>

            <br/>
            {mostActiveDate ? (
                <DetailStats date={mostActiveDate as string}/>
            ) : isGettingTimeSeriesChartData ? (
                <Card className='school-stats__time-series-details-loading-card'>
                    <H5 className='school-stats__time-series-details-loading-title'>
                        Waiting for Chart Data
                    </H5>
                    <ActivityIndicator/>
                </Card>
            ) : (
                <Card className='school-stats__time-series-details-loading-card'>
                    <H5 className='school-stats__time-series-details-loading-title'>
                        Finding Most Active Day From Chart
                    </H5>
                    <ActivityIndicator/>
                </Card>
            )}
        </>
    );
};

export default SchoolStats;
