import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Request from "../../utils/request";
import {RootState} from "../reducers";
import PATHS from "../../utils/paths";
import {DashboardCardData, SlimDashData} from "./superAdminDashboard";
import {addError} from './errors';
import IError from '../../types/IError';

type getTenantDashboardDataProps = {
    tenantId: number
}

export const getTenantDashboardData = createAsyncThunk(
    'dashboard/getTenantDashboardData',
    async (params: getTenantDashboardDataProps, {dispatch, getState}) => {
        try {
            const res = await new Request((getState() as RootState).auth.token).get(PATHS.dashboard.getTenantDashboard(params.tenantId));
            // console.log("Result: ", res)
            const {liveNow, topDms, topDeals, topEvents, topThreads, topItems, pushNotifications, recentlyJoinedProfiles, mostActiveProfiles, mostActiveForumTopics} = res.data.data.dashboardStats
            const timeSeriesChart = res.data.data.timeSeries

            const dau: Array<{x: Date, y: number}> = timeSeriesChart.dau.map(r => {
                return {
                    x: r.t,
                    y: r.value
                }
            });

            const mau: Array<{x: Date, y: number}> = timeSeriesChart.mau.map(r => {
                return {
                    x: r.t,
                    y: r.value
                }
            });

            const headcount: Array<{x: Date, y: number}> = timeSeriesChart.headcount.map(r => {
                return {
                    x: r.t,
                    y: r.value
                }
            });

            const profileActivity: Array<{x: Date, y: number}> = timeSeriesChart.profileActivity.map(r => {
                return {
                    x: r.t,
                    y: r.value
                }
            });

            const percentUsersWithPushesEnabled: Array<{x: Date, y: number}> = timeSeriesChart.percentUsersWithPushesEnabled.map(r => {
                return {
                    x: r.t,
                    y: r.value
                }
            });

            const mostActiveUsersData: DashboardCardData = {
                title: 'Most Active Users',
                subtitle: 'USER',
                subtitles: ['ACTIVITIES', ''],
                data: mostActiveProfiles
            }
            const topDealsData: DashboardCardData = {
                title: 'Top Deals',
                subtitle: 'CONTENT',
                subtitles: ['ENGAGEMENT', ''],
                data: topDeals
            }
            const topEventsData: DashboardCardData = {
                title: 'Top Events',
                subtitle: 'CONTENT',
                subtitles: ['ENGAGEMENT', ''],
                data: topEvents
            }
            const topThreadsData: DashboardCardData = {
                title: 'Top Threads',
                subtitle: 'CONTENT',
                subtitles: ['ENGAGEMENT', ''],
                data: topThreads
            }
            const topItemsData: DashboardCardData = {
                title: 'Top Items',
                subtitle: 'CONTENT',
                subtitles: ['PRICE', ''],
                data: topItems
            }
            const pushNotificationsData: DashboardCardData = {
                title: 'Push Notifications',
                subtitle: 'MESSAGE',
                subtitles: ['SCHOOL', ''],
                data: pushNotifications
            }
            const recentlyJoinedUsersData: DashboardCardData = {
                title: 'Recently Joined Users',
                subtitle: 'EMAIL ADDRESS',
                subtitles: ['CREATED', ''],
                data: recentlyJoinedProfiles
            }
            const mostActiveGroupsData: DashboardCardData = {
                title: 'Most Active Groups',
                subtitle: 'LIFETIME',
                subtitles: ['CONTENT', ''],
                data: mostActiveForumTopics
            }
            const topDmUsersData: DashboardCardData = {
                title: 'Top DM Users',
                subtitle: 'USER',
                subtitles: ['CONVERSATIONS', ''],
                data: topDms
            }
            const liveNowData: DashboardCardData = {
                title: 'Live Now',
                subtitle: 'MOST ACTIVE NOW',
                subtitles: ['ACTIVITIES', ''],
                data: liveNow
            }

            const getCountAverage = (chart) => {
                const counts = chart.map(row => row.y)
                if (counts.length > 0) {
                    return counts.reduce((a, b) => a + b) / counts.length;
                }
            }

            const slimDashData = [
                {
                    type: 'Daily Active Users',
                    todayCount: dau[dau.length - 1]?.y,
                    yesterdayCount: dau[dau.length - 2]?.y,
                    average: dau.length > 0 && getCountAverage(dau)
                },
                {
                    type: 'Monthly Active Users',
                    todayCount: mau[mau.length - 1]?.y,
                    yesterdayCount: mau[mau.length - 2]?.y,
                    average: getCountAverage(mau)
                },
                {
                    type: 'Headcount',
                    todayCount: headcount[headcount.length - 1]?.y,
                    yesterdayCount: headcount[headcount.length - 2]?.y,
                    average: getCountAverage(headcount)
                },
                {
                    type: 'User Activity',
                    todayCount: profileActivity[profileActivity.length - 1]?.y,
                    yesterdayCount: profileActivity[profileActivity.length - 2]?.y,
                    average: getCountAverage(profileActivity)
                },
                {
                    type: 'Percent Users on Latest Version with Pushes Enabled',
                    todayCount: percentUsersWithPushesEnabled[percentUsersWithPushesEnabled.length - 1]?.y,
                    yesterdayCount: percentUsersWithPushesEnabled[percentUsersWithPushesEnabled.length - 2]?.y,
                    average: getCountAverage(percentUsersWithPushesEnabled)
                },
            ]

            return {
                topDmUsersData,
                liveNowData,
                topDealsData,
                topEventsData,
                topThreadsData,
                topItemsData,
                mostActiveUsersData,
                pushNotificationsData,
                recentlyJoinedUsersData,
                mostActiveGroupsData,
                slimDashData,
                dau,
                mau,
                headcount,
                profileActivity,
                percentUsersWithPushesEnabled
            };
        } catch (err: any) {
            console.log('getTenantDashboardData', err);
            err.friendlyMessage = 'Error getting the tenant dashboard data. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    });

interface TenantDashboardState {
    liveNowData: DashboardCardData,
    mostActiveUsersData: DashboardCardData,
    topDmUsersData: DashboardCardData,
    topDealsData: DashboardCardData,
    topEventsData: DashboardCardData,
    topThreadsData: DashboardCardData,
    topItemsData: DashboardCardData,
    pushNotificationsData: DashboardCardData,
    recentlyJoinedUsersData: DashboardCardData,
    mostActiveGroupsData: DashboardCardData,
    slimDashData: Array<SlimDashData>,
    dau: Array<{x: Date, y: number}>,
    mau: Array<{x: Date, y: number}>,
    headcount: Array<{x: Date, y: number}>,
    profileActivity: Array<{x: Date, y: number}>,
    percentUsersWithPushesEnabled: Array<{x: Date, y: number}>,
    globalLifetimeStats: any
    timeSeriesChart: any
    isGettingTenantDashboard: boolean
    getTenantDashboardError?: IError

}

const initialState: TenantDashboardState = {
    liveNowData:  {title: '', subtitle: '', subtitles: [], data: null},
    globalLifetimeStats: {},
    timeSeriesChart: {dau: [], mau: [], headcount: []},
    mostActiveUsersData: {title: '', subtitle: '', subtitles: [], data: null},
    topDmUsersData: {title: '', subtitle: '', subtitles: [], data: null},
    topDealsData: {title: '', subtitle: '', subtitles: [], data: null},
    topEventsData: {title: '', subtitle: '', subtitles: [], data: null},
    topThreadsData: {title: '', subtitle: '', subtitles: [], data: null},
    topItemsData: {title: '', subtitle: '', subtitles: [], data: null},
    pushNotificationsData: {title: '', subtitle: '', subtitles: [], data: null},
    recentlyJoinedUsersData: {title: '', subtitle: '', subtitles: [], data: null},
    mostActiveGroupsData: {title: '', subtitle: '', subtitles: [], data: null},
    slimDashData: [],
    dau: [],
    mau: [],
    headcount: [],
    profileActivity: [],
    percentUsersWithPushesEnabled: [],
    isGettingTenantDashboard: false,
    getTenantDashboardError: undefined
}


export const tenantDashboardSlice = createSlice({
    name: 'tenantDashboardSlice',
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(getTenantDashboardData.pending, (state, action) => {
            state.getTenantDashboardError = undefined;
            state.isGettingTenantDashboard = true;
        });
        addCase(getTenantDashboardData.fulfilled, (state, action) => {
            state.dau = action.payload.dau;
            state.getTenantDashboardError = undefined;
            state.headcount = action.payload.headcount;
            state.isGettingTenantDashboard = false;
            state.liveNowData = action.payload.liveNowData;
            state.mau = action.payload.mau;
            state.mostActiveUsersData = action.payload.mostActiveUsersData;
            state.mostActiveGroupsData = action.payload.mostActiveGroupsData;
            state.percentUsersWithPushesEnabled = action.payload.percentUsersWithPushesEnabled;
            state.profileActivity = action.payload.profileActivity;
            state.pushNotificationsData = action.payload.pushNotificationsData;
            state.recentlyJoinedUsersData = action.payload.recentlyJoinedUsersData;
            state.slimDashData = action.payload.slimDashData;
            state.topDealsData = action.payload.topDealsData;
            state.topEventsData = action.payload.topEventsData;
            state.topThreadsData = action.payload.topThreadsData;
            state.topItemsData = action.payload.topItemsData;
            state.topDmUsersData = action.payload.topDmUsersData;
        });
        addCase(getTenantDashboardData.rejected, (state, action) => {
            state.getTenantDashboardError = action.error;
            state.isGettingTenantDashboard = false;
        });
    }
});

export default tenantDashboardSlice.reducer;
