import "./DetailStat.scss"

import * as React from "react";
import {useState} from "react";
import Modal from "../../../../../../components/Modal";
import ContentSummary from "../../../../../../components/ContentSummary";
type Props = {
    stat: any
}

const DetailStat: React.FC<Props> = ({stat}) => {
    const [showListModal, setShowListModal] = useState(false);

    const isClickable = stat.data?.length > 0;

    return (
        <dl
            className={`c-stat ${isClickable ? 'a-stat--clickable' : ''}`}
            onClick={isClickable ? () => setShowListModal(true) : null}
        >
            <dt className={'c-stat__label'}>{stat.name}</dt>
            <dd className={'c-stat__value'}>{stat.value}</dd>

            {isClickable && (
                <div className="c-stat__can-click">
                    Click to view list
                </div>
            )}

            <Modal
                declineButtonOnClick={() => {
                    console.log('click')
                    setShowListModal(false)
                }}
                declineButtonText="OK"
                show={showListModal}
                title={stat.name}
            >
                {stat.name === 'Posts Tapped' && (
                    <>
                        {stat.data.map((item) => {
                            if(!item || !item.post) {
                                return null;
                            }

                            return (
                                <div className="c-stat__summary-container" key={item.postId}>
                                    <ContentSummary
                                        post={item.post}
                                    />

                                    <div className="c-stat__count">
                                        {item.clickCount}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}

                {stat.name === 'Notifications Opened' && (
                    <>
                        {stat.data.map((item) => {
                            if(!item || !item.notification) {
                                return null;
                            }

                            return (
                                <div className = 'c-stat__summary-container'>
                                    <div className="c-content-summary">
                                        <div className="c-content-summary__text">
                                            <div className="c-content-summary__title">
                                                {item.notification.message}
                                            </div>

                                            {item.notification.user && (
                                                <div className="c-content-summary__description">
                                                    Opened by {item.notification.user.firstName} {item.notification.user.lastName}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="c-stat__count">
                                        {item.clickCount}
                                    </div>

                                </div>
                            );
                        })}
                    </>
                )}
            </Modal>

        </dl>
    );
}

export default DetailStat
