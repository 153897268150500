import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import IError from "../../types/IError";
import { RootState } from "../reducers";
import Sponsorship from "../../types/Sponsorship";
import SponsorshipsMetadata from "../../types/SponsorshipsMetadata";
import WalletCategory from "../../types/WalletCategory";

import { buildErrorObject } from "../../utils/errors";
import { generateSponsorship, generateSponsorshipsMetadata } from "../../utils/generators";
import PATHS, { buildQueryString } from "../../utils/paths";
import Request from "../../utils/request";


type DeleteSponsorshipProps = {
    sponsorship?: Sponsorship
}

export const deleteSponsorship = createAsyncThunk(
    'sponsorships/deleteSponsorship',
    async ({sponsorship}: DeleteSponsorshipProps, {dispatch, getState, rejectWithValue}) => {
        try {
            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.sponsorships.delete(sponsorship.walletSponsorshipId));
            return res;
        } catch(err) {
            console.log('getSponsorships error', err);
            let friendlyMessage = 'Error getting a list of sponsorships. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type GetSponsorshipsProps = {
    sponsorshipsMetadata?: SponsorshipsMetadata
    isUpdate?: boolean
}

export const getSponsorships = createAsyncThunk(
    'sponsorships/getSponsorships',
    async ({sponsorshipsMetadata, isUpdate}: GetSponsorshipsProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!sponsorshipsMetadata) {
                sponsorshipsMetadata = clone((getState() as RootState).sponsorships.sponsorshipsMetadata);
            } else {
                sponsorshipsMetadata = {...sponsorshipsMetadata}
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.sponsorships.get(buildQueryString(sponsorshipsMetadata)));
            let sponsorships = res.data.data.items;
            sponsorshipsMetadata.total = res.data.data.meta.total;
            return { sponsorships, sponsorshipsMetadata };
        } catch(err) {
            console.log('getSponsorships error', err);
            let friendlyMessage = 'Error getting a list of sponsorships. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type GetAllWalletCategoriesProps = {
    tenantId?: number
}

export const getAllWalletCategories = createAsyncThunk(
    'sponsorships/getAllWalletCategories',
    async ({tenantId}: GetAllWalletCategoriesProps, {dispatch, getState, rejectWithValue}) => {
        try {
            const metadata = {
                page_num: 0,
                page_size: 10000,
                order: 'asc',
                sort: 'value',
            };

            let path = PATHS.wallet.getCategories(buildQueryString(metadata));

            if(tenantId) {
                path = PATHS.wallet.getCategoriesForTenant(tenantId, buildQueryString(metadata));
            }

            const res = await new Request((getState() as RootState).auth.token).get(path);
            return res.data.data.items;
        } catch(err) {
            console.log('getAllWalletCategories error', err);
            let friendlyMessage = 'Error getting a complete list of wallet categories. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

export const saveSponsorship = createAsyncThunk(
    'sponsorships/saveSponsorship',
    async (_: void, {dispatch, getState, rejectWithValue}) => {
        try {
            const sponsorship = clone((getState() as RootState).sponsorships.sponsorship);
            const request = new Request((getState() as RootState).auth.token);

            let path = PATHS.sponsorships.save();
            let reqFunc = request.post;

            if(sponsorship.walletSponsorshipId) {
                path = PATHS.sponsorships.update(sponsorship.walletSponsorshipId);
                reqFunc = request.put;
            }

            const res = await reqFunc(path, sponsorship);
            return res;
        } catch(err) {
            let friendlyMessage = 'Error saving your sponsorship. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

interface SponsorshipsState {
    deleteSponsorshipError: IError
    getAllWalletCategoriesError: IError
    getSponsorshipsError: IError
    isDeletingSponsorship: boolean
    isGettingAllWalletCategories: boolean
    isGettingSponsorships: boolean
    isSavingSponsorship: boolean
    saveSponsorshipError: IError
    sponsorship: Sponsorship
    sponsorships: Array<Sponsorship>
    sponsorshipsMetadata: SponsorshipsMetadata
    walletCategories: Array<WalletCategory>
}

const initialState: SponsorshipsState = {
    sponsorship: generateSponsorship(),
    sponsorships: [],
    sponsorshipsMetadata: generateSponsorshipsMetadata(),
    walletCategories: [],
    isDeletingSponsorship: false,
    isGettingAllWalletCategories: false,
    isGettingSponsorships: false,
    isSavingSponsorship: false,
    deleteSponsorshipError: undefined,
    getAllWalletCategoriesError: undefined,
    getSponsorshipsError: undefined,
    saveSponsorshipError: undefined,
};

export const sponsorshipsSlice = createSlice({
    name: 'sponsorships',
    initialState,
    reducers: {
        clearSponsorship: (state) => {
            state.sponsorship = generateSponsorship();
            state.saveSponsorshipError = undefined;
        },
        clearSponsorshipsMetadata: (state) => {
            state.sponsorshipsMetadata = generateSponsorshipsMetadata();
        },
        setSponsorship: (state, action) => {
            state.sponsorship = action.payload;
        }
    },
    extraReducers: ({addCase}) => {
        addCase(deleteSponsorship.pending, (state, action) => {
            state.deleteSponsorshipError = undefined;
            state.isDeletingSponsorship = true;
        });
        addCase(deleteSponsorship.fulfilled, (state, action) => {
            state.isDeletingSponsorship = false;
        });
        addCase(deleteSponsorship.rejected, (state, action) => {
            state.deleteSponsorshipError = action.payload;
            state.isDeletingSponsorship = false;
        });

        addCase(getAllWalletCategories.pending, (state, action) => {
            state.getAllWalletCategoriesError = undefined;
            state.isGettingAllWalletCategories = true;
        });
        addCase(getAllWalletCategories.fulfilled, (state, action) => {
            state.walletCategories = action.payload;
            state.isGettingAllWalletCategories = false;
        });
        addCase(getAllWalletCategories.rejected, (state, action) => {
            state.getAllWalletCategoriesError = action.payload;
            state.isGettingAllWalletCategories = false;
        });

        addCase(getSponsorships.pending, (state, action) => {
            state.getSponsorshipsError = undefined;
            state.isGettingSponsorships = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.sponsorshipsMetadata) {
                state.sponsorshipsMetadata = action.meta.arg.sponsorshipsMetadata;
            }
        });
        addCase(getSponsorships.fulfilled, (state, action) => {
            state.sponsorships = action.payload.sponsorships;
            state.sponsorshipsMetadata = action.payload.sponsorshipsMetadata;
            state.isGettingSponsorships = false;
        });
        addCase(getSponsorships.rejected, (state, action) => {
            state.getSponsorshipsError = action.payload;
            state.isGettingSponsorships = false;
        });

        addCase(saveSponsorship.pending, (state, action) => {
            state.saveSponsorshipError = undefined;
            state.isSavingSponsorship = true;
        });
        addCase(saveSponsorship.fulfilled, (state, action) => {
            state.sponsorship = generateSponsorship();
            state.isSavingSponsorship = false;
        });
        addCase(saveSponsorship.rejected, (state, action) => {
            state.saveSponsorshipError = action.payload;
            state.isSavingSponsorship = false;
        });
    }
});

export const { clearSponsorship, clearSponsorshipsMetadata, setSponsorship } = sponsorshipsSlice.actions;

export default sponsorshipsSlice.reducer;
