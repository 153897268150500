import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

import Profile from "../../../../../types/Profile";

import { assignTags, AssignTagsProps, getAllTags } from "../../../../../store/slices/students";
import { useAppDispatch } from '../../../../../store';
import { useTypedSelector } from '../../../../../store/reducers';

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Button, { ButtonThemes } from "../../../../../components/Button";
import ErrorInline from "../../../../Errors/ErrorInline";
import Form from '../../../../../components/Form';
import FormRow from "../../../../../components/FormRow";
import Tag from "../../../../../types/Tag";

type Props = {
    onCancel: Function
    onSuccess?: Function
    profile: Profile
};

const ManageStudentTagsForm: React.FC<Props> = ({
    onCancel,
    onSuccess,
    profile,
}) => {
    const dispatch = useAppDispatch();

    const [tagOptions, setTagOptions] = useState([]);
    const [selectedStudentTags, setSelectedStudentTags] = useState([]);
    const {allTags, isGettingAllTags, isAssigningStudentTags, assignStudentTagsError, getAllTagsError} = useTypedSelector(store => store.students);

    useEffect(() => {
        dispatch(getAllTags({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTagOptions(parseTags(allTags));
    }, [allTags]);

    useEffect(() => {
        if(!profile) {
            return;
        }

        console.log('profile in useEffect', profile);

        let tags: Array<Tag>;
        if(profile.admitProfile) {
            tags = profile.admitProfile.tags?.map(tag => tag.tag);
        } else {
            tags = profile.tags?.map(tag => tag.tag);
        }
        setSelectedStudentTags(tags ? parseTags(tags) : []);
    }, [profile?.tags]);

    const parseTags = (tags: Array<Tag>) => {
        return tags.map(tag => {
            return {value: tag.tagId, label: tag.text}
        });
    }

    const handleSubmit = async () => {
        const tags = selectedStudentTags.filter(t => typeof(t.value) === 'number').map(t => t.value);
        const newTags = selectedStudentTags.filter(t => typeof(t.value) === 'string').map(t => t.value);
        try {
            let args: AssignTagsProps = {newTags, tags, profileId: profile.profileId};
            if(profile.admitProfile) {
                args.admitProfileId = profile.admitProfile.admitProfileId;
            }
            await dispatch(assignTags(args)).unwrap();
            onSuccess();
        } catch (err) {
            console.log('ManageStudentTagsForm handleSubmit err', err);
        }
    };

    const handleChange = (newValue) => {
        setSelectedStudentTags(newValue);
    };

    return (
        <Form className="tag-form">
            <FormRow className={`tag-form__select ${isGettingAllTags && 'tag-form__select--loading'}`}>
                <CreatableSelect
                    isMulti
                    name="tags"
                    options={tagOptions}
                    value={selectedStudentTags}
                    onChange={handleChange}
                />
                {isGettingAllTags && (
                    <div className="tag-form__select__loader">
                        <ActivityIndicator />
                    </div>
                )}
            </FormRow>
            <div className="c-modal__button-wrapper">
                <Button
                    className="c-modal__confirm-button"
                    onClick={(event) => {
                        event.preventDefault();
                        handleSubmit();
                    }}
                    showActivityIndicator={isAssigningStudentTags}
                >
                    Save
                </Button>
                <Button
                    className="c-modal__decline-button"
                    onClick={onCancel}
                    theme={ButtonThemes.Secondary}
                >
                    Cancel
                </Button>
            </div>

            {getAllTagsError && <ErrorInline error={getAllTagsError} />}
            {assignStudentTagsError && <ErrorInline error={assignStudentTagsError} />}

        </Form>
    );
};

export default ManageStudentTagsForm;
