import Role from "../types/Role";

import { CAPABILITIES, userCan } from "./roles";
import { isArrayNullOrEmpty } from "./utils";
import { LOCALSTORAGE } from "./constants";

export const determinePathAfterLogin = (roles: Array<Role>) => {
    if(isArrayNullOrEmpty(roles)) {
        return '/auth/logout';
    }

    // Check if the user tried to access a page before logging in
    const redirectURL = localStorage.getItem(LOCALSTORAGE.REDIRECT_URL);
    if (redirectURL) {
        localStorage.removeItem(LOCALSTORAGE.REDIRECT_URL);
        return redirectURL;
    }

    if(userCan(CAPABILITIES.VIEW_ENGINEERING)) {
        return '/engineering';
    }
    if(userCan(CAPABILITIES.VIEW_SUPER_ADMIN)) {
        return '/schools/all/dashboard';
    } else {
        // not super admin
        if(roles.length > 1) {
            //more than one role, show list of schools
            return '/schools/list'
        } else {
            if(userCan(CAPABILITIES.VIEW_SCHOOL_DASHBOARD)) {
                //only one school, user can view dashboard
                return `/school/${roles[0].tenantId}/student/dashboard`
            } else {
                //only one school, user can't view dashboard
                return `/school/${roles[0].tenantId}/student/feed`
            }
        }
    }
};

export const setLoginRedirectURL = () => {
    try {
        const fullURL = new URL(window.location.href);
        const strippedURL = fullURL.pathname + fullURL.search + fullURL.hash;

        if (strippedURL === '/') {
            return;
        }

        localStorage.setItem(LOCALSTORAGE.REDIRECT_URL, strippedURL);
    } catch (e) {
        console.log('Error setting redirect URL', e);
    }
}
