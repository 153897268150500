import React, { useEffect, useRef, useState } from 'react';
import clone from "clone";
import { validated } from "react-custom-validation";
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router';

import IntegrationEvent from "../../../../types/IntegrationEvent";
import Validation from "../../../../types/Validation";

import { isDateAfterOtherDate, isRequired } from '../../../../utils/validations';
import { handleDateChange, handleTextChange } from '../../../../utils/handle-changes';

import {
    deleteIntegrationEvent,
    getIntegrationEvents,
    publishIntegrationEvent,
    setIntegrationEvent
} from '../../../../store/slices/integrations';
import { useTypedSelector } from '../../../../store/reducers';
import { useAppDispatch } from '../../../../store';

import A from '../../../../components/A';
import Button, { ButtonThemes, ButtonTypes } from '../../../../components/Button';
import ButtonRow from '../../../../components/ButtonRow';
import Datepicker from '../../../../components/Datepicker';
import FileUpload from '../../../../components/FileUpload';
import Form from '../../../../components/Form';
import FormColumn from '../../../../components/FormColumn';
import FormRow from '../../../../components/FormRow';
import FormValidationMessage from '../../../../components/FormValidationMessage';
import H4 from "../../../../components/H4";
import LocationSearch from '../../../../components/LocationSearch';
import Modal from '../../../../components/Modal';
import ProfileTypesSelector from '../../../../components/ProfileTypesSelector';
import Textbox from '../../../../components/Textbox';
import VisibilityCard from '../../../../components/VisibilityCard';

type Props = {
    close?: () => void
    post: IntegrationEvent
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        artifactStagingId: Validation
        description: Validation
        endAt: Validation
        startAt: Validation
        title: Validation
    }
}

const EditIntegrationEventForm: React.FC<Props> = ({
    close,
    post,
    $field,
    $fieldEvent,
    $submit,
    $validation
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const match = useRouteMatch();

    const locationRef = useRef<any>(null);

    const [isUploadingMainArtifact, setIsUploadingMainArtifact] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const { isDeletingIntegrationEvent, isPublishingIntegrationEvent } = useTypedSelector(store => store.integrations);

    useEffect(() => {
        if (post.artifactStaging && post.artifactStagingId !== post.artifactStaging.artifactStagingId) {
            handleChange('artifactStagingId', post.artifactStaging.artifactStagingId);
        }

        handleChange(`${match.params.profileType}Visibility`, true);

        return () => {
            setTimeout(() => $fieldEvent('reset'), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name: string, value: any) => {
        let clonedEvent = clone(post);
        if(name === 'publishAt' && value) {
            clonedEvent.publishAt = value;
            clonedEvent.status = 'X';
        } else if(name === 'status') {
            clonedEvent.status = value;

            if(value === 'W') {
                delete clonedEvent.publishAt;
            }
        } else {
            clonedEvent[name] = value;
        }
        dispatch(setIntegrationEvent(clonedEvent));
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteIntegrationEvent({})).unwrap();
            $fieldEvent('reset');
            history.push(`/school/${match.params.schoolId}/${match.params.profileType}/integrations/events/list`);
        } catch (err) {
            console.log('EditIntegrationEventForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleSave = async (isValid: boolean) => {
        if(!isValid || isPublishingIntegrationEvent) {
            return;
        }

        try {
            await dispatch(publishIntegrationEvent({})).unwrap();
            setShowSuccessModal(true);
            if(locationRef?.current?.refs?.input) {
                locationRef.current.refs.input.value = '';
            }
            $fieldEvent('reset');
        } catch(err) {
            console.log('CreateEditDealForm handleSave err', err);
        }
    }

    const onUploadStart = () => {
        setIsUploadingMainArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        console.log(file, artifactId);
        if (artifactId) {
            handleChange('artifactStagingId', artifactId);
        }
        setIsUploadingMainArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingMainArtifact(false);
    };

    const onDelete = () => {
        let clonedEvent = clone(post);
        delete clonedEvent.artifactStaging;
        delete clonedEvent.artifactStagingId;
        dispatch(setIntegrationEvent(clonedEvent));
    };

    return (
        <Form className="content-form">
            <H4>
                Publish Event
            </H4>

            <FormRow>
                <FileUpload
                    disabled={isUploadingMainArtifact}
                    currentArtifact={post.artifactStaging || null}
                    name="artifactStagingId"
                    onDelete={onDelete}
                    onStart={onUploadStart}
                    onSuccess={onUploadSuccess}
                    onFailure={onUploadFailure}
                    uploadType="integration"
                />

                {$validation.artifactStagingId.isValid === false && $validation.artifactStagingId.show === true ? (
                    <FormValidationMessage>
                        {$validation.artifactStagingId.error.reason}
                    </FormValidationMessage>
                ) : null}
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingMainArtifact}
                    id="txtTitle"
                    label="Title"
                    name="title"
                    required
                    type="text"
                    validation={$validation.title}
                    value={post.title || ''}
                    {...$field('title', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingMainArtifact}
                    id="txtDescription"
                    label="Description"
                    name="description"
                    required
                    type="textarea"
                    validation={$validation.description}
                    value={post.description || ''}
                    {...$field('description', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            {post.url && (
                <FormRow>
                    <A href={post.url} target="_blank">View Event Details on {post.integrationSource?.displayName || 'the source website'}</A>
                </FormRow>
            )}

            <FormRow columnCount={2}>
                <FormColumn>
                    <Datepicker
                        dateFormat="yyyy-MM-dd h:mm a"
                        disabled={isUploadingMainArtifact}
                        id="dateStartAt"
                        label="Start time"
                        minDate={new Date()}
                        name="startAt"
                        placeholderText="mm-dd-yyyy  00:00 pm"
                        required
                        selected={post.startAt || null}
                        showMonthDropdown
                        showTimeSelect
                        showYearDropdown
                        timeIntervals={15}
                        validation={$validation.startAt}
                        {...$field('startAt', (newDate) => handleDateChange(handleChange, 'startAt', null, moment(newDate).format()))}
                    />
                </FormColumn>

                <FormColumn>
                    <Datepicker
                        dateFormat="yyyy-MM-dd h:mm a"
                        disabled={isUploadingMainArtifact}
                        id="dateEndAt"
                        label="End time"
                        minDate={post.startAt}
                        name="endAt"
                        placeholderText="mm-dd-yyyy  00:00 pm"
                        selected={post.endAt || null}
                        showMonthDropdown
                        showTimeSelect
                        showYearDropdown
                        timeIntervals={15}
                        validation={$validation.endAt}
                        {...$field('endAt', (newDate) => handleDateChange(handleChange, 'endAt', null, moment(newDate).format()))}
                    />
                </FormColumn>
            </FormRow>

            <FormRow>
                <LocationSearch
                    disabled={isUploadingMainArtifact}
                    label="Location"
                    name="location"
                    onPlaceSelected={(location) => handleChange('location', location)}
                    selectedLocation={post.location}
                    thisRef={locationRef}
                />
            </FormRow>

            <FormRow>
                <ProfileTypesSelector item={post} onChange={handleChange} />
            </FormRow>

            <VisibilityCard
                handleChange={handleChange}
                post={post}
            />

            <ButtonRow>
                <Button
                    disabled={isUploadingMainArtifact}
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                    showActivityIndicator={isPublishingIntegrationEvent}
                    type={ButtonTypes.Submit}
                >
                    Publish Event
                </Button>

                {post.postStagingId && (
                    <Button
                        className="destructive"
                        disabled={isUploadingMainArtifact}
                        onClick={() => setShowDeleteConfirmationModal(true)}
                        theme={ButtonThemes.Link}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <Modal
                confirmButtonOnClick={() => {
                    dispatch(getIntegrationEvents({}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                confirmButtonText="OK"
                show={showSuccessModal}
                title="Success!"
            >
                <p>
                    Your event has been saved.
                </p>
                <p>
                    It may take a minute for your event to show in feeds and lists.
                </p>
            </Modal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingIntegrationEvent}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this event?
            </Modal>
        </Form>
    );
};

function editIntegrationEventFormValidationConfig(props) {
    let { artifactStagingId, description, endAt, startAt, title }: IntegrationEvent = props.post;

    return {
        fields: ['artifactStagingId', 'description', 'endAt', 'startAt', 'title'],
        validations: {
            artifactStagingId: [
                [isRequired, artifactStagingId]
            ],
            description: [
                [isRequired, description]
            ],
            endAt: [
                [isDateAfterOtherDate, endAt, startAt]
            ],
            startAt: [
                [isRequired, startAt]
            ],
            title: [
                [isRequired, title]
            ]
        }
    }
}
export default validated(editIntegrationEventFormValidationConfig)(EditIntegrationEventForm);
