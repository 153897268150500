import './FeedGroup.scss';

import React from 'react';
import classNames from "classnames";

import {useHistory, useRouteMatch} from "react-router";

import Group from "../../../types/Group";

import buildImageSrc from "../../../utils/buildImageSrc";
import { isObjectNullOrEmpty } from "../../../utils/utils";
import { PrivacyTypes } from "../../../utils/enums";

import Avatar from "../../Avatar";
import Card from "../../Card";
import GroupTableMoreMenu from "../../../containers/Schools/School/SchoolGroups/ListGroups/GroupTableMoreMenu";
import H4 from "../../H4";
import Icon from "../../Icon";
import ParagraphsFromNewlines from "../../ParagraphsFromNewlines";

type Props = {
    className?: string
    getAfterModerate?: () => void
    onEdit?: (shouldShow: boolean) => void
    group: Group
}

const FeedGroup: React.FC<Props> = ({
    className,
    getAfterModerate,
    onEdit,
    group,
}) => {
    const match = useRouteMatch();
    const history = useHistory();

    const classes = classNames(
        'c-feed-group c-feed-item',
        className,
        {
            'a-feed-item--flagged': group.flagged,
            'a-feed-item--removed-by-mods': group.removedByMods,
            'a-feed-item--super-approved': group.superApproval,
        }
    );

    return (
        <Card className={classes}>
            <header className="c-feed-group__header">
                <div
                    className="c-feed-group__image"
                    style={group.artifact ? {backgroundImage: `url(${buildImageSrc(group.artifact, '667')})`} : {backgroundImage: 'url("/img/abuzz-group-placeholder.svg")'}}
                    onClick={() => history.push(`/school/${match?.params?.schoolId}/group/${group.forumTopicId}/activity`)}
                />

                <div className="c-feed-group__header--right">
                    <div className="c-feed-group__privacy-type">
                        <Icon type={group.privacyType === PrivacyTypes.Public ? 'unlocked' : 'locked'} />

                        <div className="c-feed-group__privacy-type-text">
                            {group.privacyType}
                        </div>
                    </div>

                    {onEdit && !group.removedByMods && (
                        <GroupTableMoreMenu
                            getAfterDelete={getAfterModerate}
                            item={group}
                            setShowNewContentModal={onEdit}
                        />
                    )}
                </div>

                <H4>
                    <div role="link"  onClick={() => history.push(`/school/${match?.params?.schoolId}/group/${group.forumTopicId}/activity`)} className="c-feed-group__title">
                        {group.name}
                    </div>
                </H4>
            </header>

            <div className="c-feed-group__content">
                <div className="c-feed-group__data c-feed-post__data">
                    <ParagraphsFromNewlines
                        className="c-feed-group__description c-feed-post__description"
                        text={group.description}
                    />
                </div>

                {!isObjectNullOrEmpty(group.ownerProfile) && (
                    <div className="c-feed-group__profile" role="link" onClick={() => history.push(`/school/${match?.params?.schoolId}/students/${group.ownerProfile.profileId}/posts`)}>
                        <Avatar artifact={group.ownerProfile.artifact} />

                        <div className="c-feed-thread__data c-feed-post__data">
                            <div className="c-feed-group__name" role="link"  onClick={() => history.push(`/school/${match?.params?.schoolId}/students/${group.ownerProfile.profileId}/posts`)}>
                                {group.ownerProfile.firstName} {group.ownerProfile.lastName}
                            </div>

                            {group.ownerProfile.position != null && (
                                <div className="c-feed-group__position">
                                    {group.ownerProfile.position}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default FeedGroup;
