import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import Credentials from "../../types/Credentials";
import IError from "../../types/IError";
import Role from "../../types/Role";
import Tenant from "../../types/Tenant";
import User from "../../types/User";

import Request from "../../utils/request";
import PATHS from "../../utils/paths";
import { buildErrorObject } from "../../utils/errors";
import { createElementsObject } from "../../utils/elements";
import { LOCALSTORAGE } from "../../utils/constants";
import { generateCredentials } from "../../utils/generators";

import { RootState } from '../reducers';
import { setUser } from "./users";
import { addError } from "./errors";
import { ROLES } from '../../utils/roles';
import Profile from '../../types/Profile';
import { setActiveGlobalProfile } from './globalProfiles';


const auditUserRolesForSuperAdminAndActiveGlobalProfile = (user: User) => {
    const roles = user.roles;
    let activeGlobalProfile: Profile | undefined = undefined;

    roles.forEach(r => {
        if (r.type === ROLES.SUPER_ADMIN && r.activeGlobalProfile) {
            activeGlobalProfile = r.activeGlobalProfile;
        }
    });

    return activeGlobalProfile;
}

type AcceptInvitationProps = {
    token: string
}

export const acceptInvitation = createAsyncThunk(
    'auth/acceptInvitation',
    async ({token}: AcceptInvitationProps, {dispatch, getState}) => {
        try {
            let { auth: { credentials }, users: { user } } = (getState() as RootState);

            user = clone(user);
            user.token = token;
            user.password = credentials.password;

            const res = await new Request().put(PATHS.auth.acceptInvitation(), user);
            const profile = res.data.data;

            localStorage.setItem(LOCALSTORAGE.ID_TOKEN, profile.token);
            localStorage.setItem(LOCALSTORAGE.ID_USERDATA, JSON.stringify(profile));
            dispatch(setToken(profile.token));
            return profile;
        } catch(err) {
            console.log('acceptInvitation', err.response);
            err.friendlyMessage = `There was an error accepting your invitation. ${err?.response?.data?.error ? err.response.data.error : ''} Please try again.`;
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetInvitationDetailsProps = {
    token: string
}

export const getInvitationDetails = createAsyncThunk(
    'auth/getInvitationDetails',
    async ({token}: GetInvitationDetailsProps, {dispatch, getState}) => {
        try {
            const res = await new Request().get(PATHS.auth.getInvitationDetails(token));
            dispatch(setUser(res.data.data));
            return res;
        } catch(err) {
            console.log('getLoggedInUserError', err);
            err.friendlyMessage = 'There was a problem with your invitation. Please contact your administrator.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetLoggedInUserProps = {
    token?: string
}

export const getLoggedInUser = createAsyncThunk(
    'auth/getLoggedInUser',
    async ({token}: GetLoggedInUserProps = {}, {dispatch, getState}) => {
        if (!token) {
            token = (getState() as RootState).auth.token;
        }

        // Start fetching user info from server
        dispatch(getLoggedInUserFromServer({token}))

        // While that's happening, get data from localStorage
        try {
            const profile = await localStorage.getItem(LOCALSTORAGE.ID_USERDATA);
            return JSON.parse(profile);
        } catch(err) {
            console.log('getLoggedInUserError', err);
            err.friendlyMessage = 'Your session has expired. Please log in again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

const getLoggedInUserFromServer = createAsyncThunk(
    'auth/getLoggedInUserFromServer',
    async ({token}: GetLoggedInUserProps = {}, {dispatch, getState}) => {
        if (!token) {
            token = (getState() as RootState).auth.token;
        }

        try {
            const res = await new Request(token).get(PATHS.auth.getLoggedInUser());
            const profile = res.data.data;
            localStorage.setItem(LOCALSTORAGE.ID_USERDATA, JSON.stringify(profile));
            const activeGlobalProfile = auditUserRolesForSuperAdminAndActiveGlobalProfile(profile);
            if (activeGlobalProfile) {
                dispatch(setActiveGlobalProfile(activeGlobalProfile));
            }
            return profile;
        } catch(err) {
            console.log('getLoggedInUserError', err);
            err.friendlyMessage = 'Your session has expired. Please log in again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetTenantDetailsProps = {
    tenantId: number
    token?: string
}

export const getTenantDetails = createAsyncThunk(
    'auth/getTenantDetails',
    async ({tenantId, token}: GetTenantDetailsProps, {dispatch, getState}) => {
        if (!token) {
            token = (getState() as RootState).auth.token;
        }

        try {
            const request = new Request(token);

            const res = await Promise.all([
                request.get(PATHS.auth.getElements(tenantId)),
                request.get(PATHS.auth.getInterests(tenantId))
            ]);

            const elements = createElementsObject(res[0].data.data);

            return {elements, interests: res[1].data.data};
        } catch(err) {
            dispatch(addError(err));
            throw err;
        }
    }
);

export const login = createAsyncThunk(
    'auth/login',
    async (_, {dispatch, getState, rejectWithValue}) => {
        try {
            const { credentials } = (getState() as RootState).auth;

            const res = await new Request().post(PATHS.auth.login(), {...credentials, platform: 'web'});
            const profile = res.data.data;

            const activeGlobalProfile = auditUserRolesForSuperAdminAndActiveGlobalProfile(profile);
            if (activeGlobalProfile) {
                dispatch(setActiveGlobalProfile(activeGlobalProfile));
            }

            localStorage.setItem(LOCALSTORAGE.ID_TOKEN, profile.token);
            localStorage.setItem(LOCALSTORAGE.ID_USERDATA, JSON.stringify(profile));
            dispatch(setToken(profile.token));
            return profile;
        } catch(err) {
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                // If we get a 401 response, display a hard-coded friendly message, otherwise show the server error message
                friendlyMessage: err.response?.status === 401 ? 'Incorrect email or password' : null
            });
            return rejectWithValue(errorObject);
        }
    }
);

type ResetPasswordProps = {
    token: string
}

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({token}: ResetPasswordProps, {dispatch, getState}) => {
        try {
            const { resetPassword1: password } = (getState() as RootState).auth.credentials;

            const res = await new Request().post(PATHS.auth.resetPassword(), {password, token, platform: 'web'});

            return res;
        } catch(err) {
            console.log(err);
            err.friendlyMessage = 'Unable to reset password. Please try again.';
            throw err;
        }
    }
);

export const sendForgotPasswordEmail = createAsyncThunk(
    'auth/sendForgotPasswordEmail',
    async (_, {dispatch, getState}) => {
        try {
            const { email } = (getState() as RootState).auth.credentials;

            const res = await new Request().post(PATHS.auth.forgotPassword(), {email});

            return res;
        } catch(err) {
            console.log(err);
            err.friendlyMessage = 'Unable to reset password. Please try again.';
            throw err;
        }
    }
);

type SetTenantProps = {
    forceUpdate?: boolean
    name: string
    tenantId: number
}

export const setTenant = createAsyncThunk(
    'auth/setTenant',
    async ({ forceUpdate, name, tenantId }: SetTenantProps, {dispatch, getState}) => {
        const { activeTenant } = (getState() as RootState).auth;
        tenantId = parseInt(tenantId as any);

        // If the active tenant has a name, and has the same ID as the one
        // we're trying to set, bail.
        if (activeTenant.name && activeTenant.tenantId === tenantId && !forceUpdate) {
            return;
        }

        // If we only have an ID, run setTenantById to fetch tenant info
        // from the server first.
        if (!name) {
            dispatch(setTenantById({tenantId}));
            return;
        }

        dispatch(getTenantDetails({tenantId}));
        return;
    }
);

type SetTenantByIdProps = {
    tenantId: number
    token?: string
}

export const setTenantById = createAsyncThunk(
    'auth/setTenantById',
    async ({tenantId, token}: SetTenantByIdProps, {dispatch, getState}) => {
        if (!token) {
            token = (getState() as RootState).auth.token;
        }

        try {
            const res = await new Request(token).get(PATHS.schools.getSingle(tenantId));
            let tenant = res.data.data;
            dispatch(setActiveTenant(tenant));
            return tenant;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }
);

export interface AuthState {
    acceptInvitationError: IError
    activeTenant?: Tenant
    credentials: Credentials
    profile: User
    roles: Array<Role>
    tenants: Array<Tenant>
    elements: object
    interests: Array<object>
    token?: string
    isAcceptingInvitation: boolean
    isGettingInvitationDetails: boolean
    isGettingLoggedInUser: boolean
    isGettingLoggedInUserFromServer: boolean
    isGettingTenantDetails: boolean
    isLoggingIn: boolean
    isLoggingOut: boolean
    isResettingPassword: boolean
    isSendingForgotPasswordEmail: boolean
    getInvitationDetailsError: IError
    getLoggedInUserError?: IError
    getLoggedInUserFromServerError?: IError
    getTenantDetailsError?: IError
    loginError?: IError
    loggedInUserError?: IError
    logoutError?: IError
    resetPasswordError?: IError
    sendForgotPasswordEmailError?: IError
}

const initialState: AuthState = {
    // activeTenant: undefined,
    credentials: generateCredentials(),
    profile: undefined,
    roles: [],
    tenants: [],
    elements: {},
    interests: [],
    token: null,
    isAcceptingInvitation: false,
    isGettingInvitationDetails: false,
    isGettingLoggedInUser: false,
    isGettingLoggedInUserFromServer: false,
    isGettingTenantDetails: false,
    isLoggingIn: false,
    isLoggingOut: false,
    isResettingPassword: false,
    isSendingForgotPasswordEmail: false,
    acceptInvitationError: undefined,
    getInvitationDetailsError: undefined,
    getLoggedInUserError: undefined,
    getTenantDetailsError: undefined,
    loginError: undefined,
    loggedInUserError: undefined,
    logoutError: undefined,
    resetPasswordError: undefined,
    sendForgotPasswordEmailError: undefined,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearCredentials: (state) => {
            state.credentials = generateCredentials();
        },
        resetGlobalState: (state) => {
            state = clone(initialState);
        },
        setActiveTenant: (state, action) => {
            state.activeTenant = action.payload;
        },
        setCredentials: (state, action) => {
            state.credentials = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        }
    },
    extraReducers: ({addCase}) => {
        addCase(acceptInvitation.pending, (state) => {
            state.acceptInvitationError = undefined;
            state.isAcceptingInvitation = true;
        });
        addCase(acceptInvitation.fulfilled, (state, action) => {
            state.isAcceptingInvitation = false;
            state.credentials = generateCredentials();
            state.profile = action.payload.user;
            state.roles = action.payload.roles;
        });
        addCase(acceptInvitation.rejected, (state, action) => {
            state.acceptInvitationError = action.error as IError;
            state.isAcceptingInvitation = false;
        });

        addCase(getInvitationDetails.pending, (state) => {
            state.getInvitationDetailsError = undefined;
            state.isGettingInvitationDetails = true;
        });
        addCase(getInvitationDetails.fulfilled, (state, action) => {
            state.isGettingInvitationDetails = false;
        });
        addCase(getInvitationDetails.rejected, (state, action) => {
            state.getInvitationDetailsError = action.error as IError;
            state.isGettingInvitationDetails = false;
        });

        addCase(getLoggedInUser.pending, (state) => {
            state.getLoggedInUserError = undefined;
            state.isGettingLoggedInUser = true;
        });
        addCase(getLoggedInUser.fulfilled, (state, action) => {
            state.profile = action.payload.user;
            state.roles = action.payload.roles;
            state.isGettingLoggedInUser = false;
        });
        addCase(getLoggedInUser.rejected, (state, action) => {
            state.getLoggedInUserError = action.error as IError;
            state.isGettingLoggedInUser = false;
        });

        addCase(getLoggedInUserFromServer.pending, (state) => {
            state.getLoggedInUserFromServerError = undefined;
            state.isGettingLoggedInUserFromServer = true;
        });
        addCase(getLoggedInUserFromServer.fulfilled, (state, action) => {
            state.profile = action.payload.user;
            state.roles = action.payload.roles;
            state.isGettingLoggedInUserFromServer = false;
        });
        addCase(getLoggedInUserFromServer.rejected, (state, action) => {
            state.getLoggedInUserFromServerError = action.error as IError;
            state.isGettingLoggedInUserFromServer = false;
        });

        addCase(getTenantDetails.pending, (state) => {
            state.getTenantDetailsError = undefined;
            state.isGettingTenantDetails = true;
        });
        addCase(getTenantDetails.fulfilled, (state, action) => {
            state.elements = action.payload.elements;
            state.interests = action.payload.interests;
            state.isGettingTenantDetails = false;
        });
        addCase(getTenantDetails.rejected, (state, action) => {
            state.getTenantDetailsError = action.error as IError;
            state.isGettingTenantDetails = false;
        });

        addCase(login.pending, (state) => {
            state.loginError = undefined;
            state.isLoggingIn = true;
        });
        addCase(login.fulfilled, (state, action) => {
            state.profile = action.payload.user;
            state.roles = action.payload.roles;
            state.credentials = generateCredentials();
            state.isLoggingIn = false;
        });
        addCase(login.rejected, (state, action) => {
            state.loginError = action.payload as IError;
            state.isLoggingIn = false;
        });

        addCase(resetPassword.pending, (state) => {
            state.resetPasswordError = undefined;
            state.isResettingPassword = true;
        });
        addCase(resetPassword.fulfilled, (state, action) => {
            let clonedCredentials = clone(state.credentials);
            delete clonedCredentials.resetPassword;
            delete clonedCredentials.resetPassword2;
            state.credentials = clonedCredentials;
            state.isResettingPassword = false;
        });
        addCase(resetPassword.rejected, (state, action) => {
            state.resetPasswordError = action.error as IError;
            state.isResettingPassword = false;
        });

        addCase(sendForgotPasswordEmail.pending, (state) => {
            state.sendForgotPasswordEmailError = undefined;
            state.isSendingForgotPasswordEmail = true;
        });
        addCase(sendForgotPasswordEmail.fulfilled, (state, action) => {
            state.isSendingForgotPasswordEmail = false;
        });
        addCase(sendForgotPasswordEmail.rejected, (state, action) => {
            state.sendForgotPasswordEmailError = action.error as IError;
            state.isSendingForgotPasswordEmail = false;
        });
    }
});

export const { clearCredentials, resetGlobalState, setActiveTenant, setCredentials, setToken } = authSlice.actions;

export default authSlice.reducer;
