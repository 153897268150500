import './Form.scss';

import React from 'react';

type Props = {
    children: React.ReactNode
    className?: string
}

const Form: React.FC<Props> = ({
    children,
    className = ''
}) => (
    <form className={`c-form ${className}`} onSubmit={() => false}>
        {children}
    </form>
);

export default Form;
