import './ParagraphsFromNewlines.scss';

import React from 'react';
import Linkify from "react-linkify";

type Props = {
    className?: string
    linkify?: boolean
    text?: string
}

const ParagraphsFromNewlines: React.FC<Props> = ({
    className = '',
    linkify,
    text,
}) => {
    if(!text) {
        return null;
    }

    const renderContent = () => (
        <div className={`c-paragraphs-from-newlines ${className}`}>
            {text.split('\n').map((paragraph, index) => {
                if(!paragraph) {
                    return null;
                }

                return (
                    <p key={index}>
                        {paragraph}
                    </p>
                );
            })}
        </div>
    );

    if(linkify) {
        return (
            <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
                <a className="c-link" target="_blank" rel='noopener noreferrer' href={decoratedHref} key={key}>
                    {decoratedText}
                </a>
            )}
            >
                {renderContent()}
            </Linkify>
        );
    } else {
        return renderContent();
    }
};

export default ParagraphsFromNewlines;
