import './LandingPageReorderableList.scss';

import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import LandingPageGroupSummary from "../LandingPageGroupSummary";
import LandingPageEventSummary from "../LandingPageEventSummary";
import LandingPageThreadSummary from "../LandingPageThreadSummary";

type Props = {
    disableReordering?: boolean
    handleRemoveContent: Function
    items: Array<EventPost> | Array<Group> | Array<Thread>
    type: 'adminForumTopics' | 'assignedTargets' | 'events' | 'studentForumTopics' | 'threads'
}

const LandingPageReorderableList: React.FC<Props> = ({
    disableReordering,
    handleRemoveContent,
    items,
    type,
}) => {
    const renderContentSummary = (item, index) => {
        switch(type) {
            case 'assignedTargets':
            case 'adminForumTopics':
            case 'studentForumTopics':
                return (
                    <LandingPageGroupSummary
                        disableReordering={disableReordering}
                        group={item}
                        handleDelete={() => handleRemoveContent(type, index)}
                    />
                );

            case 'events':
                return (
                    <LandingPageEventSummary
                        handleDelete={() => handleRemoveContent(type, index)}
                        post={item}
                    />
                );

            case 'threads':
                return (
                    <LandingPageThreadSummary
                        handleDelete={() => handleRemoveContent(type, index)}
                        thread={item}
                    />
                );

            default:
                return null;
        }
    };

    const renderId = (item) => {
        if(item.forumTopicId) {
            return item.forumTopicId.toString();
        } else if(item.postId) {
            return item.postId.toString();
        }
    };

    return (
        <Droppable droppableId={type}>
            {(provided) => (
                <ul
                    className="landing-page-form__attached-content-list"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {items.map((item, i: number) =>
                        <Draggable
                            draggableId={renderId(item)}
                            index={i}
                            isDragDisabled={disableReordering}
                            key={renderId(item)}
                        >
                            {(provided) => (
                                <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    {renderContentSummary(item, i)}
                                </li>
                            )}
                        </Draggable>
                    )}

                    {provided.placeholder}
                </ul>
            )}
        </Droppable>
    );
};

export default LandingPageReorderableList;
