import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import IError from "../../types/IError";
import IFile from "../../types/IFile";

import {buildErrorObject} from "../../utils/errors";
import Request from "../../utils/request";
import PATHS from "../../utils/paths";

import { RootState } from '../reducers';

type UploadFileToAwsProps = {
    file: IFile
};

export const uploadFileToAws = createAsyncThunk(
    'uploads/uploadFileToAws',
    async ({file}: UploadFileToAwsProps, {dispatch, getState, rejectWithValue}) => {
        let config = {
            baseURL: '',
            headers: {
                'Content-Type': 'application/octet-stream'
            },
            onUploadProgress: (progressEvent) => dispatch(setUploadPercentage((progressEvent.loaded / progressEvent.total) * 100))
        };
        let uploadRequest = new Request();

        try {
            const res = await uploadRequest.put(file.uploadUrl, file, config);
            return {res, file};
        } catch(err) {
            return rejectWithValue(err);
        }
    }
);

type UploadFilesProps = {
    file: IFile
    height: number
    width: number
    type?: string
}

export const uploadFiles = createAsyncThunk(
    'uploads/uploadFiles',
    async ({file, width, height, type}: UploadFilesProps, {dispatch, getState, rejectWithValue}) => {
        //console.log('uploading...')
        const tenantId = (getState() as RootState).schools.activeSchool.tenantId;
        const token = (getState() as RootState).auth.token;

        let path = PATHS.upload.getPresignedUrlForArtifactStaging(tenantId, file.name, height, width);
        if(type === 'artifact') {
            path = PATHS.upload.getPresignedUrlForArtifact(tenantId, file.name, height, width);
        } else if(type === 'sponsorship') {
            path = PATHS.upload.getPresignedUrlForSponsorship(file.name, height, width);
        }

        if(!token) {
            path = PATHS.upload.getPresignedUrlForInvitationArtifact(file.name, height, width)
        }

        try {
            const res = await new Request(token).post(path);
            file.uploadUrl = res.data.data.tempArtifactUploadUrl;
            if(type === 'artifact' || type === 'sponsorship') {
                file.artifactId = res.data.data.artifactId;
            } else {
                file.artifactId = res.data.data.artifactStagingId;
            }
            await dispatch(uploadFileToAws({file})).unwrap();
            return file;
        } catch(err) {
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage: 'There was an error while attempting to upload your file. Please try again.'
            });
            return rejectWithValue(errorObject);
        }
    }
);

interface uploadState {
    isUploading: boolean
    uploadError?: IError
    uploadPercentage?: number
}

export const initialState: uploadState = {
    isUploading: false,
    uploadError: undefined,
    uploadPercentage: 0,
};

export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setUploadPercentage: (state, action) => {
            state.uploadPercentage = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(uploadFileToAws.pending, (state) => {
            state.uploadError = undefined;
            state.isUploading = true;
        });
        addCase(uploadFileToAws.fulfilled, (state, action) => {
            console.log('uploadFileToAws FULFILLED');
            state.isUploading = false;
            state.uploadPercentage = 0;
        });
        addCase(uploadFileToAws.rejected, (state, action) => {
            state.uploadError = action.error;
            state.isUploading = false;
            state.uploadPercentage = 0;
        });

        addCase(uploadFiles.rejected, (state, action) => {
            state.uploadError = action.payload;
        });
    }
});

export const { setUploadPercentage } = uploadSlice.actions;

export default uploadSlice.reducer;
