import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../reducers';
import { addError } from './errors';

import PATHS, { buildQueryString } from '../../utils/paths';
import { generateProfilePromotionsMetadata } from '../../utils/generators';
import Request from '../../utils/request';

import IError from '../../types/IError';
import Metadata from '../../types/Metadata';
import ProfilePromotion from '../../types/ProfilePromotion';

type ApproveProfilePromotionParams = {
    schoolId?: number;
    profileIds: number[];
};

type GetPendingStudentsForPromotionParams = {
    isUpdate?: boolean;
    schoolId?: number;
    profilePromotionMetadata?: Metadata
};

type InitiateProfilePromotionParams = {
    schoolId?: number;
    profileIds: number[];
};

export const getPendingStudentsForPromotion = createAsyncThunk(
    'profilePromotion/getPendingStudentsForPromotion',
    async ({
               isUpdate,
               schoolId,
               profilePromotionMetadata,
           }: GetPendingStudentsForPromotionParams, {
               dispatch,
               getState,
           }) => {
        try {
            if (!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool?.tenantId;
            }

            if (!profilePromotionMetadata) {
                profilePromotionMetadata = generateProfilePromotionsMetadata();
            }

            const response = await new Request((getState() as RootState).auth.token)
                .get(PATHS.studentPromotion.getStudentsPendingPromotion(
                    schoolId,
                    buildQueryString(profilePromotionMetadata),
                ));
            const profilePromotions = response.data.data.items;

            profilePromotionMetadata.total = response.data.data.meta.total;

            return {profilePromotions, profilePromotionMetadata, isAtEnd: response.data.data.items.length === 0};
        } catch (err) {
            console.log('getApiRequests', err);
            err.friendlyMessage = 'Error getting the list of pending students available for promotion. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    },
);

export const initiateProfilePromotion = createAsyncThunk(
    'profilePromotion/initiateProfilePromotion',
    async ({
               schoolId,
               profileIds,
           }: InitiateProfilePromotionParams, {
               dispatch,
               getState,
           }) => {
        try {
            if (!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool?.tenantId;
            }

            await new Request((getState() as RootState).auth.token)
                .post(PATHS.studentPromotion.initiatePromotion(schoolId), {profileIds});
        } catch (err) {
            console.log('initiateProfilePromotion', err);
            err.friendlyMessage = 'Error initiating the promotion. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    },
);

export const approveProfilePromotions = createAsyncThunk(
    'profilePromotion/approveProfilePromotion',
    async ({
               schoolId,
               profileIds,
           }: ApproveProfilePromotionParams, {
               dispatch,
               getState,
           }) => {
        try {
            if (!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool?.tenantId;
            }

            await new Request((getState() as RootState).auth.token)
                .put(PATHS.studentPromotion.approvePendingPromotions(schoolId), {profileIds});
        } catch (err) {
            console.log('approveProfilePromotions', err);
            err.friendlyMessage = 'Error approving the promotions. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    },
);

interface ProfilePromotionState {
    profilePromotions: ProfilePromotion[];
    profilePromotionsMetadata: Metadata;
    isApprovingProfilePromotions: boolean;
    isGettingProfilePromotions: boolean;
    isInitiatingProfilePromotion: boolean;
    approveProfilePromotionsError?: IError;
    getProfilePromotionsError?: IError;
    initiateProfilePromotionError?: IError;
}

const initialState: ProfilePromotionState = {
    profilePromotions: [],
    profilePromotionsMetadata: generateProfilePromotionsMetadata(),
    isGettingProfilePromotions: false,
    isInitiatingProfilePromotion: false,
    isApprovingProfilePromotions: false,
};

export const ProfilePromotionSlice = createSlice({
    name: 'profilePromotion',
    initialState,
    reducers: {
        clearProfilePromotions: (state) => {
            state.profilePromotions = [];
        },
        clearProfilePromotionsMetadata: (state) => {
            state.profilePromotionsMetadata = generateProfilePromotionsMetadata();
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPendingStudentsForPromotion.pending, (state) => {
                state.isGettingProfilePromotions = true;
                state.getProfilePromotionsError = undefined;
            })
            .addCase(getPendingStudentsForPromotion.fulfilled, (state, action) => {
                state.profilePromotions = action.payload.profilePromotions;
                state.profilePromotionsMetadata = action.payload.profilePromotionMetadata;
                state.isGettingProfilePromotions = false;
            })
            .addCase(getPendingStudentsForPromotion.rejected, (state, action) => {
                state.isGettingProfilePromotions = false;
                state.getProfilePromotionsError = action.error;
            });

        builder
            .addCase(initiateProfilePromotion.pending, (state) => {
                state.isInitiatingProfilePromotion = true;
                state.initiateProfilePromotionError = undefined;
            })
            .addCase(initiateProfilePromotion.fulfilled, (state) => {
                state.isInitiatingProfilePromotion = false;
            })
            .addCase(initiateProfilePromotion.rejected, (state, action) => {
                state.isInitiatingProfilePromotion = false;
                state.initiateProfilePromotionError = action.error;
            });

        builder
            .addCase(approveProfilePromotions.pending, (state) => {
                state.isApprovingProfilePromotions = true;
                state.approveProfilePromotionsError = undefined;
            })
            .addCase(approveProfilePromotions.fulfilled, (state) => {
                state.isApprovingProfilePromotions = false;
            })
            .addCase(approveProfilePromotions.rejected, (state, action) => {
                state.isApprovingProfilePromotions = false;
                state.approveProfilePromotionsError = action.error;
            });
    },
});

export const { clearProfilePromotions, clearProfilePromotionsMetadata } = ProfilePromotionSlice.actions;

export default ProfilePromotionSlice.reducer;
