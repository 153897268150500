import AdmitDmReportRow from '../../types/AdmitDmReportRow';
import { generateAdminConversionReport, generateAdmitDmReportMetadata } from '../../utils/generators';
import Metadata from '../../types/Metadata';
import { buildErrorObject } from '../../utils/errors';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../reducers';
import PATHS, { buildQueryString } from '../../utils/paths';
import Request from '../../utils/request';
import clone from 'clone';
import AdmitConversionReport from '../../types/AdmitConversionReport';

type GetDmDashboardProps = {
    isUpdate?: boolean,
    dmReportMetadata?: {totalMessageCount: number} & Metadata
}

export const getDmDashboard = createAsyncThunk(
    'admitDashboard/getDmReport',
    async ({ dmReportMetadata } : GetDmDashboardProps, { getState, rejectWithValue }) => {
        try {
            if(!dmReportMetadata) {
                dmReportMetadata = clone((getState() as RootState).admitDashboard.dmReportMetadata);
            } else {
                dmReportMetadata = {...dmReportMetadata}
            }
            const { token } = (getState() as RootState).auth;
            const {activeSchool, } = (getState() as RootState).schools;

            const response = await new Request(token).get(PATHS.admits.dmDashboard(activeSchool.tenantId, buildQueryString(dmReportMetadata)));

            const dmReport = response.data.data.items;

            dmReportMetadata.total = response.data.data.meta.total;
            dmReportMetadata.totalMessageCount = response.data.data.meta.totalMessageCount;

            return {dmReport, dmReportMetadata, isAtEnd: response.data.data.items.length === 0};
        } catch (err) {
            let friendlyMessage = 'Error requesting report from the server. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    },
);

export const getConversionDashboard = createAsyncThunk(
    'admitDashboard/getConversionReport',
    async (_, { getState, rejectWithValue }) => {
        try {
            const { token } = (getState() as RootState).auth;
            const {activeSchool, } = (getState() as RootState).schools;

            const response = await new Request(token).get(PATHS.admits.conversionDashboard(activeSchool.tenantId));
            return response.data.data;
        } catch (err) {
            let friendlyMessage = 'Error requesting report from the server. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    },
);

interface AdmitDashboardState {
    conversionReport: AdmitConversionReport
    dmReport: Array<AdmitDmReportRow>
    isGettingConversionReport: boolean,
    isGettingDmReport: boolean,
    getConversionReportError?: Object
    getDmReportError?: Object
    dmReportMetadata: {totalMessageCount: number} & Metadata
}

export const initialState: AdmitDashboardState = {
    isGettingDmReport: false,
    isGettingConversionReport: false,
    conversionReport: generateAdminConversionReport(),
    dmReport: [],
    dmReportMetadata: generateAdmitDmReportMetadata(),
};

const admitDashboardSlice = createSlice({
    name: 'admitDashboard',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getDmDashboard.pending, (state) => {
            state.isGettingDmReport = true;
            state.getDmReportError = undefined;
        });
        builder.addCase(getDmDashboard.fulfilled, (state, action) => {
            state.isGettingDmReport = false;
            state.dmReport = action.payload.dmReport;
            state.dmReportMetadata = action.payload.dmReportMetadata;
        });
        builder.addCase(getDmDashboard.rejected, (state, action) => {
            state.isGettingDmReport = false;
            state.getDmReportError = action.payload;
        });

        builder.addCase(getConversionDashboard.pending, (state) => {
            state.isGettingConversionReport = true;
            state.getConversionReportError = undefined;
        });
        builder.addCase(getConversionDashboard.fulfilled, (state, action) => {
            state.isGettingConversionReport = false;
            state.conversionReport = action.payload;
        });
        builder.addCase(getConversionDashboard.rejected, (state, action) => {
            state.isGettingConversionReport = false;
            state.getConversionReportError = action.payload;
        });
    },
});

export default admitDashboardSlice.reducer;
