import React from 'react';

import Group from "../../../../types/Group";

import { useTypedSelector } from '../../../../store/reducers';

import CreateEditThreadForm from "./CreateEditThreadForm";

type Props = {
    forumTopic?: Group
    close?: Function
}

const CreateEditThread: React.FC<Props> = ({
    close,
    forumTopic,
}) => {
    const { thread } = useTypedSelector(store => store.threads);

    return (
        <>
            <CreateEditThreadForm
                forumTopic={forumTopic}
                close={close}
                thread={thread}
            />
        </>
    )
}
export default CreateEditThread;
