import React, {useState} from "react";

import {VictoryArea, VictoryAxis, VictoryChart, VictoryLabel, VictoryScatter, VictoryTooltip} from 'victory';
import moment from "moment";

import ActivityIndicator from "../../../../../../../components/ActivityIndicator";
import Card from "../../../../../../../components/Card";
import H5 from "../../../../../../../components/H5";
import H6 from "../../../../../../../components/H6";
import {IChartTimeSeriesData} from "../index";

type Props = {
    chart: Array<IChartTimeSeriesData>
    title: string
    isGettingDashboard: boolean
    initialized: boolean
};

const DashboardCardChart: React.FC<Props> = ({chart, title, isGettingDashboard, initialized}) => {
    const [datumDate, setDatumDate] = useState(null);
    const [datumCount, setDatumCount] = useState(null);

    const CHART_DOMAIN_PADDING: any = {x: [0, 0], y: [20, 0]};

    const CHART_HEIGHT = 200;
    const CHART_WIDTH = 400;
    const CHART_PADDING = {left: 35, right: 10, top: 5, bottom: 20};

    const X_AXIS_STYLE = {
        axis: {stroke: '#000000'},
        tickLabels: {fill: '#000000', textAlign: 'left'},
        grid: {stroke: '#cccccc'},
    };
    const Y_AXIS_STYLE = {
        axis: {stroke: '#cccccc'},
        axisLabel: {fill: '#000000', angle: 0},
        tickLabels: {fill: '#000000'},
        grid: {stroke: '#cccccc'}
    };

    const X_AXIS_TICK_LABEL_COMPONENT = (
        <VictoryLabel style={{
            fill: '#000000',
            fontSize: 8,
            textAlign: 'left',
            fontWeight: () => {
                return 'normal';
            }
        }}/>
    );
    const Y_AXIS_TICK_LABEL_COMPONENT = (
        <VictoryLabel style={{
            fill: '#000000',
            fontSize: 8,
            textAlign: 'left',
            fontWeight: () => {
                return 'normal';
            }
        }}/>
    );

    const AREA_STYLE = {data: {stroke: '#0057FF', fill: '#E0E8FD'}};

    const TOOLTIP_STYLE = {
        fill: '#000000',
        fontSize: 10,
        textAlign: 'center',
        padding: {bottom: 2},
        fontWeight: () => {
            return 'normal';
        }
    }

    const X_AXIS_TICK_FORMAT = (t) => moment(t).format('MM/DD');

    const formatDateForHeader = (date) => moment(date).format('MMM DD, YYYY');

    const getDateFromDatumDate = (value, chart) => formatDateForHeader(chart[value -1].x);

    const getCountAverage = (chart) => {
        const counts = chart.map(row => row.y);
        const averaged = counts.reduce((a, b) => a + b) / counts.length;
        return averaged.toLocaleString('en-US', {maximumFractionDigits: 0});
    };

    const clearDatumData = () => {
        setDatumCount(null);
        setDatumDate(null);
    };

    const setDatumData = (x: Date, y: number) => {
        setDatumCount(y);
        setDatumDate(x);
    };

    const generateTooltip = (datum) => {
        const date = getDateFromDatumDate(datum._x, chart);
        const userFriendlyValue = datum._y.toLocaleString();
        return `${X_AXIS_TICK_FORMAT(date)}  |  Users: ${userFriendlyValue}`
    }

    return (
        <Card className='dashboard-chart__card'>
            <div className='dashboard-card__row'>
                <H5>
                    {title}
                </H5>
            </div>

            <div className='dashboard-card__row'>
                {!isGettingDashboard && chart && chart.length > 0 && (
                    <div className='dashboard-card__row'>
                        <H6 className='dashboard-card__detail-row-muted'>
                            {datumDate === 0 ?
                                getDateFromDatumDate(datumDate, chart) :
                                    datumDate ?
                                        getDateFromDatumDate(datumDate, chart) :
                                        'Today'
                            }
                        </H6>

                        <div className='dashboard-card__primary-count'>
                            {datumCount === null ? (
                                (chart && chart.length > 0 ? chart[chart.length - 1].y.toLocaleString('en-US') : 0)
                            ) : (datumCount ? (datumCount.toLocaleString('en-US')) : ('Unknown')
                            )}
                        </div>
                    </div>
                )}
                <div>
                    {!isGettingDashboard && (
                        <div className='dashboard-card__detail-row-muted'>
                            {!isGettingDashboard && chart && chart.length > 0 && (
                                'Average Users: ' + getCountAverage(chart)
                            )}
                        </div>
                    )}
                </div>
            </div>

            {isGettingDashboard ? (
                <ActivityIndicator size="large"/>
            ) : (
                <>
                    {!isGettingDashboard && chart && chart.length > 0 && (
                        <div className='dashboard-chart__container'>
                            <VictoryChart
                                domainPadding={CHART_DOMAIN_PADDING}
                                height={CHART_HEIGHT}
                                width={CHART_WIDTH}
                                padding={CHART_PADDING}
                                domain={{
                                    y: [1, chart.map(row => row.y).sort((a, b) => b - a)[0] + 2],
                                    x: [1, chart.length]
                                }}
                            >
                                <VictoryAxis
                                    tickValues={chart.map(row => row.x)}
                                    tickCount={8}
                                    tickFormat={X_AXIS_TICK_FORMAT}
                                    tickLabelComponent={X_AXIS_TICK_LABEL_COMPONENT}
                                    style={X_AXIS_STYLE}
                                />

                                <VictoryAxis
                                    crossAxis
                                    dependentAxis
                                    orientation="left"
                                    tickFormat={(int) => parseInt(int)}
                                    tickLabelComponent={Y_AXIS_TICK_LABEL_COMPONENT}
                                    style={Y_AXIS_STYLE}
                                />

                                <VictoryArea
                                    data={[
                                        0,
                                        ...chart.map(row => row.y)
                                    ]}
                                    style={AREA_STYLE}
                                />

                                <VictoryScatter
                                    data={[
                                        0,
                                        ...chart.map(row => row.y)
                                    ]}
                                    name={title}
                                    labelComponent={
                                    <VictoryTooltip
                                        style={TOOLTIP_STYLE}
                                    />
                                }
                                    labels={({datum}) => generateTooltip(datum)}
                                    style={{
                                        labels: {
                                            fill: '#E0E8FD',
                                        }
                                    }}
                                    size={2}
                                    events={[{
                                        target: "data",
                                        eventHandlers: {
                                            onClick: () => {
                                                clearDatumData();
                                                return [
                                                    {
                                                        target: "labels",
                                                        mutation: (props) => {
                                                            setDatumData(props.datum._x, props.datum._y);
                                                        }
                                                    }
                                                ];
                                            }
                                        }
                                    }]}
                                />
                            </VictoryChart>

                            {!isGettingDashboard && (
                                <div className='dashboard-chart__row'>
                                    {!isGettingDashboard && chart && chart.length > 0 && (
                                        formatDateForHeader(chart[0].x) + ' To ' + formatDateForHeader(chart[chart.length - 1].x)
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </Card>
    )
}

export default DashboardCardChart;
