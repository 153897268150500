import './ViewGroup.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from "react-responsive";
import clone from 'clone';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router";

import NewContentMenuOption from "../../../../../types/NewContentMenuOption";
import Tab from "../../../../../types/Tab";

import { isObjectNullOrEmpty } from "../../../../../utils/utils";
import { CAPABILITIES, ROLES, userCan } from '../../../../../utils/roles';
import {
    PRIVACY_TYPES,
    PUSH_NOTIFICATION_DESTINATION_TYPES,
    TOPIC_MEMBERSHIP_TYPES
} from "../../../../../utils/constants";

import buildImageSrc from "../../../../../utils/buildImageSrc";
import {
    clearGroup,
    clearInviteAllStudentsToGroupError,
    getGroup,
    getGroupFeed,
    getGroupMembersRecent,
    inviteAllStudentsToGroup,
    inviteStudentsToGroup,
    joinGroup,
    leaveGroup
} from "../../../../../store/slices/groups";
import { clearThread } from "../../../../../store/slices/threads";
import { clearPushNotification, setPushNotification } from "../../../../../store/slices/pushNotifications";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Button, { ButtonSizes, ButtonThemes } from "../../../../../components/Button";
import Card from "../../../../../components/Card";
import CreateEditNotification from "../../../../Content/Notifications/CreateEditNotification";
import CreateEditThread from "../../../../Content/Threads/CreateEditThread";
import FormError from "../../../../../components/FormError";
import H4 from "../../../../../components/H4";
import ListStudents from "../../SchoolStudents/ListStudents";
import Icon from "../../../../../components/Icon";
import InviteUsersViaEmail from "./InviteUsersViaEmail";
import Modal from "../../../../../components/Modal";
import NewContentMenu from "../../../../../components/NewContentMenu";
import NewContentModal from "../../../../../components/NewContentModal";
import ProfileSummary from "../../../../../components/ProfileSummary";
import SuccessModal from "../../../../../components/Modal/SuccessModal";
import TableMoreMenu from "../../../../../components/Table/TableMoreMenu";
import TabNav from "../../../../../components/TabNav";
import UserListCard from "../../../../../components/UserListCard";
import ViewGroupMembers from "./ViewGroupMembers";
import ViewGroupActivity from "./ViewGroupActivity";
import ViewGroupChat from "./ViewGroupChat";
import { PrivacyTypes, ProfileTypes, YesOrNo } from '../../../../../utils/enums';

const ViewGroup: React.FC = () => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const [initialized, setInitialized] = useState(false);
    const [isChat, setIsChat] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [showInviteAllStudentsModal, setShowInviteAllStudentsModal] = useState(false);
    const [showInviteViaEmailModal, setShowInviteViaEmailModal] = useState(false);
    const [showInviteAllSuccessModal, setShowInviteAllSuccessModal] = useState(false);
    const [showInviteSuccessModal, setShowInviteSuccessModal] = useState(false);
    const [showNewPushNotificationModal, setShowNewPushNotificationModal] = useState(false);
    const [showNewContentModal, setShowNewContentModal] = useState(false);
    const [showNewContentMenu, setShowNewContentMenu] = useState(false);
    const [showSelectedStudentsConfirmModal, setShowSelectedStudentsConfirmModal] = useState(false);
    const [showSelectStudentsModal, setShowSelectStudentsModal] = useState(false);

    const { group, inviteAllStudentsToGroupError, inviteStudentsError, isGettingGroup, isGettingGroupFeed, isInvitingAllStudentsToGroup, isInvitingStudents, isJoiningGroup, isLeavingGroup, groupMembersRecent, isGettingGroupMembersRecent, getGroupMembersRecentError } = useTypedSelector((state) => state.groups);
    const { pushNotification } = useTypedSelector((state) => state.pushNotifications);
    const { postAsProfile } = useTypedSelector((state) => state.schools.activeSchool);

    const isMobile = useMediaQuery({ query: '(max-width: 47.5em)' });

    useEffect(() => {
        const initialize = async () => {
            try {
                let res = await dispatch(getGroup({schoolId: params?.schoolId, forumTopicId: params?.forumTopicId})).unwrap();

                if(res.group.isFollowing || res.group.privacyType === PrivacyTypes.Public || postAsProfile.profileType === ProfileTypes.Global) {
                    await dispatch(getGroupFeed({
                        schoolId: params?.schoolId,
                        forumTopicId: params?.forumTopicId
                    })).unwrap();

                    if (!isMobile) {
                        await dispatch(getGroupMembersRecent({
                            schoolId: params?.schoolId,
                            forumTopicId: params?.forumTopicId
                        })).unwrap();
                    }
                }
            } catch(err) {
                console.log('ViewGroup initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            //clearing push in case this forumTopic was attached
            dispatch(clearPushNotification());
            dispatch(clearGroup());
            dispatch(clearThread());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(showNewPushNotificationModal === false) {
            dispatch(clearPushNotification());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showNewPushNotificationModal]);

    useEffect(() => {
        if (location.pathname.includes('/chat')) {
            setIsChat(true);
        } else {
            setIsChat(false);
        }
    }, [location]);

    const newContentMenuOptions: Array<NewContentMenuOption> = useMemo(
        () => {
            return [
                {
                    icon: 'envelope',
                    onClick: () => {
                        setShowInviteViaEmailModal(true);
                        setShowNewContentMenu(false);
                    },
                    subtext: 'Invite students via email address(s)',
                    text: 'Email Address',
                }, {
                    icon: 'groups',
                    onClick: () => {
                        setShowInviteAllStudentsModal(true);
                        setShowNewContentMenu(false);
                    },
                    subtext: 'Send an invite to all registered students',
                    text: 'All',
                }, {
                    icon: 'check',
                    onClick: () => {
                        setShowSelectStudentsModal(true);
                        setShowNewContentMenu(false);
                    },
                    subtext: 'Select students to invite',
                    text: 'Selected Users',
                }
            ]
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const handleBulkSelectStudent = (profileId) => {
        let foundIndex = selectedStudents.findIndex((ss) => ss === profileId);
        let clonedStudents = clone(selectedStudents);

        if(foundIndex !== -1) {
            clonedStudents.splice(foundIndex, 1);
        } else {
            clonedStudents.push(profileId);
        }

        setSelectedStudents(clonedStudents);
    };

    const handleInviteAllStudents = async () => {
        try {
            await dispatch(inviteAllStudentsToGroup({})).unwrap();
            setShowInviteAllStudentsModal(false);
            setShowInviteAllSuccessModal(true);
        } catch(err) {
            console.log('ViewGroup handleInviteAllStudents err', err);
        }
    };

    const handleInviteBulkStudents = async () => {
        try {
            await dispatch(inviteStudentsToGroup({profileIds: selectedStudents})).unwrap();
            setShowSelectedStudentsConfirmModal(false);
            setShowInviteSuccessModal(true);
            setSelectedStudents([]);
        } catch(err) {
            console.log('ViewGroup handleInviteBulkStudents err', err);
        }
    };

    const handleJoinLeave = async () => {
        let joinLeaveFunc = null;

        if(group.isFollowing) {
            joinLeaveFunc = leaveGroup;
        } else if(group.privacyType === PRIVACY_TYPES.PRIVATE) {
            /*if(group.myForumTopicProfile?.membershipStatus === TOPIC_MEMBERSHIP_TYPES.INVITED) {
                joinLeaveFunc = inviteToTopicAccept;
            } else {*/
                joinLeaveFunc = joinGroup;
            //}
        } else {
            joinLeaveFunc = joinGroup;
        }

        try {
            await dispatch(joinLeaveFunc(group));
        } catch(err) {
            console.log('ViewGroup handleJoinLeave err', err);
        }
    };

    const handlePushClick = async () => {
        let clonedPushNotification = clone(pushNotification);
        clonedPushNotification.forumTopic = group;
        clonedPushNotification.destination = PUSH_NOTIFICATION_DESTINATION_TYPES.PUSH;
        dispatch(setPushNotification(clonedPushNotification));
        setShowNewPushNotificationModal(true);
    };

    const handleTargetWithPushClick = async () => {
        let clonedPushNotification = clone(pushNotification);
        clonedPushNotification.targetForumTopic = group;
        clonedPushNotification.destination = PUSH_NOTIFICATION_DESTINATION_TYPES.PUSH;
        dispatch(setPushNotification(clonedPushNotification));
        setShowNewPushNotificationModal(true);
    };

    const renderFollowButton = () => {
        if(group.elementId != null) {
            return null;
        }

        let text = group.isFollowing ? 'Leave' : 'Join';
        let theme = group.isFollowing ? ButtonThemes.Secondary : null;
        let isDisabled = false;
        let icon = group.isFollowing ? 'x' : null;

        if(postAsProfile?.profileId === group.ownerProfileId) {
            text = 'Owner';
            theme = ButtonThemes.Light;
            icon = 'check';
            isDisabled = true;
        } else if(group.privacyType === PRIVACY_TYPES.PRIVATE && !group.isFollowing) {
            if(group.myForumTopicProfile?.membershipStatus === TOPIC_MEMBERSHIP_TYPES.INVITED) {
                text = 'Invited';
            } else if(group.myForumTopicProfile?.membershipStatus === TOPIC_MEMBERSHIP_TYPES.REQUESTED) {
                text = 'Requested';
                theme = ButtonThemes.Light;
                icon = 'check';
                isDisabled = true;
            } else {
                text = 'Request';
            }
        }

        return (
            <Button
                className="view-group__follow-button"
                disabled={isDisabled}
                onClick={handleJoinLeave}
                showActivityIndicator={isJoiningGroup || isLeavingGroup}
                size={ButtonSizes.Small}
                theme={theme}
            >
                {text}

                {icon && <Icon type={icon} />}
            </Button>
        )
    };

    let TABS: Array<Tab> = [
        {
            isActive: location.pathname.includes('/activity'),
            onClick: () => history.push(`/school/${params.schoolId}/${params.profileType}/group/${params.forumTopicId}/activity`),
            text: 'Activity'
        }, {
            disabled: group.groupChatEnabled === YesOrNo.No || (!group.isFollowing && !(postAsProfile.profileType !== ROLES.SUPER_ADMIN)),
            isActive: location.pathname.includes('/chat'),
            onClick: () => history.push(`/school/${params.schoolId}/${params.profileType}/group/${params.forumTopicId}/chat`),
            text: 'Chat'
        }
    ];

    if (group.admit !== YesOrNo.Yes) {
        TABS.push(
            {
                isActive: location.pathname.includes('/members'),
                onClick: () => history.push(`/school/${params.schoolId}/${params.profileType}/group/${params.forumTopicId}/members/active`),
                text: 'Members'
            }
        )
    }

    return (
        <div className="view-group">
            <div className="view-group__left-col">
                <Button
                    className="view-group__back-button"
                    noStyles
                    onClick={() => history.replace(`/school/${params.schoolId}/${params.profileType}/groups`)}
                >
                    <Icon type="arrow-left" />
                </Button>

                {isGettingGroup || isGettingGroupFeed || !initialized ? (
                    <div className="view-group__activity-indicator">
                        <ActivityIndicator />
                    </div>
                ) : (
                    <>
                        <div className={`view-group__main ${isChat ? 'view-group__main--chat' : ''}`}>
                            <Card
                                className="view-group__header"
                            >
                                <div className="view-group__header-content">
                                    <div className="view-group__header-image" style={!isObjectNullOrEmpty(group.artifact) ? {backgroundImage: `url(${buildImageSrc(group.artifact, '220')})`} : {backgroundImage: 'url(/img/abuzz-group-placeholder.svg)'}} />

                                    <H4>
                                        {group.name}
                                    </H4>

                                    {group.ownerProfile && <ProfileSummary extraText={group.adminUser?.position} profile={group.ownerProfile} />}

                                    <div className="view-group__actions">
                                        {(group.privacyType === PRIVACY_TYPES.PUBLIC || group.isFollowing || postAsProfile.profileId === group.ownerProfileId) && (
                                            <>
                                                <Button
                                                    onClick={() => setShowNewContentModal(true)}
                                                    size={ButtonSizes.Small}
                                                >
                                                    Start a post
                                                </Button>

                                                {userCan(CAPABILITIES.MANAGE_PUSH_NOTIFICATIONS) && (
                                                    <>
                                                        {group.elementId == null && (
                                                            <Button
                                                                onClick={handlePushClick}
                                                                size={ButtonSizes.Small}
                                                                theme={ButtonThemes.Secondary}
                                                            >
                                                                Send via notification
                                                            </Button>
                                                        )}

                                                        <Button
                                                            onClick={handleTargetWithPushClick}
                                                            size={ButtonSizes.Small}
                                                            theme={ButtonThemes.Secondary}
                                                        >
                                                            Target with notification
                                                        </Button>
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {(postAsProfile.profileId === group.ownerProfileId || postAsProfile.profileType === ProfileTypes.Global) && (
                                            <span className="view-group__invite-container">
                                                <Button
                                                    onClick={() => setShowNewContentMenu(true)}
                                                    size={ButtonSizes.Small}
                                                    theme={ButtonThemes.Secondary}
                                                >
                                                    Invite Users
                                                </Button>

                                                <NewContentMenu
                                                    close={() => setShowNewContentMenu(false)}
                                                    options={newContentMenuOptions}
                                                    show={showNewContentMenu}
                                                />
                                            </span>
                                        )}

                                        {group.admit !== YesOrNo.Yes && (
                                            <>{renderFollowButton()}</>
                                        )}
                                    </div>
                                </div>

                                {(group.privacyType === PrivacyTypes.Public || group.isFollowing || postAsProfile.profileType === ProfileTypes.Global) && (
                                    <TabNav
                                        tabs={TABS}
                                    />
                                )}

                                <div className="view-group__header__more-menu-container">
                                    <TableMoreMenu
                                        item={group}
                                        onlyModerationOptions
                                        type="group"
                                    />
                                </div>
                            </Card>

                            {(group.privacyType === PrivacyTypes.Public || group.isFollowing || postAsProfile.profileType === ProfileTypes.Global) && (
                                <Switch>
                                    <Route exact path="/school/:schoolId/:profileType/group/:forumTopicId/activity" component={ViewGroupActivity} />
                                    <Route exact path="/school/:schoolId/:profileType/group/:forumTopicId/chat" component={ViewGroupChat} />
                                    <Route exact path="/school/:schoolId/:profileType/group/:forumTopicId/members/:memberType" component={ViewGroupMembers} />
                                </Switch>
                            )}
                        </div>
                    </>
                )}
            </div>

            {!isMobile && !isGettingGroup && (group.privacyType === PrivacyTypes.Public || group.isFollowing || postAsProfile.profileType === ProfileTypes.Global) && (
                <div className="view-group__right-col">
                    <UserListCard
                        error={getGroupMembersRecentError}
                        group={group}
                        limit={5}
                        showActivityIndicator={isGettingGroupMembersRecent}
                        title="Recently Joined"
                        users={groupMembersRecent}
                        overridePrivacySetting={postAsProfile.profileType === ProfileTypes.Global}
                    />
                </div>
            )}

            <SuccessModal
                buttonOnClick={() => {
                    setShowInviteSuccessModal(false);
                }}
                buttonText="Done"
                show={showInviteSuccessModal}
                title="Nice job!"
            >
                <p>
                    Invite(s) have been sent to the specified students.
                </p>
            </SuccessModal>

            <SuccessModal
                buttonOnClick={() => {
                    setShowInviteAllSuccessModal(false);
                }}
                buttonText="Done"
                show={showInviteAllSuccessModal}
                title="Nice job!"
            >
                <p>
                    Invites have been sent to all students.
                </p>
            </SuccessModal>

            <Modal
                className="c-success-modal"
                declineButtonOnClick={() => setShowInviteViaEmailModal(false)}
                show={showInviteViaEmailModal}
            >
                <InviteUsersViaEmail
                    closeModal={() => setShowInviteViaEmailModal(false)}
                    onSuccess={() => {
                        setShowInviteViaEmailModal(false);
                        setShowInviteSuccessModal(true);
                    }}
                />
            </Modal>

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                <CreateEditThread
                    forumTopic={group}
                    close={() => setShowNewContentModal(false)}
                />
            </NewContentModal>

            <NewContentModal
                close={() => setShowNewPushNotificationModal(false)}
                show={showNewPushNotificationModal}
            >
                <CreateEditNotification
                    close={() => setShowNewPushNotificationModal(false)}
                    startWithAttachedContent
                />
            </NewContentModal>

            <Modal
                confirmButtonOnClick={handleInviteAllStudents}
                confirmButtonText="Send Invites"
                declineButtonText="Cancel"
                declineButtonOnClick={() => {
                    setShowInviteAllStudentsModal(false);
                    dispatch(clearInviteAllStudentsToGroupError());
                }}
                show={showInviteAllStudentsModal}
                showActivityIndicator={isInvitingAllStudentsToGroup}
                title="Confirm Invitations"
            >
                <>
                    <p>You've chosen to send an invitation to every student registered in the app. Please confirm.</p>

                    <FormError error={inviteAllStudentsToGroupError} />
                </>
            </Modal>

            <NewContentModal
                close={() => setShowSelectStudentsModal(false)}
                show={showSelectStudentsModal}
                width="wide"
            >
                <ListStudents
                    bulkSelectedItems={selectedStudents}
                    onBulkSelected={handleBulkSelectStudent}
                    onBulkSubmit={() => {
                        setShowSelectStudentsModal(false);
                        setTimeout(() => setShowSelectedStudentsConfirmModal(true), 300);
                    }}
                />
            </NewContentModal>

            <Modal
                confirmButtonOnClick={handleInviteBulkStudents}
                confirmButtonText="Send Invites"
                declineButtonText="Cancel"
                declineButtonOnClick={() => {
                    setShowSelectedStudentsConfirmModal(false);
                    setSelectedStudents([]);
                }}
                show={showSelectedStudentsConfirmModal}
                showActivityIndicator={isInvitingStudents}
                title="Confirm Invitations"
            >
                <>
                    <p>You've chosen to send an invitation to {selectedStudents.length} student{selectedStudents.length === 1 ? '' : 's'} registered in the app. Please confirm.</p>

                    <FormError error={inviteStudentsError} />
                </>
            </Modal>
        </div>
    );
};

export default ViewGroup;
