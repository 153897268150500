const EXTENDED_COLORS_ARRAY = [
    '#2EFFA0',
    '#20E3D6',
    '#8C4AA4',
    '#E72D95',
    '#F78A35',
    '#B8C63D',
    '#BEFF1C',
    '#B77F37',
    '#0E8ACA',
    '#9000C7',
    '#0DBAA7'
];

export const createElementsObject = (elementsArray: Array<any>) => {
    return elementsArray.reduce((acc: any, el: any) => {
        const type = el.code;
        acc[type] = el;
        if(type === 'interests') {
            acc.interests.svgs = {};
            acc.interests.colors = {};
            el.details.forEach((interest, i) => {
                acc.interests.svgs[interest.code] = interest.imgSvg;
                acc.interests.colors[interest.code] = EXTENDED_COLORS_ARRAY[i % (EXTENDED_COLORS_ARRAY.length)];
            });
        }
        return acc;
    }, {});
};

export const sanitizeDetails = (object) => {
    if (!object.details) {
        return object;
    }
    const details = object.details.reduce((acc, el) => {

        const elementCode = el.element ? el.element.code : el.code;

        if(elementCode === undefined) {
            return acc;
        }

        const item = el.elementDetail;

        if (!acc[elementCode]) {
            acc[elementCode] = [item];
            return acc;
        }
        if (acc[elementCode]) {
            if (acc[elementCode].length > 0) {
                acc[elementCode] = [ ...acc[elementCode], item ];
            } else {
                acc[elementCode] = [ acc[elementCode], item ];
            }
        }
        return acc;
    }, {})
    return {
        ...object,
        ...details,
    };
}
