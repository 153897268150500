import './H5.scss';

import React from 'react';

type Props = {
    children: React.ReactNode
    className?: string
}

const H5: React.FC<Props> = ({
    children,
    className = ''
}) => (
    <h5 className={`c-h5 ${className}`}>
        {children}
    </h5>
);

export default H5;
