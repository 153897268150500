import './Modal.scss';

import React from 'react';
import ReactModal from 'react-modal';

import { MODAL_STYLE_RESET } from '../../utils/constants';

import Button, { ButtonThemes } from '../Button';
import H5 from '../H5';
import Icon from '../Icon';

ReactModal.setAppElement('#root');

type Props = {
    children: React.ReactNode
    className?: string
    confirmButtonDisabled?: boolean
    confirmButtonOnClick?: Function
    confirmButtonText?: string
    contentLabel?: string
    declineButtonDisabled?: boolean
    declineButtonOnClick?: Function
    declineButtonText?: string
    show: boolean,
    showActivityIndicator?: boolean
    title?: string | React.ReactNode
}

const Modal: React.FC<Props> = ({
    children,
    className= '',
    confirmButtonDisabled,
    confirmButtonOnClick,
    confirmButtonText,
    contentLabel,
    declineButtonDisabled,
    declineButtonOnClick,
    declineButtonText,
    show,
    showActivityIndicator,
    title,
}) => (
    <ReactModal
        className={className}
        closeTimeoutMS={200}
        contentLabel={contentLabel || 'Modal'}
        isOpen={show === true}
        onRequestClose={(e) => {
            e.stopPropagation();
            declineButtonOnClick ? declineButtonOnClick() : confirmButtonOnClick();
        }}
        style={MODAL_STYLE_RESET}
    >
        <div className={`c-modal__content--wrapper ${className || ''}`} onClick={(e) => e.stopPropagation()}>
            {title != null ? (
                <H5 className="c-modal__title">
                    {title}

                    {confirmButtonOnClick || declineButtonOnClick ? (
                        <Button className="c-modal__close-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    declineButtonOnClick ? declineButtonOnClick() : confirmButtonOnClick();
                                }}>
                            <Icon type="x" />
                        </Button>
                    ) : null}
                </H5>
            ) : null}

            <div className="c-modal__content">
                {children}
            </div>

            {confirmButtonText || declineButtonText ? (
                <div className="c-modal__button-wrapper">
                    {confirmButtonText && confirmButtonOnClick ? (
                        <Button
                            className="c-modal__confirm-button"
                            disabled={confirmButtonDisabled}
                            onClick={confirmButtonOnClick}
                            showActivityIndicator={showActivityIndicator}
                        >
                            {confirmButtonText}
                        </Button>
                    ) : null}

                    {declineButtonText && declineButtonOnClick ? (
                        <Button
                            className="c-modal__decline-button"
                            disabled={declineButtonDisabled}
                            onClick={(e) => {
                                e.stopPropagation();
                                declineButtonOnClick();
                            }}
                            theme={ButtonThemes.Secondary}
                        >
                            {declineButtonText}
                        </Button>
                    ) : null}
                </div>
            ) : null}
        </div>
    </ReactModal>
);

Modal.propTypes = {

};

export default Modal;
