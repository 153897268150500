import './Button.scss';

import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import ActivityIndicator from "../ActivityIndicator";
import Icon from "../Icon";

export enum ButtonSizes {
    Small = 'small',
}

export enum ButtonThemes {
    Add = 'add',
    Destructive = 'destructive',
    Light = 'light',
    Link = 'link',
    LinkSecondary = 'link-secondary',
    Primary = 'primary',
    Round = 'round',
    Secondary = 'secondary',
    Small = 'small',
}

export enum ButtonTypes {
    Button = 'button',
    Reset = 'reset',
    Submit = 'submit',
}

type Props = {
    children: React.ReactNode
    className?: string
    disabled?: boolean
    href?: string
    icon?: string
    onClick?: Function
    noStyles?: boolean
    showActivityIndicator?: boolean
    size?: ButtonSizes
    state?: object
    target?: string
    theme?: ButtonThemes
    to?: string
    type?: ButtonTypes
};

const Button: React.FC<Props> = ({
    children,
    className = '',
    disabled,
    href,
    icon,
    noStyles,
    onClick,
    showActivityIndicator,
    size,
    state,
    target,
    theme,
    to,
    type = ButtonTypes.Button,
    ...otherProps
}) => {
    let classes = classNames(
        'c-button',
        size != null && `a-button--${size}`,
        theme != null ? `a-button--${theme}` : 'a-button--primary',
        className,
        {
            'a-button--no-styles': noStyles === true,
            'a-button--disabled': (href != null || to != null) && (disabled === true || showActivityIndicator === true)
        });
    if(href != null) {
        return (
            <a
                className={classes}
                href={href}
                target={target}
            >
                {theme === 'add' && (
                    <div className="c-button__icon">
                        <Icon type="plus" />
                    </div>
                )}
                {children}
            </a>
        );
    } else if(to != null) {
        return (
            <Link className={classes}
                  to={{
                      pathname: to,
                      state
                  }}
                  onClick={onClick}>
                {theme === 'add' && (
                    <div className="c-button__icon">
                        <Icon type="plus" />
                    </div>
                )}
                {children}
            </Link>
        )
    } else {
        return (
            <button
                className={classes}
                disabled={disabled || showActivityIndicator}
                // @ts-ignore
                onClick={onClick}
                type={type}
            >
                {theme === 'add' && (
                    <div className="c-button__icon">
                        <Icon type="plus" />
                    </div>
                )}

                {icon && (
                    <span className="c-button__icon--inline">
                        <Icon type={icon} />
                    </span>
                )}

                {children}

                {showActivityIndicator ? (
                    <div className="c-button__activity-indicator">
                        <ActivityIndicator />
                    </div>
                ) : null}
            </button>
        );
    }
};

export default Button;
