import './PreviewLandingPageEvent.scss';

import React from 'react';
import moment from 'moment';

import EventPost from "../../../../../../../../../types/EventPost";

import buildImageSrc from "../../../../../../../../../utils/buildImageSrc";
import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../../../../../../../../utils/utils";

import Button, { ButtonSizes, ButtonThemes } from "../../../../../../../../../components/Button";

type Props = {
    item: EventPost
}

const PreviewLandingPageEvent: React.FC<Props> = ({item}) => {
    let post = item;

    const renderDate = () => {
        let dateString = moment(post.startAt).format('MMM DD, YYYY [•] h:mm A');

        if(post.endAt) {
            if(moment(post.startAt).format('MM DD') === moment(post.endAt).format('MM DD')) {
                dateString += ` - ${moment(post.endAt).format('h:mm A')}`;
            } else {
                dateString += ` - ${moment(post.endAt).format('MMM DD, YYYY [•] h:mm A')}`;
            }

        }

        return dateString;
    };

    return (
        <div className="c-preview-landing-page__event">
            <div className="c-preview-landing-page__event-artifact" style={{backgroundImage: !isArrayNullOrEmpty(post.artifacts) ? `url(${buildImageSrc(post.artifacts[0], '220')})` : null}} />

            <div className="c-preview-landing-page__event-content">
                {!isObjectNullOrEmpty(post.location) && (
                    <div className="c-preview-landing-page__event-location">
                        {post.location.name}
                    </div>
                )}

                <div className="c-preview-landing-page__event-title">
                    {post.title}
                </div>

                <div className="c-preview-landing-page__event-date">
                    {renderDate()}
                </div>

                <div className="c-preview-landing-page__event-button-container">
                    <Button
                        size={ButtonSizes.Small}
                        theme={ButtonThemes.Light}
                    >
                        Learn More
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PreviewLandingPageEvent;
