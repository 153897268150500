import React from 'react';

import Modal from '../../Modal';

type Props = {
    close: Function
    show: boolean
}

const FileUploadErrorModal: React.FC<Props> = ({
    close,
    show
}) => {
    return (
        <Modal
            declineButtonOnClick={() => close(false)}
            declineButtonText="OK"
            show={show}
            title="Incorrect file type or number of files"
        >
            <p>
                Select a single image file for upload.
            </p>
            <p>
                Allowed formats: JPEG, PNG, GIF, WebP, HEIC, HEIF
            </p>
        </Modal>
    );
};

export default FileUploadErrorModal;
