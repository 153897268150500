import './ListGroups.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clone from 'clone';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import TableColumn from "../../../../../types/TableColumn";
import TableFilterMenuOption from "../../../../../types/TableFilterMenuOption";

import { LIST_TYPES, PRIVACY_TYPES } from "../../../../../utils/constants";
import { isArrayNullOrEmpty } from "../../../../../utils/utils";

import {
    clearGroup,
    clearGroupsMetadata,
    getGroups,
    setGroup,
    setSearchTerm
} from "../../../../../store/slices/groups";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Button, { ButtonSizes, ButtonThemes } from "../../../../../components/Button";
import CreateEditGroup from "../CreateEditGroup";
import GridList, { GridListTypes } from "../../../../../components/GridList";
import GroupTableMoreMenu from "./GroupTableMoreMenu";
import H5 from "../../../../../components/H5";
import Icon from "../../../../../components/Icon";
import ListTypeSelector from "../../../../../components/ListTypeSelector";
import NewContentMenu from "../../../../../components/NewContentMenu";
import NewContentModal from "../../../../../components/NewContentModal";
import PageHeader from "../../../../../components/PageHeader";
import Table from "../../../../../components/Table";
import TableFilterMenu from "../../../../../components/Table/TableFilterMenu";
import TableFilterRow from "../../../../../components/Table/TableFilterRow";
import TableFilterRowDivider from "../../../../../components/Table/TableFilterRow/TableFilterRowDivider";
import TableFlaggedOnlyToggle from "../../../../../components/Table/TableFlaggedOnlyToggle";
import TableRowImage from "../../../../../components/Table/TableRowImage";
import TableSearch from "../../../../../components/TableSearch";
import {ProfileTypes} from "../../../../../utils/enums";

type Props = {
    appliedFilters?: any
    disableFilter?: (arg0: any) => boolean
    hideGroupFilterButtons?: boolean
    ignorePathFilters?: boolean
    onRowClick?: Function
    showStudentGroups?: boolean
}

const ListGroups: React.FC<Props> = ({
    appliedFilters,
    disableFilter,
    hideGroupFilterButtons,
    ignorePathFilters,
    onRowClick,
    showStudentGroups,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const match = useRouteMatch();
    const { pathname } = useLocation();

    if (match.params.profileType === ProfileTypes.Admit) {
        hideGroupFilterButtons = true;
    }

    const [initialized, setInitialized] = useState(false);
    const [selectedListType, setSelectedListType] = useState(LIST_TYPES.TABLE);
    const [showNewContentMenu, setShowNewContentMenu] = useState(false);
    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const { group, groups, groupsMetadata, isGettingGroups, searchTerm } = useTypedSelector((state) => state.groups);
    const { activeSchool } = useTypedSelector((state) => state.schools);

    useEffect(() => {
        const initialize = async () => {
            try {
                let clonedMetadata = clone(groupsMetadata);
                console.log('SchoolGroups existing metadata: ', clonedMetadata);
                console.log('SchoolGroups appliedFilters: ', appliedFilters);

                if(appliedFilters) {
                    clonedMetadata = {
                        ...clonedMetadata,
                        ...appliedFilters,
                    };

                    if (appliedFilters.student_topics) {
                        delete clonedMetadata.admin_topics;
                    }

                    if (appliedFilters.admin_topics) {
                        delete clonedMetadata.student_topics;
                    }
                }

                if (!ignorePathFilters) {
                    if (pathname.includes('/student/')) {
                        clonedMetadata.student_topics = true;
                    }

                    if (pathname.includes('/alumni/')) {
                        clonedMetadata.alumni_topics = true;
                    }
              
                    if (pathname.includes('/admit/')) {
                        clonedMetadata.admit_topics = true;
                    }
                }


                console.log('SchoolGroups initialize', clonedMetadata);

                await dispatch(getGroups({isUpdate: false, schoolId: match?.params?.schoolId, groupsMetadata: clonedMetadata})).unwrap();
            } catch(err) {
                console.log('SchoolEvents initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            setShowNewContentMenu(false);
            dispatch(clearGroup());
            dispatch(clearGroupsMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Name',
                    accessor: 'name',
                    sortBy: 'name',
                    Cell: ({row: { original }}) => (
                        <div
                            className="school-groups__name-column"
                            onClick={onRowClick ? null : () => history.push(`/school/${match?.params?.schoolId}/${match?.params?.profileType}/group/${original.forumTopicId}/activity`)}
                        >
                            <TableRowImage
                                artifact={original.artifact}
                                flagged={original.flagged}
                                placeholder="/img/abuzz-group-placeholder.svg"
                            />

                            {original.name}
                        </div>
                    ),
                    hasImage: true
                }, {
                    Header: 'Type',
                    accessor: (d) => (
                        <span className="school-groups__privacy-type-column">
                            {d.privacyType}
                        </span>
                    ),
                    sortBy: 'privacyType'
                }, {
                    Header: 'Members',
                    accessor: 'memberCount'
                }, {
                    Header: '% Chat Push Enabled',
                    accessor: 'percentMembersWithChatNotificationsEnabled'
                }, {
                    Header: 'Threads',
                    accessor: 'postCount'
                }, {
                    Header: 'Owner',
                    accessor: (d) => {
                        if(!d.ownerProfile) {
                            return '--';
                        }

                        return `${d.ownerProfile.firstName} ${d.ownerProfile.lastName}`;
                    }
                },
            ];

            if(!onRowClick) {
                columns.push({
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original),
                    hasImage: true
                });
            }

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const data = useMemo(
        () => {
            return groups;
        },
        [groups]
    );

    const filterOptions: Array<TableFilterMenuOption> = useMemo(
        () => {
            return (
                [{
                    isActive: !groupsMetadata.admin_topics && !groupsMetadata.my_topics && !groupsMetadata.student_topics && !groupsMetadata.system_topics,
                    onSelect: () => {
                        onMetadataChange({
                            admin_topics: false,
                            my_topics: false,
                            other_topics: false,
                            student_topics: false,
                            system_topics: false,
                            page_num: 0
                        }, false);
                    },
                    text: "All Groups"
                }, {
                    isActive: groupsMetadata.my_topics,
                    onSelect: () => {
                        onMetadataChange({
                            my_topics: true,
                            admin_topics: false,
                            other_topics: false,
                            student_topics: false,
                            system_topics: false,
                            page_num: 0
                        }, false);
                    },
                    text: "My Groups"
                }, {
                    isActive: groupsMetadata.system_topics,
                    onSelect: () => {
                        onMetadataChange({
                            system_topics: true,
                            admin_topics: false,
                            my_topics: false,
                            other_topics: false,
                            student_topics: false,
                            page_num: 0
                        }, false);
                    },
                    text: "System Groups"
                }, {
                    isActive: groupsMetadata.admin_topics,
                    onSelect: () => {
                        onMetadataChange({
                            admin_topics: true,
                            system_topics: false,
                            my_topics: false,
                            other_topics: false,
                            student_topics: false,
                            page_num: 0
                        }, false);
                    },
                    text: "Admin Groups",
                }, {
                    isActive: groupsMetadata.student_topics,
                    onSelect: () => {
                        onMetadataChange({
                            student_topics: true,
                            admin_topics: false,
                            system_topics: false,
                            my_topics: false,
                            other_topics: false,
                            page_num: 0
                        }, false);
                    },
                    text: "Student Groups",
                }]
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [groupsMetadata]
    );

    const newContentMenuOptions = [
        {
            icon: 'public',
            onClick: () => handleSelectNewGroupType(PRIVACY_TYPES.PUBLIC),
            subtext: 'Visible to all students in app',
            text: 'Public Group',
        }, {
            icon: 'private',
            onClick: () => handleSelectNewGroupType(PRIVACY_TYPES.PRIVATE),
            subtext: 'Invite-only or request to join',
            text: 'Private Group',
        }
    ];

    const handleRowClick = ({original}) => {
        if(!onRowClick) {
            return null;
        }

        onRowClick(original);
    };

    const handleSelectNewGroupType = (privacyType: string) => {
        dispatch(clearGroup()); // Just in case there's already a group in the store
        let clonedGroup = clone(group);
        clonedGroup.privacyType = privacyType;
        dispatch(setGroup(clonedGroup));
        setShowNewContentModal(true);
        setShowNewContentMenu(false);
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        console.log('onMetadataChange', changes, isUpdate);
        try {
            let clonedMetadata = clone(groupsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            if(changes.search != null) {
                clonedMetadata.page_num = 0;
            }

            return await dispatch(getGroups({isUpdate, groupsMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('SchoolDeals onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item) => {
        if(item.removedByMods) {
            return null;
        }

        return (
            <GroupTableMoreMenu
                item={item}
                getAfterDelete={() => onMetadataChange({}, false)}
                setShowNewContentModal={setShowNewContentModal}
            />
        );
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="school-groups">
            <PageHeader>
                <H5>
                    <Icon type="navigation-groups" />

                    Groups
                </H5>
            </PageHeader>

            <TableFilterRow>
                {!hideGroupFilterButtons && (
                    <>
                        <TableFilterMenu
                            label="View"
                            options={filterOptions}
                        />

                        <TableFlaggedOnlyToggle
                            label="Flagged only"
                            onToggleChange={() => onMetadataChange({flagged: !groupsMetadata.flagged, page_num: 0}, false)}
                            value={groupsMetadata.flagged}
                        />

                        <TableFilterRowDivider />
                    </>
                )}

                <ListTypeSelector
                    onListTypeSelected={(listType) => setSelectedListType(listType)}
                    selected={selectedListType}
                />

                <TableFilterRowDivider />

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search Group Names"
                    searchTerm={searchTerm}
                    size="small"
                />

                {activeSchool.postAsProfile && !onRowClick && (
                    <Button
                        onClick={() => {
                            if (pathname.includes('/admit/')) {
                                handleSelectNewGroupType(PRIVACY_TYPES.PUBLIC);
                            } else {
                                setShowNewContentMenu(true);
                            }
                        }}
                        size={ButtonSizes.Small}
                    >
                        Create Group
                    </Button>
                )}

                <NewContentMenu
                    close={() => setShowNewContentMenu(false)}
                    options={newContentMenuOptions}
                    show={showNewContentMenu}
                />
            </TableFilterRow>

            {isGettingGroups ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                            No groups to display. <Button
                                onClick={() => setShowNewContentModal(true)}
                                theme={ButtonThemes.Link}
                            >
                                Create One?
                            </Button>
                        </>
                    ) : (
                        <>
                            {selectedListType === LIST_TYPES.TABLE ? (
                                <Table
                                    columns={columns}
                                    data={data}
                                    disableFilter={disableFilter}
                                    getData={onMetadataChange}
                                    onRowClick={onRowClick && handleRowClick}
                                    pageNumber={groupsMetadata.page_num}
                                    pageSize={groupsMetadata.page_size}
                                    sortBy={groupsMetadata.sort}
                                    sortOrder={groupsMetadata.order}
                                    totalNumberOfItems={groupsMetadata.total}
                                />
                            ) : (
                                <GridList
                                    createCardButtonText="Create a Group"
                                    createCardNewContentMenuOptions={newContentMenuOptions}
                                    createCardText="Start discussions, create events, and keep students updated"
                                    isLastPage={Math.ceil(groupsMetadata.total / groupsMetadata.page_size) === groupsMetadata.page_num + 1}
                                    items={data}
                                    listType={GridListTypes.Group}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: groupsMetadata.page_num + 1})}
                                    onOverlayClick={onRowClick ? (item) => onRowClick(item) : null}
                                    onPreviousPageClick={() => onMetadataChange({page_num: groupsMetadata.page_num - 1})}
                                    pageNumber={groupsMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
            >
                <CreateEditGroup
                    close={() => setShowNewContentModal(false)}
                />
            </NewContentModal>
        </div>
    );
};

export default ListGroups;
