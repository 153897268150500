import clone from "clone";
import _isNil from 'lodash/isNil';

import ClassNote from "../types/ClassNote";
import Comment from "../types/Comment";
import EventPost from "../types/EventPost";
import Thread from "../types/Thread";

import {isArrayNullOrEmpty, isObjectNullOrEmpty} from "./utils";

export const clonePostWithDeletedComment = (post, postCommentId) => {

    function seekAndDestroyComment(comments) {
        if (comments && comments.length > 0) {
            for (let i = 0; i < comments.length; i++) {
                let found = false;
                if (comments[i].postCommentId === postCommentId) {
                    console.log('found!');
                    console.log(comments[i]);
                    comments.splice(i, 1);
                    // comments[i].comments.push({...comment, profile});
                    found = true;
                } else if (comments[i].comments?.length) {
                    found = seekAndDestroyComment(comments[i].comments) !== undefined;
                } else {
                    found = false;
                }
                if (found) {
                    break;
                }
            }
        }
    }
    let newPost = clone(post);

    newPost.commentCount --;

    seekAndDestroyComment(newPost.comments);

    return newPost;
}

export const clonePostWithNewComment = (post: ClassNote | EventPost | Thread, parentPostCommentId: number, comment: Comment) => {

    function addCommentToParent(comments) {
        for (let i = 0; i < comments.length; i++) {
            let found = false;
            if (comments[i].postCommentId === parentPostCommentId) {
                if(!comments[i].childComments) {
                    comments[i].childComments = [];
                }
                comments[i].childComments.push({...comment});
                found = true;
            } else if (comments[i].childComments?.length) {
                found = addCommentToParent(comments[i].childComments) !== undefined;
            } else {
                found = false;
            }
            if (found) {
                break;
            }
        }
    }
    let newPost = clone(post);

    console.log(newPost);

    if (!parentPostCommentId) {
        if (!isArrayNullOrEmpty(newPost.comments)) {
            newPost.comments.push(comment)
        } else {
            newPost.comments = [comment];
        }
    } else {
        addCommentToParent(newPost.comments);
    }

    newPost.commentCount ++;

    return newPost;
}

export const cloneStudentsWithNewTags = (students, modifiedStudent) => {
    const clonedStudents = clone(students);
    let foundIndex = students.findIndex(s => s.profileId === modifiedStudent.profileId);
    clonedStudents[foundIndex] = modifiedStudent;
    return clonedStudents;
}

export const cloneFeedWithUpdatedPost = (feed, post) => {
    if(isObjectNullOrEmpty(post)) {
        return feed;
    }

    const clonedFeed = clone(feed);
    let foundIndex = clonedFeed.findIndex((fi) => fi.postId === post.postId);
    if(!_isNil(foundIndex)) {
        clonedFeed[foundIndex] = post;
    }
    return clonedFeed;
}

export const cloneListWithUpdatedGroup = (listOfGroups, group) => {
    if(isObjectNullOrEmpty(group)) {
        return listOfGroups;
    }

    const clonedList = clone(listOfGroups);
    let foundIndex = clonedList.findIndex((fi) => fi.forumTopicId === group.forumTopicId);
    if(!_isNil(foundIndex)) {
        clonedList[foundIndex] = group;
    }
    return clonedList;
}

export const cloneListWithUpdatedComment = (listOfComments: Comment[], comment: Comment) => {
    if(isObjectNullOrEmpty(comment)) {
        return listOfComments;
    }

    const clonedList = clone(listOfComments);
    let foundIndex = clonedList.findIndex((c: Comment) => c.postCommentId === comment.postCommentId);
    if(!_isNil(foundIndex)) {
        clonedList[foundIndex] = comment;
    }
    return clonedList;
}

export const cloneFeedWithDeletedPost = (feed, postId) => {
    if(_isNil(postId)) {
        return feed;
    }

    let clonedFeed = clone(feed);
    return clonedFeed.filter((fi) => fi.postId !== postId);
}

export const cloneMessagesWithError = (messages, payload) => {
    const messageId = payload.forumTopicMessageId;
    const error = payload.errorObject;
    const clonedMessages = clone(messages);

    clonedMessages.forEach((message) => {
        if (message.forumTopicMessageId === messageId) {
            message.error = error;
        }
    })

    return clonedMessages;
}
