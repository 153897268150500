import './CustomControlWithIcon.scss';

import React from 'react';
import { components, ControlProps } from 'react-select';
import _isNil from 'lodash/isNil';

import DropdownOption from "../../../types/DropdownOption";

import Icon from "../../Icon";

const CustomControlWithIcon: React.FC = (
    props: ControlProps<DropdownOption>
) => {
    return (
        <components.Control
            className="custom-control-with-icon"
            {...props}
        >
            <div
                className="custom-control-with-icon__inner"
                ref={props.innerRef}
                {...props.innerProps}
            >
                {_isNil(props.selectProps?.value) ? (
                    <>

                        <div className="custom-control-with-icon__icon-container">
                            <Icon type="paperclip" />
                        </div>

                        <div className="custom-control-with-icon__content">
                            <div className="custom-control-with-icon__label">
                                Select an Option
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        {props.selectProps?.value?.icon && (
                            <div className="custom-control-with-icon__icon-container">
                                <Icon type={props.selectProps.value.icon} />
                            </div>
                        )}

                        <div className="custom-control-with-icon__content">
                            <div className="custom-control-with-icon__label">
                                {props.selectProps.value.label}
                            </div>

                            <div className="custom-control-with-icon__sublabel">
                                {props.selectProps.value.sublabel}
                            </div>
                        </div>
                    </>
                )}


                <Icon
                    className="custom-control-with-icon__arrow-down"
                    type="caret-down"
                />

            </div>

            <div className="custom-control-with-icon__children">
                {props.children}
            </div>
        </components.Control>
    );
};

export default CustomControlWithIcon;
