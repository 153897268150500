import './FeedViewsRow.scss';
import React from 'react';

type Props = {
    viewCount?: number
    saveCount?: number
    rsvpCount?: number
}

const FeedViewsRow: React.FC<Props> = ({viewCount, saveCount, rsvpCount}) => {

    const renderCountsRow = () => {
        let counts: Array<string> = [];

        if (viewCount !== undefined) {
            counts.push((viewCount || 0) + (viewCount === 1 ? ' VIEW' : ' VIEWS'))
        }
        if (saveCount && saveCount > 0) {
            counts.push((saveCount || 0) + (saveCount === 1 ? ' SAVE' : ' SAVES'))
        }
        return counts.join(' • ');
    }

    return (
        <div className="c-feed-views-row__views">
            {renderCountsRow()}
        </div>
    );
};

export default FeedViewsRow;
