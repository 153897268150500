import './GroupChatMessage.scss';

import React from 'react';
import { useHistory, useRouteMatch } from "react-router";
import classNames from "classnames";
import moment from "moment";

import ChatMessage from "../../types/ChatMessage";

import buildImageSrc from "../../utils/buildImageSrc";
import { isObjectNullOrEmpty } from "../../utils/utils";

import { useTypedSelector } from "../../store/reducers";

import Avatar from "../Avatar";
import ParagraphsFromNewlines from "../ParagraphsFromNewlines";
import PendingImage from "./PendingImage";

export enum GroupChatMessageThemes {
    Main = 'main',
    Moderation = 'moderation',
}

type Props = {
    isMessageInFocus?: boolean
    message: ChatMessage
    theme?: GroupChatMessageThemes
}

const GroupChatMessage: React.FC<Props> = ({
    isMessageInFocus,
    message,
    theme,
}) => {
    const match = useRouteMatch();
    const history = useHistory();
    const { postAsProfile } = useTypedSelector(store => store.schools.activeSchool);

    const classes = classNames(
        'c-group-chat-message',
        {
            'a-group-chat-message--flagged': message.flagged,
            'a-group-chat-message--message-in-focus': isMessageInFocus,
            'a-group-chat-message--theme-main': theme === 'main',
            'a-group-chat-message--mine': message.senderId === postAsProfile?.profileId,
        }
    );

    return (
        <div className={classes}>
            <div role="link" onClick={() => history.push(`/school/${match?.params?.schoolId}/students/${message.sender?.profileId}/posts`)}>
                <Avatar
                    artifact={message.sender.artifact}
                />
            </div>

            <div className="c-group-chat-message__content">
                <div className="c-group-chat-message__header">
                    <div className="c-group-chat-message__name" role="link" onClick={() => history.push(`/school/${match?.params?.schoolId}/students/${message.sender?.profileId}/posts`)}>
                        {message.sender.firstName} {message.sender.lastName}
                    </div>

                    <div className="c-group-chat-message__time">
                        {moment(message.createdAt).format('h:mm a')}
                    </div>
                </div>

                {!isObjectNullOrEmpty(message.artifact) && (
                    <img className="c-group-chat-message__artifact" src={buildImageSrc(message.artifact, '667')} alt="Posted in a group chat" />
                )}

                <PendingImage message={message} />

                <ParagraphsFromNewlines
                    className="c-group-chat-message__body"
                    text={message.body}
                />
            </div>
        </div>
    );
};

export default GroupChatMessage;
