import './CreateEditUser.scss';

import React, { useState } from 'react';

import { useTypedSelector } from "../../../../../store/reducers";

import CreateEditUserForm from "./CreateEditUserForm";

type Props = {
    close?: Function
    show: boolean
}

const CreateEditUser: React.FC<Props> = ({
    close,
    show
}) => {
    const [useSameDataForAppProfile, setUseSameDataForAppProfile] = useState(true);

    const { user } = useTypedSelector((state) => state.users);

    return (
        <div className={`create-edit-user ${show ? 'a-create-edit-user--show' : ''}`}>
            <CreateEditUserForm
                close={close}
                credentials={{}}
                setUseSameDataForAppProfile={setUseSameDataForAppProfile}
                useSameDataForAppProfile={useSameDataForAppProfile}
                user={user}
            />
        </div>
    );
};

export default CreateEditUser;
