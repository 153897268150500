import './ManageTags.scss';

import React from 'react';

import Profile from "../../../../../types/Profile";

import ManageStudentTagsForm from "./ManageStudentTagsForm";

type Props = {
    closeModal: Function
    onSuccess: Function
    profile: Profile
}

const ManageStudentTags: React.FC<Props> = (
    {
        closeModal,
        onSuccess,
        profile,
    }
) => {

    return (
        <>
            <p>Manage {profile.firstName}'s tags</p>
            <ManageStudentTagsForm
                onCancel={closeModal}
                onSuccess={onSuccess}
                profile={profile}
            />
        </>
    );
};

export default ManageStudentTags;
