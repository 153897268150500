import './IntegrationsEventsList.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clone from 'clone';
import { useRouteMatch } from 'react-router';
import moment from 'moment';

import IntegrationEvent from "../../../../types/IntegrationEvent";

import { POST_STATUS } from "../../../../utils/constants";

import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';
import { deleteIntegrationEvent, getIntegrationEvents, setIntegrationEvent, setSearchTerm } from '../../../../store/slices/integrations';

import ActivityIndicator from '../../../../components/ActivityIndicator';
import EditIntegrationEvent from "../EditIntegrationEvent";
import H5 from '../../../../components/H5';
import Icon from "../../../../components/Icon";
import NewContentModal from "../../../../components/NewContentModal";
import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';
import TableFilterRow from "../../../../components/Table/TableFilterRow";
import TableMoreMenu from '../../../../components/Table/TableMoreMenu';
import TableRowImage from '../../../../components/Table/TableRowImage';
import TableSearch from '../../../../components/TableSearch';

const IntegrationsEventsList: React.FC = () => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const { events, integrationEventsMetadata, isDeletingIntegrationEvent, isGettingIntegrationEvents, searchTerm } = useTypedSelector(store => store.integrations);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getIntegrationEvents({schoolId: match?.params?.schoolId})).unwrap();
            } catch(err) {
                console.log('SchoolEvents initialize err', err);
            }
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            return [
                {
                    Header: 'Title',
                    accessor: 'title',
                    sortBy: 'title'
                }, {
                    Header: 'Location',
                    accessor: 'location.name',
                    sortBy: 'locationName'
                }, {
                    Header: 'Date',
                    accessor: (d) => (
                        <div className="event-date">
                            {d.startAt ? moment(d.startAt).format('MMM DD, YYYY') : ''}<br />
                            {d.startAt ? `${moment(d.startAt).format('hh:mma')}` : ''}{d.endAt ? <> - {moment(d.endAt).format('h:mma')}</> : ''}
                        </div>
                    ),
                    sortBy: 'startAt'
                }, {
                    Header: 'Image',
                    Cell: ({row: { original }}) => (
                        <TableRowImage
                            artifact={original.postStagingArtifact}
                        />
                    )
                }, {
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => (
                        <TableMoreMenu
                            hideModerationOptions
                            isDeleting={isDeletingIntegrationEvent}
                            onDeleteClick={() => handleDelete(original)}
                            onEditClick={() => {
                                let clonedEvent = clone(original);
                                clonedEvent.status = POST_STATUS.PUBLISHED;
                                dispatch(setIntegrationEvent(clonedEvent));
                                setShowNewContentModal(true);
                            }}
                        />
                    )
                }
            ];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const data = useMemo(
        () => {
            return events;
        },
        [events]
    );

    const handleDelete = async (post: IntegrationEvent) => {
        try {
            await dispatch(deleteIntegrationEvent({integrationEvent: post})).unwrap();
            onMetadataChange({}, false);
        } catch(err) {
            console.log('EventsList handleDelete err', err);
        }
    };

    const onMetadataChange = async (changes, isUpdate) => {
        console.log('onMetadataChange', changes, isUpdate);
        try {
            let clonedMetadata = clone(integrationEventsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getIntegrationEvents({isUpdate, integrationEventsMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('IntegrationEventsList onMetadataChange err', err);
        }
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="integrations-events-list">
            <PageHeader>
                <H5>
                    <Icon type="integration" />

                    Events via API / RSS
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search Events"
                    searchTerm={searchTerm}
                    size="small"
                />
            </TableFilterRow>

            {isGettingIntegrationEvents ? (
                <ActivityIndicator size="large" />
            ) : (
                <Table
                    columns={columns}
                    data={data}
                    getData={onMetadataChange}
                    pageNumber={integrationEventsMetadata.page_num}
                    pageSize={integrationEventsMetadata.page_size}
                    sortBy={integrationEventsMetadata.sort}
                    sortOrder={integrationEventsMetadata.order}
                    totalNumberOfItems={integrationEventsMetadata.total}
                />
            )}

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                <EditIntegrationEvent
                    close={() => setShowNewContentModal(false)}
                />
            </NewContentModal>
        </div>
    );
};

export default IntegrationsEventsList;
