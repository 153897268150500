import './DetailStats.scss'

import React, {useRef} from "react";
import {useEffect, useState} from "react";
import {useTypedSelector} from "../../../../../store/reducers";
import {getPresentationDetailData} from "../../../../../store/slices/detailStats";
import DetailStatsGrid from "./DetailStatsGrid";
import Datepicker from "../../../../../components/Datepicker";
import ActivityIndicator from "../../../../../components/ActivityIndicator";
import moment from "moment";
import H5 from "../../../../../components/H5";
import Card from "../../../../../components/Card";
import {useAppDispatch} from "../../../../../store";
import {useRouteMatch} from "react-router";
import Button, { ButtonTypes } from "../../../../../components/Button";

type Props = {
    date?: string
}

const DetailStats: React.FC<Props> = ({date}) => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();
    const datepickerRef = useRef(null);

    const [singleDayDetails, setSingleDayDetails] = useState(null);
    const [readyToShowDetails, setReadyToToShowDetails] = useState(false);
    const [searchStartDate, setSearchStartDate] = useState(date || null);
    const [searchEndDate, setSearchEndDate] = useState(date || null)

    const {isGettingPresentationDetailData, data} = useTypedSelector(state => state.presentationDetailData);

    useEffect(() => {
        initialize()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const initialize = async () => {
        if (isGettingPresentationDetailData) {
            return;
        }
        try {
            // if (searchStartDate) {
            //     await dispatch(getPresentationDetailData({tenantId: match?.params?.schoolId, startDate: searchStartDate as string})).unwrap();
            // }
            await dispatch(getPresentationDetailData({tenantId: match?.params?.schoolId, startDate: searchStartDate.toString(), endDate: searchEndDate.toString()})).unwrap();

        }
        catch (err) {
            console.log("Error getting details data: ", err);
        }
        finally {
            setReadyToToShowDetails(true);
            setSingleDayDetails(data);
        }
    }
    let stats = [];
    if (readyToShowDetails) {
        stats = [{
            name: 'Posts Tapped',
            value: data.posts.length,
            data: data.posts,
        },{
            name: 'Notifications Opened',
            value: data.notifications.length,
            data: data.notifications,
        }, {
            name: 'Screens Viewed',
            value: data.screenViewCount
        },{
            name: 'Foreground Events',
            value: data.foregroundCount
        },{
            name: 'Engagement Notification Tap Count',
            value: data.engagementPushClickCount
        },{
            name: 'Unidentified Notification Tap Count',
            value: data.unidentifiedPushCount
        }]
    }

    // const handleChange = (date) => {
    //     const formatted = moment(date).format('yyyy-MM-DD');
    //     setSearchStartDate(date);
    //     dispatch(getPresentationDetailData({tenantId: match?.params?.schoolId, date: formatted}));
    // }

    const handleStartDateChange = (date) => {
        const formatted = moment(date).format('yyyy-MM-DD');
        setSearchStartDate(formatted);
    }

    const handleEndDateChange = (date) => {
        const formatted = moment(date).format('yyyy-MM-DD');
        console.log("Formatted end date: " + formatted)
        setSearchEndDate(formatted as any)
    }

    const handleClick = () => {
        console.log("Search Date: " + searchStartDate)
        console.log("End Date: " + searchEndDate)
        setSingleDayDetails(null);
        initialize();
    }

    return (
        <Card className='single-day-details__card'>
            <Card className = 'single-day-details__card-options'>
                <H5 className='single-day-details__title'>
                    Profile Activity Details Dashboard
                </H5>
                <div className='single-day-details__date-grid'>
                    <div className='school-stats__date'>
                        <br/>
                        <div className='school-stats__date-title'>Select a Start Date:</div>
                        <Datepicker
                            className='school-stats__date-datepicker'
                            dateFormat="MM-dd-yyyy"
                            id="dateToSearch"
                            name="searchDate"
                            onChange={(newDate) => handleStartDateChange(newDate)}
                            placeholderText="mm-dd-yyyy"
                            required
                            selected={moment(searchStartDate).startOf("day").toDate()}
                            showMonthDropdown
                            showYearDropdown
                            thisRef={datepickerRef}
                        />
                        <br/>
                    </div>
                    <div className='school-stats__date'>
                        <br/>
                        <div className='school-stats__date-title'>Select an End Date:</div>
                        <Datepicker
                            className='school-stats__date-datepicker'
                            dateFormat="MM-dd-yyyy"
                            id="searchEndDate"
                            name="searchEndDate"
                            onChange={(newDate) => handleEndDateChange(newDate)}
                            placeholderText="mm-dd-yyyy"
                            required
                            selected={moment(searchEndDate).startOf("day").toDate()}
                            showMonthDropdown
                            showYearDropdown
                            thisRef={datepickerRef}
                        />
                        <br/>
                    </div>
                </div>
                <div className='school-stats__button'>
                    <Button
                        onClick={() => handleClick()}
                        type={ButtonTypes.Button}
                    >
                        Search
                    </Button>
                </div>
            </Card>
            <br/>
            {isGettingPresentationDetailData && (
                <Card className='single-day-details__loading'>
                    <p className='single-day-details__loading-title'>Loading</p>
                    <ActivityIndicator />
                </Card>
            )}
            {!isGettingPresentationDetailData && singleDayDetails && (
                <Card>
                    <H5 className='single-day-details__title'>
                        Details
                    </H5>
                    <DetailStatsGrid stats={stats} />
                </Card>
            )}
        </Card>
    );
}

export default DetailStats
