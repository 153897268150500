import './ViewGroupChat.scss';

import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import _uniqBy from "lodash/uniqBy";
import { useDropzone } from "react-dropzone";
import moment from "moment/moment";
import browserImageSize from "browser-image-size";

import GroupChatMessageBlock from "../../../../../../types/GroupChatMessageBlock";

import { isArrayNullOrEmpty } from "../../../../../../utils/utils";

import { addError } from "../../../../../../store/slices/errors";
import { uploadFiles } from "../../../../../../store/slices/upload";
import {
    clearMessages,
    getGroupChatMessages,
    markGroupChatAsRead,
    sendGroupChatMessage,
    setNewMessage,
    setNewMessageArtifact,
    setNewMessageRecentMedia,
    subscribeToMessages,
    updateGroupChatMessages,
} from "../../../../../../store/slices/groupChat";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import ActivityIndicator from "../../../../../../components/ActivityIndicator";
import Button, { ButtonThemes, ButtonTypes } from "../../../../../../components/Button";
import Card from "../../../../../../components/Card";
import GroupChatMessage, { GroupChatMessageThemes } from "../../../../../../components/GroupChatMessage";
import Icon from "../../../../../../components/Icon";
import FileUploadErrorModal from "../../../../../../components/Modal/FileUploadErrorModal";
import Form from "../../../../../../components/Form";
import FormRow from "../../../../../../components/FormRow";
import ProgressBar from "../../../../../../components/ProgressBar";
import Textbox from "../../../../../../components/Textbox";

const ViewGroupChat: React.FC = () => {
    const dispatch = useAppDispatch();

    const { isGettingMessages, isSendingMessage, rawMessages, newMessage, websocketStatus } = useTypedSelector((state) => state.groupChat);
    const { group } = useTypedSelector((state) => state.groups);
    const { uploadPercentage } = useTypedSelector((state) => state.upload);

    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [preview, setPreview] = useState(null);
    const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);
    const [atEnd, setAtEnd] = useState<boolean>(false);
    const [isInfiniteScrolling, setIsInfiniteScrolling] = useState<boolean>(false);
    const [canInfiniteScroll, setCanInfiniteScroll] = useState<boolean>(false);

    const messages = useMemo(() => {
        let messages: Array<GroupChatMessageBlock> = [];
        if (isArrayNullOrEmpty(rawMessages)) return messages;
        let messagesObject: any = {};

        rawMessages.forEach((m: any) => {
            let date = moment(m.createdAt).format('YYYY-MM-DD');
            if(!messagesObject[date]) {
                messagesObject[date] = [m];
            } else {
                messagesObject[date].push(m);
            }
        });

        Object.keys(messagesObject).forEach((key) => {
            messagesObject[key] = _uniqBy(messagesObject[key], 'forumTopicMessageId');
            messages.push({
                data: messagesObject[key],
                date: key,
                title: moment(key).format('MMM D, YYYY'),
            });
        });
        return messages;
    }, [rawMessages]);

    const [initialized, setInitialized] = useState(false);

    const sub = useCallback(() => {
        dispatch(subscribeToMessages({forumTopicId: group.forumTopicId}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialize = async () => {
            try {
                sub();

                if(group.forumTopicId) {
                    let res = await dispatch(getGroupChatMessages({forumTopicId: group.forumTopicId})).unwrap();
                    if(res.isInitialGet) {
                        setCanInfiniteScroll(!res.atEnd);
                        setAtEnd(res.atEnd === true);
                    }

                    if (!res.atEnd && res.rawMessages && group?.forumTopicId) {
                        dispatch(markGroupChatAsRead({forumTopicMessageId: res.rawMessages[0].forumTopicMessageId}));
                    }
                }
            } catch(err) {
                console.log('ViewGroup initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        if (!initialized) {
            initialize();
        }
        return () => {
            dispatch(clearMessages());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const handleInfiniteScroll = async () => {
        if(!canInfiniteScroll || isInfiniteScrolling || atEnd) {
            return;
        }

        try {
            setIsInfiniteScrolling(true);
            let res = await dispatch(updateGroupChatMessages({forumTopicId: group.forumTopicId})).unwrap();
            setCanInfiniteScroll(!res.atEnd);
            setAtEnd(res.atEnd);
        } catch(err) {
            console.log('ForumTopicChat handleInfiniteScroll err', err);
        } finally {
            setIsInfiniteScrolling(false);
        }
    }

    const handleChange = (msg) => {
        dispatch(setNewMessage(msg));
    }

    const clearImage = () => {
        setPreview(null);
        dispatch(setNewMessageArtifact(null));
    }

    const handleSend = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await dispatch(sendGroupChatMessage());
            // dispatch(setNewMessage(''));
            clearImage();
        } catch(err) {
            console.log('handleSend err', err);
        }
    }

    const onDrop = async (goodFiles, badFiles) => {

        if (badFiles && badFiles.length > 0) {
            setShowUploadErrorModal(true);
            return
        }
        let file = goodFiles[0];

        setIsUploadingImage(true);
        file.preview = URL.createObjectURL(file);
        setPreview(file);

        // Try getting image size first. This will catch errors before uploading.
        // This process needs to be improved in the next iteration of the admin.
        let imageSize;
        try {
            imageSize = await browserImageSize(file);
        } catch (err) {
            err.response = {
                status: 422 // Error needs a response status to be displayed to user
            }
            err.friendlyMessage = 'Error prepping file for upload. This is likely due to an unsupported file format.';
            setIsUploadingImage(false);
            setPreview(null);
            dispatch(addError(err))
        }

        // If we have image size, proceed with attempting to upload to the server
        if (imageSize) {
            try {
                let { height, width } = imageSize;
                let uploadResult: any = await dispatch(uploadFiles({ file, height, width, type: 'artifact' }));
                console.log('uploadResult', uploadResult);
                dispatch(setNewMessageArtifact(uploadResult.payload))
                dispatch(setNewMessageRecentMedia(uploadResult.payload));
                setIsUploadingImage(false);
            } catch (err) {
                console.log('upload file err', err);
                dispatch(addError(err))
                setPreview(null);
                setIsUploadingImage(false);
            }
        }
    };

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: 'image/jpeg, image/png, image/gif, image/webp, image/heic, image/heif',
        noClick: true,
        noKeyboard: true
    });

    return (
        <div {...getRootProps()} className="c-group-chat-container">
            <Card className="c-group-chat">

                <header className="c-group-chat__header">
                    <Icon type="comments" /> Chat
                </header>
                <div  className="c-group-chat__chats">
                    {isGettingMessages && (
                        <div className="c-group-chat__loading">
                            <ActivityIndicator size="large" />
                        </div>
                    )}
                    {messages.map((day, index) => {
                        return (
                            <div className="c-group-chat-day" key={index}>
                                <h2 className="c-group-chat-day__heading">{day.title}</h2>
                                <div className="c-group-chat-day__messages">
                                    {day.data.map((message, index) => {
                                        return (
                                            <div className="c-group-chat__message" key={index}>
                                                <GroupChatMessage message={message} theme={GroupChatMessageThemes.Main} />
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        )
                    })}

                    {!isArrayNullOrEmpty(messages) && (
                        <div className="c-conversation-thread__load-more-container">
                            {atEnd ? (
                                <span>No more messages</span>
                            ) : (
                                <Button
                                    disabled={isInfiniteScrolling}
                                    noStyles
                                    onClick={handleInfiniteScroll}
                                >
                                    Load More
                                </Button>
                            )}
                        </div>
                    )}
                </div>

                {websocketStatus !== 'connected' && (
                    <div className='c-group-chat__status'>
                        <Icon type="info" />
                        <p>Trying to reconnect...</p>
                    </div>
                )}
                <Form className="c-group-chat__new-message">
                    <FormRow>
                        <Textbox
                            id="txtTitle"
                            name="title"
                            required
                            type="text"
                            onChange={(e) => handleChange(e.target.value)}
                            value={newMessage}
                        />
                    </FormRow>

                    <FormRow>
                        {preview && (
                            <div className='conversation__new-message__image-preview'>
                                <img src={preview.preview} alt="" />

                                <Button
                                    className='conversation__new-message__image-preview__delete'
                                    noStyles
                                    onClick={() => clearImage()}
                                    theme={ButtonThemes.Light}
                                >
                                    <Icon type='x' />
                                </Button>
                            </div>
                        )}
                    </FormRow>

                    <FormRow className="c-group-chat__new-message__actions">
                        <Button
                            className="conversation__new-message-button"
                            noStyles
                            onClick={open}
                        >
                            <Icon type="image" />
                        </Button>

                        <input {...getInputProps()} />

                        <Button
                            disabled={isUploadingImage}
                            onClick={handleSend}
                            showActivityIndicator={isSendingMessage}
                            theme={ButtonThemes.Round}
                            type={ButtonTypes.Submit}
                        >
                            <Icon type="send" />
                        </Button>
                    </FormRow>
                </Form>

                {(isDragActive || isUploadingImage) && (
                    <div className='conversation__dragActive'>
                        <Icon type="image" className='conversation__dragActive__icon' />
                        {isUploadingImage ? (
                            <div className="conversation__dragActive__progress">
                                <ProgressBar progress={uploadPercentage} />
                            </div>
                        ) : (
                            <p> Drop an image here to upload. </p>
                        )}
                    </div>
                )}
            </Card>

            <FileUploadErrorModal
                close={() => setShowUploadErrorModal(false)}
                show={showUploadErrorModal}
            />
        </div>
    );
};

export default ViewGroupChat;
