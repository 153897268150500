import React from "react";

import moment from "moment";

import Avatar from "../../../../../../../components/Avatar";
import Card from "../../../../../../../components/Card";
import {DetailModalProps} from "../../index";
import FormRow from "../../../../../../../components/FormRow";
import FormColumn from "../../../../../../../components/FormColumn";
import H6 from "../../../../../../../components/H6";

const TopThreadDetailModal: React.FC<DetailModalProps> = ({data}) => {
    const formatDate = (date: Date) => {
        return moment(date).format('MMMM DD, YYYY');
    };

    return (
        <>
            <Card>
                <div className='dashboard-card__detail-main-col'>
                    <H6>
                        {data.post.title}
                    </H6>

                    <Avatar artifact={data.post.artifacts[0]} />
                </div>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div>
                            <div className='dashboard-card__detail-row-muted'>
                                Published
                            </div>

                            <div className='dashboard-card__detail-row'>
                                {formatDate(data.post.publishedAt)}
                            </div>
                        </div>
                    </FormColumn>

                    <FormColumn>
                        <div>
                            <div className='dashboard-card__detail-row-muted'>
                                School
                            </div>

                            <div className='dashboard-card__detail-row'>
                                {data.post.tenant.name}
                            </div>
                        </div>
                    </FormColumn>
                </FormRow>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div>
                            <div className='dashboard-card__detail-row-muted'>
                                Description
                            </div>

                            <div className='dashboard-card__detail-row'>
                                {data.post.description || 'Unknown'}
                            </div>
                        </div>
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Group
                        </div>

                        <div className='dashboard-card__detail-row'>
                            {data.post.forumTopic?.name || 'Unknown'}
                        </div>
                    </FormColumn>
                </FormRow>
            </Card>
            <Card>
                <H6>
                    Author
                </H6>
                <FormRow>
                    <div className='dashboard-card__detail-main-col'>
                        <H6>
                            {data.post.profile.firstName + ' ' + data.post.profile.lastName}
                        </H6>

                        <Avatar artifact={data.post.profile.artifact} />
                    </div>
                </FormRow>
                <H6>
                    Details
                </H6>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            School
                        </div>

                        <div className='dashboard-card__detail-row'>
                            {data.post.tenant?.name || 'Unknown'}
                        </div>
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Location
                        </div>

                        <div className='dashboard-card__detail-row'>
                            {data.post.profile?.location?.name || ' Unknown'}
                        </div>
                    </FormColumn>
                </FormRow>
            </Card>
            <FormRow columnCount={2}>
                <FormColumn>
                    <Card>
                        <H6>
                            Comments
                        </H6>

                        {data.commentCount}
                    </Card>
                </FormColumn>

                <FormColumn>
                    <Card>
                        <H6>
                            Saves
                        </H6>

                        {data.saveCount}
                    </Card>
                </FormColumn>
            </FormRow>
            <FormRow columnCount={2}>
                <FormColumn>
                    <Card>
                        <H6>
                            Upvotes
                        </H6>
                        {data.likeCount}
                    </Card>
                </FormColumn>

                <FormColumn>
                    <Card>
                        <H6>
                            Taps
                        </H6>

                        {data.tapCount}
                    </Card>
                </FormColumn>
            </FormRow>
        </>
    )
};

export default TopThreadDetailModal;
