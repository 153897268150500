import React from "react";

import Avatar from "../../../../../../../components/Avatar";
import Card from "../../../../../../../components/Card";
import {DetailModalProps} from "../../index";
import H6 from "../../../../../../../components/H6";
import FormColumn from "../../../../../../../components/FormColumn";
import FormRow from "../../../../../../../components/FormRow";

const TopDmUsersDetailModal: React.FC<DetailModalProps> = ({data}) => {
    return (
        <>
            <Card>
                <div className='dashboard-card__detail-main-col'>
                    <div>
                        <H6>
                            {data.profile.firstName + ' ' + data.profile.lastName}
                        </H6>

                        <div className='dashboard-card__detail-row-muted'>
                            {data.profile.tenant.name}
                        </div>
                    </div>

                    <Avatar artifact={data.profile.artifact}/>
                </div>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Conversations
                        </div>

                        <p>
                            {data.conversationCount}
                        </p>
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            About
                        </div>

                        <p>
                            {data.profile.about || 'Nothing to show.'}
                        </p>
                    </FormColumn>
                </FormRow>
                <FormRow>
                </FormRow>
            </Card>
            <Card>
                <H6>
                    Details
                </H6>
                <FormRow columnCount={3}>
                    <FormColumn className='dashboard-card__detail-row-muted'>
                        Major
                    </FormColumn>

                    <FormColumn className='dashboard-card__detail-row-muted'>
                        Minor
                    </FormColumn>

                    <FormColumn className='dashboard-card__detail-row-muted'>
                        Class Year
                    </FormColumn>
                </FormRow>
                <FormRow columnCount={3}>
                    <FormColumn className='dashboard-card__detail-stat-col'>
                        {data.profile?.details ? data.profile.details.filter(detail => detail?.element?.code === 'major')[0]?.elementDetail?.description || 'Not Set' : 'Unknown'}
                    </FormColumn>

                    <FormColumn className='dashboard-card__detail-stat-col'>
                        {data.profile?.details ? data.profile.details.filter(detail => detail?.element?.code === 'minor')[0]?.elementDetail?.description || 'Not Set' : 'Unknown'}
                    </FormColumn>

                    <FormColumn className='dashboard-card__detail-stat-col'>
                        {data.profile?.details ? data.profile.details.filter(detail => detail?.element?.code === 'gradyear')[0]?.elementDetail?.code || 'Not Set' : 'Unknown'}
                    </FormColumn>
                </FormRow>
            </Card>
        </>
    );
}

export default TopDmUsersDetailModal;
