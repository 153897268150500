import React from "react";

import {CardType} from "../../../index";
import DashboardCard from "../../DashboardCards/DashboardCard";
import {DashboardCardData} from "../../../../../../store/slices/superAdminDashboard";

type Props = {
    cardData: DashboardCardData,
    cardType: CardType
    initialized: boolean
    isGettingDashboard: boolean
}

const DashboardStat: React.FC<Props> = ({cardData, cardType, initialized, isGettingDashboard}) => {
    return (
        <DashboardCard
            cardData={cardData}
            cardType={cardType}
            initialized={initialized}
            isGettingDashboard={isGettingDashboard}
        />
    )
};

export default DashboardStat;
