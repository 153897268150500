import './FeedClassNote.scss';

import React from 'react';
import moment from 'moment';
import classNames from "classnames";

import {useHistory, useRouteMatch} from "react-router";

import Thread from "../../../types/Thread";

import buildImageSrc from '../../../utils/buildImageSrc';

import Avatar from '../../Avatar';
import Card from '../../Card';
import Comments from "../components/Comments";
import FeedViewsRow from '../components/FeedViewsRow';
import Icon from '../../Icon';
import ParagraphsFromNewlines from '../../ParagraphsFromNewlines';

type Props = {
    className?: string
    disableCommenting?: boolean
    inFlaggedContent?: boolean
    onEdit?: Function
    post: Thread
}

const FeedClassNote: React.FC<Props> = ({
    className = '',
    disableCommenting,
    inFlaggedContent,
    onEdit,
    post,
}) => {
    const match = useRouteMatch();
    const history = useHistory();

    const generateTimeStampForPost = (status: 'X' | 'A' | 'W') => {
        switch(status) {
            case "W":
                return (
                    <div className="c-feed-thread__date">
                        <div className="c-feed-thread__date-text">
                            Drafted {moment(post.createdAt).format('ddd, MMM DD, YYYY [•] hh:mm A')}
                        </div>
                        <Icon type="clock" className="c-feed-thread__date-icon"/>
                    </div>
                );
            case "X":
                return (

                <div className="c-feed-thread__date">
                    <div className="c-feed-thread__date-text">
                        Scheduled {moment(post.publishAt).format('ddd, MMM DD, YYYY [•] hh:mm A')}
                    </div>
                    <Icon type="document-regular" className="c-feed-thread__date-icon"/>
                </div>
                );
            case "A":
            default:
                return (
                    <div className="c-feed-thread__date">
                        <div className="c-feed-thread__date-text">
                            Published {moment(post.publishedAt || post.createdAt).format('ddd, MMM DD, YYYY [•] hh:mm A')}
                        </div>
                    </div>
                );
        }
    }



    const classes = classNames(
        'c-feed-thread c-feed-item',
        className,
        {
            'a-feed-item--flagged': post.flagged,
            'a-feed-item--removed-by-mods': post.removedByMods,
            'a-feed-item--super-approved': post.superApproval,
        }
    );


    return (
        <Card className={classes}>
            <div className="c-feed-thread__header" >
                {post.profile && (
                    <div role="link"  onClick={() => history.push(`/school/${match?.params?.schoolId}/students/${post.profile.profileId}/posts`)}>
                        <Avatar artifact={post.profile.artifact} />
                    </div>
                )}

                <div className="c-feed-thread__data c-feed-post__data">
                    {post.profile && (
                        <div
                            className="c-feed-thread__name"
                            role="link"
                            onClick={() => history.push(`/school/${match?.params?.schoolId}/${match?.params?.profileType}/students/${post.profile.profileId}/posts`)}
                        >
                            {post.profile.firstName} {post.profile.lastName}
                        </div>
                    )}

                    <div className="c-feed-thread__date">
                        {generateTimeStampForPost(post.status)}
                    </div>
                </div>

            </div>

            <div className="c-feed-thread__content">
                <ParagraphsFromNewlines
                    className="c-feed-thread__title c-feed-post__title"
                    linkify
                    text={post.title}
                />

                <ParagraphsFromNewlines
                    className="c-feed-thread__description"
                    linkify
                    text={post.description}
                />

                {post.artifacts && post.artifacts[0] && (

                    <>
                        {post.artifacts[0].type === 'image' && (
                            <img className="c-feed-thread__image" src={buildImageSrc(post.artifacts[0], 'original')} alt={post.title} />
                        )}
                        {post.artifacts[0].type === 'video' && (
                            <video className="c-feed-thread__image" controls src={post.artifacts[0].videoMp4Url} />
                        )}
                    </>
                )}


                <FeedViewsRow viewCount={post.totalViews} saveCount={post.saveCount} />
            </div>

            <Comments post={post} disableCommenting={disableCommenting} inFlaggedContent={inFlaggedContent} />
        </Card>
    );
};

export default FeedClassNote;
