import './ModerationOptionsModal.scss';

import React, { useState } from 'react';
import { useRouteMatch } from "react-router";
import { capitalize } from 'lodash';

import ChatMessage from "../../types/ChatMessage";
import ClassNote from '../../types/ClassNote';
import Comment from '../../types/Comment';
import Deal from "../../types/Deal";
import EventPost from "../../types/EventPost";
import Group from "../../types/Group";
import MarketplaceItem from "../../types/MarketplaceItem";
import ModerationData from "../../types/ModerationData";
import Profile from "../../types/Profile";
import Thread from "../../types/Thread";

import { ModerationStatuses } from "../../utils/enums";

import { moderateContent } from "../../store/slices/moderation";
import { useAppDispatch } from "../../store";
import { useTypedSelector } from "../../store/reducers";

import Checkbox from "../Checkbox";
import Modal from "../Modal";

type Props = {
    close: () => void
    getAfterModerate?: () => Promise<any>
    item: ChatMessage | ClassNote | Comment | Deal | EventPost | Group | MarketplaceItem | Profile | Thread
    show: boolean
    type?: 'giving' | 'group' | 'profile' | 'school' | 'user'

}

const ModerationOptionsModal: React.FC<Props> = ({
    close,
    getAfterModerate,
    item,
    show,
    type,
}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();
    const profileType = capitalize(params.profileType);

    const [selectedModerationOption, setSelectedModerationOption] = useState<ModerationStatuses>(null);

    const { isModeratingContent } = useTypedSelector((state) => state.moderation);

    const handleModerateContent = async () => {
        try {
            let moderationData: ModerationData = {
                moderationStatus: selectedModerationOption
            };

            await dispatch(moderateContent({moderationData, item, schoolId: params.schoolId}));
            getAfterModerate && getAfterModerate();
            close();
        } catch(err) {
            console.log('ModerationOptionsModal handleModerateContent err', err);
        } finally {
            setSelectedModerationOption(null);
        }
    };

    const noun = (type === 'profile' ? profileType : (type === 'group' ? 'Group' : 'Content'));

    return (
        <Modal
            confirmButtonDisabled={!selectedModerationOption}
            confirmButtonOnClick={handleModerateContent}
            confirmButtonText="Confirm"
            declineButtonOnClick={() => {
                close();
                setSelectedModerationOption(null);
            }}
            declineButtonText="Cancel"
            show={show}
            showActivityIndicator={isModeratingContent}
            title={`Moderate ${noun}`}
        >
            <div className="c-moderation-options-modal">
                <div className="c-moderation-options-modal__option">
                    <Checkbox
                        checked={selectedModerationOption === ModerationStatuses.Approved}
                        disabled={isModeratingContent || item.flagged !== true}
                        id="chkModerationStatusApproved"
                        label={type === 'profile' ? (
                            <>
                                <strong>{noun} is Fine</strong>
                                Moderator has reviewed the {noun.toLowerCase()}'s profile and content and doesn't believe it is problematic and should continue to have access the app.
                            </>
                        ) : (
                            <>
                                <strong>{noun} is Fine</strong>
                                Moderator has reviewed the flagged {noun.toLowerCase()} and doesn't believe it is problematic and should stay in the app.
                            </>
                        )}
                        name="moderationStatus"
                        onChange={() => setSelectedModerationOption(ModerationStatuses.Approved)}
                    />
                </div>

                <div className="c-moderation-options-modal__option">
                    <Checkbox
                        checked={selectedModerationOption === ModerationStatuses.Removed}
                        disabled={isModeratingContent}
                        id="chkModerationStatusRemoved"
                        label={type === 'profile' ? (
                            <>
                                <strong>{noun} Should be Removed</strong>
                                Moderator has reviewed the {noun.toLowerCase()}'s profile and content and has confirmed it is negative or problematic and should be immediately removed from the app, and student should no longer have access.
                            </>
                        ) : (
                            <>
                                <strong>{noun} Should be Removed</strong>
                                Moderator has reviewed the flagged {noun.toLowerCase()} and has confirmed it is negative or problematic and should be immediately removed from the app.
                            </>
                        )}
                        name="moderationStatus"
                        onChange={() => setSelectedModerationOption(ModerationStatuses.Removed)}
                    />
                </div>

                <div className="c-moderation-options-modal__option">
                    <Checkbox
                        checked={selectedModerationOption === ModerationStatuses.SuperApproval}
                        disabled={isModeratingContent || item.flagged !== true}
                        id="chkModerationStatusSuperApproval"
                        label={type === 'profile' ? (
                            <>
                                <strong>Permanent Approval</strong>
                                Moderator is confirming the {noun.toLowerCase()} and content is fine and the system should ignore all future flags/reports of this student's profile.
                            </>
                        ) : (
                            <>
                                <strong>Permanent Approval</strong>
                                Moderator is confirming the {noun.toLowerCase()} is fine and the system should ignore all future flags/reports of this {noun.toLowerCase()}.
                            </>
                        )}
                        name="moderationStatus"
                        onChange={() => setSelectedModerationOption(ModerationStatuses.SuperApproval)}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ModerationOptionsModal;
