import './CardVideo.scss';

import React from 'react';

type Props = {
    videoUrl: string
    className?: string
};

const CardVideo: React.FC<Props> = ({videoUrl, className = '',}) => {
    return (
        <div
            className={`c-card-video ${className}`}
        >
            <video src={videoUrl} className="c-card-video__video" controls />
        </div>
    );
};

export default CardVideo;
