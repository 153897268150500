import React, { useState } from 'react';

import { setShowMostActiveGroupsDetailModal } from '../../../../../../store/slices/superAdminDashboard';
import { useAppDispatch } from '../../../../../../store';
import { useTypedSelector } from '../../../../../../store/reducers';

import ActivityIndicator from '../../../../../../components/ActivityIndicator';
import Button, { ButtonThemes } from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import { CardType, DashboardCardProps } from '../../../index';
import DashboardCardModal from '../../DashboardCardModal';
import FormRow from '../../../../../../components/FormRow';
import FormColumn from '../../../../../../components/FormColumn';
import H5 from '../../../../../../components/H5';

const MostActiveGroupsCard: React.FC<DashboardCardProps> = ({cardData, initialized, isGettingDashboard}) => {
    const [detailData, setDetailData] = useState(null);
    const dispatch = useAppDispatch();

    const {showMostActiveGroupsDetailModal} = useTypedSelector(state => state.superAdminDashboard);
    const {title, subtitle, subtitles, data} = cardData;

    const handleClick = (value: any) => {
        dispatch(setShowMostActiveGroupsDetailModal(value));
    };

    return (
        <Card
            key={cardData.title}
            className="dashboard-card__card"
        >
            <div className="dashboard-card__row">
                <H5>
                    {title}
                </H5>
            </div>
            {isGettingDashboard && (
                <ActivityIndicator size={'large'}/>
            )}
            {initialized && !isGettingDashboard && (
                <>
                    <FormRow
                        key={subtitle}
                        columnCount={1}
                        className="dashboard-card__detail-row"
                    >
                        <FormColumn className="dashboard-card__detail-subtitle-col--primary">
                            {subtitle}
                        </FormColumn>

                        {subtitles.map(st => (
                            <FormColumn key={st} className="dashboard-card__detail-subtitle-col">
                                {st}
                            </FormColumn>
                        ))}
                    </FormRow>
                    {data && data.length > 0 && data.map((forumTopicData, i) => (
                        <FormRow
                            key={i}
                            columnCount={2}
                            className="dashboard-card__detail-row"
                        >
                            <FormColumn className="dashboard-card__detail-main-col">
                                <div>
                                    <div>
                                        {forumTopicData?.forumTopic?.name}
                                    </div>
                                    <div className="dashboard-card__detail-row-muted">
                                        {(forumTopicData.activeMemberCount).toLocaleString('en-US')} Active Members
                                    </div>
                                </div>
                            </FormColumn>

                            <FormColumn className="dashboard-card__detail-stat-col">
                                {(forumTopicData.postCount)}
                            </FormColumn>

                            <FormColumn className="dashboard-card__detail-stat-col details">
                                <Button
                                    theme={ButtonThemes.Link}
                                    onClick={() => {
                                        handleClick(!showMostActiveGroupsDetailModal);
                                        setDetailData(forumTopicData);
                                    }}>
                                    Details
                                </Button>
                            </FormColumn>
                        </FormRow>
                    ))}
                    {detailData && (
                        <DashboardCardModal
                            type={CardType.MostActiveGroups}
                            data={detailData}
                        />
                    )}
                </>
            )}
        </Card>
    );
};

export default MostActiveGroupsCard;
