import './CreateEditSponsorship.scss';

import React from 'react';

import { useTypedSelector } from "../../../../store/reducers";

import CreateEditSponsorshipForm from "./CreateEditSponsorshipForm";

type Props = {
    close?: Function
}

const CreateEditSponsorship: React.FC<Props> = ({
    close
}) => {
    const { sponsorship } = useTypedSelector((state) => state.sponsorships);

    return (
        <div className="create-edit-sponsorship">
            <CreateEditSponsorshipForm
                close={close}
                sponsorship={sponsorship}
            />
        </div>
    );
};

export default CreateEditSponsorship;
