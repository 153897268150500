import './ActivityIndicator.scss';

import React from 'react';

type Props = {
    color?: string,
    size?: 'large'
}

const ActivityIndicator: React.FC<Props> = ({
    color,
    size = '',
}) => (
    <div className={`c-activity-indicator c-activity-indicator--${size}`}>
        <img src={color === 'white' ? '/img/loader-ring-white.gif' : '/img/loader-ring-yellow.gif'} alt="Loading" />
    </div>
);

export default ActivityIndicator;
