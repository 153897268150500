import React, { useEffect, useState } from 'react';
import { useRouteMatch } from "react-router";
import moment from 'moment';

import buildImageSrc from "../../../../utils/buildImageSrc";

import { getMarketplaceItem } from '../../../../store/slices/marketplaceItems';
import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import Card from "../../../../components/Card";
import ProfileSummary from "../../../../components/ProfileSummary";

const ViewMarketplaceItem: React.FC = () => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const { marketplaceItem, isGettingMarketplaceItem } = useTypedSelector((state) => state.marketplace);

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const initialize = () => {
            if (match.params.postId && !isGettingMarketplaceItem) {
                dispatch(getMarketplaceItem({postId: match.params.postId}));
            }
            setIsInitialized(true);
        }
        if (!isInitialized) {
            initialize()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.postId]);

    return (
        <div>
            {(isGettingMarketplaceItem || !isInitialized )? (
                <Card>
                    <ActivityIndicator size={'large'} />
                </Card>
            ) : (
                <Card>
                    <ProfileSummary
                        profile={marketplaceItem.profile}
                        extraText={moment(marketplaceItem.createdAt).format('MMM D, YYYY • h:mm a')}
                    />
                    <h1>{marketplaceItem.title}</h1>
                    <img src={buildImageSrc(marketplaceItem.artifacts[0], '667')} alt=""/>
                    <p>{marketplaceItem.description}</p>
                    {marketplaceItem.freeItem ? (
                        <p>Free</p>
                    ) : (
                        <p>${marketplaceItem.price}</p>
                    )}
                </Card>
            )}
        </div>
    )
}
export default ViewMarketplaceItem;
