import './TableFilterRowGroup.scss';

import React from 'react';

type Props = {
    children: React.ReactNode
    className?: string
}

const TableFilterRowGroup: React.FC<Props> = ({
                                             children,
                                             className = ''
                                         }) => {
    return (
        <div className={`c-table-filter-row-group ${className}`}>
            {children}
        </div>
    );
};

export default TableFilterRowGroup;
