import './MassDM.scss';
import React, { useState } from 'react';
import { useRouteMatch } from "react-router";

import { isArrayNullOrEmpty } from "../../../../../../utils/utils";
import { AdmissionStatus, ProfileTypes } from "../../../../../../utils/enums";

import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";
import {
    getConversations,
    resetConversationPagination,
    sendMessage,
} from "../../../../../../store/slices/directMessages";
import Avatar from "../../../../../../components/Avatar";

import ConversationNewMessage from "../Conversation/components/ConversationNewMessage";
import Icon from "../../../../../../components/Icon";
import ListAdmits from "../../../Admits/ListAdmits";
import ListAlumni from "../../../Alumni/ListAlumni";
import ListStudents from "../../../SchoolStudents/ListStudents";
import SuccessModal from "../../../../../../components/Modal/SuccessModal";

type Props = {
    close?: Function
}
const MassDM: React.FC<Props> = ({close}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [ showSuccessModal, setShowSuccessModal] = useState(false);
    const [ selectedProfiles, setSelectedProfiles] = useState([]);

    const { isSendingMessage } = useTypedSelector((state) => state.directMessages);

    const handleAddRemoveProfile = (profile) => {
        let newSelectedProfiles = [...selectedProfiles];
        let index = newSelectedProfiles.findIndex((p) => p.profileId === profile.profileId);
        if(index > -1) {
            newSelectedProfiles.splice(index, 1);
        } else {
            newSelectedProfiles.push(profile);
        }
        setSelectedProfiles(newSelectedProfiles);
    }

    const handleSendMessage = async (e) => {
        try {
            e.preventDefault();
            const recipientIds = selectedProfiles.map((profile) => profile.profileId);
            await dispatch(sendMessage({
                profileType: params?.profileType,
                schoolId: params?.schoolId,
                recipientIds
            })).unwrap();
            dispatch(resetConversationPagination());
            dispatch(getConversations({hideSpinner: true, schoolId: params?.schoolId, profileType: params.profileType}));
            setShowSuccessModal(true);
        } catch(err) {
            console.warn('Conversation handleSendMessage err', err);
        }
    }

    const renderNoun = () => {
        switch (params.profileType) {
            case ProfileTypes.Student:
                if (selectedProfiles.length === 1) {
                    return 'Student';
                } else {
                    return 'Students';
                }
            case ProfileTypes.Admit:
                if (selectedProfiles.length === 1) {
                    return 'Admit';
                } else {
                    return 'Admits';
                }
            case ProfileTypes.Alumni:
                if (selectedProfiles.length === 1) {
                    return 'Alum';
                } else {
                    return 'Alumni';
                }
            default:
                return 'User(s)';
        }
    }

    return (
        <div className="multi-profile-select">
            <div className="multi-profile-select__left">
                {params.profileType === ProfileTypes.Admit && (
                    <ListAdmits
                        filters={{ admission_status: AdmissionStatus.Admit }}
                        fromDirectMessages={true}
                        inSelectAdmitsList
                        onRowClick={handleAddRemoveProfile}
                    />
                )}
                {params.profileType === ProfileTypes.Student && (
                    <ListStudents
                        onRowClick={handleAddRemoveProfile}
                    />
                )}
                {params.profileType === ProfileTypes.Alumni && (
                    <ListAlumni
                        onRowClick={handleAddRemoveProfile}
                        fromDirectMessages={true}
                    />
                )}
            </div>
            <div className="multi-profile-select__right">

                <div className="multi-profile-select__recipients">
                    {selectedProfiles.map((user, index) => {
                        return (
                            <button
                                key={index}
                                className={`c-multi-profile-select__selected-profile`}
                                onClick={() => handleAddRemoveProfile(user)}
                            >
                                <Avatar artifact={user.artifact} />
                                <div>
                                    <div className="c-multi-profile-select__selected-profile__name">{user.firstName} {user.lastName}</div>
                                </div>

                                    <Icon type="x" />

                            </button>
                        )
                    })}
                </div>
                <div className="multi-profile-select__form">
                    {!isArrayNullOrEmpty(selectedProfiles) && (
                        <div className="c-alert c-alert--info multi-profile-select__alert">
                            <p>Message will send to <strong>{selectedProfiles.length}</strong> {renderNoun()}</p>
                        </div>
                    )}
                    <ConversationNewMessage
                        onSendMessage={handleSendMessage}
                        isSendingMessage={isSendingMessage}
                    />
                </div>

            </div>
            <SuccessModal
                buttonOnClick={() => {
                    setShowSuccessModal(false);
                    close && close();
                }}
                buttonText="Done"
                show={showSuccessModal}
                title="Nice job!"
            >
                <p>
                    Your message has been sent.
                </p>
            </SuccessModal>
        </div>
    );
};

export default MassDM;
