import axios, { AxiosRequestConfig } from 'axios';
import environment from "./environment";
import { setLoginRedirectURL } from "./routing";
import { clearLocalStorageData } from "./auth";

export default class Request {
    axiosConfig: AxiosRequestConfig
    request: {
        delete: Function
        get: Function
        patch: Function
        post: Function
        put: Function
    }

    constructor(token?: string) {
        this.axiosConfig = {
            baseURL: environment.apiUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            validateStatus: status => isRequestSuccessful(status),
        };

        if (token != null) {
            this.axiosConfig.headers['X-Auth-Token'] = token;
        }

        const req = axios.create(this.axiosConfig);

        const responseSuccessHandler = (response) => {
            return response;
        }

        const responseErrorHandler = (error) => {
            // If we get a 401 response not related to a login request (session expires),
            // clear localStorageData, then hard redirect to expired session page
            if (error.response.status === 401 && !error.response?.config?.url?.includes('auth/login')) {
                clearLocalStorageData();

                // Save the URL the user attempted to access so we can redirect them back to it
                setLoginRedirectURL();

                window.setTimeout(() => {
                    // Force the browser to refresh to cancel any pending requests
                    window.location.href = '/auth/expired';
                })
            }
            return Promise.reject(error);
        }

        req.interceptors.response.use(
            response => responseSuccessHandler(response),
            error => responseErrorHandler(error)
        )
        this.request = req;
    }

    get = (url: string, config?: object) => this.request.get(url, config);

    post = (url: string, data?: object, config?: object) => this.request.post(url, data, config);

    patch = (url: string, data?: object, config?: object) => this.request.patch(url, data, config);

    delete = (url: string, config?: object) => this.request.delete(url, config);

    put = (url: string, data?: object, config?: object) => this.request.put(url, data, config);
}

export const isRequestSuccessful = (status: number) => status >= 200 && status < 300;
