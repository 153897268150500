import '../../ContentForms.scss'

import React, { useEffect, useState } from 'react';
import { validated } from "react-custom-validation";
import clone from "clone";

import MarketplaceItem from "../../../../types/MarketplaceItem";
import Validation from "../../../../types/Validation";

import { isRequired, isRequiredIfTrue } from "../../../../utils/validations";
import { handleCheckboxChange, handleTextChange } from "../../../../utils/handle-changes";

import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from "../../../../store/reducers";
import { saveMarketplaceItem, deleteMarketplaceItem, setMarketplaceItem, clearMarketplaceItem, getMarketplaceItems } from "../../../../store/slices/marketplaceItems";

import Button, { ButtonThemes, ButtonTypes } from "../../../../components/Button";
import ButtonRow from "../../../../components/ButtonRow";
import Checkbox from "../../../../components/Checkbox";
import FileUpload from "../../../../components/FileUpload";
import Form from "../../../../components/Form";
import FormRow from "../../../../components/FormRow";
import FormValidationMessage from "../../../../components/FormValidationMessage";
import H4 from "../../../../components/H4";
import Label from "../../../../components/Label";
import Modal from "../../../../components/Modal";
import PostingAsCard from "../../../../components/PostingAsCard";
import Radio from "../../../../components/Radio";
import RadioGroup from "../../../../components/RadioGroup";
import SuccessModal from "../../../../components/Modal/SuccessModal";
import Textbox from "../../../../components/Textbox";
import VisibilityCard from '../../../../components/VisibilityCard';

type Props = {
    close?: Function
    marketplaceItem: MarketplaceItem
    $field: Function
    $fieldEvent: Function
    $validation: {
        artifactIds: Validation
        title: Validation
        description: Validation
        price: Validation
        usedItem: Validation
        freeItem: Validation
    }
    $submit: Function
}

const CreateEditMarketplaceItemForm: React.FC<Props> = ({
    close,
    marketplaceItem,
    $field,
    $fieldEvent,
    $validation,
    $submit
}) => {
    const dispatch = useAppDispatch();

    const [oldPrice, setOldPrice] = useState('');
    const [isUploadingMainArtifact, setIsUploadingMainArtifact] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const { profile: authUser } = useTypedSelector((state) => state.auth);
    const { activeSchool } = useTypedSelector(store => store.schools);
    const { isSavingMarketplaceItem, isDeletingMarketplaceItem } = useTypedSelector(store => store.marketplace);

    useEffect(() => {
        if (marketplaceItem.artifacts?.length > 0) {
        }

        if(!marketplaceItem.artifactIds || marketplaceItem.artifactIds.length === 0) {
            let artifactIds = marketplaceItem.artifacts?.map((a) => a.artifactId);
            if((!artifactIds || artifactIds.length === 0) && marketplaceItem.artifact) {
                artifactIds = [marketplaceItem.artifact.artifactId];
            }
            handleChange('artifactIds', artifactIds);
        }

        return () => {
            setTimeout(() => dispatch(clearMarketplaceItem()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name, value) => {
        let clonedMarketplaceItem = clone(marketplaceItem);
        if (name === 'isFree') {
            if (value) {
                clonedMarketplaceItem.freeItem = 'Y';
                setOldPrice(clonedMarketplaceItem.price);
                clonedMarketplaceItem.price = '0';
            } else {
                clonedMarketplaceItem.freeItem = 'N';
                clonedMarketplaceItem.price = oldPrice;
            }
        }

        if(name === 'status') {
            clonedMarketplaceItem.status = value;

            if(value === 'A') {
                delete clonedMarketplaceItem.publishAt;
            }
        } else {
            clonedMarketplaceItem[name] = value;
        }

        dispatch(setMarketplaceItem(clonedMarketplaceItem));
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteMarketplaceItem({})).unwrap();
            $fieldEvent('reset');
            dispatch(getMarketplaceItems({}));
            close && close();
        } catch (err) {
            console.log('CreateEditMarketplaceItemForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const onUploadStart = () => {
        setIsUploadingMainArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange('artifactIds', [artifactId]);
        }
        setIsUploadingMainArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingMainArtifact(false);
    };

    const [uploadKey, setUploadKey] = useState(0);

    const onDelete = () => {
        handleChange('artifactIds', []);
        setIsUploadingMainArtifact(false);
        setUploadKey(prevKey => prevKey + 1);
    };


    const handleSave = async (isValid) => {
        if(isValid) {
            try {
                await dispatch(saveMarketplaceItem({marketplaceItem})).unwrap()
                setShowSuccessModal(true);
                setTimeout(() => {
                    dispatch(clearMarketplaceItem());
                    $fieldEvent('reset');
                })
            } catch(err) {
                console.log('err from creating post', err);
            }
        }
    };

    return (
        <Form className="content-form">
            <H4>
                {marketplaceItem.postId ? 'Edit ' : 'Create a '}Marketplace Item
            </H4>

            <FormRow>
                <PostingAsCard
                    profile={marketplaceItem.profile || activeSchool.postAsProfile}
                    user={!marketplaceItem.profile || marketplaceItem.profile === activeSchool.postAsProfile ? authUser : null}
                />
            </FormRow>

            <FormRow>
                <FileUpload
                    acceptsVideo={true}
                    currentArtifact={marketplaceItem.artifacts?.[0] || null}
                    key={uploadKey}
                    onDelete={onDelete}
                    name="artifactIds"
                    onStart={onUploadStart}
                    onSuccess={onUploadSuccess}
                    onFailure={onUploadFailure}
                />
                {$validation.artifactIds.isValid === false && $validation.artifactIds.show === true ? (
                    <FormValidationMessage>
                        {$validation.artifactIds.error.reason}
                    </FormValidationMessage>
                ) : null}
            </FormRow>
            <FormRow>
                <Textbox
                    disabled={isUploadingMainArtifact}
                    id="txtTitle"
                    label="Title"
                    name="title"
                    required
                    type="text"
                    validation={$validation.title}
                    value={marketplaceItem.title || ''}
                    {...$field('title', event => handleTextChange(handleChange, event))}
                />
            </FormRow>
            <FormRow>
                <Textbox
                    disabled={isUploadingMainArtifact}
                    id="txtDescription"
                    label="Description"
                    name="description"
                    required
                    type="textarea"
                    validation={$validation.description}
                    value={marketplaceItem.description || ''}
                    {...$field('description', event => handleTextChange(handleChange, event))}
                />
            </FormRow>
            <FormRow>
                <Label>Condition <span className="c-label__required-asterisk">*</span></Label>
                <RadioGroup horizontal>
                    <Radio
                        disabled={isUploadingMainArtifact}
                        label="Used"
                        checked={marketplaceItem.usedItem === 'Y'}
                        id="usedItenY"
                        key="usedItenY"
                        name="usedItem"
                        onChange={() => handleChange('usedItem', 'Y')}
                        value="Used"
                    />
                    <Radio
                        disabled={isUploadingMainArtifact}
                        label="New"
                        checked={marketplaceItem.usedItem === 'N'}
                        id="usedItemN"
                        key="usedItemN"
                        name="usedItem"
                        onChange={() => handleChange('usedItem', 'N')}
                        value="New"
                    />
                </RadioGroup>
                {$validation.usedItem.isValid === false && $validation.usedItem.show === true ? (
                    <FormValidationMessage>
                        {$validation.usedItem.error.reason}
                    </FormValidationMessage>
                ) : null}
            </FormRow>
            <FormRow>
                <Checkbox
                    label="Item Is Free"
                    checked={marketplaceItem.freeItem === 'Y'}
                    id='chkFreeItem'
                    name="isFree"
                    onChange={(e) => handleCheckboxChange(handleChange, e)}
                />
            </FormRow>

            {marketplaceItem.freeItem !== 'Y' && (
                <FormRow>
                    <Textbox
                        disabled={isUploadingMainArtifact}
                        id="txtPrice"
                        label="Price"
                        name="price"
                        required
                        type="number"
                        validation={$validation.price}
                        value={marketplaceItem.price || ''}
                        {...$field('price', event => handleTextChange(handleChange, event))}
                    />
                </FormRow>
            )}

            <VisibilityCard
                handleChange={handleChange}
                post={marketplaceItem}
            />

            <ButtonRow>
                <Button
                    disabled={isUploadingMainArtifact}
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                    showActivityIndicator={isSavingMarketplaceItem}
                    type={ButtonTypes.Submit}
                >
                    Save
                </Button>

                {marketplaceItem.postId && (
                    <Button
                        className="destructive"
                        disabled={isUploadingMainArtifact}
                        onClick={() => setShowDeleteConfirmationModal(true)}
                        theme={ButtonThemes.Link}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getMarketplaceItems({}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
            >
                <p>
                    Your listing has been saved.
                </p>
                <p>
                    It may take a minute for your listing to show in feeds and lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingMarketplaceItem}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this listing?
            </Modal>

        </Form>
    );
}

function createEditMarketplaceItemFormValidationConfig(props) {
    const { artifactIds, title, description, price, usedItem, freeItem } = props.marketplaceItem;

    return {
        fields: ['artifactIds', 'title', 'description', 'usedItem', 'price'],
        validations: {
            artifactIds: [
                [isRequired, artifactIds]
            ],
            title: [
                [isRequired, title]
            ],
            description: [
                [isRequired, description]
            ],
            usedItem: [
                [isRequired, usedItem]
            ],
            price: [
                [isRequiredIfTrue, price, freeItem !== 'Y', 'A price is required unless the item is marked as free']
            ],
        }
    }
}
export default validated(createEditMarketplaceItemFormValidationConfig)(CreateEditMarketplaceItemForm);
