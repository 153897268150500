import './ListStudents.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from "react-router";
import clone from 'clone';

import NewContentMenuOption from "../../../../../types/NewContentMenuOption";
import Profile from "../../../../../types/Profile";
import TableColumn from "../../../../../types/TableColumn";

import { ListTypes, ProfileTypes } from "../../../../../utils/enums";
import { isArrayNullOrEmpty } from "../../../../../utils/utils";

import {
    clearInviteStudentsViaCsvImportError,
    clearInviteStudentsViaEmailListError,
    clearStudentsMetadata,
    getStudents,
    setSearchTerm,
    setStudent,
} from "../../../../../store/slices/students";
import {useAppDispatch} from "../../../../../store";
import {useTypedSelector} from "../../../../../store/reducers";

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Avatar from "../../../../../components/Avatar";
import Button, { ButtonSizes, ButtonThemes } from "../../../../../components/Button";
import Checkbox from "../../../../../components/Checkbox";
import GridList, { GridListTypes } from "../../../../../components/GridList";
import H5 from "../../../../../components/H5";
import Icon from "../../../../../components/Icon";
import InviteStudentsViaCsvImport from "./InviteStudentsViaCsvImport";
import InviteStudentsViaEmail from "./InviteStudentsViaEmail";
import ListTypeSelector from "../../../../../components/ListTypeSelector";
import Modal from "../../../../../components/Modal";
import NewContentMenu from "../../../../../components/NewContentMenu";
import PageHeader from "../../../../../components/PageHeader";
import StudentsTableMoreMenu from "./StudentsTableMoreMenu";
import SuccessModal from "../../../../../components/Modal/SuccessModal";
import Table from "../../../../../components/Table";
import TableFilterRow from "../../../../../components/Table/TableFilterRow";
import TableFilterRowDivider from "../../../../../components/Table/TableFilterRow/TableFilterRowDivider";
import TableFlaggedOnlyToggle from "../../../../../components/Table/TableFlaggedOnlyToggle";
import TableSearch from "../../../../../components/TableSearch";

type Props = {
    actionButtonOnClick?: Function
    actionButtonText?: string
    bulkActionButtonText?: string
    bulkSelectedItems?: Array<number>
    onBulkSelected?: Function
    onBulkSubmit?: Function
    onRowClick?: (item: Profile) => void
}

export enum ProfileNounLookup {
    'student' = 'Students',
    'alumni' = 'Alumni',
    'admit' = 'Admits',
}

const ListStudents: React.FC<Props> = ({
    bulkActionButtonText,
    actionButtonText,
    actionButtonOnClick,
    bulkSelectedItems,
    onBulkSelected,
    onBulkSubmit,
    onRowClick,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [selectedListType, setSelectedListType] = useState<ListTypes>(ListTypes.Table);
    const [showNewContentMenu, setShowNewContentMenu] = useState<boolean>(false);
    const [showInviteViaEmailModal, setShowInviteViaEmailModal] = useState<boolean>(false);
    const [showInviteSuccessModal, setShowInviteSuccessModal] = useState<boolean>(false);
    const [showUploadCsvModal, setShowUploadCsvModal] = useState<boolean>(false);
    const [showUploadCsvSuccessModal, setShowUploadCsvSuccessModal] = useState<boolean>(false);

    const { isGettingStudents, searchTerm, students, studentsMetadata } = useTypedSelector((state) => state.students);

    useEffect(() => {
        const initialize = async () => {
            try {
                let clonedStudentsMetadata = clone(studentsMetadata);
                clonedStudentsMetadata.alumni_only = params.profileType === ProfileTypes.Alumni;
                await dispatch(getStudents({
                    schoolId: params?.schoolId,
                    studentsMetadata: clonedStudentsMetadata
                })).unwrap();
            } catch(err) {
                console.log('ListStudents initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            dispatch(clearStudentsMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Name',
                    id: 'student',
                    Cell: ({row: { original }}) => (
                        <div className="users-table__user">
                            <Avatar
                                artifact={original.artifact}
                                flagged={original.flagged || original.flaggedContent}
                            />

                            <div className="users-table__name">
                                {original.firstName} {original.lastName}
                            </div>
                        </div>
                    ),
                    hasImage: true,
                    sortBy: 'name',
                }, {
                    Header: 'Email',
                    accessor: 'emailAddress',
                    sortBy: 'emailAddress'
                }, {
                    Header: 'Major / Minor',
                    id: 'degrees',
                    Cell: ({row: { original }}) => {
                        let major = original.major ? original.major.filter((m) => m != null) : [];
                        let minor = original.minor ? original.minor.filter((m) => m != null) : [];
                        let degrees = [
                            ...major,
                            ...minor,
                        ];
                        degrees = degrees.map((d) => d.displayText || d.description);
                        return (
                            <div className="users-table__majors">
                                {isArrayNullOrEmpty(degrees) ? '--' : degrees.join(', ')}
                            </div>
                        )
                    },
                }, {
                    Header: 'Tags',
                    id: 'tags',
                    Cell: ({row: { original }}) => {
                        let tags = original.tags ? original.tags.map((t) => t.tag.text) : [];
                        return (
                            <div className="users-table__majors">
                                {isArrayNullOrEmpty(tags) ? '--' : tags.join(', ')}
                            </div>
                        )
                    },
                }, {
                    Header: 'Class Year',
                    id: 'classYear',
                    Cell: ({row: { original }}) => {
                        return (
                            <div className="users-table__majors">
                                {isArrayNullOrEmpty(original.gradyear) || !original.gradyear[0] ? '--' : (original.gradyear[0].displayText || original.gradyear[0].description)}
                            </div>
                        )
                    },
                    sortBy: 'classYear'
                }, {
                    Header: 'Admin User',
                    id: 'adminUser',
                    Cell: ({row: { original }}) => original.adminUser ? (
                        <div className="users-table__user">
                            <Avatar
                                artifact={original.adminUser.artifact}
                            />

                            <div className="users-table__name">
                                {original.adminUser.firstName} {original.adminUser.lastName}
                            </div>
                        </div>
                    ) : null,
                    hasImage: true,
                }
            ];

            if (actionButtonText && actionButtonOnClick) {
                columns = [
                    ...columns,
                    {
                        Header: '',
                        id: 'actionButton',
                        Cell: ({row: { original }}) => {
                            return (
                                <Button
                                    theme={ButtonThemes.Small}
                                    onClick={() => actionButtonOnClick(original)}
                                >
                                    {actionButtonText}
                                </Button>
                            )
                        },
                    }
                ]
            }

            if(onBulkSelected) {
                columns = [
                    {
                        Header: '',
                        id: 'bulkSelect',
                        Cell: ({row: {original}}) => (
                            <Checkbox
                                checked={bulkSelectedItems?.includes(original.profileId)}
                                className="a-checkbox--bulk-select"
                                id={`chkProfile${original.profileId}`}
                                label=""
                                name={`profile${original.profileId}`}
                                onChange={() => onBulkSelected(original.profileId)}
                            />
                        ),
                        sortable: false,
                    },
                    ...columns
                ];
            } else {
                columns = [
                    ...columns,
                    {
                        Header: '',
                        id: 'actions',
                        Cell: ({row: { original }}) => renderMoreMenu(original)
                    }
                ]
            }

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [bulkSelectedItems]
    );

    const data = useMemo(
        () => {
            return students;
        },
        [students]
    );

    const newContentMenuOptions: Array<NewContentMenuOption> = useMemo(
        () => {
            return [
                {
                    icon: 'envelope',
                    onClick: () => setShowInviteViaEmailModal(true),
                    subtext: 'Invite students by email',
                    text: 'By email',
                }, {
                    icon: 'document-x',
                    onClick: () => setShowUploadCsvModal(true),
                    subtext: 'Bulk import a list',
                    text: 'Import CSV',
                }
            ]
        }, []
    );

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(studentsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };
            return await dispatch(getStudents({isUpdate, studentsMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('ListStudents onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (student) => {
        if(student.removedByMods) {
            return null;
        }

        return (
            <StudentsTableMoreMenu
                getAfterDelete={() => onMetadataChange({}, false)}
                item={student}
            />
        );
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="list-students">
            <PageHeader>
                <H5>
                    <Icon type="navigation-users" /> Students
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableFlaggedOnlyToggle
                    label="Flagged only"
                    onToggleChange={() => onMetadataChange({flagged: !studentsMetadata.flagged, page_num: 0}, false)}
                    value={studentsMetadata.flagged}
                />

                <TableFilterRowDivider />

                {!onBulkSelected && (
                    <>
                        <ListTypeSelector
                            onListTypeSelected={(listType) => setSelectedListType(listType)}
                            selected={selectedListType}
                        />

                        <TableFilterRowDivider />
                    </>
                )}

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder={`Search ${ProfileNounLookup[params.profileType]}`}
                    searchTerm={searchTerm}
                    size="small"
                />

                {!onBulkSelected ? (
                    <>
                        <Button
                            onClick={() => setShowNewContentMenu(true)}
                            size={ButtonSizes.Small}
                        >
                            Add Students
                        </Button>

                        <NewContentMenu
                            close={() => setShowNewContentMenu(false)}
                            options={newContentMenuOptions}
                            runCloseOnClick
                            show={showNewContentMenu}
                        />
                    </>
                ) : (
                    <Button
                        disabled={isArrayNullOrEmpty(bulkSelectedItems)}
                        onClick={onBulkSubmit}
                        size={ButtonSizes.Small}
                    >
                        {bulkActionButtonText || 'Select'}
                    </Button>
                )}
            </TableFilterRow>

            {isGettingStudents ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                            No students to display.
                        </>
                    ) : (
                        <>
                            {selectedListType === ListTypes.Table ? (
                                <Table
                                    columns={columns}
                                    data={data}
                                    getData={onMetadataChange}
                                    onRowClick={({original}) => {
                                        if(onBulkSelected) {
                                            onBulkSelected(original.profileId);
                                        } else if(onRowClick) {
                                            onRowClick(original);
                                        } else if (!actionButtonOnClick) {
                                            dispatch(setStudent(original));
                                            history.push(`/school/${original.tenant.tenantId}/${params.profileType}/students/${original.profileId}/posts`)
                                        }
                                    }}
                                    pageNumber={studentsMetadata.page_num}
                                    pageSize={studentsMetadata.page_size}
                                    sortBy={studentsMetadata.sort}
                                    sortOrder={studentsMetadata.order}
                                    totalNumberOfItems={studentsMetadata.total}
                                />
                            ) : (
                                <GridList
                                    /*createCardButtonOnClick={() => setShowNewUserModal(true)}
                                    createCardButtonText="Create an Admin"*/
                                    isLastPage={Math.ceil(studentsMetadata.total / studentsMetadata.page_size) === studentsMetadata.page_num + 1}
                                    items={data}
                                    listType={GridListTypes.Student}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: studentsMetadata.page_num + 1})}
                                    onOverlayClick={(student) => {
                                        dispatch(setStudent(student));
                                        history.push(`/school/${student.tenant.tenantId}/${params.profileType}/students/${student.profileId}/posts`)
                                    }}
                                    onPreviousPageClick={() => onMetadataChange({page_num: studentsMetadata.page_num - 1})}
                                    pageNumber={studentsMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <Modal
                className="c-success-modal"
                declineButtonOnClick={() => {
                    setShowInviteViaEmailModal(false);
                    dispatch(clearInviteStudentsViaEmailListError());
                }}
                show={showInviteViaEmailModal}
                title="Invite Students"
            >
                <InviteStudentsViaEmail
                    closeModal={() => setShowInviteViaEmailModal(false)}
                    noun={ProfileNounLookup.student}
                    onSuccess={() => {
                        setShowInviteViaEmailModal(false);
                        setShowInviteSuccessModal(true);
                    }}
                />
            </Modal>

            <SuccessModal
                buttonOnClick={() => {
                    setShowInviteSuccessModal(false);
                }}
                buttonText="Done"
                show={showInviteSuccessModal}
                title="Nice job!"
            >
                <p>
                    Invite(s) have been sent to the specified users.
                </p>
            </SuccessModal>

            <Modal
                className="c-success-modal"
                declineButtonOnClick={() => {
                    setShowUploadCsvModal(false);
                    dispatch(clearInviteStudentsViaCsvImportError());
                }}
                show={showUploadCsvModal}
                title="Import Students"
            >
                <InviteStudentsViaCsvImport
                    noun={ProfileNounLookup.student}
                    onSuccess={() => {
                        setShowUploadCsvModal(false);
                        setShowUploadCsvSuccessModal(true);
                    }}
                />
            </Modal>

            <SuccessModal
                buttonOnClick={() => {
                    setShowUploadCsvSuccessModal(false);
                }}
                buttonText="Done"
                show={showUploadCsvSuccessModal}
                title="Nice job!"
            >
                <p>
                    Invite(s) have been sent to the specified users.
                </p>
            </SuccessModal>
        </div>
    );
};

export default ListStudents;
