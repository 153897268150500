import React, { useState } from 'react';
import clone from 'clone';
import { validated } from "react-custom-validation";
import { DragDropContext } from 'react-beautiful-dnd';
import moment from "moment";
import { useHistory, useRouteMatch } from "react-router";

import LandingPage from "../../../../../types/LandingPage";
import Validation from "../../../../../types/Validation";

import { isDateAfterOtherDate, isRequired, isRequiredIfTrue } from "../../../../../utils/validations";
import { hexCharactersOnly, isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../../../../utils/utils";
import { handleDateChange, handleTextChange } from "../../../../../utils/handle-changes";

import { getLandingPages, saveLandingPage, setLandingPage } from "../../../../../store/slices/landingPages";
import { setGroupsMetadata } from "../../../../../store/slices/groups";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import AttachContentModal from "../../../../Content/Notifications/CreateEditNotification/components/AttachContentModal";
import Button, { ButtonThemes, ButtonTypes } from "../../../../../components/Button";
import ButtonRow from "../../../../../components/ButtonRow";
import ColorPicker from "../../../../../components/ColorPicker";
import Datepicker from "../../../../../components/Datepicker";
import FileUpload from "../../../../../components/FileUpload";
import Form from "../../../../../components/Form";
import FormColumn from "../../../../../components/FormColumn";
import FormError from "../../../../../components/FormError";
import FormRow from "../../../../../components/FormRow";
import FormValidationMessage from "../../../../../components/FormValidationMessage";
import Icon from "../../../../../components/Icon";
import Label from "../../../../../components/Label";
import LandingPageReorderableList from "./components/LandingPageReorderableList";
import SuccessModal from "../../../../../components/Modal/SuccessModal";
import Textbox from "../../../../../components/Textbox";

type Props = {
    landingPage: LandingPage
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        artifactId: Validation
        backgroundColor: Validation
        endAt: Validation
        overview: Validation
        textColor: Validation
        welcomeMessage: Validation
    }
}

const CreateEditLandingPageForm: React.FC<Props> = ({
    landingPage,
    $field,
    $fieldEvent,
    $submit,
    $validation,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const [contentTypeToAttach, setContentTypeToAttach] = useState(null);
    const [isUploadingArtifact, setIsUploadingArtifact] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showAttachContentModal, setShowAttachContentModal] = useState(false);
    const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);
    const [showTextColorPicker, setShowTextColorPicker] = useState(false);

    const { groupsMetadata } = useTypedSelector((state) => state.groups);
    const { isSavingLandingPage, saveLandingPageError } = useTypedSelector((state) => state.landingPages);

    const handleAttachContent = (item) => {
        console.log(contentTypeToAttach, item);
        let clonedLandingPage = clone(landingPage);

        switch(contentTypeToAttach) {
            case 'assignedTarget':
                if(!clonedLandingPage.assignedTargets) {
                    clonedLandingPage.assignedTargets = [];
                }

                clonedLandingPage.assignedTargets = [
                    ...clonedLandingPage.assignedTargets,
                    item
                ];

                break;

            case 'event':
                if(!clonedLandingPage.events) {
                    clonedLandingPage.events = [];
                }

                clonedLandingPage.events = [
                    ...clonedLandingPage.events,
                    item
                ];

                break;

            case 'forumTopic':
                if(!clonedLandingPage.adminForumTopics) {
                    clonedLandingPage.adminForumTopics = [];
                }

                clonedLandingPage.adminForumTopics = [
                    ...clonedLandingPage.adminForumTopics,
                    item
                ];
                break;

            case 'studentForumTopic':
                if(!clonedLandingPage.studentForumTopics) {
                    clonedLandingPage.studentForumTopics = [];
                }

                clonedLandingPage.studentForumTopics = [
                    ...clonedLandingPage.studentForumTopics,
                    item
                ];
                break;

            case 'thread':
                if(!clonedLandingPage.threads) {
                    clonedLandingPage.threads = [];
                }

                clonedLandingPage.threads = [
                    ...clonedLandingPage.threads,
                    item
                ];

                break;

            default:
                return;
        }

        dispatch(setLandingPage(clonedLandingPage));
    };

    const handleChange = (name: string, value: any, changes?: any) => {
        let clonedLandingPage = clone(landingPage);

        if(changes) {
            clonedLandingPage = {
                ...clonedLandingPage,
                ...changes,
            };

            if(changes.artifactId) {
                delete clonedLandingPage.artifact;
            }
        } else {
            if (/backgroundColor|textColor/.test(name)) {
                if (value.length > 7) {
                    value = value.substring(0, 7);
                }

                value = hexCharactersOnly(value);

                value = '#' + value;
            }

            if (name === 'buttonText' && value.length > 24) {
                return;
            }
            clonedLandingPage[name] = value;
        }

        dispatch(setLandingPage(clonedLandingPage));
    };

    const handleColorPickerChange = async (name, color) => {
        handleChange(name, color.hex);
    };

    const handleDrop = (result) => {
        // dropped outside the list
        if (!result.destination || result.destination.droppableId !== result.source.droppableId) {
            return;
        }

        const clonedList = clone(landingPage[result.destination.droppableId]);
        const [removed] = clonedList.splice(result.source.index, 1);
        clonedList.splice(result.destination.index, 0, removed);

        handleChange(result.destination.droppableId, clonedList);
    };

    const handleOpenAttachModal = (type: 'assignedTarget' | 'event' | 'forumTopic' | 'studentForumTopic' | 'thread') => {
        setContentTypeToAttach(type);
        setShowAttachContentModal(true);
    };

    const handleRemoveContent = (name: 'assignedTargets' | 'adminForumTopics' | 'events' | 'studentForumTopics' | 'threads', index: number) => {
        let clonedLandingPage = clone(landingPage);

        if(isArrayNullOrEmpty(clonedLandingPage[name]) || isObjectNullOrEmpty(clonedLandingPage[name][index])) {
            return;
        }

        clonedLandingPage[name].splice(index, 1);

        dispatch(setLandingPage(clonedLandingPage));
    };

    const handleSave = async (isValid, isDraft) => {
        console.log('isValid', isValid);

        if(!isValid) {
            return;
        }

        try {
            let clonedLandingPage = clone(landingPage);
            if(isDraft) {
                clonedLandingPage.status = 'P';
            } else {
                clonedLandingPage.status = 'A';
            }
            await dispatch(saveLandingPage({landingPage: clonedLandingPage})).unwrap();
            $fieldEvent('reset');
            setShowSuccessModal(true);
        } catch(err) {
            console.log('CreateEditLandingPage handleSave err', err);
        }
    };

    const onUploadStart = () => {
        setIsUploadingArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange(null, null, {
                artifactId: artifactId,
                preview: file?.preview,
            });
        }
        setIsUploadingArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingArtifact(false);
    };

    const onDelete = () => {
        handleChange('artifactId', null, null);
        handleChange('preview', undefined, null);
    };

    const handleContentTypeToAttach = () => {
        console.log('contentTypeToAttach', contentTypeToAttach);
        if (contentTypeToAttach === 'studentForumTopic') {
            return { student_topics: true };
        }

        if (contentTypeToAttach === 'forumTopic') {
            return { admin_topics: true };
        }

        if (contentTypeToAttach === 'thread') {
            return { forum_topic_privacy_type: 'public' };
        }

        return null;
    }

    return (
        <DragDropContext onDragEnd={handleDrop}>
            <Form className="landing-page-form">
                <FormRow className="landing-page-form__content-container">
                    <Label>
                        Target Groups
                    </Label>

                    <Button
                        className="landing-page-form__add-content-button"
                        onClick={() => handleOpenAttachModal('assignedTarget')}
                        theme={ButtonThemes.Light}
                    >
                        Add group

                        <Icon type="plus" />
                    </Button>

                    {!isArrayNullOrEmpty(landingPage.assignedTargets) && (
                        <LandingPageReorderableList
                            disableReordering
                            handleRemoveContent={handleRemoveContent}
                            items={landingPage.assignedTargets}
                            type="assignedTargets"
                        />
                    )}
                </FormRow>

                <FormRow className="landing-page-form__content-container">
                    <Datepicker
                        dateFormat="yyyy-MM-dd h:mm a"
                        disabled={isUploadingArtifact}
                        id="dateEndAt"
                        label="Expiration Date"
                        minDate={new Date()}
                        name="endAt"
                        placeholderText="mm-dd-yyyy  00:00 pm"
                        selected={landingPage.endAt || null}
                        showMonthDropdown
                        showTimeSelect
                        showYearDropdown
                        timeIntervals={15}
                        validation={$validation.endAt}
                        {...$field('endAt', (newDate) => handleDateChange(handleChange, 'endAt', null, moment(newDate).format()))}
                    />
                </FormRow>
                <FormRow className="landing-page-form__content-container">
                    <Label>
                        Featured Image <span className="c-label__required-asterisk">*</span>
                    </Label>

                    <FileUpload
                        disabled={isUploadingArtifact}
                        currentArtifact={landingPage.artifact || null}
                        name="artifactId"
                        onDelete={onDelete}
                        onStart={onUploadStart}
                        onSuccess={onUploadSuccess}
                        onFailure={onUploadFailure}
                    />

                    {$validation.artifactId.isValid === false && $validation.artifactId.show === true ? (
                        <FormValidationMessage>
                            {$validation.artifactId.error.reason}
                        </FormValidationMessage>
                    ) : null}
                </FormRow>

                <FormRow columnCount={2}>
                    <FormColumn className="landing-page-form__color-select-container">
                        <Label htmlFor="txtBackgroundColor">
                            Background color <span className="c-label__required-asterisk">*</span>
                        </Label>

                        <div
                            className="landing-page-form__color-preview"
                            onClick={() => setShowBackgroundColorPicker(true)}
                            style={{backgroundColor: landingPage.backgroundColor}}
                        />

                        <Textbox
                            disabled={isUploadingArtifact}
                            id="txtBackgroundColor"
                            name="backgroundColor"
                            required
                            validation={$validation.backgroundColor}
                            value={landingPage.backgroundColor || ''}
                            {...$field('backgroundColor', event => handleTextChange(handleChange, event))}
                        />

                        <ColorPicker
                            className="landing-page-form__color-picker"
                            color={landingPage.backgroundColor}
                            handleChange={(color) => handleColorPickerChange('backgroundColor', color)}
                            handleDone={() => setShowBackgroundColorPicker(false)}
                            show={showBackgroundColorPicker}
                        />
                    </FormColumn>

                    <FormColumn className="landing-page-form__color-select-container">
                        <Label htmlFor="txtTextColor">
                            Text color <span className="c-label__required-asterisk">*</span>
                        </Label>

                        <div
                            className="landing-page-form__color-preview"
                            onClick={() => setShowTextColorPicker(true)}
                            style={{backgroundColor: landingPage.textColor}}
                        />

                        <Textbox
                            disabled={isUploadingArtifact}
                            id="txtTextColor"
                            name="textColor"
                            required
                            validation={$validation.textColor}
                            value={landingPage.textColor || ''}
                            {...$field('textColor', event => handleTextChange(handleChange, event))}
                        />

                        <ColorPicker
                            className="landing-page-form__color-picker"
                            color={landingPage.textColor}
                            handleChange={(color) => handleColorPickerChange('textColor', color)}
                            handleDone={() => setShowTextColorPicker(false)}
                            show={showTextColorPicker}
                        />
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <Textbox
                        disabled={isUploadingArtifact}
                        id="txtWelcomeMessage"
                        label="Welcome message"
                        name="welcomeMessage"
                        placeholder="This is a short introductory welcome message..."
                        required
                        type="textarea"
                        validation={$validation.welcomeMessage}
                        value={landingPage.welcomeMessage || ''}
                        {...$field('welcomeMessage', event => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                <FormRow>
                    <Textbox
                        disabled={isUploadingArtifact}
                        id="txtOverview"
                        label="Overview"
                        name="overview"
                        placeholder="This is a summary that provides students with additional information about what groups and content you’ve included in their customized orientation experience."
                        required
                        type="textarea"
                        validation={$validation.overview}
                        value={landingPage.overview || ''}
                        {...$field('overview', event => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                <FormRow>
                    <Textbox
                        characterLimit={24}
                        className="landing-page-form__button-text-container"
                        disabled={isUploadingArtifact}
                        id="txtButtonText"
                        label="Custom Landing Page Button Name"
                        name="buttonText"
                        onChange={(event) => handleTextChange(handleChange, event)}
                        instructions="Text to display in the button on the home screen that opens the landing page."
                        type="text"
                        value={landingPage.buttonText || ''}
                    />
                </FormRow>

                <FormRow className="landing-page-form__content-container">
                    <Label>
                        School Groups
                    </Label>

                    <Button
                        className="landing-page-form__add-content-button"
                        onClick={() => handleOpenAttachModal('forumTopic')}
                        theme={ButtonThemes.Light}
                    >
                        Add group

                        <Icon type="plus" />
                    </Button>

                    {!isArrayNullOrEmpty(landingPage.adminForumTopics) && (
                        <LandingPageReorderableList
                            handleRemoveContent={handleRemoveContent}
                            items={landingPage.adminForumTopics}
                            type="adminForumTopics"
                        />
                    )}
                </FormRow>

                <FormRow className="landing-page-form__content-container">
                    <Label>
                        Student Groups
                    </Label>

                    <Button
                        className="landing-page-form__add-content-button"
                        onClick={() => handleOpenAttachModal('studentForumTopic')}
                        theme={ButtonThemes.Light}
                    >
                        Add group

                        <Icon type="plus" />
                    </Button>

                    {!isArrayNullOrEmpty(landingPage.studentForumTopics) && (
                        <LandingPageReorderableList
                            handleRemoveContent={handleRemoveContent}
                            items={landingPage.studentForumTopics}
                            type="studentForumTopics"
                        />
                    )}
                </FormRow>

                <FormRow className="landing-page-form__content-container">
                    <Label>
                        Events
                    </Label>

                    <Button
                        className="landing-page-form__add-content-button"
                        onClick={() => handleOpenAttachModal('event')}
                        theme={ButtonThemes.Light}
                    >
                        Add Event

                        <Icon type="plus" />
                    </Button>

                    {!isArrayNullOrEmpty(landingPage.events) && (
                        <LandingPageReorderableList
                            handleRemoveContent={handleRemoveContent}
                            items={landingPage.events}
                            type="events"
                        />
                    )}
                </FormRow>

                <FormRow className="landing-page-form__content-container">
                    <Label>
                        Discussions
                    </Label>

                    <Button
                        className="landing-page-form__add-content-button"
                        onClick={() => handleOpenAttachModal('thread')}
                        theme={ButtonThemes.Light}
                    >
                        Add discussion

                        <Icon type="plus" />
                    </Button>

                    {!isArrayNullOrEmpty(landingPage.threads) && (
                        <LandingPageReorderableList
                            handleRemoveContent={handleRemoveContent}
                            items={landingPage.threads}
                            type="threads"
                        />
                    )}
                </FormRow>

                <FormError error={saveLandingPageError} />

                <ButtonRow className="landing-page-form__button-row">
                    {(landingPage.landingPageId == null || landingPage.status !== 'A') && (
                        <Button
                            disabled={isUploadingArtifact}
                            onClick={(event) => {
                                event.preventDefault();
                                $submit(() => handleSave(true, true), () => handleSave(false, true));
                            }}
                            showActivityIndicator={isSavingLandingPage}
                            theme={ButtonThemes.Secondary}
                            type={ButtonTypes.Button}
                        >
                            Save Draft
                        </Button>
                    )}

                    <Button
                        disabled={isUploadingArtifact}
                        onClick={(event) => {
                            event.preventDefault();
                            $submit(() => handleSave(true, false), () => handleSave(false, false));
                        }}
                        showActivityIndicator={isSavingLandingPage}
                        type={ButtonTypes.Submit}
                    >
                        {landingPage.landingPageId && landingPage.status === 'A' ? 'Save Changes' : 'Publish to App'}
                    </Button>
                </ButtonRow>

                <SuccessModal
                    buttonOnClick={() => {
                        dispatch(getLandingPages({}));
                        setShowSuccessModal(false);
                        history.push(`/school/${params.schoolId}/${params.profileType}/landing-pages`);
                    }}
                    show={showSuccessModal}
                >
                    <p>
                        Your landing page has been saved.
                    </p>
                    <p>
                        It may take a minute for your new landing page to show in feeds and lists.
                    </p>
                </SuccessModal>

                <AttachContentModal
                    appliedFilters={handleContentTypeToAttach()}
                    close={() => {
                        setShowAttachContentModal(false);

                        // let clonedMetadata = clone(groupsMetadata);
                        // delete clonedMetadata.student_topics;
                        dispatch(setGroupsMetadata(groupsMetadata));
                    }}
                    ignoreGroupPathFilters={true}
                    handleSelect={(item) => handleAttachContent(item)}
                    hideGroupFilterButtons={/forumTopic|studentForumTopic/.test(contentTypeToAttach)}
                    show={showAttachContentModal}
                    showStudentGroups
                    type={/assignedTarget|targetForumTopic|studentForumTopic/.test(contentTypeToAttach) ? 'forumTopic' : contentTypeToAttach}
                />
            </Form>
        </DragDropContext>
    );
};

function createEditLandingPageFormValidationConfig(props) {
    let { artifactId, artifact, backgroundColor, endAt, overview, textColor, welcomeMessage }: LandingPage = props.landingPage;

    return {
        fields: ['artifactId', 'backgroundColor', 'endAt', 'overview', 'textColor', 'welcomeMessage'],
        validations: {
            artifactId: [
                [isRequiredIfTrue, artifactId, isObjectNullOrEmpty(artifact), 'An image is required']
            ],
            backgroundColor: [
                [isRequired, backgroundColor]
            ],
            endAt: [
                [isRequired, endAt],
                [isDateAfterOtherDate, endAt, new Date(), 'A date in the future is required']
            ],
            overview: [
                [isRequired, overview]
            ],
            textColor: [
                [isRequired, textColor]
            ],
            welcomeMessage: [
                [isRequired, welcomeMessage]
            ]
        }
    }
}
export default validated(createEditLandingPageFormValidationConfig)(CreateEditLandingPageForm);
