import React, { FormEvent, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch } from '../../../../../../../../store';
import { useTypedSelector } from '../../../../../../../../store/reducers';
import { addError } from '../../../../../../../../store/slices/errors';
import { uploadFiles } from '../../../../../../../../store/slices/upload';
import { setNewMessage, setNewMessageArtifact } from '../../../../../../../../store/slices/directMessages';
import browserImageSize from 'browser-image-size';

import Button, { ButtonThemes, ButtonTypes } from '../../../../../../../../components/Button';
import Icon from '../../../../../../../../components/Icon';
import ProgressBar from '../../../../../../../../components/ProgressBar';
import FileUploadErrorModal from '../../../../../../../../components/Modal/FileUploadErrorModal';

interface MessageFormProps {
    onSendMessage: (e: React.FormEvent) => Promise<void>;
    isSendingMessage: boolean;
}

const MessageForm: React.FC<MessageFormProps> = ({ onSendMessage, isSendingMessage }) => {
    const dispatch = useAppDispatch();
    const messageRef = useRef<HTMLSpanElement>(null);

    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [preview, setPreview] = useState<string | null>(null);
    const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);

    const { uploadPercentage } = useTypedSelector((state) => state.upload);
    const { newMessage } = useTypedSelector((state) => state.directMessages);

    const onDrop = async (goodFiles, badFiles) => {

        console.log('goodFiles', goodFiles);
        if (badFiles && badFiles.length > 0) {
            setShowUploadErrorModal(true);
            return
        }
        let file = goodFiles[0];

        setIsUploadingImage(true);
        file.preview = URL.createObjectURL(file);
        console.log('file', file);
        setPreview(file);

        // Try getting image size first. This will catch errors before uploading.
        // This process needs to be improved in the next iteration of the admin.
        let imageSize;
        try {
            imageSize = await browserImageSize(file);
        } catch (err) {
            err.response = {
                status: 422 // Error needs a response status to be displayed to user
            }
            err.friendlyMessage = 'Error prepping file for upload. This is likely due to an unsupported file format.';
            setIsUploadingImage(false);
            setPreview(null);
            dispatch(addError(err))
        }

        // If we have image size, proceed with attempting to upload to the server
        if (imageSize) {
            try {
                let { height, width } = imageSize;
                let uploadResult: any = await dispatch(uploadFiles({ file, height, width, type: 'artifact' }));
                dispatch(setNewMessageArtifact(uploadResult.payload.artifactId))
                setIsUploadingImage(false);
            } catch (err) {
                console.warn('upload file err', err);
                dispatch(addError(err))
                setPreview(null);
                setIsUploadingImage(false);
            }
        }
    };

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: 'image/jpeg, image/png, image/gif, image/webp, image/heic, image/heif',
        noClick: true,
        noKeyboard: true
    });

    const clearImage = () => {
        setPreview(null);
        dispatch(setNewMessageArtifact(null));
    };

    const clearInput = () => {
        console.log('clearInput');
        if (messageRef.current) {
            messageRef.current.innerText = '';
        }
        dispatch(setNewMessage(''));
    };

    const handleSendMessage = async (e: React.FormEvent) => {
        e.preventDefault();
        if (newMessage || preview) {
            await onSendMessage(e);
            clearInput();
            clearImage();
        }
    };

    return (
        <div {...getRootProps()}>
            <form className="conversation__new-message-form" onSubmit={handleSendMessage}>
                {preview && (
                    <div className='conversation__new-message__image-preview'>
                        <img src={preview.preview} alt="" />
                        <Button
                            theme={ButtonThemes.Light}
                            noStyles
                            className='conversation__new-message__image-preview__delete'
                            onClick={clearImage}
                        >
                            <Icon type='x' />
                        </Button>
                    </div>
                )}
                <span
                    className="conversation__new-message-textbox"
                    contentEditable
                    onInput={(event: FormEvent<HTMLSpanElement>) => {
                        const target = event.target as HTMLSpanElement;
                        if (target) {
                            dispatch(setNewMessage(target.innerText));
                        }
                    }}
                    placeholder="Send a message..."
                    ref={messageRef}
                    role="textbox"
                />

                <input {...getInputProps()} />

                <Button
                    className="conversation__new-message-button"
                    noStyles
                    onClick={open}
                >
                    <Icon type="image" />
                </Button>

                <Button
                    className="conversation__new-message-button"
                    disabled={!newMessage && !preview}
                    noStyles
                    showActivityIndicator={isSendingMessage}
                    type={ButtonTypes.Submit}
                >
                    <Icon type="send" />
                </Button>
            </form>

            {(isDragActive || isUploadingImage) && (
                <div className='conversation__dragActive'>
                    <Icon type="image" className='conversation__dragActive__icon' />
                    {isUploadingImage ? (
                        <div className="conversation__dragActive__progress">
                            <ProgressBar progress={uploadPercentage} />
                        </div>
                    ) : (
                        <p>Drop an image here to upload.</p>
                    )}
                </div>
            )}

            <FileUploadErrorModal
                close={() => setShowUploadErrorModal(false)}
                show={showUploadErrorModal}
            />
        </div>
    );
};

export default MessageForm;
