import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from 'clone';

import ClassNote from '../../types/ClassNote';
import Metadata from '../../types/Metadata';

import {
    generateClassNote,
    generateClassNotesMetadata,
} from '../../utils/generators';
import PATHS, { buildQueryString } from '../../utils/paths';
import Request from '../../utils/request';

import { addError } from './errors';
import { RootState } from '../reducers';
import IError from '../../types/IError';
import { buildErrorObject } from '../../utils/errors';
import { PostTypes } from '../../utils/enums';

type DeleteClassNoteProps = {
    classNote?: ClassNote
}

export const deleteClassNote = createAsyncThunk(
    'classNotes/deleteClassNote',
    async ({classNote}: DeleteClassNoteProps = {}, {dispatch, getState, rejectWithValue}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!classNote) {
                classNote = (getState() as RootState).classNotes.classNote;
            }

            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.classNotes.delete(tenantId, classNote.postId));
            return {
                ...res,
                postId: classNote.postId,
            };
        } catch(err) {
            console.log('delete class note err', err);
            const friendlyMessage = 'Error deleting the class note. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type GetClassNotesProps = {
    isUpdate?: boolean
    schoolId?: number
    classNotesMetadata?: Metadata
}

export const getClassNotes = createAsyncThunk(
    'classNotes/getClassNotes',
    async ({isUpdate, schoolId, classNotesMetadata}: GetClassNotesProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if (!classNotesMetadata) {
                classNotesMetadata = clone((getState() as RootState).classNotes.classNotesMetadata);
            } else {
                classNotesMetadata = {...classNotesMetadata};
            }
            classNotesMetadata.type = PostTypes.ClassNote;

            if (!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.content.getContent(schoolId, buildQueryString(classNotesMetadata)));
            let classNotes = res.data.data.items;
            classNotesMetadata.total = res.data.data.meta.total;
            return {classNotes, classNotesMetadata: classNotesMetadata};
        } catch (err) {
            console.log('getClassNotes', err);
            const friendlyMessage = 'Error getting the list of class notes. Please try again.';

            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type GetClassNoteProps = {
    postId?: number | string
    schoolId?: number | string
}

export const getClassNote = createAsyncThunk(
    'classNotes/getClassNote',
    async ({postId, schoolId}: GetClassNoteProps = {}, {dispatch, getState, rejectWithValue}) => {
        try {
            const {auth: {token}, schools: {activeSchool}} = (getState() as RootState);
            if (!schoolId) {
                schoolId = activeSchool.tenantId;
            }
            const res = await new Request(token).get(PATHS.classNotes.getById(schoolId, postId));
            return res.data.data;
        } catch (err) {
            console.log('getClassNote', err);
            const friendlyMessage = 'Error getting the class note. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type SaveClassNoteProps = {
    classNote?: ClassNote
}

export const saveClassNote = createAsyncThunk(
    'classNotes/saveClassNote',
    async ({classNote}: SaveClassNoteProps, {dispatch, getState, rejectWithValue}) => {
        try {
            const {auth: {token}, schools: {activeSchool}} = (getState() as RootState);

            if (!classNote) {
                classNote = clone((getState() as RootState).classNotes.classNote);
            }
            else {
                classNote = {...classNote};
            }

            classNote.alumniVisibility = true;
            classNote.admitVisibility = false;
            classNote.studentVisibility = false;
            classNote.tenantId = activeSchool.tenantId;
            classNote.profileId = activeSchool.postAsProfile?.profileId;

            const res = await new Request(token).post(PATHS.classNotes.create(activeSchool.tenantId), classNote);
            return res.data.data;
        } catch (err) {
            console.log('createClassNote', err);
            const friendlyMessage = 'Error saving the class note. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

export interface ClassNotesState {
    classNote: ClassNote;
    classNotes: ClassNote[];
    classNotesMetadata: Metadata;
    createClassNoteError?: IError;
    deleteClassNoteError?: IError;
    getClassNotesError?: IError;
    getClassNoteError?: IError;
    isGettingClassNotes: boolean;
    isGettingClassNote: boolean;
    isSavingClassNote: boolean;
    isUpdatingClassNote: boolean;
    isDeletingClassNote: boolean;
    searchTerm: string;
    updateClassNoteError?: IError;
}

const initialState: ClassNotesState = {
    classNote: generateClassNote(),
    classNotes: [],
    classNotesMetadata: generateClassNotesMetadata(),
    isGettingClassNotes: false,
    isGettingClassNote: false,
    isSavingClassNote: false,
    isUpdatingClassNote: false,
    isDeletingClassNote: false,
    searchTerm: '',
};

const classNotesSlice = createSlice({
    name: 'classNotes',
    initialState,
    reducers: {
        clearClassNote: (state) => {
            state.classNote = generateClassNote();
        },
        clearClassNotesMetadata: (state) => {
            state.classNotesMetadata = generateClassNotesMetadata();
            state.searchTerm = '';
        },
        setClassNote: (state, action) => {
            state.classNote = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteClassNote.pending, (state, action) => {
            state.isDeletingClassNote = true;
            state.deleteClassNoteError = undefined;
        });
        addCase(deleteClassNote.fulfilled, (state, action) => {
            state.isDeletingClassNote = false;
            state.classNotes = state.classNotes.filter(classNote => classNote.postId !== action.payload.postId);
        });
        addCase(deleteClassNote.rejected, (state, action) => {
            state.isDeletingClassNote = false;
            state.deleteClassNoteError = action.error;
        });

        addCase(getClassNote.pending, (state, action) => {
            state.isGettingClassNote = true;
            state.getClassNoteError = undefined;
        });
        addCase(getClassNote.fulfilled, (state, action) => {
            state.isGettingClassNote = false;
            state.classNote = action.payload;
        });
        addCase(getClassNote.rejected, (state, action) => {
            state.isGettingClassNote = false;
            state.getClassNoteError = action.error;
        });

        addCase(getClassNotes.pending, (state, action) => {
            state.isGettingClassNotes = true;
            state.getClassNotesError = undefined;

            if(action.meta?.arg?.classNotesMetadata) {
                state.classNotesMetadata = action.meta.arg.classNotesMetadata;
            }
        });
        addCase(getClassNotes.fulfilled, (state, action) => {
            state.isGettingClassNotes = false;
            state.classNotes = action.payload.classNotes;
            state.classNotesMetadata = action.payload.classNotesMetadata;
        });
        addCase(getClassNotes.rejected, (state, action) => {
            state.isGettingClassNotes = false;
            state.getClassNotesError = action.error;
        });

        addCase(saveClassNote.pending, (state, action) => {
            state.isSavingClassNote = true;
            state.createClassNoteError = undefined;
        });
        addCase(saveClassNote.fulfilled, (state, action) => {
            state.isSavingClassNote = false;
            state.classNote = action.payload;
        });
        addCase(saveClassNote.rejected, (state, action) => {
            state.isSavingClassNote = false;
            state.createClassNoteError = action.error;
        });
    },
});

export const {
    clearClassNote,
    clearClassNotesMetadata,
    setClassNote,
    setSearchTerm,
} = classNotesSlice.actions;

export default classNotesSlice.reducer;
