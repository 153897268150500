import GoogleLocation from "../types/GoogleLocation";
import { ProfileTypes } from "./enums";
import Profile from "../types/Profile";

export const formatAsDollarAmount = (number?: number) => {
    if(number == null) {
        return null;
    }

    return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(number);
};

export const isAuthPage = (location: string) => {
    return location.includes('/auth/');
};

export const isArrayNullOrEmpty = (arr?: Array<any>) => {
    return arr == null || arr.length === 0;
};

export const isObjectNullOrEmpty = (obj?: object) => {
    return obj == null || Object.keys(obj).length === 0;
};

export const hexCharactersOnly = (value) => {
    value = value.toString();
    value = value.replace(/[^0-9A-Fa-f]/g,'');
    return value;
};

export const renderHometown = (location: GoogleLocation) => {
    if(!isObjectNullOrEmpty(location)) {
        if(location.country === 'US') {
            return `${location.city || location.name}, ${location.state}`;
        } else if(location.formattedAddress) {
            return location.formattedAddress;
        } else if(location.name) {
            return location.name;
        } else {
            return '';
        }
    } else {
        return '';
    }
};

export const renderGradYear = (profile: Profile) => {
    let gradYear = profile.details.filter((detail ) => detail?.element?.code === 'gradyear')[0]?.elementDetail?.code;
    if(gradYear) {
        return gradYear;
    } else {
        return '--';
    }
}

export const setProfileTypeVisibilityInMetadata = (metadata, profileType) => {
    if(!profileType) {
        return metadata;
    }

    switch(profileType) {
        case ProfileTypes.Admit:
            metadata.admit_visibility = true;
            delete metadata.student_visibility;
            delete metadata.alumni_visibility;
            break;

        case ProfileTypes.Alumni:
            metadata.alumni_visibility = true;
            delete metadata.student_visibility;
            delete metadata.admit_visibility;
            break;

        case ProfileTypes.Student:
            metadata.student_visibility = true;
            delete metadata.alumni_visibility;
            delete metadata.admit_visibility;
            break;

        default:
            break;
    }

    return metadata;
}
