import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import EventAttendee from "../../types/EventAttendee";
import EventsMetadata from "../../types/EventsMetadata";
import EventPost from "../../types/EventPost";
import IError from "../../types/IError";
import Metadata from "../../types/Metadata";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import { generateEvent, generateEventsMetadata, generateMetadata } from '../../utils/generators';

import { addError } from "./errors";
import { RootState } from '../reducers';

type DeleteEventProps = {
    event?: EventPost
}

export const deleteEvent = createAsyncThunk(
    'events/deleteEvents',
    async ({event}: DeleteEventProps = {}, {dispatch, getState}) => {
        const {tenantId} = (getState() as RootState).schools.activeSchool;
        try {
            if(!event) {
                event = (getState() as RootState).events.event;
            }

            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.events.delete(tenantId, event.postId));
            return {
                ...res,
                postId: event.postId,
            };
        } catch(err) {
            console.log('delete event err', err);
            err.friendlyMessage = 'Error deleting the event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

type CheckInProps = {
    eventId?: Number
    attendee?: EventAttendee
}

export const checkIn = createAsyncThunk(
    'events/checkIn',
    async ({eventId, attendee}: CheckInProps = {}, {dispatch, getState}) => {
        const {tenantId} = (getState() as RootState).schools.activeSchool;
        try {
            if (!eventId) {
                eventId = (getState() as RootState).events.event.postId;
            }
            let res;
            if (attendee.isCheckedIn) {
                res = await new Request((getState() as RootState).auth.token).put(PATHS.events.removeCheckIn(tenantId, eventId), {profileId: attendee.profile.profileId});
            } else {
                res = await new Request((getState() as RootState).auth.token).put(PATHS.events.checkIn(tenantId, eventId), {profileId: attendee.profile.profileId});
            }

            return {
                ...res.data.data,
                postId: eventId,
                profile: attendee.profile
            };
        } catch(err) {
            console.log('event checkin err', err);
            err.friendlyMessage = 'Error checking user into this event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)



type GetEventsProps = {
    isUpdate?: boolean
    schoolId?: number
    eventsMetadata?: Metadata
}

export const getEvents = createAsyncThunk(
    'events/getEvents',
    async ({isUpdate, schoolId, eventsMetadata}: GetEventsProps, {dispatch, getState}) => {
        try {
            if(!eventsMetadata) {
                eventsMetadata = clone((getState() as RootState).events.eventsMetadata);
            } else {
                eventsMetadata = {...eventsMetadata}
            }
            eventsMetadata.type = 'E';

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.events.getEvents(schoolId, buildQueryString(eventsMetadata)));
            let events = res.data.data.items;
            eventsMetadata.total = res.data.data.meta.total;
            return {events, eventsMetadata};
        } catch(err) {
            console.log('getEvents', err);
            err.friendlyMessage = 'Error getting the list of events. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetEventProps = {
    postId?: number | string
    schoolId?: number | string
}

export const getEvent = createAsyncThunk(
    'events/getEvent',
    async ({postId, schoolId}: GetEventProps = {}, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            const res = await new Request(token).get(PATHS.events.getById(schoolId, postId));
            return res.data.data;
        } catch(err) {
            console.log('getEvent', err);
            err.friendlyMessage = 'Error getting the event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetEventAttendeesProps = {
    isUpdate?: boolean
    metadata?: Metadata
    postId?: number | string
    schoolId?: number | string
}

export const getEventAttendees = createAsyncThunk(
    'events/getEventAttendees',
    async ({isUpdate, metadata, postId, schoolId}: GetEventAttendeesProps = {}, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);

            if(!metadata) {
                metadata = clone((getState() as RootState).events.eventAttendeesMetadata);
            } else {
                metadata = {...metadata}
            }

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            if (!postId) {
                postId = (getState() as RootState).events.event.postId;
            }

            const res = await new Request(token).get(PATHS.events.getAttendees(schoolId, postId, buildQueryString(metadata)));

            let eventAttendees = res.data.data.items;
            metadata.total = res.data.data.meta.total;

            return { eventAttendees, metadata };
        } catch(err) {
            console.log('getEvent', err);
            err.friendlyMessage = 'Error getting the attendees of this event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetEventAttendeesForExportProps = {
    postId?: number | string
    schoolId?: number | string
}

export const getEventAttendeesForExport = createAsyncThunk(
    'events/getEventAttendeesForExport',
    async ({postId, schoolId}: GetEventAttendeesForExportProps = {}, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);

            const metadata = {
                page_size: 10000,
                page_num: 0,
            }

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            const res = await new Request(token).get(PATHS.events.getAttendees(schoolId, postId, buildQueryString(metadata)));

            let eventAttendees = res.data.data.items;

            return eventAttendees;
        } catch(err) {
            console.log('getEvent', err);
            err.friendlyMessage = 'Error getting the attendees of this event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);


type RemoveRsvpProps = {
    eventId?: number | string
    schoolId?: number | string
    profileId?: number | string
}

export const removeRsvp = createAsyncThunk(
    'events/removeRsvp',
    async ({eventId, profileId, schoolId}: RemoveRsvpProps = {}, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);

            if (!eventId) {
                eventId = (getState() as RootState).events.event.postId;
            }

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            const res = await new Request(token).delete(PATHS.events.removeRsvp(schoolId, eventId, profileId));
            return res.data.data;
        } catch(err) {
            console.log('removeRsvp', err);
            err.friendlyMessage = 'Error removing this RSVP. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);


export const saveEvent = createAsyncThunk(
    'events/saveEvent',
    async(_: void, {dispatch, getState}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId , postAsProfile: { profileId } } } } = (getState() as RootState);
        let { event } = (getState() as RootState).events;

        let path = PATHS.events.create(tenantId);
        let request = new Request(token);
        let reqFunc = request.post;

        if(event.postId) {
            path = PATHS.events.update(tenantId, event.postId);
            reqFunc = request.put;
        } else {
            event = {
                ...event,
                tenantId,
                profileId
            }
        }

        try {
            const res = await reqFunc(path, event);
            return res;
        } catch(err) {
            console.log('saveEvent error', err);
            err.friendlyMessage = 'Error saving the event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

interface EventsState {
    checkInError: IError
    event: EventPost
    eventAttendees: Array<EventAttendee>
    eventAttendeesMetadata: Metadata
    eventAttendeesSearchTerm: string
    events: Array<EventPost>
    eventsMetadata: EventsMetadata
    isCheckingIn: boolean
    isDeletingEvent: boolean
    isGettingEvent: boolean
    isGettingEventAttendees: boolean
    isGettingEvents: boolean
    isRemovingRsvp: boolean
    isSavingEvent: boolean
    deleteEventError: IError
    getEventError: IError
    getEventAttendeesError: IError
    getEventAttendeesForExportError: IError
    getEventsError: IError
    saveEventError: IError
    removeRsvpError: IError
    searchTerm: string
}

const initialState: EventsState = {
    checkInError: undefined,
    event: generateEvent(),
    eventAttendees: [],
    eventAttendeesMetadata: generateMetadata(),
    eventAttendeesSearchTerm: '',
    events: [],
    eventsMetadata: generateEventsMetadata(),
    searchTerm: '',
    isCheckingIn: true,
    isDeletingEvent: false,
    isGettingEvent: false,
    isGettingEventAttendees: false,
    isGettingEvents: false,
    isRemovingRsvp: false,
    isSavingEvent: false,
    deleteEventError: undefined,
    getEventError: undefined,
    getEventAttendeesError: undefined,
    getEventAttendeesForExportError: undefined,
    getEventsError: undefined,
    removeRsvpError: undefined,
    saveEventError: undefined,
};

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        clearEvent: (state) => {
            state.event = generateEvent();
        },
        clearEventsMetadata: (state) => {
            state.eventsMetadata = generateEventsMetadata();
            state.searchTerm = '';
        },
        setEvent: (state, action) => {
            state.event = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setEventAttendeeSearchTerm: (state, action) => {
            state.eventAttendeesSearchTerm = action.payload;
        },
    },
    extraReducers: ({addCase}) => {

        addCase(checkIn.pending, (state, action) => {
            state.checkInError = undefined;
            state.isCheckingIn = true;
            state.eventAttendees = state.eventAttendees.map((attendee) => {
                if (attendee.postRsvpId === action.meta.arg.attendee.postRsvpId) {
                    return {
                        ...attendee,
                        isCheckingIn: true
                    };
                }
                return attendee;
            });
        });
        addCase(checkIn.fulfilled, (state, action) => {
            let foundMatch = false;
            state.eventAttendees = state.eventAttendees.map((attendee) => {
                if(attendee.postRsvpId === action.payload.postRsvpId) {
                    foundMatch = true;
                    return {
                        ...attendee,
                        isCheckedIn: action.payload.isCheckedIn,
                        isCheckingIn: false
                    }
                }
                return attendee;
            });

            if(!foundMatch) {
                state.eventAttendees = [
                    {
                        ...action.payload,
                        isCheckingIn: false
                    },
                    ...state.eventAttendees
                ];
            }

        });
        addCase(checkIn.rejected, (state, action) => {
            state.eventAttendees = state.eventAttendees.map((attendee) => {
                if (attendee.postRsvpId === action.meta.arg.attendee.postRsvpId) {
                    return {
                        ...attendee,
                        isCheckingIn: false
                    };
                }
                return attendee;
            });

            state.checkInError = action.error as IError;
            state.isCheckingIn = false;
        });

        addCase(deleteEvent.pending, (state) => {
            state.deleteEventError = undefined;
            state.isDeletingEvent = true;
        });
        addCase(deleteEvent.fulfilled, (state, action) => {
            state.isDeletingEvent = false;
            state.event = generateEvent();
        });
        addCase(deleteEvent.rejected, (state, action) => {
            state.deleteEventError = action.error as IError;
            state.isDeletingEvent = false;
        });

        addCase(getEvent.pending, (state) => {
            state.getEventError = undefined;
            state.isGettingEvent = true;
        });
        addCase(getEvent.fulfilled, (state, action) => {
            state.isGettingEvent = false;
            state.event = action.payload;
        });
        addCase(getEvent.rejected, (state, action) => {
            state.getEventError = action.error as IError;
            state.isGettingEvent = false;
        });

        addCase(getEventAttendees.pending, (state, action) => {
            state.getEventAttendeesError = undefined;
            state.isGettingEventAttendees = true;
            if(action.meta?.arg?.metadata) {
                state.eventAttendeesMetadata = action.meta.arg.metadata;
            }
        });
        addCase(getEventAttendees.fulfilled, (state, action) => {
            state.eventAttendees = action.payload.eventAttendees;
            state.eventAttendeesMetadata = action.payload.metadata;
            state.isGettingEventAttendees = false;
        });
        addCase(getEventAttendees.rejected, (state, action) => {
            state.getEventAttendeesError = action.error as IError;
            state.isGettingEventAttendees = false;
        });

        addCase(getEventAttendeesForExport.pending, (state, action) => {
            state.getEventAttendeesForExportError = undefined;
        });
        addCase(getEventAttendeesForExport.fulfilled, (state, action) => {
            state.isGettingEventAttendees = false;
        });
        addCase(getEventAttendeesForExport.rejected, (state, action) => {
            state.getEventAttendeesForExportError = action.error as IError;
            state.isGettingEventAttendees = false;
        });

        addCase(removeRsvp.pending, (state, action) => {
            state.removeRsvpError = undefined;
            state.isRemovingRsvp = true;
        });
        addCase(removeRsvp.fulfilled, (state, action) => {
            state.isRemovingRsvp = false;
            state.eventAttendees = state.eventAttendees.filter((attendee) => {
                return attendee.postRsvpId !== action.payload.postRsvpId;
            });
        });
        addCase(removeRsvp.rejected, (state, action) => {
            state.removeRsvpError = action.error as IError;
            state.isRemovingRsvp = false;
        });


        addCase(getEvents.pending, (state, action) => {
            state.getEventsError = undefined;
            state.isGettingEvents = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.eventsMetadata) {
                state.eventsMetadata = action.meta.arg.eventsMetadata;
            }
        });
        addCase(getEvents.fulfilled, (state, action) => {
            state.events = action.payload.events;
            state.eventsMetadata = action.payload.eventsMetadata;
            state.isGettingEvents = false;
        });
        addCase(getEvents.rejected, (state, action) => {
            state.getEventsError = action.error as IError;
            state.isGettingEvents = false;
        });

        addCase(saveEvent.pending, (state) => {
            state.saveEventError = undefined;
            state.isSavingEvent = true;
        });
        addCase(saveEvent.fulfilled, (state, action) => {
            state.isSavingEvent = false;
            state.event = generateEvent();
        });
        addCase(saveEvent.rejected, (state, action) => {
            state.saveEventError = action.error as IError;
            state.isSavingEvent = false;
        });
    }
});

export const { clearEvent, clearEventsMetadata, setEvent, setSearchTerm, setEventAttendeeSearchTerm } = eventsSlice.actions;

export default eventsSlice.reducer;
