import './Dashboard.scss';

import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../store';
import { useTypedSelector } from '../../../store/reducers';
import { DashboardCardData, getSuperAdminDashboardData } from '../../../store/slices/superAdminDashboard';

import DashboardCharts from './components/DashboardCards/DashboardCharts';
import DashboardSlimChartCard from './components/DashboardCards/DashboardCharts/DashboardSlimChartCard';
import DashboardStat from './components/DashboardRow/DashboardStat';

export type DashboardCardProps = {
    cardData: DashboardCardData
    initialized: boolean
    isGettingDashboard: boolean
}

export enum CardType {
    LiveNow = 'ln',
    MostActiveGroups = 'mag',
    MostActiveSchools = 'mas',
    MostActiveUsers = 'mau',
    PushNotifications = 'pn',
    RecentlyJoinedUsers = 'rju',
    TopDmUsers = 'tdu',
    TopDmSchools = 'tds',
    TopDeals = 'td',
    TopEvents = 'te',
    TopItems = 'ti',
    TopThreads = 'tt',
}

const SuperAdminDashboard: React.FC = () => {
    const dispatch = useAppDispatch();

    const [initialized, setInitialized] = useState(false);

    const {
        dau,
        headcount,
        isGettingDashboard,
        mau,
        liveNowData,
        topDmSchoolsData,
        topDmUsersData,
        mostActiveGroupsData,
        mostActiveSchoolsData,
        mostActiveUsersData,
        profileActivity,
        pushNotificationsData,
        recentlyJoinedUsersData,
        slimDashData,
        topDealsData,
        topEventsData,
        topItemsData,
        topThreadsData,
    } = useTypedSelector(store => store.superAdminDashboard);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getSuperAdminDashboardData({}));
            } catch (err) {
                console.log('Error initializing tenant dashboard: ', err);
            } finally {
                setInitialized(true);
            }
        };
        initialize();
    }, [dispatch]);

    return (
        <div className="dashboard-cards__container">
            <h1>
                All Schools Dashboard
            </h1>

            <DashboardSlimChartCard
                isGettingDashboard={isGettingDashboard}
                initialized={initialized}
                slimDashData={slimDashData}
            />
            <DashboardCharts
                dau={dau}
                mau={mau}
                headcount={headcount}
                profileActivity={profileActivity}
                isGettingDashboard={isGettingDashboard}
                initialized={initialized}
            />

            <DashboardStat
                cardData={pushNotificationsData}
                cardType={CardType.PushNotifications}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.PushNotifications}
            />

            <DashboardStat
                cardData={liveNowData}
                cardType={CardType.LiveNow}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.LiveNow}
            />

            <DashboardStat
                cardData={topDmSchoolsData}
                cardType={CardType.TopDmSchools}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.TopDmSchools}
            />

            <DashboardStat
                cardData={topDmUsersData}
                cardType={CardType.TopDmUsers}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.TopDmUsers}
            />

            <DashboardStat
                cardData={recentlyJoinedUsersData}
                cardType={CardType.RecentlyJoinedUsers}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.RecentlyJoinedUsers}
            />

            <DashboardStat
                cardData={mostActiveUsersData}
                cardType={CardType.MostActiveUsers}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.MostActiveUsers}
            />

            <DashboardStat
                cardData={mostActiveSchoolsData}
                cardType={CardType.MostActiveSchools}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.MostActiveSchools}
            />

            <DashboardStat
                cardData={mostActiveGroupsData}
                cardType={CardType.MostActiveGroups}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.MostActiveGroups}
            />

            <DashboardStat
                cardData={topDealsData}
                cardType={CardType.TopDeals}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.TopDeals}
            />

            <DashboardStat
                cardData={topEventsData}
                cardType={CardType.TopEvents}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.TopEvents}
            />

            <DashboardStat
                cardData={topThreadsData}
                cardType={CardType.TopThreads}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.TopThreads}
            />

            <DashboardStat
                cardData={topItemsData}
                cardType={CardType.TopItems}
                initialized={initialized}
                isGettingDashboard={isGettingDashboard}
                key={CardType.TopItems}
            />

        </div>
    );
};

export default SuperAdminDashboard;
