import './SystemHealthService.scss';

import React, { useMemo } from 'react';

import { HealthCheckStatus } from '../../../../types/HealthCheckStatus';
import SystemHealth from '../../../../types/SystemHealth';

import FormColumn from '../../../../components/FormColumn';
import FormRow from '../../../../components/FormRow';
import H4 from '../../../../components/H4';
import H5 from '../../../../components/H5';
import SystemHealthServiceDependenciesTable from '../SystemHealthServiceDependenciesTable';
import TableColumn from '../../../../types/TableColumn';
import Table from '../../../../components/Table';
import Tooltip, { TooltipSide } from '../../../../components/Tooltip';
import SystemHealthService from '../../../../types/SystemHealthService';

type Props = {
    systemHealth: SystemHealth;
}

const SystemHealthServicesTable: React.FC<Props> = ({
    systemHealth
}) => {
    const parseServiceName = (string: string) => {
        const words = string.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
        const sentence = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    };

    const renderDateInPast = (date: Date) => {
        let dateString = '';
        const secondsAgo = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        if (secondsAgo < 60) {
            dateString = 'less than a minute ago';
        } else if (secondsAgo < 3600) {
            dateString = `${minutesAgo} minutes ago`;
        } else if (secondsAgo < 86400) {
            dateString = `${Math.floor(secondsAgo / 3600)} hours ago`;
        } else {
            dateString = `${Math.floor(secondsAgo / 86400)} days ago`;
        }
        return dateString;
    };

    const calculateAvgLatency = (dependencies: any[]) => {
        if (dependencies.length === 0) {
            return 'N/A';
        }
        let total = 0;
        dependencies.forEach((dependency) => {
            total += dependency.durationMs;
        });
        return Math.floor(total / dependencies.length) + 'ms';
    };

    const findSlowestDependency = (name: string, dependencies: any[]) => {
        if (dependencies.length === 0) {
            return 'N/A';
        }
        const sortable = [...dependencies].sort((a, b) => b.durationMs - a.durationMs);
        return parseServiceName(sortable[0].name) + ' - ' + sortable[0].durationMs + 'ms';
    };

    const renderStatus = (row: SystemHealthService) => {
        let healthy = true;
        if (row.dependencies.length === 0) {
            healthy = false;
        }
        row.dependencies.forEach((dependency) => {
            if (dependency.status !== HealthCheckStatus.OK) {
                healthy = false;
            }
        });
        if (row.status !== HealthCheckStatus.OK) {
            healthy = false;
        }

        return healthy  ? '🟢' : '🔴';
    };

    const calculateErrors = (row: SystemHealthService) => {
        const errors: string[] = [];
        if (row.dependencies.length === 0) {
            errors.push('No dependencies found to be running');
        }
        else {
            row.dependencies.forEach((dependency) => {
                if (dependency.error) {
                    errors.push(dependency.error);
                }
            });
        }
        if (errors.length === 0) {
            return 'N/A';
        }

        return <Tooltip icon="info" side={TooltipSide.Left}>{errors.join('\n')}</Tooltip>;
    }

    const columns = useMemo(
        () => {
            let columnsArray: Array<TableColumn> = [
                {
                    Header: 'Status',
                    accessor: (row) => renderStatus(row),
                },{
                    Header: 'Service',
                    accessor: (row) => parseServiceName(row.service),
                }, {
                    Header: 'Average Latency',
                    accessor: (row) => calculateAvgLatency(row.dependencies),
                }, {
                    Header: 'Slowest Dependency',
                    accessor: (row) => findSlowestDependency(row.service, row.dependencies),
                }, {
                    Header: 'Dependencies',
                    accessor: (row) => row.dependencies.length,
                }, {
                    Header: 'Errors',
                    accessor: (row) => calculateErrors(row),
                },
            ];

            return columnsArray;
        },
        [],
    );

    const data = useMemo(() => {
        const data = [...systemHealth.services];
        return data.sort((a, b) => {
            return b.dependencies.length - a.dependencies.length;
        });
    }, [systemHealth]);

    const firstHalf = data.slice(0, Math.floor(data.length / 2));
    const remainder = data.slice(Math.floor(data.length / 2));
    return (
        <>
            <H4>
                System Health Check Details - Completed {renderDateInPast(systemHealth.timestamp)}
            </H4>

            <Table
                columns={columns}
                data={data}
                pageNumber={0}
                pageSize={1}
                totalNumberOfItems={1}
                showPagination={false}
            />

            <br/>

            <H5 className="system-health-service__dependency-title">
                Individual Service Details
            </H5>

            <FormRow columnCount={2}>
                <FormColumn className="system-health-service__left-column">
                    {firstHalf.map((service) => (
                        <SystemHealthServiceDependenciesTable
                            service={service}
                            renderTitle={parseServiceName}
                        />
                    ))}
                </FormColumn>

                <FormColumn className="system-health-service__right-column">
                    {remainder.map((service) => (
                        <SystemHealthServiceDependenciesTable
                            service={service}
                            renderTitle={parseServiceName}
                        />
                    ))}
                </FormColumn>
            </FormRow>
        </>
    );
};

export default SystemHealthServicesTable;
