import './ConversationSummary.scss';

import React, { useMemo } from 'react';
import _get from 'lodash/get';

import { useTypedSelector } from "../../../../../../../../store/reducers";

import Conversation from "../../../../../../../../types/Conversation";

import Avatar from "../../../../../../../../components/Avatar";
import Icon from "../../../../../../../../components/Icon";
import { ProfileStatuses } from "../../../../../../../../utils/enums";

type Props = {
    conversation: Conversation
    onClick: Function
}

const ConversationSummary: React.FC<Props> = ({
    conversation,
    onClick
}) => {
    const { conversation: { conversationId: activeConversationId }} = useTypedSelector((state) => state.directMessages);

    const recipient = useMemo(
        () => {
            return conversation.recipients.find((r) => r.isMe !== 'Y');
        },
        [conversation.recipients]
    );

    const isUnread = conversation.messages && conversation.messages[0]?.viewed === 'N' && conversation.messages[0].isOwner === false;

    const renderMessage = () => {
        let messageBody = null;
        const mostRecentMessagePart = _get(conversation.messages, '0.parts.0');
        if (
            mostRecentMessagePart
            && mostRecentMessagePart.mimeType
            && mostRecentMessagePart.mimeType.toLowerCase() === 'application/json'
        ) {
            if(conversation.messages[0]?.artifact) {
                messageBody = 'Attachment: image';
            } else {
                messageBody = mostRecentMessagePart.body;
            }
        } else if(conversation.messages[0]?.artifact) {
            messageBody = 'Attachment: image';
        } else {
            messageBody = 'Unknown';
        }

        return messageBody;
    }

    return (
        <div
            className={`c-conversation-summary ${conversation.conversationId === activeConversationId ? 'a-conversation-summary--active' : ''}`}
            onClick={() => {
                if(onClick) {
                    onClick();
                }
            }}
        >
            <Avatar artifact={recipient?.profile?.artifact} />

            <div className="c-conversation-summary__content">
                <div className="c-conversation-summary__name">
                    {recipient?.profile?.firstName || ''} {recipient?.profile?.lastName || ''}
                </div>

                <div className="c-conversation-summary__message">
                    {renderMessage()}
                </div>
            </div>

            {isUnread ? <div className="c-conversation-summary__unread-dot" /> : <div className="c-conversation-summary__read-dot" />}
            {recipient.profile.status === ProfileStatuses.Pending && (
                <div className="c-conversation-summary__pending-indicator">
                    Pending User
                </div>
            )}
            <Icon type="caret-right" />
        </div>
    );
};

export default ConversationSummary;
