import './RadioGroup.scss';

import React from 'react';
import classNames from 'classnames';

type Props = {
    children: React.ReactNode
    className?: string
    horizontal?: boolean
}

const RadioGroup: React.FC<Props> = ({
    children,
    className,
    horizontal
}) => (
    <div className={classNames(
        'c-radio-group',
        className,
        {'a-radio-group--horizontal': horizontal === true}
    )}>
        {children}
    </div>
);

export default RadioGroup;

