import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import IError from "../../types/IError";
import Metadata from "../../types/Metadata";
import PushNotification from "../../types/PushNotification";
import PushNotificationsMetadata from "../../types/PushNotificationsMetadata";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import {generatePushNotification, generatePushNotificationsMetadata} from "../../utils/generators";

import { addError } from "./errors";
import { RootState } from '../reducers';

type CancelPushNotificationProps = {
    notificationId: number | string
}

export const cancelNotification = createAsyncThunk(
    'pushNotifications/cancelNotification',
    async ({notificationId}: CancelPushNotificationProps, {dispatch, getState}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.pushNotifications.cancel(tenantId, notificationId));
            console.log(res);
            return res;
        } catch(err) {
            console.log('delete thread err', err);
            err.friendlyMessage = 'Error deleting the thread. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

type GetPushNotificationProps = {
    notificationId: number | string
    schoolId?: number | string
}
export const getPushNotification = createAsyncThunk(
    'pushNotifications/getPushNotification',
    async ({notificationId, schoolId}: GetPushNotificationProps, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);
            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }
            const res = await new Request(token).get(PATHS.pushNotifications.getById(schoolId, notificationId));
            return res.data.data;
        } catch(err) {
            console.log('getPushNotification', err);
            err.friendlyMessage = 'Error getting this push notification. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetPushNotificationsProps = {
    isUpdate?: boolean
    profileType?: string
    pushNotificationsMetadata?: Metadata
    schoolId?: number
}

export const getPushNotifications = createAsyncThunk(
    'pushNotifications/getPushNotifications',
    async ({ profileType, pushNotificationsMetadata, schoolId }: GetPushNotificationsProps = {}, {dispatch, getState}) => {
        try {
            if(!pushNotificationsMetadata) {
                pushNotificationsMetadata = clone((getState() as RootState).pushNotifications.pushNotificationsMetadata);
            } else {
                pushNotificationsMetadata = {...pushNotificationsMetadata}
            }

            if(profileType) {
                pushNotificationsMetadata[`${profileType}_visibility`] = true;
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.pushNotifications.get(schoolId, buildQueryString(pushNotificationsMetadata)));
            let pushNotifications = res.data.data.items;
            pushNotificationsMetadata.total = res.data.data.meta.total;
            return {pushNotifications, pushNotificationsMetadata};
        } catch(err) {
            console.log('getPushNotifications', err);
            err.friendlyMessage = 'Error getting the list of past notifications. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type SendPushNotificationProps = {
    pushNotification?: PushNotification
}

export const sendPushNotification = createAsyncThunk(
    'pushNotifications/sendPushNotification',
    async ({pushNotification}: SendPushNotificationProps, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);

            if(!pushNotification) {
                pushNotification = (getState() as RootState).pushNotifications.pushNotification;
            }

            pushNotification = { ...pushNotification };

            if(pushNotification.post) {
                pushNotification.postId = pushNotification.post.postId;
                delete pushNotification.post;
            } else if(pushNotification.forumTopic) {
                pushNotification.forumTopicId = pushNotification.forumTopic.forumTopicId;
                delete pushNotification.forumTopic;
            } else if(pushNotification.tenantResource) {
                pushNotification.tenantResourceId = pushNotification.tenantResource.tenantResourceId;
                delete pushNotification.tenantResource;
            }

            if(pushNotification.targetForumTopic) {
                pushNotification.targetForumTopicId = pushNotification.targetForumTopic.forumTopicId;
                delete pushNotification.targetForumTopic;
                pushNotification.type = 'transactional_push';
            }

            const request = new Request(token);

            let reqFunc = request.post;
            //POST to different paths if notification is a broadcast_push, or a transactional_push
            let path: string;
            if (pushNotification.targetForumTopicId) {
                path = PATHS.pushNotifications.sendTransactionalPushToGroup(activeSchool.tenantId, pushNotification.targetForumTopicId);
            } else {
                path = PATHS.pushNotifications.sendBroadcastPushToTenant(activeSchool.tenantId);
            }

            if(pushNotification.targetedNotificationId) {
                reqFunc = request.put;
                path = PATHS.pushNotifications.update(activeSchool.tenantId, pushNotification.targetedNotificationId);
            }

            const res = await reqFunc(path, pushNotification);
            return res;
        } catch(err) {
            console.log('send push notification err', err);
            err.friendlyMessage = 'Error sending this push notification. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);


interface PushNotificationsState {
    cancelPushNotificationError: IError
    getPushNotificationError: IError
    getPushNotificationsError: IError
    isCancellingPushNotification: boolean
    isGettingPushNotification: boolean
    isGettingPushNotifications: boolean
    isSendingPushNotification: boolean
    pushNotification: PushNotification
    pushNotifications: Array<PushNotification>
    pushNotificationsMetadata: PushNotificationsMetadata
    searchTerm: string
    sendPushNotificationError: IError
}

const initialState: PushNotificationsState = {
    pushNotification: generatePushNotification(),
    pushNotifications: [],
    pushNotificationsMetadata: generatePushNotificationsMetadata(),
    searchTerm: '',
    isCancellingPushNotification: false,
    isGettingPushNotification: false,
    isGettingPushNotifications: false,
    isSendingPushNotification: false,
    cancelPushNotificationError: undefined,
    getPushNotificationError: undefined,
    getPushNotificationsError: undefined,
    sendPushNotificationError: undefined
};

export const pushNotificationsSlice = createSlice({
    name: 'pushNotifications',
    initialState,
    reducers: {
        clearPushNotification: (state) => {
            state.pushNotification = generatePushNotification();
        },
        clearPushNotificationsMetadata: (state) => {
            state.pushNotificationsMetadata = generatePushNotificationsMetadata();
            state.searchTerm = '';
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setPushNotification: (state, action) => {
            state.pushNotification = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(cancelNotification.pending, (state) => {
            state.cancelPushNotificationError = undefined;
            state.isCancellingPushNotification = true;
        });
        addCase(cancelNotification.fulfilled, (state, action) => {
            state.isCancellingPushNotification = false;
            state.pushNotification = generatePushNotification();
        });
        addCase(cancelNotification.rejected, (state, action) => {
            state.cancelPushNotificationError = action.error as IError;
            state.isCancellingPushNotification = false;
        });

        addCase(getPushNotification.pending, (state, action) => {
            state.getPushNotificationError = undefined;
            state.isGettingPushNotification = true;
        });
        addCase(getPushNotification.fulfilled, (state, action) => {
            state.pushNotification = action.payload;
            state.isGettingPushNotification = false;
        });
        addCase(getPushNotification.rejected, (state, action) => {
            state.getPushNotificationError = action.error;
            state.isGettingPushNotification = false;
        });

        addCase(getPushNotifications.pending, (state, action) => {
            state.getPushNotificationsError = undefined;
            state.isGettingPushNotifications = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.pushNotificationsMetadata) {
                state.pushNotificationsMetadata = action.meta.arg.pushNotificationsMetadata;
            }
        });
        addCase(getPushNotifications.fulfilled, (state, action) => {
            state.pushNotifications = action.payload.pushNotifications;
            state.pushNotificationsMetadata = action.payload.pushNotificationsMetadata;
            state.isGettingPushNotifications = false;
        });
        addCase(getPushNotifications.rejected, (state, action) => {
            state.getPushNotificationsError = action.error;
            state.isGettingPushNotifications = false;
        });

        addCase(sendPushNotification.pending, (state) => {
            state.isSendingPushNotification = true;
            state.sendPushNotificationError = undefined;
        });
        addCase(sendPushNotification.fulfilled, (state, action) => {
            state.isSendingPushNotification = false;
            state.pushNotification = generatePushNotification();
            const clonedNotifications = clone(state.pushNotifications);
            clonedNotifications.unshift(action.payload.data.data);
            state.pushNotifications = clonedNotifications;
        });
        addCase(sendPushNotification.rejected, (state, action) => {
            state.sendPushNotificationError = action.error as IError;
            state.isSendingPushNotification = false;
        });
    }
});

export const { clearPushNotification, clearPushNotificationsMetadata, setSearchTerm, setPushNotification } = pushNotificationsSlice.actions;

export default pushNotificationsSlice.reducer;
