import './DmDashboard.scss';

import React, { useEffect, useMemo } from 'react';

import clone from 'clone';
import moment from 'moment';

import { getDmDashboard } from '../../../../../../store/slices/admitDashboard';
import { useTypedSelector } from '../../../../../../store/reducers';
import { useAppDispatch } from '../../../../../../store';

import TableColumn from '../../../../../../types/TableColumn';
import ActivityIndicator from '../../../../../../components/ActivityIndicator';
import Card from '../../../../../../components/Card';
import H1 from '../../../../../../components/H1';
import Table from '../../../../../../components/Table';

const AdmitDmDashboard: React.FC = () => {
    const dispatch = useAppDispatch();

    const {dmReport, dmReportMetadata, isGettingDmReport} = useTypedSelector((state) => state.admitDashboard);

    useEffect(() => {
        const initialize = async () => {
            if (isGettingDmReport) {
                return;
            }

            try {
                dispatch(getDmDashboard({}));
            } catch (err) {
                console.log('DmDashboard initialize err', err);
            }
        };

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderDateInPast = (date: Date) => {
        return moment(date).format('MM/DD/YYYY');
    };

    const columns = useMemo(
        () => {
            let columnsArray: Array<TableColumn> = [
                {
                    Header: 'First Name',
                    accessor: (d) => d.profile.firstName,
                }, {
                    Header: 'Last Name',
                    accessor: (d) => d.profile.lastName,
                }, {
                    Header: 'Email Address',
                    accessor: (d) => d.profile.emailAddress,
                }, {
                    Header: 'Assigned To',
                    accessor: (d) => d.assignedUser.emailAddress,
                }, {
                    Header: 'Created At',
                    accessor: (d) => renderDateInPast(d.conversationCreatedAt),
                }, {
                    Header: 'First Message',
                    accessor: (d) => renderDateInPast(d.firstMessageCreatedAt),
                },{
                    Header: 'Last Message',
                    accessor: (d) => renderDateInPast(d.lastMessageCreatedAt),
                }, {
                    Header: 'Messages Sent',
                    accessor: 'messageCount',
                },
            ];

            return columnsArray;
        }, []);

    const data = useMemo(
        () => {
            return dmReport;
        }, [dmReport]);

    const onMetadataChange = async (changes) => {
        console.log('onMetadataChange', changes);
        try {
            let clonedMetadata = clone(dmReportMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes,
            };

            return await dispatch(getDmDashboard({dmReportMetadata: clonedMetadata}));
        } catch (err) {
            console.log('DmDashboard onMetadataChange err', err);
        }
    };

    return isGettingDmReport ? (
        <ActivityIndicator size="large"/>
    ) : (
        <Card>
            <H1>Admit DM Data</H1>
            <div className="dm-dashboard-subtitles">
                <div className="dm-dashboard-subtitle">
                    Total Conversations:
                    <div className="dm-dashboard-subtitle-value">
                        {dmReportMetadata.total}
                    </div>
                </div>

                <div className="dm-dashboard-subtitle">
                    Total Messages:
                    <div className="dm-dashboard-subtitle-value">
                        {dmReportMetadata.totalMessageCount}
                    </div>
                </div>
            </div>

            <Table
                columns={columns}
                data={data}
                getData={onMetadataChange}
                pageNumber={dmReportMetadata.page_num}
                pageSize={dmReportMetadata.page_size}
                totalNumberOfItems={dmReportMetadata.total}
            />
        </Card>

    );
};

export default AdmitDmDashboard;
