import './OtherAdminUsers.scss';

import React, { useEffect, useState } from 'react';
import { useRouteMatch } from "react-router";

import { getCurrentUserRole, ROLE_STRINGS } from "../../utils/roles";
import { isObjectNullOrEmpty } from "../../utils/utils";

import { useAppDispatch } from '../../store';
import { useTypedSelector } from '../../store/reducers';
import { getOtherAdminUsers } from "../../store/slices/schools";

import ActivityIndicator from '../../components/ActivityIndicator';
import Alert from '../Alert';
import Avatar from '../../components/Avatar';
import Button, { ButtonSizes, ButtonThemes } from "../Button";
import Card from '../../components/Card';
import Icon from "../Icon";

type Props = {
    onClickUser?: Function
    highlightUserId?: number
    limit?: number
}

const OtherAdminUsers: React.FC<Props>= ({
    onClickUser,
    highlightUserId,
    limit
}) => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const [truncateAdminUsers, setTruncateAdminUsers] = useState(true);
    const [filteredAdminUsers, setFilteredAdminUsers] = useState([]);

    const { isGettingOtherAdminUsers, otherAdminUsers, getOtherAdminUsersError } = useTypedSelector((state) => state.schools);

    useEffect(() => {
        const initialize = async (schoolId) => {
            if(isGettingOtherAdminUsers) {
                return;
            }

            try {
                await dispatch(getOtherAdminUsers({tenantId: parseInt(schoolId, 10)}));
            } catch(err) {
                console.log('OtherAdminUsers init err', err);
            }
        }

        if(match?.params?.schoolId) {
            initialize(match.params.schoolId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params?.schoolId]);

    useEffect(() => {
        if (limit) {
            setFilteredAdminUsers(otherAdminUsers.slice(0, limit))
        } else {
            setFilteredAdminUsers(otherAdminUsers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherAdminUsers])


    useEffect(() => {
        if (truncateAdminUsers) {
            setFilteredAdminUsers(otherAdminUsers.slice(0, limit))
        } else {
            setFilteredAdminUsers(otherAdminUsers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [truncateAdminUsers])

    const handleUserClick = (user) => {
        if (onClickUser) {
            onClickUser(user);
        }
    }

    const currentUserRole = getCurrentUserRole({tenantId: match.params.schoolId});

    return (
        <Card
            className="c-online-students"
            title={`Other ${ROLE_STRINGS[currentUserRole] || ''} Users`}
        >
            {isGettingOtherAdminUsers ? (
                <ActivityIndicator />
            ) : (
                <>

                    {!isObjectNullOrEmpty(getOtherAdminUsersError) && (
                        <Alert theme='error' data={getOtherAdminUsersError}>
                            There was a problem fetching other admin users.
                        </Alert>
                    )}

                    {filteredAdminUsers.map((user, index) => (
                        <div
                            key={index}
                            className={`c-user-list-item ${(highlightUserId && highlightUserId !== user.userId) ? 'c-user-list-item--faded' : ''}`}
                            onClick={() => handleUserClick(user)}
                        >
                            <Avatar artifact={user.artifact} />
                            <div>
                                <div className="c-user-list-item__name">{user.firstName} {user.lastName}</div>
                                {user.position && (
                                    <div className="c-user-list-item__position">{user.position}</div>
                                )}
                            </div>


                        </div>
                    ))}

                    {(limit > 0 && otherAdminUsers.length > limit) && (
                        <Button
                            className="c-online-students__more-button"
                            theme={ButtonThemes.Light}
                            size={ButtonSizes.Small}
                            onClick={() => setTruncateAdminUsers(!truncateAdminUsers)}
                        >
                            View {truncateAdminUsers ? 'More' : 'Less'} <Icon type="caret-down" />
                        </Button>
                    )}

                </>
            )}
        </Card>
    );
};

export default OtherAdminUsers;
