import './LandingPageThreadSummary.scss';

import React from 'react';
import Icon from "../../../../../../../components/Icon";
import TableRowImage from "../../../../../../../components/Table/TableRowImage";
import Button from "../../../../../../../components/Button";
import H5 from "../../../../../../../components/H5";

type Props = {
    handleDelete: Function
    thread: Thread | {post: Thread}
}

const LandingPageThreadSummary: React.FC<Props> = ({
    handleDelete,
    thread,
}) => {
    let postData = thread.post || thread;

    return (
        <div className="c-landing-page-thread-summary">
            <div className="c-landing-page-thread-summary__drag-target">
                <Icon type="drag" />
            </div>

            <TableRowImage
                artifact={postData.artifacts && postData.artifacts[0]}
                className="c-landing-page-thread-summary__image"
            />

            <div className="c-landing-page-thread-summary__content">
                {postData.title != null && (
                    <H5>
                        {postData.title}
                    </H5>
                )}

                {postData.description != null && (
                    <div className="c-landing-page-thread-summary__description">
                        {postData.description}
                    </div>
                )}
            </div>

            <Button
                className="c-landing-page-thread-summary__delete-button"
                noStyles
                onClick={() => handleDelete()}
            >
                <Icon type="delete" />
            </Button>
        </div>
    );
};

export default LandingPageThreadSummary;
