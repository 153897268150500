import './CronJobsTable.scss';

import React, { useEffect, useMemo } from 'react';
import clone from 'clone';
import moment from 'moment';

import { isArrayNullOrEmpty } from "../../../../utils/utils";
import { CAPABILITIES, userCan } from "../../../../utils/roles";

import { getCronJobs, getCronJobStatus, triggerConJob } from '../../../../store/slices/engineering';
import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import H5 from "../../../../components/H5";
import Card from "../../../../components/Card";
import Table from "../../../../components/Table";
import TableColumn from '../../../../types/TableColumn';
import TableMoreMenu from '../../../../components/Table/TableMoreMenu';


const CronJobsTable: React.FC = () => {
    const dispatch = useAppDispatch();

    const { cronJobsMetadata, cronJobs, cronJobStatus, isGettingCronJobs, isGettingCronJobStatus, isTriggeringCronJob } = useTypedSelector((state) => state.engineering);
    const { roles } = useTypedSelector((state) => state.auth);

    useEffect(() => {
        const initialize = async () => {
            if(isGettingCronJobs) {
                return;
            }

            try {
                if(!isArrayNullOrEmpty(roles) && userCan(CAPABILITIES.VIEW_ENGINEERING, roles[0])) {
                    dispatch(getCronJobStatus());
                    dispatch(getCronJobs({}));
                }
            } catch(err) {
                console.log('CronJobsTable initialize err', err);
            }
        };

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const triggerCronJobRequest = async (name: string) => {
        try {
            await dispatch(triggerConJob({name}));
        }
        catch (err: any) {
            console.log("triggerCronJobsRequest err: ", err);
        }
    }

    const columns = useMemo(
        () => {
            let columnsArray: Array<TableColumn> = [
                {
                    Header: 'ID',
                    accessor: 'arn'
                }, {
                    Header: 'Name',
                    accessor: 'name'
                }, {
                    Header: 'Last Run',
                    accessor: (d) => d.lastRun ? moment(d.lastRun).fromNow() : '--'
                }, {
                    Header: 'Next Run',
                    accessor: (d) => d.nextRun ? moment(d.nextRun).fromNow() : '--'
                }, {
                    Header: 'State',
                    accessor: 'state'
                }, {
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => (
                        <TableMoreMenu
                            customOptions={[{
                                icon: 'info',
                                onClick: () => !isTriggeringCronJob && triggerCronJobRequest(original.name),
                                text: 'Trigger Now'
                            }]}
                            hideModerationOptions
                            isDeleting={false}
                        />
                    )
                }
            ];

            return columnsArray;
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const data = useMemo(
        () => {
            return cronJobs;
        },
        [cronJobs]
    );

    const onMetadataChange = async (changes, isUpdate) => {
        console.log('onMetadataChange', changes, isUpdate);
        try {
            let clonedMetadata = clone(cronJobsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getCronJobs({isUpdate, cronJobsMetadata: clonedMetadata}));
        } catch(err) {
            console.log('CronJobsTable onMetadataChange err', err);
        }
    };

    return (
        <div className="engineering">
            <H5>
                Cron Jobs
            </H5>

            <div className="engineering__status-row">
                <Card title="Status">
                    {isGettingCronJobStatus ? (
                        <ActivityIndicator />
                    ) : (
                        <div className="engineering__status">
                            {cronJobStatus.overallStatus}
                        </div>
                    )}
                </Card>

                <Card title="Jobs">
                    {isGettingCronJobs ? (
                        <ActivityIndicator />
                    ) : (
                        <div className="engineering__status">
                            {cronJobsMetadata.total || 10}
                        </div>
                    )}
                </Card>
            </div>

            <div>
                {isGettingCronJobs ? (
                    <ActivityIndicator size="large" />
                ) : (
                    <>
                        <Table
                            columns={columns}
                            data={data}
                            getData={onMetadataChange}
                            pageNumber={cronJobsMetadata.page_num}
                            pageSize={cronJobsMetadata.page_size}
                            totalNumberOfItems={cronJobsMetadata.total}
                            /*onRowClick={handleRowPress}*/
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default CronJobsTable;
