import React, { Dispatch, SetStateAction, useState } from 'react';

import { validated } from 'react-custom-validation';

import Profile from "../../../../../../types/Profile";
import Validation from '../../../../../../types/Validation';

import { isEmailOrEmpty } from '../../../../../../utils/validations';
import { handleTextChange } from '../../../../../../utils/handle-changes';

import { setAlternateEmailAddress } from "../../../../../../store/slices/students";
import { promoteAdmitToStudent, setSearchTerm  } from "../../../../../../store/slices/admits";
import { useAppDispatch } from "../../../../../../store";

import Button, { ButtonThemes, ButtonTypes } from "../../../../../../components/Button";
import Form from '../../../../../../components/Form';
import FormRow from '../../../../../../components/FormRow';
import Textbox from '../../../../../../components/Textbox';

type PromoteStudentFormProps = {
    alternateEmail?: string
    close: () => void
    profile: Profile
    setAlternateEmail: Dispatch<SetStateAction<string>>
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        alternateEmail: Validation
    }
}

const PromoteStudentForm: React.FC<PromoteStudentFormProps> = ({
    alternateEmail,
    close,
    profile,
    setAlternateEmail,
    $field,
    $fieldEvent,
    $submit,
    $validation,
}) => {
    const dispatch = useAppDispatch();

    const [isPromoting, setIsPromoting] = useState<boolean>(false);

    const handleChange = (name: string, value: string) => {
        setAlternateEmail(value);
    };

    const handleSetAlternateEmail = async () => {
        try {
            if (alternateEmail) {
                await dispatch(setAlternateEmailAddress({
                    profileId: profile.profileId,
                    alternateEmail,
                })).unwrap();
            }
        }
        catch(err) {
            console.log('PromoteStudentForm handleSetAlternateEmail err', err);
        }
    };

    const handleSubmit = async (isValid: boolean) => {
        if(!isValid) {
            return;
        }

        try {
            setIsPromoting(true);
            await handleSetAlternateEmail();
            await dispatch(promoteAdmitToStudent({admitProfileId: profile.admitProfile.admitProfileId})).unwrap();
            dispatch(setSearchTerm(''));
            close();
            setTimeout(() => $fieldEvent('reset'), 500);
        } catch(err) {
            console.log('PromoteStudentForm handleSubmit err', err);
        } finally {
            setIsPromoting(false);
        }
    };

    return (
        <Form>
            <FormRow>
                <Textbox
                    id="txtAlternateEmail"
                    label="New Email Address"
                    name="alternateEmail"
                    type="email"
                    placeholder="janesmith@learning.edu"
                    validation={$validation.alternateEmail}
                    value={alternateEmail || ''}
                    icon="envelope"
                    {...$field('alternateEmail', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow className="button-container">
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false))
                    }}
                    showActivityIndicator={isPromoting}
                    type={ButtonTypes.Submit}
                >
                    Promote
                </Button>

                <Button
                    onClick={() => {
                        dispatch(setSearchTerm(''));
                        close();
                        setTimeout(() => $fieldEvent('reset'), 500);
                    }}
                    theme={ButtonThemes.Secondary}
                >
                    Cancel
                </Button>
            </FormRow>
        </Form>
    );
};

const promoteStudentFormConfig = (props: PromoteStudentFormProps) => {
    const { alternateEmail } = props;

    return {
        fields: ['alternateEmail'],
        validations: {
            alternateEmail: [
                [isEmailOrEmpty, alternateEmail, 'Please enter a valid email address.'],
            ]
        },
    };
};

export default validated(promoteStudentFormConfig)(PromoteStudentForm);
