import './Errors.scss';

import React, { useState } from 'react';

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { clearErrors } from "../../store/slices/errors";
import { useTypedSelector } from '../../store/reducers';

import Modal from '../../components/Modal';

const Errors: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [closeModal, setCloseModal] = useState(false);

    const { errors } = useTypedSelector(store => store.errors);

    const ErrorItem = ({err}) => {
        const [showDetails, setShowDetails] = useState(false);
        const handleFocus = (event) => event.target.select();
        return (
            <>
                <div onClick={() => setShowDetails(!showDetails)}>
                    {err.status === 401 || (err.response && err.response.status === 401) ? (
                        <>Your session has expired. Please log in again.</>
                    ) : (
                        <>{err.friendlyMessage || err.message}</>
                    )}

                </div>
                {showDetails && (
                    <textarea
                        wrap="off"
                        rows={8}
                        className='c-error-dump'
                        onFocus={handleFocus}
                    >
                        {JSON.stringify(err, null, 4)}
                    </textarea>
                )}
            </>
        )
    }

    const handleClose = async () => {
        for(var i = 0; i < errors.length; i++) {
            if(errors[i].status === 401 || (errors[i].response && errors[i].response.status === 401)) {
                history.push('/auth/logout');
                break;
            }
        }

        setCloseModal(true);
        setTimeout(() => {
            dispatch(clearErrors());
            setCloseModal(false);
        }, 500);
    };

    return (
        <Modal
            declineButtonOnClick={handleClose}
            declineButtonText="OK"
            show={errors && errors.length > 0 && !closeModal}
            title="Error"
        >
            <ul className="errors__list">
                {errors.map((err, i) => (
                    <li key={i}>
                        <ErrorItem err={err} />
                    </li>
                ))}
            </ul>
        </Modal>
    );
};

export default Errors;
