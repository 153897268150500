import './GridClassNote.scss';

import React from 'react';

import ClassNote from '../../../types/ClassNote';

import buildImageSrc from '../../../utils/buildImageSrc';

import Avatar from '../../Avatar';
import Card from '../../Card';
import CardVideo from '../../CardVideo';
import H6 from '../../H6';

type Props = {
    disablePending?: boolean
    item: ClassNote
    moreMenuComponent?: Function
    onClick?: Function
}

const GridClassNote: React.FC<Props> = ({
   disablePending,
   item,
   moreMenuComponent,
   onClick,
}) => {
    const isNotActive = item.status !== 'A' && disablePending;

    return (
        <Card
            className={`c-grid-class-note c-grid-list__item ${isNotActive ? 'a-grid-list__item--inactive' : ''}`}
            onClick={() => {
                if(isNotActive || !onClick) {
                    return null;
                }

                onClick(item);
            }}
        >
            <div className="c-grid-class-note__image-container c-grid-list__item__image-container">
                <div className="c-grid-class-note__image c-grid-list__item__image" style={item.artifacts && item.artifacts[0] ? {backgroundImage: `url(${buildImageSrc(item.artifacts[0], '667')})`} : null} >
                    {item.artifacts && item.artifacts[0] && item.artifacts[0].videoMp4Url && (
                        <CardVideo videoUrl={item.artifacts[0].videoMp4Url} />
                    )}
                </div>
            </div>

            <div className="c-grid-class-note__content c-grid-list__item__content">
                <H6 className="c-grid-class-note__title c-grid-list__item__title">
                    {item.title}
                </H6>

                {item.profile && (
                    <div className="c-grid-class-note__profile c-grid-list__item__profile">
                        <Avatar artifact={item.profile.artifact} />

                        {item.profile.firstName} {item.profile.lastName}
                    </div>
                )}
            </div>

            {moreMenuComponent(item)}
        </Card>
    );
};

export default GridClassNote;
