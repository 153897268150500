import './SchoolUsers.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clone from 'clone';
import { useRouteMatch } from "react-router";

import TableColumn from "../../../../types/TableColumn";

import { ROLE_STRINGS } from "../../../../utils/roles";
import { LIST_TYPES } from "../../../../utils/constants";
import { isArrayNullOrEmpty } from "../../../../utils/utils";

import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";
import {
    clearUser,
    clearUsersMetadata,
    deleteUser,
    getUsers,
    resendInvitation,
    setSearchTerm,
    setUser
} from '../../../../store/slices/users';

import ActivityIndicator from "../../../../components/ActivityIndicator";
import Avatar from "../../../../components/Avatar";
import Button, { ButtonSizes, ButtonThemes } from "../../../../components/Button";
import CreateEditUser from "./CreateEditUser";
import GridList, { GridListTypes } from "../../../../components/GridList";
import H5 from "../../../../components/H5";
import Icon from "../../../../components/Icon";
import ListTypeSelector from "../../../../components/ListTypeSelector";
import PageHeader from "../../../../components/PageHeader";
import Table from "../../../../components/Table";
import TableFilterRow from "../../../../components/Table/TableFilterRow";
import TableFilterRowDivider from "../../../../components/Table/TableFilterRow/TableFilterRowDivider";
import TableMoreMenu from "../../../../components/Table/TableMoreMenu";
import TableSearch from "../../../../components/TableSearch";
import Modal from "../../../../components/Modal";
import SuccessModal from '../../../../components/Modal/SuccessModal';

const SchoolUsers: React.FC = () => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const [initialized, setInitialized] = useState(false);
    const [selectedListType, setSelectedListType] = useState(LIST_TYPES.TABLE);
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [showResendSuccessModal, setShowResendSuccessModal] = useState(false);
    const [showDeletedUserConfirmationModal, setShowDeletedUserConfirmationModal] = useState(false);
    const [showDeletedUserSuccessModal, setShowDeletedUserSuccessModal] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);

    const { activeSchool } = useTypedSelector((state) => state.schools);
    const { isDeletingUser, isGettingUsers, searchTerm, users, usersMetadata } = useTypedSelector((state) => state.users);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getUsers({schoolId: match?.params?.schoolId})).unwrap();
            } catch(err) {
                console.log('SchoolUsers initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            setShowNewUserModal(false);
            dispatch(clearUser());
            dispatch(clearUsersMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'User',
                    id: 'user',
                    sortBy: 'firstName',
                    Cell: ({row: { original }}) => (
                        <div className="users-table__user">
                            <Avatar
                                artifact={original.artifact}
                            />

                            <div className="users-table__name">
                                {original.firstName} {original.lastName}
                            </div>
                        </div>
                    )
                }, {
                    Header: 'Position',
                    accessor: 'position',
                    sortBy: 'position'
                }, {
                    Header: 'Email',
                    accessor: 'emailAddress',
                    sortBy: 'emailAddress'
                }, {
                    Header: 'Role',
                    accessor: (d) => {
                        let matchingRole = d.roles.find((r) => r.tenantId === activeSchool.tenantId);
                        if(!matchingRole) {
                            return '--';
                        }
                        return ROLE_STRINGS[matchingRole.type];
                    },
                }, {
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original)
                }
            ];
            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const data = useMemo(
        () => {
            return users;
        },
        [users]
    );

    const handleEdit = (user) => {
        dispatch(setUser(user));
        setShowNewUserModal(true);
    }

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(usersMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };
            return await dispatch(getUsers({isUpdate, usersMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('SchoolUsers onMetadataChange err', err);
        }
    };


    const triggerUserDelete = async () => {
        if (!userIdToDelete) {
            return;
        }

        try {
            await dispatch(deleteUser({userId: userIdToDelete})).unwrap();
            setShowDeletedUserSuccessModal(true);
        } catch(err) {
            console.log('SchoolUsers deleteUser err', err);
        }
    }

    const renderMoreMenu = (item) => {
        const CUSTOM_TABLE_MORE_MENU_OPTIONS = [
            {
                icon: 'trash',
                onClick: () => {
                    setUserIdToDelete(item.userId);
                    setShowDeletedUserConfirmationModal(true);
                },
                text: 'Delete',
            }
        ];

        const INVITE_BUTTON = {
            icon: 'team',
            onClick: async () => {
                try {
                    await dispatch(resendInvitation({emailAddress: item.emailAddress})).unwrap();
                    setShowResendSuccessModal(true);
                } catch(err) {
                    console.log('SchoolUsers renderMoreMenu resendInvitation err', err);
                }
            },
            text: 'Resend',
        }
        if (item.status === 'P') {
            CUSTOM_TABLE_MORE_MENU_OPTIONS.unshift(INVITE_BUTTON);
        }

        return (
            <TableMoreMenu
                customOptions={CUSTOM_TABLE_MORE_MENU_OPTIONS}
                hideModerationOptions
                item={item}
                type="user"
                onEditClick={() => handleEdit(item)}
            />
        );
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="school-users">
            <PageHeader>
                <H5>
                    <Icon type="navigation-admins" />

                    Admins
                </H5>
            </PageHeader>

            <TableFilterRow>
                <ListTypeSelector
                    onListTypeSelected={(listType) => setSelectedListType(listType)}
                    selected={selectedListType}
                />

                <TableFilterRowDivider />

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search Admins"
                    searchTerm={searchTerm}
                    size="small"
                />

                <Button
                    onClick={() => {
                        setShowNewUserModal(true);
                    }}
                    size={ButtonSizes.Small}
                >
                    New Admin
                </Button>
            </TableFilterRow>

            {isGettingUsers ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                            No users to display. <Button
                                onClick={() => setShowNewUserModal(true)}
                                theme={ButtonThemes.Link}
                            >
                                Create One?
                            </Button>
                        </>
                    ) : (
                        <>
                            {selectedListType === LIST_TYPES.TABLE ? (
                                <Table
                                    columns={columns}
                                    data={data}
                                    getData={onMetadataChange}
                                    pageNumber={usersMetadata.page_num}
                                    pageSize={usersMetadata.page_size}
                                    sortBy={usersMetadata.sort}
                                    sortOrder={usersMetadata.order}
                                    totalNumberOfItems={usersMetadata.total}
                                />
                            ) : (
                                <GridList
                                    createCardButtonOnClick={() => setShowNewUserModal(true)}
                                    createCardButtonText="Create an Admin"
                                    isLastPage={Math.ceil(usersMetadata.total / usersMetadata.page_size) === usersMetadata.page_num + 1}
                                    items={data}
                                    listType={GridListTypes.User}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: usersMetadata.page_num + 1})}
                                    onPreviousPageClick={() => onMetadataChange({page_num: usersMetadata.page_num - 1})}
                                    overlayButtonText="Edit"
                                    pageNumber={usersMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <CreateEditUser
                close={() => {
                    setShowNewUserModal(false);
                    setTimeout(() => clearUser(), 500);
                }}
                show={showNewUserModal}
            />

            <Modal
                declineButtonOnClick={() => setShowResendSuccessModal(false)}
                declineButtonText="OK"
                show={showResendSuccessModal}
                title="Success!"
            >
                We've sent another invitation to this user's email address.
            </Modal>

            <Modal
                confirmButtonOnClick={() => {
                    triggerUserDelete().then(() => setShowDeletedUserConfirmationModal(false));
                }}
                confirmButtonText="Delete"
                declineButtonOnClick={() => setShowDeletedUserConfirmationModal(false)}
                declineButtonText="Cancel"
                show={showDeletedUserConfirmationModal}
                showActivityIndicator={isDeletingUser}
                title="Please Confirm"
            >
                Are you sure you want to delete this user? This action cannot be undone.
            </Modal>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getUsers({schoolId: match?.params?.schoolId}));
                    setShowDeletedUserSuccessModal(false);
                }}
                show={showDeletedUserSuccessModal}
            >
                <p>
                    This user has been deleted.
                </p>
                <p>
                    It may take a minute for the users content to be removed from feeds and lists.
                </p>
            </SuccessModal>
        </div>
    );
};

export default SchoolUsers;
