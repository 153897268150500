import './ForgotPassword.scss';

import React from 'react';
import Helmet from 'react-helmet';

import { useTypedSelector } from '../../../store/reducers';
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPassword = () => {
    const { credentials } = useTypedSelector(store => store.auth);

    console.log(credentials);

    return (
        <>
            <Helmet>
                <title>Reset Your Password</title>
            </Helmet>

            <ForgotPasswordForm
                credentials={credentials}
            />
        </>
    );
};

export default ForgotPassword;
