import React, { useMemo } from 'react';

import { HealthCheckStatus } from '../../../../types/HealthCheckStatus';
import SystemHealthService from '../../../../types/SystemHealthService';

import H6 from '../../../../components/H6';
import TableColumn from '../../../../types/TableColumn';
import Table from '../../../../components/Table';

type Props = {
    service: SystemHealthService;
    renderTitle: (string: string) => string;
}

const SystemHealthServiceDependenciesTable: React.FC<Props> = ({
    renderTitle,
    service,
}) => {
    const renderStatus = (status: HealthCheckStatus) => {
        return status === HealthCheckStatus.OK  ? '🟢' : '🔴'
    }


    const calculateLatency = (durationMs: number) => {
        if (durationMs === -1) {
            return 'N/A';
        }
        return durationMs + 'ms';
    }

    const calculateErrors = (errorsInPastHour: number) => {
        if (errorsInPastHour === -1) {
            return 'N/A';
        }
        return errorsInPastHour.toString();
    }

    const columns = useMemo(
        () => {
            let columnsArray: Array<TableColumn> = [
                {
                    Header: 'Status',
                    accessor: (row) => renderStatus(row.status),
                },{
                    Header: 'Dependency',
                    accessor: (row) => renderTitle(row.name),
                }, {
                    Header: 'Latency',
                    accessor: (row) => calculateLatency(row.durationMs),
                }, {
                    Header: 'Current Error',
                    accessor: (row) => row.error ? row.error : 'N/A',
                }, {
                    Header: 'Recent Errors',
                    accessor: (row) => calculateErrors(row.errorsInPastHour),
                },
            ];

            return columnsArray;
        },
        [],
    );
    const data = useMemo(() => {
        const data = [...service.dependencies];
        return data.sort((a, b) => {
            return b.durationMs - a.durationMs;
        });
    }, [service.dependencies]);

    if (data.length === 0) {
        data.push({
            name: 'N/A',
            status: HealthCheckStatus.ERROR,
            durationMs: -1,
            errorsInPastHour: -1,
            error: 'No Dependencies Found To Be Running',
        });
    }

    return (
        <>
            <H6>
                {renderTitle(service.service)} Dependencies
            </H6>

            <Table
                columns={columns}
                data={data}
                totalNumberOfItems={data.length}
                showPagination={false}
                pageNumber={0}
                pageSize={data.length}
            />
        </>
    );
};

export default SystemHealthServiceDependenciesTable;
