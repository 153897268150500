import './PendingImage.scss';

import React, { useEffect, useState } from 'react';

import ChatMessage from "../../types/ChatMessage";

import {useAppDispatch} from "../../store";
import {deleteGroupChatMessage} from "../../store/slices/groupChat";

import ActivityIndicator from "../ActivityIndicator";
import Button, { ButtonSizes, ButtonThemes } from "../Button";

type Props = {
    message: ChatMessage
}

const PendingImage: React.FC<Props> = ({message}) => {
    const dispatch = useAppDispatch();

    const [showCancel, setShowCancel] = useState(false);

    useEffect(() => {
        let showCancelTimeout;
        if (message.pendingImage) {
            showCancelTimeout = setTimeout(() => {
                setShowCancel(true);
            }, 2000);
        }
        return () => {
            clearTimeout(showCancelTimeout);
        };
    }, [message.pendingImage]);

    if (!message.pendingImage) return null;

    return (
        <div className='c-pending-image-wrap'>
            <div className="c-pending-image">
                <img className="c-group-chat-message__artifact" src={message.pendingImage.preview} alt="Posted in a group chat" />

                <div className="c-pending-image__overlay">
                    <ActivityIndicator size="large" />
                </div>
            </div>

            {showCancel && (
                <div className="c-pending-image-cancel">
                    Image is still processing.&nbsp;
                    <Button
                        onClick={() => dispatch(deleteGroupChatMessage(message.forumTopicMessageId))}
                        size={ButtonSizes.Small}
                        theme={ButtonThemes.Light}
                    >
                        Delete
                    </Button>
                </div>

            )}


        </div>
    );
};

export default PendingImage;
