import './GridUser.scss';

import React from 'react';

import User from "../../../types/User";

import Avatar from "../../Avatar";
import Card from "../../Card";
import H6 from "../../H6";

type Props = {
    item: User
    moreMenuComponent?: Function
    onClick?: Function
}

const GridUser: React.FC<Props> = ({
    item,
    moreMenuComponent,
    onClick,
}) => {
    return (
        <Card
            className="c-grid-user c-grid-list__item"
            onClick={() => onClick && onClick(item)}
        >
            <Avatar artifact={item.artifact} />

            <div className="c-grid-user__content c-grid-list__item__content">
                <H6 className="c-grid-user__title c-grid-list__item__title">
                    {item.firstName} {item.lastName}
                </H6>

                {item.position && (
                    <div className="c-grid-user__title c-grid-list__item__description">
                        {item.position}
                    </div>
                )}
            </div>

            {moreMenuComponent && moreMenuComponent(item)}
        </Card>
    );
};

export default GridUser;
