import './Avatar.scss';

import React from 'react';

import Artifact from "../../types/Artifact";

import { isObjectNullOrEmpty } from '../../utils/utils';
import buildImageSrc from '../../utils/buildImageSrc';

import FlaggedIcon from "../Icon/FlaggedIcon";
import Icon from "../Icon";

type Props = {
    artifact?: Artifact
    className?: string
    flagged?: boolean
    isOnline?: boolean
}

const Avatar: React.FC<Props> = ({
    artifact,
    className = '',
    flagged,
    isOnline,
}) => {
    const hasArtifact = !isObjectNullOrEmpty(artifact);

    return (
        <div className={`c-avatar ${className}`} style={hasArtifact ? {backgroundImage: `url(${buildImageSrc(artifact, '667')})`} : null}>
            {!hasArtifact && (
                <div className="c-avatar__svg-container">
                    <Icon type="avatar" />
                </div>
            )}

            {flagged && <FlaggedIcon />}

            {isOnline && <div className="c-avatar__online" />}
        </div>
    );
};

export default Avatar;
