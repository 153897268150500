import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import IError from "../../types/IError";
import User from "../../types/User";
import UsersMetadata from "../../types/UsersMetadata";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import { generateUser, generateUsersMetadata } from "../../utils/generators";

import { addError } from "./errors";
import { RootState } from '../reducers';
import { ROLES } from "../../utils/roles";
import { Platforms } from "../../utils/enums";
import Profile from "../../types/Profile";

type GetUsersProps = {
    isUpdate?: boolean
    role?: string
    schoolId?: number | string
    usersMetadata?: UsersMetadata
}

export const getUsers = createAsyncThunk(
    'users/getUsers',
    async ({isUpdate, role, schoolId, usersMetadata}: GetUsersProps, {dispatch, getState}) => {
        try {
            if(!usersMetadata) {
                usersMetadata = clone((getState() as RootState).users.usersMetadata);
            } else {
                usersMetadata = {...usersMetadata}
            }

            if(role) {
                usersMetadata.role = role;
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.users.get(schoolId, buildQueryString(usersMetadata)));
            let users = res.data.data.items;
            usersMetadata.total = res.data.data.meta.total;
            return { users, usersMetadata };
        } catch(err) {
            console.log('getUsers error', err);
            err.friendlyMessage = 'Error getting a list of users. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type ResendInvitationProps = {
    emailAddress: string
}

export const resendInvitation = createAsyncThunk(
    'users/resendInvitation',
    async ({emailAddress}: ResendInvitationProps, {dispatch, getState}) => {
        try {
            if(!emailAddress) {
                throw new Error('Email address is required to send invitation');

            }

            const res = await new Request((getState() as RootState).auth.token).post(PATHS.users.resendInvitation(), {emailAddress});
            return res;
        } catch(err) {
            console.log('getUsers error', err);
            err.friendlyMessage = 'Error sending invitation. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type DeleteUserProps = {
    userId: number
}

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async ({userId}: DeleteUserProps, {dispatch, getState}) => {
        try {
            if(!userId) {
                throw new Error('Missing userId');
            }

            await new Request((getState() as RootState).auth.token).post(PATHS.users.delete(), {userIds: [userId]});
        } catch(err) {
            console.log('deleteUsers error', err);
            err.friendlyMessage = 'Error deleting user. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type SaveUserProps = {
    profile?: Profile
    useSameDataForAppProfile?: boolean
}

export const saveUser = createAsyncThunk(
    'users/saveUser',
    async({profile, useSameDataForAppProfile}: SaveUserProps, {dispatch, getState}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId } } } = (getState() as RootState);
        let { user } = (getState() as RootState).users;

        let path = PATHS.users.create();
        let request = new Request(token);
        let reqFunc = request.post;

        if(user.userId) {
            path = PATHS.users.update(user.userId);
            reqFunc = request.put;
            if(profile) {
                user = {
                    ...user,
                    profile
                };
            }
        } else {
            user = {
                ...user,
                tenantId,
                platform: Platforms.Both,
                roles: [{
                    tenantId,
                    // TODO: Make this dynamic!!!!!!!
                    type: ROLES.SCHOOL_ADMIN,
                }],
            };

            if(useSameDataForAppProfile) {
                user.appArtifactId = user.artifactId;
                user.appFirstName = user.firstName;
                user.appLastName = user.lastName;
            }
        }

        try {
            const res = await reqFunc(path, user);
            return res;
        } catch(err) {
            console.log('saveUser error', err.response);
            if(err.response?.data?.error) {
                err.friendlyMessage = err.response.data.error;
            } else {
                err.friendlyMessage = 'Error saving the user. Please try again.';
            }
            dispatch(addError(err));
            throw err;
        }
    }
)


interface UsersState {
    deleteUserError: IError
    getUsersError: IError
    isDeletingUser: boolean
    isGettingUsers: boolean
    isResendingInvitation: boolean
    isSavingUser: boolean
    resendInvitationError: IError
    user: User
    users: Array<User>
    usersMetadata: UsersMetadata
    saveUserError: IError
    searchTerm: string
}

const initialState: UsersState = {
    deleteUserError: undefined,
    getUsersError: undefined,
    isDeletingUser: false,
    isGettingUsers: false,
    isResendingInvitation: false,
    isSavingUser: false,
    resendInvitationError: undefined,
    saveUserError: undefined,
    searchTerm: '',
    user: generateUser(),
    users: [],
    usersMetadata: generateUsersMetadata(),
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        clearUser: (state) => {
            state.user = generateUser();
        },
        clearUsersMetadata: (state) => {
            state.usersMetadata = generateUsersMetadata();
            state.searchTerm = '';
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(getUsers.pending, (state, action) => {
            state.getUsersError = undefined;
            state.isGettingUsers = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.usersMetadata) {
                state.usersMetadata = action.meta.arg.usersMetadata;
            }
        });
        addCase(getUsers.fulfilled, (state, action) => {
            state.users = action.payload.users;
            state.usersMetadata = action.payload.usersMetadata;
            state.isGettingUsers = false;
        });
        addCase(getUsers.rejected, (state, action) => {
            state.getUsersError = action.error;
            state.isGettingUsers = false;
        });

        addCase(resendInvitation.pending, (state) => {
            state.resendInvitationError = undefined;
            state.isResendingInvitation = true;
        });
        addCase(resendInvitation.fulfilled, (state, action) => {
            state.isResendingInvitation = false;
        });
        addCase(resendInvitation.rejected, (state, action) => {
            state.resendInvitationError = action.error as IError;
            state.isResendingInvitation = false;
        });

        addCase(saveUser.pending, (state) => {
            state.saveUserError = undefined;
            state.isSavingUser = true;
        });
        addCase(saveUser.fulfilled, (state, action) => {
            state.isSavingUser = false;
            state.user = generateUser();
        });
        addCase(saveUser.rejected, (state, action) => {
            state.saveUserError = action.error as IError;
            state.isSavingUser = false;
        });

        addCase(deleteUser.pending, (state) => {
            state.deleteUserError = undefined;
            state.isDeletingUser = true;
        });
        addCase(deleteUser.fulfilled, (state, action) => {
            state.isDeletingUser = false;
            state.user = generateUser();
        });
        addCase(deleteUser.rejected, (state, action) => {
            state.deleteUserError = action.error as IError;
            state.isDeletingUser = false;
        });
    }
});

export const { clearUser, clearUsersMetadata, setSearchTerm, setUser } = usersSlice.actions;

export default usersSlice.reducer;
