import './VideoIcon.scss';

import React from 'react';
import Icon from "../index";

type Props = {
    className?: string
}

const VideoIcon: React.FC<Props> = ({
    className,
}) => {
    return (
        <Icon type="film" className={`c-video-icon ${className || ''}`} />
    );
};

export default VideoIcon;
