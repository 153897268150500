import './ResourceRow.scss';

import React from 'react';
import Icon from "../../../../../components/Icon";
import Button, { ButtonSizes } from "../../../../../components/Button";
import Resource from "../../../../../types/Resource";

type Props = {
    deleteResource?: (resource: Resource) => void
    editResource?: (resource: Resource) => void
    hideDragIcon?: boolean
    onRowClick?: (resource: Resource) => void
    resource: Resource
}

const ResourceRow: React.FC<Props> = ({
    deleteResource,
    editResource,
    hideDragIcon,
    onRowClick,
    resource,
}) => {
    return (
        <div className="c-resource-row" onClick={onRowClick ? () => onRowClick(resource) : null}>
            {onRowClick == null && hideDragIcon !== true && (
                <div className="c-resource-row__drag-icon">
                    {resource.tenantResourceId && <Icon type="drag" />}
                </div>
            )}

            <div className="c-resource-row__icon">
                <Icon type={`resources-${resource.type}`} />
            </div>

            <div className="c-resource-row__content">
                <div className="c-resource-row__title">
                    {resource.title}
                </div>

                {resource.linkUrl && (
                    <div className="c-resource-row__link">
                        {resource.linkUrl}
                    </div>
                )}
            </div>

            {onRowClick == null && (
                <div className="c-resource-row__actions">
                    {resource.tenantResourceId ? (
                        <>
                            {editResource && (
                                <Button
                                    onClick={() => editResource(resource)}
                                    size={ButtonSizes.Small}
                                >
                                    Edit
                                </Button>
                            )}

                            {deleteResource && (
                                <Button
                                    onClick={() => deleteResource(resource)}
                                    size={ButtonSizes.Small}
                                >
                                    Delete
                                </Button>
                            )}
                        </>
                    ) : (
                        <Button
                            onClick={() => editResource(resource)}
                            size={ButtonSizes.Small}
                        >
                            Add
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ResourceRow;
