import './SelectDestination.scss';

import React from 'react';

import Validation from "../../../../../../types/Validation";

import { PUSH_NOTIFICATION_DESTINATION_TYPES } from "../../../../../../utils/constants";

import CustomOptionWithIcon from "../../../../../../components/Dropdown/CustomOptionWithIcon";
import CustomControlWithIcon from "../../../../../../components/Dropdown/CustomControlWithIcon";
import Dropdown from "../../../../../../components/Dropdown";

type Props = {
    className?: string
    destination?: string
    disabled?: boolean
    handleChange: Function
    validation: Validation
}

const OPTIONS = [
    {
        icon: 'notification-in-app',
        label: 'In-App',
        sublabel: 'Send only in the Abuzz app',
        value: PUSH_NOTIFICATION_DESTINATION_TYPES.INAPP
    }, {
        icon: 'notification-push',
        label: 'Push',
        sublabel: 'Send only to users\' devices',
        value: PUSH_NOTIFICATION_DESTINATION_TYPES.PUSH
    }, {
        icon: 'notification-both',
        label: 'In-App + Push (Both)',
        sublabel: 'Send to the Abuzz app and users’ devices',
        value: PUSH_NOTIFICATION_DESTINATION_TYPES.BOTH
    }
]

const SelectDestination: React.FC<Props> = ({
    className = '',
    destination,
    disabled,
    handleChange,
    validation
}) => {
    return (
        <div className={`c-select-destination ${className}`}>
            <Dropdown
                components={{
                    Control: CustomControlWithIcon,
                    Option: CustomOptionWithIcon
                }}
                disabled={disabled}
                label="Notification Type"
                name="destination"
                validation={validation}
                required
                options={OPTIONS}
                onChange={(selectedValue) => handleChange('destination', selectedValue.value)}
                value={destination}
            />
        </div>
    );
};

export default SelectDestination;
