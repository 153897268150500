import './CreateEditLandingPage.scss';

import React, { useEffect } from 'react';
import {useRouteMatch} from "react-router";

import { clearLandingPage, getLandingPage } from "../../../../../store/slices/landingPages";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import CreateEditLandingPageForm from "./CreateEditLandingPageForm";
import H5 from "../../../../../components/H5";
import Icon from "../../../../../components/Icon";
import PageHeader from "../../../../../components/PageHeader";
import PreviewLandingPage from "./components/PreviewLandingPage";

const CreateEditLandingPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const { isGettingLandingPage, landingPage } = useTypedSelector((state) => state.landingPages);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getLandingPage({landingPageId: params.landingPageId, schoolId: params.schoolId})).unwrap();
            } catch(err) {
                console.log('CreateEditLandingPage initialize err', err);
            }
        }

        if(!isGettingLandingPage && params.landingPageId) {
            initialize();
        }

        return () => {
            dispatch(clearLandingPage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="landing-page">
            <PageHeader>
                <H5>
                    <Icon type="landing-page" />

                    {landingPage.landingPageId ? 'Edit' : 'Create'} Landing Page
                </H5>
            </PageHeader>

            {isGettingLandingPage ? (
                <ActivityIndicator size="large" />
            ) : (
                <div className="landing-page__form-container">
                    <CreateEditLandingPageForm
                        landingPage={landingPage}
                    />

                    <PreviewLandingPage
                        landingPage={landingPage}
                    />
                </div>
            )}
        </div>
    );
};

export default CreateEditLandingPage;
