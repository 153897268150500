import './SchoolPushNotifications.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clone from 'clone';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import queryString from 'query-string';

import NewContentMenuOption from "../../../../types/NewContentMenuOption";
import PushNotification from "../../../../types/PushNotification";

import {
    PUSH_NOTIFICATION_DESTINATION_TYPES,
    PUSH_NOTIFICATION_DESTINATION_TYPES_DISPLAY
} from "../../../../utils/constants";

import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';
import {
    cancelNotification,
    clearPushNotification,
    clearPushNotificationsMetadata,
    getPushNotifications,
    setPushNotification
} from '../../../../store/slices/pushNotifications';
import { setSearchTerm } from '../../../../store/slices/pushNotifications';

import ActivityIndicator from '../../../../components/ActivityIndicator';
import Button, { ButtonSizes } from '../../../../components/Button';
import CreateEditNotification from "../../../Content/Notifications/CreateEditNotification";
import H5 from '../../../../components/H5';
import Icon from "../../../../components/Icon";
import NewContentMenu from "../../../../components/NewContentMenu";
import NewContentModal from "../../../../components/NewContentModal";
import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';
import TableFilterButtons from '../../../../components/Table/TableFilterButtons';
import TableFilterRow from "../../../../components/Table/TableFilterRow";
import TableMoreMenu from '../../../../components/Table/TableMoreMenu';
import TablePublishedAt from '../../../../components/Table/TablePublishedAt';
import TableSearch from "../../../../components/TableSearch";
import TableFilterRowDivider from "../../../../components/Table/TableFilterRow/TableFilterRowDivider";
import TableRowImage from "../../../../components/Table/TableRowImage";
import { setProfileTypeVisibilityInMetadata } from "../../../../utils/utils";

const SchoolPushNotifications: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch()
    const { search } = useLocation();

    const [contentAttached, setContentAttached] = useState(false);
    const [showNewContentMenu, setShowNewContentMenu] = useState(false);
    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const { isCancellingPushNotification, isGettingPushNotifications, pushNotification, pushNotifications, pushNotificationsMetadata, searchTerm } = useTypedSelector((state) => state.pushNotifications);

    useEffect(() => {
        const initialize = async () => {
            try {
                const qs = queryString.parse(search);
                if(qs.openmodal) {
                    setShowNewContentModal(true);
                    setContentAttached(true);
                    history.replace({search: ''});
                }

                let clonedPushNotificationsMetadata = clone(pushNotificationsMetadata);
                clonedPushNotificationsMetadata = setProfileTypeVisibilityInMetadata(clonedPushNotificationsMetadata, params.profileType);
                await dispatch(getPushNotifications({schoolId: params?.schoolId, pushNotificationsMetadata: clonedPushNotificationsMetadata})).unwrap();
            } catch(err) {
                console.log('SchoolPushNotifications initialize err', err);
            }
        }

        initialize();

        return () => {
            setShowNewContentMenu(false);
            dispatch(clearPushNotification());
            dispatch(clearPushNotificationsMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            return  [
                {
                    Header: 'Message',
                    accessor: 'message',
                    sortBy: 'message'
                }, {
                    Header: 'Type',
                    sortBy: 'destination',
                    Cell: ({row: { original }}) => (
                        <div className="school-alerts__type-column">
                            {PUSH_NOTIFICATION_DESTINATION_TYPES_DISPLAY[original.destination]}
                        </div>
                    )
                }, {
                    Header: 'Notes',
                    accessor: 'title',
                    sortBy: 'title'
                }, {
                    Header: 'Attached Content',
                    id: 'post',
                    Cell: ({row: { original }}) => {
                        if(!original.post && !original.forumTopic) {
                            return '(none)';
                        }

                        if(original.post) {
                            return (
                                <div className="school-alerts__attached-content">
                                    <TableRowImage artifact={original.post.artifacts && original.post.artifacts[0]}/>

                                    {original.post.title}
                                </div>
                            );
                        } else {
                            return (
                                <div className="school-alerts__attached-content">
                                    <TableRowImage
                                        artifact={original.forumTopic.artifact}
                                        placeholder={'/img/abuzz-group-placeholder.svg'}
                                    />

                                    {original.forumTopic.name}
                                </div>
                            )
                        }
                    },
                    hasImage: true
                }, {
                    Header: 'Status',
                    accessor: (d) => <TablePublishedAt pushNotification={d} />,
                    sortBy: 'date'
                }, {
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => {
                        //sent notifications cannot be deleted
                        if(original.sentAt) {
                            return null;
                        }

                        //only notifications scheduled for more than 30 mins in advance can be deleted due to async queue placement.
                        const timeDiff = new Date(original.sendAt).getTime() - new Date().getTime();
                        if (timeDiff < 1800 * 1000) {
                            return null;
                        }

                        return (
                            <TableMoreMenu
                                hideModerationOptions
                                isDeleting={isCancellingPushNotification}
                                onDeleteClick={() => handleDelete(original)}
                                onEditClick={() => {
                                    dispatch(setPushNotification(original));
                                    setShowNewContentModal(true);
                                }}
                            />
                        );
                    },
                    hasImage: true
                }
            ];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const data = useMemo(
        () => {
            return pushNotifications;
        },
        [pushNotifications]
    );

    const newContentMenuOptions: Array<NewContentMenuOption> = useMemo(
        () => {
            return [
                {
                    icon: 'notification-in-app',
                    onClick: () => handleSelectNotificationType(PUSH_NOTIFICATION_DESTINATION_TYPES.INAPP),
                    subtext: 'Send only in the app',
                    text: 'In-App',
                }, {
                    icon: 'notification-push',
                    onClick: () => handleSelectNotificationType(PUSH_NOTIFICATION_DESTINATION_TYPES.PUSH),
                    subtext: 'Send only to users\' devices',
                    text: 'Push',
                }, {
                    icon: 'notification-both',
                    onClick: () => handleSelectNotificationType(PUSH_NOTIFICATION_DESTINATION_TYPES.BOTH),
                    subtext: 'Send to the app and devices',
                    text: 'In-App + Push (Both)',
                }
            ]
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const handleDelete = async (pushNotification: PushNotification) => {
        try {
            await dispatch(cancelNotification({notificationId: pushNotification.targetedNotificationId})).unwrap();
            onMetadataChange({}, false);
        } catch(err) {
            console.log('SchoolPushNotifications handleDelete err', err);
        }
    };

    const handleSelectNotificationType = (type: string) => {
        setShowNewContentMenu(false);

        let clonedNotification = clone(pushNotification);
        clonedNotification.destination = type;
        dispatch(setPushNotification(clonedNotification));

        setShowNewContentModal(true);
    }

    const onMetadataChange = async (changes, isUpdate) => {
        try {
            let clonedMetadata = clone(pushNotificationsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getPushNotifications({isUpdate, pushNotificationsMetadata: clonedMetadata}));
        } catch(err) {
            console.log('SchoolPushNotifications onMetadataChange err', err);
        }
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="school-alerts">
            <PageHeader>
                <H5>
                    <Icon type="navigation-notifications" />Notifications
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableFilterButtons
                    buttons={[{
                        isActive: !pushNotificationsMetadata.pending_only && !pushNotificationsMetadata.sent_only,
                        onClick: () => {
                            if(!pushNotificationsMetadata.pending_only && !pushNotificationsMetadata.sent_only) {
                                return;
                            }

                            onMetadataChange({pending_only: false, sent_only: false, page_num: 0}, false);
                        },
                        text: "All"
                    }, {
                        isActive: pushNotificationsMetadata.pending_only,
                        onClick: () => {
                            if(pushNotificationsMetadata.pending_only) {
                                return;
                            }

                            onMetadataChange({pending_only: true, sent_only: false, page_num: 0}, false);
                        },
                        text: "Scheduled"
                    }, {
                        isActive: pushNotificationsMetadata.sent_only,
                        onClick: () => {
                            if(pushNotificationsMetadata.sent_only) {
                                return;
                            }

                            onMetadataChange({sent_only: true, pending_only: false, page_num: 0}, false);
                        },
                        text: "Sent"
                    }]}
                />

                <TableFilterRowDivider />

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search..."
                    searchTerm={searchTerm}
                    size="small"
                />

                <Button
                    onClick={() => setShowNewContentMenu(true)}
                    size={ButtonSizes.Small}
                >
                    New Notification
                </Button>

                <NewContentMenu
                    close={() => setShowNewContentMenu(false)}
                    options={newContentMenuOptions}
                    show={showNewContentMenu}
                />
            </TableFilterRow>


            {isGettingPushNotifications ? (
                <ActivityIndicator size="large" />
            ) : (
                <Table
                    columns={columns}
                    data={data}
                    getData={onMetadataChange}
                    pageNumber={pushNotificationsMetadata.page_num}
                    pageSize={pushNotificationsMetadata.page_size}
                    sortBy={pushNotificationsMetadata.sort}
                    sortOrder={pushNotificationsMetadata.order}
                    totalNumberOfItems={pushNotificationsMetadata.total}
                />
            )}

            <NewContentModal
                close={() => {
                    setShowNewContentModal(false);
                    setContentAttached(false);
                }}
                show={showNewContentModal}
            >
                <CreateEditNotification
                    close={() => {
                        setShowNewContentModal(false);
                        setContentAttached(false);
                    }}
                    startWithAttachedContent={contentAttached}
                />
            </NewContentModal>
        </div>
    );
};

export default SchoolPushNotifications;
