import './Alert.scss';

import React, { useState } from 'react';

type Props = {
    children: React.ReactNode
    className?: string,
    data?: object,
    theme?: 'error' | 'warning' | 'info' | 'success'
};

const Alert: React.FC<Props> = ({
    children,
    className = '',
    data = null,
    theme = 'info'
}) => {
    const [showDump, setShowDump] = useState(false);
    return (
        <div
            className={`c-alert c-alert--${theme} ${className}`}
            onClick={() => setShowDump(!showDump)}
        >
            {children}

            {(data && showDump) && (
                <div className='c-alert__dump'>
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default Alert;
