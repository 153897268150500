import './GridGivingOpportunity.scss';

import React from 'react';
import classNames from "classnames";

import NewsStory from "../../../types/NewsStory";

import buildImageSrc from "../../../utils/buildImageSrc";

import Avatar from "../../Avatar";
import Card from "../../Card";
import CardVideo from "../../CardVideo";
import H6 from "../../H6";
import GivingOpportunity from "../../../types/GivingOpportunity";
import Icon from "../../Icon";

type Props = {
    item: GivingOpportunity
    moreMenuComponent?: Function
    onClick?: Function
    onClickText?: string
}

const GridGivingOpportunity: React.FC<Props> = ({
    item,
    moreMenuComponent,
    onClick,
}) => {

    const containerClasses = classNames(
        'c-grid-giving-opportunity c-grid-list__item',
    );

    const imageClasses = classNames(
        'c-grid-giving-opportunity__image-container c-grid-list__item__image-container',
        {

        }
    );

    return (
        <Card
            className={containerClasses}
            onClick={() => {
                onClick(item)
            }}
        >
            <div className={imageClasses}>
                <div
                    className="c-grid-list__item__image"
                    style={item.artifact ?
                        { backgroundImage: `url(${buildImageSrc(item.artifact, '667')})` } : null
                }>
                    {item.featured && (
                        <div className="c-grid-list__item__designation">
                            <Icon type="star" /> Featured
                        </div>
                    )}
                </div>
            </div>

            <div className="c-grid-giving-opportunity__content c-grid-list__item__content">
                <H6 className="c-grid-giving-opportunity__title c-grid-list__item__title">
                    {item.name}
                </H6>

                <p className="c-grid-list__item__description">{item.description}</p>


            </div>

            {moreMenuComponent && moreMenuComponent(item)}
        </Card>
    );
};

export default GridGivingOpportunity;
