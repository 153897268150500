import './GridStudent.scss';

import React from 'react';
import classNames from "classnames";

import Profile from "../../../types/Profile";

import Avatar from "../../Avatar";
import Card from "../../Card";
import H6 from "../../H6";
import Tooltip, { TooltipSide } from '../../Tooltip';

type Props = {
    item: Profile
    moreMenuComponent?: Function
    onClick?: Function
}

const GridStudent: React.FC<Props> = ({
    item,
    moreMenuComponent,
    onClick,
}) => {
    const classes = classNames(
        'c-grid-student c-grid-list__item',
        {
            'a-grid-student--flagged': item.flagged || item.flaggedContent,
            'a-grid-student--removed-by-mods': item.removedByMods,
            'a-grid-student--has-onclick': onClick != null,
        }
    );

    const renderDegrees = () => {
        let major = item.major ? item.major.filter((m) => m != null) : [];
        let minor = item.minor ? item.minor.filter((m) => m != null) : [];
        let degrees = [
            ...major,
            ...minor,
        ];

        const degreeStrings = degrees.map((d) => d.displayText || d.description);

        if(degreeStrings.length === 0) {
            return null;
        }

        return (
            <div className="c-grid-student__title c-grid-list__item__description">
                {degreeStrings.join(', ')}
            </div>
        );
    };

    return (
        <Card
            className={classes}
            onClick={() => onClick && onClick(item)}
        >
            {item.adminUser && (
                <div className="c-grid-student__admin_user">
                    <Tooltip icon="navigation-admins" children="Linked to Admin User Account" side={TooltipSide.Right}/>
                </div>
            )}
            <Avatar
                artifact={item.artifact}
                flagged={item.flagged || item.flaggedContent}
            />

            <div className="c-grid-student__content c-grid-list__item__content">
                <H6 className="c-grid-student__title c-grid-list__item__title">
                    {item.firstName} {item.lastName}
                </H6>

                {item.gradyear && item.gradyear[0] && (
                    <div className="c-grid-student__title c-grid-list__item__description">
                        Class of {item.gradyear[0].displayText || item.gradyear[0].description}
                    </div>
                )}

                {item.position && (
                    <div className="c-grid-student__title c-grid-list__item__description">
                        {item.position}
                    </div>
                )}

                {renderDegrees()}
            </div>

            {!item.removedByMods && moreMenuComponent && moreMenuComponent(item)}
        </Card>
    );
};

export default GridStudent;
