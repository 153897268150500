import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import Deal from "../../types/Deal";
import IError from "../../types/IError";
import Metadata from "../../types/Metadata";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import {generateDeal, generateDealsMetadata} from '../../utils/generators';

import { addError } from "./errors";
import { RootState } from '../reducers';

type DeleteDealProps = {
    deal?: Deal
}

export const deleteDeal = createAsyncThunk(
    'deals/deleteDeal',
    async ({deal}: DeleteDealProps = {}, {dispatch, getState}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!deal) {
                deal = (getState() as RootState).deals.deal;
            }

            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.deals.delete(tenantId, deal.postId));
            return {
                ...res,
                postId: deal.postId,
            };
        } catch(err) {
            console.log('delete deal err', err);
            err.friendlyMessage = 'Error deleting the deal. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

type GetDealsProps = {
    isUpdate?: boolean
    schoolId?: number
    dealsMetadata?: Metadata
}

export const getDeals = createAsyncThunk(
    'deals/getDeals',
    async ({isUpdate, schoolId, dealsMetadata}: GetDealsProps, {dispatch, getState}) => {
        try {
            if(!dealsMetadata) {
                dealsMetadata = clone((getState() as RootState).deals.dealsMetadata);
            } else {
                dealsMetadata = {...dealsMetadata}
            }
            dealsMetadata.type = 'D';

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.content.getContent(schoolId, buildQueryString(dealsMetadata)));
            let deals = res.data.data.items;
            dealsMetadata.total = res.data.data.meta.total;
            return {deals, dealsMetadata};
        } catch(err) {
            console.log('getDeals', err);
            err.friendlyMessage = 'Error getting the list of deals. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetDealProps = {
    postId?: number | string
    schoolId?: number | string
}

export const getDeal = createAsyncThunk(
    'deals/getDeal',
    async ({postId, schoolId}: GetDealProps = {}, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);
            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }
            const res = await new Request(token).get(PATHS.deals.getById(schoolId, postId));
            return res.data.data;
        } catch(err) {
            console.log('getDeal', err);
            err.friendlyMessage = 'Error getting the deal. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type ResetRedemptionsForDealProps = {
    postId?: number;
}

export const resetRedemptionsForDeal = createAsyncThunk(
    'deals/resetRedemptionsForDeal',
    async ({postId}: ResetRedemptionsForDealProps, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);
            const res = await new Request(token).post(PATHS.deals.resetRedemptions(activeSchool.tenantId, postId));
            return res.data.data;
        } catch(err) {
            console.log('resetRedemptionsForDeal', err);
            err.friendlyMessage = 'Error resetting the deal. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    },
);

type SaveDealProps = {
    deal?: Deal
}

export const saveDeal = createAsyncThunk(
    'deals/saveDeal',
    async({deal}: SaveDealProps, {dispatch, getState}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId } } } = (getState() as RootState);

        if(!deal) {
            deal = (getState() as RootState).deals.deal;
        }

        let path = PATHS.deals.create(tenantId);
        let request = new Request(token);
        let reqFunc = request.post;

        if(deal.postId) {
            path = PATHS.deals.update(tenantId, deal.postId);
            reqFunc = request.put;
        } else {
            deal = {
                ...deal,
                tenantId,
            };
        }

        try {
            const res = await reqFunc(path, deal);
            return res;
        } catch(err) {
            console.log('save deal error', err);
            err.friendlyMessage = 'Error saving the deal. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

interface DealsState {
    deal: Deal
    deals: Array<Deal>
    dealsMetadata: Metadata
    isDeletingDeal: boolean
    isGettingDeal: boolean
    isGettingDeals: boolean
    isResettingRedemptionsForDeal: boolean
    isSavingDeal: boolean
    deleteDealError?: IError
    getDealError?: IError
    getDealsError?: IError
    resetRedemptionsForDealError?: IError
    saveDealError?: IError
    searchTerm: string
}

const initialState: DealsState = {
    deal: generateDeal(),
    deals: [],
    dealsMetadata: generateDealsMetadata(),
    searchTerm: '',
    isDeletingDeal: false,
    isGettingDeal: false,
    isGettingDeals: false,
    isResettingRedemptionsForDeal: false,
    isSavingDeal: false,
    deleteDealError: undefined,
    getDealError: undefined,
    getDealsError: undefined,
    saveDealError: undefined
};

export const dealsSlice = createSlice({
    name: 'deals',
    initialState,
    reducers: {
        clearDeal: (state) => {
            state.deal = generateDeal();
        },
        clearDealsMetadata: (state) => {
            state.dealsMetadata = generateDealsMetadata();
            state.searchTerm = '';
        },
        setDeal: (state, action) => {
            state.deal = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteDeal.pending, (state) => {
            state.deleteDealError = undefined;
            state.isDeletingDeal = true;
        });
        addCase(deleteDeal.fulfilled, (state, action) => {
            state.isDeletingDeal = false;
            state.deal = generateDeal();
        });
        addCase(deleteDeal.rejected, (state, action) => {
            state.deleteDealError = action.error as IError;
            state.isDeletingDeal = false;
        });

        addCase(getDeal.pending, (state) => {
            state.getDealError = undefined;
            state.isGettingDeal = true;
        });
        addCase(getDeal.fulfilled, (state, action) => {
            state.isGettingDeal = false;
            state.deal = action.payload;
        });
        addCase(getDeal.rejected, (state, action) => {
            state.getDealError = action.error as IError;
            state.isGettingDeal = false;
        });

        addCase(getDeals.pending, (state, action) => {
            state.getDealsError = undefined;
            state.isGettingDeals = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.dealsMetadata) {
                state.dealsMetadata = action.meta.arg.dealsMetadata;
            }
        });
        addCase(getDeals.fulfilled, (state, action) => {
            state.deals = action.payload.deals;
            state.dealsMetadata = action.payload.dealsMetadata;
            state.isGettingDeals = false;
        });
        addCase(getDeals.rejected, (state, action) => {
            state.getDealsError = action.error as IError;
            state.isGettingDeals = false;
        });

        addCase(resetRedemptionsForDeal.pending, (state) => {
            state.resetRedemptionsForDealError = undefined;
            state.isResettingRedemptionsForDeal = true;
        });
        addCase(resetRedemptionsForDeal.fulfilled, (state, action) => {
            state.isResettingRedemptionsForDeal = false;
        });
        addCase(resetRedemptionsForDeal.rejected, (state, action) => {
            state.resetRedemptionsForDealError = action.error as IError;
            state.isResettingRedemptionsForDeal = false;
        });

        addCase(saveDeal.pending, (state) => {
            state.saveDealError = undefined;
            state.isSavingDeal = true;
        });
        addCase(saveDeal.fulfilled, (state, action) => {
            state.isSavingDeal = false;
            state.deal = generateDeal();
        });
        addCase(saveDeal.rejected, (state, action) => {
            state.saveDealError = action.error as IError;
            state.isSavingDeal = false;
        });
    }
});

export const { clearDeal, clearDealsMetadata, setDeal, setSearchTerm } = dealsSlice.actions;

export default dealsSlice.reducer;
