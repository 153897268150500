import React from 'react';
import { useTypedSelector } from "../../../../../store/reducers";
import CreateEditResourceForm from "./CreateEditResourceForm";

type Props = {
    close?: () => void
}

const CreateEditResource: React.FC<Props> = ({
    close,
}) => {
    const { resource } = useTypedSelector((state) => state.resources);

    return (
        <div className="create-edit-resource">
            <CreateEditResourceForm
                close={close}
                resource={resource}
            />
        </div>
    );
};

export default CreateEditResource;
