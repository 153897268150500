import './TableFilterMenu.scss';

import React, { useEffect, useState } from 'react';

import TableFilterMenuOption from "../../../types/TableFilterMenuOption";

import Button from "../../Button";
import Icon from "../../Icon";

type Props = {
    label?: string
    options: Array<TableFilterMenuOption>
}

const TableFilterMenu: React.FC<Props> = ({
    label,
    options,
}) => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<any>(null);

    useEffect(() => {
        const found = options.find((o) => o.isActive);
        if(found) {
            setSelectedValue(found.text);
        }
    }, [options]);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className={`c-table-filter-menu c-table-more-menu ${showMenu ? 'a-table-more-menu--show' : ''}`} onClick={(e) => e.stopPropagation()}>
            <div
                className="c-table-more-menu__background"
                onClick={toggleMenu}
            />

            <Button
                className="c-table-filter-menu__button"
                noStyles
                onClick={toggleMenu}
            >
                {label != null && `${label}: `}<strong>{selectedValue}</strong>
                <Icon type="caret-down" />
            </Button>

            <div className="c-table-filter-menu__options c-table-more-menu__options">
                {options.map((option) => (
                    <Button
                        className={`c-table-filter-menu__action-button c-table-more-menu__action-button ${option.className ? option.className : ''}`}
                        disabled={selectedValue === option.text}
                        key={option.text}
                        noStyles
                        onClick={() => {
                            setShowMenu(false);
                            option.onSelect();
                        }}
                    >
                        <Icon type={option.icon} /> {option.text}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default TableFilterMenu;
