import './ViewGroupMembersActive.scss';

import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from "react-router";
import clone from 'clone';

import { isObjectNullOrEmpty } from "../../../../../../../utils/utils";

import { getGroupMembersActive, removeMember, saveGroup } from "../../../../../../../store/slices/groups";
import { setNewRecipient } from "../../../../../../../store/slices/directMessages";
import { useAppDispatch } from "../../../../../../../store";
import { useTypedSelector } from "../../../../../../../store/reducers";

import ActivityIndicator from "../../../../../../../components/ActivityIndicator";
import GridList, { GridListTypes } from "../../../../../../../components/GridList";
import Modal from "../../../../../../../components/Modal";
import TableMoreMenu from "../../../../../../../components/Table/TableMoreMenu";
import Profile from "../../../../../../../types/Profile";
import ForumTopicProfile from "../../../../../../../types/ForumTopicProfile";

const ViewGroupMembersActive: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [memberToUse, setMemberToUse] = useState(null);
    const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState<boolean>(false);
    const [showConfirmMakeOwnerModal, setShowConfirmMakeOwnerModal] = useState<boolean>(false);

    const { group, groupMembersActive, groupMembersActiveMetadata, isGettingGroupMembersActive, isRemovingMember, isSavingGroup } = useTypedSelector((state) => state.groups);
    const { postAsProfile } = useTypedSelector((state) => state.schools.activeSchool);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getGroupMembersActive({schoolId: params?.schoolId})).unwrap();
            } catch(err) {
                console.log('ViewGroupMembersActive initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMakeGroupOwner = async () => {
        try {
            let clonedGroup = clone(group);
            clonedGroup.ownerProfileId = memberToUse.profileId;
            delete clonedGroup.ownerProfile;
            await dispatch(saveGroup({group: clonedGroup, shouldPersistGroup: true})).unwrap();
        } catch(err) {
            console.log('ViewGroupMembersActive handleMakeGroupOwner err', err);
        } finally {
            setShowConfirmMakeOwnerModal(false);
            setMemberToUse(null);
        }
    };

    const handleRemoveMember = async () => {
        try {
            await dispatch(removeMember({
                forumTopicId: params.forumTopicId,
                forumTopicProfileId: memberToUse.forumTopicProfile?.forumTopicProfileId,
                schoolId: params.schoolId
            })).unwrap();
        } catch(err) {
            console.log('ViewGroupMembersActive handleRemoveMember err', err);
        } finally {
            setShowConfirmRemoveModal(false);
            setMemberToUse(null);
        }
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(groupMembersActiveMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };
            return await dispatch(getGroupMembersActive({isUpdate, groupMembersActiveMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('ViewGroupMembersActive onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item) => {
        let customActions = [];

        const itemIsMe = postAsProfile.profileId === item.profileId;

        if(postAsProfile && !itemIsMe) {
            customActions.push({
                icon: 'dms',
                onClick: () => {
                    if(item.conversationId) {
                        history.push(`/school/${params.schoolId}/${params.profileType}/messages/conversation/${item.conversationId}`);
                    } else {
                        dispatch(setNewRecipient(item));
                        history.push(`/school/${params.schoolId}/${params.profileType}/messages/conversation/new`);
                    }
                },
                text: 'Message',
            });
        }

        if(!isObjectNullOrEmpty(item.forumTopicProfile) && !itemIsMe) {
            customActions.push({
                icon: 'x',
                onClick: () => {
                    setMemberToUse(item);
                    setShowConfirmRemoveModal(true);
                },
                text: 'Remove',
            });
        }

        if(!group.elementDetailId && group.ownerProfileId !== item.profileId) {
            customActions.push({
                icon: 'user',
                onClick: () => {
                    setMemberToUse(item);
                    setShowConfirmMakeOwnerModal(true);
                },
                text: 'Make Group Owner',
            });
        }

        return (
            <TableMoreMenu
                customOptions={customActions}
                hideModerationOptions
            />
        );
    };

    return (
        <div className="view-group-members-active">
            {isGettingGroupMembersActive || !initialized ? (
                <ActivityIndicator />
            ) : (
                <GridList
                    isLastPage={Math.ceil(groupMembersActiveMetadata.total / groupMembersActiveMetadata.page_size) === groupMembersActiveMetadata.page_num + 1}
                    items={groupMembersActive}
                    listType={GridListTypes.User}
                    moreMenuComponent={renderMoreMenu}
                    onNextPageClick={() => onMetadataChange({page_num: groupMembersActiveMetadata.page_num + 1})}
                    onPreviousPageClick={() => onMetadataChange({page_num: groupMembersActiveMetadata.page_num - 1})}
                    overlayButtonText="Edit"
                    pageNumber={groupMembersActiveMetadata.page_num}
                />
            )}

            <Modal
                confirmButtonOnClick={handleRemoveMember}
                confirmButtonText="Remove"
                declineButtonOnClick={() => {
                    setShowConfirmRemoveModal(false);
                    setMemberToUse(null);
                }}
                declineButtonText="Cancel"
                show={showConfirmRemoveModal}
                showActivityIndicator={isRemovingMember}
                title="Are you sure?"
            >
                Are you sure you want to remove this user? This action cannot be undone.
            </Modal>

            <Modal
                confirmButtonOnClick={handleMakeGroupOwner}
                confirmButtonText="Yes"
                declineButtonOnClick={() => {
                    setShowConfirmMakeOwnerModal(false);
                    setMemberToUse(null);
                }}
                declineButtonText="Cancel"
                show={showConfirmMakeOwnerModal}
                showActivityIndicator={isSavingGroup}
                title="Are you sure?"
            >
                Are you sure you want to make this user the group owner?
            </Modal>
        </div>
    );
};

export default ViewGroupMembersActive;
