import './ConversationsList.scss';
import React, {useEffect, useRef, useState} from 'react';
import { useHistory, useRouteMatch } from "react-router";
import InfiniteScroll from 'react-infinite-scroller';

import {
    clearConversations,
    clearNewRecipient,
    getConversation,
    getConversations,
    setConversation
} from "../../../../../../store/slices/directMessages";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import ConversationSummary from "./components/ConversationSummary";
import ActivityIndicator from "../../../../../../components/ActivityIndicator";

const ConversationsList: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const timerRef = useRef(null);
    const scrollParentRef = useRef(null);

    const { conversation, conversationsMetadata, conversations, isGettingConversations } = useTypedSelector((state) => state.directMessages);
    const [ isInfiniteScrolling, setIsInfiniteScrolling ] = useState<boolean>(false);

    const [ isInitialized, setIsInitialized ] = useState<boolean>(false)
    const { activeGlobalProfile } = useTypedSelector((state) => state.globalProfiles);
    useEffect(() => {
        getListOfConversations(false);

        return () => {
            clearInterval(timerRef.current);
            dispatch(clearConversations());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getListOfConversations(false);
    }, [activeGlobalProfile]);

    const getListOfConversations = async (hideSpinner) => {
        try {
            await dispatch(getConversations({hideSpinner: hideSpinner !== false, schoolId: params?.schoolId, profileType: params.profileType})).unwrap();
            setIsInitialized(true)
        } catch(err) {
            console.warn('ConversationsList initialize err', err);
        }
    }

    const handleInfiniteScroll = async () => {
        if(!isInitialized || isGettingConversations || isInfiniteScrolling || conversationsMetadata.atEnd) {
            return;
        }

        try {
            setIsInfiniteScrolling(true);
            await dispatch(getConversations({isUpdate: true, schoolId: params?.schoolId, profileType: params.profileType}));
        } catch(err) {
            console.warn('Conversation List handleInfiniteScroll err', err);
        } finally {
            setIsInfiniteScrolling(false);
        }
    };

    const handleConversationClick = async (clickedConversation) => {
        if(clickedConversation.conversationId === conversation.conversationId) {
            dispatch(getConversation({schoolId: params.schoolId, conversationId: clickedConversation.conversationId}));
        }
        dispatch(setConversation(clickedConversation));
        dispatch(clearNewRecipient());
        history.push(`/school/${params.schoolId}/${params.profileType}/messages/conversation/${clickedConversation.conversationId}`);
    };

    return (
        <div className="conversations-list" ref={scrollParentRef}>
            {isGettingConversations ? (
                <div className="conversations-list__activity-indicator-container">
                    <ActivityIndicator />
                </div>
            ) : (
                <>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleInfiniteScroll}
                        hasMore={!conversationsMetadata.atEnd}
                        useWindow={false}
                        getScrollParent={() => scrollParentRef.current}
                    >
                        {conversations.map((conversation) => (
                            <ConversationSummary
                                conversation={conversation}
                                key={conversation.conversationId}
                                onClick={() => handleConversationClick(conversation)}
                            />
                        ))}
                    </InfiniteScroll>
                </>

            )}
        </div>
    );
};

export default ConversationsList;
