import IFile from "../types/IFile";

const useImport = () => {

    const testImportCount = async (file: IFile, maxImports: number) => {

        const getCSVLineCount = async (file: File) => {
            return new Promise<number>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const text = reader.result as string;
                    const lines = text.split('\n');
                    const nonBlankLines = lines.filter(line => line.trim() !== '');
                    resolve(nonBlankLines.length);
                };
                reader.onerror = reject;
                reader.readAsText(file);
            });
        }

        const lineCount = await getCSVLineCount(file);
        return lineCount <= maxImports + 1; // +1 for header row
    }

    return { testImportCount };
}

export default useImport;
