import React from "react";
import Card from "../../../../../../components/Card";
import DetailStat from "../DetailStat";
import './DetailStatsGrid.scss'
type Props = {
    stats: any
}

const DetailStatsGrid:React.FC<Props> = ({stats}) => {
    return (
        <div className="c-stat-grid">

            {stats.map((stat)=>
                <Card key={stat.label}>
                    <DetailStat stat={stat} />
                </Card>
            )}
        </div>
    );
}

export default DetailStatsGrid;
