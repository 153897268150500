import './ActivityReportGenerator.scss';
import React, { useEffect } from 'react';

import { clearConfig } from '../../../store/slices/activityReportGenerator';
import { useTypedSelector } from '../../../store/reducers';
import { useAppDispatch } from '../../../store';

import ActivityReportGeneratorForm from './ActivityReportGeneratorForm';
import Card from '../../../components/Card';
import H6 from '../../../components/H6';
import Icon from '../../../components/Icon';
import PageHeader from '../../../components/PageHeader';
import Tooltip from '../../../components/Tooltip';
import H5 from "../../../components/H5";

const ActivityReportGenerator: React.FC = () => {
    const dispatch = useAppDispatch();

    const { config } = useTypedSelector(state => state.activityReportGenerator);

    useEffect(() => {
        return () => {
            dispatch(clearConfig());
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="activity-report-generator">
            <PageHeader>
                <H5 className="activity-report-generator__header-title">
                    <Icon type="document-plus" />

                    User Activity Report Generator
                </H5>

                <Tooltip icon="info">
                    <H6>
                        Usage Instructions:
                    </H6>

                    <p>
                        Select one or more schools in which you would like to generate a report for, along with
                        a time series and email addresses in which to send the report to.
                    </p>

                    <p>
                        Our server will process the request, and send an individual email including the CSV
                        files containing the activity counts for each school selected.
                    </p>
                </Tooltip>
            </PageHeader>

            <Card>
                <ActivityReportGeneratorForm
                    config={config}
                />
            </Card>
        </div>

    );
};

export default ActivityReportGenerator;
