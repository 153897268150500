import './GridNewsStory.scss';

import React from 'react';
import classNames from "classnames";

import NewsStory from "../../../types/NewsStory";

import buildImageSrc from "../../../utils/buildImageSrc";

import Avatar from "../../Avatar";
import Card from "../../Card";
import CardVideo from "../../CardVideo";
import H6 from "../../H6";

type Props = {
    disablePending?: boolean
    item: NewsStory
    moreMenuComponent?: Function
    onClick?: Function
    onClickText?: string
}

const GridNewsStory: React.FC<Props> = ({
    disablePending,
    item,
    moreMenuComponent,
    onClick,
}) => {
    const isNotActive = item.status !== 'A' && disablePending;

    const containerClasses = classNames(
        'c-grid-news-story c-grid-list__item',
        {
            'a-grid-list__item--inactive': isNotActive,
        }
    );

    const imageClasses = classNames(
        'c-grid-news-story__image-container c-grid-list__item__image-container',
        {

        }
    );

    return (
        <Card
            className={containerClasses}
            onClick={() => {
                if(isNotActive || !onClick) {
                    return null;
                }

                onClick(item)
            }}
        >
            <div className={imageClasses}>
                <div
                    className="c-grid-list__item__image"
                    style={item.artifacts && item.artifacts.length > 0 ?
                        { backgroundImage: `url(${buildImageSrc(item.artifacts[0], '667')})` } : null
                }>
                    {item.artifacts[0]?.videoMp4Url && (
                        <CardVideo videoUrl={item.artifacts[0].videoMp4Url} />
                    )}
                </div>
            </div>

            <div className="c-grid-news-story__content c-grid-list__item__content">
                <H6 className="c-grid-news-story__title c-grid-list__item__title">
                    {item.title}
                </H6>

                {item.profile && (
                    <div className="c-grid-news-story__profile c-grid-list__item__profile">
                        <Avatar artifact={item.profile.artifact} />

                        {item.profile.firstName} {item.profile.lastName}
                    </div>
                )}
            </div>

            {moreMenuComponent && moreMenuComponent(item)}
        </Card>
    );
};

export default GridNewsStory;
