import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useSelector } from "react-redux";

import activityAlertsReducer from './slices/activity-alerts';
import activityReportGeneratorReducer from "./slices/activityReportGenerator";
import admitDashboardReducer from './slices/admitDashboard';
import admitsReducer from './slices/admits';
import alumniReducer from './slices/alumni';
import authReducer from './slices/auth';
import classNotesReducer from './slices/classNotes';
import dealsReducer from './slices/deals';
import directMessagesReducer from './slices/directMessages';
import engineeringReducer from './slices/engineering';
import presentationTimeSeriesReducer from './slices/timeSeriesStat'
import presentationDetailDataReducer from './slices/detailStats'
import errorsReducer from './slices/errors';
import eventsReducer from './slices/events';
import faqReducer from './slices/faq';
import feedReducer from './slices/feed';
import givingReducer from './slices/giving';
import globalProfilesReducer from './slices/globalProfiles';
import groupsReducer from './slices/groups';
import groupChatReducer from './slices/groupChat';
import integrationsReducer from './slices/integrations';
import landingPagesReducer from './slices/landingPages';
import marketplaceReducer from './slices/marketplaceItems';
import moderationReducer from './slices/moderation';
import newsReducer from './slices/news';
import profilePromotionReducer from './slices/profilePromotion';
import pushNotificationsReducer from './slices/pushNotifications';
import recentActivityReducer from './slices/recent-activity';
import resourcesReducer from './slices/resources';
import schoolsReducer from './slices/schools';
import sponsorshipsReducer from './slices/sponsorships';
import studentsReducer from './slices/students';
import superAdminDashboardReducer from "./slices/superAdminDashboard";
import tenantDashboardReducer from "./slices/tenantDashboard";
import threadsReducer from './slices/threads';
import uploadReducer from './slices/upload';
import usersReducer from './slices/users';

const rootReducer = combineReducers({
    activityAlerts: activityAlertsReducer,
    activityReportGenerator: activityReportGeneratorReducer,
    admitDashboard: admitDashboardReducer,
    admits: admitsReducer,
    alumni: alumniReducer,
    auth: authReducer,
    classNotes: classNotesReducer,
    deals: dealsReducer,
    directMessages: directMessagesReducer,
    engineering: engineeringReducer,
    errors: errorsReducer,
    events: eventsReducer,
    faq: faqReducer,
    feed: feedReducer,
    giving: givingReducer,
    globalProfiles: globalProfilesReducer,
    groups: groupsReducer,
    groupChat: groupChatReducer,
    integrations: integrationsReducer,
    landingPages: landingPagesReducer,
    marketplace: marketplaceReducer,
    moderation: moderationReducer,
    news: newsReducer,
    presentationTimeSeriesData: presentationTimeSeriesReducer,
    presentationDetailData: presentationDetailDataReducer,
    profilePromotion: profilePromotionReducer,
    pushNotifications: pushNotificationsReducer,
    recentActivity: recentActivityReducer,
    resources: resourcesReducer,
    schools: schoolsReducer,
    sponsorships: sponsorshipsReducer,
    students: studentsReducer,
    superAdminDashboard: superAdminDashboardReducer,
    tenantDashboard: tenantDashboardReducer,
    threads: threadsReducer,
    upload: uploadReducer,
    users: usersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
