import React, { useState } from 'react';

import { setShowTopDmSchoolsDetailModal } from '../../../../../../store/slices/superAdminDashboard';
import { useAppDispatch } from '../../../../../../store';
import { useTypedSelector } from '../../../../../../store/reducers';

import ActivityIndicator from '../../../../../../components/ActivityIndicator';
import Button, { ButtonThemes } from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import { CardType, DashboardCardProps } from '../../../index';
import DashboardCardModal from '../../DashboardCardModal';
import H5 from '../../../../../../components/H5';
import FormColumn from '../../../../../../components/FormColumn';
import FormRow from '../../../../../../components/FormRow';

const TopDmSchoolsCard: React.FC<DashboardCardProps> = ({cardData, initialized, isGettingDashboard}) => {
    const [detailData, setDetailData] = useState(null);
    const dispatch = useAppDispatch();

    const {showTopDmSchoolsDetailModal} = useTypedSelector(state => state.superAdminDashboard);
    const {title, subtitle, subtitles, data} = cardData;

    console.log('Data: ', data);

    const handleClick = (value: any) => {
        dispatch(setShowTopDmSchoolsDetailModal(value));
    };

    return (
        <Card
            key={cardData.title}
            className="dashboard-card__card"
        >
            <div className="dashboard-card__row">
                <H5>
                    {title}
                </H5>
            </div>
            {isGettingDashboard && (
                <ActivityIndicator size={'large'}/>
            )}
            {initialized && !isGettingDashboard && (
                <>
                    <div className="dashboard-card__row">
                        <div className="dashboard-card__primary-count">
                            {data?.conversationCount}
                        </div>
                    </div>
                    <FormRow columnCount={4} className="dashboard-card__detail-row">
                        <FormColumn className="dashboard-card__detail-subtitle-col--primary">
                            {subtitle}
                        </FormColumn>

                        {subtitles.map(st => (
                            <FormColumn key={st} className="dashboard-card__detail-subtitle-col">
                                {st}
                            </FormColumn>
                        ))}
                    </FormRow>
                    <div>
                        <div className="dashboard-card__detail-row-muted">
                            Top 5 Messengers
                        </div>
                        {data && data.length > 0 && data.map((tenantData, i) =>
                            <FormRow
                                key={i}
                                columnCount={5}
                                className="dashboard-card__detail-row"
                            >
                                <FormColumn className="dashboard-card__detail-main-col">
                                    <div>
                                        <div className="dashboard-card__row">
                                            {tenantData.tenant?.name || 'Unknown'}
                                        </div>
                                    </div>
                                </FormColumn>

                                <FormColumn className="dashboard-card__detail-stat-col">
                                    {tenantData.conversationCount || 'Unknown'}
                                </FormColumn>

                                <FormColumn className="dashboard-card__detail-stat-col details">
                                    <Button
                                        theme={ButtonThemes.Link}
                                        onClick={() => {
                                            handleClick(!showTopDmSchoolsDetailModal);
                                            setDetailData(tenantData);
                                        }}>
                                        Details
                                    </Button>
                                </FormColumn>
                            </FormRow>,
                        )}
                        {detailData && (
                            <DashboardCardModal
                                type={CardType.TopDmSchools}
                                data={detailData}
                            />
                        )}
                    </div>
                </>
            )}
        </Card>
    );
};

export default TopDmSchoolsCard;
