import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Request from "../../utils/request";
import {RootState} from "../reducers";
import PATHS from "../../utils/paths";
import {addError} from './errors';
import IError from '../../types/IError';
import {IPresentationTimeSeriesStat} from '../../types/PresentationTimeSeriesStat';

type getPresentationTimeSeriesChartProps = {
    tenantId: number
    startAt?: string
    endAt?: string
}

export const getProfileActivityTimeSeriesStat = createAsyncThunk(
    'presentationCharts/getProfileActivityTimeSeriesStat',
    async (params: getPresentationTimeSeriesChartProps, {dispatch, getState}) => {

        try {
            let query = `tenant_id=${params.tenantId}`;
            if (params.startAt) {
                query = query + `&start_date=${params.startAt}`;
            }
            if (params.endAt) {
                query = query + `&end_date=${params.endAt}`;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.presentationData.getPresentationTimeSeriesData(query));
            const chart = res.data.data;
            return {chart};
        }
        catch (err: any) {
            console.log('getProfileActivityTimeSeriesStat', err);
            err.friendlyMessage = 'Error getting the time series data. Please try again.';
            dispatch(addError(err));
            throw err;
        }


    });

interface ProfileActivityTimeSeriesChartState {
    timeSeriesChart: Array<IPresentationTimeSeriesStat>
    getProfileActivityTimeSeriesChartError?: IError
    isGettingTimeSeriesChartData?: boolean
}

const initialState: ProfileActivityTimeSeriesChartState = {
    timeSeriesChart: [],
    isGettingTimeSeriesChartData: false,
    getProfileActivityTimeSeriesChartError: undefined
}


export const ProfileActivityTimeSeriesStatSlice = createSlice({
    name: 'getProfileActivityStat',
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(getProfileActivityTimeSeriesStat.pending, (state, action) => {
            state.getProfileActivityTimeSeriesChartError = undefined;
            state.isGettingTimeSeriesChartData = true;
        });
        addCase(getProfileActivityTimeSeriesStat.fulfilled, (state, action) => {
            state.timeSeriesChart = action.payload.chart;
            state.isGettingTimeSeriesChartData = false;
        });
        addCase(getProfileActivityTimeSeriesStat.rejected, (state, action) => {
            state.getProfileActivityTimeSeriesChartError = action.error;
            state.isGettingTimeSeriesChartData = false;
        });
    }
});

export default ProfileActivityTimeSeriesStatSlice.reducer;
