import './TableFilterButtons.scss';

import React from 'react';

import Button, { ButtonSizes } from '../../Button';

type TableFilterButtonProps = {
    disabled?: boolean
    isActive?: boolean
    onClick: Function
    text: string
}

type Props = {
    buttons: Array<TableFilterButtonProps>
}

const TableFilterButtons: React.FC<Props> = ({
    buttons
}) => {
    return (
        <div className="c-table-filter-buttons">
            {buttons.map((button) => (
                <Button
                    className={`c-table-filter-button ${button.isActive ? 'a-table-filter-button--active' : ''}`}
                    disabled={button.disabled}
                    key={button.text}
                    onClick={button.onClick}
                    size={ButtonSizes.Small}
                >
                    {button.text}
                </Button>
            ))}
        </div>
    );
};

export default TableFilterButtons;
