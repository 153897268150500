import React, { useEffect, useRef, useState } from 'react';
import clone from "clone";
import { validated } from "react-custom-validation";
import moment from 'moment';

import EventPost from "../../../../types/EventPost";
import Validation from "../../../../types/Validation";

import { isDateAfterOtherDate, isRequired, isRequiredIfTrue } from '../../../../utils/validations';
import { handleDateChange, handleTextChange } from '../../../../utils/handle-changes';
import { buildArtifactIdsArray } from '../../../../utils/images';
import { isArrayNullOrEmpty } from "../../../../utils/utils";
import { useRouteMatch } from "react-router";

import { clearEvent, deleteEvent, getEvents, saveEvent, setEvent } from "../../../../store/slices/events";
import { useTypedSelector } from '../../../../store/reducers';
import { useAppDispatch } from '../../../../store';

import Button, { ButtonThemes, ButtonTypes } from '../../../../components/Button';
import ButtonRow from '../../../../components/ButtonRow';
import Checkbox from "../../../../components/Checkbox";
import Datepicker from '../../../../components/Datepicker';
import FileUpload from '../../../../components/FileUpload';
import Form from '../../../../components/Form';
import FormColumn from '../../../../components/FormColumn';
import FormRow from '../../../../components/FormRow';
import FormValidationMessage from '../../../../components/FormValidationMessage';
import H4 from "../../../../components/H4";
import Label from "../../../../components/Label";
import LocationSearch from '../../../../components/LocationSearch';
import Modal from '../../../../components/Modal';
import PostingAsCard from "../../../../components/PostingAsCard";
import SuccessModal from "../../../../components/Modal/SuccessModal";
import Textbox from '../../../../components/Textbox';
import VisibilityCard from '../../../../components/VisibilityCard';
import ProfileTypesSelector from "../../../../components/ProfileTypesSelector";

type Props = {
    close?: Function
    isVirtual: boolean
    post: EventPost
    setIsVirtual: (checked: boolean) => void
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        artifactIds: Validation
        description: Validation
        endAt: Validation
        linkUrl: Validation
        startAt: Validation
        title: Validation
    }
}

const CreateEditEventForm: React.FC<Props> = ({
    close,
    isVirtual,
    post,
    setIsVirtual,
    $field,
    $fieldEvent,
    $submit,
    $validation,
}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const locationRef = useRef<any>(null);

    const [isUploadingMainArtifact, setIsUploadingMainArtifact] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

    const { profile: authUser } = useTypedSelector((state) => state.auth);
    const { activeSchool } = useTypedSelector(store => store.schools);
    const { isDeletingEvent, isSavingEvent } = useTypedSelector(store => store.events);

    useEffect(() => {
        if(!post.artifactIds || post.artifactIds.length === 0) {
            let artifactIds = buildArtifactIdsArray(post);
            handleChange('artifactIds', artifactIds);
        }

        if(!post.postId) {
            handleChange(`${params.profileType}Visibility`, true);
        }

        if(post.linkUrl) {
            setIsVirtual(true);
        }

        return () => {
            setTimeout(() => dispatch(clearEvent()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name: string, value: any) => {
        console.log(name, value);
        let clonedEvent = clone(post);

        if(name === 'status') {
            clonedEvent.status = value;

            if(value === 'A') {
                delete clonedEvent.publishAt;
            }
        } else {
            clonedEvent[name] = value;
        }

        dispatch(setEvent(clonedEvent));
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteEvent({})).unwrap();
            $fieldEvent('reset');
            dispatch(getEvents({}));
            close && close();
        } catch (err) {
            console.log('CreateEditEventForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleSave = async (isValid: boolean) => {
        if(isValid) {
            try {
                await dispatch(saveEvent()).unwrap();
                setShowSuccessModal(true);
                if(locationRef?.current?.refs?.input) {
                    locationRef.current.refs.input.value = '';
                }
                $fieldEvent('reset');
            } catch(err) {
                console.log('CreateEditDealForm handleSave err', err);
            }
        }
    };

    const onUploadStart = () => {
        setIsUploadingMainArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange('artifactIds', [artifactId]);
        }
        setIsUploadingMainArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingMainArtifact(false);
    };

    const onDelete = () => {
        handleChange('artifactIds', []);
    };

    return (
        <Form className="content-form">
            <H4>
                {post.postId ? 'Edit ' : 'Create an '}Event
            </H4>

            <FormRow>
                <PostingAsCard
                    profile={post.profile || activeSchool.postAsProfile}
                    user={!post.profile || post.profile === activeSchool.postAsProfile ? authUser : null}
                />
            </FormRow>

            <FormRow>
                <Label>
                    Event Image <span className="c-label__required-asterisk">*</span>
                </Label>

                <FileUpload
                    disabled={isUploadingMainArtifact}
                    onDelete={onDelete}
                    currentArtifact={!isArrayNullOrEmpty(post.artifacts) ? post.artifacts[0] : null}
                    name="artifactIds"
                    onStart={onUploadStart}
                    onSuccess={onUploadSuccess}
                    onFailure={onUploadFailure}
                />

                {$validation.artifactIds.isValid === false && $validation.artifactIds.show === true ? (
                    <FormValidationMessage>
                        {$validation.artifactIds.error.reason}
                    </FormValidationMessage>
                ) : null}
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingMainArtifact}
                    id="txtTitle"
                    label="Title"
                    name="title"
                    required
                    type="text"
                    validation={$validation.title}
                    value={post.title || ''}
                    {...$field('title', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingMainArtifact}
                    id="txtDescription"
                    label="Description"
                    name="description"
                    required
                    type="textarea"
                    validation={$validation.description}
                    value={post.description || ''}
                    {...$field('description', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow columnCount={2}>
                <FormColumn>
                    <Datepicker
                        dateFormat="yyyy-MM-dd h:mm a"
                        disabled={isUploadingMainArtifact}
                        id="dateStartAt"
                        label="Start time"
                        minDate={new Date()}
                        name="startAt"
                        placeholderText="mm-dd-yyyy  00:00 pm"
                        required
                        selected={post.startAt || null}
                        showMonthDropdown
                        showTimeSelect
                        showYearDropdown
                        timeIntervals={15}
                        validation={$validation.startAt}
                        {...$field('startAt', (newDate) => handleDateChange(handleChange, 'startAt', null, moment(newDate).format()))}
                    />
                </FormColumn>

                <FormColumn>
                    <Datepicker
                        dateFormat="yyyy-MM-dd h:mm a"
                        disabled={isUploadingMainArtifact}
                        id="dateEndAt"
                        label="End time"
                        minDate={post.startAt}
                        name="endAt"
                        placeholderText="mm-dd-yyyy  00:00 pm"
                        selected={post.endAt || null}
                        showMonthDropdown
                        showTimeSelect
                        showYearDropdown
                        timeIntervals={15}
                        validation={$validation.endAt}
                        {...$field('endAt', (newDate) => handleDateChange(handleChange, 'endAt', null, moment(newDate).format()))}
                    />
                </FormColumn>
            </FormRow>

            <FormRow>
                <Checkbox
                    checked={isVirtual}
                    className="c-checkbox--full-width"
                    id="chkIsVirtual"
                    label="I Would Like to Make this a Virtual Event"
                    name="isVirtual"
                    onChange={(e) => {
                        setIsVirtual(e.target.checked);

                        if(!e.target.checked) {
                            handleChange('linkUrl', null);
                        }
                    }}
                />
            </FormRow>

            {isVirtual && (
                <FormRow className="new-event__virtual">
                    <Textbox
                        disabled={isUploadingMainArtifact}
                        id="txtLinkUrl"
                        instructions={(<>Saving a link to a virtual event will enable virtual event functionality in the app. If it's a <strong>hybrid</strong> event, you can also add a location below.</>)}
                        label="Virtual Event Link"
                        name="linkUrl"
                        required
                        validation={$validation.linkUrl}
                        value={post.linkUrl || ''}
                        {...$field('linkUrl', event => handleTextChange(handleChange, event))}
                    />
                </FormRow>
            )}

            <FormRow>
                <LocationSearch
                    disabled={isUploadingMainArtifact}
                    label="Location"
                    name="location"
                    onPlaceSelected={(location) => handleChange('location', location)}
                    selectedLocation={post.location}
                    thisRef={locationRef}
                />
            </FormRow>

            <VisibilityCard
                handleChange={handleChange}
                post={post}
            />

            <FormRow>
                <ProfileTypesSelector item={post} onChange={handleChange} />
            </FormRow>

            <ButtonRow>
                <Button
                    disabled={isUploadingMainArtifact}
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                    showActivityIndicator={isSavingEvent}
                    type={ButtonTypes.Submit}
                >
                    {post.postId ? 'Save' : 'Create'} Event
                </Button>

                {post.postId && (
                    <Button
                        className="destructive"
                        disabled={isUploadingMainArtifact}
                        onClick={() => setShowDeleteConfirmationModal(true)}
                        theme={ButtonThemes.Link}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getEvents({}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
                title="Nice job!"
            >
                <p>
                    Your event has been saved.
                </p>
                <p>
                    It may take a minute for your event to show in feeds and lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingEvent}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this event?
            </Modal>
        </Form>
    );
};

function createEditEventFormValidationConfig(props: Props) {
    const { isVirtual } = props;
    const { artifactIds, description, endAt, linkUrl, startAt, title }: EventPost = props.post;

    return {
        fields: ['artifactIds', 'description', 'endAt', 'linkUrl', 'startAt', 'title'],
        validations: {
            artifactIds: [
                [isRequired, artifactIds]
            ],
            description: [
                [isRequired, description]
            ],
            endAt: [
                [isDateAfterOtherDate, endAt, startAt]
            ],
            linkUrl: [
                [isRequiredIfTrue, linkUrl, isVirtual]
            ],
            startAt: [
                [isRequired, startAt]
            ],
            title: [
                [isRequired, title]
            ]
        }
    }
}
export default validated(createEditEventFormValidationConfig)(CreateEditEventForm);
