import './TableFilterRow.scss';

import React from 'react';

type Props = {
    children: React.ReactNode
    className?: string
}

const TableFilterRow: React.FC<Props> = ({
    children,
    className = ''
}) => {
    return (
        <div className={`c-table-filter-row ${className}`}>
            {children}
        </div>
    );
};

export default TableFilterRow;
