import './CreateEditNotification.scss';

import React from 'react';

import { useTypedSelector } from '../../../../store/reducers';

import CreateEditNotificationForm from './CreateEditNotificationForm';

type Props = {
    close?: Function
    startWithAttachedContent?: boolean
}

const CreateEditNotification: React.FC<Props> = ({
    close,
    startWithAttachedContent
}) => {
    const { pushNotification } = useTypedSelector((store) => store.pushNotifications);

    return (
        <div className="create-edit-notification">
            <CreateEditNotificationForm
                close={close}
                pushNotification={pushNotification}
                startWithAttachedContent={startWithAttachedContent}
            />
        </div>
    );
};

export default CreateEditNotification;
