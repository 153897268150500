import './ListImportJobs.scss';

import React, { useEffect, useMemo, useState } from 'react';
import clone from 'clone';
import moment from 'moment';
import { useRouteMatch } from "react-router";

import TableColumn from "../../../../../types/TableColumn";

import { isArrayNullOrEmpty } from "../../../../../utils/utils";

import {
    getImportJobs,
    reSendPendingInvites,
} from "../../../../../store/slices/students";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Avatar from "../../../../../components/Avatar";
import Button, { ButtonSizes } from "../../../../../components/Button";
import ButtonRow from "../../../../../components/ButtonRow";
import H5 from "../../../../../components/H5";
import Icon from "../../../../../components/Icon";
import PageHeader from "../../../../../components/PageHeader";
import Table from "../../../../../components/Table";

import Modal from "../../../../../components/Modal";
import SuccessModal from "../../../../../components/Modal/SuccessModal";
import ProfileImport from "../../../../../types/ProfileImport";

const ListImportJobs: React.FC = () => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState(false);
    const { importJobs, importJobsMetadata, isGettingImportJobs, isResendPendingInvites } = useTypedSelector((state) => state.students);
    const [showReSendInviteModal, setReSendInviteModal] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [selectedImport, setSelectedImport] = useState<ProfileImport | null >(null);

    useEffect(() => {
        const initialize = async () => {
            try {
                const cloneMetadata = clone(importJobsMetadata);
                cloneMetadata.type = params.profileType;
                await dispatch(getImportJobs({schoolId: params?.schoolId, importJobsMetadata: cloneMetadata})).unwrap();
            } catch(err) {
                console.log('ListImportJobs initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();
        return () => {
            setSelectedImport(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Job Date',
                    id: 'createdAt',
                    accessor: (d) => moment(d.createdAt).format('MM/DD/yyyy hh:mma')
                }, {
                    Header: 'Imported By',
                    id: 'createdByUser',
                    Cell: ({row: { original }}) => (
                        <div className="users-table__user">
                            <Avatar
                                artifact={original.createdByUser.artifact}
                            />

                            <div className="import-jobs-table__created-by-user-data">
                                <div className="users-table__name">
                                    {original.createdByUser.firstName} {original.createdByUser.lastName}
                                </div>

                                <div className="users-table__name import-jobs-table__created-by-user-position">
                                    {original.createdByUser.position}
                                </div>
                            </div>
                        </div>
                    ),
                    hasImage: true,
                    sortBy: 'name',
                }, {
                    Header: 'Status',
                    id: 'stats',
                    accessor: (d) => d.status,
                }, {
                    Header: 'Students Imported',
                    accessor: 'profileCount',
                }, {
                    Header: 'Active Count',
                    accessor: 'activeCount',
                }, {
                    Header: 'Pending Count',
                    accessor: 'pendingCount',
                }, {
                    Header: '',
                    id: 'resendInvites',
                    Cell: ({row: { original }}) => {
                        if(original.pendingCount === 0) {
                            return null;
                        }

                        return (
                            <>
                                <Button
                                    onClick={() => handleSelectedProfileImport(original)}
                                    size={ButtonSizes.Small}
                                >
                                    Resend Invites
                                </Button>
                            </>
                        );
                    },
                },
            ];
            return columns;
        },
        []
    );

    const handleSelectedProfileImport = (profileImport: ProfileImport) => {
        setSelectedImport(profileImport);
        setReSendInviteModal(true);
    };

    const handleResendInvites = async () => {
        if(!selectedImport) {
            return;
        }
        try {
            await dispatch(reSendPendingInvites({ tenantId: params.schoolId, profileImportId: selectedImport?.profileImportId })).unwrap();
            setReSendInviteModal(false);
            setTimeout(() => setShowSuccessModal(true), 300);
        } catch(err) {
            console.log('ListImportJobs handleResendInvites err', err);
            return;
        }
    };

    const data = useMemo(
        () => {
            return importJobs;
        },
        [importJobs]
    );

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(importJobsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };
            return await dispatch(getImportJobs({isUpdate, importJobsMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('ListImportJobs onMetadataActiveSchool.tenantId err', err);
        }
    };

    return (
        <div className="list-import-jobs">
            <PageHeader>
                <H5>
                    <Icon type="document-x" />

                    Past Imports
                </H5>
            </PageHeader>

            {isGettingImportJobs ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                            No completed student imports to display.
                        </>
                    ) : (
                        <Table
                            columns={columns}
                            data={data}
                            getData={onMetadataChange}
                            pageNumber={importJobsMetadata.page_num}
                            pageSize={importJobsMetadata.page_size}
                            sortBy={importJobsMetadata.sort}
                            sortOrder={importJobsMetadata.order}
                            totalNumberOfItems={importJobsMetadata.total}
                        />
                    )}
                    <Modal
                        className="c-success-modal"
                        declineButtonOnClick={() => {
                            setReSendInviteModal(false);
                        }}
                        show={showReSendInviteModal}
                        title="Resend Invites"
                    >
                        <p>This action will send {selectedImport?.pendingCount} invitation{selectedImport?.pendingCount !== 1 ? 's' : ''} to all the users who have not accepted their invitation
                            yet.</p>

                        <ButtonRow>
                            <Button
                                onClick={handleResendInvites}
                                size={ButtonSizes.Small}
                                showActivityIndicator={isResendPendingInvites}
                            >
                                OK
                            </Button>

                            <Button
                                onClick={() => {setReSendInviteModal(false);}}
                                size={ButtonSizes.Small}
                                disabled={showSuccessModal}
                            >
                                Cancel
                            </Button>
                        </ButtonRow>
                    </Modal>

                    <SuccessModal
                        buttonOnClick={() => setShowSuccessModal(false)}
                        show={showSuccessModal}
                        title="Success!"
                    >
                        <p>
                            {selectedImport?.pendingCount} invitation{selectedImport?.pendingCount !== 1 ? 's' : ''} have been sent.
                        </p>
                    </SuccessModal>

                </>

            )}
        </div>
    );
};

export default ListImportJobs;
