import React from 'react';

import ServerError from "../../../types/ServerError";

import Alert from "../../../components/Alert";

type Props = {
    error: ServerError
};

const ErrorInline: React.FC<Props> = (
    {
       error
    }
) => {
    if (!error) {
        return null;
    }

    return (
        <>
            <Alert data={error} theme="error" >
                <strong>{error.friendlyMessage}</strong>
                
                {error.breadcrumb && (
                    <div>
                        If this continues, please contact us with this code: <code>{error.breadcrumb}</code>
                    </div>
                )}
            </Alert>
        </>
    );
};

export default ErrorInline;
