import './Resources.scss';

import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from "react-beautiful-dnd";
import { useRouteMatch } from "react-router";

import { isArrayNullOrEmpty } from "../../../../utils/utils";
import Resource from "../../../../types/Resource";
import { ResourceTypes } from "../../../../utils/enums";

import {
    clearResource,
    deleteResource,
    getResources,
    reorderResources,
    setResource,
    setResources
} from "../../../../store/slices/resources";
import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import Card from "../../../../components/Card";
import CreateEditResource from "./CreateEditResource";
import H5 from "../../../../components/H5";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import NewContentModal from "../../../../components/NewContentModal";
import PageHeader from "../../../../components/PageHeader";
import ResourceRow from "./ResourceRow";

const RESOURCE_TYPES = [
    {
        icon: `resources-${ResourceTypes.DisabilityServices}`,
        title: 'Disability Services',
        type: ResourceTypes.DisabilityServices,
    }, {
        icon: `resources-${ResourceTypes.Employment}`,
        title: 'Employment',
        type: ResourceTypes.Employment,
    }, {
        icon: `resources-${ResourceTypes.FinancialAid}`,
        title: 'Financial Aid',
        type: ResourceTypes.FinancialAid,
    }, {
        icon: `resources-${ResourceTypes.HealthForms}`,
        title: 'Health Forms',
        type: ResourceTypes.HealthForms,
    }, {
        icon: `resources-${ResourceTypes.Housing}`,
        title: 'Housing',
        type: ResourceTypes.Housing,
    }, {
        icon: `resources-${ResourceTypes.KeyDates}`,
        title: 'Key Dates',
        type: ResourceTypes.KeyDates,
    }, {
        icon: `resources-${ResourceTypes.MealPlans}`,
        title: 'Meal Plans',
        type: ResourceTypes.MealPlans,
    }, {
        icon: `resources-${ResourceTypes.SafetyInformation}`,
        title: 'Safety',
        type: ResourceTypes.SafetyInformation,
    }
];

type Props = {
    onRowClick?: (resource: Resource) => void
}

const Resources: React.FC<Props> = ({
    onRowClick,
}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
    const [showNewContentModal, setShowNewContentModal] = useState<boolean>(false);

    const { isDeletingResource, isGettingResources, resources } = useTypedSelector((state) => state.resources);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getResources({ profileType: params.profileType, schoolId: params.schoolId }));
            } catch(err) {
                console.log('Resources initialize err', err);
            } finally {
                setInitialized(true);
            }
        };

        initialize();

        return () => {
            dispatch(clearResource());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddResource = (resourceToAdd) => {
        dispatch(setResource({
            title: resourceToAdd.title,
            type: resourceToAdd.type,
        }));
        setShowNewContentModal(true);
    }

    const handleDelete = async () => {
        try {
            await dispatch(deleteResource({})).unwrap();
        } catch (err) {
            console.log('Resources handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleEditClick = (r: Resource) => {
        dispatch(setResource(r));
        setShowNewContentModal(true);
    };

    const handleSaveNewOrder = async (reorderedResources) => {
        try {
            const newOrder = reorderedResources.map((q) => q.tenantResourceId);
            await dispatch(reorderResources({profileType: params.profileType, resourceIds: newOrder}));
        } catch(err) {
            console.log('Resources handleSaveNewOrder err', err);
        }
    };

    const onDragEnd = (result: OnDragEndResponder) => {
        console.log('result', result);
        // a little function to help us with reordering the result
        const reorder = (list: Array<Resource>, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedResources: Array<Resource> = reorder(
            resources,
            result.source.index,
            result.destination.index,
        );

        dispatch(setResources(reorderedResources));
        handleSaveNewOrder(reorderedResources);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="resources">
                <PageHeader>
                    <H5>
                        <Icon type="resources" />

                        Resources
                    </H5>
                </PageHeader>

                {isGettingResources || !initialized ? (
                    <ActivityIndicator size="large" />
                ) : (
                    <div className="resources__container">
                        <Card className="resources__created" title="Active Resources">
                            {isArrayNullOrEmpty(resources) ? (
                                <div className="resources__created-empty">
                                    You haven't created any resources yet. Click a button below to get started.
                                </div>
                            ) : (
                                <Droppable droppableId="resources-list">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {resources.map((r, i) => (
                                                <Draggable
                                                    draggableId={r.tenantResourceId.toString()}
                                                    index={i}
                                                    isDragDisabled={onRowClick != null}
                                                    key={r.tenantResourceId}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className="resources__created-row"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <ResourceRow
                                                                deleteResource={() => {
                                                                    dispatch(setResource(r));
                                                                    setShowDeleteConfirmationModal(true)
                                                                }}
                                                                editResource={() => handleEditClick(r)}
                                                                onRowClick={onRowClick}
                                                                resource={r}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}
                        </Card>

                        {!onRowClick && (
                            <Card className="resources__uncreated" title="Available Resource Types">
                                {RESOURCE_TYPES.map((rt) => {
                                    if(resources.find((r) => r.type === rt.type)) {
                                        return null;
                                    }

                                    return (
                                        <ResourceRow
                                            editResource={handleAddResource}
                                            resource={{linkUrl: '', title: rt.title, type: rt.type }}
                                        />
                                    );
                                })}
                            </Card>
                        )}
                    </div>
                )}

                <NewContentModal
                    close={() => setShowNewContentModal(false)}
                    show={showNewContentModal}
                >
                    <CreateEditResource
                        close={() => setShowNewContentModal(false)}
                    />
                </NewContentModal>

                <Modal
                    title="Confirm Delete"
                    show={showDeleteConfirmationModal}
                    confirmButtonOnClick={handleDelete}
                    confirmButtonText="Yes, Delete"
                    showActivityIndicator={isDeletingResource}
                    declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                    declineButtonText="Cancel"
                >
                    Are you sure you want to delete this resource?
                </Modal>
            </div>
        </DragDropContext>
    );
};

export default Resources;
