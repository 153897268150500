import './TableMoreMenu.scss';

import React, { useMemo, useState } from 'react';
import clone from 'clone';
import { useHistory, useRouteMatch } from "react-router";

import AdmitProfile from "../../../types/AdmitProfile";
import ChatMessage from "../../../types/ChatMessage";
import ClassNote from '../../../types/ClassNote';
import Comment from '../../../types/Comment';
import Deal from "../../../types/Deal";
import EventPost from "../../../types/EventPost";
import GivingOpportunity from "../../../types/GivingOpportunity";
import Group from "../../../types/Group";
import MarketplaceItem from "../../../types/MarketplaceItem";
import NewsStory from "../../../types/NewsStory";
import Profile from "../../../types/Profile";
import Resource from "../../../types/Resource";
import TableMoreMenuOption from "../../../types/TableMoreMenuOption";
import Thread from "../../../types/Thread";

import { isArrayNullOrEmpty } from "../../../utils/utils";
import { GroupCreatorTypes, PostTypes, ProfileTypes } from '../../../utils/enums';
import { PUSH_NOTIFICATION_DESTINATION_TYPES } from "../../../utils/constants";

import { setPushNotification } from "../../../store/slices/pushNotifications";
import { useAppDispatch } from "../../../store";
import { useTypedSelector } from "../../../store/reducers";

import Button from '../../Button';
import Icon from '../../Icon';
import Modal from '../../Modal';
import ModerationOptionsModal from "../../ModerationOptionsModal";

type Props = {
    customOptions?: Array<TableMoreMenuOption>
    getAfterModerate?: () => Promise<any>
    hideModerationOptions?: boolean
    isDeleting?: boolean
    item?: AdmitProfile | ClassNote | Comment | Deal | EventPost | Group | ChatMessage | MarketplaceItem | NewsStory | Profile | Resource | Thread | GivingOpportunity
    onDeleteClick?: Function
    onEditClick?: Function
    onlyModerationOptions?: boolean
    onViewClick?: Function
    showAttachToPushNotificationOption?: boolean
    type?: 'giving' | 'group' | 'profile' | 'school' | 'user'
}

const TableMoreMenu: React.FC<Props> = ({
    customOptions,
    getAfterModerate,
    hideModerationOptions,
    isDeleting,
    item,
    onDeleteClick,
    onEditClick,
    onlyModerationOptions,
    onViewClick,
    showAttachToPushNotificationOption,
    type,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showModerationModal, setShowModerationModal] = useState<boolean>(false);

    const { pushNotification } = useTypedSelector((state) => state.pushNotifications);
    const { postAsProfile } = useTypedSelector((state) => state.schools.activeSchool);

    const isMyPost = useMemo(
        () => {
            //@ts-ignore
            if(!postAsProfile || !item?.profile) {
                return false;
            }

            //@ts-ignore
            return postAsProfile.profileId === item?.profile?.profileId;
        }, [item, postAsProfile]
    );

    if(isArrayNullOrEmpty(customOptions) && !onDeleteClick && !onEditClick && !onViewClick && !showAttachToPushNotificationOption && !onlyModerationOptions) {
        return null;
    }

    const showEditAndDeleteButtons = () => {
        let renderButtons = true;

        if (item) {
            if (type === 'group' || type === 'giving' || type === 'user') {
                // Check for system group is handled in the onDeleteClick prop in the parent component
                return true;
            } else {
                //extract profile from item
                let profile = (item as ChatMessage).sender ||
                    (item as Deal || item as EventPost || item as MarketplaceItem || item as Thread).profile ||
                    (item as Group).ownerProfile ||
                    (item as Profile);

                if (profile && profile.profileType) {
                    //only content created by admins can be edited or deleted
                    if (
                        profile.profileType === ProfileTypes.Admit ||
                        profile.profileType === ProfileTypes.Alumni ||
                        profile.profileType === ProfileTypes.Student
                    ) {
                        // This is another safety check
                        //backend assigns ownerProfile to group regardless if it's an admin profile or student profile.
                        if (item as Group) {
                            renderButtons = (item as Group).creatorType !== GroupCreatorTypes.Student;
                        }
                        else {
                            renderButtons = false;
                        }
                    }
                }

                //if the post is not created by the user, don't show edit and delete buttons
                if (!isMyPost) {
                    renderButtons = false;
                }
            }
        }

        return renderButtons;
    }

    const handleAttachContent = () => {
        let clonedPushNotification = clone(pushNotification);
        clonedPushNotification.destination = PUSH_NOTIFICATION_DESTINATION_TYPES.BOTH;

        //@ts-ignore
        if(item.forumTopicId) {
            clonedPushNotification.forumTopic = item;
            delete clonedPushNotification.post;
            delete clonedPushNotification.newsStory;
            delete clonedPushNotification.resource;
        } else if((item as NewsStory).postId) {
            clonedPushNotification.newsStory = item;
            delete clonedPushNotification.post;
            delete clonedPushNotification.forumTopic;
            delete clonedPushNotification.resource;
        } else if((item as Resource).tenantResourceId) {
            clonedPushNotification.resource = item;
            delete clonedPushNotification.post;
            delete clonedPushNotification.forumTopic;
            delete clonedPushNotification.newsStory;
        } else {
            clonedPushNotification.post = item;
            delete clonedPushNotification.forumTopic;
            delete clonedPushNotification.newsStory;
            delete clonedPushNotification.resource;
        }

        dispatch(setPushNotification(clonedPushNotification));

        setShowMenu(false);

        history.push(`/school/${params.schoolId}/${params.profileType}/notifications?openmodal=1`)
    };

    const handleConfirmDelete = async () => {
        try {
            await onDeleteClick();
        } catch(err) {
            console.log('TableMoreMenu handleConfirmDelete err', err);
        } finally {
            setShowConfirmDeleteModal(false);
        }
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className={`c-table-more-menu ${showMenu ? 'a-table-more-menu--show' : ''}`} onClick={(e) => e.stopPropagation()}>
            <div
                className="c-table-more-menu__background"
                onClick={toggleMenu}
            />

            <Button
                className="c-table-more-menu__button"
                noStyles
                onClick={toggleMenu}
            >
                <Icon type="more" />
            </Button>

            <div className="c-table-more-menu__options">
                {/*@ts-ignore*/}
                {hideModerationOptions !== true && item.type !== PostTypes.Deal && (
                    <Button
                        className="c-table-more-menu__action-button c-table-more-menu__moderation-button"
                        disabled={(item as any).superApproval || (item as any).removedByMods}
                        noStyles
                        onClick={() => {
                            setShowMenu(false);
                            setShowModerationModal(true);
                        }}
                    >
                        <Icon type="flag" /> {(item as any).superApproval ? 'Mod Has Permanently Approved' : (type === 'profile' ? ((item as any).profileType === ProfileTypes.Alumni ? 'Moderate Alumni' : 'Moderate Student') : (type === 'group' ? 'Moderate Group' : 'Moderate Content'))}
                    </Button>
                )}

                {onViewClick && (
                    <Button
                        className="c-table-more-menu__action-button c-table-more-menu__view-button"
                        noStyles
                        onClick={() => {
                            setShowMenu(false);
                            onViewClick();
                        }}
                    >
                        <Icon type="groups" /> View
                    </Button>
                )}

                {showEditAndDeleteButtons() && onEditClick && (
                    <Button
                        className="c-table-more-menu__action-button c-table-more-menu__edit-button"
                        noStyles
                        onClick={() => {
                            setShowMenu(false);
                            onEditClick();
                        }}
                    >
                        <Icon type="edit" /> Edit
                    </Button>
                )}

                {showAttachToPushNotificationOption && (
                    <Button
                        className="c-table-more-menu__action-button c-table-more-menu__attach-button"
                        noStyles
                        onClick={handleAttachContent}
                    >
                        <Icon type="notifications" /> Attach
                    </Button>
                )}

                {!isArrayNullOrEmpty(customOptions) && (
                    <>
                        {customOptions.map((option) => (
                            <Button
                                className={`c-table-more-menu__action-button ${option.className ? option.className : ''}`}
                                disabled={option.disabled}
                                key={option.text}
                                noStyles
                                onClick={() => {
                                    setShowMenu(false);
                                    option.onClick();
                                }}
                            >
                                <Icon type={option.icon} /> {option.text}
                            </Button>
                        ))}
                    </>
                )}

                {showEditAndDeleteButtons() && onDeleteClick && (
                    <Button
                        className="c-table-more-menu__action-button c-table-more-menu__delete-button"
                        noStyles
                        onClick={() => {
                            setShowMenu(false);
                            setShowConfirmDeleteModal(true);
                        }}
                    >
                        <Icon type="trash" /> Delete
                    </Button>
                )}


            </div>

            <Modal
                confirmButtonOnClick={handleConfirmDelete}
                confirmButtonText="Delete"
                declineButtonOnClick={() => setShowConfirmDeleteModal(false)}
                declineButtonText="Cancel"
                show={showConfirmDeleteModal}
                showActivityIndicator={isDeleting}
                title="Are you sure?"
            >
                Deleting this item cannot be undone.
            </Modal>

            {hideModerationOptions !== true && (
                <ModerationOptionsModal
                    close={() => setShowModerationModal(false)}
                    getAfterModerate={getAfterModerate}
                    // @ts-ignore
                    item={item}
                    show={showModerationModal}
                    type={type}
                />
            )}
        </div>
    );
};

export default TableMoreMenu;
