import './ConversationThread.scss';

import React, {useRef} from 'react';
import moment from "moment";

import Message from "../../../../../../../../types/Message";

import { isArrayNullOrEmpty } from "../../../../../../../../utils/utils";

import ActivityIndicator from "../../../../../../../../components/ActivityIndicator";
import Button from "../../../../../../../../components/Button";
import ConversationMessage from "../ConversationMessage";

type Props = {
    atEnd: boolean
    handleInfiniteScroll: Function
    isInfiniteScrolling: boolean
    messages: Array<Message>
}

const ConversationThread: React.FC<Props> = ({
    atEnd,
    handleInfiniteScroll,
    isInfiniteScrolling,
    messages,
}) => {
    const scrollParentRef = useRef();

    const shouldShowBreak = (indexOne, indexTwo) => {
        if(indexOne === messages.length - 1) {
            return true;
        }

        if(indexTwo === messages.length) {
            return false;
        }

        const messageOneDate = moment(messages[indexOne].createdAt);
        const messageTwoDate = moment(messages[indexTwo].createdAt);
        return messageOneDate.diff(messageTwoDate, 'hours') > 5;
    };

    // console.log('messages', messages);

    return (
        <div
            className="c-conversation-thread"
            ref={scrollParentRef}
        >
            {!isArrayNullOrEmpty(messages) && messages.map((message, index) => {
                let isFirst = true;
                if (index !== messages.length - 1 && messages[index + 1].sender.profileId === messages[index].sender.profileId) {
                    isFirst = false;
                }

                // console.log('message', message);

                return (
                    <React.Fragment key={index}>
                        <ConversationMessage
                            className={isFirst ? 'a-message--first' : ''}
                            message={message}
                        />

                        {shouldShowBreak(index, index + 1) && (
                            <div className="c-conversation-thread__break">
                                {moment(message.createdAt).format('MMMM D, h:mma')}
                            </div>
                        )}
                    </React.Fragment>
                );
            })}

            {!isArrayNullOrEmpty(messages) && (
                <div className="c-conversation-thread__load-more-container">
                    {atEnd ? (
                        <span>No more messages</span>
                    ) : (
                        <Button
                            disabled={isInfiniteScrolling}
                            noStyles
                            onClick={handleInfiniteScroll}
                        >
                            Load More
                        </Button>
                    )}
                </div>
            )}

            {isInfiniteScrolling && <ActivityIndicator />}
        </div>
    );
};

export default ConversationThread;
