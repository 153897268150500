import './Logo.scss';

import React from 'react';

type Props = {
    className?: string
    color?: string
}

const Logo: React.FC<Props> = ({
    className = '',
    color
}) => {
    return (
        <div className={`c-logo ${className}`}>
            <svg viewBox="0 0 87 24">
                <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="#14171A" stroke="white"/>
                <path d="M19.0632 7.34218C17.197 5.89879 14.7039 5.60719 12.5461 6.55488C12.3565 6.64235 12.1816 6.72983 12.0066 6.83189C11.8317 6.72983 11.6421 6.64235 11.4672 6.55488C9.30937 5.59261 6.81622 5.89879 4.95001 7.34218L4.6001 7.60462L4.71674 8.01285C5.34367 10.0978 6.84538 11.7744 8.74075 12.6784C8.82823 14.7341 9.90714 16.6441 11.6567 17.7813L11.992 18L12.3274 17.7813C14.0915 16.6441 15.1559 14.7341 15.2433 12.6784C17.1387 11.7744 18.6404 10.0978 19.2674 8.01285L19.384 7.60462L19.0632 7.34218ZM12.0066 8.31903C12.4732 8.68352 12.8814 9.12092 13.2022 9.63121C12.8231 9.74785 12.4149 9.82075 12.0066 9.82075C11.5984 9.82075 11.1902 9.76243 10.8111 9.63121C11.1318 9.1355 11.5255 8.68352 12.0066 8.31903ZM13.9895 11.8473C13.3625 12.0515 12.6919 12.1681 12.0066 12.1681C11.3214 12.1681 10.6507 12.0515 10.0238 11.8473C10.0675 11.4828 10.1404 11.1183 10.2571 10.7684C10.8111 10.958 11.3943 11.06 11.992 11.06C12.5898 11.06 13.173 10.958 13.727 10.7684C13.8583 11.1183 13.9457 11.4828 13.9895 11.8473ZM10.0529 13.1595C10.6799 13.3345 11.336 13.422 12.0066 13.422C12.6773 13.422 13.3334 13.3345 13.9603 13.1595C13.902 13.5823 13.7854 13.976 13.625 14.3696C12.5607 14.5738 11.4526 14.5738 10.3883 14.3696C10.2279 13.9906 10.1113 13.5823 10.0529 13.1595ZM6.07266 8.07117C7.48689 7.15265 9.28021 6.99227 10.8548 7.64836C9.80508 8.61062 9.07609 9.89365 8.82823 11.3079C7.58895 10.5935 6.59753 9.45625 6.07266 8.07117ZM15.1704 11.3079C14.9226 9.87907 14.2082 8.59604 13.1584 7.64836C14.733 6.99227 16.5118 7.15265 17.9406 8.07117C17.4157 9.45625 16.4097 10.5935 15.1704 11.3079Z" fill="white"/>
                <path d="M36.3333 16C35.7778 16.8 34.8222 17.3333 33.7333 17.3333C32.1111 17.3333 30.8889 16.0889 30.8889 14.4C30.8889 12.8444 31.9778 11.4444 33.6667 11.4444C34.7778 11.4444 35.7333 11.9778 36.3333 12.7778V16ZM39.2222 9H36.4444V9.86667C35.6 9.17778 34.5111 8.77778 33.2889 8.77778C30.3556 8.77778 28 11.2222 28 14.4C28 15.8667 28.5333 17.2 29.3778 18.1778C30.3333 19.2889 31.8444 20 33.4444 20C34.5333 20 35.5556 19.6667 36.4444 18.9111V19.7778H39.2222V9Z" fill="white"/>
                <path d="M44.5608 12.7778C45.1608 11.9778 46.1163 11.4444 47.2274 11.4444C48.9163 11.4444 50.0052 12.8444 50.0052 14.4C50.0052 16.0889 48.783 17.3333 47.1608 17.3333C46.0719 17.3333 45.1163 16.8 44.5608 16V12.7778ZM44.4496 19.7778V18.9111C45.3385 19.6667 46.3608 20 47.4496 20C49.0496 20 50.5608 19.2889 51.5163 18.1778C52.3608 17.2 52.8941 15.8667 52.8941 14.4C52.8941 11.2222 50.5385 8.77778 47.6052 8.77778C46.4274 8.77778 45.4052 9.11111 44.5608 9.77778V4H41.6719V19.7778H44.4496Z" fill="white"/>
                <path d="M61.7882 9V16C61.3882 16.5111 60.4993 17.3333 59.2771 17.3333C58.7215 17.3333 58.0993 17.1556 57.6549 16.6667C57.3215 16.2889 57.1215 15.8 57.1215 14.8222V9H54.2326V14.7111C54.2326 16.3111 54.6326 17.4222 55.3215 18.2667C56.166 19.3111 57.4993 20 58.9882 20C60.0326 20 61.0549 19.6667 61.8993 18.9111V19.7778H64.6771V9H61.7882Z" fill="white"/>
                <path d="M75.6849 9H67.0182V11.4444H71.7293L66.5738 18V19.7778H76.1293V17.3333H70.4627L75.6849 10.7778V9Z" fill="white"/>
                <path d="M86.3628 9H77.6962V11.4444H82.4073L77.2517 18V19.7778H86.8073V17.3333H81.1406L86.3628 10.7778V9Z" fill="white"/>
            </svg>

        </div>
    );
};

Logo.defaultProps = {
    className: '',
    color: '#ffffff'
};

export default Logo;
