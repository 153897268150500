import React, { useEffect, useState } from 'react';
import { useRouteMatch } from "react-router";
import { useDispatch } from "react-redux";

import { getThread } from "../../../../store/slices/threads";
import { useTypedSelector } from "../../../../store/reducers";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import FeedThread from "../../../../components/Feed/FeedThread";


const ViewThread = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const { thread, isGettingThread } = useTypedSelector(store => store.threads);
    console.log('match', match);

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const initialize = () => {
            if (match.params.postId && !isGettingThread) {
                dispatch(getThread({postId: match.params.postId, schoolId: match.params.schoolId}));
            }
            setIsInitialized(true);
        }
        if (!isInitialized) {
            initialize()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.postId]);

    return (
        <div>
            {isGettingThread ? (
                <ActivityIndicator size={'large'} />
            ) : (
                <FeedThread post={thread} />
            )}
        </div>
    )
}
export default ViewThread;
