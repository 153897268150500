import React, { useEffect, useState } from 'react';
import clone from 'clone';
import { validated } from "react-custom-validation";
import { useHistory, useRouteMatch } from "react-router";

import Credentials from "../../../../../types/Credentials";
import User from "../../../../../types/User";
import Validation from "../../../../../types/Validation";

import { handleTextChange } from "../../../../../utils/handle-changes";
import { isEmail, isEqual, isRequired, isRequiredIfTrue } from "../../../../../utils/validations";
import { determinePathAfterLogin } from "../../../../../utils/routing";
import { Sizes } from "../../../../../utils/enums";

import { acceptInvitation, setCredentials } from "../../../../../store/slices/auth";
import { clearUser, getUsers, saveUser, setUser } from "../../../../../store/slices/users";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import A from "../../../../../components/A";
import Button, { ButtonThemes, ButtonTypes } from "../../../../../components/Button";
import ButtonRow from "../../../../../components/ButtonRow";
import Checkbox from "../../../../../components/Checkbox";
import Dropdown from "../../../../../components/Dropdown";
import FileUpload from "../../../../../components/FileUpload";
import Form from "../../../../../components/Form";
import FormRow from "../../../../../components/FormRow";
import FormValidationMessage from "../../../../../components/FormValidationMessage";
import H5 from "../../../../../components/H5";
import Icon from "../../../../../components/Icon";
import Label from "../../../../../components/Label";
import SuccessModal from "../../../../../components/Modal/SuccessModal";
import Textbox from "../../../../../components/Textbox";
import { isObjectNullOrEmpty } from "../../../../../utils/utils";

type Props = {
    inAcceptInvitation?: boolean
    close?: Function
    credentials: Credentials
    setUseSameDataForAppProfile?: Function
    token?: string
    useSameDataForAppProfile?: boolean
    user: User
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        appArtifactId: Validation
        appFirstName: Validation
        appLastName: Validation
        artifactId: Validation
        emailAddress: Validation
        firstName: Validation
        lastName: Validation
        password: Validation
        password2: Validation
        position: Validation
        profileArtifactId: Validation
        profileFirstName: Validation
        profileLastName: Validation
    }
}

const CreateEditUserForm: React.FC<Props> = ({
    inAcceptInvitation,
    close,
    credentials,
    setUseSameDataForAppProfile,
    token,
    useSameDataForAppProfile,
    user,
    $field,
    $fieldEvent,
    $submit,
    $validation,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const match = useRouteMatch();

    const [isUploadingArtifact, setIsUploadingArtifact] = useState(false);
    const [isUploadingAppArtifact, setIsUploadingAppArtifact] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showInvitationSuccessModal, setShowInvitationSuccessModal] = useState(false);

    const [currentArtifact, setCurrentArtifact] = useState(null);
    const [currentAppArtifact, setCurrentAppArtifact] = useState(null);


    const { isSavingUser } = useTypedSelector((state) => state.users);
    const { isAcceptingInvitation, roles } = useTypedSelector((state) => state.auth);

    useEffect(() => {
        if (user.artifact) {
            setCurrentArtifact(user.artifact);
            if(!user.artifactId ) {
                handleChange('artifactId', user.artifact.artifactId);
            }
        }

        if(user.tenantProfile) {
            handleChange('profile', user.tenantProfile);

            if(user.tenantProfile.artifact) {
                setCurrentAppArtifact(user.tenantProfile.artifact);
                if(!user.appArtifactId && user.appArtifact) {
                    handleChange('appArtifactId', user.appArtifact.artifactId);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.userId]);

    const handleAcceptInvitation = async (isValid) => {
        if(!isValid) {
            return;
        }

        try {
            await dispatch(acceptInvitation({token})).unwrap();
            setShowInvitationSuccessModal(true);
        } catch(err) {
            console.log('CreateEditUserForm handleAcceptInvitation err', err);
        }
    }

    const handleChange = (name: string, value: any) => {
        let clonedUser = clone(user);
        clonedUser[name] = value;

        if(name === 'artifactId') {
            delete clonedUser.artifact;
        } else if(name === 'appArtifactId') {
            delete clonedUser.appArtifact;
        } else if(name === 'emailAddress' && user.userId != null) {
            clonedUser.profile = {
                ...clonedUser.profile,
                emailAddress: value
            };
        }

        dispatch(setUser(clonedUser));
    };

    const handleProfileChange = (name: string, value: any) => {
        let clonedProfile = clone(user.profile);
        clonedProfile[name] = value;

        if(name === 'artifactId') {
            delete clonedProfile.artifact;
        }

        handleChange('profile', clonedProfile);
    };

    const handleDataCheckboxChange = () => {
        if(!useSameDataForAppProfile) {
            let clonedUser = clone(user);
            delete clonedUser.appArtifactId;
            delete clonedUser.appFirstName;
            delete clonedUser.appLastName;
            dispatch(setUser(clonedUser));
        }
        setUseSameDataForAppProfile(!useSameDataForAppProfile)
    };

    const handleClose = () => {
        close && close();
        dispatch(clearUser());
        $fieldEvent('reset');
    };

    const handlePasswordChange = (name, value) => {
        const clonedCredentials = clone(credentials);
        clonedCredentials[name] = value;
        dispatch(setCredentials(clonedCredentials));
    };

    const handleSave = async (isValid) => {
        if(!isValid) {
            return;
        }

        try {
            await dispatch(saveUser({useSameDataForAppProfile})).unwrap();
            setShowSuccessModal(true);
            $fieldEvent('reset');
        } catch(err) {
            console.log('CreateEditUserForm handleSave err', err);
        }
    };

    const onUploadStart = () => {
        setIsUploadingArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange('artifactId', artifactId);
        }
        setIsUploadingArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingArtifact(false);
    };

    const onAppAvatarUploadStart = () => {
        setIsUploadingAppArtifact(true);
    };

    const onAppAvatarUploadSuccess = (file, artifactId, object: string) => {
        if (artifactId) {
            if(object === 'profile') {
                handleProfileChange('artifactId', artifactId);
            } else {
                handleChange('appArtifactId', artifactId);
            }
        }
        setIsUploadingAppArtifact(false);
    };

    const onAppAvatarUploadFailure = () => {
        setIsUploadingAppArtifact(false);
    };

    const onDelete = (field: string, object?: string) => {
        if(object === 'profile') {
            handleProfileChange('artifactId', null);
        } else if(field === 'artifactId') {
            handleChange('artifactId', null);
        } else {
            handleChange('appArtifactId', null);
        }
    };

    console.log($validation);

    return (
        <Form className="create-edit-user__form">
            {inAcceptInvitation ? (
                <header className="create-edit-user__form-header a-create-edit-user__form-header--invitation">
                    <H5>
                        Welcome to Abuzz!
                    </H5>

                    Fill out the information below to complete signup.
                </header>
            ) : (
                <header className="create-edit-user__form-header">
                    <H5>
                        {user.userId ? 'Edit' : 'Create'} User
                    </H5>

                    <Button
                        className="create-edit-user__close-button"
                        onClick={handleClose}
                        theme={ButtonThemes.Secondary}
                    >
                        <Icon type="x" />
                    </Button>
                </header>
            )}

            <div className="create-edit-user__form-inner">
                <FormRow>
                    {inAcceptInvitation && (
                        <Label>
                            Admin Avatar
                        </Label>
                    )}

                    <FileUpload
                        currentArtifact={currentArtifact}
                        disabled={isUploadingArtifact || isUploadingAppArtifact}
                        name="artifactId"
                        onDelete={() => onDelete('artifactId')}
                        onFailure={onUploadFailure}
                        onStart={onUploadStart}
                        onSuccess={onUploadSuccess}
                        size={Sizes.Small}
                        theme="user"
                    />

                    {$validation.artifactId.isValid === false && $validation.artifactId.show === true ? (
                        <FormValidationMessage>
                            {$validation.artifactId.error.reason}
                        </FormValidationMessage>
                    ) : null}
                </FormRow>

                <FormRow>
                    <Textbox
                        disabled={isUploadingArtifact || isUploadingAppArtifact}
                        id="txtFirstName"
                        label="First Name"
                        name="firstName"
                        required
                        type="text"
                        validation={$validation.firstName}
                        value={user.firstName || ''}
                        {...$field('firstName', event => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                <FormRow>
                    <Textbox
                        disabled={isUploadingArtifact || isUploadingAppArtifact}
                        id="txtLastName"
                        label="Last Name"
                        name="lastName"
                        required
                        type="text"
                        validation={$validation.lastName}
                        value={user.lastName || ''}
                        {...$field('lastName', event => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                <FormRow>
                    <Textbox
                        disabled={isUploadingArtifact || isUploadingAppArtifact}
                        id="txtPosition"
                        label="Position"
                        name="position"
                        required
                        type="text"
                        validation={$validation.position}
                        value={user.position || ''}
                        {...$field('position', event => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                <FormRow>
                    <Textbox
                        disabled={isUploadingArtifact || isUploadingAppArtifact}
                        id="txtEmailAddress"
                        label="Email Address"
                        name="emailAddress"
                        required
                        type="email"
                        validation={$validation.emailAddress}
                        value={user.emailAddress || ''}
                        {...$field('emailAddress', event => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                {!inAcceptInvitation && !user.userId ? (
                    <FormRow>
                        <Checkbox
                            checked={useSameDataForAppProfile}
                            disabled={isUploadingArtifact || isUploadingAppArtifact}
                            id="chkUseSameData"
                            label="Use Matching Data for App Profile"
                            name="useSameDataForAppProfile"
                            onChange={handleDataCheckboxChange}
                        />
                    </FormRow>
                ) : (
                    <H5>
                        App Profile Information
                    </H5>
                )}

                {(!useSameDataForAppProfile || inAcceptInvitation) && !user.userId && (
                    <>
                        <FormRow>
                            {inAcceptInvitation && (
                                <Label>
                                    App Profile Avatar
                                </Label>
                            )}

                            <FileUpload
                                acceptsVideo={true}
                                currentArtifact={currentAppArtifact || null}
                                disabled={isUploadingArtifact || isUploadingAppArtifact}
                                name="appArtifactId"
                                onDelete={() => onDelete('appArtifactId')}
                                onFailure={onAppAvatarUploadFailure}
                                onStart={onAppAvatarUploadStart}
                                onSuccess={onAppAvatarUploadSuccess}
                                theme="user"
                            />

                            {$validation.appArtifactId.isValid === false && $validation.appArtifactId.show === true ? (
                                <FormValidationMessage>
                                    {$validation.appArtifactId.error.reason}
                                </FormValidationMessage>
                            ) : null}
                        </FormRow>

                        <FormRow>
                            <Textbox
                                disabled={isUploadingArtifact || isUploadingAppArtifact}
                                id="txtAppFirstName"
                                label="App Profile First Name"
                                name="appFirstName"
                                required
                                type="text"
                                validation={$validation.appFirstName}
                                value={user.appFirstName || ''}
                                {...$field('appFirstName', event => handleTextChange(handleChange, event))}
                            />
                        </FormRow>

                        <FormRow>
                            <Textbox
                                disabled={isUploadingArtifact || isUploadingAppArtifact}
                                id="txtAppLastName"
                                label="App Profile Last Name"
                                name="appLastName"
                                required
                                type="text"
                                validation={$validation.appLastName}
                                value={user.appLastName || ''}
                                {...$field('appLastName', event => handleTextChange(handleChange, event))}
                            />
                        </FormRow>
                    </>
                )}

                {!inAcceptInvitation && user.userId && (
                    <>
                        <FormRow>
                            {inAcceptInvitation && (
                                <Label>
                                    App Profile Avatar
                                </Label>
                            )}

                            <FileUpload
                                acceptsVideo={true}
                                currentArtifact={currentAppArtifact || null}
                                disabled={isUploadingArtifact || isUploadingAppArtifact}
                                name="artifactId"
                                onDelete={() => onDelete('artifactId', 'profile')}
                                onFailure={onAppAvatarUploadFailure}
                                onStart={onAppAvatarUploadStart}
                                onSuccess={(file, artifactId) => onAppAvatarUploadSuccess(file, artifactId, 'profile')}
                                theme="user"
                            />

                            {$validation.profileArtifactId.isValid === false && $validation.profileArtifactId.show === true ? (
                                <FormValidationMessage>
                                    {$validation.profileArtifactId.error.reason}
                                </FormValidationMessage>
                            ) : null}
                        </FormRow>

                        <FormRow>
                            <Textbox
                                disabled={isUploadingArtifact || isUploadingAppArtifact}
                                id="txtAppFirstName"
                                label="App Profile First Name"
                                name="firstName"
                                required
                                type="text"
                                validation={$validation.profileFirstName}
                                value={user.profile?.firstName || ''}
                                {...$field('firstName', event => handleTextChange(handleProfileChange, event))}
                            />
                        </FormRow>

                        <FormRow>
                            <Textbox
                                disabled={isUploadingArtifact || isUploadingAppArtifact}
                                id="txtAppLastName"
                                label="App Profile Last Name"
                                name="lastName"
                                required
                                type="text"
                                validation={$validation.profileLastName}
                                value={user.profile?.lastName || ''}
                                {...$field('lastName', event => handleTextChange(handleProfileChange, event))}
                            />
                        </FormRow>
                    </>
                )}

                {!inAcceptInvitation && (
                    <FormRow>
                        <Dropdown
                            disabled={true}
                            name="role"
                            onChange={() => null}
                            options={[{
                                label: 'School Admin',
                                value: 'school_admin',
                            }]}
                            value={'school_admin'}
                        />
                    </FormRow>
                )}

                {inAcceptInvitation && (
                    <>
                        <FormRow>
                            <Textbox
                                disabled={isUploadingArtifact || isUploadingAppArtifact}
                                id="txtPassword"
                                label="Choose a password"
                                name="password"
                                required
                                type="password"
                                validation={$validation.password}
                                value={credentials.password || ''}
                                {...$field('password', event => handleTextChange(handlePasswordChange, event))}
                            />
                        </FormRow>

                        <FormRow>
                            <Textbox
                                disabled={isUploadingArtifact || isUploadingAppArtifact}
                                id="txtPassword2"
                                label="Confirm new password"
                                name="password2"
                                required
                                type="password"
                                validation={$validation.password2}
                                value={credentials.password2 || ''}
                                {...$field('password2', event => handleTextChange(handlePasswordChange, event))}
                            />
                        </FormRow>
                    </>
                )}
            </div>

            {inAcceptInvitation ? (
                <div className="auth-form__button-wrapper">
                    <A
                        to="/auth/login"
                    >
                        Back to Login
                    </A>
                    <Button
                        disabled={isUploadingArtifact || isUploadingAppArtifact}
                        onClick={(event) => {
                            event.preventDefault();
                            $submit(() => handleAcceptInvitation(true), () => handleAcceptInvitation(false))
                        }}
                        showActivityIndicator={isAcceptingInvitation}
                        type={ButtonTypes.Submit}
                    >
                        Complete Signup
                    </Button>
                </div>
            ) : (
                <ButtonRow>
                    <Button
                        disabled={isUploadingArtifact || isUploadingAppArtifact}
                        onClick={(event) => {
                            event.preventDefault();
                            $submit(() => handleSave(true), () => handleSave(false));
                        }}
                        showActivityIndicator={isSavingUser}
                        type={ButtonTypes.Submit}
                    >
                        {user.userId ? 'Save' : 'Create'} User
                    </Button>
                </ButtonRow>
            )}

            <SuccessModal
                buttonOnClick={() => {
                    setShowInvitationSuccessModal(false);
                    let redirectRoute = determinePathAfterLogin(roles);
                    history.push(redirectRoute);
                    handleClose();
                }}
                show={showInvitationSuccessModal}
            >
                <p>
                    Welcome to Abuzz!
                </p>
                <p>
                    You'll now be redirected to your school's activity feed.
                </p>
            </SuccessModal>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getUsers({schoolId: match?.params?.schoolId}));
                    setShowSuccessModal(false);
                    $fieldEvent('reset');
                    close && close();
                }}
                show={showSuccessModal}
            >
                <p>
                    This user has been saved.
                </p>
                <p>
                    It may take a minute for a new user to show in feeds and lists.
                </p>
            </SuccessModal>
        </Form>
    );
};

function createEditUserFormValidationConfig(props) {
    let { appArtifactId, appFirstName, appLastName, artifact, artifactId, emailAddress, firstName, lastName, position, profile, userId }: User = props.user;
    let { password, password2 }: Credentials = props.credentials;
    let { inAcceptInvitation, useSameDataForAppProfile } = props;

    return {
        fields: [ 'appArtifactId', 'appFirstName', 'appLastName', 'artifactId', 'emailAddress', 'firstName', 'lastName', 'password', 'password2', 'position'],
        validations: {
            appArtifactId: [
                [isRequiredIfTrue, appArtifactId, !useSameDataForAppProfile || inAcceptInvitation]
            ],
            appFirstName: [
                [isRequiredIfTrue, appFirstName, !useSameDataForAppProfile || inAcceptInvitation]
            ],
            appLastName: [
                [isRequiredIfTrue, appLastName, !useSameDataForAppProfile || inAcceptInvitation]
            ],
            emailAddress: [
                [isRequired, emailAddress],
                [isEmail, emailAddress]
            ],
            artifactId: [
                [isRequiredIfTrue, artifactId, isObjectNullOrEmpty(artifact)]
            ],
            firstName: [
                [isRequired, firstName]
            ],
            lastName: [
                [isRequired, lastName]
            ],
            password: [
                [isRequiredIfTrue, password, inAcceptInvitation]
            ],
            password2: [
                [isRequiredIfTrue, password2, inAcceptInvitation],
                [isEqual, password, password2, "Passwords must match"]
            ],
            position: [
                [isRequired, position]
            ],
            profileArtifactId: [
                [isRequiredIfTrue, profile?.artifactId, userId != null && isObjectNullOrEmpty(profile?.artifact) && !inAcceptInvitation]
            ],
            profileFirstName: [
                [isRequiredIfTrue, profile?.firstName, userId != null && !inAcceptInvitation]
            ],
            profileLastName: [
                [isRequiredIfTrue, profile?.lastName, userId != null && !inAcceptInvitation]
            ],
        }
    }
}
export default validated(createEditUserFormValidationConfig)(CreateEditUserForm);
