import Artifact from './Artifact';
import Element from './Element';
import ElementDetail from "./ElementDetail";
import Profile from "./Profile";

export type TimeSlot = {
    startTime: string;
    endTime: string;
};

export type IHoursOfOperation = {
    [key in DayOfWeek]?: TimeSlot;
};

export enum DayOfWeek {
    Sunday = 'sunday',
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
}

export default interface Tenant {
    artifact?: Artifact
    artifactId?: number
    admitActive?: boolean
    admitWelcomeMessage?: string
    alumniActive?: boolean
    billingContactEmailAddress?: string
    city?: string
    coverPhotoArtifact?: Artifact
    coverPhotoArtifactId?: number
    createdAt?: Date
    details?: Array<ElementDetail>
    elements?: {
        category?: Element
        food?: Element
        hometown?: Element
        interests?: Element
        major?: Element
        minor?: Element
    }
    givingActive?: boolean
    givingCoverPhotoArtifact?: Artifact
    givingCoverPhotoArtifactId?: number
    hoursOfOperation?: IHoursOfOperation
    interests?: Array<ElementDetail>
    launchDate?: Date
    minimumDonationUsdCents?: number
    name: string
    postAsProfile?: Profile
    primaryColor?: string
    referralPostId?: number
    secondaryColor?: string
    state?: string
    status?: 'A'
    studentActive?: boolean
    stripeAccountStatus?: string
    tenantId?: number
    textColor?: string
}
