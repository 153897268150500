import './CreateEditClassNote.scss';

import React from 'react';

import { useTypedSelector } from '../../../../store/reducers';

import CreateEditClassNoteForm from './CreateEditClassNoteForm';

type Props = {
    close?: Function
}

const CreateEditClassNote: React.FC<Props> = ({
    close
}) => {

        const { classNote } = useTypedSelector(state => state.classNotes);

        return (
            <CreateEditClassNoteForm
                close={close}
                classNote={classNote}
            />
        );
}

export default CreateEditClassNote;
