import React, {useEffect, useMemo, useRef, useState} from 'react';
import {validated} from "react-custom-validation";
import clone from 'clone';

import Deal from "../../../../types/Deal";
import DropdownOption from "../../../../types/DropdownOption";
import Validation from "../../../../types/Validation";

import { isLessThan, isRequired, isRequiredIfTrue } from '../../../../utils/validations';
import { buildArtifactIdsArray } from '../../../../utils/images';
import { handleSelectChange, handleTextChange } from '../../../../utils/handle-changes';
import { DEAL_TYPE_OPTIONS, REDEEM_FREQUENCY_OPTIONS } from '../../../../utils/constants';
import { isObjectNullOrEmpty } from "../../../../utils/utils";
import { Sizes } from "../../../../utils/enums";

import { clearDeal, deleteDeal, getDeals, saveDeal, setDeal } from '../../../../store/slices/deals';
import { getAllWalletCategories } from "../../../../store/slices/sponsorships";
import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';

import Button, { ButtonThemes, ButtonTypes } from '../../../../components/Button';
import ButtonRow from '../../../../components/ButtonRow';
import Checkbox from '../../../../components/Checkbox';
import Dropdown from "../../../../components/Dropdown";
import FileUpload from '../../../../components/FileUpload';
import Form from '../../../../components/Form';
import FormColumn from '../../../../components/FormColumn';
import FormRow from '../../../../components/FormRow';
import FormValidationMessage from '../../../../components/FormValidationMessage';
import H4 from "../../../../components/H4";
import Label from '../../../../components/Label';
import LocationSearch from '../../../../components/LocationSearch';
import Modal from '../../../../components/Modal';
import PostingAsCard from "../../../../components/PostingAsCard";
import Radio from '../../../../components/Radio';
import RadioGroup from '../../../../components/RadioGroup';
import SuccessModal from "../../../../components/Modal/SuccessModal";
import Textbox from '../../../../components/Textbox';
import VisibilityCard from '../../../../components/VisibilityCard';

type Props = {
    close?: Function
    deal: Deal
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        approxCashValue: Validation
        artifactIds: Validation
        dealType: Validation
        description: Validation
        locationArtifactId: Validation
        terms: Validation
        title: Validation
        walletCategoryId: Validation
    }
}

const CreateEditDealForm: React.FC<Props> = ({
    close,
    deal,
    $field,
    $fieldEvent,
    $submit,
    $validation,
}) => {
    const dispatch = useAppDispatch();

    const locationRef = useRef<any>(null);

    const { profile: authUser } = useTypedSelector((state) => state.auth);
    const { activeSchool } = useTypedSelector((state) => state.schools);
    const { isDeletingDeal, isSavingDeal } = useTypedSelector((state) => state.deals);
    const { walletCategories } = useTypedSelector((state) => state.sponsorships);

    const [isUploadingLocationArtifact, setIsUploadingLocationArtifact] = useState(false);
    const [isUploadingMainArtifact, setIsUploadingMainArtifact] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        if(!deal.artifactIds && deal.artifacts) {
            let artifactIds = buildArtifactIdsArray(deal);
            handleChange('artifactIds', artifactIds);
        }

        dispatch(getAllWalletCategories({tenantId: activeSchool.tenantId}));

        return () => {
            setTimeout(() => dispatch(clearDeal()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name: string, value: any) => {
        let clonedDeal = clone(deal);

        if(name === 'status') {
            clonedDeal.status = value;

            if(value === 'A') {
                delete clonedDeal.publishAt;
            }
        } else if(name === 'location' && clonedDeal.location?.artifactId) {
            value.artifactId = clonedDeal.location.artifactId;
            clonedDeal.location = value;
        } else {
            clonedDeal[name] = value;
        }

        dispatch(setDeal(clonedDeal));
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteDeal({})).unwrap();
            dispatch(getDeals({}));
            if(locationRef?.current?.refs?.input) {
                locationRef.current.refs.input.value = '';
            }
            $fieldEvent('reset');
            close && close();
        } catch (err) {
            console.log('CreateEditDealForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }

        console.log('done')
    };

    const handleSave = async (isValid: boolean) => {
        if(isValid) {
            try {
                await dispatch(saveDeal({})).unwrap();
                setShowSuccessModal(true);
                if(locationRef?.current?.refs?.input) {
                    locationRef.current.refs.input.value = '';
                }
                $fieldEvent('reset');
            } catch(err) {
                console.log('CreateEditDealForm handleSave err', err);
            }
        }
    }

    const onUploadStart = () => {
        setIsUploadingMainArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange('artifactIds', [artifactId]);
        }
        setIsUploadingMainArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingMainArtifact(false);
    };

    const onDelete = () => {
        handleChange('artifactIds', []);
    };

    const onLocationUploadStart = () => {
        setIsUploadingLocationArtifact(true);
    };

    const onLocationUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            let location = clone(deal.location);
            if(!location) {
                location = {};
            }
            location.artifactId = artifactId;
            delete location.artifact;
            handleChange('location', location);
        }
        setIsUploadingLocationArtifact(false);
    };

    const onLocationUploadFailure = () => {
        setIsUploadingLocationArtifact(false);
    };

    const onLocationDelete = () => {
        let clonedDeal = clone(deal);
        if(!isObjectNullOrEmpty(clonedDeal.location)) {
            delete clonedDeal.location.artifactId;
            delete clonedDeal.location.artifact;
        }
        dispatch(setDeal(clonedDeal));
    };

    const WALLET_CATEGORY_OPTIONS: Array<DropdownOption> = useMemo(
        () => {
            return walletCategories.map((wc) => ({
                label: wc.value,
                value: wc.walletCategoryId,
            }));
        }, [walletCategories]
    );

    return (
        <Form className="content-form">
            <H4>
                {deal.postId ? 'Edit ' : 'Create a '}Deal
            </H4>

            <FormRow>
                <PostingAsCard
                    profile={deal.profile || activeSchool.postAsProfile}
                    user={!deal.profile || deal.profile === activeSchool.postAsProfile ? authUser : null}
                />
            </FormRow>

            <FormRow>
                <FileUpload
                    acceptsVideo={false}
                    currentArtifact={deal.artifacts?.[0] || null}
                    disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                    name="artifactIds"
                    onDelete={onDelete}
                    onStart={onUploadStart}
                    onSuccess={onUploadSuccess}
                    onFailure={onUploadFailure}
                />
                {$validation.artifactIds.isValid === false && $validation.artifactIds.show === true ? (
                    <FormValidationMessage>
                        {$validation.artifactIds.error.reason}
                    </FormValidationMessage>
                ) : null}
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                    id="txtTitle"
                    label="Title"
                    name="title"
                    required
                    type="text"
                    validation={$validation.title}
                    value={deal.title || ''}
                    {...$field('title', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                    id="txtDescription"
                    label="Description"
                    name="description"
                    required
                    type="textarea"
                    validation={$validation.description}
                    value={deal.description || ''}
                    {...$field('description', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                    id="txtApproxCashValue"
                    label="Approximate Cash Value"
                    name="approxCashValue"
                    required
                    type="number"
                    validation={$validation.approxCashValue}
                    value={deal.approxCashValue || ''}
                    {...$field('approxCashValue', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Dropdown
                    isSearchable={false}
                    label='Deal Type'
                    name='dealType'
                    options ={DEAL_TYPE_OPTIONS}
                    required
                    validation={$validation.dealType}
                    value={deal.dealType || ''}
                    {...$field('dealType', (selectedValue) => handleSelectChange(handleChange, 'dealType', null, $fieldEvent, selectedValue))}
                />
            </FormRow>

            <FormRow>
                <Dropdown
                    isSearchable={false}
                    label='Wallet Category'
                    name='walletCategoryId'
                    options ={WALLET_CATEGORY_OPTIONS}
                    required
                    validation={$validation.walletCategoryId}
                    value={deal.walletCategoryId || ''}
                    {...$field('dealType', (selectedValue) => handleSelectChange(handleChange, 'walletCategoryId', null, $fieldEvent, selectedValue))}
                />
            </FormRow>

            <div className="deal-form__location">
                <FormRow className="content-form__deal-location-row" columnCount={2}>
                    <FormColumn>
                        <LocationSearch
                            disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                            label="Location"
                            name="location"
                            onPlaceSelected={(location) => handleChange('location', location)}
                            selectedLocation={deal.location}
                            thisRef={locationRef}
                        />
                    </FormColumn>

                    <FormColumn>
                        <FileUpload
                            disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                            acceptsVideo={false}
                            currentArtifact={deal?.location?.artifact || null}
                            name="artifactIds"
                            onDelete={onLocationDelete}
                            size={Sizes.Small}
                            onStart={onLocationUploadStart}
                            onSuccess={onLocationUploadSuccess}
                            onFailure={onLocationUploadFailure}
                        />
                        {$validation.locationArtifactId.isValid === false && $validation.locationArtifactId.show === true ? (
                            <FormValidationMessage>
                                {$validation.locationArtifactId.error.reason}
                            </FormValidationMessage>
                        ) : null}
                    </FormColumn>
                </FormRow>
            </div>

            <FormRow>
                <Textbox
                    disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                    id="txtTerms"
                    label="Terms"
                    name="terms"
                    required
                    type="textarea"
                    validation={$validation.terms}
                    value={deal.terms || ''}
                    {...$field('terms', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Checkbox
                    disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                    checked={deal.redeem === 'Y'}
                    label="Require user to redeem deal for use"
                    id="chkRedeem"
                    name="redeem"
                    onChange={() => handleChange('redeem', deal.redeem === 'Y' ? 'N' : 'Y')}
                />
            </FormRow>

            {deal.redeem === 'Y' ? (
                <FormRow>
                    <Label>How often can this deal be redeemed?</Label>

                    <RadioGroup horizontal>
                        {REDEEM_FREQUENCY_OPTIONS.map((option) => (
                            <Radio
                                checked={deal.redeemFrequency === option.value}
                                disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                                id={`chkRedeemFrequency${option.value}`}
                                key={option.value || 'abcde'}
                                label={option.label}
                                name="redeemFrequency"
                                onChange={() => handleChange('redeemFrequency', option.value)}
                                value={option.value}
                            />
                        ))}
                    </RadioGroup>
                </FormRow>
            ) : null}

            <VisibilityCard
                handleChange={handleChange}
                post={deal}
            />

            <ButtonRow>
                <Button
                    disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                    showActivityIndicator={isSavingDeal}
                    type={ButtonTypes.Submit}
                >
                    Save
                </Button>

                {deal.postId && (
                    <Button
                        className="destructive"
                        disabled={isUploadingLocationArtifact || isUploadingMainArtifact}
                        onClick={() => setShowDeleteConfirmationModal(true)}
                        theme={ButtonThemes.Link}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getDeals({}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
            >
                <p>
                    Your deal has been saved.
                </p>
                <p>
                    It may take a minute for your listing to show in feeds and lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingDeal}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this deal?
            </Modal>
        </Form>
    );
};

function createEditDealFormValidationConfig(props) {
    let { approxCashValue, artifactIds, dealType, description, location, terms, title, walletCategoryId }: Deal = props.deal;
    const locationArtifact = location ? location.artifact : null;
    const locationArtifactId = location ? location.artifactId : null;

    return {
        fields: ['approxCashValue', 'artifactIds', 'dealType', 'description', 'locationArtifactId', 'terms', 'title', 'walletCategoryId'],
        validations: {
            approxCashValue: [
                [isRequired, approxCashValue],
                [isLessThan, approxCashValue, 1000000, 'Please choose a realistic value for this deal.']
            ],
            artifactIds: [
                [isRequired, artifactIds]
            ],
            dealType: [
                [isRequired, dealType]
            ],
            description: [
                [isRequired, description]
            ],
            locationArtifactId: [
                [isRequiredIfTrue, locationArtifactId, location && !locationArtifact && Object.keys(location).length > 1, 'Required']
            ],
            terms: [
                [isRequired, terms]
            ],
            title: [
                [isRequired, title]
            ],
            walletCategoryId: [
                [isRequired, walletCategoryId]
            ]
        }
    }
}
export default validated(createEditDealFormValidationConfig)(CreateEditDealForm);
