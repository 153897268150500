import './UpdateGivingStatus.scss';

import React, { useEffect, useState } from 'react';

import {
    clearActiveSchool, clearGivingStatusConfig, resendStripeAccountSetupEmail,
    setActiveSchool, updateGivingStatus,
} from '../../../store/slices/schools';

import { useAppDispatch } from '../../../store';
import { useTypedSelector } from '../../../store/reducers';

import Tenant from '../../../types/Tenant';
import { StripeAccountStatus } from '../../../types/StripeAccountStatus';

import Button, { ButtonThemes } from '../../../components/Button';
import Label from '../../../components/Label';
import Icon from '../../../components/Icon';
import FormRow from '../../../components/FormRow';
import Form from '../../../components/Form';
import SelectSchoolModal from '../SelectSchoolModal';
import FormColumn from '../../../components/FormColumn';
import UpdateGivingStatusForm from './UpdateGivingStatusForm';
import SuccessModal from '../../../components/Modal/SuccessModal';
import GivingStatusPendingApprovalForm from './GivingStatusPendingApprovalForm';

const UpdateGivingStatus: React.FC = () => {
    const dispatch = useAppDispatch();

    const {
        activeSchool,
        updateGivingStatusConfig,
        isResendingStripeAccountSetupEmail,
    } = useTypedSelector(state => state.schools);

    const [showAttachContentModal, setShowAttachContentModal] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showPendingApproval, setShowPendingApproval] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showSuccessModalResend, setShowSuccessModalResend] = useState<boolean>(false);

    useEffect(() => {
        if (!activeSchool.tenantId) {
            clearGivingStatusConfig();
            setShowForm(false);
        }

        if (
            activeSchool.givingActive &&
            activeSchool.billingContactEmailAddress &&
            activeSchool.stripeAccountStatus !== StripeAccountStatus.Complete
        ) {
            clearGivingStatusConfig();
            setShowPendingApproval(true);
            setShowForm(false);
        }

        if (activeSchool.stripeAccountStatus === StripeAccountStatus.Complete) {
            setShowPendingApproval(false);
            setShowForm(true);
        }

    }, [activeSchool]);

    useEffect(() => {
        return () => {
            handleClearActiveSchool();
        }
    }, []);

    const handleSelectSchool = (item: Tenant) => {
        dispatch(setActiveSchool(item));
        setShowAttachContentModal(false);
        setShowForm(true);
    };

    const handleClearActiveSchool = () => {
        dispatch(clearActiveSchool());
        dispatch(clearGivingStatusConfig());
        setShowForm(false);
        setShowPendingApproval(false);
    };

    const handleResendStripeAccountSetupEmail = () => {
        const resend = async () => {
            try {
                await dispatch(resendStripeAccountSetupEmail());
                setShowSuccessModalResend(true);
            } catch (err) {
                console.error(err);
            }
        };

        resend();
    };

    const handleSubmit = async () => {
        await dispatch(updateGivingStatus()).unwrap();
        setShowSuccessModal(true);
    }

    return (
        <Form>
            <FormColumn className="update-giving-status">

                {!activeSchool.tenantId && (
                    <FormRow className="update-giving-status__select_school_container">
                        <Label>
                            Select a School to Enable Giving:
                        </Label>

                        <Button
                            className="update-giving-status__select-school-button"
                            onClick={() => setShowAttachContentModal(true)}
                            theme={ButtonThemes.Link}
                        >
                            Add School
                        </Button>
                    </FormRow>
                )}

                {activeSchool.tenantId && (
                    <>
                        <FormRow className="update-giving-status__select_school_container">
                            <>
                                <Label>
                                    Selected School:
                                </Label>
                                <Label className="update-giving-status__selected_school_text">
                                    {activeSchool.name}
                                </Label>
                                <Button
                                    className="update-giving-status__form-item-button"
                                    key={activeSchool.tenantId}
                                    onClick={handleClearActiveSchool}
                                    noStyles={true}
                                >
                                    <Icon type="trash"/>
                                </Button>
                            </>
                        </FormRow>

                        <FormRow>
                            <Label>
                                Stripe Account Status: {activeSchool.stripeAccountStatus}
                            </Label>
                        </FormRow>
                    </>
                )}

                {showForm && activeSchool.tenantId && (
                    <UpdateGivingStatusForm
                        config={updateGivingStatusConfig}
                        dispatch={dispatch}
                        onSubmit={handleSubmit}
                    />
                )}

                {showPendingApproval && activeSchool.tenantId && (
                    <GivingStatusPendingApprovalForm
                        activeSchool={activeSchool}
                        isResendingStripeAccountSetupEmail={isResendingStripeAccountSetupEmail}
                        handleResendStripeAccountSetupEmail={handleResendStripeAccountSetupEmail}
                    />
                )}
            </FormColumn>

            <SelectSchoolModal
                show={showAttachContentModal}
                setShow={setShowAttachContentModal}
                handleSelectSchool={handleSelectSchool}
            />

            <SuccessModal
                buttonOnClick={() => setShowSuccessModalResend(false)}
                show={showSuccessModalResend}
            >
                <p>
                    We've received your request.
                </p>
                <p>
                    Please inform the billing contact for {activeSchool.name} that we sent an email address
                    to {activeSchool.billingContactEmailAddress} for them to update their Stripe Account.
                </p>
            </SuccessModal>

            <SuccessModal
                buttonOnClick={() => setShowSuccessModal(false)}
                show={showSuccessModal}
            >
                <p>
                    Your request to enable the giving feature has been received.
                </p>
                <p>
                    Our billing partner, Stripe, will send an email to the address provided to complete the setup
                    process momentarily. If you do not receive it within 5 minutes, please revisit this page to send
                    another invite.
                </p>
            </SuccessModal>
        </Form>

    );
};

export default UpdateGivingStatus;

