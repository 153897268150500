import './Table.scss';

import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';
import classNames from "classnames";

import TableColumn from "../../types/TableColumn";

import { PAGE_SIZE_OPTIONS } from "../../utils/constants";

import ActivityIndicator from "../ActivityIndicator";
import Button from "../Button";
import Dropdown from "../Dropdown";
import Icon from "../Icon";
import Card from "../Card";

type Props = {
    className?: string
    columns: Array<TableColumn>
    data: Array<any>
    disableFilter?: (arg0: any) => boolean
    disablePending?: boolean
    getData?: (arg0: any, arg1?: boolean) => void
    pageNumber: number
    pageSize: number
    onRowClick?: (arg0: any) => void
    showPagination?: boolean
    sortBy?: string
    sortOrder?: string
    totalNumberOfItems: number
}

const Table: React.FC<Props> = ({
    className = '',
    columns,
    data,
    disableFilter,
    disablePending,
    getData,
    pageNumber,
    pageSize,
    onRowClick,
    showPagination = true,
    sortBy,
    sortOrder,
    totalNumberOfItems
}) => {
    let pageCount = Math.ceil(totalNumberOfItems / pageSize);

    const [isUpdating, setIsUpdating] = useState(false);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        // Get the state from the instance
        //state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageNumber},
            manualPagination: true,
            pageCount: pageCount,
        },
        usePagination
    );

    const calculateMax = () => {
        let total = (pageNumber + 1) * pageSize;
        if(total > totalNumberOfItems) {
            return totalNumberOfItems;
        }

        return total;
    };

    const generatePageOptions = () => {
        let options = [];
        for(var i = 0; i < pageCount; i++) {
            options.push({
                label: i + 1,
                value: i
            });
        }
        return options;
    };

    const handleChangePageClick = async (change) => {
        let page_num = pageNumber + change;

        setIsUpdating(true);
        await getData({page_num}, true);
        setIsUpdating(false);
    };

    const handleSortChange = async (columnSortBy) => {
        if(!columnSortBy) {
            return;
        }

        setIsUpdating(true);
        let changes = {
            sort: columnSortBy,
            order: 'asc'
        };
        if(sortBy === columnSortBy) {
            changes.order = sortOrder === 'asc' ? 'desc' : 'asc';
        }
        await getData(changes, true);
        setIsUpdating(false);
    };

    const handlePageSelect = async (page_num) => {
        setIsUpdating(true);
        await getData({page_num}, true);
        setIsUpdating(false);
    };

    const handlePageSizeChange = async (page_size) => {
        setIsUpdating(true);
        await getData({page_size, page_num: 0});
        setIsUpdating(false);
    };

    const handleRowClick = (row) => {
        if(onRowClick) {
            onRowClick(row);
        }
    };

    return (
        <Card className={`o-table ${className}`}>
            <table
                className={`c-table ${onRowClick ? 'a-table--row-click' : ''}`}
                {...getTableProps()}
                cellSpacing={0}
            >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                let classes = classNames(column.className, {
                                    'c-table__has-sort-by': column.sortBy,
                                });

                                return (
                                    <th
                                        className={classes}
                                        onClick={() => handleSortChange(column.sortBy)}
                                        {...column.getHeaderProps()}
                                    >
                                        <div className="c-table__th-inner">
                                            {column.render('Header')}
                                            {column.sortBy && column.sortBy === sortBy && (
                                                <Icon
                                                    className={`c-table__sort-icon ${sortOrder === 'asc' ? 'a-table__sort-icon--asc' : ''}`}
                                                    type="caret-down"
                                                />
                                            )}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        let isNotActive = row.original?.status !== 'A' && disablePending;
                        if(disableFilter) {
                            isNotActive = disableFilter(row);
                        }
                        let classes = classNames({
                            'a-table__row--inactive': isNotActive,
                            'a-table__row--flagged': row.original?.flagged || row.original?.flaggedContent,
                            'item--removed-by-mods': row.original?.removedByMods,
                            'item--super-approved': row.original?.superApproval,
                        });
                        return (
                            <tr
                                className={classes}
                                onClick={() => {
                                    if(isNotActive) {
                                        return;
                                    }

                                    handleRowClick(row);
                                }}
                                {...row.getRowProps()}
                            >
                                {row.cells.map(cell => {
                                    let classes = classNames(cell?.column?.className, {
                                        'c-table__cell-has-image': cell?.column?.hasImage,
                                    });
                                    return <td className={classes} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {showPagination && (
                <div className="c-table__pagination">
                    <div className="c-table__pagination--left">
                        <Dropdown
                            label="Items per page: "
                            name="page_size"
                            naked
                            options={PAGE_SIZE_OPTIONS}
                            onChange={({value}) => handlePageSizeChange(value)}
                            value={pageSize}
                        />

                        <div className="c-table__item-count">
                            {(pageNumber * pageSize) + 1} - {calculateMax()} of {totalNumberOfItems || 0} items
                        </div>
                    </div>

                    <div className="c-table__pagination--right">
                        <div className="c-table__page-number">
                            <Dropdown
                                label={`${isNaN(pageCount) ? '0' : ''} of ${isNaN(pageCount) ? '0' : pageCount} page${pageCount !== 1 ? 's' : ''}`}
                                name="page_num"
                                naked
                                options={generatePageOptions()}
                                onChange={({value}) => handlePageSelect(value)}
                                value={pageNumber}
                            />
                        </div>

                        <div className="c-table__page-button-container">
                            <Button
                                className="c-table__page-button"
                                disabled={pageNumber === 0}
                                noStyles
                                onClick={() => handleChangePageClick(-1)}
                            >
                                <Icon type="arrow-left" />
                            </Button>

                            <Button
                                className="c-table__page-button"
                                disabled={pageNumber + 1 === pageCount}
                                noStyles
                                onClick={() => handleChangePageClick(1)}
                            >
                                <Icon type="arrow-right" />
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {isUpdating && (
                <div className="c-table__is-updating">
                    <ActivityIndicator size="large" />
                </div>
            )}
        </Card>
    );
};

export default Table;
