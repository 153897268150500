import './ViewGroupMembers.scss';

import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from "react-router";

import { PRIVACY_TYPES } from "../../../../../../utils/constants";

import { useTypedSelector } from "../../../../../../store/reducers";

import Card from "../../../../../../components/Card";
import Icon from "../../../../../../components/Icon";
import TableFilterButtons from "../../../../../../components/Table/TableFilterButtons";
import ViewGroupMembersActive from "./ViewGroupMembersActive";
import ViewGroupMembersPendingInvited from "./ViewGroupMembersPendingInvited";
import ViewGroupMembersPendingRequested from "./ViewGroupMembersPendingRequested";
import { ProfileTypes } from '../../../../../../utils/enums';

const ViewGroupMembers: React.FC = () => {
    const history = useHistory();
    const { params } = useRouteMatch();

    const { group } = useTypedSelector((state) => state.groups);
    const { postAsProfile } = useTypedSelector((state) => state.schools.activeSchool);

    let BUTTONS = [{
        isActive: params.memberType === 'active',
        onClick: () => history.push(`/school/${params.schoolId}/${params.profileType}/group/${params.forumTopicId}/members/active`),
        text: `Active (${group.memberCount || 0})`
    }, {
        disabled: !group.requestedCount,
        isActive: params.memberType === 'requested',
        onClick: () => history.push(`/school/${params.schoolId}/${params.profileType}/group/${params.forumTopicId}/members/requested`),
        text: `Requests (${group.requestedCount || 0})`
    }, {
        disabled: !group.invitedCount,
        isActive: params.memberType === 'invited',
        onClick: () => history.push(`/school/${params.schoolId}/${params.profileType}/group/${params.forumTopicId}/members/invited`),
        text: `Invitations (${group.invitedCount || 0})`
    }];

    return (
        <Card className="view-group-members">
            <header className="view-group-members__header">
                <Icon type="team" /> Members
            </header>

            {(group.privacyType === PRIVACY_TYPES.PUBLIC || group.isFollowing  || postAsProfile.profileType === ProfileTypes.Global) ? (
                <>
                    <nav className="view-group-members__types-nav">
                        <TableFilterButtons
                            buttons={BUTTONS}
                        />
                    </nav>

                    <Switch>
                        <Route exact path="/school/:schoolId/:profileType/group/:forumTopicId/members/active" component={ViewGroupMembersActive} />
                        <Route exact path="/school/:schoolId/:profileType/group/:forumTopicId/members/invited" component={ViewGroupMembersPendingInvited} />
                        <Route exact path="/school/:schoolId/:profileType/group/:forumTopicId/members/requested" component={ViewGroupMembersPendingRequested} />
                    </Switch>
                </>
            ) : (
                <p>
                    Join this group to see a list of members.
                </p>
            )}
        </Card>
    );
};

export default ViewGroupMembers;
