import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import IError from "../../types/IError";
import Metadata from "../../types/Metadata";
import NewsStory from "../../types/NewsStory";

import { buildErrorObject } from "../../utils/errors";
import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import {
    generateNewsStoriesMetadata,
    generateNewsStory
} from '../../utils/generators';

import { addError } from "./errors";
import { RootState } from '../reducers';

type DeleteNewsStoryProps = {
    newsStory?: NewsStory
}

export const deleteNewsStory = createAsyncThunk(
    'news/deleteNewsStory',
    async ({newsStory}: DeleteNewsStoryProps = {}, {dispatch, getState, rejectWithValue}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!newsStory) {
                newsStory = (getState() as RootState).news.newsStory;
            }

            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.news.delete(tenantId, newsStory.postId));
            return {
                ...res,
                postId: newsStory.postId,
            };
        } catch(err) {
            console.log('delete news story err', err);
            let friendlyMessage = 'Error deleting this news story. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
)

type GetNewsStoriesProps = {
    isUpdate?: boolean
    schoolId?: number
    newsStoriesMetadata?: Metadata
}

export const getNewsStories = createAsyncThunk(
    'news/getNewsStories',
    async ({isUpdate, schoolId, newsStoriesMetadata}: GetNewsStoriesProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!newsStoriesMetadata) {
                newsStoriesMetadata = clone((getState() as RootState).news.newsStoriesMetadata);
            } else {
                newsStoriesMetadata = {...newsStoriesMetadata}
            }

            newsStoriesMetadata.type = 'N';

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.content.getContent(schoolId, buildQueryString(newsStoriesMetadata)));
            let newsStories = res.data.data.items;
            newsStoriesMetadata.total = res.data.data.meta.total;
            return {newsStories, newsStoriesMetadata};
        } catch(err) {
            console.log('getNewsStories err', err);
            let friendlyMessage = 'Error getting the list of news stories. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(err));
            return rejectWithValue(errorObject);
        }
    }
);

type GetNewsStoryProps = {
    postId?: number | string
    schoolId?: number | string
}

export const getNewsStory = createAsyncThunk(
    'news/getNewsStory',
    async ({postId, schoolId}: GetNewsStoryProps = {}, {dispatch, getState, rejectWithValue}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            const res = await new Request(token).get(PATHS.news.getById(schoolId, postId));
            return res.data.data;
        } catch(err) {
            console.log('getDeal', err);
            let friendlyMessage = 'Error getting the news story. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(err));
            return rejectWithValue(errorObject);
        }
    }
);

type SaveNewsStoryProps = {
    newsStory?: NewsStory
}

export const saveNewsStory = createAsyncThunk(
    'news/saveNewsStory',
    async({newsStory}: SaveNewsStoryProps, {dispatch, getState, rejectWithValue}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId } } } = (getState() as RootState);

        if(!newsStory) {
            newsStory = (getState() as RootState).news.newsStory;
        }

        let path = PATHS.news.create(tenantId);
        let request = new Request(token);
        let reqFunc = request.post;

        if(newsStory.postId) {
            path = PATHS.news.update(tenantId, newsStory.postId);
            reqFunc = request.put;
        } else {
            newsStory = {
                ...newsStory,
                tenantId,
            };
        }

        try {
            const res = await reqFunc(path, newsStory);
            return res;
        } catch(err) {
            console.log('save deal error', err);
            let friendlyMessage = 'Error saving the news story. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(err));
            return rejectWithValue(errorObject);
        }
    }
)

interface DealsState {
    newsStory: NewsStory
    newsStories: Array<NewsStory>
    newsStoriesMetadata: Metadata
    isDeletingNewsStory: boolean
    isGettingNewsStory: boolean
    isGettingNewsStories: boolean
    isSavingNewsStory: boolean
    deleteNewsStoryError?: IError
    getNewsStoryError?: IError
    getNewsStoriesError?: IError
    saveNewsStoryError?: IError
    searchTerm: string
}

const initialState: DealsState = {
    newsStory: generateNewsStory(),
    newsStories: [],
    newsStoriesMetadata: generateNewsStoriesMetadata(),
    searchTerm: '',
    isDeletingNewsStory: false,
    isGettingNewsStory: false,
    isGettingNewsStories: false,
    isSavingNewsStory: false,
    deleteNewsStoryError: undefined,
    getNewsStoryError: undefined,
    getNewsStoriesError: undefined,
    saveNewsStoryError: undefined
};

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        clearNewsStory: (state) => {
            state.newsStory = generateNewsStory();
        },
        clearNewsStoriesMetadata: (state) => {
            state.newsStoriesMetadata = generateNewsStoriesMetadata();
            state.searchTerm = '';
        },
        setNewsStory: (state, action) => {
            state.newsStory = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteNewsStory.pending, (state) => {
            state.deleteNewsStoryError = undefined;
            state.isDeletingNewsStory = true;
        });
        addCase(deleteNewsStory.fulfilled, (state, action) => {
            state.isDeletingNewsStory = false;
            state.newsStory = generateNewsStory();
        });
        addCase(deleteNewsStory.rejected, (state, action) => {
            state.deleteNewsStoryError = action.error as IError;
            state.isDeletingNewsStory = false;
        });

        addCase(getNewsStories.pending, (state, action) => {
            state.getNewsStoriesError = undefined;
            state.isGettingNewsStories = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.newsStoriesMetadata) {
                state.newsStoriesMetadata = action.meta.arg.newsStoriesMetadata;
            }
        });
        addCase(getNewsStories.fulfilled, (state, action) => {
            state.newsStories = action.payload.newsStories;
            state.newsStoriesMetadata = action.payload.newsStoriesMetadata;
            state.isGettingNewsStories = false;
        });
        addCase(getNewsStories.rejected, (state, action) => {
            state.getNewsStoriesError = action.error as IError;
            state.isGettingNewsStories = false;
        });

        addCase(getNewsStory.pending, (state) => {
            state.getNewsStoryError = undefined;
            state.isGettingNewsStory = true;
        });
        addCase(getNewsStory.fulfilled, (state, action) => {
            state.isGettingNewsStory = false;
            state.newsStory = action.payload;
        });
        addCase(getNewsStory.rejected, (state, action) => {
            state.getNewsStoryError = action.error as IError;
            state.isGettingNewsStory = false;
        });

        addCase(saveNewsStory.pending, (state) => {
            state.saveNewsStoryError = undefined;
            state.isSavingNewsStory = true;
        });
        addCase(saveNewsStory.fulfilled, (state, action) => {
            state.isSavingNewsStory = false;
            state.newsStory = generateNewsStory();
        });
        addCase(saveNewsStory.rejected, (state, action) => {
            state.saveNewsStoryError = action.error as IError;
            state.isSavingNewsStory = false;
        });
    }
});

export const { clearNewsStory, clearNewsStoriesMetadata, setNewsStory, setSearchTerm } = newsSlice.actions;

export default newsSlice.reducer;
