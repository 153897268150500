import './CustomOptionWithIcon.scss';

import React from 'react';
import { components, OptionProps } from 'react-select';
import Icon from "../../Icon";
import {isObjectNullOrEmpty} from "../../../utils/utils";

const CustomOptionWithIcon: React.FC = (
    props: OptionProps<DropdownOption>
) => {
    console.log('option props', props);

    if(props.isSelected || isObjectNullOrEmpty(props.data)) {
        return null;
    }

    return (
        <components.Option
            className="custom-control-with-icon"
            {...props}
        >
            <div className="custom-control-with-icon__inner">
                {props.data.icon && (
                    <div className="custom-control-with-icon__icon-container">
                        <Icon type={props.data.icon} />
                    </div>
                )}

                <div className="custom-control-with-icon__content">
                    <div className="custom-control-with-icon__label">
                        {props.data.label}
                    </div>

                    <div className="custom-control-with-icon__sublabel">
                        {props.data.sublabel}
                    </div>
                </div>
            </div>
        </components.Option>
    );
};

export default CustomOptionWithIcon;
