import './EditSchoolDetails.scss';

import React, { useEffect, useState } from 'react';
import { validated } from "react-custom-validation";
import moment from "moment";
import clone from 'clone';

import Tenant, { DayOfWeek } from "../../../../types/Tenant";
import Validation from "../../../../types/Validation";

import { handleTextChange } from "../../../../utils/handle-changes";
import { Sizes } from "../../../../utils/enums";
import { isRequired, isRequiredIfTrue} from "../../../../utils/validations";
import { isObjectNullOrEmpty} from "../../../../utils/utils";

import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";
import { clearSchoolToEdit, getSchool, saveSchool, setSchoolToEdit } from "../../../../store/slices/schools";

import Button, { ButtonTypes } from "../../../../components/Button";
import ButtonRow from "../../../../components/ButtonRow";
import Checkbox from "../../../../components/Checkbox";
import ColorPicker from "../../../../components/ColorPicker";
import Datepicker from "../../../../components/Datepicker";
import FileUpload from "../../../../components/FileUpload";
import Form from "../../../../components/Form";
import FormColumn from "../../../../components/FormColumn";
import FormRow from "../../../../components/FormRow";
import FormValidationMessage from "../../../../components/FormValidationMessage";
import H5 from "../../../../components/H5";
import Icon from "../../../../components/Icon";
import Label from "../../../../components/Label";
import PageHeader from "../../../../components/PageHeader";
import Radio from "../../../../components/Radio";
import RadioGroup from "../../../../components/RadioGroup";
import SuccessModal from "../../../../components/Modal/SuccessModal";
import Textbox from "../../../../components/Textbox";

type Props = {
    school: Tenant
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        admitWelcomeMessage: Validation
        artifactId: Validation
        coverPhotoArtifactId: Validation
        hoursOfOperation: Validation
        name: Validation
        primaryColor: Validation
        secondaryColor: Validation
        textColor: Validation
        sundayStartTime: Validation
        mondayStartTime: Validation
        tuesdayStartTime: Validation
        wednesdayStartTime: Validation
        thursdayStartTime: Validation
        fridayStartTime: Validation
        saturdayStartTime: Validation
        sundayEndTime: Validation
        mondayEndTime: Validation
        tuesdayEndTime: Validation
        wednesdayEndTime: Validation
        thursdayEndTime: Validation
        fridayEndTime: Validation
        saturdayEndTime: Validation
    }
}

enum FieldNames {
    AdmitActive = 'admitActive',
    AdmitWelcomeMessage = 'admitWelcomeMessage',
    AlumniActive = 'alumniActive',
    ArtifactId = 'artifactId',
    CoverPhotoArtifactId = 'coverPhotoArtifactId',
    HoursOfOperation = 'hoursOfOperation',
    Name = 'name',
    PrimaryColor = 'primaryColor',
    SecondaryColor = 'secondaryColor',
    StudentActive = 'studentActive',
    TextColor = 'textColor',
}

const EditSchoolDetailsForm: React.FC<Props> = ({
    school,
    $field,
    $fieldEvent,
    $submit,
    $validation
}) => {
    const dispatch = useAppDispatch();

    const { isSavingSchool, activeSchool } = useTypedSelector((state) => state.schools);

    const [isUploadingArtifact, setIsUploadingArtifact] = useState(false);
    const [showPrimaryColorPicker, setShowPrimaryColorPicker] = useState(false);
    const [showSecondaryColorPicker, setShowSecondaryColorPicker] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        if (activeSchool) {
            dispatch(setSchoolToEdit(clone(activeSchool)));
        }
        return () => {
            dispatch(clearSchoolToEdit());
            $fieldEvent('reset');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSchool]);

    const handleChange = (name: FieldNames, value: any) => {
        let clonedSchool: Tenant = clone(school);

        if (name === FieldNames.HoursOfOperation) {
            handleHoursOfOperationChange(clonedSchool, value);
        } else {
            // @ts-ignore
            clonedSchool[name] = value;
        }
        dispatch(setSchoolToEdit(clonedSchool));
    };

    const handleColorPickerChange = async (name: FieldNames, color: any) => {
        handleChange(name, color.hex);
    };

    const handleSave = async (isValid: boolean) => {
        if (isValid) {
            try {
                await dispatch(saveSchool()).unwrap();
                setShowSuccessModal(true);
            } catch (err) {
                console.log('EditSchoolDetailsForm handleSave err', err);
            }
        }
    };

    const onUploadStart = () => {
        setIsUploadingArtifact(true);
    };

    const onUploadSuccess = (name: FieldNames, artifactId) => {
        if (artifactId) {
            handleChange(name, artifactId);
        }
        setIsUploadingArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingArtifact(false);
    };

    const onDelete = (name: FieldNames) => {
        handleChange(name, null);
    };

    const handleHoursOfOperationChange = (clonedSchool: Tenant, {day, endTime, startTime, remove}: { day: DayOfWeek, startTime?: Date, endTime?: Date, remove?: boolean}) => {
        const dayExists = clonedSchool.hoursOfOperation[day];
        if (!dayExists) {
            clonedSchool.hoursOfOperation[day] = {
                startTime: '',
                endTime: ''
            };
        }

        if (remove) {
            delete clonedSchool.hoursOfOperation[day];
            return;
        }

        if (startTime) {
            if (clonedSchool.hoursOfOperation[day].endTime) {
                const end = moment(clonedSchool.hoursOfOperation[day].endTime, 'HH:mm');
                const start = moment(startTime);
                if (end.isBefore(start)) {
                    clonedSchool.hoursOfOperation[day].endTime = '23:59';
                }
            }
            const startAt = moment(startTime).format('HH:mm');
            if (startAt.length < 5) {
                clonedSchool.hoursOfOperation[day].startTime = `0${startAt}`;
            } else {
                clonedSchool.hoursOfOperation[day].startTime = startAt;
            }
        }
        if (endTime) {
            if (clonedSchool.hoursOfOperation[day].startTime) {
                const start = moment(clonedSchool.hoursOfOperation[day].startTime, 'HH:mm');
                const end = moment(endTime);
                if (end.isBefore(start)) {
                    clonedSchool.hoursOfOperation[day].startTime = '00:00';
                }
            }

            const endAt = moment(endTime).format('HH:mm');
            if (endAt.length < 5) {
                clonedSchool.hoursOfOperation[day].endTime = `0${endAt}`;
            } else {
                clonedSchool.hoursOfOperation[day].endTime = endAt;
            }

            clonedSchool.hoursOfOperation[day].endTime = moment(endTime).format('HH:mm');
        }
    };

    return (
        <div className="edit-tenant-details">
            <PageHeader>
                <H5>
                    <Icon type="edit" />Edit School Details
                </H5>
            </PageHeader>
            <div className="edit-tenant-form-container">
                <Form className="edit-tenant-form">
                    <FormRow>
                        <Textbox
                            disabled={isUploadingArtifact}
                            id="tenantName"
                            label="School Name"
                            name={FieldNames.Name}
                            required
                            type="text"
                            value={school.name || ''}
                            validation={$validation.name}
                            {...$field('name', event => handleTextChange(handleChange, event))}
                        />
                    </FormRow>

                    <FormRow columnCount={2}>
                        <FormColumn>
                            <Label>
                                School Logo <span className="c-label__required-asterisk">*</span>
                            </Label>
                            <FileUpload
                                acceptsVideo={false}
                                currentArtifact={activeSchool.artifact || null}
                                disabled={isUploadingArtifact}
                                name={FieldNames.ArtifactId}
                                onDelete={() => onDelete(FieldNames.ArtifactId)}
                                onStart={onUploadStart}
                                onSuccess={({artifactId}) => onUploadSuccess(FieldNames.ArtifactId, artifactId)}
                                onFailure={onUploadFailure}
                                size={Sizes.Small}
                                theme='user'
                            />
                            {$validation.artifactId.isValid === false && $validation.artifactId.show === true ? (
                                <FormValidationMessage>
                                    {$validation.artifactId.error.reason}
                                </FormValidationMessage>
                            ) : null}
                        </FormColumn>
                        <FormColumn>
                            <Label>
                                Header Background Image <span className="c-label__required-asterisk">*</span>
                            </Label>
                            <FileUpload
                                acceptsVideo={false}
                                currentArtifact={activeSchool.coverPhotoArtifact || null}
                                disabled={isUploadingArtifact}
                                name={FieldNames.CoverPhotoArtifactId}
                                onDelete={() => onDelete(FieldNames.CoverPhotoArtifactId)}
                                onStart={onUploadStart}
                                onSuccess={({artifactId}) => onUploadSuccess(FieldNames.CoverPhotoArtifactId, artifactId)}
                                onFailure={onUploadFailure}
                                size={Sizes.Small}
                                theme='user'
                            />
                            {$validation.coverPhotoArtifactId.isValid === false && $validation.coverPhotoArtifactId.show === true ? (
                                <FormValidationMessage>
                                    {$validation.coverPhotoArtifactId.error.reason}
                                </FormValidationMessage>
                            ) : null}
                        </FormColumn>
                    </FormRow>

                    <FormRow columnCount={2}>
                        <FormColumn>
                            <Label htmlFor="txtPrimaryColor">
                                Primary Color <span className="c-label__required-asterisk">*</span>
                            </Label>
                            <FormRow columnCount={2}>
                                <FormColumn>
                                    <Textbox
                                        disabled={isUploadingArtifact}
                                        id="txtPrimaryColor"
                                        name={FieldNames.PrimaryColor}
                                        required
                                        validation={$validation.primaryColor}
                                        value={school.primaryColor || ''}
                                        {...$field('primaryColor', event => handleTextChange(handleChange, event))}
                                    />
                                    <ColorPicker
                                        className="tenant-details-form__color-preview"
                                        color={school.primaryColor}
                                        handleChange={(color) => handleColorPickerChange(FieldNames.PrimaryColor, color)}
                                        handleDone={() => setShowPrimaryColorPicker(false)}
                                        show={showPrimaryColorPicker}
                                    />
                                </FormColumn>
                                <FormColumn>
                                    <div
                                        className="tenant-details-form__color-preview"
                                        onClick={() => setShowPrimaryColorPicker(true)}
                                        style={{backgroundColor: school.primaryColor}}
                                    />
                                </FormColumn>
                            </FormRow>
                        </FormColumn>

                        <FormColumn>
                            <Label htmlFor="txtSecondaryColor">
                                Secondary Color <span className="c-label__required-asterisk">*</span>
                            </Label>
                            <FormRow columnCount={2}>
                                <FormColumn>
                                    <Textbox
                                        disabled={isUploadingArtifact}
                                        id="txtSecondaryColor"
                                        name={FieldNames.SecondaryColor}
                                        required
                                        validation={$validation.secondaryColor}
                                        value={school.secondaryColor || ''}
                                        {...$field('secondaryColor', event => handleTextChange(handleChange, event))}
                                    />
                                    <ColorPicker
                                        className="tenant-details-form__color-picker"
                                        color={school.secondaryColor}
                                        handleChange={(color) => handleColorPickerChange(FieldNames.SecondaryColor, color)}
                                        handleDone={() => setShowSecondaryColorPicker(false)}
                                        show={showSecondaryColorPicker}
                                    />
                                </FormColumn>
                                <FormColumn>
                                    <div
                                        className="tenant-details-form__color-preview"
                                        onClick={() => setShowSecondaryColorPicker(true)}
                                        style={{backgroundColor: school.secondaryColor}}
                                    />
                                </FormColumn>
                            </FormRow>
                        </FormColumn>
                    </FormRow>

                    <FormRow>
                        <Label>
                            Text Color <span className="c-label__required-asterisk">*</span>
                        </Label>
                        <RadioGroup horizontal={true}>
                            <Radio
                                label="Light"
                                checked={school.textColor === '#ffffff'}
                                id="light"
                                name={FieldNames.TextColor}
                                onChange={() => handleChange(FieldNames.TextColor, '#ffffff')}
                                value="#ffffff"
                            />

                            <Radio
                                label="Dark"
                                checked={school.textColor === '#000000'}
                                id="dark"
                                name={FieldNames.TextColor}
                                onChange={() => handleChange(FieldNames.TextColor, '#000000')}
                                value="#000000"
                            />

                        </RadioGroup>
                        {$validation.textColor.isValid === false && $validation.textColor.show === true ? (
                            <FormValidationMessage>
                                {$validation.textColor.error.reason}
                            </FormValidationMessage>
                        ) : null}
                    </FormRow>

                    <FormRow columnCount={4}>
                        <FormColumn>
                            <Checkbox
                                checked={school.studentActive}
                                id="chkStudentActive"
                                label="Student Functionality Active"
                                name={FieldNames.StudentActive}
                                onChange={event => handleChange(FieldNames.StudentActive, event.target.checked)}
                            />
                        </FormColumn>

                        <FormColumn>
                            <Checkbox
                                checked={school.admitActive}
                                id="chkAdmitActive"
                                label="Admit Functionality Active"
                                name={FieldNames.AdmitActive}
                                onChange={event => handleChange(FieldNames.AdmitActive, event.target.checked)}
                            />
                        </FormColumn>

                        <FormColumn>
                            <Checkbox
                                checked={school.alumniActive}
                                id="chkAlumniActive"
                                label="Alumni Functionality Active"
                                name={FieldNames.AlumniActive}
                                onChange={event => handleChange(FieldNames.AlumniActive, event.target.checked)}
                            />
                        </FormColumn>
                    </FormRow>

                    {school.admitActive && (
                        <FormRow>
                            <Textbox
                                id="txtWelcomeMessage"
                                label="Admit Welcome Message"
                                name={FieldNames.AdmitWelcomeMessage}
                                type="textarea"
                                validation={$validation.admitWelcomeMessage}
                                value={school.admitWelcomeMessage || ''}
                                {...$field('admitWelcomeMessage', event => handleTextChange(handleChange, event))}
                            />
                        </FormRow>
                    )}

                    {school.hoursOfOperation && (
                        <FormRow>
                            <Label>
                                Hours of Operation
                            </Label>
                            {Object.values(DayOfWeek).map((day) => {
                                console.log(`${day}EndTime`)
                                return (
                                    <FormRow key={day}>
                                        <FormColumn>
                                            <Label
                                                className="tenant-details-form__form-item"
                                            >
                                                {day.charAt(0).toUpperCase() + day.slice(1)}
                                                <Button
                                                    className="tenant-details-form__form-item-button"
                                                    key={activeSchool.tenantId}
                                                    onClick={() => handleChange(FieldNames.HoursOfOperation, {day, remove: true})}
                                                    noStyles
                                                >
                                                    <Icon
                                                        className="tenant-details-form__form-item-button-icon"
                                                        type="trash"
                                                    />
                                                </Button>
                                            </Label>
                                        </FormColumn>
                                        <FormRow columnCount={2}>
                                            <FormColumn>
                                                <Datepicker
                                                    dateFormat="h:mm aa"
                                                    id={`${day}Start`}
                                                    label="Open Time"
                                                    name={`${day}Start`}
                                                    placeholderText={school.hoursOfOperation[day]?.startTime || 'None'}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    minDate={moment().startOf('day').add('6', 'hours').toDate()}
                                                    onChange={(date) => handleChange(FieldNames.HoursOfOperation, {day, startTime: date})}
                                                    validation={$validation[`${day}StartTime`]}
                                                />
                                            </FormColumn>

                                            <FormColumn>
                                                <Datepicker
                                                    dateFormat="h:mm aa"
                                                    id={`${day}End`}
                                                    label="Close Time"
                                                    name={`${day}End`}
                                                    placeholderText={school.hoursOfOperation[day]?.endTime || 'None'}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    minDate={moment().endOf('day').subtract('6', 'hours').toDate()}
                                                    onChange={(date) => handleChange(FieldNames.HoursOfOperation, {day, endTime: date})}
                                                    validation={$validation[`${day}EndTime`]}
                                                />
                                            </FormColumn>
                                        </FormRow>
                                    </FormRow>
                                )}
                            )}
                        </FormRow>
                    )}

                    <ButtonRow>
                        <Button
                            disabled={isUploadingArtifact}
                            onClick={(event) => {
                                event.preventDefault();
                                $submit(() => handleSave(true), () => handleSave(false));
                            }}
                            showActivityIndicator={isSavingSchool}
                            type={ButtonTypes.Button}
                        >
                            Save
                        </Button>
                    </ButtonRow>

                    <SuccessModal
                        buttonOnClick={() => {
                            dispatch(getSchool({schoolId: activeSchool.tenantId}));
                            setShowSuccessModal(false);
                        }}
                        show={showSuccessModal}
                    >
                        <p>
                            School details saved successfully.
                        </p>
                    </SuccessModal>

                </Form>
            </div>
        </div>
    )
}

function createEditSchoolDetailsFormValidationConfig(props) {
    let { artifact, artifactId, coverPhotoArtifact, coverPhotoArtifactId, primaryColor, secondaryColor, textColor, admitWelcomeMessage, admitActive, name, hoursOfOperation }: Tenant = props.school;

    const hoursValidations = {};
    const hoursFields = [];

    Object.values(DayOfWeek).forEach((day) => {
        hoursValidations[`${day}StartTime`] = [
            [isRequiredIfTrue, hoursOfOperation?.[day]?.startTime, !!hoursOfOperation?.[day]?.endTime, 'A open time is required if an close time is selected'],
        ];

        hoursValidations[`${day}EndTime`] = [
            [isRequiredIfTrue, hoursOfOperation?.[day]?.endTime, !!hoursOfOperation?.[day]?.startTime, 'A close time is required if an open time is selected'],
        ];

        hoursFields.push(`${day}StartTime`);
        hoursFields.push(`${day}EndTime`);
    })

    return {
        fields: ['artifactId', 'coverPhotoArtifactId', 'name', 'primaryColor', 'secondaryColor', 'textColor', 'studentWelcomeMessage', 'alumniWelcomeMessage', 'admitWelcomeMessage', ...hoursFields],
        validations: {
            admitWelcomeMessage: [
                [isRequiredIfTrue, admitWelcomeMessage, admitActive],
            ],
            artifactId: [
                [isRequiredIfTrue, artifactId, isObjectNullOrEmpty(artifact)],
            ],
            coverPhotoArtifactId: [
                [isRequiredIfTrue, coverPhotoArtifactId, isObjectNullOrEmpty(coverPhotoArtifact)],
            ],
            hoursOfOperation: [
                [isRequiredIfTrue, hoursOfOperation],
            ],
            name: [
                [isRequired, name],
            ],
            primaryColor: [
                [isRequired, primaryColor],
            ],
            secondaryColor: [
                [isRequired, secondaryColor],
            ],
            textColor: [
                [isRequired, textColor],
            ],
            ...hoursValidations
        },
    };
}

export default validated(createEditSchoolDetailsFormValidationConfig)(EditSchoolDetailsForm);
