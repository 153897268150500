import './SuccessModal.scss';

import React from 'react';
import Modal from "../index";
import H5 from "../../H5";
import Icon from "../../Icon";
import Button from "../../Button";

type Props = {
    buttonOnClick: Function
    buttonText?: string
    children?: React.ReactNode
    show: boolean
    title?: string
}

const SuccessModal: React.FC<Props> = ({
    buttonOnClick,
    buttonText = 'Done',
    children,
    show,
    title = 'Nice job!'
}) => {
    return (
        <Modal
            declineButtonOnClick={buttonOnClick}
            className="c-success-modal"
            show={show}
        >
            <div className="c-success-modal__icon-container">
                <Icon type="check" />
            </div>

            <H5 className="c-success-modal__title">
                {title}
            </H5>

            <div className="c-success-modal__content">
                {children}
            </div>

            <div className="c-success-modal__button-container">
                <Button
                    onClick={buttonOnClick}
                >
                    {buttonText}
                </Button>
            </div>
        </Modal>
    );
};

export default SuccessModal;
