import React from 'react';

import { PostStatuses } from "../../../../../utils/enums";

import { deleteThread, setThread } from "../../../../../store/slices/threads";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import TableMoreMenu from "../../../../../components/Table/TableMoreMenu";
import Thread from "../../../../../types/Thread";

type Props = {
    getAfterDelete?: () => void
    item: Thread
    setShowNewContentModal: Function
}

const ThreadTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    item,
    setShowNewContentModal,
}) => {
    const dispatch = useAppDispatch();

    const { isDeletingThread } = useTypedSelector((state) => state.threads);
    const { activeSchool } = useTypedSelector((state) => state.schools);

    const isMyThread = item.profile?.profileId === activeSchool?.postAsProfile?.profileId;

    const handleDelete = async (thread) => {
        try {
            await dispatch(deleteThread({thread})).unwrap();

            if(getAfterDelete) {
                getAfterDelete();
            }
        } catch(err) {
            console.log('ThreadTableMoreMenu handleDelete err', err);
        }
    };

    const handleEditThread = (thread) => {
        dispatch(setThread(thread));
        setShowNewContentModal(true);
    };

    return (
        <TableMoreMenu
            isDeleting={isDeletingThread}
            item={item}
            getAfterModerate={getAfterDelete}
            onDeleteClick={() => handleDelete(item)}
            onEditClick={isMyThread ? () => handleEditThread(item) : null}
            showAttachToPushNotificationOption={item.publishedAt != null || item.status === PostStatuses.Active}
        />
    );
};

export default ThreadTableMoreMenu;
