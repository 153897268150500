import React from "react";

import moment from "moment";

import Card from "../../../../../../../components/Card";
import {DetailModalProps} from "../../index";
import FormRow from "../../../../../../../components/FormRow";
import FormColumn from "../../../../../../../components/FormColumn";
import H6 from "../../../../../../../components/H6";

const MostActiveSchoolDetailModal: React.FC<DetailModalProps> = ({data}) => {
    const formatDate = (date: Date) => {
        return moment(date).format('MMMM DD, YYYY');
    };

    return (
        <>
            <Card>
                <H6 className='dashboard-card__detail-main-col'>
                    {data?.tenant?.name}
                </H6>

                <FormRow>
                    <div className='dashboard-card__detail-row-muted'>
                        Launched
                    </div>

                    {data.tenant?.launchDate ? formatDate(data.tenant.launchDate) : 'Not Launched'}
                </FormRow>
            </Card>
            <Card>
                <H6>
                    Details
                </H6>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Active Profiles
                        </div>

                        {data.activeProfileCount}
                    </FormColumn>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Location
                        </div>

                        {data.tenant.city + ', ' + data.tenant.state}
                    </FormColumn>
                </FormRow>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Groups
                        </div>

                        {data.forumTopicCount}
                    </FormColumn>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Notifications
                        </div>

                        {data.notificationCount}
                    </FormColumn>
                </FormRow>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Events
                        </div>

                        {data.eventCount}
                    </FormColumn>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Deals
                        </div>

                        {data.dealCount}
                    </FormColumn>
                </FormRow>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Threads
                        </div>

                        {data.forumTopicCount}
                    </FormColumn>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Items
                        </div>

                        {data.itemCount}
                    </FormColumn>
                </FormRow>
            </Card>
        </>
    )
};

export default MostActiveSchoolDetailModal;
