import React, {useEffect, useState} from "react";

import {setAllDetailModals} from "../../../../../store/slices/superAdminDashboard";
import {useTypedSelector} from "../../../../../store/reducers";
import {useAppDispatch} from "../../../../../store";

import {CardType} from "../../index";
import Modal from "../../../../../components/Modal";
import MostActiveGroupDetailModal from "./components/MostActiveGroupDetailModal";
import MostActiveSchoolDetailModal from "./components/MostActiveSchoolDetailModal";
import MostActiveUserDetailModal from "./components/MostActiveUserDetailModal";
import PushNotificationDetailModal from "./components/PushNotificationDetailModal";
import RecentlyJoinedUserDetailModal from "./components/RecentlyJoinedUserDetailModal";
import TopDealDetailModal from "./components/TopDealDetailModal";
import TopEventDetailModal from "./components/TopEventDetailModal";
import TopItemDetailModal from "./components/TopItemDetailModal";
import TopThreadDetailModal from "./components/TopThreadDetailModal";
import LiveNowDetailModal from "./components/LiveNowDetailModal";
import TopDmUsersDetailModal from "./components/TopDmUsersDetailModal";
import TopDmSchoolsDetailModal from "./components/TopDmSchoolsDetailModal";

export type DetailModalProps = {
    data: any
}

type Props = {
    type: CardType,
    data: any,
}

const DashboardCardModal: React.FC<Props> = ({type, data}) => {
    const dispatch = useAppDispatch();

    const [cardData, setCardData] = useState(null);
    const [cardType, setCardType] = useState(null);

    const {
        showMostActiveGroupsDetailModal,
        showMostActiveSchoolsDetailModal,
        showMostActiveUsersDetailModal,
        showPushNotificationsDetailModal,
        showRecentlyJoinedUsersDetailModal,
        showTopEventsDetailModal,
        showTopItemsDetailModal,
        showTopDealsDetailModal,
        showTopThreadsDetailModal,
        showLiveNowDetailModal,
        showTopDmUsersDetailModal,
        showTopDmSchoolsDetailModal
    } = useTypedSelector(state => state.superAdminDashboard);

    useEffect(() => {
        setCardData(data);
        setCardType(type);
    }, [data, type]);

    const renderChildComponentByType = (type: CardType, data: any) => {
        switch (type) {
            case CardType.LiveNow:
                return <LiveNowDetailModal data={data} />
            case CardType.MostActiveGroups:
                return <MostActiveGroupDetailModal data={data}/>
            case CardType.MostActiveSchools:
                return <MostActiveSchoolDetailModal data={data}/>;
            case CardType.MostActiveUsers:
                return <MostActiveUserDetailModal data={data}/>;
            case CardType.RecentlyJoinedUsers:
                return <RecentlyJoinedUserDetailModal data={data}/>;
            case CardType.PushNotifications:
                return <PushNotificationDetailModal data={data}/>;
            case CardType.TopDmUsers:
                return <TopDmUsersDetailModal data={data}/>;
            case CardType.TopDmSchools:
                return <TopDmSchoolsDetailModal data={data}/>;
                case CardType.TopDeals:
                return <TopDealDetailModal data={data}/>;
            case CardType.TopEvents:
                return <TopEventDetailModal data={data}/>;
            case CardType.TopItems:
                return <TopItemDetailModal data={data}/>;
            case CardType.TopThreads:
                return <TopThreadDetailModal data={data}/>;
            default:
                return <></>;
        }
    };

    const formatCardTypeToHumanReadableFormat = (cardType: CardType) => {
        switch (cardType) {
            case CardType.TopDmSchools:
                return 'Top DM Schools';
            case CardType.TopDmUsers:
                return 'Top DM Users';
            case CardType.LiveNow:
                return 'Live Now Details';
            case CardType.MostActiveSchools:
                return 'School Details'
            case CardType.MostActiveGroups:
                return 'Group Details'
            case CardType.MostActiveUsers:
            case CardType.RecentlyJoinedUsers:
                return 'User Details'
            case CardType.PushNotifications:
                return 'Notification Details'
            case CardType.TopDeals:
                return 'Deal Details'
            case CardType.TopEvents:
                return 'Event Details'
            case CardType.TopItems:
                return 'Item Details'
            case CardType.TopThreads:
                return 'Thread Details'
            default:
                return '';
        }
    };

    const determineModalToShowByType = (cardType: CardType) => {
        switch (cardType) {
            case CardType.LiveNow:
                return showLiveNowDetailModal;
            case CardType.MostActiveGroups:
                return showMostActiveGroupsDetailModal;
            case CardType.MostActiveSchools:
                return showMostActiveSchoolsDetailModal;
            case CardType.MostActiveUsers:
                return showMostActiveUsersDetailModal;
            case CardType.PushNotifications:
                return showPushNotificationsDetailModal;
            case CardType.RecentlyJoinedUsers:
                return showRecentlyJoinedUsersDetailModal;
            case CardType.TopDmSchools:
                return showTopDmSchoolsDetailModal;
            case CardType.TopDmUsers:
                return showTopDmUsersDetailModal;
            case CardType.TopDeals:
                return showTopDealsDetailModal;
            case CardType.TopEvents:
                return showTopEventsDetailModal;
            case CardType.TopItems:
                return showTopItemsDetailModal;
            case CardType.TopThreads:
                return showTopThreadsDetailModal;
            default:
                return false;
        }
    };

    const handleClick = () => {
        dispatch(setAllDetailModals(false))
    };

    return (
        <Modal
            className='dashboard-card__detail-modal'
            declineButtonOnClick={() => handleClick()}
            declineButtonText="OK"
            show={determineModalToShowByType(cardType)}
            title={formatCardTypeToHumanReadableFormat(cardType)}
        >
            {renderChildComponentByType(cardType, cardData)}
        </Modal>
    )
};

export default DashboardCardModal;
