import React, { useEffect, useRef, useState } from 'react';
import { validated } from 'react-custom-validation';
import clone from 'clone';

import ClassNote from '../../../../types/ClassNote';
import Validation from '../../../../types/Validation';

import { isRequired } from '../../../../utils/validations';
import { buildArtifactIdsArray } from '../../../../utils/images';
import { handleTextChange } from '../../../../utils/handle-changes';

import {
    clearClassNote,
    deleteClassNote, getClassNote,
    getClassNotes,
    saveClassNote,
    setClassNote,
} from '../../../../store/slices/classNotes';
import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';

import Button, { ButtonThemes, ButtonTypes } from '../../../../components/Button';
import ButtonRow from '../../../../components/ButtonRow';
import FileUpload from '../../../../components/FileUpload';
import Form from '../../../../components/Form';

import FormRow from '../../../../components/FormRow';
import FormValidationMessage from '../../../../components/FormValidationMessage';
import H4 from '../../../../components/H4';
import Modal from '../../../../components/Modal';
import PostingAsCard from '../../../../components/PostingAsCard';
import SuccessModal from '../../../../components/Modal/SuccessModal';
import Textbox from '../../../../components/Textbox';

type Props = {
    close?: Function
    classNote: ClassNote
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        artifactIds: Validation
        description: Validation
    }
}

const CreateEditClassNoteForm: React.FC<Props> = ({
                                                      close,
                                                      classNote,
                                                      $field,
                                                      $fieldEvent,
                                                      $submit,
                                                      $validation,
                                                  }) => {
    const dispatch = useAppDispatch();

    const locationRef = useRef<any>(null);

    const {profile: authUser} = useTypedSelector((state) => state.auth);
    const {activeSchool} = useTypedSelector((state) => state.schools);
    const {isDeletingClassNote, isSavingClassNote} = useTypedSelector((state) => state.classNotes);
    const [isUploadingArtifact, setIsUploadingArtifact] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    useEffect(() => {
        if (!classNote.artifactIds && classNote.artifacts) {
            let artifactIds = buildArtifactIdsArray(classNote);
            handleChange('artifactIds', artifactIds);
        }

        return () => {
            setTimeout(() => dispatch(clearClassNote()), 500);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name: string, value: any) => {
        let clonedClassNote = clone(classNote);

        if (name === 'status') {
            clonedClassNote.status = value;

            if (value === 'A') {
                delete clonedClassNote.publishAt;
            }
        } else if (name === 'location' && clonedClassNote.location?.artifactId) {
            value.artifactId = clonedClassNote.location.artifactId;
            clonedClassNote.location = value;
        } else {
            clonedClassNote[name] = value;
        }

        dispatch(setClassNote(clonedClassNote));
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteClassNote({})).unwrap();
            dispatch(getClassNote({}));
            if (locationRef?.current?.refs?.input) {
                locationRef.current.refs.input.value = '';
            }
            $fieldEvent('reset');
            close && close();
        } catch (err) {
            console.log('CreateEditClassNote handleDelete err', err);
        } finally {
            setShowDeleteConfirmationModal(false);
        }

        console.log('done');
    };

    const handleSave = async (isValid: boolean) => {
        if (isValid) {
            try {
                await dispatch(saveClassNote({})).unwrap();
                setShowSuccessModal(true);
                if (locationRef?.current?.refs?.input) {
                    locationRef.current.refs.input.value = '';
                }
                $fieldEvent('reset');
            } catch (err) {
                console.log('CreateEditClassNote handleSave err', err);
            }
        }
    };

    const onUploadStart = () => {
        setIsUploadingArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange('artifactIds', [artifactId]);
        }
        setIsUploadingArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingArtifact(false);
    };

    const onDelete = () => {
        handleChange('artifactIds', []);
    };

    return (
        <Form className="content-form">
            <H4>
                {classNote.postId ? 'Edit ' : 'Create a '}Class Note
            </H4>

            <FormRow>
                <PostingAsCard
                    profile={classNote.profile || activeSchool.postAsProfile}
                    user={!classNote.profile || classNote.profile === activeSchool.postAsProfile ? authUser : null}
                />
            </FormRow>

            <FormRow>
                <FileUpload
                    acceptsVideo={false}
                    currentArtifact={classNote.artifacts?.[0] || null}
                    disabled={isUploadingArtifact}
                    name="artifactIds"
                    onDelete={onDelete}
                    onStart={onUploadStart}
                    onSuccess={onUploadSuccess}
                    onFailure={onUploadFailure}
                />
                {$validation.artifactIds.isValid === false && $validation.artifactIds.show === true ? (
                    <FormValidationMessage>
                        {$validation.artifactIds.error.reason}
                    </FormValidationMessage>
                ) : null}
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingArtifact}
                    id="txtTitle"
                    label="Title"
                    name="title"
                    required
                    type="text"
                    value={classNote.title || ''}
                    {...$field('title', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingArtifact}
                    id="txtDescription"
                    label="Note"
                    name="description"
                    required
                    type="textarea"
                    validation={$validation.description}
                    value={classNote.description || ''}
                    {...$field('description', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <ButtonRow>
                <Button
                    disabled={isUploadingArtifact}
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                    showActivityIndicator={isSavingClassNote}
                    type={ButtonTypes.Submit}
                >
                    Save
                </Button>

                {classNote.postId && (
                    <Button
                        className="destructive"
                        disabled={isUploadingArtifact || isSavingClassNote}
                        onClick={() => setShowDeleteConfirmationModal(true)}
                        theme={ButtonThemes.Link}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getClassNotes({}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
            >
                <p>
                    Your class note has been saved.
                </p>
                <p>
                    It may take a minute for your class note to show in feeds and lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingClassNote}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this class note?
            </Modal>
        </Form>
    );
};

function createEditClassNoteFormValidationConfig(props) {
    let {artifactIds, description, title}: ClassNote = props.classNote;

    return {
        fields: ['artifactIds', 'description'],
        validations: {
            artifactIds: [
                [isRequired, artifactIds],
            ],
            description: [
                [isRequired, description],
            ]
        },
    };
}

export default validated(createEditClassNoteFormValidationConfig)(CreateEditClassNoteForm);
