import React, {useEffect, useState} from "react";

import moment from "moment";

import Card from "../../../../../../../components/Card";
import H6 from "../../../../../../../components/H6";
import Avatar from "../../../../../../../components/Avatar";
import FormRow from "../../../../../../../components/FormRow";
import FormColumn from "../../../../../../../components/FormColumn";
import {DetailModalProps} from "../../index";

const PushNotificationDetailModal: React.FC<DetailModalProps> = ({data}) => {
    const [group, setGroup] = useState(null);
    const [post, setPost] = useState(null);

    useEffect(() => {
        if (data.notification.forumTopic) {
            setGroup(data.notification.forumTopic)
        }
        if (data.notification.post) {
            setPost(data.notification.post)
        }
    }, [data]);

    const formatDate = (date: Date) => {
        return moment(date).format('MMMM DD, YYYY')
    };

    return (
        <>
            <Card>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Title
                        </div>

                        <H6>
                            {data.notification.title || 'None'}
                        </H6>
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Description
                        </div>

                        <H6>
                            {data.notification.message}
                        </H6>
                    </FormColumn>
                </FormRow>
                <FormRow columnCount={3}>
                    <FormColumn>
                        <div>
                            <div className='dashboard-card__detail-row-muted'>
                                Sent
                            </div>

                            <div className='dashboard-card__detail-row'>
                                {formatDate(data.notification.sentAt)}
                            </div>
                        </div>
                    </FormColumn>

                    <FormColumn>
                        <div>
                            <div className='dashboard-card__detail-row-muted'>
                                School
                            </div>

                            <div className='dashboard-card__detail-row'>
                                {data.notification?.tenant?.name || 'Unknown'}
                            </div>
                        </div>
                    </FormColumn>

                    <FormColumn>
                        <div>
                            <div className='dashboard-card__detail-row-muted'>
                                Destination
                            </div>

                            <div className='dashboard-card__detail-row'>
                                {data.notification.destination}
                            </div>
                        </div>
                    </FormColumn>
                </FormRow>
            </Card> {group && (
            <Card>
                <H6>
                    Content
                </H6>
                <FormRow>
                    <div className='dashboard-card__detail-main-col'>
                        <H6>
                            {group.name}
                        </H6>

                        <Avatar artifact={group.artifact} />
                    </div>
                </FormRow>
                <div>
                    <div className='dashboard-card__detail-row-muted'>
                        Description
                    </div>

                    <div className='dashboard-card__detail-row'>
                        {group.description || 'Unknown'}
                    </div>
                </div>
            </Card>
        )} {post && (
            <Card>
                <H6>
                    Content
                </H6>
                <FormRow>
                    <div className='dashboard-card__detail-main-col'>
                        <H6>
                            {post.title}
                        </H6>

                        <Avatar artifact={post.artifacts[0]} />
                    </div>
                </FormRow>
                <FormRow columnCount={2}>
                    <FormColumn>
                        <div>
                            <div className='dashboard-card__detail-row-muted'>
                                Description
                            </div>

                            <div className='dashboard-card__detail-row'>
                                {post.description || 'Unknown'}
                            </div>
                        </div>
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Published
                        </div>

                        <div className='dashboard-card__detail-row'>
                            {formatDate(post.publishedAt)}
                        </div>
                    </FormColumn>
                </FormRow>
            </Card>
        )}
            <FormRow columnCount={2}>
                <FormColumn>
                    <Card>
                        <H6>
                            Pushes Sent
                        </H6>

                        {data.notificationSentCount}
                    </Card>
                </FormColumn>

                <FormColumn>
                    <Card>
                        <H6>
                            Pushes Opened
                        </H6>

                        {data.notificationOpenedCount}
                    </Card>
                </FormColumn>
            </FormRow>
        </>
    )
};

export default PushNotificationDetailModal;
