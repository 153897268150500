import validator from 'validator';
import moment from 'moment';
import Linkify from 'linkify-it';

const linkify = new Linkify();
linkify.tlds(require('tlds'));

export const isEmail = (email?: string, errorMessage?: string) => {
    return email != null && email !== '' && validator.isEmail(email) ? null : errorMessage || `Please enter a valid email.`;
};

export const isEmailOrEmpty = (email?: string, errorMessage?: string) => {
    if(email == null || email === '') {
        return null;
    }
    return validator.isEmail(email) ? null : errorMessage || `Please enter a valid email.`;
};

export const isEmailArray = (emailArray?: string, errorMessage?: string) => {

    emailArray = emailArray && emailArray.length > 0 ? emailArray.replace(/\s/g, '') : '';

    const arrayOfEmails = emailArray.split(',').filter(email => email.length > 0);

    let invalidEmails = [];

    arrayOfEmails.forEach(email => {
        email = email.replace(/\s/g, '');

        if (!validator.isEmail(email)) {
            invalidEmails.push(email);
        }
    });

    if (invalidEmails.length > 0) {
        return `Invalid email address${invalidEmails.length > 1 ? 'es' : ''}: ${invalidEmails.join(', ')}`
    } else {
        return null;
    }
}

export const isEmailCsv = (emailCsv?: string, errorMessage?: string) => {

    // Remove spaces
    emailCsv = emailCsv.replace(/\s/g, '');

    // Split into an array with empty values removed
    const emailArray = emailCsv.split(',').filter(email => email.length > 0);

    // Build an array of invalid email addresses
    let invalidEmails = [];
    emailArray.forEach(email => {
        if (!validator.isEmail(email)) {
            invalidEmails.push(email);
        }
    });

    if (invalidEmails.length > 0) {
        return `Invalid email address${invalidEmails.length > 1 ? 'es' : ''}: ${invalidEmails.join(', ')}`
    } else {
        return null;
    }
};

export const isEmailIfTrue = (value?: string, booleanValue?: boolean, errorMessage?: string) => {
    if (booleanValue === true) {
        return isEmail(value, errorMessage);
    } else {
        return null;
    }
};

export const isRequired = (value?: string, errorMessage?: string) => {
    if (Array.isArray(value) === true && value.length === 0) {
        return errorMessage || 'This field is required.';
    }
    return value != null && value !== '' ? null : errorMessage || 'This field is required.';
};

export const isRequiredObject = (value?: Array<any> | any, errorMessage?: string) => {
    if (Array.isArray(value) === true && value.length === 0) {
        return errorMessage || 'This field is required.';
    }
    return value != null && value !== '' && Object.keys(value).length > 0 ? null : errorMessage || 'This field is required.';
};

export const isRequiredIfTrue = (value?: string, booleanValue?: boolean, errorMessage?: string) => {
    if (booleanValue === true) {
        if (Array.isArray(value) === true && value.length === 0) {
            return errorMessage || 'This field is required.';
        }

        if (Boolean(value) === false) {
            return errorMessage || 'This field is required.';
        }

        return value != null && value !== '' ? null : errorMessage || 'This field is required.';
    } else {
        return null;
    }
};

export const isRequiredIfOther = (value?: string | object, valueOfSelect?: string) => {
    if (valueOfSelect != null && valueOfSelect.toLowerCase() === 'other') {
        return validator.isEmpty(value) === false ? null : 'This field is required.';
    } else {
        return true;
    }
};

export const isRequiredIfSiblingNotNull = (value?: string | object, valueOfSibling?: any, nameOfSibling?: string) => {
    if (valueOfSibling != null) {
        return validator.isEmpty(value) === false ? null : `This field is required when ${nameOfSibling} has a value.`;
    } else {
        return null;
    }
};

export const isPhoneNumber = (value?: string) => {
    let onlyNumbers = '';
    if (value != null) {
        onlyNumbers = value.replace(/\D/g, '');
    }
    return value != null && (onlyNumbers.length === 9 || onlyNumbers.length === 10)
        ? null
        : `Please enter a valid phone number`;
};

export const isDate = (value?: string) => {
    return validator.isDate(new Date(value)) ? null : `Please enter a valid date.`;
};

export const isDateInFuture = (value?: string) => {
    return moment(value).isSameOrAfter(moment(), 'day') ? null : 'Please choose a date that is in the future.';
};

export const isDateAfterOtherDate = (value?: string, otherDate?: string, errorMessage?: string) => {
    return value == null || value === '' || moment(value).valueOf() > moment(otherDate).valueOf() ? null : errorMessage || 'Please choose a date later than the start date.';
}

export const isTrue = (value?: boolean, message?: string) => {
    return value === true ? null : message;
};

export const isEqual = (value1?: string | number | boolean, value2?: string | number | boolean, message?: string) => {
    return value1 === value2 ? null : message;
};

export const isGreaterThanOrEqualTo = (value1?: string | number, value2?: string | number, message?: string) => {
    return value1 >= value2 ? null : message;
};

export const isLessThan = (value1?: string | number, value2?: string | number, message?: string) => {
    return value1 < value2 ? null : message;
};

export const isStringShorterThan = (value?: string, maxLength?: number, errorMessage?: string) => {
    return value == null || value === '' || value.length <= maxLength
        ? null
        : errorMessage || `Shorten to ${maxLength} characters.`;
};

export const isStringLongerThan = (value?: string, minLength?: number, errorMessage?: string) => {
    return value == null || value === '' || value.length >= minLength
        ? null
        : errorMessage || `Lengthen to ${minLength} characters.`;
};

export const isStringNLong = (value?: string, lengthsArray?: Array<number>, errorMessage?: string) => {
    if (value == null || value === '') {
        return null;
    } else {
        let matches = false;

        lengthsArray.forEach(length => {
            if (value.length === length) {
                matches = true;
            }
        });

        return matches ? null : errorMessage || 'Match required length';
    }
};

export const isUrl = (value?: string, errorMessage?: string) => {
    if (errorMessage === null) {
        errorMessage = 'Please enter a valid URL.';
    }

    if (value === null || value === '' || value === undefined) {
        return 'Valid URL is required';
    }

    console.log('value', value);

    return linkify.test(value) ? null : errorMessage;
}
