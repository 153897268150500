import './FeedDeal.scss';

import React from 'react';

import buildImageSrc from '../../../utils/buildImageSrc';
import { REDEEM_FREQUENCY_LABELS } from '../../../utils/constants';

import Deal from "../../../types/Deal";

import Card from '../../Card';
import DealTableMoreMenu from "../../../containers/Schools/School/SchoolDeals/DealTableMoreMenu";
import FeedViewsRow from '../components/FeedViewsRow';

type Props = {
    className?: string
    onEdit?: Function
    post: Deal
}

const FeedDeal: React.FC<Props> = ({
    className = '',
    onEdit,
    post,
}) => {
    const renderImage = () => {
        const artifact = post.artifacts && post.artifacts[0] ? post.artifacts[0] : post.artifact;

        return (
            <div className="c-feed-deal__image" style={artifact ? { backgroundImage: `url(${buildImageSrc(artifact, '220')})` } : null} />
        )
    };

    return (
        <Card className={`c-feed-deal c-feed-item ${className}`}>
            <div className="c-feed-deal__header">
                {renderImage()}

                <div className="c-feed-deal__data c-feed-post__data">
                    {post.location && (
                        <div className="c-feed-deal__location c-feed-post__location">
                            {post.location.name || post.location.formattedAddress}
                        </div>
                    )}

                    <div className="c-feed-deal__title c-feed-post__title">
                        {post.title}
                    </div>

                    {post.redeem === 'Y' && (
                        <div className="c-feed-deal__redeem">
                            Redeem {post.redeemFrequency ? REDEEM_FREQUENCY_LABELS[post.redeemFrequency] : 'Once'}
                        </div>
                    )}

                    <FeedViewsRow viewCount={post.totalViews} saveCount={post.saveCount} />
                </div>

                {onEdit && (
                    <DealTableMoreMenu
                        item={post}
                        setShowNewContentModal={onEdit}
                    />
                )}
            </div>
        </Card>
    );
};

export default FeedDeal;
