import React, { useEffect, useState } from 'react';
import clone from 'clone';
import { validated } from "react-custom-validation";
import { useRouteMatch } from "react-router";

import Question from "../../../../../types/Question";
import Validation from "../../../../../types/Validation";

import { handleTextChange } from "../../../../../utils/handle-changes";
import { isRequired } from "../../../../../utils/validations";

import { clearQuestion, deleteQuestion, getQuestions, saveQuestion, setQuestion } from "../../../../../store/slices/faq";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import Button, { ButtonThemes, ButtonTypes } from "../../../../../components/Button";
import ButtonRow from "../../../../../components/ButtonRow";
import Form from "../../../../../components/Form";
import FormRow from "../../../../../components/FormRow";
import H4 from "../../../../../components/H4";
import Modal from "../../../../../components/Modal";
import ProfileTypesSelector from "../../../../../components/ProfileTypesSelector";
import SuccessModal from "../../../../../components/Modal/SuccessModal";
import Textbox from "../../../../../components/Textbox";

type Props = {
    close: () => void
    question: Question
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        answer: Validation
        question: Validation
    }
}

const CreateEditFAQForm: React.FC<Props> = ({
    close,
    question,
    $field,
    $fieldEvent,
    $submit,
    $validation,
}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

    const { isDeletingQuestion, isSavingQuestion } = useTypedSelector((state) => state.faq);

    useEffect(() => {
        if(!question.tenantFaqId) {
            handleChange(`${params.profileType}Visibility`, true);
        }

        return () => {
            setTimeout(() => dispatch(clearQuestion()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name: string, value: any) => {
        let clonedQuestion = clone(question);
        clonedQuestion[name] = value;
        dispatch(setQuestion(clonedQuestion));
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteQuestion({})).unwrap();
            $fieldEvent('reset');
            dispatch(getQuestions({profileType: params.profileType}));
            close && close();
        } catch (err) {
            console.log('CreateEditFAQForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleSave = async (isValid: boolean) => {
        if(!isValid) {
            return;
        }

        try {
            await dispatch(saveQuestion({})).unwrap();
            setShowSuccessModal(true);
            $fieldEvent('reset');
        } catch(err) {
            console.log('CreateEditFAQForm handleSave err', err);
        }
    };

    return (
        <Form>
            <H4>
                {question.tenantFaqId ? 'Edit ' : 'Create '}Question
            </H4>

            <FormRow>
                <Textbox
                    id="txtQuestion"
                    label="Question"
                    name="question"
                    required
                    type="text"
                    validation={$validation.question}
                    value={question.question || ''}
                    {...$field('question', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    className="faq__answer"
                    id="txtAnswer"
                    label="Answer"
                    name="answer"
                    required
                    type="textarea"
                    validation={$validation.answer}
                    value={question.answer || ''}
                    {...$field('answer', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <ProfileTypesSelector item={question} onChange={handleChange} />
            </FormRow>

            <ButtonRow>
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                    showActivityIndicator={isSavingQuestion}
                    type={ButtonTypes.Submit}
                >
                    {question.tenantFaqId ? 'Save' : 'Create'} Question
                </Button>

                {question.tenantFaqId && (
                    <Button
                        className="destructive"
                        disabled={isSavingQuestion}
                        onClick={() => setShowDeleteConfirmationModal(true)}
                        theme={ButtonThemes.Link}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getQuestions({profileType: params.profileType}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
                title="Nice job!"
            >
                <p>
                    Your question has been saved.
                </p>
                <p>
                    It may take a minute for your question to show in app and admin lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingQuestion}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this question?
            </Modal>
        </Form>
    );
};

function createEditFAQFormValidationConfig(props) {
    let { answer, question }: Question = props.question;

    return {
        fields: ['answer', 'question'],
        validations: {
            answer: [
                [isRequired, answer]
            ],
            question: [
                [isRequired, question]
            ],
        }
    }
}
export default validated(createEditFAQFormValidationConfig)(CreateEditFAQForm);
