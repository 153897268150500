import './FAQ.scss';

import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from "react-beautiful-dnd";
import { useRouteMatch } from "react-router";
import { useMediaQuery } from "react-responsive";

import Question from "../../../../types/Question";

import { isArrayNullOrEmpty } from "../../../../utils/utils";

import {
    clearQuestion,
    deleteQuestion,
    getQuestions,
    reorderQuestions,
    setQuestion,
    setQuestions
} from "../../../../store/slices/faq";
import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import Button, { ButtonSizes, ButtonThemes } from "../../../../components/Button";
import Card from "../../../../components/Card";
import CreateEditFAQ from "./CreateEditFAQ";
import H5 from "../../../../components/H5";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import NewContentModal from "../../../../components/NewContentModal";
import PageHeader from "../../../../components/PageHeader";

const FAQ: React.FC = () => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
    const [showNewContentModal, setShowNewContentModal] = useState<boolean>(false);

    const { isDeletingQuestion, isGettingQuestions, questions } = useTypedSelector((state) => state.faq);

    const isMobile = useMediaQuery({ query: '(max-width: 47.5em)' });

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getQuestions({ profileType: params.profileType, schoolId: params.schoolId }));
            } catch(err) {
                console.log('FAQ initialize err', err);
            } finally {
                setInitialized(true);
            }
        };

        initialize();

        return () => {
            dispatch(clearQuestion());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDelete = async () => {
        try {
            await dispatch(deleteQuestion({})).unwrap();
            dispatch(getQuestions({profileType: params.profileType}));
        } catch (err) {
            console.log('FAQ handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleEditClick = (q: Question) => {
        dispatch(setQuestion(q));
        setShowNewContentModal(true);
    };

    const handleSaveNewOrder = async (reorderedQuestions) => {
        try {
            const newOrder = reorderedQuestions.map((q) => q.tenantFaqId);
            await dispatch(reorderQuestions({profileType: params.profileType, questionIds: newOrder}));
        } catch(err) {
            console.log('FAQ handleSaveNewOrder err', err);
        }
    };

    const onDragEnd = (result: OnDragEndResponder) => {
        // a little function to help us with reordering the result
        const reorder = (list: Array<Question>, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedQuestions: Array<Question> = reorder(
            questions,
            result.source.index,
            result.destination.index,
        );

        dispatch(setQuestions(reorderedQuestions));
        handleSaveNewOrder(reorderedQuestions);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className="faq">
                <PageHeader>
                    <H5>
                        <Icon type="faq" />

                        {isMobile ? 'FAQ' : 'Frequently Asked Questions'}
                    </H5>

                    <Button
                        onClick={() => setShowNewContentModal(true)}
                        size={ButtonSizes.Small}
                    >
                        New Question
                    </Button>
                </PageHeader>

                {isGettingQuestions || !initialized ? (
                    <ActivityIndicator size="large" />
                ) : (
                    <>
                        {isArrayNullOrEmpty(questions) && initialized ? (
                            <>
                                    No Frequently Asked Questions to display. <Button
                                    onClick={() => setShowNewContentModal(true)}
                                    theme={ButtonThemes.Link}
                                >
                                    Create One?
                                </Button>
                            </>
                        ) : (
                            <Card className="faq__questions-container">
                                <Droppable droppableId="faq-list">
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {questions.map((q, i) => (
                                                <Draggable
                                                    draggableId={q.tenantFaqId.toString()}
                                                    index={i}
                                                    key={q.tenantFaqId}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className="faq__question"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Icon type="drag" />

                                                            <div className="faq__content">
                                                                <div className="faq__question-text">
                                                                    {q.question}
                                                                </div>

                                                                <div className="faq__answer-text">
                                                                    {q.answer}
                                                                </div>
                                                            </div>

                                                            <div className="faq__question-actions">
                                                                <Button
                                                                    onClick={() => handleEditClick(q)}
                                                                    size={ButtonSizes.Small}
                                                                >
                                                                    Edit
                                                                </Button>

                                                                <Button
                                                                    onClick={() => {
                                                                        dispatch(setQuestion(q));
                                                                        setShowDeleteConfirmationModal(true);
                                                                    }}
                                                                    size={ButtonSizes.Small}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </Card>
                        )}
                    </>
                )}

                <NewContentModal
                    close={() => setShowNewContentModal(false)}
                    show={showNewContentModal}
                >
                    <CreateEditFAQ
                        close={() => setShowNewContentModal(false)}
                    />
                </NewContentModal>

                <Modal
                    title="Confirm Delete"
                    show={showDeleteConfirmationModal}
                    confirmButtonOnClick={handleDelete}
                    confirmButtonText="Yes, Delete"
                    showActivityIndicator={isDeletingQuestion}
                    declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                    declineButtonText="Cancel"
                >
                    Are you sure you want to delete this question?
                </Modal>
            </div>
        </DragDropContext>
    );
};

export default FAQ;
