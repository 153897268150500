import './InviteStudentsViaEmail.scss';

import React from 'react';

import { ProfileNounLookup } from "../../ListStudents";

import { useTypedSelector } from "../../../../../../store/reducers";

import InviteStudentsViaEmailForm from "./InviteStudentsViaEmailForm";

type Props = {
    closeModal: Function
    noun: ProfileNounLookup
    onSuccess: Function
}

const InviteStudentsViaEmail: React.FC<Props> = (
    {
        closeModal,
        noun,
        onSuccess,
    }
) => {
    const { inviteStudentsViaEmailListCsv } = useTypedSelector((state) => state.students);

    return (
        <>
            <p>Enter a comma-separated list of email addresses to invite {noun.toLowerCase()} to create Abuzz accounts.</p>

            <InviteStudentsViaEmailForm
                onCancel={closeModal}
                emails={inviteStudentsViaEmailListCsv}
                onSuccess={onSuccess}
            />
        </>
    );
};

export default InviteStudentsViaEmail;
