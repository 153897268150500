import './EditSchoolDetails.scss';

import React from 'react';

import { useTypedSelector } from '../../../../store/reducers';

import EditSchoolDetailsForm from './EditSchoolDetailsForm';

const EditSchoolDetails: React.FC = () => {
    const { schoolToEdit } = useTypedSelector(state => state.schools);

    return (
        <EditSchoolDetailsForm
            school={schoolToEdit}
        />
    );
}

export default EditSchoolDetails;
