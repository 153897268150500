import React from 'react';

import { deleteMarketplaceItem, setMarketplaceItem } from "../../../../../store/slices/marketplaceItems";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import MarketplaceItem from "../../../../../types/MarketplaceItem";
import TableMoreMenu from "../../../../../components/Table/TableMoreMenu";

type Props = {
    getAfterDelete?: () => void
    item: MarketplaceItem
    setShowNewContentModal: Function
}

const MarketplaceItemTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    item,
    setShowNewContentModal,
}) => {
    const dispatch = useAppDispatch();

    const { isDeletingMarketplaceItem } = useTypedSelector((state) => state.marketplace);

    const handleDelete = async (marketplaceItem) => {
        try {
            await dispatch(deleteMarketplaceItem({marketplaceItem})).unwrap();

            if(getAfterDelete) {
                getAfterDelete();
            }
        } catch(err) {
            console.log('MarketplaceItemTableMoreMenu handleDelete err', err);
        }
    };

    const handleEditMarketplaceItem = (item) => {
        dispatch(setMarketplaceItem(item));
        setShowNewContentModal(true);
    };

    return (
        <TableMoreMenu
            isDeleting={isDeletingMarketplaceItem}
            item={item}
            getAfterModerate={getAfterDelete}
            onDeleteClick={() => handleDelete(item)}
            onEditClick={() => handleEditMarketplaceItem(item)}
            showAttachToPushNotificationOption={item.publishedAt != null || item.status === 'A'}
        />
    );
};

export default MarketplaceItemTableMoreMenu;
