import './AttachContentModal.scss';

import React from 'react';
import clone from 'clone';
import { useRouteMatch } from "react-router";

import { ProfileTypes } from "../../../../../../utils/enums";
import { PushNotificationContentTypes } from "../SelectAttachContentType";

import { setPushNotification } from "../../../../../../store/slices/pushNotifications";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import ListGroups from "../../../../../Schools/School/SchoolGroups/ListGroups";
import NewContentModal from "../../../../../../components/NewContentModal";
import SchoolDeals from "../../../../../Schools/School/SchoolDeals";
import SchoolEvents from "../../../../../Schools/School/SchoolEvents";
import SchoolMarketplaceItems from "../../../../../Schools/School/SchoolMarketplaceItems";
import SchoolThreads from "../../../../../Schools/School/SchoolThreads";
import Resources from "../../../../../Schools/School/Resources";
import ListNewsStories from "../../../../../Schools/School/NewsStories/ListNewsStories";
import SchoolClassNotes from "../../../../../Schools/School/SchoolClassNotes";

type Props = {
    appliedFilters?: any,
    close: Function
    disableFilter?: (arg0: any) => boolean
    handleSelect?: Function
    ignoreGroupPathFilters?: boolean
    hideGroupFilterButtons?: boolean
    show: boolean
    showStudentGroups?: boolean
    type?: PushNotificationContentTypes
}

const AttachContentModal: React.FC<Props> = ({
    appliedFilters,
    close,
    disableFilter,
    handleSelect,
    hideGroupFilterButtons,
    ignoreGroupPathFilters,
    show,
    showStudentGroups,
    type
}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch() as { params: { profileType?: string }};

    const { pushNotification } = useTypedSelector((state) => state.pushNotifications);

    if(!type) {
        return null;
    }

    const handleRowClick = (clickedItem) => {
        if(handleSelect) {
            handleSelect(clickedItem);
        } else {
            let clonedPushNotification = clone(pushNotification);

            delete clonedPushNotification.forumTopic;
            delete clonedPushNotification.newsStory;
            delete clonedPushNotification.post;
            delete clonedPushNotification.tenantResource;

            if (type === PushNotificationContentTypes.Group) {
                clonedPushNotification.forumTopic = clickedItem;
            } else if(type === PushNotificationContentTypes.Resource) {
                clonedPushNotification.tenantResource = clickedItem;
            } else {
                clonedPushNotification.post = clickedItem;
            }

            if(params?.profileType) {
                // Remove secondary profile types when setting content
                clonedPushNotification.admitVisibility = params.profileType === ProfileTypes.Admit;
                clonedPushNotification.alumniVisibility = params.profileType === ProfileTypes.Alumni;
                clonedPushNotification.studentVisibility = params.profileType === ProfileTypes.Student;
            }

            dispatch(setPushNotification(clonedPushNotification));
        }

        close();
    }

    const renderContent = () => {
        switch(type) {
            case PushNotificationContentTypes.ClassNote:
                return (
                    <SchoolClassNotes
                        disablePending
                        onRowClick={handleRowClick}
                    />
                );
            case PushNotificationContentTypes.Deal:
                return (
                    <SchoolDeals
                        disablePending
                        onRowClick={handleRowClick}
                    />
                );
            case PushNotificationContentTypes.Event:
                return (
                    <SchoolEvents
                        disablePending
                        futureOnly={true}
                        onRowClick={handleRowClick}
                    />
                );
            case PushNotificationContentTypes.Group:
                return (
                    <ListGroups
                        appliedFilters={appliedFilters}
                        disableFilter={disableFilter}
                        ignorePathFilters={ignoreGroupPathFilters}
                        hideGroupFilterButtons={hideGroupFilterButtons}
                        onRowClick={handleRowClick}
                        showStudentGroups={showStudentGroups}
                    />
                );
            case PushNotificationContentTypes.MarketplaceItem:
                return (
                    <SchoolMarketplaceItems
                        disablePending
                        onRowClick={handleRowClick}
                    />
                );
            case PushNotificationContentTypes.NewsStory:
                return (
                    <ListNewsStories
                        onRowClick={handleRowClick}
                    />
                )
            case PushNotificationContentTypes.Resource:
                return (
                    <Resources
                        onRowClick={handleRowClick}
                    />
                )
            case PushNotificationContentTypes.Thread:
                return (
                    <SchoolThreads
                        appliedFilters={appliedFilters}
                        disablePending
                        onRowClick={handleRowClick}
                    />
                )

            default:
                return null;
        }
    }

    return (
        <NewContentModal
            className="c-new-content-modal"
            close={close}
            show={show}
            width="wide"
        >
            {renderContent()}
        </NewContentModal>
    );
};

export default AttachContentModal;
