import React from "react";
import {CardType} from "../../../index";
import {DashboardCardData} from "../../../../../../store/slices/superAdminDashboard";
import MostActiveGroupsCard from "../MostActiveGroupsCard";
import MostActiveSchoolsCard from "../MostActiveSchoolsCard";
import MostActiveUsersCard from "../MostActiveUsersCard";
import PushNotificationsCard from "../PushNotificationsCard";
import RecentlyJoinedUsersCard from "../RecentlyJoinedUsersCard";
import TopDealsCard from "../TopDealsCard";
import TopEventsCard from "../TopEventsCard";
import TopItemsCard from "../TopItemsCard";
import TopThreadsCard from "../TopThreadsCard";
import TopDmSchoolsCard from "../TopDmSchoolsCard";
import TopDmUsersCard from "../TopDmUsersCard";
import LiveNowCard from "../LiveNowCard";

type Props = {
    cardData: DashboardCardData
    cardType: CardType
    initialized: boolean
    isGettingDashboard
}


const DashboardCard: React.FC<Props> = ({cardData, cardType, initialized, isGettingDashboard}) => {
    switch (cardType) {
        case CardType.LiveNow:
            return (
                <LiveNowCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );
        case CardType.MostActiveGroups:
            return (
                <MostActiveGroupsCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        case CardType.MostActiveSchools:
            return (
                <MostActiveSchoolsCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        case CardType.MostActiveUsers:
            return (
                <MostActiveUsersCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        case CardType.PushNotifications:
            return (
                <PushNotificationsCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        case CardType.RecentlyJoinedUsers:
            return (
                <RecentlyJoinedUsersCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        case CardType.TopDeals:
            return (
                <TopDealsCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        case CardType.TopDmSchools:
            return (
                <TopDmSchoolsCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        case CardType.TopDmUsers:
            return (
                <TopDmUsersCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        case CardType.TopEvents:
            return (
                <TopEventsCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        case CardType.TopItems:
            return (
                <TopItemsCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        case CardType.TopThreads:
            return (
                <TopThreadsCard
                    cardData={cardData}
                    initialized={initialized}
                    isGettingDashboard={isGettingDashboard}
                />
            );

        default:
            return <></>;
    }
}

export default DashboardCard;
