import React from 'react';

import Group from "../../../../../../types/Group";

import buildImageSrc from "../../../../../../utils/buildImageSrc";

import Button, { ButtonSizes, ButtonThemes } from "../../../../../../components/Button";

type Props = {
    forumTopic: Group
    handleDeleteButtonClick?: Function
    handleEditButtonClick?: Function
    hideEditButton?: boolean
}

const AttachedForumTopic: React.FC<Props> = ({
    forumTopic,
    handleDeleteButtonClick,
    handleEditButtonClick,
    hideEditButton,
}) => {
    return (
        <div className="c-content-summary a-content-summary--group">
            <div
                className="c-content-summary__artifact"
                style={forumTopic.artifact ? {backgroundImage: `url(${buildImageSrc(forumTopic.artifact, '220')})`} : {backgroundImage: 'url("/img/abuzz-group-placeholder.svg")'}}
            />

            <div className="c-content-summary__text">
                {forumTopic.name && (
                    <div className="c-content-summary__title">
                        {forumTopic.name}
                    </div>
                )}

                {forumTopic.memberCount != null && (
                    <div className="c-content-summary__description">
                        {forumTopic.memberCount} member{forumTopic.memberCount !== 1 ? 's' : ''}
                    </div>
                )}
            </div>

            {!hideEditButton && handleEditButtonClick && (
                <Button
                    onClick={() => handleEditButtonClick()}
                    size={ButtonSizes.Small}
                    theme={ButtonThemes.Light}
                >
                    Edit
                </Button>
            )}

            {handleDeleteButtonClick && (
                <Button
                    onClick={() => handleDeleteButtonClick()}
                    size={ButtonSizes.Small}
                    theme={ButtonThemes.Light}
                >
                    Remove
                </Button>
            )}
        </div>
    );
};

export default AttachedForumTopic;
