import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";

import { CAPABILITIES, userCan, userCanAccessSchool } from "../../../utils/roles";
import { determinePathAfterLogin } from "../../../utils/routing";
import { ProfileTypes } from "../../../utils/enums";

import { getModerationCount } from "../../../store/slices/moderation";
import { getSchool } from '../../../store/slices/schools';
import { useAppDispatch } from '../../../store';
import { useTypedSelector } from '../../../store/reducers';

import AdmitDashboard from './Admits/Dashboard';
import ActivityIndicator from "../../../components/ActivityIndicator";
import Ambassadors from "./Ambassadors";
import ComingSoon from "./ComingSoon";
import CreateEditDeal from '../../Content/Deals/CreateEditDeal';
import CreateEditEvent from '../../Content/Events/CreateEditEvent';
import CreateEditLandingPage from "./LandingPages/CreateEditLandingPage";
import CreateEditMarketplaceItem from "../../Content/Marketplace/CreateEditMarketplaceItem";
import CreateEditNotification from '../../Content/Notifications/CreateEditNotification';
import CreateEditThread from "../../Content/Threads/CreateEditThread";
import Dashboard from "./Dashboard";
import DirectMessages from "./DirectMessages";
import EditSchoolDetails from "./Details";
import FAQ from "./FAQ";
import FlaggedContent from "./FlaggedContent";
import Giving from './Giving';
import Integrations from '../../Integrations';
import ListAdmits from "./Admits/ListAdmits";
import ListGroups from "./SchoolGroups/ListGroups";
import ListImportJobs from "./SchoolStudents/ListImportJobs";
import ListLandingPages from "./LandingPages/ListLandingPages";
import ListNewsStories from "./NewsStories/ListNewsStories";
import ListStudents from "./SchoolStudents/ListStudents";
import Partners from "./Partners";
import Promotion from './Promotion';
import Resources from "./Resources";
import SchoolClassNotes from './SchoolClassNotes';
import SchoolDeals from './SchoolDeals';
import SchoolEvents from './SchoolEvents';
import SchoolFeed from "./SchoolFeed";
import SchoolGiving from "./SchoolGiving";
import SchoolMarketplaceItems from "./SchoolMarketplaceItems";
import SchoolPushNotifications from './SchoolPushNotifications';
import SchoolStats from './SchoolStats';
import SchoolThreads from "./SchoolThreads";
import TenantActivityReportGenerator from './TenantActivityReportGenerator';
import Users from "./SchoolUsers";
import ViewGroup from "./SchoolGroups/ViewGroup";
import ViewMarketplaceItem from "../../Content/Marketplace/ViewMarketplaceItem";
import ViewStudent from "./SchoolStudents/ViewStudent";
import ViewThread from "../../Content/Threads/ViewThread";
import ListAlumni from './Alumni/ListAlumni';
import EventAttendees from "./SchoolEvents/EventAttendees";

const School = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();
    const { pathname } = useLocation();

    let moderationCountTimer = useRef(null);

    const { activeSchool, isGettingSchool } = useTypedSelector((state) => state.schools);
    const { isGettingModerationCount } = useTypedSelector((state) => state.moderation);
    const { roles } = useTypedSelector((state) => state.auth);

    const [initialized, setInitialized] = useState<boolean>(false);

    const routes = useMemo(
        () => {
            let authorizedRoutes = [];

            let schoolId = null;
            if(params?.schoolId) {
                schoolId = parseInt(params.schoolId);
            }

            if(pathname.includes(`/${ProfileTypes.Student}/`)) {
                if(activeSchool.studentActive) {
                    if (userCan(CAPABILITIES.VIEW_SUPER_ADMIN, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/stats', component: SchoolStats},
                        ];
                    }
                    if (userCan(CAPABILITIES.VIEW_SCHOOL_DASHBOARD, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/dashboard', component: Dashboard},
                        ];
                    }
                    if (userCan(CAPABILITIES.VIEW_SCHOOL_FEED, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/feed', component: SchoolFeed},
                            {path: '/school/:schoolId/:profileType/flagged', component: FlaggedContent},
                        ];
                    }
                    if (userCan(CAPABILITIES.VIEW_TENANT_REPORT_GENERATOR, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/report-generator', component: TenantActivityReportGenerator},
                        ];
                    }
                    if (userCan(CAPABILITIES.MANAGE_AMBASSADORS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/ambassadors', component: Ambassadors},
                        ];
                    }
                    if (userCan(CAPABILITIES.MANAGE_PARTNERS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/partners', component: Partners},
                        ];
                    }
                    if (userCan(CAPABILITIES.MANAGE_GROUPS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/groups', component: ListGroups},
                            {path: '/school/:schoolId/:profileType/group/:forumTopicId', component: ViewGroup},
                        ];
                    }
                    if (userCan(CAPABILITIES.MANAGE_PUSH_NOTIFICATIONS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {
                                path: '/school/:schoolId/:profileType/notifications',
                                component: SchoolPushNotifications,
                                exact: true
                            },
                            {
                                path: '/school/:schoolId/:profileType/notification/new',
                                component: CreateEditNotification
                            },
                            {
                                path: '/school/:schoolId/:profileType/notifications/:notificationId/edit',
                                component: CreateEditNotification
                            },
                        ];
                    }
                    if (userCan(CAPABILITIES.VIEW_CONTENT_TABLE, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/deals', component: SchoolDeals, exact: true},
                            {path: '/school/:schoolId/:profileType/events', component: SchoolEvents, exact: true},
                            {path: '/school/:schoolId/:profileType/event/:postId/attendees', component: EventAttendees, exact: true},
                            {path: '/school/:schoolId/:profileType/discussions', component: SchoolThreads, exact: true},
                            {
                                path: '/school/:schoolId/:profileType/marketplace',
                                component: SchoolMarketplaceItems,
                                exact: true
                            },
                            {path: '/school/:schoolId/:profileType/news', component: ListNewsStories, exact: true},
                            {path: '/school/:schoolId/:profileType/faq', component: FAQ, exact: true},
                            {path: '/school/:schoolId/:profileType/resources', component: Resources, exact: true},
                        ];
                    }
                    if (userCan(CAPABILITIES.EDIT_CONTENT, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/deal/new', component: CreateEditDeal},
                            {path: '/school/:schoolId/:profileType/deal/:postId/edit', component: CreateEditDeal},
                            {path: '/school/:schoolId/:profileType/event/new', component: CreateEditEvent},
                            {path: '/school/:schoolId/:profileType/event/:postId/edit', component: CreateEditEvent},
                            {path: '/school/:schoolId/:profileType/discussion/new', component: CreateEditThread},
                            {
                                path: '/school/:schoolId/:profileType/discussion/:postId/edit',
                                component: CreateEditThread
                            },
                            {
                                path: '/school/:schoolId/:profileType/marketplace/new',
                                component: CreateEditMarketplaceItem
                            },
                            {
                                path: '/school/:schoolId/:profileType/marketplace/:postId/edit',
                                component: CreateEditMarketplaceItem
                            },
                            {path: '/school/:schoolId/:profileType/discussions/:postId', component: ViewThread},
                            {
                                path: '/school/:schoolId/:profileType/marketplace/:postId',
                                component: ViewMarketplaceItem
                            },
                        ];
                    }
                    if (userCan(CAPABILITIES.MANAGE_INTEGRATIONS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/integrations', component: Integrations},
                        ];
                    }
                    if (userCan(CAPABILITIES.MANAGE_LANDING_PAGES, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/landing-page/new', component: CreateEditLandingPage},
                            {
                                path: '/school/:schoolId/:profileType/landing-page/:landingPageId',
                                component: CreateEditLandingPage
                            },
                            {path: '/school/:schoolId/:profileType/landing-pages', component: ListLandingPages},
                        ];
                    }
                    if (userCan(CAPABILITIES.MANAGE_STUDENTS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/students', component: ListStudents, exact: true},
                            {path: '/school/:schoolId/:profileType/students/:profileId', component: ViewStudent},
                            {path: '/school/:schoolId/:profileType/imports', component: ListImportJobs},
                        ];
                    }
                    if (userCan(CAPABILITIES.DM, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {
                                path: '/school/:schoolId/:profileType/messages/conversation/new',
                                component: DirectMessages
                            },
                            {
                                path: '/school/:schoolId/:profileType/messages/conversation/:conversationId',
                                component: DirectMessages
                            },
                            {path: '/school/:schoolId/:profileType/messages', component: DirectMessages},
                        ];
                    }
                } else {
                    authorizedRoutes = [
                        {
                            path: '/school/:schoolId/:profileType/activate',
                            component: ComingSoon,
                            exact: true
                        }
                    ]
                }
            } else if(pathname.includes(`/${ProfileTypes.Alumni}/`)) {
                if(activeSchool.admitActive) {
                    if (userCan(CAPABILITIES.VIEW_GIVING, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/giving', component: Giving},
                        ];
                    }

                    if (userCan(CAPABILITIES.VIEW_SCHOOL_FEED, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/feed', component: SchoolFeed},
                            /*{path: '/school/:schoolId/alumni/flagged', component: FlaggedContent},*/
                        ];
                    }

                    if (userCan(CAPABILITIES.MANAGE_STUDENTS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/list', component: ListAlumni, exact: true},
                            /*{path: '/school/:schoolId/:profileType/students/:profileId', component: ViewStudent},
                            {path: '/school/:schoolId/:profileType/imports', component: ListImportJobs},*/
                        ];
                    }

                    if (userCan(CAPABILITIES.VIEW_CONTENT_TABLE, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/events', component: SchoolEvents, exact: true},
                            {path: '/school/:schoolId/:profileType/discussions', component: SchoolThreads, exact: true},
                            {path: '/school/:schoolId/:profileType/faq', component: FAQ, exact: true},
                            {path: '/school/:schoolId/:profileType/resources', component: Resources, exact: true},
                            {path: '/school/:schoolId/:profileType/news', component: ListNewsStories, exact: true},
                            {path: '/school/:schoolId/:profileType/classNotes', component: SchoolClassNotes, exact: true},
                            {path: '/school/:schoolId/:profileType/giving', component: SchoolGiving, exact: true},
                            {path: '/school/:schoolId/:profileType/giving/:categoriesOrOpportunities', component: SchoolGiving, exact: true},
                        ];
                    }

                    if (userCan(CAPABILITIES.DM, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {
                                path: '/school/:schoolId/:profileType/messages/conversation/new',
                                component: DirectMessages
                            },
                            {
                                path: '/school/:schoolId/:profileType/messages/conversation/:conversationId',
                                component: DirectMessages
                            },
                            {path: '/school/:schoolId/:profileType/messages', component: DirectMessages},
                        ];
                    }

                    if (userCan(CAPABILITIES.MANAGE_PUSH_NOTIFICATIONS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {
                                path: '/school/:schoolId/:profileType/notifications',
                                component: SchoolPushNotifications,
                                exact: true
                            },
                            {
                                path: '/school/:schoolId/:profileType/notification/new',
                                component: CreateEditNotification
                            },
                            {
                                path: '/school/:schoolId/:profileType/notifications/:notificationId/edit',
                                component: CreateEditNotification
                            },
                        ];
                    }
                } else {
                    authorizedRoutes = [
                        {
                            path: '/school/:schoolId/:profileType/activate',
                            component: ComingSoon,
                            exact: true
                        }
                    ]
                }
            } else if(pathname.includes(`/${ProfileTypes.Admin}/`)) {
                if (userCan(CAPABILITIES.MANAGE_PEOPLE, null, schoolId)) {
                    authorizedRoutes = [...authorizedRoutes,
                        {path: '/school/:schoolId/:profileType/admins', component: Users},
                    ];
                }
                if (userCan(CAPABILITIES.MODERATE_CONTENT, null, schoolId)) {
                    authorizedRoutes = [...authorizedRoutes,
                        {path: '/school/:schoolId/:profileType/flagged', component: FlaggedContent},
                    ];
                }
                if (userCan(CAPABILITIES.MANAGE_STUDENTS, null, schoolId)) {
                    authorizedRoutes = [...authorizedRoutes,
                        {path: '/school/:schoolId/:profileType/promotion', component: Promotion},
                    ];
                }
                if (userCan(CAPABILITIES.EDIT_SCHOOL_DETAILS, null, schoolId)) {
                    authorizedRoutes = [...authorizedRoutes,
                        {path: '/school/:schoolId/:profileType/details', component: EditSchoolDetails},
                    ];
                }

            } else if(pathname.includes(`/${ProfileTypes.Admit}/`)) {
                if(activeSchool.admitActive) {
                    if (userCan(CAPABILITIES.MANAGE_STUDENTS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/list', component: ListAdmits, exact: true},
                            {path: '/school/:schoolId/:profileType/dashboard', component: AdmitDashboard, exact: true},
                        ];
                    }

                    if (userCan(CAPABILITIES.VIEW_CONTENT_TABLE, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/events', component: SchoolEvents, exact: true},
                            {path: '/school/:schoolId/:profileType/faq', component: FAQ, exact: true},
                            {path: '/school/:schoolId/:profileType/resources', component: Resources, exact: true},
                            {path: '/school/:schoolId/:profileType/news', component: ListNewsStories, exact: true},
                        ];
                    }

                    if (userCan(CAPABILITIES.DM, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {
                                path: '/school/:schoolId/:profileType/messages/conversation/new',
                                component: DirectMessages
                            },
                            {
                                path: '/school/:schoolId/:profileType/messages/conversation/:conversationId',
                                component: DirectMessages
                            },
                            {path: '/school/:schoolId/:profileType/messages', component: DirectMessages},
                        ];
                    }

                    if (userCan(CAPABILITIES.MANAGE_GROUPS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {path: '/school/:schoolId/:profileType/groups', component: ListGroups},
                            {path: '/school/:schoolId/:profileType/group/:forumTopicId', component: ViewGroup},
                        ];
                    }

                    if (userCan(CAPABILITIES.MANAGE_PUSH_NOTIFICATIONS, null, schoolId)) {
                        authorizedRoutes = [...authorizedRoutes,
                            {
                                path: '/school/:schoolId/:profileType/notifications',
                                component: SchoolPushNotifications,
                                exact: true
                            },
                            {
                                path: '/school/:schoolId/:profileType/notification/new',
                                component: CreateEditNotification
                            },
                            {
                                path: '/school/:schoolId/:profileType/notifications/:notificationId/edit',
                                component: CreateEditNotification
                            },
                        ];
                    }
                } else {
                    authorizedRoutes = [
                        {
                            path: '/school/:schoolId/:profileType/activate',
                            component: ComingSoon,
                            exact: true
                        }
                    ]
                }
            }

            return authorizedRoutes;
        }, [params?.schoolId, pathname, activeSchool]
    );

    useEffect(() => {
        const initialize = () => {
            setInitialized(true);
            try {
                if (params?.schoolId) {
                    if (userCanAccessSchool(params.schoolId)) {
                        dispatch(getSchool({
                            schoolId: params.schoolId,
                            hideSpinner: params.schoolId === activeSchool.tenantId,
                        })).unwrap();
                    } else {
                        let redirectRoute = determinePathAfterLogin(roles);
                        history.push(redirectRoute);
                    }
                }
            } catch(err) {
                console.log('school initialize err', err);
            }
        };
        if (!initialized) {
            initialize();
        }
    }, [activeSchool?.tenantId, dispatch, history, initialized, params?.schoolId, roles]);

    useEffect(() => {
        if(activeSchool?.tenantId) {
            getModerationCountOnTimer();
        } else {
            clearInterval(moderationCountTimer.current);
        }

        return () => {
            clearInterval(moderationCountTimer.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSchool?.tenantId]);

    const getModerationCountOnTimer = async () => {
        try {
            if(!isGettingModerationCount) {
                await dispatch(getModerationCount({schoolId: activeSchool.tenantId}));
            }
            moderationCountTimer.current = setTimeout(getModerationCountOnTimer, 60000);
        } catch(err) {
            console.log('School getModerationCount err', err);
        }
    };

    return (
        <>
            {isGettingSchool ? (
                <ActivityIndicator />
            ) : (
                <Switch>
                    {routes.map((route, i) => (
                        <Route path={route.path} component={route.component} exact={route.exact} key={i} />
                    ))}
                </Switch>
            ) }
        </>

    )
}
export default School;
