import './FormColumn.scss';

import React from 'react';

type Props = {
    children: React.ReactNode
    className?: string
}

const FormColumn: React.FC<Props> = ({
    children,
    className = ''
}) => (
    <div className={`c-form-column ${className}`}>
        {children}
    </div>
);

export default FormColumn;
