import React from 'react';

import { useTypedSelector } from '../../../../../../store/reducers';

import DashboardCardChart from './DashboardCardChart';

export interface IChartTimeSeriesData {
    x: Date,
    y: number
}

export type ChartProps = {
    dau: Array<IChartTimeSeriesData>
    mau: Array<IChartTimeSeriesData>
    headcount: Array<IChartTimeSeriesData>
    percentUsersWithPushesEnabled?: Array<IChartTimeSeriesData>
    profileActivity: Array<IChartTimeSeriesData>
    isGettingDashboard: boolean
    initialized: boolean
};

const DashboardCharts: React.FC<ChartProps> = ({
    dau,
    mau,
    headcount,
    percentUsersWithPushesEnabled,
    profileActivity,
    isGettingDashboard,
    initialized,
}) => {
    const {showChartCards} = useTypedSelector(state => state.superAdminDashboard);

    return (showChartCards && (
        <>
            <DashboardCardChart
                chart={dau}
                title="Daily Active Users (DAU)"
                isGettingDashboard={isGettingDashboard}
                initialized={initialized}
            />

            <DashboardCardChart
                chart={mau}
                title="Monthly Active Users (MAU)"
                isGettingDashboard={isGettingDashboard}
                initialized={initialized}
            />

            <DashboardCardChart
                chart={headcount}
                title="Headcount"
                isGettingDashboard={isGettingDashboard}
                initialized={initialized}
            />
            <DashboardCardChart
                chart={profileActivity}
                title="User Activity"
                isGettingDashboard={isGettingDashboard}
                initialized={initialized}
            />
            {percentUsersWithPushesEnabled && <DashboardCardChart
                chart={percentUsersWithPushesEnabled}
                title={'% Users on Latest Version with Pushes Enabled'}
                isGettingDashboard={isGettingDashboard}
                initialized={initialized}
            />}
        </>
    ));
};

export default DashboardCharts;
