import React, { useMemo } from 'react';
import { useHistory } from 'react-router';

import Tenant from "../../../../types/Tenant";

import { isArrayNullOrEmpty } from "../../../../utils/utils";

import TableMoreMenu from "../../../../components/Table/TableMoreMenu";

type Props = {
    item: Tenant
}

const SchoolListMoreMenu: React.FC<Props> = ({
     item,
}) => {
    const history = useHistory();

    const customActions = useMemo(
        () => {
            let actions = [];

            actions.push({
                icon: 'edit',
                onClick: () => {
                    history.push (`/school/${item.tenantId}/admin/details`);
                },
                text: 'Edit Details',
            });
            return actions;

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [],
    );

    if (isArrayNullOrEmpty(customActions)) {
        return null;
    }

    return (
        <>
            <TableMoreMenu
                customOptions={customActions}
                hideModerationOptions
                item={item}
                type="school"
            />

        </>
    );
};

export default SchoolListMoreMenu;
