import './EditIntegrationEvent.scss';

import React from 'react';

import { useTypedSelector } from '../../../../store/reducers';

import EditIntegrationEventForm from './EditIntegrationEventForm';

type Props = {
    close: () => void
}

const EditIntegrationEvent: React.FC<Props> = ({
    close,
}) => {
    const { event } = useTypedSelector(store => store.integrations);

    return (
        <>
            <EditIntegrationEventForm
                close={close}
                post={event}
            />
        </>
    );
};

export default EditIntegrationEvent;
