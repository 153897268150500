import './PageHeader.scss';

import React from 'react';

type Props = {
    children: React.ReactNode
    className?: string
}

const PageHeader: React.FC<Props> = ({
    children,
    className = '',
}) => {
    return (
        <header className={`c-page-header ${className}`}>
            {children}
        </header>
    );
};

export default PageHeader;
