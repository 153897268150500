import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import clone from "clone";

import {addError} from './errors';
import PATHS, {buildQueryString} from '../../utils/paths';

import ActivityAlert from '../../types/ActivityAlert';
import IError from '../../types/IError';
import Metadata from '../../types/Metadata';
import Request from '../../utils/request';
import {RootState} from '../reducers';
import Role from '../../types/Role';
import { ROLES } from '../../utils/roles';


type GetActivityAlertsProps = {
    isUpdate?: boolean
    schoolId?: number
    activityAlertsMetadata?: Metadata
}

export const getActivityAlerts = createAsyncThunk(
    'feed/getActivityAlerts',
    async ({isUpdate, schoolId, activityAlertsMetadata}: GetActivityAlertsProps, {dispatch, getState}) => {
        try {
            const isSuperAdmin = (getState() as RootState).auth.roles.find(role => role.type === ROLES.SUPER_ADMIN);
            if (isSuperAdmin) {
                return;
            }

            if(!activityAlertsMetadata) {
                activityAlertsMetadata = clone((getState() as RootState).feed.activityAlertsMetadata);
            } else {
                activityAlertsMetadata = {...activityAlertsMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            if(isUpdate) {
                activityAlertsMetadata.page_num = activityAlertsMetadata.page_num + 1;
            } else {
                activityAlertsMetadata.page_num = 0;
            }
            const res = await new Request((getState() as RootState).auth.token).get(PATHS.activityAlerts.getActivityAlerts(schoolId, buildQueryString(activityAlertsMetadata)));
            let activityAlerts = res.data.data.items;
            if(isUpdate) {
                activityAlerts = (getState() as RootState).feed.feedItems.concat(activityAlerts);
            }

            return {activityAlerts, activityAlertsMetadata: activityAlertsMetadata, isAtEnd: res.data.data.items.length === 0};
        } catch(err) {
            console.log('getActivityAlerts error: ', err);
            err.friendlyMessage = 'Error getting your Activity Alerts. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

export const markActivityAlertsAsViewed = createAsyncThunk(
    'feed/markActivityAlertsAsViewed',
    async ({schoolId}: GetActivityAlertsProps, {dispatch, getState}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            await new Request((getState() as RootState).auth.token).put(PATHS.activityAlerts.markActivityAlertsAsViewed(schoolId));

        } catch(err) {
            console.log('markActivityAlertsAsViewed error: ', err);
            err.friendlyMessage = 'Error marking your Activity Alerts as viewed. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

interface ActivityAlertsState {
    activityAlerts: Array<ActivityAlert>
    activityAlertsMetadata: Metadata
    getActivityAlertsError: IError
    isGettingActivityAlerts: boolean
    readyToMarkActivityAlertsAsRead: boolean
}

const initialState: ActivityAlertsState = {
    activityAlerts: [],
    activityAlertsMetadata: {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: ''
    },
    getActivityAlertsError: undefined,
    isGettingActivityAlerts: false,
    readyToMarkActivityAlertsAsRead: false,
};

export const activityAlertsSlice = createSlice({
    name: 'activityAlerts',
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(getActivityAlerts.pending, (state, action) => {
            state.getActivityAlertsError = undefined;
            state.isGettingActivityAlerts = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.activityAlertsMetadata) {
                state.activityAlertsMetadata = action.meta.arg.activityAlertsMetadata;
            }
        });
        addCase(getActivityAlerts.fulfilled, (state, action) => {
            state.activityAlerts = action.payload.activityAlerts;
            state.activityAlertsMetadata = action.payload.activityAlertsMetadata;
            state.readyToMarkActivityAlertsAsRead = true;
            state.isGettingActivityAlerts = false;
        });
        addCase(getActivityAlerts.rejected, (state, action) => {
            state.getActivityAlertsError = action.error;
            state.isGettingActivityAlerts = false;
        });

        addCase(markActivityAlertsAsViewed.pending, (state, action) => {
            state.getActivityAlertsError = undefined;
            state.readyToMarkActivityAlertsAsRead = false;
        });
        addCase(markActivityAlertsAsViewed.fulfilled, (state, action) => {
            state.readyToMarkActivityAlertsAsRead = false;
        });
        addCase(markActivityAlertsAsViewed.rejected, (state, action) => {
            state.getActivityAlertsError = action.error;
            state.readyToMarkActivityAlertsAsRead = false;
        });
    }
});

export default activityAlertsSlice.reducer;
