import './Radio.scss';

import React from 'react';

import Label from '../Label';

type Props = {
    checked: boolean
    className?: string
    disabled?: boolean
    id: string
    label: string | React.ReactNode
    name: string
    onChange: Function
    value: string
}

const Radio: React.FC<Props> = ({
    checked,
    className = '',
    disabled,
    id,
    label,
    name,
    onChange,
    value
}) => (
    <div className={`o-radio ${className}`}>
        <input
            checked={checked}
            className="c-radio"
            disabled={disabled}
            id={id}
            name={name}
            onChange={() => onChange()}
            type="radio"
            value={value}
        />

        <Label htmlFor={id}>{label}</Label>
    </div>
);

export default Radio;
