import './ActivityReportGenerator.scss';
import React, { useState } from 'react';
import moment from 'moment';
import clone from 'clone';
import { validated } from 'react-custom-validation';

import Validation from '../../../types/Validation';
import Tenant from '../../../types/Tenant';

import { handleDateChange } from '../../../utils/handle-changes';
import { isArrayNullOrEmpty } from '../../../utils/utils';
import { isDate, isEmailArray, isRequired, isRequiredIfTrue } from '../../../utils/validations';

import {
    ActivityReportGeneratorState,
    generateActivityReport,
    setConfig,
} from '../../../store/slices/activityReportGenerator';
import { useAppDispatch } from '../../../store';
import { useTypedSelector } from '../../../store/reducers';

import Button, { ButtonThemes } from '../../../components/Button';
import ButtonRow from '../../../components/ButtonRow';
import Checkbox from '../../../components/Checkbox';
import Datepicker from '../../../components/Datepicker';
import FormColumn from '../../../components/FormColumn';
import FormRow from '../../../components/FormRow';
import Icon from '../../../components/Icon';
import Label from '../../../components/Label';
import Textbox from '../../../components/Textbox';
import Form from '../../../components/Form';
import SelectTenantModal from './components/SelectTenantModal';
import SuccessModal from '../../../components/Modal/SuccessModal';

type Props = {
    config: ActivityReportGeneratorState['config'],
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        tenantIds: Validation
        emailAddresses: Validation
        startAt: Validation
        endAt: Validation
        includeMyself: Validation
        includeGlobalReport: Validation
        sendActivityReport: Validation
        sendDmReport: Validation
    }
}

enum FieldNames {
    EmailAddress = 'emailAddress',
    EndAt = 'endAt',
    IncludeMyself = 'includeMyself',
    IncludeGlobalReport = 'includeGlobalReport',
    SendActivityReport = 'sendActivityReport',
    SendDmReport = 'sendDmReport',
    StartAt = 'startAt',
    Tenant = 'tenant',
}

const ActivityReportGeneratorForm: React.FC<Props> = ({ config, $field, $fieldEvent, $submit, $validation }) => {
    const dispatch = useAppDispatch();

    const [showAttachContentModal, setShowAttachContentModal] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

    const { isRequestingReport } = useTypedSelector(state => state.activityReportGenerator);
    const { schools } = useTypedSelector(state => state.schools);

    const handleSelectSchool = (item: Tenant) => {
        handleChange(FieldNames.Tenant, item);
    };

    const handleSelectAllSchools = () => {
        const clonedConfig = clone(config);
        if (clonedConfig.tenants.length > 0) {
            clonedConfig.tenants = [];
            dispatch(setConfig(clonedConfig));
            return;
        }
        clonedConfig.tenants = schools.map((school: Tenant) => school);
        dispatch(setConfig(clonedConfig));
    }

    const handleChange = (name: FieldNames, value: Tenant | string | boolean) => {
        const clonedConfig: Props['config'] = clone(config);

        switch (true) {
            case name === FieldNames.EmailAddress:
                clonedConfig.emailAddresses = value as string;
                break;
            case name === FieldNames.EndAt:
                clonedConfig.endAt = value as string;
                break;
            case name === FieldNames.IncludeMyself:
                clonedConfig.includeMyself = value as boolean;
                break;
            case name === FieldNames.IncludeGlobalReport:
                clonedConfig.includeGlobalReport = value as boolean;
                break;
            case name === FieldNames.StartAt:
                clonedConfig.startAt = value as string;
                break;
            case name === FieldNames.Tenant:
                if (isArrayNullOrEmpty(clonedConfig.tenants)) {
                    clonedConfig.tenants = [];
                }
                const index = clonedConfig.tenants.findIndex(t => t.tenantId === (value as Tenant).tenantId);
                if (index > -1) {
                    clonedConfig.tenants.splice(index, 1);
                }
                else {
                    clonedConfig.tenants = [
                        ...clonedConfig.tenants,
                        value as Tenant,
                    ];
                }
                break;
            case name === FieldNames.SendActivityReport:
                clonedConfig.sendActivityReport = value as boolean;
                break;
            case name === FieldNames.SendDmReport:
                clonedConfig.sendDmReport = value as boolean;
                break;
            default:
                break;
        }
        dispatch(setConfig(clonedConfig));
    };

    const handleDeleteSelectedTenant = (tenantId: number) => {
        const clonedConfig = clone(config);
        clonedConfig.tenants = clonedConfig.tenants.filter(t => t.tenantId!==tenantId);

        dispatch(setConfig(clonedConfig));
    };

    const handleSave = async (isValid) => {
        if (!isValid) {
            return;
        }
        try {
            await dispatch(generateActivityReport()).unwrap();
            $fieldEvent('reset');
            setShowSuccessModal(true);
        } catch (err) {
            console.log('CreateEditLandingPage handleSave err', err);
        }
    };

    return (
        <Form>
            <FormRow columnCount={2}>
                <FormColumn>
                    <Datepicker
                        dateFormat="yyyy-MM-dd"
                        disabled={isRequestingReport}
                        id="dateStartAt"
                        label="Select a Start Date"
                        maxDate={new Date()}
                        name="startAt"
                        placeholderText="mm-dd-yyyy"
                        selected={config.startAt || null}
                        showMonthDropdown
                        showYearDropdown
                        validation={$validation.startAt}
                        {...$field(
                            FieldNames.StartAt,
                            (newDate) => handleDateChange(
                                handleChange,
                                FieldNames.StartAt,
                                null,
                                moment(newDate).format(),
                            ))}
                    />
                </FormColumn>

                <FormColumn>
                    <Datepicker
                        dateFormat="yyyy-MM-dd"
                        disabled={isRequestingReport}
                        id="dateEndAt"
                        maxDate={new Date()}
                        label="Select an End Date"
                        name="endAt"
                        placeholderText="mm-dd-yyyy"
                        selected={config.endAt || null}
                        showMonthDropdown
                        showYearDropdown
                        validation={$validation.endAt}
                        {...$field(
                            FieldNames.EndAt,
                            (newDate) => handleDateChange(
                                handleChange,
                                FieldNames.EndAt,
                                null,
                                moment(newDate).format(),
                            ))}
                    />

                </FormColumn>
            </FormRow>

            <FormRow>
                <Label>Select the schools you'd like to generate a report for</Label>

                <Button
                    onClick={() => setShowAttachContentModal(true)}
                    theme={ButtonThemes.Link}
                >
                    Add School
                </Button>

                {!isArrayNullOrEmpty(config.tenants)
                    && config.tenants.map((tenant: Tenant) => (
                        <div className="activity-report-generator__form-item-container">
                            <Label className="activity-report-generator__form-item">
                                {tenant.name}
                            </Label>
                            <Button
                                className="activity-report-generator__form-item-button"
                                key={tenant.tenantId}
                                onClick={() => handleDeleteSelectedTenant(tenant.tenantId)}
                                noStyles={true}
                            >
                                <Icon type="trash"/>
                            </Button>
                        </div>
                    ))}
            </FormRow>

            <FormRow className="activity-report-generator__add-email-container">
                <Textbox
                    label="Who should we send this report to?"
                    className="activity-report-generator__form-item"
                    id="emailAddressTextbox"
                    placeholder="jane@email.edu, john@email.edu, etc."
                    validation={$validation.emailAddresses}
                    value={config.emailAddresses || ''}
                    {...$field(
                        FieldNames.EmailAddress, (event) => handleChange(FieldNames.EmailAddress, event.target.value),
                    )}
                />
            </FormRow>

            <Label>Other Options</Label>

            <FormRow>
                <Checkbox
                    checked={config.includeMyself}
                    id="chkIncludeMyself"
                    label="Include myself as a recipient in this report"
                    name="include-me"
                    validation={$validation.includeMyself}
                    {...$field(FieldNames.IncludeMyself, () => handleChange(FieldNames.IncludeMyself, !config.includeMyself))}
                />
            </FormRow>

            <FormRow>
                <Checkbox
                    checked={config.includeGlobalReport}
                    id="chkIncludeGLobalReport"
                    label="Include the global activity report"
                    name="include-global-report"
                    validation={$validation.includeGlobalReport}
                    {...$field(FieldNames.IncludeGlobalReport, () => handleChange(FieldNames.IncludeGlobalReport, !config.includeGlobalReport))}
                />
            </FormRow>

            <FormRow>
                <Checkbox
                    checked={config.sendDmReport}
                    id="chkSendDmReport"
                    label="Include the DM Report"
                    name="include-dm-report"
                    onChange={() => {
                        handleChange(FieldNames.SendDmReport, !config.sendDmReport)
                    }}
                    validation={$validation.sendDmReport}
                />
            </FormRow>

            <FormRow>
                <Checkbox
                    checked={config.sendActivityReport}
                    id="chkSendActivityReport"
                    label="Include the Active Users Report"
                    name="include-activity-report"
                    onChange={() => {
                        handleChange(FieldNames.SendActivityReport, !config.sendActivityReport)
                    }}
                    validation={$validation.sendActivityReport}
                />
            </FormRow>

            <ButtonRow className="activity-report-generator__button-row">
                <Button
                    disabled={isRequestingReport}
                    showActivityIndicator={isRequestingReport}
                    onClick={(e) => {
                        e.preventDefault();
                        $submit(() => handleSave(true),() => handleSave(false));
                    }}
                >
                    Generate Report
                </Button>
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    setShowSuccessModal(false);
                }}
                show={showSuccessModal}
            >
                <p>
                    Your report request has been received.
                </p>
                <p>
                    It may take a minute for the report{(config.tenants.length > 1 || config.includeMyself) && 's'} to
                    be delivered.
                </p>
            </SuccessModal>

            <SelectTenantModal
                show={showAttachContentModal}
                setShow={setShowAttachContentModal}
                handleSelectAllSchools={handleSelectAllSchools}
                handleSelectSchool={handleSelectSchool}
                selectedSchools={config.tenants}
            />
        </Form>
    );
};

const activityReportGeneratorFormValidationConfig = (props: Props) => {
    const { emailAddresses, endAt, includeGlobalReport, includeMyself, startAt, tenants, sendDmReport, sendActivityReport } = props.config;

    return {
        fields: ['emailAddresses', 'endAt', 'includeGlobalReport', 'includeMyself', 'startAt', 'tenants', 'sendDmReport', 'sendActivityReport'],
        validations: {
            emailAddresses: [
                [isEmailArray, emailAddresses],
            ],
            includeMyself: [
                [isRequiredIfTrue, emailAddresses, !includeMyself, 'Enter at least one email address, or select "Include myself as a recipient in this report"'],
            ],
            endAt: [
                [isDate, endAt],
            ],
            includeGlobalReport: [
                [isRequired, includeGlobalReport],
                [isRequiredIfTrue, tenants, !includeGlobalReport, 'Select at least one school, or select "Include the global activity report"'],
            ],
            startAt: [
                [isDate, startAt],
            ],
            tenants: [
                [isRequired, tenants],
            ],
            sendActivityReport: [
                [isRequiredIfTrue, sendDmReport, !sendActivityReport, 'Select at least one report to send'],
            ],
            sendDmReport: [
                [isRequiredIfTrue, sendActivityReport, !sendDmReport, 'Select at least one report to send'],
            ],
        },
    };
};

export default validated(activityReportGeneratorFormValidationConfig)(ActivityReportGeneratorForm);
