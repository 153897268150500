import './FlaggedContent.scss';

import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useRouteMatch } from "react-router";
import clone from 'clone';

import TableFilterMenuOption from "../../../../types/TableFilterMenuOption";

import { getFlaggedContent } from "../../../../store/slices/moderation";
import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import Feed from "../../../../components/Feed";
import H5 from "../../../../components/H5";
import Icon from "../../../../components/Icon";
import PageHeader from "../../../../components/PageHeader";
import TableFilterMenu from "../../../../components/Table/TableFilterMenu";

const FlaggedContent: React.FC = () => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const [atEnd, setAtEnd] = useState<boolean>(true);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [isInfiniteScrolling, setIsInfiniteScrolling] = useState<boolean>(false);

    const { activeSchool } = useTypedSelector((state) => state.schools);
    const { flaggedContent, flaggedContentMetadata, isGettingFlaggedContent } = useTypedSelector((state) => state.moderation);

    useEffect(() => {
        const initialize = async () => {
            try {
                let res: any = await dispatch(getFlaggedContent({schoolId: match?.params?.schoolId, flaggedContentMetadata})).unwrap();
                setAtEnd(res.isAtEnd);
            } catch(err) {
                console.log('FlaggedContent initialize err', err);
            } finally {
                setInitialized(true);
            }
        };

        if (!initialized) {
            initialize();
        }

        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params?.schoolId]);

    const filterOptions: Array<TableFilterMenuOption> = useMemo(
        () => {
            return (
                [{
                    isActive: flaggedContentMetadata.sort === 'lastFlaggedAt',
                    onSelect: () => {
                        onMetadataChange({sort: 'lastFlaggedAt', page_num: 0}, false);
                    },
                    text: "Latest Flags"
                }, {
                    isActive: flaggedContentMetadata.sort === 'flaggedCount',
                    onSelect: () => {
                        onMetadataChange({sort: 'flaggedCount', page_num: 0}, false);
                    },
                    text: "Most Flags"
                }]
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [flaggedContentMetadata]
    );

    const handleInfiniteScroll = async () => {
        if(isGettingFlaggedContent || isInfiniteScrolling || atEnd) {
            return;
        }

        try {
            setIsInfiniteScrolling(true);
            let res: any = await dispatch(getFlaggedContent({isUpdate: true, schoolId: activeSchool.tenantId})).unwrap();
            setAtEnd(res.isAtEnd);
        } catch(err) {
            console.log('FlaggedContent handleInfiniteScroll err', err);
            setAtEnd(true);
        } finally {
            setIsInfiniteScrolling(false);
        }
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(flaggedContentMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getFlaggedContent({isUpdate, flaggedContentMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('FlaggedContent onMetadataChange err', err);
        }
    };

    return (
        <div className="flagged-content">
            <PageHeader>
                <H5>
                    <Icon type="flag" />Flagged Content
                </H5>
            </PageHeader>

            <div className="flagged-content__inner school-feed__inner">
                <div className="school-feed__left-col">
                    <div className="school-feed__my-content-container">
                        <TableFilterMenu
                            label="View"
                            options={filterOptions}
                        />
                    </div>

                    {flaggedContent.length === 0 && !isGettingFlaggedContent && initialized ? (
                        <div className="school-feed__empty">
                            There's nothing here.
                        </div>
                    ) : (
                        <>
                            {isGettingFlaggedContent ? (
                                <ActivityIndicator />
                            ) : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={handleInfiniteScroll}
                                    hasMore={!atEnd}
                                >
                                    <Feed flaggedContent={flaggedContent} />

                                    {isInfiniteScrolling && (
                                        <div className="school-feed__infinite-scrolling-indicator">
                                            <ActivityIndicator color="white" />
                                        </div>
                                    )}

                                    {atEnd && !isInfiniteScrolling && (
                                        <div className="school-feed__at-end">
                                            End of list
                                        </div>
                                    )}
                                </InfiniteScroll>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FlaggedContent;
