import './TableFlaggedOnlyToggle.scss';

import React  from 'react';

import ToggleSwitch from "../../ToggleSwitch";

type Props = {
    label?: string
    onToggleChange: (flaggedOnly: boolean) => void,
    value: boolean,
}

const TableFlaggedOnlyToggle: React.FC<Props> = ({
    label,
    onToggleChange,
    value,
}) => {
    const handleChange = () => {
        onToggleChange(!value)
    };

    return (
        <ToggleSwitch
            checked={value}
            className="c-table-flagged-only-toggle"
            label={label || 'Filter by flagged content'}
            labelPosition="left"
            name="flaggedOnly"
            onClick={handleChange}
        />
    );
};

export default TableFlaggedOnlyToggle;
