import './ContentSummary.scss';

import React from 'react';
import moment from "moment";

import Deal from "../../types/Deal";
import EventPost from "../../types/EventPost";
import MarketplaceItem from "../../types/MarketplaceItem";
import Thread from "../../types/Thread";

import { formatAsDollarAmount } from "../../utils/utils";
import buildImageSrc from "../../utils/buildImageSrc";

import Button, { ButtonSizes, ButtonThemes } from "../Button";

type Props = {
    handleEditClick?: Function
    post?: Deal | EventPost | MarketplaceItem | Thread
}

const ContentSummary: React.FC<Props> = ({
    handleEditClick,
    post
}) => {
    if(!post) {
        return null;
    }

    let artifact = post.artifacts && post.artifacts[0];
    let description = '';

    switch(post.type) {
        case 'D':
            if(post.location) {
                description = `${post.location.name} •`;
            }

            description += ` ${moment(post.publishedAt).format('MMM D, yyyy')}`;
            break;

        case 'E':
            description = moment(post.startAt).format('MMM D, yyyy h:mmA');

            if(post.endAt) {
                description += ` - ${moment(post.endAt).format('MMM D, yyyy h:mmA')}`;
            }
            break;

        case 'I':
            if(post.profile) {
                description = `${post.profile.firstName} ${post.profile.lastName} •`;
            }

            if(post.freeItem === 'Y') {
                description += ` FREE`;
            } else {
                description += ` ${formatAsDollarAmount(post.price)}`;
            }
            break;
        case 'T':
            if(post.profile) {
                description = `${post.profile.firstName} ${post.profile.lastName} •`;
            }

            description += ` ${moment(post.publishedAt).format('MMM D, yyyy')}`;
            break;
    }

    return (
        <div className="c-content-summary">
            {artifact && (
                <div className="c-content-summary__artifact" style={{backgroundImage: `url(${buildImageSrc(artifact, '220')})`}} />
            )}

            <div className="c-content-summary__text">
                {post.title && (
                    <div className="c-content-summary__title">
                        {post.title}
                    </div>
                )}

                {description && (
                    <div className="c-content-summary__description">
                        {description}
                    </div>
                )}
            </div>

            {handleEditClick && (
                <Button
                    onClick={handleEditClick}
                    size={ButtonSizes.Small}
                    theme={ButtonThemes.Light}
                >
                    Edit
                </Button>
            )}
        </div>
    );
};

export default ContentSummary;
