import './ListNewsStories.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clone from 'clone';
import { useRouteMatch } from "react-router";

import NewsStory from "../../../../../types/NewsStory";
import TableColumn from "../../../../../types/TableColumn";

import { isArrayNullOrEmpty, setProfileTypeVisibilityInMetadata } from "../../../../../utils/utils";
import { LIST_TYPES } from "../../../../../utils/constants";
import { ListTypes } from "../../../../../utils/enums";

import {
    clearNewsStoriesMetadata,
    clearNewsStory,
    getNewsStories,
    setSearchTerm
} from "../../../../../store/slices/news";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Button, { ButtonSizes, ButtonThemes } from "../../../../../components/Button";
import CreateEditNewsStory from "../CreateEditNewsStory";
import GridList, { GridListTypes } from "../../../../../components/GridList";
import H5 from "../../../../../components/H5";
import Icon from "../../../../../components/Icon";
import ListTypeSelector from "../../../../../components/ListTypeSelector";
import NewContentModal from "../../../../../components/NewContentModal";
import NewsStoryTableMoreMenu from "../NewsStoryTableMoreMenu";
import PageHeader from "../../../../../components/PageHeader";
import Table from "../../../../../components/Table";
import TableFilterRow from "../../../../../components/Table/TableFilterRow";
import TableFilterRowDivider from "../../../../../components/Table/TableFilterRow/TableFilterRowDivider";
import TablePublishedAt from "../../../../../components/Table/TablePublishedAt";
import TableRowImage from "../../../../../components/Table/TableRowImage";
import TableSearch from "../../../../../components/TableSearch";

type Props = {
    onRowClick?: (newsStory: NewsStory) => void
}

const ListNewsStories: React.FC<Props> = ({
    onRowClick,
}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [selectedListType, setSelectedListType] = useState<ListTypes>(ListTypes.Table);
    const [showNewContentModal, setShowNewContentModal] = useState<boolean>(false);

    const { isGettingNewsStories, newsStories, newsStoriesMetadata, searchTerm } = useTypedSelector((state) => state.news);

    useEffect(() => {
        const initialize = async () => {
            try {
                let clonedNewsStoriesMetadata = clone(newsStoriesMetadata);
                clonedNewsStoriesMetadata = setProfileTypeVisibilityInMetadata(clonedNewsStoriesMetadata, params.profileType);
                await dispatch(getNewsStories({schoolId: params?.schoolId, newsStoriesMetadata: clonedNewsStoriesMetadata})).unwrap();
            } catch(err) {
                console.log('ListNewsStories initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            dispatch(clearNewsStory());
            dispatch(clearNewsStoriesMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Image',
                    Cell: ({row: { original }}) => (
                        <TableRowImage
                            artifact={original.artifacts && original.artifacts.length > 0 ? original.artifacts[0] : undefined}
                            flagged={original.flagged}
                        />
                    ),
                    hasImage: true,
                }, {
                    Header: 'Title',
                    accessor: 'title',
                    sortBy: 'title',
                }, {
                    Header: 'Author',
                    accessor: (d) => {
                        if(d.profile) {
                            return `${d.profile.firstName} ${d.profile.lastName}`;
                        } else {
                            return null;
                        }
                    },
                    sortBy: 'profileFirstName',
                }, {
                    Header: 'URL',
                    accessor: (d) => <a href={d.linkUrl} target="_blank" rel="noreferrer">{d.linkUrl}</a>,
                    sortable: false,
                }, {
                    Header: 'Date',
                    accessor: (d) => <TablePublishedAt post={d} />,
                    sortBy: 'publish',
                }
            ];

            if(!onRowClick) {
                columns.push({
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original),
                });
            }

            return columns;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const handleRowClick = ({original}) => {
        if(!onRowClick) {
            return null;
        }

        onRowClick(original);
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(newsStoriesMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            if(changes.search != null) {
                clonedMetadata.page_num = 0;
            }

            return await dispatch(getNewsStories({isUpdate, newsStoriesMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('ListNewsStories onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item: NewsStory) => {
        return (
            <NewsStoryTableMoreMenu
                getAfterDelete={() => onMetadataChange({}, false)}
                item={item}
                setShowNewContentModal={setShowNewContentModal}
            />
        );
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="news-stories">
            <PageHeader>
                <H5>
                    <Icon type="news" />

                    News Stories
                </H5>
            </PageHeader>

            <TableFilterRow>
                {onRowClick == null && (
                    <>
                        <ListTypeSelector
                            onListTypeSelected={(listType) => setSelectedListType(listType)}
                            selected={selectedListType}
                        />

                        <TableFilterRowDivider />
                    </>
                )}

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search News Stories"
                    searchTerm={searchTerm}
                    size="small"
                />

                {!onRowClick && (
                    <Button
                        onClick={() => setShowNewContentModal(true)}
                        size={ButtonSizes.Small}
                    >
                        New News Story
                    </Button>
                )}
            </TableFilterRow>

            {isGettingNewsStories || !initialized ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(newsStories) && initialized ? (
                        <>
                                No news stories to display. <Button
                                onClick={() => setShowNewContentModal(true)}
                                theme={ButtonThemes.Link}
                            >
                                Create One?
                            </Button>
                        </>
                    ) : (
                        <>
                            {selectedListType === LIST_TYPES.TABLE ? (
                                <Table
                                    columns={columns}
                                    data={newsStories}
                                    getData={onMetadataChange}
                                    onRowClick={onRowClick && handleRowClick}
                                    pageNumber={newsStoriesMetadata.page_num}
                                    pageSize={newsStoriesMetadata.page_size}
                                    sortBy={newsStoriesMetadata.sort}
                                    sortOrder={newsStoriesMetadata.order}
                                    totalNumberOfItems={newsStoriesMetadata.total}
                                />
                            ) : (
                                <GridList
                                    createCardButtonText="Publish a Story"
                                    createCardText="Link a news story and publish it to your users"
                                    isLastPage={Math.ceil(newsStoriesMetadata.total / newsStoriesMetadata.page_size) === newsStoriesMetadata.page_num + 1}
                                    items={newsStories}
                                    listType={GridListTypes.News}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: newsStoriesMetadata.page_num + 1})}
                                    onOverlayClick={onRowClick ? (item) => onRowClick(item) : null}
                                    onPreviousPageClick={() => onMetadataChange({page_num: newsStoriesMetadata.page_num - 1})}
                                    overlayButtonText={onRowClick ? 'Select' : 'Edit'}
                                    pageNumber={newsStoriesMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                <CreateEditNewsStory
                    close={() => setShowNewContentModal(false)}
                />
            </NewContentModal>
        </div>
    );
};

export default ListNewsStories;
