import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import IError from "../../types/IError";
import MarketplaceItem from "../../types/MarketplaceItem";
import Metadata from "../../types/Metadata";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import { generateMarketplaceItem, generateMarketplaceItemsMetadata } from '../../utils/generators';

import { addError } from "./errors";
import { RootState } from '../../store/reducers';

type GetMarketplaceItemsProps = {
    isUpdate?: boolean,
    schoolId?: number,
    marketplaceItemsMetadata?: Metadata
}

export const getMarketplaceItems = createAsyncThunk(
    'marketplace/getMarketplaceItems',
    async ({isUpdate, schoolId, marketplaceItemsMetadata}: GetMarketplaceItemsProps, {dispatch, getState}) => {
        try {
            if(!marketplaceItemsMetadata) {
                marketplaceItemsMetadata = clone((getState() as RootState).marketplace.marketplaceItemsMetadata);
            } else {
                marketplaceItemsMetadata = {...marketplaceItemsMetadata}
            }
            marketplaceItemsMetadata.type = 'I';

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.content.getContent(schoolId, buildQueryString(marketplaceItemsMetadata)));
            let marketplaceItems = res.data.data.items;
            marketplaceItemsMetadata.total = res.data.data.meta.total;
            return {marketplaceItems, marketplaceItemsMetadata};
        } catch(err) {
            console.log('getMarketplaceItems', err);
            err.friendlyMessage = 'Error getting the list of marketplace items. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetMarketplaceItemProps = {
    postId?: number | string
    schoolId?: number | string
}

export const getMarketplaceItem = createAsyncThunk(
    'marketplace/getMarketplaceItem',
    async ({postId, schoolId}: GetMarketplaceItemProps = {}, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            const res = await new Request(token).get(PATHS.events.getById(schoolId, postId));
            let marketplaceItem = res.data.data;
            marketplaceItem.isFree = !marketplaceItem.price;
            return marketplaceItem;
        } catch(err) {
            console.log('getMarketplaceItem', err);
            err.friendlyMessage = 'Error getting the marketplace item. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type SaveMarketplaceItemProps = {
    marketplaceItem: MarketplaceItem
}

export const saveMarketplaceItem = createAsyncThunk(
    'marketplace/saveMarketplaceItem',
    async ({marketplaceItem}: SaveMarketplaceItemProps, {dispatch, getState}) => {
        const { tenantId, postAsProfile } = (getState() as RootState).schools.activeSchool;

        const path = marketplaceItem.postId ?
            PATHS.marketplace.update(tenantId, marketplaceItem.postId) : PATHS.marketplace.create(tenantId);

        try {
            if (marketplaceItem.postId) {
                const res = await new Request((getState() as RootState).auth.token).put(path, {...marketplaceItem, tenantId});
                return res;
            } else {
                const res = await new Request((getState() as RootState).auth.token).post(path, {
                    ...marketplaceItem,
                    tenantId,
                    profileId: postAsProfile.profileId
                });
                return res;
            }
        } catch(err) {
            console.log('getMarketplaceItem', err);
            err.friendlyMessage = 'Error saving the marketplace item. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type DeleteMarketplaceItemProps = {
    marketplaceItem?: MarketplaceItem
}

export const deleteMarketplaceItem = createAsyncThunk(
    'marketplace/deleteMarketplaceItem',
    async ({marketplaceItem}: DeleteMarketplaceItemProps, {dispatch, getState}) => {
        const {tenantId} = (getState() as RootState).schools.activeSchool

        if(!marketplaceItem) {
            marketplaceItem = (getState() as RootState).marketplace.marketplaceItem;
        }

        try {
            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.marketplace.delete(tenantId, marketplaceItem.postId));
            return {
                ...res,
                postId: marketplaceItem.postId,
            };
        } catch(err) {
            console.log('getMarketplaceItem', err);
            err.friendlyMessage = 'Error deleting the marketplace item. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

interface marketplaceState {
    deleteMarketplaceItemError?: IError
    getMarketplaceItemError?: IError
    isDeletingMarketplaceItem: boolean
    isGettingMarketplaceItem: boolean
    isSavingMarketplaceItem: boolean
    marketplaceItem: MarketplaceItem
    saveMarketplaceItemError?: IError
    searchTerm: string
    marketplaceItems: Array<MarketplaceItem>
    marketplaceItemsMetadata: Metadata
    isGettingMarketplaceItems: boolean
    getMarketplaceItemsError: IError
}

export const initialState: marketplaceState = {
    marketplaceItem: generateMarketplaceItem(),
    isGettingMarketplaceItem: false,
    getMarketplaceItemError: undefined,
    isSavingMarketplaceItem: false,
    saveMarketplaceItemError: undefined,
    isDeletingMarketplaceItem: false,
    deleteMarketplaceItemError: undefined,

    searchTerm: '',
    marketplaceItems: [],
    marketplaceItemsMetadata: generateMarketplaceItemsMetadata(),
    isGettingMarketplaceItems: false,
    getMarketplaceItemsError: undefined
};

export const marketplaceSlice = createSlice({
    name: 'marketplace',
    initialState,
    reducers: {
        clearMarketplaceItem: (state) => {
            state.marketplaceItem = generateMarketplaceItem();
        },
        clearMarketplaceItemsMetadata: (state) => {
            state.marketplaceItemsMetadata = generateMarketplaceItemsMetadata();
            state.searchTerm = '';
        },
        setMarketplaceItem: (state, action) => {
            state.marketplaceItem = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
    extraReducers: ({addCase}) => {

        addCase(getMarketplaceItems.pending, (state, action) => {
            state.getMarketplaceItemsError = undefined;
            state.isGettingMarketplaceItems = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.marketplaceItemsMetadata) {
                state.marketplaceItemsMetadata = action.meta.arg.marketplaceItemsMetadata;
            }
        });
        addCase(getMarketplaceItems.fulfilled, (state, action) => {
            state.marketplaceItems = action.payload.marketplaceItems;
            state.marketplaceItemsMetadata = action.payload.marketplaceItemsMetadata;
            state.isGettingMarketplaceItems = false;
        });
        addCase(getMarketplaceItems.rejected, (state, action) => {
            state.getMarketplaceItemsError = action.error;
            state.isGettingMarketplaceItems = false;
        });

        addCase(getMarketplaceItem.pending, (state) => {
            state.getMarketplaceItemError = undefined;
            state.isGettingMarketplaceItem = true;
        });
        addCase(getMarketplaceItem.fulfilled, (state, action) => {
            state.isGettingMarketplaceItem = false;
            state.marketplaceItem = action.payload;
        });
        addCase(getMarketplaceItem.rejected, (state, action) => {
            state.getMarketplaceItemError = action.error as IError;
            state.isGettingMarketplaceItem = false;
        });

        addCase(saveMarketplaceItem.pending, (state) => {
            state.isSavingMarketplaceItem = true;
        });
        addCase(saveMarketplaceItem.fulfilled, (state, action) => {
            state.isSavingMarketplaceItem = false;
            state.saveMarketplaceItemError = undefined;
        });
        addCase(saveMarketplaceItem.rejected, (state, action) => {
            state.saveMarketplaceItemError = action.error as IError;
            state.isSavingMarketplaceItem = false;
        });

        addCase(deleteMarketplaceItem.pending, (state) => {
            state.deleteMarketplaceItemError = undefined;
            state.isDeletingMarketplaceItem = true;
        });
        addCase(deleteMarketplaceItem.fulfilled, (state) => {
            state.isDeletingMarketplaceItem = undefined;
        });
        addCase(deleteMarketplaceItem.rejected, (state, action) => {
            state.deleteMarketplaceItemError = action.error as IError;
            state.isDeletingMarketplaceItem = false;
        });
    }
});

export const { clearMarketplaceItem, clearMarketplaceItemsMetadata, setMarketplaceItem, setSearchTerm } = marketplaceSlice.actions;

export default marketplaceSlice.reducer;
