import './TenantActivityReportGenerator.scss';

import React, { useState } from 'react';
import moment from 'moment';
import clone from 'clone';
import { validated } from 'react-custom-validation';

import Tenant from '../../../../types/Tenant';
import Validation from '../../../../types/Validation';

import { handleDateChange } from '../../../../utils/handle-changes';
import { isDate, isEmailArray, isRequiredIfTrue } from '../../../../utils/validations';

import {
    ActivityReportGeneratorState,
    generateTenantActivityReport,
    setConfig,
} from '../../../../store/slices/activityReportGenerator';
import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';


import Button from '../../../../components/Button';
import ButtonRow from '../../../../components/ButtonRow';
import Checkbox from '../../../../components/Checkbox';
import Datepicker from '../../../../components/Datepicker';
import Form from '../../../../components/Form';
import FormColumn from '../../../../components/FormColumn';
import FormRow from '../../../../components/FormRow';
import SuccessModal from '../../../../components/Modal/SuccessModal';
import Textbox from '../../../../components/Textbox';
import { TooltipSide } from '../../../../components/Tooltip';
import ToggleSwitch from '../../../../components/ToggleSwitch';

type Props = {
    config: ActivityReportGeneratorState['config'],
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        emailAddresses: Validation
        endAt: Validation
        includeMyself: Validation
        startAt: Validation
        sendActivityReport: Validation
        sendDmReport: Validation
    }
}

enum FieldNames {
    EmailAddress = 'emailAddress',
    EndAt = 'endAt',
    IncludeMyself = 'includeMyself',
    SendActivityReport = 'sendActivityReport',
    SendDmReport = 'sendDmReport',
    StartAt = 'startAt',
}

const TenantActivityReportGeneratorForm: React.FC<Props> = ({ config, $field, $fieldEvent, $submit, $validation }) => {
    const dispatch = useAppDispatch();

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

    const { isRequestingReport } = useTypedSelector(state => state.activityReportGenerator);


    const handleChange = (name: FieldNames, value: Tenant | string | boolean) => {
        const clonedConfig: Props['config'] = clone(config);

        switch (true) {
            case name === FieldNames.EmailAddress:
                clonedConfig.emailAddresses = value as string;
                break;
            case name === FieldNames.EndAt:
                clonedConfig.endAt = value as string;
                break;
            case name === FieldNames.IncludeMyself:
                clonedConfig.includeMyself = value as boolean;
                break;
            case name === FieldNames.StartAt:
                clonedConfig.startAt = value as string;
                break;
            case name === FieldNames.SendActivityReport:
                clonedConfig.sendActivityReport = value as boolean;
                break;
            case name === FieldNames.SendDmReport:
                clonedConfig.sendDmReport = value as boolean;
                break;
            default:
                break;
        }
        dispatch(setConfig(clonedConfig));
    };
    const handleSave = async (isValid) => {
        if (!isValid) {
            return;
        }
        try {
            await dispatch(generateTenantActivityReport()).unwrap();
            $fieldEvent('reset');
            setShowSuccessModal(true);
        } catch (err) {
            console.log('CreateEditLandingPage handleSave err', err);
        }
    };

    return (
        <Form>
            <FormRow columnCount={2}>
                <FormColumn>
                    <Datepicker
                        dateFormat="yyyy-MM-dd"
                        disabled={isRequestingReport}
                        id="dateStartAt"
                        label="Select a Start Date"
                        maxDate={new Date()}
                        name="startAt"
                        placeholderText="mm-dd-yyyy"
                        selected={config.startAt || null}
                        showMonthDropdown
                        showYearDropdown
                        showToolTip
                        toolTipOptions={{
                            text: 'Note: if no date is selected, we will default to one week from today.',
                            side: TooltipSide.Right,
                        }}
                        validation={$validation.startAt}
                        {...$field(
                            FieldNames.StartAt,
                            (newDate) => handleDateChange(
                                handleChange,
                                FieldNames.StartAt,
                                null,
                                moment(newDate).format(),
                            ))}
                    />
                </FormColumn>

                <FormColumn>
                    <Datepicker
                        dateFormat="yyyy-MM-dd"
                        disabled={isRequestingReport}
                        id="dateEndAt"
                        maxDate={new Date()}
                        label="Select an End Date"
                        name="endAt"
                        placeholderText="mm-dd-yyyy"
                        selected={config.endAt || null}
                        showMonthDropdown
                        showYearDropdown
                        showToolTip
                        toolTipOptions={{
                            text: 'Note: if no date is selected, we will default to today.',
                            side: TooltipSide.Right,
                        }}
                        validation={$validation.endAt}
                        {...$field(
                            FieldNames.EndAt,
                            (newDate) => handleDateChange(
                                handleChange,
                                FieldNames.EndAt,
                                null,
                                moment(newDate).format(),
                            ))}
                    />
                </FormColumn>
            </FormRow>

            <FormRow className="tenant-activity-report-generator__add-email-container">
                <Textbox
                    label="Who should we send this report to?"
                    className="activity-report-generator__form-item"
                    id="emailAddressTextbox"
                    placeholder="jane@email.edu, john@email.edu, etc."
                    onChange={(event) => handleChange(FieldNames.EmailAddress, event.target.value)}
                />
            </FormRow>

            <FormRow>
                <Checkbox
                    checked={config.includeMyself}
                    id="chkIncludeMyself"
                    label="Include myself as a recipient in this report"
                    name="include-me"
                    onChange={() => handleChange(FieldNames.IncludeMyself, !config.includeMyself)}
                    validation={$validation.includeMyself}
                />
            </FormRow>

            <FormRow>
                <Checkbox
                    checked={config.sendDmReport}
                    id="chkSendDmReport"
                    label="Include the DM Report"
                    name="include-dm-report"
                    onChange={() => {
                        handleChange(FieldNames.SendDmReport, !config.sendDmReport)
                    }}
                    validation={$validation.sendDmReport}
                />
            </FormRow>

            <FormRow>
                <Checkbox
                    checked={config.sendActivityReport}
                    id="chkSendActivityReport"
                    label="Include the Active Users Report"
                    name="include-activity-report"
                    onChange={() => {
                        handleChange(FieldNames.SendActivityReport, !config.sendActivityReport)
                    }}
                    validation={$validation.sendActivityReport}
                />
            </FormRow>

            <ButtonRow className="tenant-activity-report-generator__button-row">
                <Button
                    disabled={isRequestingReport}
                    showActivityIndicator={isRequestingReport}
                    onClick={(e) => {
                        e.preventDefault();
                        $submit(() => handleSave(true),() => handleSave(false));
                    }}
                >
                    Generate Report
                </Button>
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    setShowSuccessModal(false);
                }}
                show={showSuccessModal}
            >
                <p>
                    Your report request has been received.
                </p>
                <p>
                    It may take a minute for the report{(config.tenants.length > 1 || config.includeMyself) && 's'} to
                    be delivered.
                </p>
            </SuccessModal>

        </Form>
    );
};

const activityReportGeneratorFormValidationConfig = (props: Props) => {
    const { emailAddresses, endAt, includeMyself, startAt, sendActivityReport, sendDmReport} = props.config;

    return {
        fields: ['emailAddresses', 'endAt', 'includeGlobalReport', 'includeMyself', 'startAt', 'sendActivityReport', 'sendDmReport'],
        validations: {
            emailAddresses: [
                [isEmailArray, emailAddresses],
            ],
            includeMyself: [
                [isRequiredIfTrue, emailAddresses, !includeMyself, 'Enter at least one email address, or select "Include myself as a recipient in this report"'],
            ],
            endAt: [
                [isDate, endAt],
            ],
            startAt: [
                [isDate, startAt],
            ],
            sendActivityReport: [
                [isRequiredIfTrue, sendDmReport, !sendActivityReport, 'Select at least one report to send'],
            ],
            sendDmReport: [
                [isRequiredIfTrue, sendActivityReport, !sendDmReport, 'Select at least one report to send'],
            ],
        },
    };
};

export default validated(activityReportGeneratorFormValidationConfig)(TenantActivityReportGeneratorForm);
