import React, { useEffect, useState } from 'react';
import { validated } from "react-custom-validation";
import clone from 'clone';
import { useHistory, useRouteMatch } from "react-router";

import Group from "../../../../../types/Group";
import IFile from "../../../../../types/IFile";
import Validation from "../../../../../types/Validation";

import { handleTextChange, handleCheckboxChange } from "../../../../../utils/handle-changes";
import { isRequired } from "../../../../../utils/validations";
import { PRIVACY_TYPES } from "../../../../../utils/constants";
import { ProfileTypes, YesOrNo } from "../../../../../utils/enums";

import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";
import { clearGroup, deleteGroup, getGroups, saveGroup, setGroup } from "../../../../../store/slices/groups";

import Button, { ButtonThemes, ButtonTypes } from "../../../../../components/Button";
import ButtonRow from "../../../../../components/ButtonRow";
import Checkbox from "../../../../../components/Checkbox";
import FileUpload from "../../../../../components/FileUpload";
import Form from "../../../../../components/Form";
import FormRow from "../../../../../components/FormRow";
import FormValidationMessage from "../../../../../components/FormValidationMessage";
import H4 from "../../../../../components/H4";
import Icon from "../../../../../components/Icon";
import Label from "../../../../../components/Label";
import Modal from "../../../../../components/Modal";
import SuccessModal from "../../../../../components/Modal/SuccessModal";
import Textbox from "../../../../../components/Textbox";

type Props = {
    close: Function
    group: Group
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        artifactId: Validation
        description: Validation
        name: Validation
        privacyType: Validation
    }
}

const CreateEditGroupForm: React.FC<Props> = ({
    close,
    group,
    $field,
    $fieldEvent,
    $submit,
    $validation,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const match = useRouteMatch();

    const [isUploadingArtifact, setIsUploadingArtifact] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const { isDeletingGroup, isSavingGroup } = useTypedSelector((state) => state.groups);

    useEffect(() => {
        if (group.artifact) {
            if(!group.artifactId ) {
                handleChange('artifactId', group.artifact.artifactId);
            }
        }

        return () => {
            setTimeout(() => dispatch(clearGroup()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name: string, value: any) => {
        if(group.forumTopicId && name === 'privacyType') {
            return null;
        }

        let clonedGroup = clone(group);
        clonedGroup[name] = value;

        if (name === 'disableChat') {
            clonedGroup.groupChatEnabled = value ? YesOrNo.No : YesOrNo.Yes;
        }

        if(name === 'artifactId') {
            delete clonedGroup.artifact;
        }

        dispatch(setGroup(clonedGroup));
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteGroup({})).unwrap();
            $fieldEvent('reset');
            history.push(`/school/${match.params.schoolId}/${match.params.profileType}/groups`);
        } catch (err) {
            console.log('CreateEditGroupForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleSave = async (isValid: boolean) => {
        if(!isValid) {
            return;
        }

        if (match.params.profileType === ProfileTypes.Admit) {
            handleChange('admit', 'Y');
        }

        try {
            await dispatch(saveGroup({})).unwrap();
            setShowSuccessModal(true);
            $fieldEvent('reset');
        } catch(err) {
            console.log('CreateEditDealForm handleSave err', err);
        }
    };

    const onUploadStart = () => {
        setIsUploadingArtifact(true);
    };

    const onUploadSuccess = (file: IFile, artifactId?: number) => {
        if (artifactId) {
            handleChange('artifactId', artifactId);
        }
        setIsUploadingArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingArtifact(false);
    };

    const onDelete = () => {
        handleChange('artifactId', null);
    };

    return (
        <>
            <H4>
                {group.forumTopicId ? 'Edit' : 'Create a'} Group
            </H4>

            <Form>
                <FormRow>
                    <FileUpload
                        disabled={isUploadingArtifact}
                        currentArtifact={group.artifact || null}
                        name="artifactId"
                        onDelete={onDelete}
                        onStart={onUploadStart}
                        onSuccess={onUploadSuccess}
                        onFailure={onUploadFailure}
                    />

                    {$validation.artifactId.isValid === false && $validation.artifactId.show === true ? (
                        <FormValidationMessage>
                            {$validation.artifactId.error.reason}
                        </FormValidationMessage>
                    ) : null}
                </FormRow>

                <FormRow>
                    <Textbox
                        disabled={isUploadingArtifact}
                        id="txtName"
                        label="Name"
                        name="name"
                        required
                        type="text"
                        validation={$validation.name}
                        value={group.name || ''}
                        {...$field('name', event => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                <FormRow>
                    <Textbox
                        disabled={isUploadingArtifact}
                        id="txtDescription"
                        label="Description"
                        name="description"
                        required
                        type="textarea"
                        validation={$validation.description}
                        value={group.description || ''}
                        {...$field('description', event => handleTextChange(handleChange, event))}
                    />
                </FormRow>

                {match.params.profileType !== ProfileTypes.Admit && (
                    <FormRow>
                        <Label>Type</Label>
                        <div className="create-edit-group__privacy-container">
                            <div
                                className={`create-edit-group__privacy-option ${group.privacyType === PRIVACY_TYPES.PUBLIC ? 'a-create-edit-group__privacy-type--active' : ''} ${group.forumTopicId ? 'a-create-edit-group__privacy-type--disabled' : ''}`}
                                onClick={() => handleChange('privacyType', PRIVACY_TYPES.PUBLIC)}
                            >
                                <div className="create-edit-group__privacy-option-icon-container">
                                    <Icon type="public" />
                                </div>

                                <div className="create-edit-group__privacy-option-title">
                                    Public
                                </div>

                                Open to all students
                            </div>

                            <div
                                className={`create-edit-group__privacy-option ${group.privacyType === PRIVACY_TYPES.PRIVATE ? 'a-create-edit-group__privacy-type--active' : ''} ${group.forumTopicId ? 'a-create-edit-group__privacy-type--disabled' : ''}`}
                                onClick={() => handleChange('privacyType', PRIVACY_TYPES.PRIVATE)}
                            >
                                <div className="create-edit-group__privacy-option-icon-container">
                                    <Icon type="private" />
                                </div>

                                <div className="create-edit-group__privacy-option-title">
                                    Private
                                </div>

                                Invite-only or request to join
                            </div>
                        </div>

                        {$validation.privacyType.isValid === false && $validation.privacyType.show === true ? (
                            <FormValidationMessage>
                                {$validation.privacyType.error.reason}
                            </FormValidationMessage>
                        ) : null}
                    </FormRow>
                )}

                {match.params.profileType === ProfileTypes.Admit && (
                    <FormRow>
                        <FormRow>
                            <Checkbox
                                label="Disable Group Chat"
                                checked={group.groupChatEnabled === YesOrNo.No}
                                id='chkDisableChat'
                                name="disableChat"
                                disabled={typeof group.forumTopicId !== 'undefined'}
                                onChange={(e) => handleCheckboxChange(handleChange, e)}
                            />
                        </FormRow>
                    </FormRow>
                )}

                <ButtonRow>
                    <Button
                        disabled={isUploadingArtifact}
                        onClick={(event) => {
                            event.preventDefault();
                            $submit(() => handleSave(true), () => handleSave(false));
                        }}
                        showActivityIndicator={isSavingGroup}
                        type={ButtonTypes.Submit}
                    >
                        {group.forumTopicId ? 'Save' : 'Create'} Group
                    </Button>

                    {group.forumTopicId && (
                        <Button
                            className="destructive"
                            disabled={isUploadingArtifact}
                            onClick={() => setShowDeleteConfirmationModal(true)}
                            theme={ButtonThemes.Link}
                        >
                            Delete
                        </Button>
                    )}
                </ButtonRow>
            </Form>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getGroups({schoolId: match?.params?.schoolId}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
            >
                <p>
                    Your group has been saved.
                </p>
                <p>
                    It may take a minute for your new group to show in feeds and lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingGroup}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this group?
            </Modal>
        </>
    );
};

function createEditGroupFormValidationConfig(props) {
    let { artifactId, description, name, privacyType }: Group = props.group;

    return {
        fields: ['artifactId', 'description', 'name', 'privacyType'],
        validations: {
            artifactId: [
                [isRequired, artifactId, 'An image is required']
            ],
            description: [
                [isRequired, description]
            ],
            name: [
                [isRequired, name]
            ],
            privacyType: [
                [isRequired, privacyType]
            ]
        }
    }
}
export default validated(createEditGroupFormValidationConfig)(CreateEditGroupForm);
