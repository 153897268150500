import React, { useEffect, useMemo } from 'react';
import clone from 'clone';

import {getSqsMessages} from '../../../../store/slices/engineering';
import {useAppDispatch} from "../../../../store";
import {useTypedSelector} from "../../../../store/reducers";

import {isArrayNullOrEmpty} from "../../../../utils/utils";
import {CAPABILITIES, userCan} from "../../../../utils/roles";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import H5 from "../../../../components/H5";
import Table from "../../../../components/Table";
import TableColumn from '../../../../types/TableColumn';

const SqsMessageTable: React.FC = () => {
    const dispatch = useAppDispatch();

    const { sqsMessagesMetadata, sqsMessages, isGettingSqsMessages} = useTypedSelector((state) => state.engineering);
    const { roles } = useTypedSelector((state) => state.auth);

    useEffect(() => {
        const initialize = async () => {
            if(isGettingSqsMessages) {
                return;
            }

            try {
                if(!isArrayNullOrEmpty(roles) && userCan(CAPABILITIES.VIEW_ENGINEERING, roles[0])) {
                    dispatch(getSqsMessages({}));
                }
            } catch(err) {
                console.log('CronJobsTable initialize err', err);
            }
        };

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatParameters = (parameters?: { [key: string]: any}) => {
        if (!parameters) {
            return 'None';
        }
        else {
            return JSON.stringify(parameters, null, 2);
        }
    }

    const columns = useMemo(
        () => {
            let columnsArray: Array<TableColumn> = [
                {
                    Header: 'ID',
                    accessor: '_id'
                }, {
                    Header: 'Task',
                    accessor: 'type'
                }, {
                    Header: 'Created',
                    accessor: 'createdAt'
                }, {
                    Header: 'Parameters',
                    accessor: (row) => formatParameters(row.parameters)
                }, {
                    Header: 'Sender',
                    accessor: 'sender'
                }, {
                    Header: 'Queue URL',
                    accessor: 'queueUrl'
                },
            ];

            return columnsArray;
        },
        []
    );

    const data = useMemo(
        () => {
            return sqsMessages;
        },
        [sqsMessages]
    );

    const onMetadataChange = async (changes, isUpdate) => {
        console.log('onMetadataChange', changes, isUpdate);
        try {
            let clonedMetadata = clone(sqsMessagesMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getSqsMessages({isUpdate, sqsMessagesMetadata: clonedMetadata}));
        } catch(err) {
            console.log('SqsMessagesTable onMetadataChange err', err);
        }
    };

    return (
        <div className="engineering">
            <H5>
                SQS Messages
            </H5>

            <div>
                {isGettingSqsMessages ? (
                    <ActivityIndicator size="large" />
                ) : (
                    <>
                        <Table
                            columns={columns}
                            data={data}
                            getData={onMetadataChange}
                            pageNumber={sqsMessagesMetadata.page_num}
                            pageSize={sqsMessagesMetadata.page_size}
                            totalNumberOfItems={sqsMessagesMetadata.total}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default SqsMessageTable;
