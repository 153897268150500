import './AdmitConversionDashboard.scss';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getConversionDashboard } from '../../../../../../store/slices/admitDashboard';
import { useTypedSelector } from '../../../../../../store/reducers';

import ActivityIndicator from '../../../../../../components/ActivityIndicator';
import Card from '../../../../../../components/Card';

const AdmitConversionDashboard: React.FC = () => {
    const dispatch = useDispatch();

    const {conversionReport, isGettingConversionReport} = useTypedSelector((state) => state.admitDashboard);

    useEffect(() => {
        const initialize = async () => {
            if(isGettingConversionReport) {
                return;
            }

            try {
                dispatch(getConversionDashboard());
            } catch(err) {
                console.log('AdmitConversionDashboard initialize err', err);
            }
        };

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isGettingConversionReport ? <ActivityIndicator size={'large'} /> : (
        <Card>
            <h1>Admit Conversion Data</h1>

            <div className='conversion-data-container'>
                <div className='conversion-data-label'>
                    Active
                    <div className='conversion-data-value'>{conversionReport.active}</div>
                </div>

                <div className='conversion-data-label'>
                    Promoted
                    <div className='conversion-data-value'>{conversionReport.promoted}</div>
                </div>

                <div className='conversion-data-label'>
                    Pending
                    <div className='conversion-data-value'>{conversionReport.pending}</div>
                </div>

                <div className='conversion-data-label'>
                    Declined
                    <div className='conversion-data-value'>{conversionReport.declined}</div>
                </div>
            </div>


        </Card>
    );
}

export default AdmitConversionDashboard;
