import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import AdmitsMetadata from "../../types/AdmitsMetadata";
import IError from "../../types/IError";
import Metadata from "../../types/Metadata";
import Profile from "../../types/Profile";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import { sanitizeDetails } from "../../utils/elements";
import { buildErrorObject } from "../../utils/errors";
import { generateAdmitsMetadata } from "../../utils/generators";

import { addError } from "./errors";
import { deleteStudent } from "./students";
import { RootState } from '../reducers';

type DeclineAdmitProps = {
    admitProfileId?: number
    schoolId?: number | string
}

export const declineAdmit = createAsyncThunk(
    'admits/declineAdmit',
    async ({admitProfileId, schoolId}: DeclineAdmitProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            await new Request((getState() as RootState).auth.token).put(PATHS.admits.decline(schoolId, admitProfileId));
            return admitProfileId;
        } catch(err) {
            console.log('declineAdmit error', err);
            let friendlyMessage = 'Error changing this admit account. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type DeclineAllPendingAdmitsProps = {
    schoolId?: number
}

export const declineAllPendingAdmits = createAsyncThunk(
    'admits/declineAllPendingAdmits',
    async ({ schoolId }: DeclineAllPendingAdmitsProps, { getState, rejectWithValue }) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            await new Request((getState() as RootState).auth.token).post(PATHS.admits.declineAllPending(schoolId));
            return { success: true };
        } catch(err) {
            console.log('declineAdmit error', err);
            let friendlyMessage = 'Error declining all admits. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type DeleteAdmitProps = {
    admitProfileId?: number
    schoolId?: number | string
}

export const deleteAdmit = createAsyncThunk(
    'admits/deleteAdmit',
    async ({admitProfileId, schoolId}: DeleteAdmitProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            await new Request((getState() as RootState).auth.token).delete(PATHS.admits.delete(schoolId, admitProfileId));
            return admitProfileId;
        } catch(err) {
            console.log('deleteAdmit error', err);
            let friendlyMessage = 'Error removing this admit account. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);


type GetAdmitsProps = {
    admitsMetadata?: Metadata
    isUpdate?: boolean
    schoolId?: number | string
}

export const getAdmits = createAsyncThunk(
    'admits/getAdmits',
    async ({admitsMetadata, isUpdate, schoolId}: GetAdmitsProps, {dispatch, getState}) => {
        try {
            if(!admitsMetadata) {
                admitsMetadata = clone((getState() as RootState).admits.admitsMetadata);
            } else {
                admitsMetadata = {...admitsMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.admits.getAdmits(schoolId, buildQueryString(admitsMetadata)));
            let admits = res.data.data.items;
            admits.forEach((s, i) => {
                admits[i] = sanitizeDetails(s);
            })
            admitsMetadata.total = res.data.data.meta.total;
            return {admits, admitsMetadata};
        } catch(err) {
            console.log('getAdmits error', err);
            err.friendlyMessage = 'Error getting a list of admits. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type InviteAdmitsViaCsvImportProps = {
    csvFile: File
    schoolId?: number | string
    useTenantMessage?: boolean
}

export const inviteAdmitsViaCsvImport = createAsyncThunk(
    'admits/inviteAdmitsViaCsvImport',
    async ({csvFile, useTenantMessage, schoolId}: InviteAdmitsViaCsvImportProps, {dispatch, getState, rejectWithValue}) => {
        console.log('inviteAdmitsViaCsvImport', csvFile, schoolId);
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const defaultWelcomeMessage = (getState() as RootState).admits.defaultWelcomeMessage;

            let formData = new FormData();
            if (defaultWelcomeMessage) {
                formData.append('welcomeMessage', defaultWelcomeMessage);
            }
            formData.append('useTenantMessage',  useTenantMessage ? 'true' : 'false')
            formData.append('csv', csvFile);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const res = await new Request((getState() as RootState).auth.token).post(PATHS.admits.inviteViaCsvImport(schoolId), formData, config);
            return res;
        } catch(err) {
            console.log('inviteAdmitsViaCsvImport error', err);
            let friendlyMessage = 'Error importing users via CSV. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type PromoteAdmitToStudentProps = {
    admitProfileId?: number
    schoolId?: number | string
}

export const promoteAdmitToStudent = createAsyncThunk(
    'admits/promoteAdmitToStudent',
    async ({admitProfileId, schoolId}: PromoteAdmitToStudentProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            await new Request((getState() as RootState).auth.token).put(PATHS.admits.promote(schoolId, admitProfileId));
            return admitProfileId;
        } catch(err) {
            console.log('promoteAdmit error', err);
            let friendlyMessage = 'Error promoting this admit account to student. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type UpdateAdmitWelcomeMessageProps = {
    admitProfileId?: number
    schoolId?: number | string
    welcomeMessage?: string
}

export const updateAdmitWelcomeMessage = createAsyncThunk(
    'admits/updateAdmitWelcomeMessage',
    async ({admitProfileId, schoolId, welcomeMessage}: UpdateAdmitWelcomeMessageProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if (!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            await new Request((getState() as RootState).auth.token).put(PATHS.admits.updateWelcomeMessage(schoolId, admitProfileId), {welcomeMessage});
            return admitProfileId;
        } catch(err) {
            console.log('updateAdmitWelcomeMessage error', err);
            let friendlyMessage = 'Error updating this admit account\'s welcome message. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

interface AdmitsState {
    admits: Array<Profile>,
    admitsMetadata: AdmitsMetadata,
    declineAdmitError?: IError
    declineAllPendingAdmitsError?: IError
    defaultWelcomeMessage?: string
    deleteAdmitError?: IError
    getAdmitsError?: IError
    inviteAdmitsViaCsvImportError?: IError
    isDecliningAdmit: boolean
    isDecliningAllPendingAdmits: boolean
    isDeletingAdmit: boolean
    isGettingAdmits: boolean
    isPromotingAdmitToStudent: boolean
    isInvitingAdmitsViaCsvImport: boolean
    isUpdatingAdmit: boolean
    promoteAdmitToStudentError?: IError
    searchTerm: string
    updateAdmitError?: IError
    welcomeMessage?: string
}

const initialState: AdmitsState = {
    admits: [],
    admitsMetadata: generateAdmitsMetadata(),
    defaultWelcomeMessage: '',
    searchTerm: '',
    isDecliningAdmit: false,
    isDeletingAdmit: false,
    isGettingAdmits: false,
    isInvitingAdmitsViaCsvImport: false,
    isPromotingAdmitToStudent: false,
    isUpdatingAdmit: false,
    declineAdmitError: undefined,
    deleteAdmitError: undefined,
    getAdmitsError: undefined,
    inviteAdmitsViaCsvImportError: undefined,
    promoteAdmitToStudentError: undefined,
    updateAdmitError: undefined,
};

export const admitsSlice = createSlice({
    name: 'admits',
    initialState,
    reducers: {
        clearAdmitsMetadata: (state) => {
            state.admitsMetadata = generateAdmitsMetadata();
        },
        clearDeclineAdmitError: (state) => {
            state.declineAdmitError = undefined;
        },
        clearDeleteAdmitError: (state) => {
            state.deleteAdmitError = undefined;
        },
        clearInviteAdmitsViaCsvImportError: (state) => {
            state.inviteAdmitsViaCsvImportError = undefined;
        },
        clearPromoteAdmitToStudentError: (state) => {
            state.promoteAdmitToStudentError = undefined;
        },
        clearUpdateAdmitError: (state) => {
            state.updateAdmitError = undefined;
        },
        clearWelcomeMessage: (state) => {
            state.welcomeMessage = undefined;
        },
        setDefaultWelcomeMessage: (state, action) => {
            state.defaultWelcomeMessage = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setWelcomeMessage: (state, action) => {
            state.welcomeMessage = action.payload;
        }

    },
    extraReducers: ({addCase}) => {
        addCase(declineAdmit.pending, (state) => {
            state.declineAdmitError = undefined;
            state.isDecliningAdmit = true;
        });
        addCase(declineAdmit.fulfilled, (state, action) => {
            const admitProfileId = action.payload;
            state.admits = clone(state.admits).filter((admit) => admit.admitProfile.admitProfileId !== admitProfileId);
            state.isDecliningAdmit = false;
        });
        addCase(declineAdmit.rejected, (state, action) => {
            state.declineAdmitError = action.payload;
            state.isDecliningAdmit = false;
        });

        addCase(declineAllPendingAdmits.pending, (state) => {
            state.declineAllPendingAdmitsError = undefined;
            state.isDecliningAllPendingAdmits = true;
        });
        addCase(declineAllPendingAdmits.fulfilled, (state, action) => {
            state.isDecliningAllPendingAdmits = false;
        });
        addCase(declineAllPendingAdmits.rejected, (state, action) => {
            state.declineAllPendingAdmitsError = action.payload;
            state.isDecliningAllPendingAdmits = false;
        });

        addCase(deleteAdmit.pending, (state) => {
            state.deleteAdmitError = undefined;
            state.isDeletingAdmit = true;
        });
        addCase(deleteAdmit.fulfilled, (state, action) => {
            const admitProfileId = action.payload;
            state.admits = clone(state.admits).filter((admit) => admit.admitProfile.admitProfileId !== admitProfileId);
            state.isDeletingAdmit = false;
        });
        addCase(deleteStudent.rejected, (state, action) => {
            state.deleteAdmitError = action.payload;
            state.isDeletingAdmit = false;
        });

        addCase(getAdmits.pending, (state, action) => {
            state.getAdmitsError = undefined;
            state.isGettingAdmits = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.admitsMetadata) {
                state.admitsMetadata = action.meta.arg.admitsMetadata;
            }
        });
        addCase(getAdmits.fulfilled, (state, action) => {
            state.admits = action.payload.admits;
            state.admitsMetadata = action.payload.admitsMetadata;
            state.isGettingAdmits = false;
        });
        addCase(getAdmits.rejected, (state, action) => {
            state.getAdmitsError = action.error;
            state.isGettingAdmits = false;
        });

        addCase(inviteAdmitsViaCsvImport.pending, (state) => {
            state.inviteAdmitsViaCsvImportError = undefined;
            state.isInvitingAdmitsViaCsvImport = true;
        });
        addCase(inviteAdmitsViaCsvImport.fulfilled, (state) => {
            state.isInvitingAdmitsViaCsvImport = false;
        });
        addCase(inviteAdmitsViaCsvImport.rejected, (state, action) => {
            state.inviteAdmitsViaCsvImportError = action.payload as IError;
            state.isInvitingAdmitsViaCsvImport = false;
        });

        addCase(promoteAdmitToStudent.pending, (state) => {
            state.promoteAdmitToStudentError = undefined;
            state.isPromotingAdmitToStudent = true;
        });
        addCase(promoteAdmitToStudent.fulfilled, (state, action) => {
            const admitProfileId = action.payload;
            state.admits = clone(state.admits).filter((admit) => admit.admitProfile.admitProfileId !== admitProfileId);
            state.isPromotingAdmitToStudent = false;
        });
        addCase(promoteAdmitToStudent.rejected, (state, action) => {
            state.promoteAdmitToStudentError = action.payload;
            state.isPromotingAdmitToStudent = false;
        });

        addCase(updateAdmitWelcomeMessage.pending, (state) => {
            state.updateAdmitError = undefined;
            state.isUpdatingAdmit = true;
        });
        addCase(updateAdmitWelcomeMessage.fulfilled, (state, action) => {
            state.isUpdatingAdmit = false;
        });
        addCase(updateAdmitWelcomeMessage.rejected, (state, action) => {
            state.updateAdmitError = action.payload;
            state.isUpdatingAdmit = false;
        });
    }
});

export const { clearAdmitsMetadata, clearDeclineAdmitError, clearDeleteAdmitError, clearInviteAdmitsViaCsvImportError, clearPromoteAdmitToStudentError, clearUpdateAdmitError, clearWelcomeMessage, setSearchTerm , setDefaultWelcomeMessage, setWelcomeMessage} = admitsSlice.actions;

export default admitsSlice.reducer;
