import './ViewGroupActivity.scss';

import React from 'react';

import { isArrayNullOrEmpty } from "../../../../../../utils/utils";

import { useTypedSelector } from "../../../../../../store/reducers";

import Feed from "../../../../../../components/Feed";
import Card from "../../../../../../components/Card";
import {PRIVACY_TYPES} from "../../../../../../utils/constants";
import { ProfileTypes } from '../../../../../../utils/enums';

const ViewGroupActivity: React.FC = () => {
    const { group, groupFeed } = useTypedSelector((state) => state.groups);
    const { postAsProfile} = useTypedSelector((state) => state.schools.activeSchool);

    const shouldHideActivity = () => {
        //return group.privacyType === PRIVACY_TYPES.PRIVATE && group.ownerProfileId !== profileId;
    };

    return (
        <div className="view-group-activity">
            {(group.privacyType === PRIVACY_TYPES.PUBLIC || group.isFollowing || postAsProfile.profileType === ProfileTypes.Global) ? (
                <>
                    {isArrayNullOrEmpty(groupFeed) || shouldHideActivity() ? (
                        <Card>
                            No activity in this group.
                        </Card>
                    ) : (
                        <Feed
                            disableCommenting={!group.isFollowing}
                            items={groupFeed}
                        />
                    )}
                </>
            ) : (
                <Card>
                    Join this group to see whats being talked about.
                </Card>
            )}

        </div>
    );
};

export default ViewGroupActivity;
