import './FlaggedIcon.scss';

import React from 'react';
import Icon from "../index";

type Props = {
    className?: string
}

const FlaggedIcon: React.FC<Props> = ({
    className,
}) => {
    return (
        <div className={`c-flagged-icon ${className || ''}`}>
            <Icon type="flag" />
        </div>
    );
};

export default FlaggedIcon;
