import React, { useEffect, useState } from 'react';
import clone from 'clone';
import { validated } from "react-custom-validation";
import { useRouteMatch } from "react-router";

import Resource from "../../../../../types/Resource";
import Validation from "../../../../../types/Validation";

import { handleTextChange } from "../../../../../utils/handle-changes";
import { isRequired } from "../../../../../utils/validations";

import {
    clearResource,
    deleteResource,
    getResources,
    saveResource,
    setResource
} from "../../../../../store/slices/resources";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import Button, { ButtonThemes, ButtonTypes } from "../../../../../components/Button";
import ButtonRow from "../../../../../components/ButtonRow";
import Form from "../../../../../components/Form";
import FormRow from "../../../../../components/FormRow";
import H4 from "../../../../../components/H4";
import Modal from "../../../../../components/Modal";
import SuccessModal from "../../../../../components/Modal/SuccessModal";
import Textbox from "../../../../../components/Textbox";

type Props = {
    close?: () => void
    resource: Resource,
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        linkUrl: Validation
        title: Validation
    }
}

const CreateEditResourceForm: React.FC<Props> = ({
    close,
    resource,
    $field,
    $fieldEvent,
    $submit,
    $validation,
}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

    const { isDeletingResource, isSavingResource } = useTypedSelector((state) => state.resources);

    useEffect(() => {
        if(!resource.tenantResourceId) {
            handleChange(`${params.profileType}Visibility`, true);
        }

        return () => {
            setTimeout(() => dispatch(clearResource()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name: string, value: any) => {
        let clonedResource = clone(resource);
        clonedResource[name] = value;
        dispatch(setResource(clonedResource));
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteResource({})).unwrap();
            $fieldEvent('reset');
            close && close();
        } catch (err) {
            console.log('CreateEditResourceForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleSave = async (isValid: boolean) => {
        if(!isValid) {
            return;
        }

        try {
            await dispatch(saveResource({})).unwrap();
            setShowSuccessModal(true);
            $fieldEvent('reset');
        } catch(err) {
            console.log('CreateEditResourceForm handleSave err', err);
        }
    };

    return (
        <Form>
            <H4>
                {resource.tenantResourceId ? 'Edit ' : 'Create '}Resource
            </H4>

            <FormRow>
                <Textbox
                    id="txtTitle"
                    label="Title"
                    name="title"
                    required
                    type="text"
                    validation={$validation.title}
                    value={resource.title || ''}
                    {...$field('title', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtLinkUrl"
                    label="Link URL"
                    name="linkUrl"
                    required
                    type="text"
                    validation={$validation.linkUrl}
                    value={resource.linkUrl || ''}
                    {...$field('linkUrl', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <ButtonRow>
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                    showActivityIndicator={isSavingResource}
                    type={ButtonTypes.Submit}
                >
                    {resource.tenantResourceId ? 'Save' : 'Create'} Resource
                </Button>

                {resource.tenantResourceId && (
                    <Button
                        className="destructive"
                        disabled={isSavingResource}
                        onClick={() => setShowDeleteConfirmationModal(true)}
                        theme={ButtonThemes.Link}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getResources({profileType: params.profileType}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
                title="Nice job!"
            >
                <p>
                    Your resource has been saved.
                </p>
                <p>
                    It may take a minute for your changes to show in app and admin lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingResource}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this resource?
            </Modal>
        </Form>
    );
};

function createEditResourceFormValidationConfig(props) {
    let { linkUrl, title }: Resource = props.resource;

    return {
        fields: ['linkUrl', 'question'],
        validations: {
            linkUrl: [
                [isRequired, linkUrl]
            ],
            title: [
                [isRequired, title]
            ],
        }
    }
}
export default validated(createEditResourceFormValidationConfig)(CreateEditResourceForm);
