import './ActivityAlerts.scss'

import React, { useEffect, useState } from "react";
import moment from "moment";

import { useAppDispatch } from "../../../../../store";
import { useHistory, useRouteMatch } from "react-router";
import { useTypedSelector } from "../../../../../store/reducers";

import { getActivityAlerts, markActivityAlertsAsViewed } from '../../../../../store/slices/activity-alerts';

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Avatar from "../../../../../components/Avatar";
import Button, { ButtonSizes, ButtonThemes } from "../../../../../components/Button";
import Card from "../../../../../components/Card";
import Icon from "../../../../../components/Icon";

type Props = {
    limit?: number
}

const ActivityAlerts: React.FC<Props> = ({limit}) => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();
    const history = useHistory();

    const [initialized, setInitialized] = useState(false);
    const [truncateActivityAlerts, setTruncateActivityAlerts] = useState(true);
    const [loadedActivityAlerts, setLoadedActivityAlerts] = useState([]);
    const [atEnd, setAtEnd] = useState(true);

    const {activityAlerts, isGettingActivityAlerts, readyToMarkActivityAlertsAsRead} = useTypedSelector((state) => state.activityAlerts);

    useEffect(() => {
        const initialize = async () => {
            try {
                const res = await dispatch(getActivityAlerts({schoolId: match?.params?.schoolId})).unwrap();
                setAtEnd(res.isAtEnd);
            } catch (err) {
                console.log('SchoolFeed initialize err', err);
            } finally {
                setInitialized(true);
            }
        };

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params?.schoolId]);

    useEffect(() => {
        if (limit) {
            setLoadedActivityAlerts(activityAlerts.slice(0, limit));
        } else {
            setLoadedActivityAlerts(activityAlerts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityAlerts]);

    useEffect(() => {
        if (truncateActivityAlerts) {
            setLoadedActivityAlerts(activityAlerts.slice(0, limit));
        } else {
            setLoadedActivityAlerts(activityAlerts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [truncateActivityAlerts]);

    useEffect(() => {
        const markAsRead = async () => {
            if (readyToMarkActivityAlertsAsRead) {
                let hasUnviewed = false;
                for (let ii = 0; ii < loadedActivityAlerts.length; ii++) {
                    if (loadedActivityAlerts[ii].viewed === 'N') {
                        hasUnviewed = true;
                    }
                }
                if (hasUnviewed) {
                    await dispatch(markActivityAlertsAsViewed({schoolId: match?.params?.schoolId})).unwrap();
                }
            }
        }
        markAsRead()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params?.schoolId, readyToMarkActivityAlertsAsRead, loadedActivityAlerts]);

    const renderHistoryUrl = (item) => {
        if (item.forumTopic) {
            return `/school/${match?.params?.schoolId}/${match?.params?.profileType}/group/${item.forumTopic.forumTopicId}/activity`
        }
        else if (item.subjectPost) {
            return `/school/${match?.params?.schoolId}/${match?.params?.profileType}/group/${item.subjectPost.forumTopicId}/activity`
        }
        else {
            return ''
        }
    }

    const navOnClick = (item, type: 'profile' | 'group' ) => {
        console.log("Item: ", item)
        if (type === 'profile') {
            return history.push(`/school/${match?.params?.schoolId}/${match?.params?.profileType}/students/${item.activityInitiator?.profileId}/posts`);
        }
        else {
            return history.push(renderHistoryUrl(item));
        }
    }

    const ActivityAlert = ({item, index}) => {
        return (
            <div key={index} className="c-activity-item">
                <div role="link" onClick={() => navOnClick(item, 'profile')}>
                    <Avatar className="c-avatar" artifact={item.activityInitiator.artifact}/>
                </div>
                <div className="c-activity-item__data">
                    <strong role="link" onClick={() => navOnClick(item, 'profile')}>{item.activityInitiator.firstName + ' ' + item.activityInitiator.lastName}</strong>
                    {' ' + item.activityString + ' '}
                    <strong role="link" onClick={() => navOnClick(item, 'group')}>{item.forumTopic ? item.forumTopic.name : item.subjectPost?.title}</strong>
                    <div className="c-activity-item__meta">{item.subjectProfile.firstName + ' ' + item.subjectProfile.lastName} • {moment(item.createdAt).fromNow()}</div>
                </div>
            </div>
        );
    }

    return (
        <Card
            className="c-activity-alert"
            title="Activity Alerts"
        >
            {loadedActivityAlerts.length === 0 && !isGettingActivityAlerts && initialized ? (
                <div className="c-activity-alert-items__empty">
                    There's nothing here.
                </div>
            ) : (
                <>
                    {isGettingActivityAlerts && !initialized ? (
                        <ActivityIndicator/>
                    ) : (
                        <>
                            {loadedActivityAlerts.map((activityItem, index) => {
                                if (!activityItem) {
                                    return null;
                                }
                                return (activityItem.viewed === 'N' ? (
                                        <div className="c-activity-alert-item">
                                            <ActivityAlert index={index} item={activityItem}/>
                                            <div className='c-activity-alert-item-icon'/>
                                        </div>
                                    ) : (
                                        <div className="c-activity-alert-item">
                                            <ActivityAlert index={index} item={activityItem}/>
                                        </div>
                                    )
                                );
                            })}
                            {(limit && activityAlerts.length > limit) && (
                                <Button
                                    className="c-activity-alert-items__more-button"
                                    theme={ButtonThemes.Light}
                                    size={ButtonSizes.Small}
                                    onClick={() => setTruncateActivityAlerts(!truncateActivityAlerts)}
                                    disabled={atEnd}
                                >
                                    View {truncateActivityAlerts ? 'More' : 'Less'}
                                    {truncateActivityAlerts && (<Icon type="caret-down"/>)}
                                </Button>
                            )}
                        </>
                    )}
                </>
            )}
        </Card>
    );
}

export default ActivityAlerts;
