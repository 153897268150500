import './TabNav.scss';

import React from 'react';

import Tab from "../../types/Tab";

import { isArrayNullOrEmpty } from '../../utils/utils';

import Button from '../Button';

type Props = {
    className?: string
    tabs: Array<Tab>
}

const TabNav: React.FC<Props> = ({
    className = '',
    tabs
}) => {
    if(isArrayNullOrEmpty(tabs)) {
        return null;
    }

    return (
        <nav className={`c-tabnav ${className}`}>
            {tabs.map((tab) => (
                <Button
                    className={`c-tabnav__tab ${tab.isActive ? `a-tabnav__tab--active` : ''}`}
                    disabled={tab.disabled}
                    key={tab.text}
                    noStyles
                    onClick={tab.onClick}
                >
                    {tab.text}
                </Button>
            ))}
        </nav>
    );
};

export default TabNav;
