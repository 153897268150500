import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import clone from 'clone';

import { getSchools, setSearchTerm } from '../../../../../store/slices/schools';
import { useAppDispatch } from '../../../../../store';
import { useTypedSelector } from '../../../../../store/reducers';

import TableColumn from '../../../../../types/TableColumn';
import Tenant from '../../../../../types/Tenant';

import Button, { ButtonThemes } from '../../../../../components/Button';
import Icon from '../../../../../components/Icon';
import Modal from '../../../../../components/Modal';
import Table from '../../../../../components/Table';
import TableSearch from '../../../../../components/TableSearch';
import TableFilterRow from '../../../../../components/Table/TableFilterRow';

type SelectTenantModalProps = {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    handleSelectSchool: (item: Tenant) => void;
    handleSelectAllSchools?: () => void;
    selectedSchools: Tenant[];
};

const SelectTenantModal: React.FC<SelectTenantModalProps> = ({
    show,
    setShow,
    handleSelectSchool,
    handleSelectAllSchools,
    selectedSchools,
}) => {
    const dispatch = useAppDispatch();

    const {schools, isGettingSchools, schoolsMetadata, searchTerm} = useTypedSelector((state) => state.schools);

    useEffect(() => {
        const intialize = async () => {
            await dispatch(getSchools({
                isUpdate: false,
                schoolsMetadata: {
                    page_num: 0,
                    page_size: 100,
                    sort: 'name',
                    order: 'asc',
                    search: '',
                }
            })).unwrap();
        }
        intialize();

    }, []);

    const columns: TableColumn[] = [{
        accessor: 'name',
        Header: 'Name',
    }, {
        accessor: 'selected',
        Header: 'Selected',
        Cell: ({row}) => {
            return selectedSchools.map(s => s.tenantId).includes(row.original.tenantId) ? <Icon type='check' /> : '';
        }
    }];

    const data = useMemo(() => {
        return schools.map(school => {
            return {
                tenantId: school.tenantId,
                name: school.name,
                selected: selectedSchools.map(school => school.tenantId).includes(school.tenantId)
            }
        });
    }, [schools, selectedSchools.length]);

    const handleSelect = (item: Tenant) => {
        handleSelectSchool(item);
    }

    const onMetadataChange = async (changes, isUpdate) => {
        try {
            let clonedMetadata = clone(schoolsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getSchools({isUpdate, schoolsMetadata: clonedMetadata}));
        } catch(err) {
            console.log('SchoolsList onMetadataChange err', err);
        }

    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <Modal
            show={show}
            confirmButtonDisabled={selectedSchools.length === 0}
            confirmButtonText="Continue"
            confirmButtonOnClick={() => setShow(false)}
            title="Select One Or More Schools"
            className={'wide'}
        >
            <TableFilterRow>
                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search School Names"
                    searchTerm={searchTerm}
                    size="small"
                />
                <Button
                    onClick={handleSelectAllSchools}
                    theme={ButtonThemes.Secondary}
                >
                    Select {selectedSchools.length > 0 ? 'None' : 'All'}
                </Button>
            </TableFilterRow>

            <Table
                columns={columns}
                data={data}
                getData={onMetadataChange}
                pageNumber={schoolsMetadata.page_num}
                pageSize={schoolsMetadata.page_size}
                totalNumberOfItems={schoolsMetadata.total}
                showPagination={false}
                disablePending={isGettingSchools}
                onRowClick={(item) => {
                    handleSelect(item.original);
                }}
            />
        </Modal>
    )
}

export default SelectTenantModal;
