import './SelectAttachContentType.scss';

import React from 'react';

import CustomControlWithIcon from "../../../../../../components/Dropdown/CustomControlWithIcon";
import CustomOptionWithIcon from "../../../../../../components/Dropdown/CustomOptionWithIcon";
import Dropdown from "../../../../../../components/Dropdown";
import FormRow from "../../../../../../components/FormRow";
import { ProfileTypes } from "../../../../../../utils/enums";
import { useRouteMatch } from "react-router";

export enum PushNotificationContentTypes {
    ClassNote = 'classnote',
    Deal = 'deal',
    Event = 'event',
    Group = 'forumTopic',
    MarketplaceItem = 'item',
    NewsStory = 'newsStory',
    Resource = 'resource',
    Thread = 'thread',
}

type Props = {
    className?: string
    disabled?: boolean
    handleChange: (value: PushNotificationContentTypes) => void
    value: PushNotificationContentTypes,
}

const OPTIONS = {
    [ProfileTypes.Admit]: [
        {
            icon: 'calendar',
            label: 'Event',
            sublabel: 'Attach an event to your notification',
            value: PushNotificationContentTypes.Event,
        }, {
            icon: 'resources',
            label: 'Resource',
            sublabel: 'Attach a resource to your notification',
            value: PushNotificationContentTypes.Resource,
        }, {
            icon: 'news',
            label: 'News Story',
            sublabel: 'Attach a news story to your notification',
            value: PushNotificationContentTypes.NewsStory,
        },
    ],
    [ProfileTypes.Alumni]: [
        {
            icon: 'classnote',
            label: 'Class Note',
            sublabel: 'Attach a class note to your notification',
            value: PushNotificationContentTypes.ClassNote,
        }, {
            icon: 'calendar',
            label: 'Event',
            sublabel: 'Attach an event to your notification',
            value: PushNotificationContentTypes.Event,
        }, {
            icon: 'news',
            label: 'News Story',
            sublabel: 'Attach a news story to your notification',
            value: PushNotificationContentTypes.NewsStory,
        },
    ],
    [ProfileTypes.Student]: [
        {
            icon: 'deal',
            label: 'Deal',
            sublabel: 'Attach a deal to your notification',
            value: PushNotificationContentTypes.Deal,
        }, {
            icon: 'discussion',
            label: 'Discusson',
            sublabel: 'Attach a discussion to your notification',
            value: PushNotificationContentTypes.Thread,
        }, {
            icon: 'calendar',
            label: 'Event',
            sublabel: 'Attach an event to your notification',
            value: PushNotificationContentTypes.Event,
        }, {
            icon: 'marketplace',
            label: 'Marketplace',
            sublabel: 'Attach a marketplace item to your notification',
            value: PushNotificationContentTypes.MarketplaceItem,
        }, {
            icon: 'groups',
            label: 'Group',
            sublabel: 'Attach a group to your notification',
            value: PushNotificationContentTypes.Group,
        }
    ]
}

const SelectAttachContentType: React.FC<Props> = ({
    className = '',
    disabled,
    handleChange,
    value,
}) => {
    const { params } = useRouteMatch();

    return (
        <FormRow className={`c-select-attach-content-type ${className}`}>
            <Dropdown
                components={{
                    Control: CustomControlWithIcon,
                    Option: CustomOptionWithIcon
                }}
                disabled={disabled}
                label="Content Type to Attach"
                name="type"
                required
                options={OPTIONS[params.profileType]}
                onChange={(selectedValue) => handleChange(selectedValue.value)}
                value={value}
            />
        </FormRow>
    );
};

export default SelectAttachContentType;
