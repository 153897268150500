export const LIST_TYPES = {
    GRID: 'grid',
    TABLE: 'table',
};

export const LOCALSTORAGE = {
    ID_TOKEN: 'id_token',
    ID_USERDATA: 'id_userdata',
    ID_TENANTDATA: 'id_tenantdata',
    REDIRECT_URL: 'redirect_url',
};

export const MODAL_STYLE_RESET = {
    overlay: {
        position: null,
        top: null,
        left: null,
        right: null,
        bottom: null,
        backgroundColor: null,
    },
    content: {
        position: null,
        top: null,
        left: null,
        right: null,
        bottom: null,
        border: null,
        background: null,
        overflow: null,
        WebkitOverflowScrolling: null,
        borderRadius: null,
        outline: null,
        padding: null,
    },
};

export const breakpoints = {
    largeMobileBreakpoint: '29.5em',
    smallTabletBreakpoint: '36.5em',
    maxTabletBreakpoint: '47.4375em',
    tabletBreakpoint: '47.5em',
    maxDesktopBreakpoint: '62.9375em',
    desktopBreakpoint: '63em',
    widescreenBreakpoint: '80em',
    superWideBreakpoint: '112.5em',
};

export const POST_STATUS = {
    DRAFT: 'W',
    PENDING: 'X',
    PUBLISHED: 'A'
};

export const POST_STATUS_LABELS = {
    A: 'Published',
    W: 'Draft',
    X: 'Pending'
};

export const REDEEM_FREQUENCY_OPTIONS = [
    {
        label: 'Once',
        value: null
    }, {
        label: 'Daily',
        value: 'D'
    }, {
        label: 'Weekly',
        value: 'W'
    }, {
        label: 'Monthly',
        value: 'M'
    }, {
        label: 'Annual',
        value: 'Y'
    },
];

export const REDEEM_FREQUENCY_LABELS = {
    D: 'Daily',
    W: 'Weekly',
    M: 'Monthly',
    Y: 'Yearly'
};

export const PAGE_SIZE_OPTIONS = [
    {
        label: '10',
        value: 10
    }, {
        label: '25',
        value: 25
    }, {
        label: '100',
        value: 100
    }
];

export const PUSH_NOTIFICATION_DESTINATION_TYPES = {
    BOTH: 'both',
    INAPP: 'in-app',
    PUSH: 'push'
};

export const PUSH_NOTIFICATION_DESTINATION_TYPES_DISPLAY = {
    both: 'In-App + Push',
    'in-app': 'In-App',
    push: 'Push'
}

export const PUSH_NOTIFICATION_DISPLAY_TYPES = {
    INFO: 'general-info',
    UPDATE: 'update',
    WARNING: 'warning'
}

export const PRIVACY_TYPES = {
    PRIVATE: 'private',
    PUBLIC: 'public'
}

export const TOPIC_MEMBERSHIP_TYPES = {
    ACTIVE: 'active',
    DECLINED: 'declined',
    DENIED: 'denied',
    INVITED: 'invited',
    REQUESTED: 'requested'
};

export enum SPONSORSHIP_TYPES {
    CATEGORY = 'category',
    FEATURED = 'featured',
    HEADLINE = 'headline',
}

export const DEAL_TYPE_OPTIONS = [{
    label: 'Discounts',
    value: 'discounts'
},{
    label: 'Cash',
    value: 'cash'
},{
    label: 'Free',
    value: 'free'
}];

export const THREAD_TOPICS = [
    {label: 'Academics', value: 'Academics'},
    {label: 'Advice to Freshman Me', value: 'Advice to Freshman Me'},
    {label: 'Arts', value: 'Arts'},
    {label: 'Baseball', value: 'Baseball'},
    {label: 'Basketball', value: 'Basketball'},
    {label: 'Biking & Cycling', value: 'Biking & Cycling'},
    {label: 'Camping', value: 'Camping'},
    {label: 'Campus', value: 'Campus'},
    {label: 'Clubs & Orgs', value: 'Clubs & Orgs'},
    {label: 'Dancing', value: 'Dancing'},
    {label: 'Dorm Life', value: 'Dorm Life'},
    {label: 'Faith & Spirituality', value: 'Faith & Spirituality'},
    {label: 'Fishing', value: 'Fishing'},
    {label: 'Food & Drinks', value: 'Food & Drinks'},
    {label: 'Football', value: 'Football'},
    {label: 'Gaming', value: 'Gaming'},
    {label: 'Golf', value: 'Golf'},
    {label: 'Greek Life', value: 'Greek Life'},
    {label: 'Health & Fitness', value: 'Health & Fitness'},
    {label: 'Hiking', value: 'Hiking'},
    {label: 'Hockey', value: 'Hockey'},
    {label: 'Housing & Roommates', value: 'Housing & Roommates'},
    {label: 'Intramurals', value: 'Intramurals'},
    {label: 'Jobs & Internships', value: 'Jobs & Internships'},
    {label: 'Lacrosse', value: 'Lacrosse'},
    {label: 'LGBTQ', value: 'LGBTQ'},
    {label: 'Misc. Sports', value: 'Misc. Sports'},
    {label: 'Music', value: 'Music'},
    {label: 'News', value: 'News'},
    {label: 'Nightlife', value: 'Nightlife'},
    {label: 'Overheard on Campus', value: 'Overheard on Campus'},
    {label: 'Pets', value: 'Pets'},
    {label: 'Photography', value: 'Photography'},
    {label: 'Rowing', value: 'Rowing'},
    {label: 'Rugby', value: 'Rugby'},
    {label: 'Scholarships', value: 'Scholarships'},
    {label: 'Skiing & Snowboarding', value: 'Skiing & Snowboarding'},
    {label: 'Soccer', value: 'Soccer'},
    {label: 'Softball', value: 'Softball'},
    {label: 'Study Abroad', value: 'Study Abroad'},
    {label: 'Style & Fashion', value: 'Style & Fashion'},
    {label: 'Swimming', value: 'Swimming'},
    {label: 'Tennis', value: 'Tennis'},
    {label: 'Volleyball', value: 'Volleyball'},
    {label: 'Volunteering', value: 'Volunteering'},
    {label: 'Yoga', value: 'Yoga'},
];
