import React, { useEffect } from 'react';
import {useSelector} from "react-redux";

const Ambassadors = () => {
    const { activeSchool } = useSelector(store => store.schools);

    useEffect(() => {
        // Get Ambassadors data
    }, []);

    return (
        <div>
            <h1>{activeSchool.name} Ambassadors</h1>
        </div>
    )
}
export default Ambassadors;
