import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Request from "../../utils/request";
import {RootState} from "../reducers";
import PATHS from "../../utils/paths";
import {addError} from './errors';
import IError from '../../types/IError';

type getSuperAdminDashboardDataProps = {
}

export type DashboardCardData = {
    title: string
    subtitle: string
    subtitles: Array<string>
    data: any
}

export type SlimDashData = {
    type: string
    todayCount: number
    yesterdayCount: number
    average: number
}


export const getSuperAdminDashboardData = createAsyncThunk(
    'dashboard/getSuperAdminDashboardData',
    async (params: getSuperAdminDashboardDataProps, {dispatch, getState}) => {
        try {
            const res = await new Request((getState() as RootState).auth.token).get(PATHS.dashboard.getSuperAdminDashboard());

            const {
                topDeals,
                topEvents,
                topThreads,
                topItems,
                pushNotifications,
                recentlyJoinedProfiles,
                mostActiveProfiles,
                mostActiveTenants,
                mostActiveForumTopics,
                topDms,
                liveNow
            } = res.data.data.dashboardStats;

            const timeSeriesChart = res.data.data.timeSeries;

            const dau: Array<{x: Date, y: number}> = timeSeriesChart.dau.map(r => {
                return {
                    x: r.t,
                    y: r.value
                }
            });

            const mau: Array<{x: Date, y: number}> = timeSeriesChart.mau.map(r => {
                return {
                    x: r.t,
                    y: r.value
                }
            });

            const headcount: Array<{x: Date, y: number}> = timeSeriesChart.headcount.map(r => {
                return {
                    x: r.t,
                    y: r.value
                }
            });

            const profileActivity: Array<{x: Date, y: number}> = timeSeriesChart.profileActivity.map(r => {
                return {
                    x: r.t,
                    y: r.value
                }
            });

            const mostActiveUsersData: DashboardCardData = {
                title: 'Most Active Users',
                subtitle: 'USER',
                subtitles: ['ACTIVITIES', ''],
                data: mostActiveProfiles
            };

            const topDealsData: DashboardCardData = {
                title: 'Top Deals',
                subtitle: 'CONTENT',
                subtitles: ['ENGAGEMENT', ''],
                data: topDeals
            };

            const topEventsData: DashboardCardData = {
                title: 'Top Events',
                subtitle: 'CONTENT',
                subtitles: ['ENGAGEMENT', ''],
                data: topEvents
            };

            const topThreadsData: DashboardCardData = {
                title: 'Top Threads',
                subtitle: 'CONTENT',
                subtitles: ['ENGAGEMENT', ''],
                data: topThreads
            };

            const topItemsData: DashboardCardData = {
                title: 'Top Items',
                subtitle: 'CONTENT',
                subtitles: ['PRICE', ''],
                data: topItems
            };

            const pushNotificationsData: DashboardCardData = {
                title: 'Push Notifications',
                subtitle: 'MESSAGE',
                subtitles: ['SCHOOL', ''],
                data: pushNotifications
            };

            const recentlyJoinedUsersData: DashboardCardData = {
                title: 'Recently Joined Users',
                subtitle: 'EMAIL ADDRESS',
                subtitles: ['CREATED',''],
                data: recentlyJoinedProfiles
            };

            const mostActiveSchoolsData: DashboardCardData = {
                title: 'Most Active Schools',
                subtitle: 'THIS YEAR',
                subtitles: ['CONTENT', ''],
                data: mostActiveTenants
            };

            const mostActiveGroupsData: DashboardCardData = {
                title: 'Most Active Groups',
                subtitle: 'LIFETIME',
                subtitles: ['CONTENT', ''],
                data: mostActiveForumTopics
            };

            const topDmSchoolsData: DashboardCardData = {
                title: 'Top DM Schools',
                subtitle: 'SCHOOL',
                subtitles: [ 'CONVERSATIONS', ''],
                data: topDms.mostActiveTenants
            };

            const topDmUsersData: DashboardCardData = {
                title: 'Top DM Users',
                subtitle: 'USER',
                subtitles: ['CONVERSATIONS', ''],
                data: topDms
            };

            const liveNowData: DashboardCardData = {
                title: 'Live Now',
                subtitle: 'LAST 12 HOURS',
                subtitles: ['LAST SEEN', ''],
                data: liveNow
            };

            const getCountAverage = (chart) => {
                const counts = chart.map(row => row.y)
                if (counts.length > 0) {
                    return counts.reduce((a, b) => a + b) / counts.length;
                }
            }

            const slimDashData: Array<SlimDashData> = [
                {
                    type: 'Daily Active Users',
                    todayCount: dau[dau.length - 1]?.y,
                    yesterdayCount: dau[dau.length - 2]?.y,
                    average: dau.length > 0 && getCountAverage(dau)
                },
                {
                    type: 'Monthly Active Users',
                    todayCount: mau[mau.length - 1]?.y,
                    yesterdayCount: mau[mau.length - 2]?.y,
                    average: getCountAverage(mau)
                },
                {
                    type: 'Headcount',
                    todayCount: headcount[headcount.length - 1]?.y,
                    yesterdayCount: headcount[headcount.length - 2]?.y,
                    average: getCountAverage(headcount)
                },
                {
                    type: 'User Activity',
                    todayCount: profileActivity[profileActivity.length - 1]?.y,
                    yesterdayCount: profileActivity[profileActivity.length - 2]?.y,
                    average: getCountAverage(profileActivity)
                },
            ]

            return {
                liveNowData,
                mostActiveUsersData,
                topDmUsersData,
                topDmSchoolsData,
                topDealsData,
                topEventsData,
                topThreadsData,
                topItemsData,
                pushNotificationsData,
                recentlyJoinedUsersData,
                mostActiveSchoolsData,
                mostActiveGroupsData,
                slimDashData,
                dau,
                mau,
                headcount,
                profileActivity
            };
        }
        catch (err: any) {
            console.log('getSuperAdminDashboardData', err);
            err.friendlyMessage = 'Error getting the global admin dashboard data. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    });

interface SuperAdminDashboardState {
    liveNowData: DashboardCardData,
    mostActiveUsersData: DashboardCardData,
    topDealsData: DashboardCardData,
    topEventsData: DashboardCardData,
    topThreadsData: DashboardCardData,
    topItemsData: DashboardCardData,
    topDmUsersData: DashboardCardData,
    topDmSchoolsData: DashboardCardData,
    pushNotificationsData: DashboardCardData,
    recentlyJoinedUsersData: DashboardCardData,
    mostActiveSchoolsData: DashboardCardData,
    mostActiveGroupsData: DashboardCardData,
    slimDashData: Array<SlimDashData>,
    dau: Array<{x: Date, y: number}>,
    mau: Array<{x: Date, y: number}>,
    headcount: Array<{x: Date, y: number}>,
    profileActivity: Array<{x: Date, y: number}>,
    globalLifetimeStats: any
    timeSeriesChart: any
    isGettingDashboard: boolean
    getDashboardError?: IError
    showTopDealsDetailModal: boolean
    showTopItemsDetailModal: boolean
    showTopThreadsDetailModal: boolean
    showTopEventsDetailModal: boolean
    showMostActiveUsersDetailModal: boolean
    showMostActiveSchoolsDetailModal: boolean
    showMostActiveGroupsDetailModal: boolean
    showPushNotificationsDetailModal: boolean
    showRecentlyJoinedUsersDetailModal: boolean
    showTopDmUsersDetailModal: boolean
    showTopDmSchoolsDetailModal: boolean
    showLiveNowDetailModal: boolean
    showChartCards: boolean
}

const initialState: SuperAdminDashboardState = {
    globalLifetimeStats: {},
    timeSeriesChart: {dau: [], mau: [], headcount: []},
    liveNowData: {title: '', subtitle: '', subtitles: [], data: null},
    mostActiveUsersData: {title: '', subtitle: '', subtitles: [], data: null},
    topDealsData: {title: '', subtitle: '', subtitles: [], data: null},
    topEventsData: {title: '', subtitle: '', subtitles: [], data: null},
    topThreadsData: {title: '', subtitle: '', subtitles: [], data: null},
    topItemsData: {title: '', subtitle: '', subtitles: [], data: null},
    topDmUsersData: {title: '', subtitle: '', subtitles: [], data: null},
    topDmSchoolsData: {title: '', subtitle: '', subtitles: [], data: null},
    pushNotificationsData: {title: '', subtitle: '', subtitles: [], data: null},
    recentlyJoinedUsersData: {title: '', subtitle: '', subtitles: [], data: null},
    mostActiveSchoolsData: {title: '', subtitle: '', subtitles: [], data: null},
    mostActiveGroupsData: {title: '', subtitle: '', subtitles: [], data: null},
    slimDashData: [],
    dau: [],
    mau: [],
    headcount: [],
    profileActivity: [],
    isGettingDashboard: false,
    getDashboardError: undefined,
    showTopDealsDetailModal: false,
    showTopItemsDetailModal: false,
    showTopThreadsDetailModal: false,
    showTopEventsDetailModal: false,
    showMostActiveUsersDetailModal: false,
    showMostActiveSchoolsDetailModal: false,
    showMostActiveGroupsDetailModal: false,
    showPushNotificationsDetailModal: false,
    showRecentlyJoinedUsersDetailModal: false,
    showTopDmUsersDetailModal: false,
    showTopDmSchoolsDetailModal: false,
    showLiveNowDetailModal: false,
    showChartCards: false
}


export const superAdminDashboardSlice = createSlice({
    name: 'superAdminDashboardSlice',
    initialState,
    reducers: {
        setShowTopDealsDetailModal: (state, action) => {
            state.showTopDealsDetailModal = action.payload;
        },
        setShowTopItemsDetailModal: (state, action) => {
            state.showTopItemsDetailModal = action.payload;
        },
        setShowTopThreadsDetailModal: (state, action) => {
            state.showTopThreadsDetailModal = action.payload;
        },
        setShowTopEventsDetailModal: (state, action) => {
            state.showTopEventsDetailModal = action.payload;
        },
        setShowMostActiveUsersDetailModal: (state, action) => {
            state.showMostActiveUsersDetailModal = action.payload;
        },
        setShowMostActiveSchoolsDetailModal: (state, action) => {
            state.showMostActiveSchoolsDetailModal = action.payload;
        },
        setShowMostActiveGroupsDetailModal: (state, action) => {
            state.showMostActiveGroupsDetailModal = action.payload;
        },
        setShowPushNotificationsDetailModal: (state, action) => {
            state.showPushNotificationsDetailModal = action.payload;
        },
        setShowRecentlyJoinedUsersDetailModal: (state, action) => {
            state.showRecentlyJoinedUsersDetailModal = action.payload;
        },
        setShowChartCards: (state, action) => {
            state.showChartCards = action.payload;
        },
        setShowTopDmUsersDetailModal:  (state, action) => {
            state.showTopDmUsersDetailModal = action.payload;
        },
        setShowTopDmSchoolsDetailModal:  (state, action) => {
            state.showTopDmSchoolsDetailModal = action.payload;
        },
        setShowLiveNowDetailModal:  (state, action) => {
            state.showLiveNowDetailModal = action.payload;
        },
        setAllDetailModals: (state, action) => {
            state.showRecentlyJoinedUsersDetailModal = action.payload;
            state.showPushNotificationsDetailModal = action.payload;
            state.showMostActiveGroupsDetailModal = action.payload;
            state.showMostActiveUsersDetailModal = action.payload;
            state.showMostActiveSchoolsDetailModal = action.payload;
            state.showTopEventsDetailModal = action.payload;
            state.showTopDealsDetailModal = action.payload;
            state.showTopItemsDetailModal = action.payload;
            state.showTopThreadsDetailModal = action.payload;
            state.showTopDmUsersDetailModal = action.payload;
            state.showTopDmSchoolsDetailModal = action.payload;
            state.showLiveNowDetailModal = action.payload;
        }
    },
    extraReducers: ({addCase}) => {
        addCase(getSuperAdminDashboardData.pending, (state, action) => {
            state.getDashboardError = undefined;
            state.isGettingDashboard = true;
        });
        addCase(getSuperAdminDashboardData.fulfilled, (state, action) => {
            state.dau = action.payload.dau;
            state.getDashboardError = undefined;
            state.headcount = action.payload.headcount;
            state.isGettingDashboard = false;
            state.liveNowData = action.payload.liveNowData;
            state.mau = action.payload.mau;
            state.mostActiveUsersData = action.payload.mostActiveUsersData;
            state.mostActiveSchoolsData = action.payload.mostActiveSchoolsData;
            state.mostActiveGroupsData = action.payload.mostActiveGroupsData;
            state.profileActivity = action.payload.profileActivity;
            state.pushNotificationsData = action.payload.pushNotificationsData;
            state.recentlyJoinedUsersData = action.payload.recentlyJoinedUsersData;
            state.slimDashData = action.payload.slimDashData;
            state.topDealsData = action.payload.topDealsData;
            state.topEventsData = action.payload.topEventsData;
            state.topThreadsData = action.payload.topThreadsData;
            state.topItemsData = action.payload.topItemsData;
            state.topDmUsersData = action.payload.topDmUsersData;
            state.topDmSchoolsData = action.payload.topDmSchoolsData;
        });
        addCase(getSuperAdminDashboardData.rejected, (state, action) => {
            state.getDashboardError = action.error;
            state.isGettingDashboard = false;
        });
    }
});

export const {
    setShowChartCards,
    setShowTopItemsDetailModal,
    setShowTopEventsDetailModal,
    setShowTopDealsDetailModal,
    setShowTopThreadsDetailModal,
    setShowRecentlyJoinedUsersDetailModal,
    setShowPushNotificationsDetailModal,
    setShowMostActiveUsersDetailModal,
    setShowMostActiveSchoolsDetailModal,
    setShowMostActiveGroupsDetailModal,
    setAllDetailModals,
    setShowLiveNowDetailModal,
    setShowTopDmSchoolsDetailModal,
    setShowTopDmUsersDetailModal
} = superAdminDashboardSlice.actions

export default superAdminDashboardSlice.reducer;
