import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import { addError } from "./errors";
import { RootState } from '../reducers';

import GivingStatus from '../../types/GivingStatus';
import Metadata from "../../types/Metadata";
import Profile from "../../types/Profile";
import Tenant, { IHoursOfOperation } from "../../types/Tenant";

import Request from "../../utils/request";
import { createElementsObject } from "../../utils/elements";
import PATHS, { buildQueryString } from "../../utils/paths";
import { LOCALSTORAGE } from "../../utils/constants";
import { generateGivingStatus, generateSchool, generateSchoolsMetadata } from '../../utils/generators';
import { getCurrentUserRole, ROLES } from '../../utils/roles';

type GetGivingStatusProps = {
    schoolId: number
}

type GetSchoolsProps = {
    isUpdate?: boolean
    schoolsMetadata?: Metadata
}

type GetSchoolProps = {
    hideSpinner?: boolean
    schoolId: number
    token?: string
    fromServer?: boolean
}

export type UpdateGivingStatusProps = {
    active: boolean
    billingContactEmailAddress: string
    minimumDonationUsdCents?: number
    refreshUrl?: string
    returnUrl?: string
}

export const getSchools = createAsyncThunk(
    'schools/getSchools',
    async ({isUpdate, schoolsMetadata}: GetSchoolsProps = {}, {dispatch, getState}) => {
        try {
            if(!schoolsMetadata) {
                schoolsMetadata = clone((getState() as RootState).schools.schoolsMetadata);
            } else {
                schoolsMetadata = {...schoolsMetadata}
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.schools.get(buildQueryString(schoolsMetadata)));

            let schools = res.data.data.items;
            schoolsMetadata.total = res.data.data.meta.total;
            return {schools, schoolsMetadata};
        } catch(err) {
            console.log('getSchools', err);
            err.friendlyMessage = 'Error getting the list of schools. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

export const getSchool = createAsyncThunk(
    'schools/getSchool',
    async ({hideSpinner, schoolId, token, fromServer}: GetSchoolProps, {dispatch, getState}) => {
        const roles = (getState() as RootState).auth.roles;
        let matchingRole = roles.find((r) => {
            return r.tenantId === parseInt(schoolId as any);
        });

        if (!matchingRole) {
           matchingRole = roles.find((r) => {
                return r.type === ROLES.SUPER_ADMIN;
           });
        }

        const currentActiveSchool = (getState() as RootState).schools.activeSchool;
        if (currentActiveSchool.tenantId === schoolId) {
            return currentActiveSchool;
        }
        if (!token) {
            token = (getState() as RootState).auth.token;
        }
        try {
            const localStorageTenant = await JSON.parse(localStorage.getItem(LOCALSTORAGE.ID_TENANTDATA));

            let tenant;

            if (fromServer || !localStorageTenant || localStorageTenant.tenantId !== parseInt(schoolId as any)) {
                const res = await new Request(token).get(PATHS.schools.getSingle(schoolId));
                tenant = res.data.data;
                if (matchingRole) {
                    if (matchingRole.tenant?.postAsProfile) {
                        tenant.postAsProfile = matchingRole.tenant.postAsProfile;
                    }
                    if (matchingRole.activeGlobalProfile && matchingRole.activeGlobalProfile.profileId) {
                        tenant.postAsProfile = matchingRole.activeGlobalProfile;
                    }
                }
                tenant.elements = createElementsObject(tenant.elements);
                localStorage.setItem(LOCALSTORAGE.ID_TENANTDATA, JSON.stringify(tenant) );
            } else {
                tenant = localStorageTenant;
                dispatch(getSchool({schoolId, token, hideSpinner: true, fromServer: true }))
            }
            return tenant;
        } catch(err) {
            console.log('getSchool error:', err);
            err.friendlyMessage = 'Error getting the school. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

export const saveSchool = createAsyncThunk(
    'schools/saveSchool',
    async (_, {dispatch, getState}) => {
        try {
            const { schoolToEdit} = (getState() as RootState).schools;

            const res = await new Request((getState() as RootState).auth.token).put(PATHS.schools.update(schoolToEdit.tenantId), schoolToEdit);

            return res.data.data;
        } catch(err) {
            console.log('saveSchool', err);
            err.friendlyMessage = 'Error saving the school. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetOtherAdminUsersProps = {
    tenantId: number
}

export const getOtherAdminUsers = createAsyncThunk(
    'schools/getOtherAdminUsers',
    async ({tenantId}: GetOtherAdminUsersProps, {dispatch, getState}) => {

        try {
            const role = getCurrentUserRole({tenantId});

            let res: any;
            if (role === ROLES.SUPER_ADMIN || role === ROLES.ENGINEERING) {
                res = await new Request((getState() as RootState).auth.token).get(PATHS.schools.getOtherAdminUsers(tenantId));
            }
            else {
                res = await new Request((getState() as RootState).auth.token).get(PATHS.schools.getOtherAdminUsers(tenantId, role));
            }

            return res.data.data.items;
        } catch(err) {
            console.log('getOtherAdminUsers', err);
            err.friendlyMessage = 'Error getting the list other admin users. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

export const updateGivingStatus = createAsyncThunk(
    'schools/updateGivingStatus',
    async (_, {dispatch, getState}) => {
        try {
            const {activeSchool, updateGivingStatusConfig} = (getState() as RootState).schools;

            if (!activeSchool.tenantId) {
                throw new Error('No active school found');
            }

            const clonedConfig = clone(updateGivingStatusConfig);
            clonedConfig.minimumDonationUsdCents = updateGivingStatusConfig.minimumDonationUsdCents * 100 as any; // convert to cents

            const res = await new Request((getState() as RootState).auth.token).put(PATHS.schools.updateGivingStatus(activeSchool.tenantId), clonedConfig);

            return res.data.data;
        } catch(err) {
            console.log('updateGivingStatus', err);
            err.friendlyMessage = 'Error updating the giving status. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

export const resendStripeAccountSetupEmail = createAsyncThunk(
    'schools/resendStripeAccountSetupEmail',
    async (_, {dispatch, getState}) => {
        try {
            const {activeSchool} = (getState() as RootState).schools;

            if (!activeSchool.tenantId) {
                throw new Error('No active school found');
            }

            const res = await new Request((getState() as RootState).auth.token).put(PATHS.schools.resendStripeAccountSetupEmail(activeSchool.tenantId));

            return res.data.data;
        } catch(err) {
            console.log('resendStripeAccountSetupEmail', err);
            err.friendlyMessage = 'Error resending the stripe account setup email. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

export const getGivingStatus = createAsyncThunk(
    'schools/getGivingStatus',
    async ({schoolId}: GetGivingStatusProps, {dispatch, getState}) => {
        try {
            if (!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.schools.getGivingStatus(schoolId));

            return res.data.data;
        } catch(err) {
            console.log('getGivingStatus', err);
            err.friendlyMessage = 'Error getting the giving status. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

interface SchoolsState {
    activeSchool: Tenant
    getGivingStatusError: object
    getSchoolError: object
    getSchoolsError: object
    givingStatus: GivingStatus
    getOtherAdminUsersError: object
    isGettingSchool: boolean
    isGettingSchools: boolean
    isSavingSchool: boolean
    isGettingOtherAdminUsers: boolean
    isUpdatingGivingStatus: boolean
    isGettingGivingStatus: boolean
    isResendingStripeAccountSetupEmail: boolean
    otherAdminUsers: Array<Profile>
    resendStripeAccountSetupEmailError: object
    saveSchoolError: object
    searchTerm: string
    schools: Array<Tenant>
    schoolsMetadata: Metadata
    schoolToEdit: Tenant
    updateGivingStatusError: object
    updateGivingStatusConfig: UpdateGivingStatusProps
}

export const initialState: SchoolsState = {
    schools: [],
    searchTerm: '',
    activeSchool: generateSchool(),
    schoolsMetadata: generateSchoolsMetadata(),
    givingStatus: generateGivingStatus(),
    schoolToEdit: generateSchool(),
    isGettingSchool: false,
    isGettingSchools: false,
    isSavingSchool: false,
    isGettingGivingStatus: false,
    isGettingOtherAdminUsers: false,
    isResendingStripeAccountSetupEmail: false,
    isUpdatingGivingStatus: false,
    getGivingStatusError: {},
    getSchoolError: {},
    getSchoolsError: {},
    getOtherAdminUsersError: {},
    otherAdminUsers: [],
    resendStripeAccountSetupEmailError: {},
    saveSchoolError: {},
    updateGivingStatusError: {},
    updateGivingStatusConfig: {
        active: false,
        billingContactEmailAddress: ''
    },
};

export const schoolsSlice = createSlice({
    name: 'schools',
    initialState,
    reducers: {
        clearActiveSchool: (state) => {
            state.activeSchool = generateSchool();
        },
        clearSchoolsMetadata: (state) => {
            state.schoolsMetadata = generateSchoolsMetadata();
        },
        clearGivingStatusConfig: (state) => {
            state.updateGivingStatusConfig = {
                active: false,
                billingContactEmailAddress: ''
            };
        },
        clearGivingStatus: (state) => {
            state.givingStatus = generateGivingStatus();
        },
        clearSchoolToEdit: (state) => {
            state.schoolToEdit = generateSchool();
        },
        setActiveSchool: (state, action) => {
            state.activeSchool = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setGivingStatusConfig: (state, action) => {
            state.updateGivingStatusConfig = action.payload;
        },
        setSchoolToEdit: (state, action) => {
            state.schoolToEdit = action.payload;
        }
    },
    extraReducers: ({addCase}) => {
        addCase(getSchools.pending, (state, action) => {
            state.getSchoolsError = {};
            state.isGettingSchools = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.schoolsMetadata) {
                state.schoolsMetadata = action.meta.arg.schoolsMetadata;
            }
        });
        addCase(getSchools.fulfilled, (state, action) => {
            state.schools = action.payload.schools;
            state.schoolsMetadata = action.payload.schoolsMetadata;
            state.isGettingSchools = false;
        });
        addCase(getSchools.rejected, (state, action) => {
            state.getSchoolsError = action.error;
            state.isGettingSchools = false;
        });

        addCase(getSchool.fulfilled, (state, action) => {
            state.isGettingSchool = false;
            state.activeSchool = action.payload;
        });
        addCase(getSchool.pending, (state) => {
            state.getSchoolError = {};
            state.isGettingSchool = true;
        });
        addCase(getSchool.rejected, (state, action) => {
            state.getSchoolError = action.error;
            state.isGettingSchool = false;
        });

        addCase(saveSchool.pending, (state, action) => {
            state.saveSchoolError = undefined;
            state.isSavingSchool = true;
        });
        addCase(saveSchool.fulfilled, (state, action) => {
            state.isSavingSchool = false;
            state.activeSchool = action.payload;
        });
        addCase(saveSchool.rejected, (state, action) => {
            state.saveSchoolError = action.error;
            state.isSavingSchool = false;
        });

        addCase(getOtherAdminUsers.pending, (state, action) => {
            state.getOtherAdminUsersError = {};
            state.isGettingOtherAdminUsers = true;
        });
        addCase(getOtherAdminUsers.fulfilled, (state, action) => {
            state.otherAdminUsers = action.payload;
            state.isGettingOtherAdminUsers = false;
        });
        addCase(getOtherAdminUsers.rejected, (state, action) => {
            state.getOtherAdminUsersError = action.error;
            state.isGettingOtherAdminUsers = false;
        });

        addCase(resendStripeAccountSetupEmail.pending, (state, action) => {
            state.isResendingStripeAccountSetupEmail = true;
        });
        addCase(resendStripeAccountSetupEmail.fulfilled, (state, action) => {
            state.isResendingStripeAccountSetupEmail = false;
        });
        addCase(resendStripeAccountSetupEmail.rejected, (state, action) => {
            state.resendStripeAccountSetupEmailError = action.error;
            state.isResendingStripeAccountSetupEmail = false;
        });

        addCase(updateGivingStatus.pending, (state, action) => {
            state.isUpdatingGivingStatus = true;
        });
        addCase(updateGivingStatus.fulfilled, (state, action) => {
            state.isUpdatingGivingStatus = false;
            state.activeSchool = action.payload;
        });
        addCase(updateGivingStatus.rejected, (state, action) => {
            state.updateGivingStatusError = action.error;
            state.isUpdatingGivingStatus = false;
        });

        addCase(getGivingStatus.pending, (state, action) => {
            state.isGettingGivingStatus = true;
        });
        addCase(getGivingStatus.fulfilled, (state, action) => {
            state.givingStatus = action.payload;
            state.isGettingGivingStatus = false;
        });
        addCase(getGivingStatus.rejected, (state, action) => {
            state.getGivingStatusError = action.error;
            state.isGettingGivingStatus = false;
        });
    }
});

export const {
    clearActiveSchool,
    clearGivingStatus,
    clearGivingStatusConfig,
    clearSchoolToEdit,
    clearSchoolsMetadata,
    setActiveSchool,
    setSearchTerm,
    setGivingStatusConfig,
    setSchoolToEdit
} = schoolsSlice.actions;

export default schoolsSlice.reducer;
