import './AcceptInvitation.scss';

import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import { useHistory, useLocation } from "react-router";

import { getInvitationDetails } from "../../../store/slices/auth";
import { useAppDispatch } from "../../../store";
import { useTypedSelector } from "../../../store/reducers";

import CreateEditUserForm from "../../Schools/School/SchoolUsers/CreateEditUser/CreateEditUserForm";

const AcceptInvitation: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();

    const [token, setToken] = useState(null);

    const { credentials } = useTypedSelector((state) => state.auth);
    const { user } = useTypedSelector((state) => state.users);

    useEffect(() => {
        const initialize = async () => {
            const qs = queryString.parse(location.search);

            document.body.classList.add('auth-page--invitation');

            if(!qs.t) {
                history.push('/auth/forgot-password');
            } else {
                setToken(qs.t);
                dispatch(getInvitationDetails({token: qs.t as string}));
            }


        };

        initialize();

        return () => document.body.classList.remove('auth-page--invitation');
    }, [dispatch, history, location]);

    return (
        <>
            <Helmet>
                <title>Create Account</title>
            </Helmet>

            <CreateEditUserForm
                inAcceptInvitation
                credentials={credentials}
                token={token}
                user={user}
            />
        </>
    );
};

export default AcceptInvitation;
