import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import IError from "../../types/IError";

import { buildErrorObject } from "../../utils/errors";
import Request from "../../utils/request";
import PATHS from "../../utils/paths";
import { generateQuestion
} from '../../utils/generators';

import { addError } from "./errors";
import { RootState } from '../reducers';
import Question from "../../types/Question";
import { ProfileTypes } from "../../utils/enums";

type DeleteQuestionProps = {
    question?: Question
}

export const deleteQuestion = createAsyncThunk(
    'faq/deleteQuestion',
    async ({question}: DeleteQuestionProps = {}, {dispatch, getState, rejectWithValue}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!question) {
                question = (getState() as RootState).faq.question;
            }

            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.faq.delete(tenantId, question.tenantFaqId));
            return {
                ...res,
                tenantFaqId: question.tenantFaqId,
            };
        } catch(err) {
            console.log('delete question err', err);
            let friendlyMessage = 'Error deleting this question. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
)

type GetQuestionsProps = {
    profileType: ProfileTypes
    schoolId?: number | string
}

export const getQuestions = createAsyncThunk(
    'faq/getQuestions',
    async ({profileType, schoolId}: GetQuestionsProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.faq.get(schoolId, `${profileType}_visibility=true&page_size=10000&page_num=0`));
            let questions = res.data.data.items;
            return { questions };
        } catch(err) {
            console.log('getQuestions err', err);
            let friendlyMessage = 'Error getting the list of frequently asked questions. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(err));
            return rejectWithValue(errorObject);
        }
    }
);

type ReorderQuestionsProps = {
    profileType: ProfileTypes
    questionIds?: Array<number>
}

export const reorderQuestions = createAsyncThunk(
    'faq/reorderQuestions',
    async({ profileType, questionIds }: ReorderQuestionsProps, {dispatch, getState, rejectWithValue}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!questionIds) {
                questionIds = (getState() as RootState).faq.questions.map((q: Question) => q.tenantFaqId);
            }
            const res = await new Request((getState() as RootState).auth.token).put(PATHS.faq.reorder(tenantId), {ids: questionIds, visibilityType: profileType});
            return res;
        } catch(err) {
            console.log('reorder frequently asked questions error', err);
            let friendlyMessage = 'Error reordering the questions. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(err));
            return rejectWithValue(errorObject);
        }
    }
)

type SaveQuestionProps = {
    question?: Question
}

export const saveQuestion = createAsyncThunk(
    'faq/saveQuestion',
    async({question}: SaveQuestionProps, {dispatch, getState, rejectWithValue}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId } } } = (getState() as RootState);

        if(!question) {
            question = (getState() as RootState).faq.question;
        }

        let path = PATHS.faq.create(tenantId);
        let request = new Request(token);
        let reqFunc = request.post;
        let isUpdate = false;

        if(question.tenantFaqId) {
            path = PATHS.faq.update(tenantId, question.tenantFaqId);
            reqFunc = request.put;
            isUpdate = true;
        }

        try {
            const res = await reqFunc(path, question);
            return { question: res.data.data, isUpdate };
        } catch(err) {
            console.log('save frequently asked question error', err);
            let friendlyMessage = 'There was an error saving your question. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
)

interface FaqState {
    deleteQuestionError?: IError
    getQuestionError?: IError
    getQuestionsError?: IError
    isDeletingQuestion: boolean
    isGettingQuestion: boolean
    isGettingQuestions: boolean
    isReorderingQuestions: boolean
    isSavingQuestion: boolean
    reorderQuestionsError?: IError
    question: Question
    questions: Array<Question>
    saveQuestionError?: IError
}

const initialState: FaqState = {
    question: generateQuestion(),
    questions: [],
    isDeletingQuestion: false,
    isGettingQuestion: false,
    isGettingQuestions: false,
    isReorderingQuestions: false,
    isSavingQuestion: false,
    deleteQuestionError: undefined,
    getQuestionError: undefined,
    getQuestionsError: undefined,
    reorderQuestionsError: undefined,
    saveQuestionError: undefined,
};

export const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        clearQuestion: (state) => {
            state.question = generateQuestion();
        },
        setQuestion: (state, action) => {
            state.question = action.payload;
        },
        setQuestions: (state, action) => {
            state.questions = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteQuestion.pending, (state) => {
            state.deleteQuestionError = undefined;
            state.isDeletingQuestion = true;
        });
        addCase(deleteQuestion.fulfilled, (state, action) => {
            state.isDeletingQuestion = false;
            state.question = generateQuestion();
            state.questions = state.questions.filter((q) => q.tenantFaqId !== action.payload.tenantFaqId);
        });
        addCase(deleteQuestion.rejected, (state, action) => {
            state.deleteQuestionError = action.error as IError;
            state.isDeletingQuestion = false;
        });

        addCase(getQuestions.pending, (state, action) => {
            state.getQuestionsError = undefined;
            state.isGettingQuestions = true;
        });
        addCase(getQuestions.fulfilled, (state, action) => {
            state.questions = action.payload.questions;
            state.isGettingQuestions = false;
        });
        addCase(getQuestions.rejected, (state, action) => {
            state.getQuestionsError = action.error as IError;
            state.isGettingQuestions = false;
        });

        addCase(saveQuestion.pending, (state) => {
            state.saveQuestionError = undefined;
            state.isSavingQuestion = true;
        });
        addCase(saveQuestion.fulfilled, (state, action) => {
            state.isSavingQuestion = false;
            if(action.payload.isUpdate) {
                const foundIndex = state.questions.findIndex((q) => q.tenantFaqId === action.payload.question.tenantFaqId);
                if(foundIndex > -1) {
                    state.questions[foundIndex] = action.payload.question;
                }
            } else {
                state.questions = [
                    ...state.questions,
                    action.payload.question,
                ]
            }
            state.question = generateQuestion();
        });
        addCase(saveQuestion.rejected, (state, action) => {
            state.saveQuestionError = action.error as IError;
            state.isSavingQuestion = false;
        });
    }
});

export const { clearQuestion, setQuestion, setQuestions } = faqSlice.actions;

export default faqSlice.reducer;
