import './VisibilityCard.scss';

import React, { useRef } from 'react';
import moment from 'moment';

import ClassNote from '../../types/ClassNote';
import Deal from "../../types/Deal";
import EventPost from "../../types/EventPost";
import IntegrationEvent from "../../types/IntegrationEvent";
import MarketplaceItem from "../../types/MarketplaceItem";
import NewsStory from "../../types/NewsStory";
import Thread from "../../types/Thread";

import { handleDateChange } from '../../utils/handle-changes';

import Datepicker from '../Datepicker';
import FormRow from '../FormRow';
import H5 from "../H5";
import Radio from '../Radio';
import RadioGroup from '../RadioGroup';

type Props = {
    disableDatepicker?: boolean
    handleChange: Function
    post: ClassNote | Deal | EventPost | IntegrationEvent | MarketplaceItem | NewsStory | Thread
}

const VisibilityCard: React.FC<Props> = ({
    disableDatepicker,
    handleChange,
    post
}) => {
    const datepickerRef = useRef(null);

    return (
        <FormRow className="c-visibility-card">
            <H5>
                Publish
            </H5>
            <RadioGroup>
                <Radio
                    disabled={post.publishedAt != null}
                    label={post.publishedAt ? `Published (as of ${moment(post.publishedAt).format('MMM D, hh:mma')})` : 'Now'}
                    checked={post.status === 'A'}
                    id="status--publish"
                    key="status--publish"
                    name="status"
                    onChange={() => handleChange('status', 'A')}
                    value="Publish"
                />

                <Radio
                    disabled={post.publishedAt != null}
                    label="Schedule it"
                    checked={post.status === 'X'}
                    id="status--draft"
                    key="status--draft"
                    name="status"
                    onChange={() => handleChange('status', 'X')}
                    value="Draft"
                />
            </RadioGroup>

            {post.publishedAt == null && post.status === 'X' && (
                <Datepicker
                    dateFormat="MM-dd-yyyy h:mm a"
                    disabled={disableDatepicker}
                    id="datePublishAt"
                    minDate={new Date()}
                    name="publishAt"
                    onChange={(newDate) => handleDateChange(handleChange, 'publishAt', null, moment(newDate).format())}
                    placeholderText="mm-dd-yyyy  00:00 pm"
                    required
                    selected={post.publishAt || null}
                    showMonthDropdown
                    showTimeSelect
                    showYearDropdown
                    thisRef={datepickerRef}
                    timeIntervals={15}
                />
            )}
        </FormRow>
    );
};

export default VisibilityCard;
