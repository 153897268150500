import './ComingSoon.scss';

import React from 'react';
import { useRouteMatch } from "react-router";

import { ProfileTypes } from "../../../../utils/enums";

import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import H1 from "../../../../components/H1";
import H6 from "../../../../components/H6";

const ComingSoon: React.FC = () => {
    const { params } = useRouteMatch();

    const renderAdmitContent = () => (
        <>
            <div className="coming-soon__content">
                <header className="coming-soon__header">
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37 1.73205C38.8564 0.660254 41.1436 0.660254 43 1.73205L71.641 18.268C73.4974 19.3397 74.641 21.3205 74.641 23.4641V56.5359C74.641 58.6795 73.4974 60.6603 71.641 61.7321L43 78.268C41.1436 79.3397 38.8564 79.3397 37 78.268L8.35898 61.7321C6.50257 60.6603 5.35898 58.6795 5.35898 56.5359V23.4641C5.35898 21.3205 6.50258 19.3397 8.35898 18.268L37 1.73205Z" fill="#CACBFB"/>
                        <g clip-path="url(#clip0_3012_14818)">
                            <path d="M59.168 30.8334C59.168 32.8112 58.5815 34.7446 57.4827 36.3891C56.3839 38.0336 54.8221 39.3153 52.9948 40.0722C51.1675 40.829 49.1569 41.0271 47.2171 40.6412C45.2773 40.2554 43.4954 39.303 42.0969 37.9044C40.6984 36.5059 39.746 34.7241 39.3601 32.7843C38.9743 30.8445 39.1723 28.8338 39.9292 27.0065C40.6861 25.1793 41.9678 23.6175 43.6123 22.5187C45.2568 21.4199 47.1902 20.8334 49.168 20.8334" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M59.168 23.3334L50.0513 32.4484C49.9354 32.5645 49.7977 32.6566 49.6461 32.7194C49.4945 32.7823 49.3321 32.8146 49.168 32.8146C49.0039 32.8146 48.8414 32.7823 48.6898 32.7194C48.5383 32.6566 48.4006 32.5645 48.2846 32.4484L45.418 29.5834" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M32.4987 59.1667L33.332 49.1667H37.4987V43.3333C37.4987 41.1232 36.6207 39.0036 35.0579 37.4408C33.4951 35.878 31.3755 35 29.1654 35C26.9552 35 24.8356 35.878 23.2728 37.4408C21.71 39.0036 20.832 41.1232 20.832 43.3333V49.1667H24.9987L25.832 59.1667H32.4987Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M23.332 26.6667C23.332 27.4328 23.4829 28.1913 23.7761 28.899C24.0692 29.6068 24.4989 30.2498 25.0406 30.7915C25.5823 31.3332 26.2253 31.7629 26.933 32.056C27.6408 32.3492 28.3993 32.5 29.1654 32.5C29.9314 32.5 30.69 32.3492 31.3977 32.056C32.1054 31.7629 32.7485 31.3332 33.2902 30.7915C33.8318 30.2498 34.2615 29.6068 34.5547 28.899C34.8478 28.1913 34.9987 27.4328 34.9987 26.6667C34.9987 25.9007 34.8478 25.1421 34.5547 24.4344C34.2615 23.7267 33.8318 23.0836 33.2902 22.5419C32.7485 22.0002 32.1054 21.5706 31.3977 21.2774C30.69 20.9843 29.9314 20.8334 29.1654 20.8334C28.3993 20.8334 27.6408 20.9843 26.933 21.2774C26.2253 21.5706 25.5823 22.0002 25.0406 22.5419C24.4989 23.0836 24.0692 23.7267 23.7761 24.4344C23.4829 25.1421 23.332 25.9007 23.332 26.6667V26.6667Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3012_14818">
                                <rect width="40" height="40" fill="white" transform="translate(20 20)"/>
                            </clipPath>
                        </defs>
                    </svg>

                    <div className="coming-soon__header-text">
                        <H6>
                            Coming Soon!
                        </H6>

                        <H1>
                            Abuzz Admit
                        </H1>
                    </div>
                </header>

                <div className="coming-soon__content-text">
                    <p>
                        Instantly build powerful connections between potential students and your school’s administration.
                    </p>

                    <ul>
                        <li>
                            Greet admits with custom welcome messaging from an assigned student counselor to build connection.
                        </li>

                        <li>
                            Enable discovery of campus events, helpful resources, school news, and more. Admits can even ask their counselor questions with direct messaging all within the app.
                        </li>
                    </ul>

                    <Button
                        className="coming-soon__contact-button"
                        href="mailto:info@campusabuzz.com"
                    >
                        Contact Us Today
                    </Button>
                </div>
            </div>

            <div className="coming-soon__image">
                <img src="/img/coming-soon-admit.jpg" alt="Screenshots of the admit app running on an iphone" />
            </div>
        </>
    );

    const renderAlumniContent = () => (
        <>
            <div className="coming-soon__content">
                <header className="coming-soon__header">
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37 1.73205C38.8564 0.660254 41.1436 0.660254 43 1.73205L71.641 18.268C73.4974 19.3397 74.641 21.3205 74.641 23.4641V56.5359C74.641 58.6795 73.4974 60.6603 71.641 61.7321L43 78.268C41.1436 79.3397 38.8564 79.3397 37 78.268L8.35898 61.7321C6.50257 60.6603 5.35898 58.6795 5.35898 56.5359V23.4641C5.35898 21.3205 6.50258 19.3397 8.35898 18.268L37 1.73205Z" fill="#CACBFB"/>
                        <g clip-path="url(#clip0_3012_14676)">
                            <path d="M56.1073 51.3883V55.5316C56.1071 55.8662 56.0062 56.193 55.8178 56.4694C55.6293 56.7459 55.362 56.9592 55.0507 57.0816L50.0257 59.06C49.6495 59.2005 49.2352 59.2005 48.859 59.06L43.8323 57.0816C43.5213 56.9589 43.2544 56.7455 43.0662 56.4691C42.8781 56.1926 42.7774 55.866 42.7773 55.5316V51.3883" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M49.1197 45.9666L39.4414 49.9999L49.1197 54.0332C49.2214 54.0757 49.3304 54.0975 49.4406 54.0975C49.5507 54.0975 49.6598 54.0757 49.7614 54.0332L58.5181 50.3849C58.5942 50.3534 58.6593 50.3 58.7052 50.2315C58.751 50.1629 58.7754 50.0823 58.7754 49.9999C58.7754 49.9175 58.751 49.8369 58.7052 49.7684C58.6593 49.6998 58.5942 49.6464 58.5181 49.6149L49.7614 45.9666C49.6598 45.9242 49.5507 45.9023 49.4406 45.9023C49.3304 45.9023 49.2214 45.9242 49.1197 45.9666V45.9666Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M39.4414 50V55.8333" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21.1094 45.8333C21.1094 43.1811 22.1629 40.6375 24.0383 38.7622C25.9137 36.8868 28.4572 35.8333 31.1094 35.8333C33.7615 35.8333 36.3051 36.8868 38.1804 38.7622C40.0558 40.6375 41.1094 43.1811 41.1094 45.8333H21.1094Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M24.8594 27.0833C24.8594 27.904 25.021 28.7167 25.3351 29.475C25.6492 30.2333 26.1096 30.9223 26.69 31.5027C27.2703 32.083 27.9593 32.5434 28.7176 32.8575C29.4759 33.1716 30.2886 33.3333 31.1094 33.3333C31.9301 33.3333 32.7429 33.1716 33.5011 32.8575C34.2594 32.5434 34.9484 32.083 35.5288 31.5027C36.1092 30.9223 36.5695 30.2333 36.8836 29.475C37.1977 28.7167 37.3594 27.904 37.3594 27.0833C37.3594 26.2625 37.1977 25.4498 36.8836 24.6915C36.5695 23.9332 36.1092 23.2442 35.5288 22.6638C34.9484 22.0835 34.2594 21.6231 33.5011 21.309C32.7429 20.9949 31.9301 20.8333 31.1094 20.8333C30.2886 20.8333 29.4759 20.9949 28.7176 21.309C27.9593 21.6231 27.2703 22.0835 26.69 22.6638C26.1096 23.2442 25.6492 23.9332 25.3351 24.6915C25.021 25.4498 24.8594 26.2625 24.8594 27.0833V27.0833Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M40.6914 27.0833C40.6914 28.7409 41.3499 30.3306 42.522 31.5027C43.6941 32.6748 45.2838 33.3333 46.9414 33.3333C48.599 33.3333 50.1887 32.6748 51.3608 31.5027C52.5329 30.3306 53.1914 28.7409 53.1914 27.0833C53.1914 25.4256 52.5329 23.8359 51.3608 22.6638C50.1887 21.4917 48.599 20.8333 46.9414 20.8333C45.2838 20.8333 43.6941 21.4917 42.522 22.6638C41.3499 23.8359 40.6914 25.4256 40.6914 27.0833V27.0833Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M41.9648 37.1567C43.3653 36.3535 44.9395 35.9013 46.5528 35.8388C48.1661 35.7763 49.7705 36.1053 51.2289 36.7978C52.6874 37.4902 53.9565 38.5255 54.9278 39.8152C55.8991 41.1048 56.5437 42.6104 56.8065 44.2034" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3012_14676">
                                <rect width="40" height="40" fill="white" transform="translate(20 20)"/>
                            </clipPath>
                        </defs>
                    </svg>

                    <div className="coming-soon__header-text">
                        <H6>
                            Coming Soon!
                        </H6>

                        <H1>
                            Abuzz Alumni
                        </H1>
                    </div>
                </header>

                <div className="coming-soon__content-text">
                    <p>
                        Abuzz Alumni retains the connection between alumni, as well as the school.
                    </p>

                    <ul>
                        <li>
                            Alumni want to know how other alumni are doing, and where they live and work.
                        </li>

                        <li>
                            Alumni offices manage alumni lists for outgoing communication using email. Is that still the best way?
                        </li>
                    </ul>

                    <Button
                        className="coming-soon__contact-button"
                        href="mailto:info@campusabuzz.com"
                    >
                        Contact Us Today
                    </Button>
                </div>
            </div>

            <div className="coming-soon__image">
                <img src="/img/coming-soon-alumni.jpg" alt="Screenshots of the alumni app running on an iphone" />
            </div>
        </>
    );

    const renderStudentContent = () => (
        <>
            <div className="coming-soon__content">
                <header className="coming-soon__header">
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37 1.73205C38.8564 0.660254 41.1436 0.660254 43 1.73205L71.641 18.268C73.4974 19.3397 74.641 21.3205 74.641 23.4641V56.5359C74.641 58.6795 73.4974 60.6603 71.641 61.7321L43 78.268C41.1436 79.3397 38.8564 79.3397 37 78.268L8.35898 61.7321C6.50257 60.6603 5.35898 58.6795 5.35898 56.5359V23.4641C5.35898 21.3205 6.50258 19.3397 8.35898 18.268L37 1.73205Z" fill="#CACBFB"/>
                        <g clip-path="url(#clip0_3_427)">
                            <path d="M40 36.1666V40.3333" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M40 40.3333L43.75 44.0833" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M40 40.3333L36.25 44.0833" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M36.25 21.5833C36.25 22.5778 36.6451 23.5317 37.3483 24.2349C38.0516 24.9382 39.0054 25.3333 40 25.3333C40.9946 25.3333 41.9484 24.9382 42.6516 24.2349C43.3549 23.5317 43.75 22.5778 43.75 21.5833C43.75 20.5887 43.3549 19.6349 42.6516 18.9316C41.9484 18.2284 40.9946 17.8333 40 17.8333C39.0054 17.8333 38.0516 18.2284 37.3483 18.9316C36.6451 19.6349 36.25 20.5887 36.25 21.5833V21.5833Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M47.5 32.8333C47.075 31.1667 46.1071 29.6891 44.7489 28.6339C43.3908 27.5786 41.7199 27.0058 40 27.0058C38.2801 27.0058 36.6092 27.5786 35.2511 28.6339C33.8929 29.6891 32.925 31.1667 32.5 32.8333H47.5Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M24.584 44.9166C24.584 45.4091 24.681 45.8967 24.8694 46.3517C25.0579 46.8067 25.3341 47.2201 25.6823 47.5683C26.0306 47.9165 26.444 48.1927 26.8989 48.3812C27.3539 48.5696 27.8415 48.6666 28.334 48.6666C28.8264 48.6666 29.3141 48.5696 29.769 48.3812C30.224 48.1927 30.6374 47.9165 30.9856 47.5683C31.3339 47.2201 31.6101 46.8067 31.7985 46.3517C31.987 45.8967 32.084 45.4091 32.084 44.9166C32.084 44.4242 31.987 43.9365 31.7985 43.4816C31.6101 43.0266 31.3339 42.6132 30.9856 42.265C30.6374 41.9168 30.224 41.6405 29.769 41.4521C29.3141 41.2636 28.8264 41.1666 28.334 41.1666C27.8415 41.1666 27.3539 41.2636 26.8989 41.4521C26.444 41.6405 26.0306 41.9168 25.6823 42.265C25.3341 42.6132 25.0579 43.0266 24.8694 43.4816C24.681 43.9365 24.584 44.4242 24.584 44.9166V44.9166Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M35.834 56.1667C35.409 54.5001 34.4411 53.0225 33.0829 51.9673C31.7248 50.912 30.0539 50.3391 28.334 50.3391C26.6141 50.3391 24.9432 50.912 23.585 51.9673C22.2269 53.0225 21.259 54.5001 20.834 56.1667H35.834Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M47.9165 44.9166C47.9165 45.9112 48.3116 46.865 49.0149 47.5683C49.7181 48.2715 50.6719 48.6666 51.6665 48.6666C52.6611 48.6666 53.6149 48.2715 54.3182 47.5683C55.0214 46.865 55.4165 45.9112 55.4165 44.9166C55.4165 43.9221 55.0214 42.9682 54.3182 42.265C53.6149 41.5617 52.6611 41.1666 51.6665 41.1666C50.6719 41.1666 49.7181 41.5617 49.0149 42.265C48.3116 42.9682 47.9165 43.9221 47.9165 44.9166Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M59.1665 56.1667C58.7415 54.5001 57.7736 53.0225 56.4154 51.9673C55.0573 50.912 53.3864 50.3391 51.6665 50.3391C49.9466 50.3391 48.2757 50.912 46.9176 51.9673C45.5594 53.0225 44.5915 54.5001 44.1665 56.1667H59.1665Z" stroke="#4E50F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_3_427">
                                <rect width="40" height="40" fill="white" transform="translate(20 16.9999)"/>
                            </clipPath>
                        </defs>
                    </svg>

                    <div className="coming-soon__header-text">
                        <H6>
                            Activate Now!
                        </H6>

                        <H1>
                            Abuzz Student
                        </H1>
                    </div>
                </header>

                <div className="coming-soon__content-text">
                    <p>
                        Abuzz Student instantly builds campus-centric connectivity between students and school administrators.
                    </p>

                    <ul>
                        <li>
                            Automatic grouping by Class Year
                        </li>

                        <li>
                            Automatic grouping by Major
                        </li>

                        <li>
                            Searchable Student Directory
                        </li>

                        <li>
                            Direct Messaging
                        </li>
                    </ul>

                    <Button
                        className="coming-soon__contact-button"
                        href="mailto:info@campusabuzz.com"
                    >
                        Contact Us Today
                    </Button>
                </div>
            </div>

            <div className="coming-soon__image">
                <img src="/img/coming-soon-student.jpg" alt="Screenshots of the student app running on an iphone" />
            </div>
        </>
    );

    const renderContent = () => {
        switch(params.profileType) {
            case ProfileTypes.Admit:
                return renderAdmitContent();

            case ProfileTypes.Alumni:
                return renderAlumniContent();

            case ProfileTypes.Student:
                return renderStudentContent();

            default:
                return null;
        }
    }

    return (
        <div className="coming-soon coming-soon--admit">
            <Card className="coming-soon__container">
                {renderContent()}
            </Card>
        </div>
    );
};

export default ComingSoon;
