import './ApiRequestTable.scss';

import React, { useEffect, useMemo } from 'react';
import clone from 'clone';

import TableColumn from "../../../../types/TableColumn";

import { isArrayNullOrEmpty } from "../../../../utils/utils";
import { CAPABILITIES, userCan } from "../../../../utils/roles";


import {getApiRequests} from '../../../../store/slices/engineering';
import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import H5 from "../../../../components/H5";
import Table from "../../../../components/Table";

const ApiRequestTable: React.FC = () => {
    const dispatch = useAppDispatch();

    const { apiRequestsMetadata, apiRequests, isGettingApiRequests } = useTypedSelector((state) => state.engineering);
    const { roles } = useTypedSelector((state) => state.auth);

    useEffect(() => {
        const initialize = async () => {
            if(isGettingApiRequests) {
                return;
            }

            try {
                if(!isArrayNullOrEmpty(roles) && userCan(CAPABILITIES.VIEW_ENGINEERING, roles[0])) {
                    dispatch(getApiRequests({}));
                }
            } catch(err) {
                console.log('ApiRequestTable initialize err', err);
            }
        };

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columnsArray: Array<TableColumn> = [
                {
                    Header: 'Method',
                    accessor: 'method'
                }, {
                    Header: 'Status',
                    accessor: 'status'
                },{
                    Header: 'Path',
                    accessor: 'path'
                },{
                    Header: 'Profile ID',
                    accessor: 'profileId'
                },{
                    Header: 'Error',
                    accessor: 'error'
                },{
                    Header: 'IP Address',
                    accessor: 'ipAddress'
                }, {
                    Header: 'State',
                    accessor: 'state'
                }, {
                    Header: 'App',
                    accessor: 'app'
                }
            ];

            return columnsArray;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const data = useMemo(
        () => {
            return apiRequests;
        },
        [apiRequests]
    );

    const onMetadataChange = async (changes, isUpdate) => {
        console.log('onMetadataChange', changes, isUpdate);
        try {
            let clonedMetadata = clone(apiRequestsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getApiRequests({isUpdate, apiRequestsMetadata: clonedMetadata}));
        } catch(err) {
            console.log('CronJobsTable onMetadataChange err', err);
        }
    };

    return (
        <div className="engineering">
            <H5>
                API Requests
            </H5>

            <div>
                {isGettingApiRequests ? (
                    <ActivityIndicator size="large" />
                ) : (
                    <>
                        <Table
                            columns={columns}
                            data={data}
                            getData={onMetadataChange}
                            pageNumber={apiRequestsMetadata.page_num}
                            pageSize={apiRequestsMetadata.page_size}
                            totalNumberOfItems={apiRequestsMetadata.total}
                            /*onRowClick={handleRowPress}*/
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default ApiRequestTable;
