import './TablePublishedAt.scss';

import React from 'react';
import moment from 'moment';

import Deal from "../../../types/Deal";
import EventPost from "../../../types/EventPost";
import IntegrationEvent from "../../../types/IntegrationEvent";
import MarketplaceItem from "../../../types/MarketplaceItem";
import Thread from "../../../types/Thread";
import NewsStory from "../../../types/NewsStory";
import PushNotification from "../../../types/PushNotification";

import { POST_STATUS_LABELS } from '../../../utils/constants';
import { PostStatuses } from "../../../utils/enums";

type Props = {
    post?: Deal | EventPost | IntegrationEvent | MarketplaceItem | NewsStory | Thread
    pushNotification?: PushNotification
}

const TablePublishedAt: React.FC<Props> = ({
    post,
    pushNotification
}) => {
    let date;

    if(post) {
        date = post.publishedAt;

        if (post.status === PostStatuses.Active && !post.publishedAt) {
            date = post.createdAt;
        } else if (post.status === PostStatuses.Draft) {
            if (post.publishAt) {
                date = post.publishAt;
            } else {
                date = null;
            }
        } else if (post.status === PostStatuses.Scheduled && post.publishAt) {
            date = post.publishAt;
        }
    } else if(pushNotification) {
        date = pushNotification.sentAt || pushNotification.sendAt;
    }

    return (
        <div className="c-table-published-at">
            <div className="c-table-published-at__status">
                {post ? POST_STATUS_LABELS[post.status] : (pushNotification ? (pushNotification.sentAt ? 'Sent at' : 'Scheduled') : null)}
            </div>
            {date && (
                <div className="c-table-published-at__date">
                    {moment(date).format('YYYY/MM/DD [at] h:mm a')}
                </div>
            )}
        </div>
    );
};

export default TablePublishedAt;
