import './GridList.scss';

import React, { useState } from 'react';

import AdmitProfile from "../../types/AdmitProfile";
import ClassNote from '../../types/ClassNote';
import Deal from "../../types/Deal";
import EventPost from "../../types/EventPost";
import Group from "../../types/Group";
import MarketplaceItem from "../../types/MarketplaceItem";
import NewContentMenuOption from "../../types/NewContentMenuOption";
import NewsStory from "../../types/NewsStory";
import Profile from "../../types/Profile";
import ProfilePromotion from '../../types/ProfilePromotion';
import Thread from "../../types/Thread";

import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../utils/utils";

import Button, { ButtonSizes, ButtonThemes } from "../Button";
import Card from "../Card";
import GridClassNote from './GridClassNote';
import GridDeal from "./GridDeal";
import GridEvent from "./GridEvent";
import GridGroup from "./GridGroup";
import GridMarketplaceItem from "./GridMarketplaceItem";
import GridStudent from "./GridStudent";
import GridThread from "./GridThread";
import GridUser from "./GridUser";
import Icon from "../Icon";
import NewContentMenu from "../NewContentMenu";
import GridNewsStory from "./GridNewsStory";
import GivingOpportunity from "../../types/GivingOpportunity";
import GridGivingOpportunity from "./GridGivingOpportunity";
import GridProfilePromotion from './GridProfilePromotion';

export enum GridListTypes {
    Group = 'group',
    News = 'news',
    Student = 'student',
    User = 'user',
    ProfilePromotion = 'profile-promotion',
}

type Props = {
    createCardButtonOnClick?: Function
    createCardButtonText?: string
    createCardNewContentMenuOptions?: Array<NewContentMenuOption>
    createCardText?: string
    disablePending?: boolean
    isLastPage: boolean
    items: Array<AdmitProfile | ClassNote | Deal | EventPost | GivingOpportunity | Group | MarketplaceItem | NewsStory | Profile | ProfilePromotion | Thread>
    listType?: GridListTypes
    moreMenuComponent?: Function
    onNextPageClick: Function
    onOverlayClick?: Function
    onPreviousPageClick: Function
    overlayButtonText?: string
    pageNumber: number
}

const GridList: React.FC<Props> = ({
    createCardButtonOnClick,
    createCardButtonText,
    createCardNewContentMenuOptions,
    createCardText,
    disablePending,
    isLastPage,
    items,
    listType,
    moreMenuComponent,
    onNextPageClick,
    onOverlayClick,
    onPreviousPageClick,
    overlayButtonText,
    pageNumber,
}) => {
    const [showNewContentMenu, setShowNewContentMenu] = useState(false);

    const renderItem = (item) => {
        if(isObjectNullOrEmpty(item)) {
            return null;
        }

        if(!listType) {
            switch (item.type) {
                case 'D':
                    return <GridDeal disablePending={disablePending} item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;
                case 'E':
                    return <GridEvent disablePending={disablePending} item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;
                case 'I':
                    return <GridMarketplaceItem disablePending={disablePending} item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;
                case 'S':
                    return <GridClassNote disablePending={disablePending} item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;
                case 'T':
                    return <GridThread disablePending={disablePending} item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;
                case 'GivingOpportunity':
                    return <GridGivingOpportunity item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;

                default:
                    return null;
            }
        } else {
            switch(listType) {
                case 'group':
                    return <GridGroup item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;
                case 'news':
                    return <GridNewsStory item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;
                case 'student':
                    return <GridStudent item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;
                case 'user':
                    return <GridUser item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;
                case 'profile-promotion':
                    return <GridProfilePromotion item={item} moreMenuComponent={moreMenuComponent} onClick={onOverlayClick} />;

                default:
                    return null;
            }
        }
    };

    if(isArrayNullOrEmpty(items) && !(createCardButtonOnClick || createCardNewContentMenuOptions) && !createCardButtonText) {
        return null;
    }

    return (
        <div className={`c-grid-list ${listType ? `a-grid-list--${listType}` : ''}`}>
            <div className="c-grid-list__grid-container">
                {(createCardButtonOnClick || createCardNewContentMenuOptions) && createCardButtonText && (
                    <Card
                        className={`c-grid-list__item c-grid-list__create ${createCardText != null ? 'a-grid-list--has-create-card-text' : ''}`}
                        onClick={() => {
                            if(!isArrayNullOrEmpty(createCardNewContentMenuOptions)) {
                                setShowNewContentMenu(true);
                            } else {
                                createCardButtonOnClick();
                            }
                        }}
                    >
                        <div className="c-grid-list__create-icon-container">
                            <Icon type="plus-circle" />
                        </div>

                        <Button
                            onClick={() => createCardButtonOnClick && createCardButtonOnClick()}
                            size={ButtonSizes.Small}
                            theme={ButtonThemes.Light}
                        >
                            {createCardButtonText}
                        </Button>

                        {createCardText && (
                            <div className="c-grid-list__create-text">
                                {createCardText}
                            </div>
                        )}

                        {!isArrayNullOrEmpty(createCardNewContentMenuOptions) && (
                            <NewContentMenu
                                close={(e) => {
                                    e.stopPropagation();
                                    setShowNewContentMenu(false);
                                }}
                                options={createCardNewContentMenuOptions}
                                runCloseOnClick
                                show={showNewContentMenu}
                            />
                        )}
                    </Card>
                )}
                {items.map((item) => renderItem(item))}
            </div>

            <div className="c-grid-list__pagination">
                <Button
                    className="c-grid-list__previous-page"
                    disabled={pageNumber === 0}
                    noStyles
                    onClick={onPreviousPageClick}
                >
                    <Icon type="arrow-left" />

                    Previous
                </Button>

                <Button
                    className="c-grid-list__next-page"
                    disabled={isLastPage}
                    noStyles
                    onClick={onNextPageClick}
                >
                    Next

                    <Icon type="arrow-right" />
                </Button>
            </div>
        </div>
    );
};

export default GridList;
