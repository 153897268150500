import React, { useEffect, useMemo, useState } from 'react';
import { validated } from "react-custom-validation";
import clone from 'clone';

import DropdownOption from "../../../../types/DropdownOption";
import IFile from "../../../../types/IFile";
import Sponsorship from "../../../../types/Sponsorship";
import Validation from "../../../../types/Validation";
import WalletCategory from "../../../../types/WalletCategory";

import { SPONSORSHIP_TYPES } from "../../../../utils/constants";
import { handleTextChange } from "../../../../utils/handle-changes";
import { isRequired, isRequiredIfTrue, isUrl } from "../../../../utils/validations";
import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../../../utils/utils";

import { clearSponsorship, deleteSponsorship, getAllWalletCategories, getSponsorships, saveSponsorship, setSponsorship } from "../../../../store/slices/sponsorships";
import { useAppDispatch } from "../../../../store";
import { useTypedSelector } from "../../../../store/reducers";

import useSponsorships from "../../../../hooks/useSponsorships";

import AttachContentModal from "../../../Content/Notifications/CreateEditNotification/components/AttachContentModal";
import Button, { ButtonThemes, ButtonTypes } from "../../../../components/Button";
import ButtonRow from "../../../../components/ButtonRow";
import Dropdown from "../../../../components/Dropdown";
import FileUpload from "../../../../components/FileUpload";
import Form from "../../../../components/Form";
import FormError from "../../../../components/FormError";
import FormRow from "../../../../components/FormRow";
import FormValidationMessage from "../../../../components/FormValidationMessage";
import H4 from "../../../../components/H4";
import H5 from "../../../../components/H5";
import Label from "../../../../components/Label";
import Modal from "../../../../components/Modal";
import SuccessModal from "../../../../components/Modal/SuccessModal";
import Textbox from "../../../../components/Textbox";
import ToggleSwitch from "../../../../components/ToggleSwitch";

type Props = {
    close?: Function
    sponsorship: Sponsorship
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        artifactId: Validation
        linkUrl: Validation
        name: Validation
        tenantId: Validation
        type: Validation
    }
}

const CreateEditSponsorshipForm: React.FC<Props> = ({
    close,
    sponsorship,
    $field,
    $fieldEvent,
    $submit,
    $validation,
}) => {
    const dispatch = useAppDispatch();
    const { testBannerSize } = useSponsorships();

    const [isUploadingArtifact, setIsUploadingArtifact] = useState(false);
    const [showIncorrectSizeError, setShowIncorrectSizeError] = useState(false);
    const [showSelectTenantModal, setShowSelectTenantModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showCategoryClearedModal, setShowCategoryClearedModal] = useState(false);

    const { isDeletingSponsorship, isSavingSponsorship, saveSponsorshipError, walletCategories } = useTypedSelector((state) => state.sponsorships);

    useEffect(() => {
        populateDataForForm();

        return () => {
            setTimeout(() => dispatch(clearSponsorship()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = async (name: string, value: any) => {
        let clonedSponsorship = clone(sponsorship);
        clonedSponsorship[name] = value;

        if(name === 'artifactId') {
            delete clonedSponsorship.artifact;
        }

        if(name === 'type') {
            delete clonedSponsorship.artifact;
            delete clonedSponsorship.artifactId;
            delete clonedSponsorship.walletCategoryId;

            if(value === SPONSORSHIP_TYPES.CATEGORY && isArrayNullOrEmpty(walletCategories)) {
                dispatch(getAllWalletCategories({tenantId: sponsorship.tenant?.tenantId}));
            }
        }

        if(name === 'global' && value === true) {
            delete clonedSponsorship.tenant;
            delete clonedSponsorship.tenantIds;
            await updateWalletCategories(clonedSponsorship, null);
        }

        if(name === 'tenant') {
            if(value != null) {
                clonedSponsorship.tenantIds = [value.tenantId];
                await updateWalletCategories(clonedSponsorship, value.tenantId);
            } else {
                delete clonedSponsorship.tenant;
                delete clonedSponsorship.tenantIds;
            }
        }

        dispatch(setSponsorship(clonedSponsorship));
    };

    const updateWalletCategories = async (clonedSponsorship, tenantId) => {
        const cats = await dispatch(getAllWalletCategories({tenantId}));
        const isAvailable = cats.payload.find(cat => cat.walletCategoryId === sponsorship.walletCategoryId);
        if (!isAvailable && clonedSponsorship.walletCategoryId) {
            clonedSponsorship.walletCategoryId = null;
            setShowCategoryClearedModal(true);
        }
    }

    const handleDelete = async () => {
        try {
            await dispatch(deleteSponsorship({sponsorship})).unwrap();
            $fieldEvent('reset');
            dispatch(getSponsorships({}));
            close && close();
        } catch (err) {
            console.log('CreateEditSponsorshipForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const onUploadStart = () => {
        setIsUploadingArtifact(true);
        setShowIncorrectSizeError(false);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange('artifactId', artifactId);
        }
        setIsUploadingArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingArtifact(false);
    };

    const handleImageDelete = () => {
        handleChange('artifactId', null);
    };

    const imageValidator = async (file: IFile) => {
        const isCorrectSize = await testBannerSize(file, sponsorship.type);
        if(!isCorrectSize) {
            setShowIncorrectSizeError(true);
            setIsUploadingArtifact(false);
            return false;
        }
        return true;
    }

    const handleSave = async (isValid: boolean) => {
        if(!isValid) {
            return;
        }

        try {
            await dispatch(saveSponsorship()).unwrap();
            setShowSuccessModal(true);
            setTimeout(() => $fieldEvent('reset'), 250);
        } catch(err) {
            console.log('CreateEditSponsorshipForm handleSave err', err);
        }
    }

    const populateDataForForm = () => {
        const clonedSponsorship = clone(sponsorship);

        if(!isArrayNullOrEmpty(sponsorship.walletSponsorshipTenants)) {
            clonedSponsorship.tenant = sponsorship.walletSponsorshipTenants[0].tenant;
            clonedSponsorship.tenantIds = sponsorship.walletSponsorshipTenants.map(t => t.tenantId);
        }

        if(!isObjectNullOrEmpty(sponsorship.assignedWalletCategory)) {
            clonedSponsorship.walletCategoryId = sponsorship.assignedWalletCategory.walletCategoryId;
        }

        if(sponsorship.type === SPONSORSHIP_TYPES.CATEGORY) {
            dispatch(getAllWalletCategories({tenantId: clonedSponsorship.tenant?.tenantId}));
        }

        dispatch(setSponsorship(clonedSponsorship));
    }

    const categoryDropdownOptions: Array<DropdownOption> = useMemo(
        () => {
            if(isArrayNullOrEmpty(walletCategories)) {
                return [];
            }

            return walletCategories.map((wc: WalletCategory) => ({
                label: wc.value,
                value: wc.walletCategoryId,
            }));
        }, [walletCategories]
    );

    const typeDropdownOptions: Array<DropdownOption> = useMemo(
        () => {
            return Object.keys(SPONSORSHIP_TYPES).map((key) => ({
                label: SPONSORSHIP_TYPES[key],
                value: SPONSORSHIP_TYPES[key],
            }));
        }, []
    );

    // console.log($validation);

    return (
        <>
            <H4>
                {sponsorship.walletSponsorshipId ? 'Edit' : 'Create a'} Sponsorship
            </H4>

            <div className="sponsorship-form-container">
                <Form className="sponsorship-form">
                    <FormRow>
                        <Textbox
                            disabled={isUploadingArtifact}
                            id="txtName"
                            label="Sponsorship Name (For Tracking Usage Only)"
                            name="name"
                            required
                            type="text"
                            validation={$validation.name}
                            value={sponsorship.name || ''}
                            {...$field('name', event => handleTextChange(handleChange, event))}
                        />
                    </FormRow>

                    <FormRow>
                        <Dropdown
                            className="sponsorship-form__type-dropdown"
                            disabled={isUploadingArtifact}
                            label="Sponsorship Type"
                            name="type"
                            onChange={({value}) => handleChange('type', value)}
                            options={typeDropdownOptions}
                            placeholder="Start by selecting a sponsorship type"
                            required
                            value={sponsorship.type}
                            validation={$validation.type}
                        />
                    </FormRow>

                    {sponsorship.type != null && (
                        <>
                            <FormRow>
                                <Label>
                                    Upload banner graphic <span className="c-label__required-asterisk">*</span>
                                </Label>

                                <FileUpload
                                    disabled={isUploadingArtifact}
                                    acceptsVideo={true}
                                    currentArtifact={sponsorship.artifact || null}
                                    name="artifactId"
                                    onDelete={handleImageDelete}
                                    retainMimeType
                                    onStart={onUploadStart}
                                    onSuccess={onUploadSuccess}
                                    onFailure={onUploadFailure}
                                    uploadType="sponsorship"
                                    validator={imageValidator}
                                />

                                {$validation.artifactId.isValid === false && $validation.artifactId.show === true && (
                                    <FormValidationMessage>
                                        {$validation.artifactId.error.reason}
                                    </FormValidationMessage>
                                )}

                                {showIncorrectSizeError && (
                                    <FormValidationMessage>
                                        Incorrectly sized banner image. Please upload another.
                                    </FormValidationMessage>
                                )}

                                <div className="sponsorship-form__instructions">
                                    <strong>Specs (all sized in 3x)</strong><br />
                                    Category Sponsor: 1184px x 264px<br />
                                    Featured Sponsor: 1122px x 264px<br />
                                    Headline Sponsor: 1242px x 168px
                                </div>
                            </FormRow>

                            <FormRow>
                                <Textbox
                                    disabled={isUploadingArtifact}
                                    id="txtLinkUrl"
                                    label="Destination URL"
                                    name="linkUrl"
                                    required
                                    type="text"
                                    validation={$validation.linkUrl}
                                    value={sponsorship.linkUrl || ''}
                                    {...$field('linkUrl', event => handleTextChange(handleChange, event))}
                                />
                            </FormRow>
                        </>
                    )}

                    {sponsorship.type === SPONSORSHIP_TYPES.CATEGORY && (
                        <FormRow>
                            <Dropdown
                                disabled={isUploadingArtifact}
                                label="Category"
                                name="walletCategoryId"
                                onChange={({value}) => handleChange('walletCategoryId', value)}
                                options={categoryDropdownOptions}
                                placeholder="select a category"
                                value={sponsorship.walletCategoryId}
                            />

                            <div className="sponsorship-form__instructions">
                                If left empty, this sponsorship will show when "All" is selected for a category.
                            </div>
                        </FormRow>
                    )}

                    {sponsorship.type != null && (
                        <>
                            <FormRow className="sponsorship-form__toggles">
                                <ToggleSwitch
                                    checked={sponsorship.enabled}
                                    label="Enabled"
                                    name="enabled"
                                    onClick={() => handleChange('enabled', !sponsorship.enabled)}
                                />

                                <ToggleSwitch
                                    checked={sponsorship.global}
                                    label="Global Sponsorship"
                                    name="global"
                                    onClick={() => handleChange('global', !sponsorship.global)}
                                />
                            </FormRow>

                            {!sponsorship.global && (
                                <FormRow>
                                    <div className="sponsorship-form__select-tenant-container">
                                        {sponsorship.tenant ? (
                                            <div className="sponsorship-form__select-tenant-description">
                                                <strong>{sponsorship.tenant.name}</strong><br />
                                                Sponsorship only visible at this college.
                                            </div>
                                        ) : (
                                            <div className="sponsorship-form__select-tenant-description">
                                                <strong>No college selected</strong><br />
                                                Select a college to limit display of this sponsorship.
                                            </div>
                                        )}

                                        <Button
                                            onClick={() => setShowSelectTenantModal(true)}
                                        >
                                            {sponsorship.tenant ? 'Change' : 'Select'} college
                                        </Button>
                                    </div>

                                    {$validation.tenantId.isValid === false && $validation.tenantId.show === true && (
                                        <FormValidationMessage>
                                            {$validation.tenantId.error.reason}
                                        </FormValidationMessage>
                                    )}
                                </FormRow>
                            )}
                        </>
                    )}

                    <FormError
                        error={saveSponsorshipError}
                    />

                    <ButtonRow>
                        <Button
                            disabled={isUploadingArtifact}
                            onClick={(event) => {
                                event.preventDefault();
                                $submit(() => handleSave(true), () => handleSave(false));
                            }}
                            showActivityIndicator={isSavingSponsorship}
                            type={ButtonTypes.Submit}
                        >
                            {sponsorship.walletSponsorshipId ? 'Save' : 'Create'} Sponsorship
                        </Button>

                        {sponsorship.walletSponsorshipId && (
                            <Button
                                className="destructive"
                                disabled={isUploadingArtifact}
                                onClick={() => setShowDeleteConfirmationModal(true)}
                                theme={ButtonThemes.Link}
                            >
                                Delete
                            </Button>
                        )}
                    </ButtonRow>
                </Form>

                <div className="sponsorship-form__types">
                    <H5>
                        Sponsorship Types
                    </H5>

                    <dl>
                        <dt>
                            Basic Deals
                        </dt>

                        <dd>
                            Basic deals are created in individual tenants. Select a school in the left menu to create one.
                        </dd>

                        <dt>
                            Category Sponsors
                        </dt>

                        <dd>
                            Category sponsors will display within a specific category (e.g. Food  & Drink). These special paid ads remain globally persistent at the top of a category with premium visual placement.
                        </dd>

                        <dt>
                            Featured Sponsors
                        </dt>

                        <dd>
                            Featured sponsorships are paid and receive priority placement in the wallet. Featured placements will display a “sponsored” badge to increase visual attention.
                        </dd>

                        <dt>
                            Headline Sponsors
                        </dt>

                        <dd>
                            Our highest level of sponsorship, Global sponsors will appear at the top of the Student Wallet at all times until the sponsorship ends.
                        </dd>
                    </dl>
                </div>
            </div>

            <AttachContentModal
                close={() => setShowSelectTenantModal(false)}
                handleSelect={(selectedTenant) => {
                    handleChange('tenant', selectedTenant);
                    setShowSelectTenantModal(false);
                }}
                show={showSelectTenantModal}
                type="tenant"
            />

            <Modal
                title="Category Cleared"
                show={showCategoryClearedModal}
                declineButtonText="OK"
                declineButtonOnClick={() => setShowCategoryClearedModal(false)}
            >
                {isArrayNullOrEmpty(sponsorship.tenantIds) ? (
                    <p>The selected category is not available for global sponsorships. Please select a new one.</p>
                ) : (
                    <p>The selected category is not available for {`${sponsorship?.tenant?.name ? sponsorship.tenant.name : 'the selected tenant'}`}. Please select a new one.</p>
                )}
            </Modal>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getSponsorships({}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
            >
                <p>
                    This sponsorship has been saved.
                </p>
                <p>
                    It may take a minute for a new sponsorship to show in feeds and lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                // confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingSponsorship}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this sponsorship?
            </Modal>
        </>
    );
};

function createEditSponsorshipFormValidationConfig(props) {
    let { artifactId, global, linkUrl, name, tenantIds, type, walletSponsorshipTenants }: Sponsorship = props.sponsorship;

    // console.log(type);

    return {
        fields: ['artifactId', 'linkUrl', 'name', 'tenantId', 'type'],
        validations: {
            artifactId: [
                [isRequired, artifactId, 'An image is required']
            ],
            linkUrl: [
                [isRequired, linkUrl],
                [isUrl, linkUrl],
            ],
            name: [
                [isRequired, name]
            ],
            tenantId: [
                [isRequiredIfTrue, tenantIds, global === false && isArrayNullOrEmpty(walletSponsorshipTenants), 'You must select a college if a sponsorship is not global']
            ],
            type: [
                [isRequired, type]
            ],
        }
    }
}

export default validated(createEditSponsorshipFormValidationConfig)(CreateEditSponsorshipForm);
