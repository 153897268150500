import './ThreadComment.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CommentType from '../../types/Comment';
import EventPost from "../../types/EventPost";
import Thread from '../../types/Thread';

import { useTypedSelector } from '../../store/reducers';
import { deleteThreadComment } from '../../store/slices/threads';

import Avatar from '../Avatar';
import Button, { ButtonThemes } from '../Button';
import FeedFlaggedBanner from '../Feed/FeedFlaggedBanner';
import Icon from '../Icon';
import Modal from '../Modal';
import ModerationOptionsModal from '../ModerationOptionsModal';
import ParagraphsFromNewlines from '../ParagraphsFromNewlines';
import ThreadCommentForm from './ThreadCommentForm';

type CommentProps = {
    comment: CommentType
};

const Comment: React.FC<CommentProps>  = ({
    comment,
}) => (
    <div className="c-thread-comment__comment">
        <h4 className="c-thread-comment__heading">
            {comment.profile.firstName} {comment.profile.lastName}
        </h4>

        <ParagraphsFromNewlines
            className="c-thread-comment__body"
            linkify
            text={comment.comment || comment.body}
        />
    </div>
);

type Props = {
    comment: CommentType
    depth?: number
    disableReply?: boolean
    getAfterModerate?: () => Promise<any>
    isReply: boolean
    showOneLevel?: boolean
    thread: Thread | EventPost
}

const ThreadComment: React.FC<Props> = ({
    comment,
    depth = 0,
    disableReply,
    getAfterModerate,
    isReply,
    showOneLevel,
    thread,
}) => {
    const dispatch = useDispatch();

    const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false);
    const [showDeleteCommentErrorModal, setShowDeleteCommentErrorModal] = useState(false);
    const [showModerationModal, setShowModerationModal] = useState(false);
    const [showCommentForm, setShowCommentForm] = useState(false);
    const [deleteCommentError, setDeleteCommentError] = useState(null);
    const [commentToDelete, setCommentToDelete] = useState(null);

    const { isDeletingThreadComment } = useTypedSelector(store => store.threads);
    const { postAsProfile } = useTypedSelector(store => store.schools.activeSchool);

    const handleDeleteComment = async (comment) => {
        if (comment.postCommentId) {
            await dispatch(deleteThreadComment({threadId: thread.postId, postCommentId: comment.postCommentId }));
            setShowDeleteCommentModal(false);
        } else {
            setDeleteCommentError('Please refresh before deleting a newly added comment');
            setShowDeleteCommentModal(true);
        }
    };

    return (
        <div className={`c-thread-comment-wrapper ${(comment.comments?.length > 0 || showCommentForm) ? 'c-thread-comment-wrapper--has-children' : ''}`}>
            <div className="c-thread-comment" key={comment.postCommentId}>
                {!disableReply &&comment.profileId === postAsProfile.profileId ? (
                    <Button
                        theme={ButtonThemes.Link}
                        className="c-thread-comment__delete"
                        onClick={() => {
                            if (comment.postCommentId) {
                                setShowDeleteCommentModal(true);
                                setCommentToDelete(comment);
                            } else {
                                setDeleteCommentError('Please refresh before deleting a newly added comment');
                                setShowDeleteCommentErrorModal(true);
                            }
                        }}
                    >
                        <Icon type="trash" />
                    </Button>
                ) : (
                    <Button
                        theme={ButtonThemes.Link}
                        className="c-thread-comment__delete"
                        onClick={() => {
                            if (comment.postCommentId) {
                                setShowModerationModal(true);
                            } else {
                                setDeleteCommentError('Please refresh before deleting a newly added comment');
                                setShowDeleteCommentErrorModal(true);
                            }
                        }}
                    >
                        <Icon type="flag" />
                    </Button>
                )}

                <Avatar artifact={comment.profile?.artifact} />

                <div className="c-thread-comment__text">
                    <FeedFlaggedBanner item={comment} />
                    <Comment comment={comment} />

                    {postAsProfile && !disableReply && (
                        <Button className=""
                                theme={ButtonThemes.LinkSecondary}
                                onClick={() => {
                                    setShowCommentForm(!showCommentForm);
                                }}
                        >
                            Reply
                        </Button>
                    )}
                </div>
            </div>

            {!showOneLevel && comment.childComments && comment.childComments.map((comment) => (
                <ThreadComment
                    depth={depth + 1}
                    disableReply={disableReply || depth >= 3}
                    isReply={true}
                    thread={thread}
                    key={comment.postCommentId}
                    comment={comment}
                />
            ))}

            {showCommentForm && (
                <ThreadCommentForm
                    thread={thread}
                    focusOnRender={true}
                    parentPostCommentId={comment.postCommentId}
                    onComplete={() => setShowCommentForm(false)}
                    placeholder={`Reply to ${comment.profile.firstName}`}
                />
            )}

            <Modal
                confirmButtonOnClick={() => {
                    handleDeleteComment(commentToDelete);
                }}
                confirmButtonText="OK"
                declineButtonText="Cancel"
                declineButtonOnClick={() => {
                    setShowDeleteCommentModal(false);
                }}
                show={showDeleteCommentModal}
                title="Delete Comment?"
                showActivityIndicator={isDeletingThreadComment}
            >
                <p>
                    Are you sure you want to delete this comment?
                </p>

                <Comment comment={commentToDelete} />
            </Modal>

            <Modal
                confirmButtonText="OK"
                confirmButtonOnClick={() => {
                    setDeleteCommentError(null);
                    setShowDeleteCommentErrorModal(false);
                }}
                show={showDeleteCommentErrorModal}
                title="Error"
            >
                {deleteCommentError}
            </Modal>

            <ModerationOptionsModal
                close={() => {
                    setShowModerationModal(false);
                }}
                getAfterModerate={getAfterModerate}
                item={comment}
                show={showModerationModal}
            />
        </div>
    )
};

export default ThreadComment;
