import './CreateEditNewsStory.scss';

import React from 'react';

import { useTypedSelector } from "../../../../../store/reducers";

import CreateEditNewsStoryForm from "./CreateEditNewsStoryForm";

type Props = {
    close?: () => void
}

const CreateEditNewsStory: React.FC<Props> = ({
    close,
}) => {
    const { newsStory } = useTypedSelector((state) => state.news);

    return (
        <div className="news-story">
            <CreateEditNewsStoryForm
                close={close}
                newsStory={newsStory}
            />
        </div>
    );
};

export default CreateEditNewsStory;
