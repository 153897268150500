import './H6.scss';

import React from 'react';

type Props = {
    children: React.ReactNode
    className?: string
}

const H6: React.FC<Props> = ({
    children,
    className = ''
}) => (
    <h6 className={`c-h6 ${className}`}>
        {children}
    </h6>
);

export default H6;
