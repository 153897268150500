import './H4.scss';

import React from 'react';

type Props = {
    children: React.ReactNode
    className?: string
}

const H4: React.FC<Props> = ({
    children,
    className = ''
}) => (
    <h4 className={`c-h4 ${className}`}>
        {children}
    </h4>
);

export default H4;
