import React from 'react';

import { deleteDeal, resetRedemptionsForDeal, setDeal } from '../../../../../store/slices/deals';
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import Deal from "../../../../../types/Deal";
import TableMoreMenu from "../../../../../components/Table/TableMoreMenu";

type Props = {
    getAfterDelete?: Function
    item: Deal
    setShowNewContentModal: Function
}

const DealTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    item,
    setShowNewContentModal,
}) => {
    const dispatch = useAppDispatch();

    const { isDeletingDeal } = useTypedSelector((state) => state.deals);

    const handleDelete = async (deal) => {
        try {
            await dispatch(deleteDeal({deal})).unwrap();

            if(getAfterDelete) {
                getAfterDelete();
            }
        } catch(err) {
            console.log('DealTableMoreMenu handleDelete err', err);
        }
    };

    const handleEditDeal = (deal) => {
        dispatch(setDeal(deal));
        setShowNewContentModal(true);
    };


    const handleResetRedemptions = async () => {
        try {
            await dispatch(resetRedemptionsForDeal({postId: item.postId})).unwrap();
        } catch(err) {
            console.log('DealTableMoreMenu resetRedemptionsForDeal err', err);
        }
    };

    return (
        <TableMoreMenu
            hideModerationOptions
            isDeleting={isDeletingDeal}
            item={item}
            onDeleteClick={() => handleDelete(item)}
            onEditClick={() => handleEditDeal(item)}
            showAttachToPushNotificationOption={item.publishedAt != null || item.status === 'A'}
            customOptions={[
                {
                    text: 'Reset Redemptions',
                    onClick: handleResetRedemptions,
                    icon: 'refresh'
                }
            ]}
        />
    );
};

export default DealTableMoreMenu;
