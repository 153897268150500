import { createAsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../reducers';
import Tenant from '../../types/Tenant';

import { buildErrorObject } from '../../utils/errors';
import { generateUserActivityReportConfig } from '../../utils/generators';
import PATHS from '../../utils/paths';
import Request from '../../utils/request';

export const generateActivityReport = createAsyncThunk(
    'activityReportGenerator/generateActivityReport',
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            const { config } = (getState() as RootState).activityReportGenerator;
            const tenantIds = config.tenants?.map(tenant => tenant.tenantId);
            const emailAddresses = config.emailAddresses?.split(',')
                .map(email => email.trim().toLowerCase())
                .filter(email => email.length > 0);

            const formattedConfig = {
                ...config,
                tenantIds,
                emailAddresses: emailAddresses || [],
            };
            delete formattedConfig.tenants;

            if (config.sendDmReport) {
                await new Request((getState() as RootState).auth.token).post(PATHS.activityReportGenerator.generateDmReport(), formattedConfig);
            }

            if (config.sendActivityReport) {
                await new Request((getState() as RootState).auth.token).post(PATHS.activityReportGenerator.generateActivityReport(), formattedConfig);
            }

        } catch (err) {
            let friendlyMessage = 'Error requesting report generation from the server. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    },
);

export const generateTenantActivityReport = createAsyncThunk(
    'activityReportGenerator/generateActivityReport',
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            const { config } = (getState() as RootState).activityReportGenerator;
            const {activeSchool, } = (getState() as RootState).schools;

            if (!activeSchool) {
                return rejectWithValue(buildErrorObject({
                    friendlyMessage: 'Unable to request report. Please contact support.',
                }));
            }

            const emailAddresses = config.emailAddresses?.split(',')
                .map(email => email.trim().toLowerCase())
                .filter(email => email.length > 0);

            const formattedConfig = {
                ...config,
                tenantIds: [activeSchool.tenantId],
                emailAddresses: emailAddresses || [],
            };
            delete formattedConfig.tenants;

            if (config.sendDmReport) {
                await new Request((getState() as RootState).auth.token).post(PATHS.activityReportGenerator.generateTenantDmReport(activeSchool.tenantId), formattedConfig);
            }

            if (config.sendActivityReport) {
                await new Request((getState() as RootState).auth.token).post(PATHS.activityReportGenerator.generateTenantActivityReport(activeSchool.tenantId), formattedConfig);
            }
        } catch (err) {
            let friendlyMessage = 'Error requesting report generation from the server. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    },
);


export interface ActivityReportGeneratorState {
    config: {
        tenants: Array<Tenant>,
        emailAddresses?: string,
        includeMyself: boolean,
        sendDmReport: boolean,
        sendActivityReport: boolean,
        includeGlobalReport: boolean,
        startAt?: string,
        endAt?: string
    },
    isRequestingReport: boolean,
    requestReportError?: Object
}

export const initialState: ActivityReportGeneratorState = {
    config: generateUserActivityReportConfig(),
    isRequestingReport: false,
    requestReportError: undefined,
};

export const activityReportGeneratorSlice = createSlice({
    name: 'activityReportGenerator',
    initialState,
    reducers: {
        clearConfig: (state: Draft<ActivityReportGeneratorState>) => {
            state.config = generateUserActivityReportConfig();
        },
        setConfig: (state: Draft<ActivityReportGeneratorState>, action: PayloadAction<ActivityReportGeneratorState['config']>) => {
            state.config = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(generateActivityReport.pending, (state: Draft<ActivityReportGeneratorState>) => {
            state.isRequestingReport = true;
        });
        builder.addCase(generateActivityReport.fulfilled, (state: Draft<ActivityReportGeneratorState>) => {
            state.isRequestingReport = false;
        });
        builder.addCase(generateActivityReport.rejected, (state: Draft<ActivityReportGeneratorState>, action) => {
            state.isRequestingReport = false;
            state.requestReportError = action.error;
        });
    },
});

export const {
    clearConfig,
    setConfig,
} = activityReportGeneratorSlice.actions;

export default activityReportGeneratorSlice.reducer;
