import React, { useEffect, useState } from 'react';
import { validated } from "react-custom-validation";
import clone from 'clone';
import { useRouteMatch } from "react-router";

import NewsStory from "../../../../../types/NewsStory";
import Validation from "../../../../../types/Validation";

import { handleTextChange } from "../../../../../utils/handle-changes";
import { isRequired } from "../../../../../utils/validations";

import { clearNewsStory, deleteNewsStory, getNewsStories, saveNewsStory, setNewsStory } from "../../../../../store/slices/news";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import Button, { ButtonThemes, ButtonTypes } from "../../../../../components/Button";
import ButtonRow from "../../../../../components/ButtonRow";
import FileUpload from "../../../../../components/FileUpload";
import Form from "../../../../../components/Form";
import FormRow from "../../../../../components/FormRow";
import FormValidationMessage from "../../../../../components/FormValidationMessage";
import H4 from "../../../../../components/H4";
import Label from "../../../../../components/Label";
import Modal from "../../../../../components/Modal";
import PostingAsCard from "../../../../../components/PostingAsCard";
import ProfileTypesSelector from "../../../../../components/ProfileTypesSelector";
import SuccessModal from "../../../../../components/Modal/SuccessModal";
import Textbox from "../../../../../components/Textbox";
import VisibilityCard from "../../../../../components/VisibilityCard";
import { buildArtifactIdsArray } from '../../../../../utils/images';

type Props = {
    close?: () => void
    newsStory: NewsStory
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        artifactId: Validation
        linkUrl: Validation
        title: Validation
    }
}

const CreateEditNewsStoryForm: React.FC<Props> = ({
    close,
    newsStory,
    $field,
    $fieldEvent,
    $submit,
    $validation,
}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [isUploadingArtifact, setIsUploadingArtifact] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

    const { profile: authUser } = useTypedSelector((state) => state.auth);
    const { activeSchool } = useTypedSelector(store => store.schools);
    const { isDeletingNewsStory, isSavingNewsStory } = useTypedSelector((state) => state.news);

    useEffect(() => {
        if(!newsStory.artifactIds || newsStory.artifactIds.length === 0) {
            let artifactIds = buildArtifactIdsArray(newsStory);
            handleChange('artifactIds', artifactIds);
        }
        if(!newsStory.postId) {
            handleChange(`${params.profileType}Visibility`, true);
        }

        return () => {
            setTimeout(() => dispatch(clearNewsStory()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name: string, value: any) => {
        let clonedNewsStory = clone(newsStory);
        clonedNewsStory[name] = value;
        dispatch(setNewsStory(clonedNewsStory));
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteNewsStory({})).unwrap();
            $fieldEvent('reset');
            dispatch(getNewsStories({}));
            close && close();
        } catch (err) {
            console.log('CreateEditNewsStoryForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleSave = async (isValid: boolean) => {
        if(isValid) {
            try {
                await dispatch(saveNewsStory({})).unwrap();
                setShowSuccessModal(true);
                $fieldEvent('reset');
            } catch(err) {
                console.log('CreateEditNewsStoryForm handleSave err', err);
            }
        }
    };

    const onUploadStart = () => {
        setIsUploadingArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange('artifactIds', [artifactId]);
        }
        setIsUploadingArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingArtifact(false);
    };

    const onDelete = () => {
        handleChange('artifactId', null);
    };

    return (
        <Form className="content-form">
            <H4>
                {newsStory.postId ? 'Edit ' : 'Create '}News Story
            </H4>

            <FormRow>
                <PostingAsCard
                    profile={newsStory.profile || activeSchool.postAsProfile}
                    user={!newsStory.profile || newsStory.profile === activeSchool.postAsProfile ? authUser : null}
                />
            </FormRow>

            <FormRow>
                <Label>
                    News Story Image <span className="c-label__required-asterisk">*</span>
                </Label>

                <FileUpload
                    disabled={isUploadingArtifact}
                    onDelete={onDelete}
                    currentArtifact={newsStory.artifacts && newsStory.artifacts.length > 0 && newsStory.artifacts[0]}
                    name="artifactId"
                    onStart={onUploadStart}
                    onSuccess={onUploadSuccess}
                    onFailure={onUploadFailure}
                />

                {$validation.artifactId.isValid === false && $validation.artifactId.show === true ? (
                    <FormValidationMessage>
                        {$validation.artifactId.error.reason}
                    </FormValidationMessage>
                ) : null}
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingArtifact}
                    id="txtTitle"
                    label="Title"
                    name="title"
                    required
                    type="text"
                    validation={$validation.title}
                    value={newsStory.title || ''}
                    {...$field('title', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingArtifact}
                    id="txtDescription"
                    label="Description"
                    name="description"
                    onChange={(event) => handleTextChange(handleChange, event)}
                    type="textarea"
                    value={newsStory.description || ''}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    disabled={isUploadingArtifact}
                    id="txtLinkUrl"
                    label="News Story URL"
                    name="linkUrl"
                    required
                    type="text"
                    validation={$validation.linkUrl}
                    value={newsStory.linkUrl || ''}
                    {...$field('title', (event) => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <VisibilityCard
                handleChange={handleChange}
                post={newsStory}
            />

            <FormRow>
                <ProfileTypesSelector item={newsStory} onChange={handleChange} />
            </FormRow>

            <ButtonRow>
                <Button
                    disabled={isUploadingArtifact}
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                    showActivityIndicator={isSavingNewsStory}
                    type={ButtonTypes.Submit}
                >
                    {newsStory.postId ? 'Save' : 'Create'} News Story
                </Button>

                {newsStory.postId && (
                    <Button
                        className="destructive"
                        disabled={isSavingNewsStory}
                        onClick={() => setShowDeleteConfirmationModal(true)}
                        theme={ButtonThemes.Link}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getNewsStories({}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
                title="Nice job!"
            >
                <p>
                    Your news story has been saved.
                </p>
                <p>
                    It may take a minute for your news story to show in feeds and lists.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingNewsStory}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this news story?
            </Modal>
        </Form>
    );
};

function createEditNewsStoryFormValidationConfig(props) {
    let { artifactIds, linkUrl, title }: NewsStory = props.newsStory;

    return {
        fields: ['artifactId', 'linkUrl', 'title'],
        validations: {
            artifactId: [
                [isRequired, artifactIds]
            ],
            linkUrl: [
                [isRequired, linkUrl]
            ],
            title: [
                [isRequired, title]
            ]
        }
    }
}
export default validated(createEditNewsStoryFormValidationConfig)(CreateEditNewsStoryForm);
