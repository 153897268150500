import React, { useEffect, useRef, useState } from 'react';

import EventPost from "../../types/EventPost";
import Thread from "../../types/Thread";

import { handleTextChange } from '../../utils/handle-changes';

import { useAppDispatch } from "../../store";
import { useTypedSelector } from "../../store/reducers";
import { saveThreadComment } from "../../store/slices/threads";

import Avatar from "../Avatar";
import Button, { ButtonSizes, ButtonThemes, ButtonTypes } from '../Button';
import Form from "../Form";
import FormRow from '../FormRow';
import Textbox from '../Textbox';

type Props = {
    thread: Thread | EventPost,
    placeholder: string,
    parentPostCommentId: string,
    focusOnRender?: boolean,
    onComplete?: () => void,
}

const ThreadCommentForm: React.FC<Props> = ({
    thread,
    placeholder,
    parentPostCommentId,
    focusOnRender,
    onComplete,
}) => {
    const dispatch = useAppDispatch();
    const { postAsProfile } = useTypedSelector(store => store.schools.activeSchool);

    const [newComment, setNewComment] = useState('');
    const [isSavingComment, setIsSavingComment] = useState(false);

    const handleCommentChange = (name, value) => {
        setNewComment(value);
    };

    const textboxRef = useRef<any>(null);

    useEffect(() => {
        if (focusOnRender && textboxRef?.current) {
            textboxRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSaveComment = async(newComment) => {
        try {
            setIsSavingComment(true);

            await dispatch(saveThreadComment({comment: newComment, parentPostCommentId, threadId: thread.postId}));

            if (onComplete) {
                onComplete();
            }

            setNewComment('');
        } catch(err) {
            console.log('ThreadCommentForm handleSaveComment err', err);
        } finally {
            setIsSavingComment(false);
        }
    };

    return (
        <Form className="c-thread-comment__form">

            <Avatar
                className="c-thread-comment__form__avatar"
                artifact={postAsProfile?.artifact}
            />

            <FormRow>
                <Textbox
                    id="txtNewComment"
                    className="c-thread-comment__form__input"
                    name="newComment"
                    placeholder={placeholder ? placeholder : 'Add a comment...'}
                    type="text"
                    size="small"
                    thisRef={textboxRef}
                    value={newComment || ''}
                    onChange={(event) => {
                        handleTextChange(handleCommentChange, event)
                    }}

                />
                <Button
                    className="detail-view--comment--form--button"
                    disabled={!newComment}
                    onClick={(event) => {
                        event.preventDefault();
                        handleSaveComment(newComment);
                    }}
                    showActivityIndicator={isSavingComment}
                    size={ButtonSizes.Small}
                    theme={ButtonThemes.Small}
                    type={ButtonTypes.Submit}
                >
                    Add
                </Button>
            </FormRow>


        </Form>
    )
};

export default ThreadCommentForm;
