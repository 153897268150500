import React, {useEffect, useState} from 'react';

import {getTenantDashboardData} from "../../../../store/slices/tenantDashboard";
import {useAppDispatch} from "../../../../store";
import {useTypedSelector} from "../../../../store/reducers";
import {useRouteMatch} from "react-router";

import {CardType} from "../../Dashboard";
import DashboardCharts from "../../Dashboard/components/DashboardCards/DashboardCharts";
import DashboardStat from "../../Dashboard/components/DashboardRow/DashboardStat";
import DashboardSlimChartCard from "../../Dashboard/components/DashboardCards/DashboardCharts/DashboardSlimChartCard";

const Dashboard = () => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const [initialized, setInitialized] = useState(false);
    const { activeSchool } = useTypedSelector(store => store.schools);
    const {
        dau,
        headcount,
        isGettingTenantDashboard,
        mau,
        mostActiveGroupsData,
        mostActiveUsersData,
        percentUsersWithPushesEnabled,
        profileActivity,
        pushNotificationsData,
        recentlyJoinedUsersData,
        slimDashData,
        topDealsData,
        topEventsData,
        topItemsData,
        topThreadsData,
        topDmUsersData,
        liveNowData,
    } = useTypedSelector(store => store.tenantDashboard);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getTenantDashboardData({tenantId: match?.params?.schoolId || activeSchool.tenantId}))
            }
            catch (err) {
                console.log("Error initializing tenant dashboard: ", err)
            }
            finally {
                setInitialized(true);
            }
        }
        initialize()
    }, [activeSchool.tenantId, dispatch, match?.params?.schoolId]);

    return (
        <div className='dashboard-cards__container'>
            <h1>
                {activeSchool.name} Dashboard
            </h1>

            <div className='dashboard-cards__container'>
                <DashboardSlimChartCard
                    isGettingDashboard={isGettingTenantDashboard}
                    initialized={initialized}
                    slimDashData={slimDashData}
                />

                <DashboardCharts
                    dau={dau}
                    mau={mau}
                    headcount={headcount}
                    profileActivity={profileActivity}
                    isGettingDashboard={isGettingTenantDashboard}
                    percentUsersWithPushesEnabled={percentUsersWithPushesEnabled}
                    initialized={initialized}
                />

                <DashboardStat
                    cardData={liveNowData}
                    cardType={CardType.LiveNow}
                    initialized={initialized}
                    isGettingDashboard={isGettingTenantDashboard}
                    key={CardType.LiveNow}
                />

                <DashboardStat
                    cardData={topDmUsersData}
                    cardType={CardType.TopDmUsers}
                    initialized={initialized}
                    isGettingDashboard={isGettingTenantDashboard}
                    key={CardType.TopDmUsers}
                />

                <DashboardStat
                    cardData={pushNotificationsData}
                    cardType={CardType.PushNotifications}
                    initialized={initialized}
                    isGettingDashboard={isGettingTenantDashboard}
                    key={CardType.PushNotifications}
                />

                <DashboardStat
                    cardData={recentlyJoinedUsersData}
                    cardType={CardType.RecentlyJoinedUsers}
                    initialized={initialized}
                    isGettingDashboard={isGettingTenantDashboard}
                    key={CardType.RecentlyJoinedUsers}
                />

                <DashboardStat
                    cardData={mostActiveUsersData}
                    cardType={CardType.MostActiveUsers}
                    initialized={initialized}
                    isGettingDashboard={isGettingTenantDashboard}
                    key={CardType.MostActiveUsers}
                />

                <DashboardStat
                    cardData={mostActiveGroupsData}
                    cardType={CardType.MostActiveGroups}
                    initialized={initialized}
                    isGettingDashboard={isGettingTenantDashboard}
                    key={CardType.MostActiveGroups}
                />

                <DashboardStat
                    cardData={topDealsData}
                    cardType={CardType.TopDeals}
                    initialized={initialized}
                    isGettingDashboard={isGettingTenantDashboard}
                    key={CardType.TopDeals}
                />

                <DashboardStat
                    cardData={topEventsData}
                    cardType={CardType.TopEvents}
                    initialized={initialized}
                    isGettingDashboard={isGettingTenantDashboard}
                    key={CardType.TopEvents}
                />

                <DashboardStat
                    cardData={topThreadsData}
                    cardType={CardType.TopThreads}
                    initialized={initialized}
                    isGettingDashboard={isGettingTenantDashboard}
                    key={CardType.TopThreads}
                />

                <DashboardStat
                    cardData={topItemsData}
                    cardType={CardType.TopItems}
                    initialized={initialized}
                    isGettingDashboard={isGettingTenantDashboard}
                    key={CardType.TopItems}
                />

            </div>
        </div>
    )
};
export default Dashboard;
