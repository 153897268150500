import './NewContentModal.scss';

import React from 'react';
import ReactModal from 'react-modal';

import { MODAL_STYLE_RESET } from '../../utils/constants';

import Button, { ButtonThemes } from "../Button";
import Icon from "../Icon";

ReactModal.setAppElement('#root');

type Props = {
    children: React.ReactNode
    className?: string
    contained?: boolean
    close: Function
    contentLabel?: string
    show: boolean,
    width?: 'skinny' | 'wide'
}

const NewContentModal: React.FC<Props> = ({
    children,
    className = '',
    close,
    contained = false,
    contentLabel,
    show,
    width = 'skinny'
}) => (
    <ReactModal
        className={`a-new-content-modal--${width}`}
        closeTimeoutMS={200}
        contentLabel={contentLabel || 'New Content Modal'}
        isOpen={show === true}
        onRequestClose={close}
        overlayClassName={width ? `a-new-content-modal__overlay--${width}` : 'a-new-content-modal__overlay'}
        portalClassName={`c-new-content-modal ${className}`}
        style={MODAL_STYLE_RESET}
    >
        <Button
            className="c-new-content-modal__close-button"
            onClick={close}
            theme={ButtonThemes.Light}
        >
            <Icon type="x" />
        </Button>
        <div className="c-new-content-modal__children">
            <div className={`c-new-content-modal__children-inner ${contained && 'c-new-content-modal__children-inner--contained'}`}>
                {children}
            </div>
        </div>
    </ReactModal>
);

export default NewContentModal;
