import ServerError from "../types/ServerError";

import { isObjectNullOrEmpty } from "./utils";

type BuildErrorObjectProps = {
    serverError?: ServerError,
    friendlyMessage?: string,
}

export const buildErrorObject = ({serverError, friendlyMessage}: BuildErrorObjectProps) => {

    const defaultFriendlyMessage = "There was a problem. Please try again."

    if (isObjectNullOrEmpty(serverError)) {
        return {
            friendlyMessage: friendlyMessage || defaultFriendlyMessage
        }
    }

    serverError.friendlyMessage = friendlyMessage || (serverError.error || defaultFriendlyMessage);

    if(serverError.breadcrumb) {
        serverError.friendlyMessage += ` If this problem persists, please contact customer support with this code: ${serverError.breadcrumb}`
    }

    return serverError;
};
