import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import Deal from "../../types/Deal";
import EventPost from "../../types/EventPost";
import FeedMetadata from "../../types/FeedMetadata";
import IError from "../../types/IError";
import MarketplaceItem from "../../types/MarketplaceItem";
import Metadata from "../../types/Metadata";
import Thread from "../../types/Thread";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import { generateFeedMetadata } from "../../utils/generators";

import { addError } from "./errors";
import { RootState } from '../reducers';
import { deleteDeal, saveDeal } from "./deals";
import { deleteEvent, saveEvent } from "./events";
import { deleteMarketplaceItem, saveMarketplaceItem } from "./marketplaceItems";
import { deleteThread, saveThread } from "./threads";
import { moderateContent } from "./moderation";

import {
    cloneFeedWithDeletedPost,
    cloneFeedWithUpdatedPost,
    clonePostWithDeletedComment,
    clonePostWithNewComment
} from "../../utils/reducerHelpers";

import ActivityAlert from '../../types/ActivityAlert';

type GetFeedProps = {
    isUpdate?: boolean
    schoolId?: number
    feedMetadata?: Metadata
}

export const getFeed = createAsyncThunk(
    'feed/getFeed',
    async ({isUpdate, schoolId, feedMetadata}: GetFeedProps, {dispatch, getState}) => {
        try {
            if(!feedMetadata) {
                feedMetadata = clone((getState() as RootState).feed.feedMetadata);
            } else {
                feedMetadata = {...feedMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            if(isUpdate) {
                feedMetadata.page_num = feedMetadata.page_num + 1;
            } else {
                feedMetadata.page_num = 0;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.content.getContent(schoolId, buildQueryString(feedMetadata)));
            let feedItems = res.data.data.items;
            if(isUpdate) {
                feedItems = (getState() as RootState).feed.feedItems.concat(feedItems);
            }

            return {feedItems, feedMetadata, isAtEnd: res.data.data.items.length === 0};
        } catch(err) {
            console.log('getFeed', err);
            err.friendlyMessage = 'Error getting your feed. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

interface FeedState {
    activityAlerts: Array<ActivityAlert>
    activityAlertsMetadata: Metadata
    feedItems: Array<Deal | EventPost | MarketplaceItem | Thread>
    feedMetadata: FeedMetadata
    getFeedError?: IError
    isGettingFeed: boolean
    isGettingActivityAlerts: boolean
    isGettingStudentEngagement: boolean
    readyToMarkActivityAlertsAsRead: boolean
}

const initialState: FeedState = {
    activityAlerts: [],
    activityAlertsMetadata: {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'publish',
        search: ''
    },
    feedItems: [],
    feedMetadata: generateFeedMetadata(),
    isGettingFeed: false,
    readyToMarkActivityAlertsAsRead: false,
    isGettingActivityAlerts: false,
    isGettingStudentEngagement: false,
    getFeedError: undefined,
};

export const feedSlice = createSlice({
    name: 'feed',
    initialState,
    reducers: {
        addCommentToFeed: (state, action) => {
            let clonedFeed = clone(state.feedItems);
            if (clonedFeed.length === 0) {
                return;
            }
            console.log('clonedFeed', clonedFeed);
            let matchedIndex = clonedFeed.findIndex(i => i.postId === action.payload.threadId);
            if(matchedIndex != null) {
                const newPost = clonePostWithNewComment(clonedFeed[matchedIndex], action.payload.parentPostCommentId, action.payload);
                clonedFeed[matchedIndex] = newPost;
            }
            state.feedItems = clonedFeed;
        },
        deleteCommentInFeed: (state, action) => {
            let clonedFeed = clone(state.feedItems);
            if (clonedFeed.length === 0) {
                return;
            }
            let matchedIndex = clonedFeed.findIndex(i => i.postId === action.payload.threadId);
            const newPost = clonePostWithDeletedComment(clonedFeed[matchedIndex], action.payload.postCommentId);
            clonedFeed[matchedIndex] = newPost;
            state.feedItems = clonedFeed;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(getFeed.pending, (state, action) => {
            state.getFeedError = undefined;
            state.isGettingFeed = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.feedMetadata) {
                state.feedMetadata = action.meta.arg.feedMetadata;
            }
        });
        addCase(getFeed.fulfilled, (state, action) => {
            state.feedItems = action.payload.feedItems;
            state.feedMetadata = action.payload.feedMetadata;
            state.isGettingFeed = false;
        });
        addCase(getFeed.rejected, (state, action) => {
            state.getFeedError = action.error;
            state.isGettingFeed = false;
        });

        addCase(deleteDeal.fulfilled, (state, action) => {
            if(action.payload?.postId) {
                state.feedItems = cloneFeedWithDeletedPost(state.feedItems, action.payload.postId);
            }
        });
        addCase(saveDeal.fulfilled, (state, action) => {
            if(action.payload?.data?.data) {
                state.feedItems = cloneFeedWithUpdatedPost(state.feedItems, action.payload.data.data);
            }
        });

        addCase(deleteEvent.fulfilled, (state, action) => {
            if(action.payload?.postId) {
                state.feedItems = cloneFeedWithDeletedPost(state.feedItems, action.payload.postId);
            }
        });
        addCase(saveEvent.fulfilled, (state, action) => {
            if(action.payload?.data?.data) {
                state.feedItems = cloneFeedWithUpdatedPost(state.feedItems, action.payload.data.data);
            }
        });

        addCase(deleteMarketplaceItem.fulfilled, (state, action) => {
            if(action.payload?.postId) {
                state.feedItems = cloneFeedWithDeletedPost(state.feedItems, action.payload.postId);
            }
        });
        addCase(saveMarketplaceItem.fulfilled, (state, action) => {
            if(action.payload?.data?.data) {
                state.feedItems = cloneFeedWithUpdatedPost(state.feedItems, action.payload.data.data);
            }
        });

        addCase(deleteThread.fulfilled, (state, action) => {
            if(action.payload?.postId) {
                state.feedItems = cloneFeedWithDeletedPost(state.feedItems, action.payload.postId);
            }
        });
        addCase(saveThread.fulfilled, (state, action) => {
            if(action.payload?.data?.data) {
                state.feedItems = cloneFeedWithUpdatedPost(state.feedItems, action.payload.data.data);
            }
        });

        addCase(moderateContent.fulfilled, (state, action) => {
            state.feedItems = cloneFeedWithUpdatedPost(state.feedItems, action.payload.post);
        });
    }
});

export const { addCommentToFeed, deleteCommentInFeed } = feedSlice.actions;

export default feedSlice.reducer;
