import './ButtonRow.scss';

import React from 'react';
import classNames from 'classnames';

type Props = {
    alignment?: 'center' | 'justified' | 'left' | 'right'
    children: React.ReactNode
    className?: string
    style?: React.StyleHTMLAttributes<any>
}

const ButtonRow: React.FC<Props> = ({
        children,
        className,
        alignment,
        style
}) => {
    let classes = classNames(
        'c-button-row',
        alignment != null && `c-button-row--${alignment}`,
        className,
    );

    return (
        <div className={classes} style={style || null}>
            {children}
        </div>
    );
}

export default ButtonRow;
