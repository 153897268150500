import './CreateEditFAQ.scss';

import React from 'react';

import { useTypedSelector } from "../../../../../store/reducers";

import CreateEditFAQForm from "./CreateEditFAQForm";

type Props = {
    close?: () => void
}

const CreateEditFAQ: React.FC<Props> = ({
    close,
}) => {
    const { question } = useTypedSelector((state) => state.faq);

    return (
        <div className="create-edit-faq">
            <CreateEditFAQForm
                close={close}
                question={question}
            />
        </div>
    );
};

export default CreateEditFAQ;
