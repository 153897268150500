import { createSlice } from '@reduxjs/toolkit';
import clone from "clone";

interface ErrorsState {
    errors: Array<object>
}

export const initialState: ErrorsState = {
    errors: []
};

export const errorsSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        addError: (state, action) => {
            let errors = clone(state.errors);
            const error = action.payload;

            // If it's a 401 error, the request interceptor will handle it
            // by redirecting to the expired session page.
            if (error.response?.status === 401) {
                return;
            }

            for(var i = 0; i < errors.length; i++) {
                if(errors[i].friendlyMessage === error.friendlyMessage) {
                    return;
                }
            }

            errors = errors.concat([error]);
            if(errors.length > 5) {
                errors = errors.slice(1, 5);
            }

            state.errors = errors;
        },
        clearErrors: (state) => {
            state.errors = [];
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
    }
});

export const { addError, clearErrors, setErrors } = errorsSlice.actions;

export default errorsSlice.reducer;
