import React from 'react';
import { Route, Switch } from "react-router";

import SchoolsList from "./SchoolsList";
import Sponsorships from "./Sponsorships";
import SuperAdminDashboard from "./Dashboard";

const Schools = () => {
    return (
        <div className="schools-wrapper">
            <Switch>
                <Route path="/schools/all/dashboard" component={SuperAdminDashboard} />
                <Route path="/schools/all/sponsorships" component={Sponsorships} />
                <Route path="/schools/list" component={SchoolsList} />
            </Switch>
        </div>
    );
};

export default Schools;
