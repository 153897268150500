import React from 'react';
import ProfilePromotionTable from './ProfilePromotionTable';

const Promotion: React.FC = () => {

    return (
        <ProfilePromotionTable />
    );
}

export default Promotion;
