import './FileUpload.scss';

import React, { useEffect, useState } from 'react';
import browserImageSize from "browser-image-size";
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import heic2any from "heic2any";

import Artifact from "../../types/Artifact";
import IFile from "../../types/IFile";

import buildImageSrc from "../../utils/buildImageSrc";
import { resizeImage } from "../../utils/images";

import { addError } from "../../store/slices/errors";
import { uploadFiles } from "../../store/slices/upload";
import { useAppDispatch } from "../../store";
import { useTypedSelector } from "../../store/reducers";

import ActivityIndicator from '../ActivityIndicator';
import Button, { ButtonSizes, ButtonThemes } from '../Button';
import ErrorInline from "../../containers/Errors/ErrorInline";
import FileUploadErrorModal from '../Modal/FileUploadErrorModal';
import Icon from '../Icon';
import ProgressBar from "../ProgressBar";
import {getCSVLineCount} from "../../utils/csv";

type Props = {
    acceptsVideo?: boolean,
    buttonText?: string
    currentArtifact?: Artifact,
    disabled?: boolean
    hideProgressBar?: boolean
    name?: string
    onDelete?: Function
    onStart?: Function
    onSuccess?: Function
    onFailure?: Function
    onDrop?: Function
    retainMimeType?: boolean
    progress?: number
    secondaryText?: string
    theme?: 'user'
    thisRef?: React.Ref<any>
    types?: string
    size?: 'small' | 'large',
    validator?: Function,
    uploadType?: string,
}

const FileUpload: React.FC<Props> = ({
    acceptsVideo,
    buttonText,
    currentArtifact,
    disabled,
    hideProgressBar,
    name,
    onDelete,
    onStart,
    onSuccess,
    onFailure,
    onDrop,
    progress,
    retainMimeType,
    secondaryText,
    theme,
    thisRef,
    types,
    size,
    validator,
    uploadType,
}) => {
    const dispatch = useAppDispatch();

    const [activeDrag, setActiveDrag] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [convertingHEIC, setConvertingHEIC] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [preview, setPreview] = useState<Blob | string | null>(null);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [dropped, setDropped] = useState<boolean>(false);
    const [isVideo, setIsVideo] = useState<boolean>(false);
    const [previewSrc, setPreviewSrc] = useState<string>(null);
    const [fileIsUploaded, setFileIsUploaded] = useState(false);

    const { uploadError, uploadPercentage } = useTypedSelector((state) => state.upload);

    useEffect(() => {

        if (isDeleted) {
            setFileIsUploaded(false);
            setPreviewSrc(null);
            return;
        }

        if (preview && typeof preview === 'string') {
            setPreviewSrc(preview);
        } else {
            if (currentArtifact && !preview) {
                if (currentArtifact.type === 'video') {
                    setPreviewSrc(currentArtifact.videoMp4Url);
                    if (!dropped) {
                        setIsVideo(true);
                    }
                } else {
                    if (currentArtifact.url) {
                        let url = currentArtifact.url;
                        if(url.indexOf('//') !== 0 && url.indexOf('https://') !== 0) {
                            url = '//' + url;
                        }
                        setPreviewSrc(url);
                    }

                    // Only rebuild the preview if we have an artifactId, this breaks the preview for artifact stagings otherwise
                    if (currentArtifact.artifactId) {
                        setPreviewSrc(buildImageSrc(currentArtifact, '667'))
                    }

                    setIsVideo(false)
                }
            } else {
                // setImageUrl(null);
                setIsVideo(false);
                setPreview(null)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentArtifact, preview, isDeleted]);


    const handleDrop = async (goodFiles, badFiles) => {

        setActiveDrag(false);

        if(badFiles && badFiles.length > 0) {
            setShowErrorModal(true);
        } else {
            let file = goodFiles[0];

            if (validator) {
                const isValid = await validator(file);
                if (!isValid) {
                    onFailure();
                    return;
                }
            }

            if (onDrop) {
                onDrop(file);
            } else {
                setDropped(true);
                onStart(file);
                if (file.type.toLowerCase() === 'video/mp4') {
                    try {
                        setIsUploading(true);
                        let uploadResult: any = await dispatch(uploadFiles({file, height: null, width: null, type: uploadType || 'artifact'})).unwrap();
                        setIsUploading(false);
                        file.preview = URL.createObjectURL(file);
                        setIsDeleted(false)
                        setPreview(file.preview);
                        if (file.type.includes('video/')) {
                            setIsVideo(true);
                        } else {
                            setIsVideo(false);
                        }
                        onSuccess(file, uploadResult?.artifactId);
                    } catch(err) {
                        setIsUploading(false);
                        setPreview(null);
                        dispatch(addError(err));
                        onFailure();
                    }
                } else {
                    let convertedImage;
                    setIsVideo(false);
                    if(file.type.toLowerCase() === 'image/heic') {
                        setConvertingHEIC(true);

                        try {
                            convertedImage = await heic2any({blob: file, toType: 'image/jpg' });
                        } catch(err) {
                            console.log(err);
                        }
                        file = await resizeImage(convertedImage, 1600) as IFile;
                        setConvertingHEIC(false);

                    } else {
                        if (file.type.includes('image/')) {
                            file = await resizeImage(file, 1600, retainMimeType ? file.type : 'image/jpeg') as IFile;
                        }
                    }

                    // Try getting image size first. This will catch errors before uploading.
                    // This process can still be improved
                    let imageSize;
                    try {
                        imageSize = await browserImageSize(file);
                    } catch(err) {
                        err.response = {
                            status: 422 // Error needs a response status to be displayed to user
                        }
                        err.friendlyMessage = 'Error prepping file for upload. This is likely due to an unsupported file format.';
                        setPreview(null);
                        dispatch(addError(err))
                        onFailure();
                    }

                    // If we have image size, proceed with attempting to upload to the server
                    if (imageSize) {
                        try {
                            setIsUploading(true);
                            let { height, width } = imageSize;
                            let uploadResult: any = await dispatch(uploadFiles({file, height, width, type: uploadType || 'artifact'})).unwrap();
                            setIsUploading(false);

                            file.preview = URL.createObjectURL(file);
                            setIsDeleted(false);
                            setPreview(file.preview);
                            onSuccess(file, uploadResult?.artifactId);
                        } catch(err) {
                            setIsUploading(false);
                            setPreview(null);
                            dispatch(addError(err))
                            onFailure();
                        }
                    }
                }
            }
        }
    };

    let classes = classNames(
        'c-file-upload',
        {
            'a-file-upload--active': activeDrag,
            'a-file-upload--has-preview': (preview || previewSrc)  && !isUploading && !convertingHEIC,
            'a-file-upload--is-uploading': isUploading,
            'a-file-upload--disabled': disabled,
        }
    );

    if(theme) {
        classes += ` a-file-upload--${theme}`;
    }

    if (!acceptsVideo) {
        // strip video/mp4
        types = types.replace(/video\/mp4/g, '');
        // strip trailing comma
        types = types.replace(/,$/, '');
    }

    return (
        <div className={classes}>
            <div className="c-file-upload--inner">
                <Dropzone accept={types}
                          disabled={disabled}
                          multiple={false}
                          onDragEnter={() => setActiveDrag(true)}
                          onDragLeave={() => setActiveDrag(false)}
                          onDrop={handleDrop}
                          ref={thisRef}>
                    {({getRootProps, getInputProps}) => (
                        <div className="c-file-upload__content" {...getRootProps()}>
                            <input {...getInputProps()} />

                            {isUploading ? (
                                <div className="c-file-upload__uploading">
                                    <div className="c-file-upload__uploading-inner">
                                        <ActivityIndicator />  Uploading
                                    </div>

                                    {!hideProgressBar && (
                                        <ProgressBar
                                            progress={progress || uploadPercentage}
                                        />
                                    )}
                                </div>
                            ) : (
                                <>
                                    {convertingHEIC ? (
                                        <div className="c-file-upload__converting-heic">
                                            <ActivityIndicator />{size !== 'small' && (<>&nbsp;Processing HEIC Image</>)}
                                        </div>
                                    ) : (
                                        <>
                                            {previewSrc ? (
                                                <div className="c-file-upload__preview-wrapper">
                                                    {isVideo ? (
                                                        <video src={previewSrc} className="c-file-upload__preview" controls={true} />
                                                        ) : (
                                                        <img src={previewSrc} alt="" className="c-file-upload__preview" />
                                                    )}
                                                </div>
                                            ) : (
                                                <>
                                                    {theme === 'user' && (
                                                        <div className="c-file-upload__avatar-icon">
                                                            <Icon type="avatar" />
                                                        </div>
                                                    )}
                                                    <Button
                                                        className="c-file-upload__btn"
                                                        size={theme ? ButtonSizes.Small : null}
                                                        theme={ButtonThemes.Secondary}
                                                    >
                                                        {buttonText || `Add Image${acceptsVideo ? ' or Video' : ''}`}
                                                    </Button>

                                                    {theme !== 'user' && (
                                                        <div className="c-file-upload__text">
                                                            {secondaryText || `or drop an image${acceptsVideo ? ' or video' : ''} to upload`}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </Dropzone>

                <ErrorInline error={uploadError} />

                {(preview || previewSrc) && !isUploading && onDelete ? (
                    <Button
                        className="c-file-upload__delete"
                        onClick={() => {
                            setPreview(null);
                            setPreviewSrc(null);
                            setIsDeleted(true);
                            setIsVideo(false);
                            onDelete();
                        }}
                    >
                        <Icon type="x" />
                    </Button>
                ) : null}
            </div>

            <FileUploadErrorModal
                close={() => setShowErrorModal(false)}
                show={showErrorModal}
            />
        </div>
    );
};

FileUpload.defaultProps = {
    types: "image/jpeg,image/png,image/gif,image/webp,image/heic,image/heif,video/mp4"
};

export default FileUpload;
