import clone from 'clone';
import Metadata from '../../types/Metadata';
import Profile from '../../types/Profile';
import PATHS, { buildQueryString } from '../../utils/paths';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../reducers';
import Request from "../../utils/request";
import { addError } from './errors';
import { buildErrorObject } from '../../utils/errors';
import IError from '../../types/IError';
import { generateAlumniMetadata } from '../../utils/generators';

type DeleteAlumniProps = {
  profileId: number
  schoolId?: number
}

export const deleteAlumni = createAsyncThunk(
  'alumni/deleteAlumni',
  async ({profileId, schoolId}: DeleteAlumniProps, {dispatch, getState}) => {
    try {
      if(!schoolId) {
        schoolId = (getState() as RootState).schools.activeSchool.tenantId;
      }
      await new Request(
        (getState() as RootState).auth.token,
      ).delete(PATHS.students.delete(schoolId, profileId));
      return profileId;
    } catch (err) {
      console.log('deleteAlumni error', err);
      err.friendlyMessage = 'Error deleting alumni. Please try again.';
      dispatch(addError(err));
      throw err;
    }
  },
);

type GetAlumniProps = {
  alumniMetadata?: Metadata;
  isUpdate?: boolean
  schoolId?: number | string
}

export const getAlumni = createAsyncThunk(
  'alumni/getAlumni',
  async ({alumniMetadata, isUpdate, schoolId}: GetAlumniProps = {}, {dispatch, getState}) => {
    try {
      if(!alumniMetadata) {
        alumniMetadata = clone((getState() as RootState).admits.admitsMetadata);
      } else {
        alumniMetadata = {...alumniMetadata}
      }
      alumniMetadata.alumni_only = true;

      const result = await new Request(
        (getState() as RootState).auth.token,
      ).get(PATHS.students.getStudents(schoolId, buildQueryString(alumniMetadata)));

      console.log("Result: ", result)

      let alumni = result.data.data.items;

      alumniMetadata.total = result.data.data.meta.total;

      return { alumni, alumniMetadata };

    } catch (err) {
      console.log('getAdmits error', err);
      err.friendlyMessage = 'Error getting a list of admits. Please try again.';
      dispatch(addError(err));
      throw err;
    }
  },
);

type InviteAlumniViaCsvImportProps = {
  csvFile: File
  schoolId?: number | string
}

export const inviteAlumniViaCsvImport = createAsyncThunk(
    'alumni/inviteAlumniViaCsvImport',
    async ({csvFile, schoolId}: InviteAlumniViaCsvImportProps, {dispatch, getState, rejectWithValue}) => {
      try {
        if(!schoolId) {
          schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        let formData = new FormData();
        formData.append('csv', csvFile);

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }

        const res = await new Request((getState() as RootState).auth.token).post(PATHS.alumni.inviteViaCsvImport(schoolId), formData, config);
        return res;
      } catch(err) {
        console.log('inviteAlumniViaCsvImport error', err);
        let friendlyMessage = 'Error importing users via CSV. Please try again.';
        if (err.response?.data?.error) {
          friendlyMessage = err.response.data.error;
        }
        let errorObject = buildErrorObject({
          serverError: err.response?.data,
          friendlyMessage,
        });
        return rejectWithValue(errorObject);
      }
    }
);

interface AlumniState {
  alumniMetadata?: Metadata;
  alumni?: Array<Profile>;
  deleteAlumniError?: IError;
  isDeletingAlumni: boolean;
  isGettingAlumni: boolean;
  isInvitingAlumniViaCsvImport: boolean;
  getAlumniError?: IError;
  inviteAlumniViaCsvImportError?: IError;
  searchTerm: string;
}

const initialState: AlumniState = {
  alumniMetadata: generateAlumniMetadata(),
  alumni: [],
  isDeletingAlumni: false,
  isGettingAlumni: false,
  isInvitingAlumniViaCsvImport: false,
  searchTerm: '',
}

const alumniSlice = createSlice({
  name: 'alumni',
  initialState,
  reducers: {
    clearAlumniMetadata: (state) => {
      state.alumniMetadata = generateAlumniMetadata();
    },
    clearDeleteAlumniError: (state) => {
      state.deleteAlumniError = undefined;
    },
    clearInviteAlumniViaCsvImportError: (state) => {
      state.inviteAlumniViaCsvImportError = undefined;
    },
    setSearchTerm: (state, {payload}) => {
      state.searchTerm = payload;
    }
  },
  extraReducers: ({addCase}) => {
    addCase(getAlumni.pending, (state) => {
      state.isGettingAlumni = true;
    });
    addCase(getAlumni.fulfilled, (state, {payload}) => {
      state.alumni = payload.alumni;
      state.alumniMetadata = payload.alumniMetadata;
      state.isGettingAlumni = false;
    });
    addCase(getAlumni.rejected, (state, {payload}) => {
      state.getAlumniError = payload as IError;
      state.isGettingAlumni = false;
    });

    addCase(inviteAlumniViaCsvImport.pending, (state) => {
      state.isInvitingAlumniViaCsvImport = true;
    });
    addCase(inviteAlumniViaCsvImport.fulfilled, (state) => {
      state.isInvitingAlumniViaCsvImport = false;
    });
    addCase(inviteAlumniViaCsvImport.rejected, (state, {payload}) => {
      state.inviteAlumniViaCsvImportError = payload as IError;
      state.isInvitingAlumniViaCsvImport = false;
    });
  },
});

export const {
  clearAlumniMetadata,
  clearDeleteAlumniError,
  clearInviteAlumniViaCsvImportError,
  setSearchTerm,
} = alumniSlice.actions;

export default alumniSlice.reducer;
