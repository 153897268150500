import './EventAttendees.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from "react-router";
import clone from 'clone';
import { CSVLink } from "react-csv";

import {isArrayNullOrEmpty, renderGradYear} from "../../../../../utils/utils";

import EventAttendee from "../../../../../types/EventAttendee";
import TableColumn from "../../../../../types/TableColumn";
import TableFilterMenuOption from "../../../../../types/TableFilterMenuOption";

import {useAppDispatch} from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";
import {
    checkIn,
    clearEvent,
    getEvent,
    getEventAttendees,
    getEventAttendeesForExport,
    setEventAttendeeSearchTerm,
    removeRsvp,
} from "../../../../../store/slices/events";

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Avatar from "../../../../../components/Avatar";
import Button, {ButtonSizes, ButtonThemes} from "../../../../../components/Button";
import H5 from "../../../../../components/H5";
import Icon from "../../../../../components/Icon";
import ListStudents from "../../SchoolStudents/ListStudents";
import NewContentModal from "../../../../../components/NewContentModal";
import Table from "../../../../../components/Table";
import TableFilterMenu from "../../../../../components/Table/TableFilterMenu";
import TableFilterRow from "../../../../../components/Table/TableFilterRow";
import TableFilterRowGroup from "../../../../../components/Table/TableFilterRow/TableFilterRowGroup";
import TableSearch from "../../../../../components/TableSearch";
import Modal from "../../../../../components/Modal";

const EventAttendees: React.FC =  () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch() as { params: { postId: string, profileType: string, schoolId: string }};
    const {
        event,
        eventAttendees,
        eventAttendeesMetadata,
        eventAttendeesSearchTerm,
        isGettingEventAttendees,
        isRemovingRsvp,
    } = useTypedSelector((state) => state.events);

    const [initialized, setInitialized] = useState<boolean>(false);
    const [showNewContentModal, setShowNewContentModal] = useState<boolean>(false);
    const [exportData, setExportData] = useState<Array<Array<String>>>()
    const [rsvpToRemove, setRsvpToRemove] = useState(undefined);

    useEffect(() => {
        const initialize = async () => {
            try {
                dispatch(getEvent({ postId: params.postId }));
                await dispatch(getEventAttendees( { postId: params.postId })).unwrap();
                setInitialized(true);
            } catch(err) {
                console.log('EventAttendees initialize err', err);
            }
        };

        initialize();

        return () => {
            dispatch(clearEvent());
        }
    }, []);

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(eventAttendeesMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            if(changes.search != null) {
                clonedMetadata.page_num = 0;
            }

            return await dispatch(getEventAttendees({isUpdate, metadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('EventAttendees onMetadataChange err', err);
        }
    };

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Name',
                    id: 'student',
                    Cell: ({row: { original }}) => (
                        <div className="users-table__user">
                            <Avatar
                                artifact={original.profile.artifact}
                                flagged={original.profile.flagged || original.profile.flaggedContent}
                            />

                            <div className="users-table__name">
                                {original.profile.firstName} {original.profile.lastName}
                            </div>
                        </div>
                    ),
                    hasImage: true,
                    sortBy: 'name',
                }, {
                    Header: 'Email',
                    accessor: 'profile.emailAddress',
                    sortBy: 'emailAddress'
                }, {
                    Header: 'Class Year',
                    id: 'classYear',
                    Cell: ({row: { original }}) => {
                        return (
                            <div className="users-table__majors">
                                {renderGradYear(original.profile)}
                            </div>
                        )
                    },
                    sortBy: 'classYear'
                }, {
                    Header: 'RSVP',
                    id: 'rsvp',
                    Cell: ({row: { original }}) => {
                        return (
                            <>
                                {original.isRsvp && (
                                    <Icon type="check" theme="success" />
                                )}
                            </>
                        )
                    },
                }, {
                    Header: '',
                    id: 'checkin',
                    Cell: ({row: { original }}) => {
                        return (
                            <div className="attendees__actions">
                                <Button
                                    size={ButtonSizes.Small}
                                    icon={original.isCheckedIn ? 'check' : undefined}
                                    theme={original.isCheckedIn ? ButtonThemes.Primary : ButtonThemes.Secondary}
                                    onClick={() => handleCheckIn(event.postId, original)}
                                    showActivityIndicator={original.isCheckingIn}
                                >
                                    {original.isCheckedIn ? 'Checked In' : 'Check In'}
                                </Button>
                            </div>
                        )
                    },
                }, {
                    Header: '',
                    id: 'trash',
                    Cell: ({row: { original }}) => (

                        <>
                            {original.isRsvp && (
                                <Button
                                    theme={ButtonThemes.Link}
                                    onClick={() => setRsvpToRemove(original)}
                                >
                                    <Icon type="trash" />
                                </Button>
                            )}
                        </>
                    ),
                }
            ];

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const filterOptionsRSVP: Array<TableFilterMenuOption> = useMemo(
        () => {
            return (
                [
                    {
                        isActive: eventAttendeesMetadata.is_rsvp === undefined,
                        onSelect: () => {
                            onMetadataChange({is_rsvp: undefined, page_num: 0}, false);
                        },
                        text: "Any"
                    }, {
                    isActive: eventAttendeesMetadata.is_rsvp === true,
                    onSelect: () => {
                        onMetadataChange({
                            is_rsvp: true,
                            page_num: 0
                        }, false);
                    },
                    text: "RSVPd"
                }, {
                    isActive: eventAttendeesMetadata.is_rsvp === false,
                    onSelect: () => {
                        onMetadataChange({
                            is_rsvp: false,
                            page_num: 0
                        }, false);
                    },
                    text: "Not RSVPd"
                }
                ]
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [eventAttendeesMetadata]
    );

    const filterOptionsCheckedIn: Array<TableFilterMenuOption> = useMemo(
        () => {
            return (
                [
                    {
                        isActive: eventAttendeesMetadata.checked_in === undefined,
                        onSelect: () => {
                            onMetadataChange({checked_in: undefined, page_num: 0}, false);
                        },
                        text: "Any"
                    }, {
                    isActive: eventAttendeesMetadata.checked_in === true,
                    onSelect: () => {
                        onMetadataChange({
                            checked_in: true,
                            page_num: 0
                        }, false);
                    },
                    text: "Checked In"
                }, {
                    isActive: eventAttendeesMetadata.checked_in === false,
                    onSelect: () => {
                        onMetadataChange({
                            checked_in: false,
                            page_num: 0
                        }, false);
                    },
                    text: "Not Checked In"
                }
                ]

            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [eventAttendeesMetadata]
    );

    const CSVDownload = (props) => {
        const btnRef = useRef(null)
        useEffect(() => btnRef.current?.click(), [btnRef])
        return (
            <CSVLink {...props}>
                <span ref={btnRef} />
            </CSVLink>
        )
    }
    const handleCheckIn = async (eventId, attendee) => {
        dispatch(checkIn({eventId, attendee}));
        setShowNewContentModal(false);
    }

    const handleExportAttendeeList = async () => {
        try {
            const csvData = [['First Name', 'Last Name', 'Email', 'Class Year', 'Checked In']];

            const res = await dispatch(getEventAttendeesForExport({postId: params.postId})).unwrap();

            res.forEach((attendee: EventAttendee) => {
                return csvData.push([attendee.profile?.firstName, attendee.profile?.lastName, attendee.profile?.emailAddress, renderGradYear(attendee.profile), attendee.isCheckedIn ? 'Yes' : 'No']);
            });

            setExportData(csvData);

            // Remove the export data after the download has been triggered.
            // This is to prevent the export data from being re-downloaded.
            setTimeout(() => {
                setExportData(null);
            }, 5000);
        } catch(err) {
            console.warn('EventAttendees handleExportAttendeeList err', err);
        }

    }

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setEventAttendeeSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="attendees">

            {exportData && (
                <CSVDownload
                    data={exportData}
                    filename={`${event.title} Attendees.csv`}
                    target="_blank"
                />
            )}

            <header className="attendees__header">
                <Button
                    className="attendees__back-button"
                    noStyles
                    onClick={() => history.replace(`/school/${params.schoolId}/${params.profileType}/events`)}
                >
                    <Icon type="arrow-left" />
                </Button>

                {event?.title && (
                    <H5>
                        {event.title}
                    </H5>
                )}
            </header>

            <TableFilterRow className="filter-row-spl">
                <TableFilterRowGroup>
                    <TableFilterMenu
                        label="RSVP Status"
                        options={filterOptionsRSVP}
                    />

                    <TableFilterMenu
                        label="Checked In Status"
                        options={filterOptionsCheckedIn}
                    />

                    <TableSearch
                        handleChange={(value) => dispatch(setEventAttendeeSearchTerm(value))}
                        handleClear={handleClearSearchTerm}
                        handleSubmit={() => onMetadataChange({page_num: 0,search: eventAttendeesSearchTerm}, false)}
                        placeholder="Search Attendees"
                        searchTerm={eventAttendeesSearchTerm}
                        size="small"
                    />
                </TableFilterRowGroup>

                <TableFilterRowGroup>
                    <Button
                        theme={ButtonThemes.Secondary}
                        size={ButtonSizes.Small}
                        onClick={handleExportAttendeeList}
                    >
                        Export Attendee List
                    </Button>

                    <Button
                        size={ButtonSizes.Small}
                        onClick={() => setShowNewContentModal(true)}
                    >
                        Add Attendees
                    </Button>
                </TableFilterRowGroup>
            </TableFilterRow>

            {isGettingEventAttendees ? (
                <ActivityIndicator />
            ) : (
                <>
                    {isArrayNullOrEmpty(eventAttendees) && initialized ? (
                        <>
                            No RSVPs to display. <Button
                            onClick={() => setShowNewContentModal(true)}
                            theme={ButtonThemes.Link}
                        >
                            Search for a {params.profileType} to check in
                        </Button>.
                        </>
                    ) : (
                        <>
                            <Table
                                columns={columns}
                                data={eventAttendees}
                                getData={onMetadataChange}
                                pageNumber={eventAttendeesMetadata.page_num}
                                pageSize={eventAttendeesMetadata.page_size}
                                sortBy={eventAttendeesMetadata.sort}
                                sortOrder={eventAttendeesMetadata.order}
                                totalNumberOfItems={eventAttendeesMetadata.total}
                            />
                        </>
                    )}
                </>
            )}

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                <ListStudents
                    actionButtonText="Add"
                    actionButtonOnClick={(user) => handleCheckIn(event.postId, {profile: user})}
                />
            </NewContentModal>

            <Modal
                confirmButtonOnClick={async () => {
                    await dispatch(removeRsvp({eventId: event.postId, profileId: rsvpToRemove.profile.profileId}));
                    setRsvpToRemove(undefined);
                }}
                confirmButtonText="OK"
                declineButtonText="Cancel"
                declineButtonOnClick={() => {
                    setRsvpToRemove(undefined);
                }}
                show={rsvpToRemove !== undefined}
                title="Remove RSVP?"
                showActivityIndicator={isRemovingRsvp}
            >
                <p>Are you sure you want to remove this RSVP?</p>
            </Modal>
        </div>
    );
};

export default EventAttendees;
