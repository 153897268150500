import './FeedGroupChatMessage.scss';

import React, { useState } from 'react';
import classNames from "classnames";
import moment from 'moment';

import { useHistory, useRouteMatch } from "react-router";

import ChatMessage from "../../../types/ChatMessage";

import { isArrayNullOrEmpty } from "../../../utils/utils";

import Button, { ButtonThemes } from "../../Button";
import Card from "../../Card";
import GroupChatMessage from "../../GroupChatMessage";
import TableMoreMenu from "../../Table/TableMoreMenu";

type Props = {
    className?: string
    message: ChatMessage
}

const FeedGroupChatMessage: React.FC<Props> = ({
    className,
    message,
}) => {
    const match = useRouteMatch();
    const history = useHistory();

    const [showContext, setShowContext] = useState<boolean>(false);

    const classes = classNames(
        'c-feed-group-chat-message c-feed-item',
        className,
        {
            'a-feed-item--flagged': message.flaggedCount > 0,
            'a-feed-item--removed-by-mods': message.removedByMods,
            'a-feed-item--super-approved': message.superApproval,
        }
    );

    return (
        <Card className={classes}>
            <header className="c-feed-group-chat-message__header">
                <div className="c-feed-group-chat-message__group">
                    <strong>Group Chat</strong> in <strong onClick={() => history.push(`/school/${match?.params?.schoolId}/group/${message.forumTopicId}/activity`)}>{message.forumTopic.name}</strong> on {moment(message.createdAt).format('MMM DD, YYYY [at] h:mm A')}
                </div>

                <div className="c-feed-group-chat-message__flagged-at">
                    {message.flaggedCount > 0 && (
                        <>
                            Flagged {moment(message.lastFlaggedAt).format('MMM DD, YYYY [at] h:mm A')}
                        </>
                    )}

                    <TableMoreMenu
                        item={message}
                        onlyModerationOptions
                    />
                </div>
            </header>

            <div className="c-feed-group-chat-message__messages">
                {showContext ? (
                    <>
                        {message.contextMessages.map((cm) => (
                            <GroupChatMessage
                                isMessageInFocus={cm.forumTopicMessageId === message.forumTopicMessageId}
                                message={cm}
                            />
                        ))}
                    </>
                ) : (
                    <GroupChatMessage
                        isMessageInFocus
                        message={message}
                    />
                )}
            </div>

            {!message.removedByMods && (
                <div className="c-feed-group-chat-message__context">
                    <Button
                        disabled={isArrayNullOrEmpty(message.contextMessages) || message.contextMessages.length < 2}
                        onClick={() => setShowContext(!showContext)}
                        theme={ButtonThemes.Link}
                    >
                        {showContext ? 'View Single Comment' : 'View in Context'}
                    </Button>
                </div>
            )}
        </Card>
    );
};

export default FeedGroupChatMessage;
