import React, { useState } from 'react';

import ActivityIndicator from '../../../../../../components/ActivityIndicator';
import Avatar from '../../../../../../components/Avatar';
import Button, { ButtonThemes } from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import { CardType, DashboardCardProps } from '../../../index';
import DashboardCardModal from '../../DashboardCardModal';
import { useAppDispatch } from '../../../../../../store';
import { useTypedSelector } from '../../../../../../store/reducers';
import { setShowMostActiveUsersDetailModal } from '../../../../../../store/slices/superAdminDashboard';
import FormRow from '../../../../../../components/FormRow';
import FormColumn from '../../../../../../components/FormColumn';
import H5 from '../../../../../../components/H5';

const MostActiveUsersCard: React.FC<DashboardCardProps> = ({cardData, initialized, isGettingDashboard}) => {
    const [detailData, setDetailData] = useState(null);
    const dispatch = useAppDispatch();

    const {showMostActiveUsersDetailModal} = useTypedSelector(state => state.superAdminDashboard);
    const {title, subtitle, subtitles, data} = cardData;

    const handleClick = (value: any) => {
        dispatch(setShowMostActiveUsersDetailModal(value));
    };
    return (
        <Card
            key={cardData.title}
            className="dashboard-card__card"
        >
            <div className="dashboard-card__row">
                <H5>
                    {title}
                </H5>
            </div>
            {isGettingDashboard && (
                <ActivityIndicator size={'large'}/>
            )}
            {initialized && !isGettingDashboard && (
                <>
                    <FormRow
                        columnCount={5}
                        className="dashboard-card__detail-row"
                    >
                        <FormColumn className="dashboard-card__detail-subtitle-col--primary">
                            {subtitle}
                        </FormColumn>

                        {subtitles.map(st => (
                            <FormColumn
                                key={st}
                                className="dashboard-card__detail-subtitle-col"
                            >
                                {st}
                            </FormColumn>
                        ))}
                    </FormRow>
                    {data && data.length > 0 && data.map(profile => (
                        <FormRow
                            key={profile.profile.profileId}
                            columnCount={5}
                            className="dashboard-card__detail-row"
                        >
                            <FormColumn className="dashboard-card__detail-main-col">
                                <div className="dashboard-card__detail-row">
                                    <Avatar
                                        className="dashboard-card__detail-row-avatar"
                                        artifact={profile.profile.artifact}
                                    />
                                    <div>
                                        <div>
                                            {profile.profile.emailAddress}
                                        </div>
                                        <div
                                            className="dashboard-card__detail-row-muted">Class {profile.profile.details.filter(detail => detail.element && detail.element.code && detail.element.code === 'gradyear')[0]?.elementDetail?.code || 'Unknown'}</div>
                                    </div>
                                </div>
                            </FormColumn>

                            <FormColumn className="dashboard-card__detail-stat-col">
                                {(profile.upvoteCount + profile.saveCount + profile.postCount + profile.commentCount)}
                            </FormColumn>

                            <FormColumn className="dashboard-card__detail-stat-col details">
                                <Button
                                    theme={ButtonThemes.Link}
                                    onClick={() => {
                                        handleClick(!showMostActiveUsersDetailModal);
                                        setDetailData(profile);
                                    }}>
                                    Details
                                </Button>
                            </FormColumn>
                        </FormRow>
                    ))}
                    {detailData && (
                        <DashboardCardModal
                            type={CardType.MostActiveUsers}
                            data={detailData}
                        />
                    )}
                </>
            )}
        </Card>
    );
};

export default MostActiveUsersCard;
