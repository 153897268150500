import './ProgressBar.scss';

import React from 'react';

type Props = {
    progress?: number
}

const ProgressBar: React.FC<Props> = ({
    progress = 30
}) => {
    return (
        <div className="c-progress-bar">
            <div
                className="c-progress-bar__inner"
                style={{width: `${progress}%`}}
            />
        </div>
    );
};

export default ProgressBar;
