import React, { useMemo } from 'react';
import TableMoreMenu from "../../../../../components/Table/TableMoreMenu";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";
import {deleteGivingOpportunity, setGivingOpportunity, featureGivingCategoryOrOpportunity, unFeatureGivingCategoryOrOpportunity} from "../../../../../store/slices/giving";
import GivingOpportunity from "../../../../../types/GivingOpportunity";
import Modal from "../../../../../components/Modal";

type Props = {
    getAfterDelete?: () => void
    item: GivingOpportunity
    setShowNewContentModal: Function
}

const GivingOpportunitiesTableMoreMenu: React.FC<Props> = ({
     getAfterDelete,
     item,
     setShowNewContentModal,
 }) => {
    const dispatch = useAppDispatch();

    const { isDeletingGivingOpportunity } = useTypedSelector((state) => state.giving);

    const [showFeatureValidationModal, setShowFeatureValidationModal] = React.useState<boolean>(false);

    const handleDelete = async (item) => {
        try {
            await dispatch(deleteGivingOpportunity({opportunityId: item.givingOpportunityId})).unwrap();
            getAfterDelete && getAfterDelete();
        } catch(err) {
            console.warn('ListNewsStories handleDelete err', err);
        }
    };

    const handleEditGivingOpportunity = (item) => {
        dispatch(setGivingOpportunity(item));
        setShowNewContentModal();
    };

    const handleFeatureGivingOpportunity = () => {
        if (!item.artifact || !item.description) {
            dispatch(setGivingOpportunity(item));
            setShowFeatureValidationModal(true);
            return;
        }
        dispatch(featureGivingCategoryOrOpportunity({givingOpportunityId: item.givingOpportunityId}));
    }

    const handleUnFeatureGivingOpportunity = () => {
        dispatch(unFeatureGivingCategoryOrOpportunity());
    }

    const customActions = useMemo(
        () => {
            let actions = [];

            if (item.featured) {
                actions.push({
                    icon: 'star',
                    onClick: handleUnFeatureGivingOpportunity,
                    text: 'Un-Feature'
                });
            } else {
                actions.push({
                    icon: 'star',
                    onClick: handleFeatureGivingOpportunity,
                    text: 'Feature'
                });
            }

            return actions;

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    return (
        <>
            <TableMoreMenu
                customOptions={customActions}
                hideModerationOptions
                isDeleting={isDeletingGivingOpportunity}
                item={item}
                onDeleteClick={() => handleDelete(item)}
                onEditClick={() => handleEditGivingOpportunity(item)}
                type="giving"
            />
            <Modal
                title="Before you can feature this fund..."
                show={showFeatureValidationModal}
                confirmButtonOnClick={() => {
                    handleEditGivingOpportunity(item);
                    setShowFeatureValidationModal(false);
                }}
                confirmButtonText="Edit"
                declineButtonOnClick={() => setShowFeatureValidationModal(false)}
                declineButtonText="Cancel"
            >
                A fund must have a description and an image to be featured.
            </Modal>
        </>
    );
};

export default GivingOpportunitiesTableMoreMenu;
