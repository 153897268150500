import './CreateEditDeal.scss';

import React from 'react';

import { useTypedSelector } from '../../../../store/reducers';

import CreateEditDealForm from './CreateEditDealForm';

type Props = {
    close?: Function
}

const CreateEditDeal: React.FC<Props> = ({
    close
}) => {

    const { deal } = useTypedSelector(store => store.deals);

    return (
        <CreateEditDealForm
            close={close}
            deal={deal}
        />
    );
};

export default CreateEditDeal;
