import './UpdateGivingStatus.scss';

import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../../../store';
import { useTypedSelector } from '../../../../../store/reducers';
import {
    clearGivingStatusConfig,
} from '../../../../../store/slices/schools';

import { StripeAccountStatus } from '../../../../../types/StripeAccountStatus';
import Tenant from '../../../../../types/Tenant';
import FormRow from '../../../../../components/FormRow';
import GivingStatusPendingApprovalForm from './GivingStatusPendingApprovalForm';
import Label from '../../../../../components/Label';
import NewContentModal from '../../../../../components/NewContentModal';
import UpdateGivingStatusForm from './UpdateGivingStatusForm';

type UpdateGivingStatusProps = {
    school: Tenant,
    showForm: boolean,
    setShowForm: (showForm: boolean) => void,
}

const UpdateGivingStatus: React.FC<UpdateGivingStatusProps> = ({
   school,
   showForm,
   setShowForm
}) => {
    const dispatch = useAppDispatch();

    const {updateGivingStatusConfig} = useTypedSelector(state => state.schools);

    const [showPendingApproval, setShowPendingApproval] = useState<boolean>(false);

    useEffect(() => {
        if (!school.tenantId) {
            console.error('No tenantId found');
            clearGivingStatusConfig();
            setShowForm(false);
        }

        if (
            school.givingActive &&
            school.billingContactEmailAddress &&
            school.stripeAccountStatus !== StripeAccountStatus.Complete
        ) {
            clearGivingStatusConfig();
            setShowPendingApproval(true);
        }
    }, [school]);

    const handleSubmit = async (func: Function) => {
        try {
            await func();
            setShowForm(false);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <NewContentModal
            show={showForm}
            close={setShowForm}
            contentLabel={'Update Giving Status'}
            width={'wide'}
        >
            {school.tenantId && (
                <>
                    <FormRow>
                        <Label>
                            Stripe Account Status: {school.stripeAccountStatus}
                        </Label>
                    </FormRow>
                </>
            )}

            {showForm && school.tenantId && (
                <UpdateGivingStatusForm
                    config={updateGivingStatusConfig}
                    dispatch={dispatch}
                    handleSubmit={handleSubmit}
                />
            )}

            {showPendingApproval && school.tenantId && (
                <GivingStatusPendingApprovalForm
                    activeSchool={school}
                />
            )}
        </NewContentModal>
    );
};

export default UpdateGivingStatus;

