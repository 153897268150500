import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import IError from "../../types/IError";
import IntegrationEvent from "../../types/IntegrationEvent";
import Metadata from "../../types/Metadata";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";

import { addError } from "./errors";
import { RootState } from '../reducers';
import { generateIntegrationEvent } from '../../utils/generators';
import { POST_STATUS } from '../../utils/constants';

type DeleteIntegrationEventProps = {
    integrationEvent?: IntegrationEvent
}

export const deleteIntegrationEvent = createAsyncThunk(
    'integrationEvents/deleteIntegrationEvent',
    async ({integrationEvent}: DeleteIntegrationEventProps = {}, {dispatch, getState}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!integrationEvent) {
                integrationEvent = (getState() as RootState).integrations.event;
            }
            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.integrations.deleteEvent(tenantId, integrationEvent.postStagingId));
            return res;
        } catch(err) {
            console.log('delete integrationEvent err', err);
            err.friendlyMessage = 'Error deleting the event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

type GetIntegrationEventProps = {
    postStagingId: number | string
    schoolId?: number | string
}

export const getIntegrationEvent = createAsyncThunk(
    'integrationEvents/getIntegrationEvent',
    async ({postStagingId, schoolId}: GetIntegrationEventProps, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);
            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }
            const res = await new Request(token).get(PATHS.integrations.getEventById(schoolId, postStagingId));
            if(!res.data.data.status) {
                res.data.data.status = POST_STATUS.DRAFT;
            }
            return res.data.data;
        } catch(err) {
            console.log('getDeal', err);
            err.friendlyMessage = 'Error getting the integration. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetIntegrationEventsProps = {
    isUpdate?: boolean
    schoolId?: number
    integrationEventsMetadata?: Metadata
}

export const getIntegrationEvents = createAsyncThunk(
    'integrations/getIntegrationEvents',
    async ({isUpdate, schoolId, integrationEventsMetadata}: GetIntegrationEventsProps = {}, {dispatch, getState}) => {
        try {
            await setTimeout(() => null, 10000);

            if(!integrationEventsMetadata) {
                integrationEventsMetadata = clone((getState() as RootState).integrations.integrationEventsMetadata);
            } else {
                integrationEventsMetadata = {...integrationEventsMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.integrations.getEvents(schoolId, buildQueryString(integrationEventsMetadata)));

            let integrationEvents = res.data.data.postStagings;
            integrationEventsMetadata.total = res.data.data.meta.totalCount;
            return {integrationEvents, integrationEventsMetadata};
        } catch(err) {
            console.log('getIntegrationEvents', err);
            err.friendlyMessage = 'Error getting the list of integrationEvents. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type PublishIntegrationEventProps = {
    integrationEvent?: IntegrationEvent
}

export const publishIntegrationEvent = createAsyncThunk(
    'integrationEvents/publishIntegrationEvent',
    async({integrationEvent}: PublishIntegrationEventProps, {dispatch, getState}) => {
        const { activeSchool: { tenantId } } = (getState() as RootState).schools;

        if(!integrationEvent) {
            integrationEvent = (getState() as RootState).integrations.event;
        }

        try {
            const res = await new Request((getState() as RootState).auth.token).post(PATHS.integrations.publishEvent(tenantId, integrationEvent.postStagingId), integrationEvent);
            return res;
        } catch(err) {
            console.log('publish integrationEvent error', err);
            err.friendlyMessage = 'Error publishing the event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

interface IntegrationsState {
    event: IntegrationEvent
    events: Array<IntegrationEvent>
    integrationEventsMetadata: Metadata
    isDeletingIntegrationEvent: boolean
    isGettingIntegrationEvent: boolean
    isGettingIntegrationEvents: boolean
    isPublishingIntegrationEvent: boolean
    deleteIntegrationEventError?: IError
    getDealError?: IError
    getIntegrationEventError?: IError
    getIntegrationEventsError?: IError
    publishIntegrationEventError?: IError
    searchTerm: string
}

const initialState: IntegrationsState = {
    event: generateIntegrationEvent(),
    events: [],
    integrationEventsMetadata: {
        page_size: 10,
        page_num: 0,
        order: 'asc',
        sort: 'startAt',
        search: ''
    },
    searchTerm: '',
    isDeletingIntegrationEvent: false,
    isGettingIntegrationEvent: false,
    isGettingIntegrationEvents: false,
    isPublishingIntegrationEvent: false,
    deleteIntegrationEventError: undefined,
    getIntegrationEventError: undefined,
    getIntegrationEventsError: undefined,
    publishIntegrationEventError: undefined
};

export const integrationsSlice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        clearIntegrationEvent: (state) => {
            state.event = generateIntegrationEvent();
        },
        setIntegrationEvent: (state, action) => {
            state.event = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteIntegrationEvent.pending, (state) => {
            state.deleteIntegrationEventError = undefined;
            state.isDeletingIntegrationEvent = true;
        });
        addCase(deleteIntegrationEvent.fulfilled, (state) => {
            state.isDeletingIntegrationEvent = false;
            state.event = generateIntegrationEvent();
        });
        addCase(deleteIntegrationEvent.rejected, (state, action) => {
            state.deleteIntegrationEventError = action.error as IError;
            state.isDeletingIntegrationEvent = false;
        });

        addCase(getIntegrationEvent.pending, (state) => {
            state.getIntegrationEventError = undefined;
            state.isGettingIntegrationEvent = true;
        });
        addCase(getIntegrationEvent.fulfilled, (state, action) => {
            state.isGettingIntegrationEvent = false;
            state.event = action.payload;
        });
        addCase(getIntegrationEvent.rejected, (state, action) => {
            state.getIntegrationEventError = action.error as IError;
            state.isGettingIntegrationEvent = false;
        });

        addCase(getIntegrationEvents.pending, (state, action) => {
            state.getIntegrationEventsError = undefined;
            state.isGettingIntegrationEvents = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.integrationEventsMetadata) {
                state.integrationEventsMetadata = action.meta.arg.integrationEventsMetadata;
            }
        });
        addCase(getIntegrationEvents.fulfilled, (state, action) => {
            state.events = action.payload.integrationEvents;
            state.integrationEventsMetadata = action.payload.integrationEventsMetadata;
            state.isGettingIntegrationEvents = false;
        });
        addCase(getIntegrationEvents.rejected, (state, action) => {
            state.getIntegrationEventsError = action.error as IError;
            state.isGettingIntegrationEvents = false;
        });

        addCase(publishIntegrationEvent.pending, (state) => {
            state.publishIntegrationEventError = undefined;
            state.isPublishingIntegrationEvent = true;
        });
        addCase(publishIntegrationEvent.fulfilled, (state) => {
            state.isPublishingIntegrationEvent = false;
            state.event = generateIntegrationEvent();
        });
        addCase(publishIntegrationEvent.rejected, (state, action) => {
            state.publishIntegrationEventError = action.error as IError;
            state.isPublishingIntegrationEvent = false;
        });
    }
});

export const { clearIntegrationEvent, setIntegrationEvent, setSearchTerm } = integrationsSlice.actions;

export default integrationsSlice.reducer;
