import './Datepicker.scss';

import React from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';

import Validation from '../../types/Validation';

import { isObjectNullOrEmpty } from "../../utils/utils";

import FormValidationMessage from '../FormValidationMessage';
import Icon from "../Icon";
import Label from '../Label';
import Tooltip, { TooltipSide } from '../Tooltip';

type Props = {
    className?: string
    dateFormat?: string
    disabled?: boolean
    dropdownMode?: string
    fixedHeight?: boolean
    id: string
    label?: string
    minDate?: Date | number | string
    name: string
    onChange: Function
    placeholderText?: string
    required?: boolean
    selected?: Date | number | string
    validation?: Validation
    showMonthDropdown?: boolean
    showTimeSelect?: boolean
    showTimeSelectOnly?: boolean
    showYearDropdown?: boolean
    showToolTip?: boolean
    toolTipOptions?: {
        side: TooltipSide
        text: string
    },
    thisRef?: React.Ref<any>
    timeIntervals?: number
}

const Datepicker: React.FC<Props> = ({
    className,
    dateFormat,
    label,
    toolTipOptions,
    minDate,
    required,
    selected,
    showToolTip,
    thisRef,
    validation,
    ...otherProps
}) => {
    let classes = classNames('o-datepicker', className, {
        'has-error': validation != null && validation.show === true && validation.isValid === false,
        'has-label': label != null,
        'a-datepicker--disabled': otherProps.disabled === true,
    });

    if (dateFormat == null) {
        dateFormat = 'MM/dd/yyyy';
    }

    if (selected && typeof selected === 'string') {
        selected = Date.parse(selected);
    }

    if (minDate && typeof minDate === 'string') {
        minDate = Date.parse(minDate);
    }

    return (
        <div className={classes}>
            {label != null ? (
                <Label htmlFor={otherProps.id} className="c-datepicker-label__container">
                    {label} {required === true ? <span className="c-label__required-asterisk">*</span> : ''}

                    {showToolTip && !isObjectNullOrEmpty(toolTipOptions) && (
                        <Tooltip icon="info" side={toolTipOptions.side}>
                            {toolTipOptions.text}
                        </Tooltip>
                    )}
                </Label>
            ) : null}

            <DatePicker
                className="c-datepicker c-textbox"
                dateFormat={dateFormat}
                minDate={minDate}
                ref={thisRef}
                selected={selected}
                {...otherProps}
            />

            <Icon className="c-datepicker__icon" type="calendar" />

            {validation && validation.show && <FormValidationMessage>{validation.error.reason}</FormValidationMessage>}
        </div>
    );
};

export default Datepicker;
