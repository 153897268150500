import './CsvFormError.scss';

import React from 'react';

import { isObjectNullOrEmpty } from '../../utils/utils';

import FormRow from '../FormRow';
import FormValidationMessage from '../FormValidationMessage';

type Props = {
    error: IError
}

const CsvFormError: React.FC<Props> = ({
    error
}) => {
    if(isObjectNullOrEmpty(error)) {
        return null;
    }

    return (
        <FormRow className="c-csv-form-error">
            <FormValidationMessage>
                {error.message}
            </FormValidationMessage>
        </FormRow>
    );
};

export default CsvFormError;
