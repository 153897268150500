import './ViewGroupMembersPendingInvited.scss';

import React, { useEffect, useState } from 'react';
import { useRouteMatch } from "react-router";
import moment from "moment";

import { getGroupMembersPendingInvited, inviteProfile, removeMember } from "../../../../../../../store/slices/groups";
import { useAppDispatch } from "../../../../../../../store";
import { useTypedSelector } from "../../../../../../../store/reducers";

import ActivityIndicator from "../../../../../../../components/ActivityIndicator";
import Avatar from "../../../../../../../components/Avatar";
import Button, { ButtonSizes, ButtonThemes } from "../../../../../../../components/Button";
import Modal from "../../../../../../../components/Modal";

const ViewGroupMembersPendingInvited: React.FC = () => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState(false);
    const [memberToReinvite, setMemberToReinvite] = useState(null);
    const [showInviteSuccessModal, setShowInviteSuccessModal] = useState(false);

    const { groupMembersPendingInvited, isGettingGroupMembersPendingInvited, isInvitingProfile, isRemovingMember } = useTypedSelector((state) => state.groups);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getGroupMembersPendingInvited({schoolId: params?.schoolId, forumTopicId: params?.forumTopicId})).unwrap();
            } catch(err) {
                console.log('ViewGroupMembersPendingInvited initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancelInvitation = async (member) => {
        try {
            setMemberToReinvite(member);

            await dispatch(removeMember({
                forumTopicId: params.forumTopicId,
                forumTopicProfileId: member.forumTopicProfileId,
                schoolId: params.schoolId
            })).unwrap();
        } catch(err) {
            console.log('ViewGroupMembersPendingInvited handleResendInvitation err', err);
        } finally {
            setMemberToReinvite(null);
        }
    };

    const handleResendInvitation = async (member) => {
        try {
            setMemberToReinvite(member);

            await dispatch(inviteProfile({
                forumTopicId: params.forumTopicId,
                profileId: member.profile.profileId,
                schoolId: params.schoolId
            })).unwrap();
            setShowInviteSuccessModal(true);
        } catch(err) {
            console.log('ViewGroupMembersPendingInvited handleResendInvitation err', err);
        } finally {
            setMemberToReinvite(null);
        }
    };

    return (
        <div className="view-group-members-invited">
            {isGettingGroupMembersPendingInvited || !initialized ? (
                <ActivityIndicator />
            ) : (
                <div className="pending-members-list">
                    {groupMembersPendingInvited.map((member) => (
                        <div className="pending-members-list__row">
                            <div className="pending-members-list__row__user-data-container">
                                <Avatar artifact={member.profile?.artifact} />

                                <div className="pending-members-list__row__user-data">
                                    <div className="pending-members-list__row__name">
                                        {member.profile?.firstName} {member.profile?.lastName}
                                    </div>

                                    <div className="pending-members-list__row__description">
                                        {member.profile?.emailAddress}
                                    </div>
                                </div>
                            </div>

                            <div className="pending-members-list__row__date">
                                {member.createdAt && moment(member.createdAt).format('[Invited] MMM D, YYYY')}
                            </div>

                            <div className="pending-members-list__row__actions">
                                <Button
                                    disabled={isInvitingProfile || isRemovingMember}
                                    onClick={() => handleCancelInvitation(member)}
                                    showActivityIndicator={isRemovingMember && memberToReinvite?.forumTopicProfileId === member.forumTopicProfileId}
                                    size={ButtonSizes.Small}
                                    theme={ButtonThemes.Secondary}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    disabled={isInvitingProfile || isRemovingMember}
                                    onClick={() => handleResendInvitation(member)}
                                    showActivityIndicator={isInvitingProfile && memberToReinvite?.forumTopicProfileId === member.forumTopicProfileId}
                                    size={ButtonSizes.Small}
                                    theme={ButtonThemes.Secondary}
                                >
                                    Resend
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <Modal
                declineButtonOnClick={() => {
                    setShowInviteSuccessModal(false);
                }}
                declineButtonText="OK"
                show={showInviteSuccessModal}
                title="Success!"
            >
                This invitation has been sent again.
            </Modal>
        </div>
    );
};

export default ViewGroupMembersPendingInvited;
