import './PreviewLandingPageStudent.scss';

import React from 'react';

import { isObjectNullOrEmpty } from "../../../../../../../../../utils/utils";

import Avatar from "../../../../../../../../../components/Avatar";

type Props = {
    item: Profile
}

const PreviewLandingPageStudent: React.FC<Props> = ({item}) => {
    let student = item.profile || item;

    const renderLocation = () => {
        if(isObjectNullOrEmpty(student.location)) {
            return <>&nbsp;</>;
        }

        if(student.location.city && student.location.state) {
            return `${student.location.city}, ${student.location.state}`;
        }

        return student.location.name;
    };

    return (
        <div className="c-preview-landing-page__student">
            <Avatar
                artifact={student.artifact}
            />

            <div className="c-preview-landing-page__student-name">
                {student.firstName} {student.lastName}
            </div>

            <div className="c-preview-landing-page__student-hometown">
                {renderLocation()}
            </div>
        </div>
    );
};

export default PreviewLandingPageStudent;
