import React from "react";

import Avatar from "../../../../../../../components/Avatar";
import Card from "../../../../../../../components/Card";
import {DetailModalProps} from '../../index';
import FormColumn from "../../../../../../../components/FormColumn";
import FormRow from "../../../../../../../components/FormRow";
import H6 from "../../../../../../../components/H6";

enum CreatorType {
    ContentCreator = 'content_creator',
    Engineering = 'engineering',
    SchoolAdmin = 'school_admin',
    Student = 'student',
    SuperAdmin = 'super_admin',
    TenantAdmin = 'tenant_admin',
    TenantTeam = 'tenant_team',
}

const MostActiveGroupDetailModal: React.FC<DetailModalProps> = ({data}) => {
    const convertCreatorTypeToHumanReadableFormat = (creatorType: CreatorType) => {
        switch (creatorType) {
            case CreatorType.ContentCreator:
            case CreatorType.Engineering:
            case CreatorType.SchoolAdmin:
            case CreatorType.SuperAdmin:
            case CreatorType.TenantAdmin:
            case CreatorType.TenantTeam:
                return 'Administrator'
            case CreatorType.Student:
                return 'Student'
            default:
                return 'Unknown'
        }
    }

    return (
        <>
            <Card>
                <div className='dashboard-card__detail-main-col'>
                    <div>
                        <H6>
                            {data?.forumTopic?.name}
                        </H6>
                        <div className='dashboard-card__detail-row-muted'>
                            {data.forumTopic?.tenant?.name || 'Unknown'}
                        </div>
                    </div>

                    <Avatar artifact={data.forumTopic?.artifact} />
                </div>

                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Description
                        </div>

                        {data.forumTopic?.description || 'None.'}
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Creator Type
                        </div>

                        {convertCreatorTypeToHumanReadableFormat(data?.forumTopic.creatorType)}
                    </FormColumn>
                </FormRow>

                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Privacy Type
                        </div>

                        {data?.forumTopic?.privacyType}
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Content Created
                        </div>

                        {data.postCount + ' posts'}
                    </FormColumn>
                </FormRow>
            </Card>

            <Card>
                <H6>
                    Members
                </H6>
                <FormRow columnCount={3}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Active
                        </div>

                        {data.activeMemberCount}
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Invited
                        </div>

                        {data.invitedMemberCount}
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Requested
                        </div>

                        {data.requestedMemberCount}
                    </FormColumn>
                </FormRow>

                <FormRow columnCount={2}>
                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Declined
                        </div>

                        {data.declinedMemberCount}
                    </FormColumn>

                    <FormColumn>
                        <div className='dashboard-card__detail-row-muted'>
                            Denied
                        </div>

                        {data.deniedMemberCount}
                    </FormColumn>
                </FormRow>
            </Card>
        </>
    )
}

export default MostActiveGroupDetailModal
