import './GridMarketplaceItem.scss';

import React from 'react';
import classNames from "classnames";

import MarketplaceItem from "../../../types/MarketplaceItem";

import buildImageSrc from "../../../utils/buildImageSrc";
import { formatAsDollarAmount } from "../../../utils/utils";

import Avatar from "../../Avatar";
import Card from "../../Card";
import CardVideo from "../../CardVideo";
import FeedFlaggedBanner from "../../Feed/FeedFlaggedBanner";
import FlaggedIcon from "../../Icon/FlaggedIcon";
import H6 from "../../H6";

type Props = {
    disablePending?: boolean
    item: MarketplaceItem
    moreMenuComponent?: Function
    onClick?: Function
}

const GridMarketplaceItem: React.FC<Props> = ({
    disablePending,
    item,
    moreMenuComponent,
    onClick,
}) => {
    const isNotActive = item.status !== 'A' && disablePending;

    const containerClasses = classNames(
        'c-grid-marketplace-item c-grid-list__item',
        {
            'a-grid-list__item--inactive': isNotActive,
            'a-grid-list__item--flagged' : item.flagged,
            'a-grid-item__item--removed-by-mods': item.removedByMods,
        }
    );

    const imageClasses = classNames(
        'c-grid-marketplace-item__image-container c-grid-list__item__image-container',
        {
            'item--removed-by-mods': item.removedByMods,
            'item--super-approved': item.superApproval,
        }
    );

    return (
        <Card
            className={containerClasses}
            onClick={() => {
                if(isNotActive || !onClick) {
                    return null;
                }

                onClick(item)
            }}
        >
            <div className={imageClasses}>
                <div className="c-grid-list__item__image" style={item.artifacts && item.artifacts[0] ? {backgroundImage: `url(${buildImageSrc(item.artifacts[0], '667')})`} : null} >
                    {item.artifacts && item.artifacts[0] && item.artifacts[0].videoMp4Url && (
                        <CardVideo videoUrl={item.artifacts[0].videoMp4Url} />
                    )}
                </div>
                <FeedFlaggedBanner item={item} />
            </div>

            {item.flagged && (
                <FlaggedIcon className="c-grid-list__item__flagged" />
            )}

            <div className="c-grid-marketplace-item__content c-grid-list__item__content">
                <H6 className="c-grid-marketplace-item__title c-grid-list__item__title">
                    {item.title}
                </H6>

                {(item.freeItem === 'Y' || item.price != null) && (
                    <div className="c-grid-marketplace-item__poll c-grid-list__item__description">
                        {item.freeItem === 'Y' ? 'FREE' : formatAsDollarAmount(item.price)}
                    </div>
                )}

                {item.profile && (
                    <div className="c-grid-marketplace-item__profile c-grid-list__item__profile">
                        <Avatar artifact={item.profile.artifact} />

                        {item.profile.firstName} {item.profile.lastName}
                    </div>
                )}
            </div>

            {!item.removedByMods && moreMenuComponent(item)}
        </Card>
    );
};

export default GridMarketplaceItem;
