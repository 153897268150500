import React from 'react';
import TableMoreMenu from "../../../../../components/Table/TableMoreMenu";
import { PostStatuses } from "../../../../../utils/enums";
import NewsStory from "../../../../../types/NewsStory";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";
import { deleteNewsStory, setNewsStory } from "../../../../../store/slices/news";

type Props = {
    getAfterDelete?: () => void
    item: NewsStory
    setShowNewContentModal: (show: boolean) => void
}

const NewsStoryTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    item,
    setShowNewContentModal,
}) => {
    const dispatch = useAppDispatch();

    const { isDeletingNewsStory } = useTypedSelector((state) => state.news);

    const handleDelete = async (item) => {
        try {
            await dispatch(deleteNewsStory({newsStory: item})).unwrap();
            getAfterDelete && getAfterDelete();
        } catch(err) {
            console.log('ListNewsStories handleDelete err', err);
        }
    };

    const handleEditNewsStory = (item) => {
        dispatch(setNewsStory(item));
        setShowNewContentModal(true);
    };

    return (
        <TableMoreMenu
            hideModerationOptions
            isDeleting={isDeletingNewsStory}
            item={item}
            onDeleteClick={() => handleDelete(item)}
            onEditClick={() => handleEditNewsStory(item)}
            showAttachToPushNotificationOption={item.publishedAt != null || item.status === PostStatuses.Active}
        />
    );
};

export default NewsStoryTableMoreMenu;
