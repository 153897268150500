import React from 'react';

import { useTypedSelector } from '../../../../store/reducers';

import CreateEditGivingOpportunityForm from "./CreateEditGivingOpportunityForm";

type Props = {
    close?: Function
}

const CreateEditGivingOpportunity: React.FC<Props> = ({
                                                        close
                                                    }) => {
    const { givingOpportunity } = useTypedSelector(store => store.giving);

    return (
        <>
            <CreateEditGivingOpportunityForm
                close={close}
                givingOpportunity={givingOpportunity}
            />
        </>
    )
}
export default CreateEditGivingOpportunity;
