import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";
import { io, Socket } from 'socket.io-client';
import _uniqBy from "lodash/uniqBy";
import { orderBy as _orderBy } from "lodash";

import Artifact from "../../types/Artifact";
import Group from "../../types/Group";
import GroupChatMessage from "../../types/GroupChatMessage";
import IError from "../../types/IError";
import { RootState } from '../reducers';
import UnreadGroupChatsMetadata from "../../types/UnreadGroupChatsMetadata";

import { BeforeOrAfter, WebsocketStatuses } from "../../utils/enums";
import Request from "../../utils/request";
import environment from "../../utils/environment";
import PATHS from "../../utils/paths";
import { buildErrorObject } from "../../utils/errors";
import { generateUnreadGroupChatsMetadata } from "../../utils/generators";
import { isArrayNullOrEmpty } from "../../utils/utils";
import { cloneMessagesWithError } from "../../utils/reducerHelpers";

import { addError } from "./errors";

/*
 * WEBSOCKET FUNCTIONALITY UP TOP
 * FOLLOWED BY REST OF THUNKS
 */

interface ServerToClientEvents {
    'connect_failed': () => void
    'error': (data: any) => void
    'forum_topic_chat/message': (data: MessageReceivedFromWebsocketPayloadProps) => void
}

interface ClientToServerEvents {
    'forum_topic_chat/subscribe': (data: {forumTopicId: number, v?: number}, callback: (result: {success: boolean}) => void) => void
    'forum_topic_chat/unsubscribe': (data: {forumTopicId: number, v?: number}, callback: (result: {success: boolean}) => void) => void
}

let socket: Socket<ServerToClientEvents, ClientToServerEvents>;
export { socket };

export const connectToWebsocket = createAsyncThunk(
    'groupChat/connectToWebsocket',
    async (_, {dispatch, getState, rejectWithValue}) => {
        try {
            const { token } = (getState() as RootState).auth;

            socket = await io(`${environment.websocketUrl}${PATHS.groupChat.connectToWebsocket()}`, {
                auth: {token},
                transports: ['websocket'],
            });

            await socket.connect();

            socket.on('error', (data) => {
                //console.log('Error in websocket request: ', data);
            });

            socket.on('connect_failed', () => {
                //console.log("Sorry, there seems to be an issue with the connection!");
                dispatch(setWebsocketStatus('connect_failed'));
            })

            socket.on('connect_error', (err) => {
                //console.log("connect_error", JSON.stringify(err));
                dispatch(setWebsocketStatus('connect_error'));
            })

            socket.on('connect', () => {
                dispatch(setWebsocketStatus('connected'));
            });

            socket.on('disconnect', (reason) => {
                //console.log('disconnected', reason);
                dispatch(setWebsocketStatus('disconnected'));
            });

            socket.on('forum_topic_chat/message', (data) => {
                //console.log('forum_topic_chat/message', data);
                dispatch(processNewMessage(data));
            });

            socket.io.on("close", () => {
                //console.log('socket closed')
            });

            socket.io.on("reconnect", () => {
                dispatch(reconnectToWebsocket());
            });

            return {};
        } catch(err: any) {
            console.log('connectToWebsocket err', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'We were unable to connect to this group chat. Please try again.',
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
);

export const reconnectToWebsocket = createAsyncThunk(
    'groupChat/reconnectToWebsocket',
    async (_, {dispatch, getState}) => {
        let subs = (getState() as RootState).groupChat.forumTopicIdSubscriptions;

        if (!isArrayNullOrEmpty(subs)){
            let topicId = subs[0];
            await dispatch(unsubscribeFromMessages({forumTopicId: topicId}));
            setTimeout(() => {
                dispatch(subscribeToMessages({forumTopicId: topicId}));
            }, 1000);
        }
    }
);

type SubscribeToMessagesProps = {
    forumTopicId: number
}

export const subscribeToMessages = createAsyncThunk(
    'groupChat/subscribeToMessages',
    async ({forumTopicId}: SubscribeToMessagesProps, {dispatch, getState, rejectWithValue}) => {
        //console.log('subscribeToMessages', forumTopicId);
        try {
            if (!socket) {
                await dispatch(connectToWebsocket());
            } else if(!socket.connected) {
                //console.log('not connected, attempting')
                await socket.connect();
            }

            // unsub from all lingering subscriptions
            let subs = clone((getState() as RootState).groupChat.forumTopicIdSubscriptions);
            if(!isArrayNullOrEmpty(subs)) {
                subs.forEach(async (sub) => {
                    await dispatch(unsubscribeFromMessages({forumTopicId: sub}));
                });

                subs = [];
            }

            socket.emit('forum_topic_chat/subscribe', { forumTopicId, v: 2 }, result => {
                //console.log('subscribe result: ', result, socket);
                subs.push(forumTopicId);
                dispatch(setForumTopicIdSubscriptions(subs));
            });
        } catch(err: any) {
            console.log('subscribeToMessages err', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'Error subscribing to messages in this feed. Please refresh and try again.',
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
);

type UnsubscribeFromMessagesProps = {
    forumTopicId: number
}

export const unsubscribeFromMessages = createAsyncThunk(
    'groupChat/unsubscribeFromMessages',
    async ({forumTopicId}: UnsubscribeFromMessagesProps, {dispatch, getState}) => {
        await socket.emit('forum_topic_chat/unsubscribe', {forumTopicId, v: 2}, (result: {success: boolean}) => {
            if(result.success === true) {
                let subs = (getState() as RootState).groupChat.forumTopicIdSubscriptions;
                subs = subs.filter((sub) => sub !== forumTopicId);
                dispatch(setForumTopicIdSubscriptions(subs));
            }
        });

        return {success: true};
    }
);




type GetGroupChatMessagesProps = {
    forumTopicId?: number
}

export const getGroupChatMessages = createAsyncThunk(
    'groupChat/getGroupChatMessages',
    async ({forumTopicId}: GetGroupChatMessagesProps, {dispatch, getState, rejectWithValue}) => {
        try {
            const request = new Request((getState() as RootState).auth.token);

            const schoolId = (getState() as RootState).schools.activeSchool.tenantId;

            if(!forumTopicId) {
                forumTopicId = (getState() as RootState).groups.group.forumTopicId;
            }

            const { activeForumTopicId } = (getState() as RootState).groupChat;

            if(forumTopicId === activeForumTopicId && (getState() as RootState).groupChat.rawMessages.length > 0) {
                dispatch(updateGroupChatMessages({forumTopicId, beforeOrAfter: BeforeOrAfter.After}));
                return {isInitialGet: false};
            }

            let res = await request.get(PATHS.groupChat.getMessages(schoolId, forumTopicId));

            return { activeForumTopicId: forumTopicId, rawMessages: res.data.data.items, isInitialGet: true, atEnd: res.data.data.items.length === 0 };
        } catch (err: any) {
            console.log('getGroupChatMessages action error', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'We were unable to load this group chat. Please try again.',
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
);


type MarkGroupChatAsReadProps = {
    forumTopicMessageId: number
}

export const markGroupChatAsRead = createAsyncThunk(
    'groupChat/markGroupChatAsRead',
    async ({forumTopicMessageId}: MarkGroupChatAsReadProps, {dispatch, getState, rejectWithValue}) => {
        try {
            let latestRead = (getState() as RootState).groups.group?.myForumTopicProfile?.latestReadMessageId;
            const schoolId = (getState() as RootState).schools.activeSchool.tenantId;

            if(!forumTopicMessageId || (latestRead && latestRead >= forumTopicMessageId)) {
                return;
            }

            const request = new Request((getState() as RootState).auth.token);

            const { forumTopicId } = (getState() as RootState).groups.group;

            await request.post(PATHS.groupChat.markAsRead(schoolId, forumTopicId, forumTopicMessageId));

            return { forumTopicId };
        } catch (err: any) {
            console.log('markGroupChatAsRead action error', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'We were unable to mark these messages as read. Please try again.',
            });
            return rejectWithValue(errorObject);
        }
    }
);



type MessageReceivedFromWebsocketPayloadProps = {
    data: { message: GroupChatMessage };
    type: string
    message: GroupChatMessage
    forumTopicMessageId?: number
}

export const processNewMessage = createAsyncThunk(
    'groupChat/processNewMessage',
    async (data: MessageReceivedFromWebsocketPayloadProps, {dispatch, getState}) => {
        switch (data.type) {
            case 'message':
                dispatch(messageReceivedFromWebsocket(data));
                break;
            case 'error':
                dispatch(messageFailedToSend(data));
                break;
            case 'delete':
                console.log('delete message', data.forumTopicMessageId);
                dispatch(deleteLocalMessage(data.forumTopicMessageId));
                break;
            default:
                break;
        }
    }
);

type MessageReceivedFromWebsocketProps = {
    message: GroupChatMessage,
    pending?: boolean,
}

export const messageReceivedFromWebsocket = createAsyncThunk(
    'groupChat/messageReceivedFromWebsocket',
    async ({message, pending}: MessageReceivedFromWebsocketProps, {dispatch, getState, rejectWithValue}) => {
        try {

            // Using array spread instead of clone because if a message has a pending image
            // it will be a blob, which can't be cloned
            let rawMessages = [...(getState() as RootState).groupChat.rawMessages];

            const foundIndex = rawMessages.findIndex((m) => m.forumTopicMessageId === message.forumTopicMessageId);

            if(foundIndex !== -1) {
                // Replace the matched message, but not if it's a temporary, pending message
                if (!message.pending) {
                    rawMessages[foundIndex] = message;
                }
            } else {
                rawMessages.unshift(message);
            }

            if (!pending) {
                dispatch(markGroupChatAsRead({forumTopicMessageId: message.forumTopicMessageId}));
            }

            return { rawMessages };
        } catch (err: any) {
            console.log('messageReceivedFromWebsocket action error', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'We were unable to mark these messages as read. Please try again.',
            });
            return rejectWithValue(errorObject);
        }
    }
);

export const sendGroupChatMessage = createAsyncThunk(
    'groupChat/sendGroupChatMessage',
    async (_, {dispatch, getState, rejectWithValue}) => {
        try {
            const request = new Request((getState() as RootState).auth.token);
            const schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            const { forumTopicId } = (getState() as RootState).groups.group;
            const { newMessage, newMessageArtifact, newMessageRecentMedia } = (getState() as RootState).groupChat;

            const res = await request.post(PATHS.groupChat.sendMessage(schoolId, forumTopicId), {body: newMessage, artifactId: newMessageArtifact?.artifactId});


            dispatch(messageReceivedFromWebsocket({message: {...res.data.data}, pending: true}));

            return {message: res.data};
        } catch (err: any) {
            console.log('sendGroupChatMessage action error', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'We were unable to send your message to the group chat. Please try again.',
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
);

export const deleteGroupChatMessage = createAsyncThunk(
    'groupChat/deleteGroupChatMessage',
    async (forumTopicMessageId: number, {dispatch, getState, rejectWithValue}) => {
        try {
            const request = new Request((getState() as RootState).auth.token);

            const schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            const { forumTopicId } = (getState() as RootState).groups.group;

            await request.delete(PATHS.groupChat.deleteMessage(schoolId, forumTopicId, forumTopicMessageId));

            return {forumTopicMessageId};
        } catch (err: any) {
            console.log('deleteGroupChatMessage action error', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'We were unable to delete this message. Please try again.',
            });
            return rejectWithValue(errorObject);
        }
    }
);


type UpdateGroupChatMessagesProps = {
    beforeOrAfter?: BeforeOrAfter
    forumTopicId?: number
}

export const updateGroupChatMessages = createAsyncThunk(
    'groupChat/updateGroupChatMessages',
    async ({beforeOrAfter, forumTopicId}: UpdateGroupChatMessagesProps, {dispatch, getState, rejectWithValue}) => {
        try {
            const request = new Request((getState() as RootState).auth.token);
            const schoolId = (getState() as RootState).schools.activeSchool.tenantId;

            if(!forumTopicId) {
                forumTopicId = (getState() as RootState).groups.group.forumTopicId;
            }

            if(!beforeOrAfter) {
                beforeOrAfter = BeforeOrAfter.Before;
            }

            let rawMessages = clone((getState() as RootState).groupChat.rawMessages);

            let forumTopicMessageId = rawMessages[rawMessages.length - 1].forumTopicMessageId;

            if(beforeOrAfter === BeforeOrAfter.After) {
                forumTopicMessageId = rawMessages[0].forumTopicMessageId;
            }

            let res = await request.get(PATHS.groupChat.getMessages(schoolId, forumTopicId, beforeOrAfter, forumTopicMessageId));

            rawMessages = [
                ...rawMessages,
                ...res.data.items,
            ];

            rawMessages = _uniqBy(rawMessages, 'forumTopicMessageId');
            rawMessages = _orderBy(rawMessages, ['forumTopicMessageId'], ['desc']);

            return { activeForumTopicId: forumTopicId, rawMessages, isInitialGet: false, atEnd: res.data.items.length === 0 };
        } catch (err: any) {
            console.log('updateGroupChatMessages action error', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'We were unable to load this group chat. Please try again.',
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
);

interface GroupChatState {
    activeForumTopicId?: number
    deleteMessageError?: IError
    forumTopicIdSubscriptions: Array<number>
    getMessagesError?: IError
    getUnreadGroupChatsError?: IError
    groupChatInitialized: boolean
    isGettingMessages: boolean
    isGettingUnreadGroupChats: boolean
    isMarkingGroupChatAsRead: boolean
    isDeletingMessage: boolean
    isSendingMessage: boolean
    isTogglingGroupChatPushNotifications: boolean
    markGroupChatAsReadError?: IError
    newMessage: string
    newMessageArtifact?: Artifact
    newMessageRecentMedia?: Artifact
    rawMessages: Array<GroupChatMessage>
    sendMessageError?: IError
    toggleGroupChatPushNotificationsError?: IError
    unreadGroupChats: Array<{
        forumTopic: Group
        latestMessageId: number
        unreadCount: number
    }>
    unreadGroupChatsMetadata: UnreadGroupChatsMetadata
    websocketStatus: WebsocketStatuses
}

const initialState: GroupChatState = {
    activeForumTopicId: undefined,
    deleteMessageError: undefined,
    groupChatInitialized: false,
    newMessage: '',
    newMessageArtifact: undefined,
    newMessageRecentMedia: undefined,
    rawMessages: [],
    forumTopicIdSubscriptions: [],
    unreadGroupChats: [],
    unreadGroupChatsMetadata: generateUnreadGroupChatsMetadata(),
    websocketStatus: WebsocketStatuses.NotInitialized,
    isDeletingMessage: false,
    isGettingMessages: false,
    isGettingUnreadGroupChats: false,
    isMarkingGroupChatAsRead: false,
    isSendingMessage: false,
    isTogglingGroupChatPushNotifications: false,
    getMessagesError: undefined,
    getUnreadGroupChatsError: undefined,
    markGroupChatAsReadError: undefined,
    sendMessageError: undefined,
    toggleGroupChatPushNotificationsError: undefined,
};

export const groupChatSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        clearMessages: (state) => {
            console.log('clearMessages action called (groupChatSlice)');
            state.activeForumTopicId = undefined;
            state.rawMessages = [];
            state.getMessagesError = undefined;
            state.sendMessageError = undefined;
        },
        clearNewMessage: (state) => {
            state.newMessage = '';
            state.newMessageArtifact = undefined;
            state.newMessageRecentMedia = undefined;
        },
        deleteLocalMessage: (state, action) => {
            state.rawMessages = state.rawMessages.filter((message) => {
                return message.forumTopicMessageId !== action.payload;
            });
        },
        messageFailedToSend: (state, action) => {
            let errorObject = buildErrorObject({
                serverError: action.payload.error,
                friendlyMessage: 'This message failed to send. Please try again.',
            });
            state.rawMessages = cloneMessagesWithError(state.rawMessages, {...action.payload, errorObject})
            state.newMessageArtifact = undefined;
            state.newMessageRecentMedia = undefined;
        },
        setForumTopicIdSubscriptions: (state, action) => {
            state.forumTopicIdSubscriptions = action.payload;
        },
        setGroupChatInitialized: (state, action) => {
            state.groupChatInitialized = action.payload;
        },
        setNewMessage: (state, action) => {
            state.newMessage = action.payload;
        },
        setNewMessageArtifact: (state, action) => {
            state.newMessageArtifact = action.payload;
        },
        setNewMessageRecentMedia: (state, action) => {
            state.newMessageRecentMedia = action.payload;
        },
        setWebsocketStatus: (state, action) => {
            state.websocketStatus = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(getGroupChatMessages.pending, (state) => {
            state.getMessagesError = undefined;
            state.isGettingMessages = true;
        });
        addCase(getGroupChatMessages.fulfilled, (state, action) => {
            state.rawMessages = action.payload.rawMessages;
            state.isGettingMessages = false;
        });
        addCase(getGroupChatMessages.rejected, (state, action) => {
            state.getMessagesError = action.payload as IError;
            state.isGettingMessages = false;
        });
        addCase(markGroupChatAsRead.pending, (state, action) => {
            state.markGroupChatAsReadError = undefined;
            state.isMarkingGroupChatAsRead = true;
        });
        addCase(markGroupChatAsRead.fulfilled, (state, action) => {
            const foundIndex = state.unreadGroupChats.findIndex((gc) => gc.forumTopic.forumTopicId === action.payload?.forumTopicId);
            if(foundIndex !== -1) {
                state.unreadGroupChats = state.unreadGroupChats.filter((gc) => gc.forumTopic.forumTopicId !== action.payload?.forumTopicId);
                state.unreadGroupChatsMetadata = {
                    ...state.unreadGroupChatsMetadata,
                    total: state.unreadGroupChatsMetadata.total - 1
                };
            }
            state.isMarkingGroupChatAsRead = false;
        });
        addCase(markGroupChatAsRead.rejected, (state, action) => {
            state.markGroupChatAsReadError = action.payload as IError;
            state.isMarkingGroupChatAsRead = false;
        });

        addCase(messageReceivedFromWebsocket.fulfilled, (state, action) => {
            state.rawMessages = action.payload.rawMessages;
        });

        addCase(sendGroupChatMessage.pending, (state, action) => {
            state.sendMessageError = undefined;
            state.isSendingMessage = true;
        });
        addCase(sendGroupChatMessage.fulfilled, (state, action) => {
            state.newMessage = '';
            state.newMessageArtifact = undefined;
            state.newMessageRecentMedia = undefined;
            state.isSendingMessage = false;
        });
        addCase(sendGroupChatMessage.rejected, (state, action) => {
            state.sendMessageError = action.payload as IError;
            state.isSendingMessage = false;
        });

        addCase(deleteGroupChatMessage.pending, (state, action) => {
            state.deleteMessageError = undefined;
            state.isDeletingMessage = true;
        });
        addCase(deleteGroupChatMessage.fulfilled, (state, action) => {
            state.isDeletingMessage = false;
            state.rawMessages = state.rawMessages.filter((message) => {
                return message.forumTopicMessageId !== action.payload.forumTopicMessageId;
            });
        });
        addCase(deleteGroupChatMessage.rejected, (state, action) => {
            state.deleteMessageError = action.payload as IError;
            state.isDeletingMessage = false;
        });

        addCase(updateGroupChatMessages.pending, (state) => {
            state.getMessagesError = undefined;
        });
        addCase(updateGroupChatMessages.fulfilled, (state, action) => {
            state.activeForumTopicId = action.payload.activeForumTopicId;
            state.rawMessages = action.payload.rawMessages;
            state.isGettingMessages = false;
        });
        addCase(updateGroupChatMessages.rejected, (state, action) => {
            state.getMessagesError = action.payload as IError;
            state.isGettingMessages = false;
        });
    }
});

export const {
    clearMessages,
    clearNewMessage,
    deleteLocalMessage,
    messageFailedToSend,
    setForumTopicIdSubscriptions,
    setGroupChatInitialized,
    setNewMessage,
    setNewMessageArtifact,
    setNewMessageRecentMedia,
    setWebsocketStatus,
} = groupChatSlice.actions;

export default groupChatSlice.reducer;
