import './Checkbox.scss';

import React from 'react';

import FormValidationMessage from '../FormValidationMessage';
import Label from '../Label';
import Validation from "../../types/Validation";

type Props = {
    checked: boolean
    className?: string
    disabled?: boolean
    id: string
    label: string | React.ReactNode
    name: string
    onChange: Function
    validation?: Validation
}

const Checkbox: React.FC<Props> = ({
    checked,
    className = '',
    disabled,
    id,
    label,
    name,
    onChange,
    validation
}) => (
    <div className={`o-checkbox ${className}`}>
        <input
            checked={checked}
            className="c-checkbox"
            disabled={disabled}
            id={id}
            name={name}
            onChange={(event) => onChange(event)}
            type="checkbox"
        />

        <Label htmlFor={id}>{label}</Label>

        {validation?.show && <FormValidationMessage>{validation.error.reason}</FormValidationMessage>}
    </div>
);

export default Checkbox;
