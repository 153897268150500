import './LandingPageEventSummary.scss';

import React from 'react';
import moment from 'moment';

import Button from "../../../../../../../components/Button";
import H5 from "../../../../../../../components/H5";
import Icon from "../../../../../../../components/Icon";
import TableRowImage from "../../../../../../../components/Table/TableRowImage";

type Props = {
    handleDelete: Function
    post: EventPost | {post: EventPost}
}

const LandingPageEventSummary: React.FC<Props> = ({
    handleDelete,
    post,
}) => {
    let postData = post.post || post;

    const renderDate = () => {
        let dateString = moment(postData.startAt).format('MMM DD, YYYY [•] h:mm A');

        if(postData.endAt) {
            if(moment(postData.startAt).format('MM DD') === moment(postData.endAt).format('MM DD')) {
                dateString += ` - ${moment(postData.endAt).format('h:mm A')}`;
            } else {
                dateString += ` - ${moment(postData.endAt).format('MMM DD, YYYY [•] h:mm A')}`;
            }

        }

        return dateString;
    };

    return (
        <div className="c-landing-page-event-summary">
            <div className="c-landing-page-event-summary__drag-target">
                <Icon type="drag" />
            </div>

            <TableRowImage
                artifact={postData.artifacts && postData.artifacts[0]}
                className="c-landing-page-event-summary__image"
            />

            <div className="c-landing-page-event-summary__content">
                <H5>
                    {postData.title}
                </H5>

                {postData.startAt != null && (
                    <div className="c-landing-page-event-summary__description">
                        {renderDate()}
                    </div>
                )}
            </div>

            <Button
                className="c-landing-page-event-summary__delete-button"
                noStyles
                onClick={() => handleDelete()}
            >
                <Icon type="delete" />
            </Button>
        </div>
    );
};

export default LandingPageEventSummary;
