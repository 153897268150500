import './UpdateGivingStatus.scss';

import React, { useEffect, useState } from 'react';
import clone from 'clone';
import { validated } from 'react-custom-validation';

import { AppDispatch } from '../../../../../store';
import { useTypedSelector } from '../../../../../store/reducers';
import {
    getSchool,
    setGivingStatusConfig,
    updateGivingStatus,
    UpdateGivingStatusProps,
} from '../../../../../store/slices/schools';

import Validation from '../../../../../types/Validation';

import { isEmail, isRequired } from '../../../../../utils/validations';

import Button from '../../../../../components/Button';
import ButtonRow from '../../../../../components/ButtonRow';
import FormError from '../../../../../components/FormError';
import FormRow from '../../../../../components/FormRow';
import Textbox from '../../../../../components/Textbox';
import SuccessModal from '../../../../../components/Modal/SuccessModal';
import ToggleSwitch from '../../../../../components/ToggleSwitch';
import Form from '../../../../../components/Form';

type UpdateGivingStatusFormProps = {
    dispatch: AppDispatch
    config: UpdateGivingStatusProps
    $field: Function
    $fieldEvent: Function
    $submit: Function
    $validation: {
        active: Validation
        billingContactEmailAddress: Validation
        minimumDonationUsdCents: Validation
    }
}

enum FieldNames {
    BillingContactEmailAddress = 'billingContactEmailAddress',
    Active = 'active',
    MinimumDonationUsdCents = 'minimumDonationUsdCents',
}

const UpdateGivingStatusForm: React.FC<UpdateGivingStatusFormProps> = ({
   config,
   dispatch,
   $field,
   $fieldEvent,
   $submit,
   $validation,
}) => {
    const {activeSchool} = useTypedSelector(state => state.schools);
    const {
        isUpdatingGivingStatus,
        updateGivingStatusError,
    } = useTypedSelector(state => state.schools);

    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

    useEffect(() => {
        if (activeSchool) {
            const clonedConfig: UpdateGivingStatusFormProps['config'] = clone(config);
            clonedConfig.active = activeSchool.givingActive;

            if (activeSchool.billingContactEmailAddress) {
                clonedConfig.billingContactEmailAddress = activeSchool.billingContactEmailAddress;
            }

            if (activeSchool.minimumDonationUsdCents) {
                clonedConfig.minimumDonationUsdCents = activeSchool.minimumDonationUsdCents / 100; // convert to dollars

            }

            dispatch(setGivingStatusConfig(clonedConfig));
        }
    }, [activeSchool]);

    const handleCloseModal = async () => {
        await dispatch(getSchool({schoolId: activeSchool.tenantId, fromServer: true})).unwrap();
        setShowSuccessModal(false);
    };

    const handleSave = async (isValid) => {
        if (!isValid) {
            return;
        }
        try {
            setDisableSubmit(true);
            await dispatch(updateGivingStatus()).unwrap();
            $fieldEvent('reset');
            setDisableSubmit(false);
            setShowSuccessModal(true);
        } catch (err) {
            console.log('UpdateGivingStatusForm handleSave err', err);
        }
    };

    const handleChange = (name: FieldNames, value: number | string | boolean) => {
        const clonedConfig: UpdateGivingStatusFormProps['config'] = clone(config);

        switch (true) {
            case name === FieldNames.Active:
                clonedConfig.active = value as boolean;
                break;
            case name === FieldNames.BillingContactEmailAddress:
                clonedConfig.billingContactEmailAddress = value as string;
                break;
            case name === FieldNames.MinimumDonationUsdCents:
                clonedConfig.minimumDonationUsdCents = parseInt(value as any);
                break;
            default:
                break;
        }

        dispatch(setGivingStatusConfig(clonedConfig));
    };

    return (
        <Form>
            <FormRow className="update-giving-status__add-text-container">
                <ToggleSwitch
                    checked={config.active}
                    label="Activate Giving Features"
                    name="givingActive"
                    onClick={() => handleChange(FieldNames.Active, !config.active)}
                />
            </FormRow>

            <FormRow className="update-giving-status__add-text-container">
                <Textbox
                    id="emailAddressTextbox"
                    className="update-giving-status__form-item"
                    disabled={!config.active}
                    label="Minimum Donation Amount (USD)"
                    placeholder={0}
                    type={'number'}
                    validation={$validation.minimumDonationUsdCents}
                    value={config.minimumDonationUsdCents}

                    {...$field(
                        FieldNames.MinimumDonationUsdCents, (event) => handleChange(FieldNames.MinimumDonationUsdCents, event.target.value),
                    )}
                />
            </FormRow>

            <FormRow className="update-giving-status__add-text-container">
                <Textbox
                    id="emailAddressTextbox"
                    className="update-giving-status__form-item"
                    disabled={!config.active}
                    label="Billing Contact Email Address"
                    type="email"
                    placeholder="jane@email.edu, john@email.edu, etc."
                    validation={$validation.billingContactEmailAddress}
                    value={config.billingContactEmailAddress || ''}
                    {...$field(
                        FieldNames.BillingContactEmailAddress, (event) => handleChange(FieldNames.BillingContactEmailAddress, event.target.value),
                    )}
                />
            </FormRow>


            <ButtonRow className="activity-report-generator__button-row">
                <Button
                    disabled={disableSubmit}
                    showActivityIndicator={isUpdatingGivingStatus}
                    onClick={(e) => {
                        e.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                >
                    Submit
                </Button>
            </ButtonRow>

            <FormError error={updateGivingStatusError}/>

            <SuccessModal
                buttonOnClick={handleCloseModal}
                show={showSuccessModal}
            >
                <p>
                    Your request to enable the giving feature has been received.
                </p>
                <p>
                    Our billing partner, Stripe, will send an email to the address provided to complete the setup
                    process momentarily. If you do not receive it within 5 minutes, please revisit this page to end
                    another invite.
                </p>
            </SuccessModal>

        </Form>
    );
};

const updateGivingStatusFormConfig = (props: UpdateGivingStatusFormProps) => {
    const {active, billingContactEmailAddress, minimumDonationUsdCents} = props.config;

    return {
        fields: ['active', 'billingContactEmailAddress', 'minimumDonationUsdCents', 'refreshUrl', 'returnUrl'],
        validations: {
            active: [
                [isRequired, active],
            ],
            billingContactEmailAddress: [
                [isRequired, billingContactEmailAddress],
                [isEmail, billingContactEmailAddress, 'Please enter a valid email address.'],
            ],
            minimumDonationUsdCents: [
                [isRequired, minimumDonationUsdCents, 'Please enter a minimum donation amount'],
            ],
        },
    };
};

export default validated(updateGivingStatusFormConfig)(UpdateGivingStatusForm);
