import React, { useEffect } from 'react';
import {useSelector} from "react-redux";

const Partners = () => {
    const { activeSchool } = useSelector(store => store.schools);

    useEffect(() => {
        // Get Partners data
    }, []);

    return (
        <div>
            <h1>{activeSchool.name} Partners</h1>
        </div>
    )
}
export default Partners;
