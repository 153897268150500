import './ListTypeSelector.scss';

import React from 'react';
import Button from "../Button";
import Icon from "../Icon";
import { ListTypes } from "../../utils/enums";

type Props = {
    onListTypeSelected: Function
    selected: ListTypes
}

const ListTypeSelector: React.FC<Props> = ({
    onListTypeSelected,
    selected
}) => {
    return (
        <div className="c-list-type-selector">
            <Button
                className={`c-list-type-selector__button ${selected === ListTypes.Table ? 'a-list-type-selector__button--active' : ''}`}
                onClick={() => onListTypeSelected(ListTypes.Table)}
                noStyles
            >
                <Icon type="list-type-table" />
            </Button>

            <Button
                className={`c-list-type-selector__button ${selected === ListTypes.Grid ? 'a-list-type-selector__button--active' : ''}`}
                onClick={() => onListTypeSelected(ListTypes.Grid)}
                noStyles
            >
                <Icon type="list-type-grid" />
            </Button>
        </div>
    );
};

export default ListTypeSelector;
