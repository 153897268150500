import React from 'react';

import { useTypedSelector } from '../../../../store/reducers';

import CreateEditMarketplaceItemForm from "./CreateEditMarketplaceItemForm";

type Props = {
    close?: Function
}

const CreateEditMarketplaceItem: React.FC<Props> = ({
    close
}) => {
    const { marketplaceItem } = useTypedSelector(store => store.marketplace);

    return (
        <>
            <CreateEditMarketplaceItemForm
                close={close}
                marketplaceItem={marketplaceItem}
            />
        </>
    )
}
export default CreateEditMarketplaceItem;
