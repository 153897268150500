import React from 'react';

import { deleteClassNote, setClassNote } from '../../../../../store/slices/classNotes';
import { useAppDispatch } from '../../../../../store';
import { useTypedSelector } from '../../../../../store/reducers';

import ClassNote from '../../../../../types/ClassNote';

import TableMoreMenu from '../../../../../components/Table/TableMoreMenu';

type Props = {
    getAfterDelete?: () => Promise<any>
    getAfterModerate?: () => Promise<any>
    item: ClassNote
    setShowNewContentModal: Function
}

const ClassNotesTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    getAfterModerate,
    item,
    setShowNewContentModal,
}) => {
    const dispatch = useAppDispatch();

    const {isDeletingClassNote} = useTypedSelector((state) => state.classNotes);

    const handleDelete = async (classNote) => {
        try {
            await dispatch(deleteClassNote({classNote})).unwrap();

            if(getAfterDelete) {
                getAfterDelete();
            }
        } catch(err) {
            console.log('ClassNotesTableMoreMenu handleDelete err', err);
        }
    };

    const handleEdit = (classNote: ClassNote) => {
        dispatch(setClassNote(classNote));
        setShowNewContentModal(true);
    };

    return (
        <TableMoreMenu
            getAfterModerate={getAfterModerate}
            isDeleting={isDeletingClassNote}
            item={item}
            onDeleteClick={() => handleDelete(item)}
            onEditClick={() => handleEdit(item)}
            showAttachToPushNotificationOption={item.publishedAt != null || item.status === 'A'}
        />
    );
};

export default ClassNotesTableMoreMenu;
