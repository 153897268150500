import './ListLandingPages.scss';

import React, { useEffect, useMemo, useState } from 'react';
import clone from 'clone';
import { useHistory, useRouteMatch } from "react-router";
import moment from "moment";

import TableColumn from "../../../../../types/TableColumn";

import { isArrayNullOrEmpty } from "../../../../../utils/utils";

import {
    clearLandingPagesMetadata,
    deleteLandingPage,
    getLandingPages,
    setLandingPage
} from "../../../../../store/slices/landingPages";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import A from "../../../../../components/A";
import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Button, { ButtonSizes } from "../../../../../components/Button";
import H5 from "../../../../../components/H5";
import Icon from "../../../../../components/Icon";
import PageHeader from "../../../../../components/PageHeader";
import TableFilterRow from "../../../../../components/Table/TableFilterRow";
import Table from "../../../../../components/Table";
import TableMoreMenu from "../../../../../components/Table/TableMoreMenu";
import TableRowImage from "../../../../../components/Table/TableRowImage";

const ListLandingPages: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState(false);

    const { isDeletingLandingPage, isGettingLandingPages, landingPages, landingPagesMetadata } = useTypedSelector((state) => state.landingPages);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getLandingPages({schoolId: params?.schoolId})).unwrap();
            } catch(err) {
                console.log('ListLandingPages initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        if(!initialized && !isGettingLandingPages) {
            initialize();
        }

        return () => {
            dispatch(clearLandingPagesMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Welcome',
                    Cell: ({row: { original }}) => (
                        <div
                            className="landing-pages__welcome-container"
                            onClick={() => handleEdit(original)}
                        >
                            {original.welcomeMessage}
                        </div>
                    )
                }, {
                    Header: 'Last Updated',
                    accessor: (d) => moment(d.updatedAt).format('yyyy/MM/DD [at] hh:mma'),
                }, {
                    Header: 'Expires At',
                    accessor: (d) => d.endAt ? moment(d.endAt).format('yyyy/MM/DD [at] hh:mma') : '--',
                }, {
                    Header: 'Image',
                    Cell: ({row: { original }}) => (
                        <TableRowImage
                            artifact={original.artifact}
                        />
                    ),
                    hasImage: true
                }, {
                    Header: 'Targets',
                    accessor: (d) => d.assignedTargets?.length || 0 ,
                }, {
                    Header: 'Student Groups',
                    accessor: (d) => d.studentForumTopicIds?.length || 0 ,
                }, {
                    Header: 'School Groups',
                    accessor: (d) => d.adminForumTopicIds?.length || 0 ,
                }, {
                    Header: 'Events',
                    accessor: (d) => d.eventIds?.length || 0 ,
                }, {
                    Header: 'Discussions',
                    accessor: (d) => d.threadIds?.length || 0 ,
                }, {
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original)
                }
            ];

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const data = useMemo(
        () => {
            return landingPages;
        },
        [landingPages]
    );

    const handleDelete = async (item) => {
        try {
            await dispatch(deleteLandingPage({landingPage: item})).unwrap();
            onMetadataChange({}, false);
        } catch(err) {
            console.log('ListLandingPages handleDelete err', err);
        }
    };

    const handleEdit = (item) => {
        dispatch(setLandingPage(item));
        history.push(`/school/${params.schoolId}/${params.profileType}/landing-page/${item.landingPageId}`);
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(landingPagesMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getLandingPages({isUpdate, landingPagesMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('ListLandingPages onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item) => (
        <TableMoreMenu
            isDeleting={isDeletingLandingPage}
            item={item}
            onDeleteClick={() => handleDelete(item)}
            onEditClick={() => handleEdit(item)}
        />
    );

    return (
        <div className="landing-pages">
            <PageHeader>
                <H5>
                    <Icon type="landing-page" />

                    Landing Pages
                </H5>
            </PageHeader>

            <TableFilterRow>
                <div style={{flex: 1}} />

                <Button
                    size={ButtonSizes.Small}
                    to={`/school/${params.schoolId}/${params.profileType}/landing-page/new`}
                >
                    New Landing Page
                </Button>
            </TableFilterRow>

            {isGettingLandingPages ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                            No landing pages to display. <A
                                to={`/school/${params.schoolId}/${params.profileType}/landing-page/new`}
                            >
                                Create One?
                            </A>
                        </>
                    ) : (
                        <Table
                            columns={columns}
                            data={data}
                            getData={onMetadataChange}
                            pageNumber={landingPagesMetadata.page_num}
                            pageSize={landingPagesMetadata.page_size}
                            sortBy={landingPagesMetadata.sort}
                            sortOrder={landingPagesMetadata.order}
                            totalNumberOfItems={landingPagesMetadata.total}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ListLandingPages;
