import React from 'react';
import { validated } from 'react-custom-validation';

import Validation from "../../../../../../types/Validation";

import { handleTextChange } from '../../../../../../utils/handle-changes';
import { isEmailCsv, isRequired } from '../../../../../../utils/validations';

import { inviteStudentsViaEmailList, setInviteStudentsViaEmailListCsv } from "../../../../../../store/slices/students";
import { useAppDispatch } from '../../../../../../store';
import { useTypedSelector } from '../../../../../../store/reducers';

import Button, { ButtonThemes } from '../../../../../../components/Button';
import Form from '../../../../../../components/Form';
import FormError from '../../../../../../components/FormError';
import FormRow from '../../../../../../components/FormRow';
import Textbox from '../../../../../../components/Textbox';

type Props = {
    emails: string
    onCancel: Function
    onSuccess?: Function
    $field: Function
    $fieldEvent: Function
    $validation: {
        emails: Validation
    }
    $submit: Function
};

const InviteStudentsViaEmailForm: React.FC<Props> = (
    {
        emails,
        onCancel,
        onSuccess,
        $field,
        $fieldEvent,
        $validation,
        $submit
    }
) => {
    const dispatch = useAppDispatch();

    const {isInvitingStudentsViaEmailList, inviteStudentsViaEmailListError} = useTypedSelector(store => store.students);

    const handleChange = (name: string, value: string) => {
        dispatch(setInviteStudentsViaEmailListCsv(value));
    };

    const handleSubmit = async (isValid: boolean) => {
        if (isValid) {
            try {
                await dispatch(inviteStudentsViaEmailList({emailCsv: emails})).unwrap();
                if (onSuccess) {
                    onSuccess();
                } else {
                    onCancel();
                }
                $fieldEvent('reset');
            } catch (err) {
                console.log('InviteStudentsViaEmailForm handleSubmit err', err);
            }
        }
    };

    return (
        <Form className="invite-form">
            <FormRow>
                <Textbox
                    id="txtEmailAddress"
                    label="Email Addresses"
                    name="inviteString"
                    required
                    type="textarea"
                    validation={$validation.emails}
                    value={emails}
                    {...$field('emails', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormError
                error={inviteStudentsViaEmailListError}
            />

            <div className="c-modal__button-wrapper">
                <Button
                    className="c-modal__confirm-button"
                    disabled={!$validation.emails.isValid}
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false))
                    }}
                    showActivityIndicator={isInvitingStudentsViaEmailList}
                >
                    Send Invites
                </Button>

                <Button
                    className="c-modal__decline-button"
                    onClick={onCancel}
                    theme={ButtonThemes.Secondary}
                >
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

function inviteStudentsViaEmailFormValidationConfig(props) {
    const {emails} = props;

    return {
        fields: ['emails'],
        validations: {
            emails: [
                [isRequired, emails],
                [isEmailCsv, emails]
            ]
        }
    }
}

export default validated(inviteStudentsViaEmailFormValidationConfig)(InviteStudentsViaEmailForm);
