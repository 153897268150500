import React from 'react';
import AdmitDmDashboard from './Dm';
import AdmitConversionDashboard from './Conversion';

const AdmitDashboard: React.FC = () => {
    return (
        <>
            <AdmitConversionDashboard />
            <AdmitDmDashboard />
        </>
    );
}

export default AdmitDashboard;
