import './PostingAsCard.scss';

import React from 'react';

import Profile from "../../types/Profile";
import User from "../../types/User";

import { isObjectNullOrEmpty } from "../../utils/utils";

import ProfileSummary from "../ProfileSummary";

type Props = {
    profile: Profile
    user: User
}

const PostingAsCard: React.FC<Props> = ({
    profile,
    user,
}) => {
    if(isObjectNullOrEmpty(profile)) {
        return null;
    }

    return (
        <div className="c-posting-as-card">
            {!isObjectNullOrEmpty(user) && (
                <ProfileSummary profile={user} />
            )}

            <div className="c-posting-as-card__text">
                posting as
            </div>

            <ProfileSummary profile={profile} />
        </div>
    );
};

export default PostingAsCard;
