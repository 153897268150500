import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clone from 'clone';
import { useRouteMatch } from 'react-router';

import {
    clearClassNote,
    clearClassNotesMetadata,
    getClassNotes,
    setSearchTerm,
} from '../../../../store/slices/classNotes';
import { useAppDispatch } from '../../../../store';

import ClassNote from "../../../../types/ClassNote";
import TableColumn from '../../../../types/TableColumn';

import { isArrayNullOrEmpty } from '../../../../utils/utils';
import { ListTypes } from '../../../../utils/enums';
import { useTypedSelector } from '../../../../store/reducers';

import ActivityIndicator from '../../../../components/ActivityIndicator';
import Button, { ButtonSizes, ButtonThemes } from '../../../../components/Button';
import ClassNotesTableMoreMenu from './ClassNotesTableMoreMenu';
import CreateEditClassNote from '../../../Content/ClassNotes/CreateEditClassNote';
import GridList from '../../../../components/GridList';
import H5 from '../../../../components/H5';
import Icon from '../../../../components/Icon';
import ListTypeSelector from '../../../../components/ListTypeSelector';
import NewContentModal from '../../../../components/NewContentModal';
import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';
import TableFilterRow from '../../../../components/Table/TableFilterRow';
import TableFilterButtons from '../../../../components/Table/TableFilterButtons';
import TableFilterRowDivider from '../../../../components/Table/TableFilterRow/TableFilterRowDivider';
import TablePublishedAt from '../../../../components/Table/TablePublishedAt';
import TableRowImage from '../../../../components/Table/TableRowImage';
import TableSearch from '../../../../components/TableSearch';

type Props = {
    disablePending?: boolean
    onRowClick?: Function
}

const SchoolClassNotes: React.FC<Props> = ({
    disablePending,
    onRowClick
}) => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const [initialized, setInitialized] = useState(false);
    const [selectedListType, setSelectedListType] = useState<ListTypes>(ListTypes.Table);
    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const {isGettingClassNotes, classNotes, classNotesMetadata, searchTerm} = useTypedSelector((state) => state.classNotes);
    const { activeSchool } = useTypedSelector((state) => state.schools);
    const { profile: { userId: myUserId } } = useTypedSelector((state) => state.auth);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getClassNotes({schoolId: match?.params?.schoolId})).unwrap();
            } catch(err) {
                console.log('SchoolClassNotes initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            setShowNewContentModal(false);
            dispatch(clearClassNote());
            dispatch(clearClassNotesMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Image',
                    Cell: ({row: { original }}) => (
                        <TableRowImage
                            artifact={!isArrayNullOrEmpty(original.artifacts) ? original.artifacts[0] : original.artifact}
                            flagged={original.flagged}
                        />
                    ),
                    hasImage: true
                }, {
                    Header: 'Title',
                    accessor: 'title',
                    sortBy: 'title'
                }, {
                    Header: 'Note',
                    accessor: 'description',
                    sortBy: 'description'
                }, {
                    Header: 'Creator',
                    accessor: (d) => d.profile ? `${d.profile.firstName} ${d.profile.lastName} ${d.profile?.profileId === activeSchool?.postAsProfile?.profileId ? '(you)' : ''}` : null,
                    sortBy: 'profileFirstName'
                }, {
                    Header: 'Date',
                    accessor: (d) => <TablePublishedAt post={d} />,
                    sortBy: 'publish'
                }, {
                    Header: 'Views',
                    accessor: 'totalViews',
                }
            ];

            if(!onRowClick) {
                columns.push({
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original)
                });
            }

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onRowClick]
    );

    const data = useMemo(
        () => {
            return classNotes;
        },
        [classNotes]
    );

    const handleRowClick = ({original}) => {
        if(!onRowClick) {
            return null;
        }

        onRowClick(original);
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(classNotesMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getClassNotes({isUpdate, classNotesMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('SchoolClassNotes onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item: ClassNote) => (
        <ClassNotesTableMoreMenu
            getAfterDelete={() => onMetadataChange({}, false)}
            getAfterModerate={() => onMetadataChange({}, false)}
            item={item}
            setShowNewContentModal={setShowNewContentModal}
        />
    );

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="school-deals">
            <PageHeader>
                <H5>
                    <Icon type="classnote" />

                    Class Notes
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableFilterButtons
                    buttons={[{
                        isActive: !classNotesMetadata.created_by_user_id,
                        onClick: () => onMetadataChange({
                            created_by_user_id: null,
                            my_posts: null,
                            other_posts: null,
                            page: 0,
                        }, false),
                        text: "All Notes"
                    }, {
                        isActive: classNotesMetadata.created_by_user_id === myUserId && classNotesMetadata.my_posts === true,
                        onClick: () => onMetadataChange({
                            created_by_user_id: myUserId,
                            my_posts: true,
                            other_posts: null,
                            page: 0,
                        }, false),
                        text: "My Notes"
                    }, {
                        isActive: classNotesMetadata.created_by_user_id === myUserId && classNotesMetadata.other_posts === true,
                        onClick: () => onMetadataChange({
                            created_by_user_id: myUserId,
                            other_posts: true,
                            my_posts: null,
                            page: 0,
                        }, false),
                        text: "Other Notes"
                    }]}
                />

                <TableFilterRowDivider />

                <ListTypeSelector
                    onListTypeSelected={(listType) => setSelectedListType(listType)}
                    selected={selectedListType}
                />

                <TableFilterRowDivider />

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0, search: searchTerm}, false)}
                    placeholder="Search Class Notes"
                    searchTerm={searchTerm}
                    size="small"
                />

                <div className="school-deals__header-right-col">

                    {!onRowClick && (
                        <Button
                            onClick={() => setShowNewContentModal(true)}
                            size={ButtonSizes.Small}
                        >
                            New Class Note
                        </Button>
                    )}
                </div>
            </TableFilterRow>



            {isGettingClassNotes ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                            No class notes to display. <Button
                            onClick={() => setShowNewContentModal(true)}
                            theme={ButtonThemes.Link}
                        >
                            Create One?
                        </Button>
                        </>
                    ) : (
                        <>
                            {selectedListType === ListTypes.Table ? (
                                <Table
                                    columns={columns}
                                    data={data}
                                    disablePending={disablePending}
                                    getData={onMetadataChange}
                                    onRowClick={onRowClick && handleRowClick}
                                    pageNumber={classNotesMetadata.page_num}
                                    pageSize={classNotesMetadata.page_size}
                                    sortBy={classNotesMetadata.sort}
                                    sortOrder={classNotesMetadata.order}
                                    totalNumberOfItems={classNotesMetadata.total}
                                />
                            ) : (
                                <GridList
                                    createCardButtonOnClick={() => setShowNewContentModal(true)}
                                    createCardButtonText="Create a Deal"
                                    createCardText="Offer discounts, promotions, and other specials to students around campus"
                                    disablePending={disablePending}
                                    isLastPage={Math.ceil(classNotesMetadata.total / classNotesMetadata.page_size) === classNotesMetadata.page_num + 1}
                                    items={data}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: classNotesMetadata.page_num + 1})}
                                    onOverlayClick={onRowClick ? (item) => onRowClick(item) : null}
                                    onPreviousPageClick={() => onMetadataChange({page_num: classNotesMetadata.page_num - 1})}
                                    overlayButtonText={onRowClick ? 'Select' : 'Edit'}
                                    pageNumber={classNotesMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                <CreateEditClassNote
                    close={() => setShowNewContentModal(false)}
                />
            </NewContentModal>
        </div>
    );
};

export default SchoolClassNotes;
