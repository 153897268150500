import React from 'react';
import UpdateGivingStatus from './UpdateGivingStatus';

const Giving: React.FC = () => {
    return (
        <div>
            <h1>Giving</h1>

            <UpdateGivingStatus />
        </div>
    );
}

export default Giving;
