import './PreviewLandingPageThread.scss';

import React from 'react';

import Thread from "../../../../../../../../../types/Thread";

import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../../../../../../../../utils/utils";
import buildImageSrc from "../../../../../../../../../utils/buildImageSrc";

import Avatar from "../../../../../../../../../components/Avatar";
import Date from '../../../../../../../../../components/Date';
import Icon from "../../../../../../../../../components/Icon";

type Props = {
    item: Thread
}

const PreviewLandingPageThread: React.FC<Props> = ({
    item
}) => {
    const post = item;

    return (
        <div className="c-preview-landing-page__thread">
            {post.profile && (
                <div className="c-preview-landing-page__thread-profile">
                    <Avatar
                        artifact={post.profile.artifact}
                    />

                    <div className="c-preview-landing-page__thread-profile-text">
                        <div className="c-preview-landing-page__thread-profile-name">
                            {post.profile.firstName} {post.profile.lastName}
                        </div>

                        <div className="c-preview-landing-page__thread-topic-date-container">
                            {!isObjectNullOrEmpty(post.forumTopic) && (
                                <div className="c-preview-landing-page__thread-topic-container">
                                    in <strong>{post.forumTopic.name}</strong> •
                                </div>
                            )}

                            <Date date={post.createdAt} />

                            {!isObjectNullOrEmpty(post.poll) && <Icon type="poll" />}
                        </div>
                    </div>
                </div>
            )}

            {post.title && (
                <div className="c-preview-landing-page__thread-title">
                    {post.title}
                </div>
            )}

            {post.description && (
                <div className="c-preview-landing-page__thread-description">
                    {post.description}
                </div>
            )}

            {!isArrayNullOrEmpty(post.artifacts) && (
                <div className="c-preview-landing-page__thread-artifact">
                    <img src={buildImageSrc(post.artifacts[0], '568')} alt="Thread preview" />
                </div>
            )}
        </div>
    );
};

export default PreviewLandingPageThread;
