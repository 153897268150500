import React, { useEffect, useState } from 'react';

import moment from "moment";
import { useHistory, useRouteMatch } from "react-router";

import { getRecentActivity } from '../../../../../store/slices/recent-activity';
import { useAppDispatch } from '../../../../../store';
import { useTypedSelector } from '../../../../../store/reducers';

import ActivityIndicator from '../../../../../components/ActivityIndicator';
import Card from '../../../../../components/Card';
import Avatar from '../../../../../components/Avatar';
import Button, { ButtonSizes, ButtonThemes } from '../../../../../components/Button';
import Icon from '../../../../../components/Icon';

type Props = {
    limit?: number
}

const RecentActivity: React.FC<Props> = ({limit}) => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();
    const history = useHistory();

    const [truncateRecentActivities, setTruncateRecentActivities] = useState(true);
    const [loadedActivities, setLoadedActivities] = useState([]);

    const { isGettingRecentActivity, recentActivity } = useTypedSelector((state) => state.recentActivity);

    useEffect(() => {
        if(match?.params?.schoolId) {
            getActivity(match.params.schoolId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params?.schoolId]);

    const getActivity = async (schoolId) => {
        if(isGettingRecentActivity) {
            return;
        }

        try {
            await dispatch(getRecentActivity({schoolId}));

        } catch(err) {
            console.log('RecentActivity getStudents err', err);
        }
    }

    useEffect(() => {
        if (limit) {
            setLoadedActivities(recentActivity.slice(0, limit));
        } else {
            setLoadedActivities(recentActivity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentActivity]);

    useEffect(() => {
        if (truncateRecentActivities) {
            setLoadedActivities(recentActivity.slice(0, limit));
        } else {
            setLoadedActivities(recentActivity);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [truncateRecentActivities]);

    const renderHistoryUrl = (item) => {
        if (item.forumTopic) {
            return `/school/${match?.params?.schoolId}/group/${item.forumTopic.forumTopicId}/activity`
        }
        else if (item.subjectPost) {
            return `/school/${match?.params?.schoolId}/group/${item.subjectPost.forumTopicId}/activity`
        }
        else {
            return ''
        }
    }

    const navOnClick = (item, type: 'profile' | 'group' ) => {
        console.log("Item: ", item)
        if (type === 'profile') {
            return history.push(`/school/${match?.params?.schoolId}/${match?.params?.profileType}/students/${item.activityInitiator?.profileId}/posts`);
        }
        else {
            return history.push(renderHistoryUrl(item));
        }
    }


    return (
        <Card
            className="c-recent-activity"
            title="Student Engagement"
        >
            {isGettingRecentActivity ? (
                <ActivityIndicator />
            ) : (
                <>
                    <div className="c-activity-items">
                        {loadedActivities.map((activityItem, index) => {
                            if(!activityItem) {
                                return null;
                            }

                            return (
                                <div key={index} className="c-activity-item">
                                    <div role="link" onClick={() => navOnClick(activityItem, 'profile')}>
                                        <Avatar className="c-avatar" artifact={activityItem.activityInitiator.artifact}/>
                                    </div>
                                    <div className="c-activity-item__data">
                                        <strong role="link" onClick={() => navOnClick(activityItem, 'profile')}>{activityItem.activityInitiator.firstName + ' ' + activityItem.activityInitiator.lastName}</strong>
                                        {' ' + activityItem.activityString + ' '}
                                        <strong  role="link" onClick={() => navOnClick(activityItem, 'group')}>{activityItem.forumTopic ? activityItem.forumTopic.name : activityItem.subjectPost?.title}</strong>
                                        {activityItem.subjectPost ? (
                                            activityItem.subjectPost.title ? ': ' + activityItem.subjectPost.title : activityItem.subjectPost.description ? ': ' + activityItem.subjectPost.description : ''
                                        ) : (
                                            ''
                                        )}
                                        <div className="c-activity-item__meta">{activityItem.subjectProfile.firstName + ' ' + activityItem.subjectProfile.lastName} • {moment(activityItem.createdAt).fromNow()}</div>
                                    </div>
                                </div>
                            );
                        })}
                        {(limit && recentActivity.length > limit) && (
                            <Button
                                className="c-activity-alert-items__more-button"
                                theme={ButtonThemes.Light}
                                size={ButtonSizes.Small}
                                onClick={() => setTruncateRecentActivities(!truncateRecentActivities)}
                                disabled={loadedActivities.length < limit }
                            >
                                View {truncateRecentActivities ? 'More' : 'Less'}
                                {truncateRecentActivities && (<Icon type="caret-down"/>)}
                            </Button>
                        )}
                    </div>

                </>
            )}
        </Card>
    );
};

export default RecentActivity;
