import './Card.scss';

import React from 'react';

type Props = {
    children: React.ReactNode
    className?: string,
    onClick?: Function
    title?: string
};

const Card: React.FC<Props> = ({
    children,
    className = '',
    onClick,
    title,
}) => {
    return (
        <div
            className={`c-card ${className}`}
            onClick={() => onClick && onClick()}
        >
            {title && (
                <h2 className="c-card__title">
                    {title}
                </h2>
            )}

            {children}
        </div>
    );
};

export default Card;
