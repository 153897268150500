import './LandingPageGroupSummary.scss';

import React from 'react';
import Icon from "../../../../../../../components/Icon";
import TableRowImage from "../../../../../../../components/Table/TableRowImage";
import Button from "../../../../../../../components/Button";
import H5 from "../../../../../../../components/H5";

type Props = {
    disableReordering?: boolean
    group: Group | {forumTopic: Group}
    handleDelete: Function
}

const LandingPageGroupSummary: React.FC<Props> = ({
    disableReordering,
    group,
    handleDelete,
}) => {
    let groupData = group.forumTopic || group;

    return (
        <div className={`c-landing-page-group-summary ${disableReordering ? 'a-landing-page-group-summary--disabled-reordering' : ''}`}>
            {!disableReordering && (
                <div className="c-landing-page-group-summary__drag-target">
                    <Icon type="drag" />
                </div>
            )}

            <TableRowImage
                artifact={groupData.artifact}
                className="c-landing-page-group-summary__image"
                placeholder="/img/abuzz-group-placeholder.svg"
            />

            <div className="c-landing-page-group-summary__content">
                <H5>
                    {groupData.name}
                </H5>

                {groupData.description != null && (
                    <div className="c-landing-page-group-summary__description">
                        {groupData.description}
                    </div>
                )}
            </div>

            <Button
                className="c-landing-page-group-summary__delete-button"
                noStyles
                onClick={() => handleDelete()}
            >
                <Icon type="delete" />
            </Button>
        </div>
    );
};

export default LandingPageGroupSummary;
