import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import IError from "../../types/IError";
import LandingPage from "../../types/LandingPage";
import Metadata from "../../types/Metadata";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import { generateLandingPage, generateLandingPagesMetadata } from "../../utils/generators";
import { buildErrorObject } from "../../utils/errors";
import { isArrayNullOrEmpty } from "../../utils/utils";

import { addError } from "./errors";
import { RootState } from '../reducers';

type DeleteLandingPageProps = {
    landingPage?: LandingPage
}

export const deleteLandingPage = createAsyncThunk(
    'landingPages/deleteLandingPages',
    async ({landingPage}: DeleteLandingPageProps = {}, {dispatch, getState, rejectWithValue}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!landingPage) {
                landingPage = (getState() as RootState).landingPages.landingPage;
            }

            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.landingPages.delete(tenantId, landingPage.landingPageId));
            return res;
        } catch(err) {
            console.log('deleteLandingPage error', err);
            let friendlyMessage = 'Error deleting this landing page. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type GetLandingPageProps = {
    landingPageId: number | string
    schoolId?: number | string
}

export const getLandingPage = createAsyncThunk(
    'landingPages/getLandingPage',
    async ({landingPageId, schoolId}: GetLandingPageProps, {dispatch, getState, rejectWithValue}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            const res = await new Request(token).get(PATHS.landingPages.getById(schoolId, landingPageId));
            return res.data.data;
        } catch(err) {
            console.log('getLandingPage error', err);
            let friendlyMessage = 'Error getting this landing page from the server. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
);

type GetLandingPagesProps = {
    isUpdate?: boolean
    schoolId?: number | string
    landingPagesMetadata?: Metadata
}

export const getLandingPages = createAsyncThunk(
    'landingPages/getLandingPages',
    async ({isUpdate, schoolId, landingPagesMetadata}: GetLandingPagesProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!landingPagesMetadata) {
                landingPagesMetadata = clone((getState() as RootState).landingPages.landingPagesMetadata);
            } else {
                landingPagesMetadata = {...landingPagesMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.landingPages.get(schoolId, buildQueryString(landingPagesMetadata)));
            let landingPages = res.data.data.items;
            landingPagesMetadata.total = res.data.data.meta.total;
            return { landingPages, landingPagesMetadata };
        } catch(err) {
            console.log('getLandingPages error', err);
            let friendlyMessage = 'Error getting a list of past templates. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type SaveLandingPageProps = {
    landingPage?: LandingPage
}

export const saveLandingPage = createAsyncThunk(
    'landingPages/saveLandingPage',
    async({landingPage}: SaveLandingPageProps, {getState, rejectWithValue}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId } } } = (getState() as RootState);

        if(!landingPage) {
            landingPage = clone((getState() as RootState).landingPages.landingPage);
        } else {
            landingPage = {...landingPage};
        }


        let path = PATHS.landingPages.create(tenantId);
        let request = new Request(token);
        let reqFunc = request.post;

        if(landingPage.landingPageId) {
            path = PATHS.landingPages.update(tenantId, landingPage.landingPageId);
            reqFunc = request.put;
        }

        if(landingPage.adminForumTopics) {
            landingPage.adminForumTopicIds = landingPage.adminForumTopics.map((item) => item.forumTopicId);
        }

        if(landingPage.events) {
            landingPage.eventIds = landingPage.events.map((item) => item.postId);
        }

        if(landingPage.studentForumTopics) {
            landingPage.studentForumTopicIds = landingPage.studentForumTopics.map((item) => item.forumTopicId);
        }

        if(landingPage.threads) {
            landingPage.threadIds = landingPage.threads.map((item) => item.postId);
        }

        delete landingPage.adminForumTopics;
        delete landingPage.events;
        delete landingPage.studentForumTopics;
        delete landingPage.threads;

        if(landingPage.assignedTargets) {
            let targetForumTopicIds = [];
            /*let targetTagIds = [];
            let targetProfileImportIds = [];*/
            landingPage.assignedTargets.forEach((at) => {
                if(at.forumTopicId) {
                    targetForumTopicIds.push(at.forumTopicId);
                }/* else if(at.tagId) {
                    targetTagIds.push(at.tagId);
                } else if(at.profileImportId) {
                    targetProfileImportIds.push(at.profileImportId);
                }*/
            });

            if(!isArrayNullOrEmpty(targetForumTopicIds)) {
                landingPage.targetForumTopicIds = targetForumTopicIds;
            }

            /*if(!isArrayNullOrEmpty(targetTagIds)) {
                landingPage.targetTagIds = targetTagIds;
            }

            if(!isArrayNullOrEmpty(targetProfileImportIds)) {
                landingPage.targetProfileImportIds = targetProfileImportIds;
            }*/

            delete landingPage.assignedTargets;
        }

        try {
            const res = await reqFunc(path, landingPage);
            return res.data;
        } catch(err) {
            console.log('saveLandingPage error', err);
            let friendlyMessage = 'Error saving your landing page. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
)

interface LandingPagesState {
    deleteLandingPageError?: IError
    getLandingPageError?: IError
    getLandingPagesError?: IError
    isDeletingLandingPage: boolean
    isGettingLandingPage: boolean
    isGettingLandingPages: boolean
    isSavingLandingPage: boolean
    landingPage: LandingPage
    landingPages: Array<LandingPage>
    landingPagesMetadata: Metadata
    saveLandingPageError?: IError
}

const initialState: LandingPagesState = {
    landingPage: generateLandingPage(),
    landingPages: [],
    landingPagesMetadata: generateLandingPagesMetadata(),
    isDeletingLandingPage: false,
    isGettingLandingPage: false,
    isGettingLandingPages: false,
    isSavingLandingPage: false,
    deleteLandingPageError: undefined,
    getLandingPageError: undefined,
    getLandingPagesError: undefined,
    saveLandingPageError: undefined,
};

export const landingPagesSlice = createSlice({
    name: 'landingPages',
    initialState,
    reducers: {
        clearLandingPage: (state) => {
            state.landingPage = generateLandingPage();
        },
        clearLandingPagesMetadata: (state) => {
            state.landingPagesMetadata = generateLandingPagesMetadata();
        },
        setLandingPage: (state, action) => {
            state.landingPage = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteLandingPage.pending, (state) => {
            state.deleteLandingPageError = undefined;
            state.isDeletingLandingPage = true;
        });
        addCase(deleteLandingPage.fulfilled, (state, action) => {
            state.isDeletingLandingPage = false;
            state.landingPage = generateLandingPage();
        });
        addCase(deleteLandingPage.rejected, (state, action) => {
            state.deleteLandingPageError = action.error as IError;
            state.isDeletingLandingPage = false;
        });

        addCase(getLandingPage.pending, (state, action) => {
            state.getLandingPageError = undefined;
            state.isGettingLandingPage = true;
        });
        addCase(getLandingPage.fulfilled, (state, action) => {
            state.landingPage = action.payload;
            state.isGettingLandingPage = false;
        });
        addCase(getLandingPage.rejected, (state, action) => {
            state.getLandingPageError = action.error;
            state.isGettingLandingPage = false;
        });

        addCase(getLandingPages.pending, (state, action) => {
            state.getLandingPagesError = undefined;
            state.isGettingLandingPages = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.landingPagesMetadata) {
                state.landingPagesMetadata = action.meta.arg.landingPagesMetadata;
            }
        });
        addCase(getLandingPages.fulfilled, (state, action) => {
            state.landingPages = action.payload.landingPages;
            state.landingPagesMetadata = action.payload.landingPagesMetadata;
            state.isGettingLandingPages = false;
        });
        addCase(getLandingPages.rejected, (state, action) => {
            state.getLandingPagesError = action.error;
            state.isGettingLandingPages = false;
        });

        addCase(saveLandingPage.pending, (state, action) => {
            state.saveLandingPageError = undefined;
            state.isSavingLandingPage = true;
        });
        addCase(saveLandingPage.fulfilled, (state, action) => {
            state.landingPage = generateLandingPage();
            state.isSavingLandingPage = false;
        });
        addCase(saveLandingPage.rejected, (state, action) => {
            state.saveLandingPageError = action.error;
            state.isSavingLandingPage = false;
        });
    }
});

export const { clearLandingPage, clearLandingPagesMetadata, setLandingPage } = landingPagesSlice.actions;

export default landingPagesSlice.reducer;
