import './SchoolDeals.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from "react-router";
import clone from "clone";

import TableColumn from "../../../../types/TableColumn";

import { isArrayNullOrEmpty } from "../../../../utils/utils";
import { LIST_TYPES } from "../../../../utils/constants";
import { ListTypes } from '../../../../utils/enums';

import {
    clearDeal,
    clearDealsMetadata,
    getDeals,
    setSearchTerm
} from "../../../../store/slices/deals";
import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';

import ActivityIndicator from "../../../../components/ActivityIndicator";
import Button, { ButtonSizes, ButtonThemes } from "../../../../components/Button";
import CreateEditDeal from "../../../Content/Deals/CreateEditDeal";
import DealTableMoreMenu from "./DealTableMoreMenu";
import GridList from "../../../../components/GridList";
import H5 from "../../../../components/H5";
import Icon from "../../../../components/Icon";
import ListTypeSelector from "../../../../components/ListTypeSelector";
import NewContentModal from "../../../../components/NewContentModal";
import PageHeader from "../../../../components/PageHeader";
import Table from "../../../../components/Table";
import TablePublishedAt from '../../../../components/Table/TablePublishedAt';
import TableRowImage from "../../../../components/Table/TableRowImage";
import TableSearch from '../../../../components/TableSearch';
import TableFilterButtons from '../../../../components/Table/TableFilterButtons';
import TableFilterRow from "../../../../components/Table/TableFilterRow";
import TableFilterRowDivider from "../../../../components/Table/TableFilterRow/TableFilterRowDivider";

type Props = {
    disablePending?: boolean
    onRowClick?: Function
}

const SchoolDeals: React.FC<Props> = ({
    disablePending,
    onRowClick
}) => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const [initialized, setInitialized] = useState(false);
    const [selectedListType, setSelectedListType] = useState<ListTypes>(LIST_TYPES.TABLE as ListTypes);
    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const { isGettingDeals, deals, dealsMetadata, searchTerm } = useTypedSelector((state) => state.deals);
    const { activeSchool } = useTypedSelector((state) => state.schools);
    const { profile: { userId: myUserId } } = useTypedSelector((state) => state.auth);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getDeals({schoolId: match?.params?.schoolId})).unwrap();
            } catch(err) {
                console.log('SchoolDeals initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            setShowNewContentModal(false);
            dispatch(clearDeal());
            dispatch(clearDealsMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Deal Title',
                    accessor: 'title',
                    sortBy: 'title'
                }, {
                    Header: 'Creator',
                    accessor: (d) => d.profile ? `${d.profile.firstName} ${d.profile.lastName} ${d.profile?.profileId === activeSchool?.postAsProfile?.profileId ? '(you)' : ''}` : null,
                    sortBy: 'profileFirstName'
                }, {
                    Header: 'Date',
                    accessor: (d) => <TablePublishedAt post={d} />,
                    sortBy: 'publish'
                }, {
                    Header: 'Views',
                    accessor: 'totalViews',
                }, {
                    Header: 'Total',
                    accessor: 'totalRedemptions',
                }, {
                    Header: 'Unique Redeems',
                    accessor: 'uniqueRedemptions',
                }, {
                    Header: 'Active',
                    accessor: 'activeRedemptions',
                }, {
                    Header: 'Reset',
                    accessor: 'resetRedemptions',
                }, {
                    Header: 'Image',
                    Cell: ({row: { original }}) => (
                        <TableRowImage
                            artifact={!isArrayNullOrEmpty(original.artifacts) ? original.artifacts[0] : original.artifact}
                        />
                    ),
                    hasImage: true
                }
            ];

            if(!onRowClick) {
                columns.push({
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original)
                });
            }

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onRowClick]
    );

    const data = useMemo(
        () => {
            return deals;
        },
        [deals]
    );

    const handleRowClick = ({original}) => {
        if(!onRowClick) {
            return null;
        }

        onRowClick(original);
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(dealsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getDeals({isUpdate, dealsMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('SchoolDeals onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item) => (
        <DealTableMoreMenu
            getAfterDelete={() => onMetadataChange({}, false)}
            item={item}
            setShowNewContentModal={setShowNewContentModal}
        />
    );

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="school-deals">
            <PageHeader>
                <H5>
                    <Icon type="navigation-deals" />

                    Deals
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableFilterButtons
                    buttons={[{
                        isActive: !dealsMetadata.created_by_user_id,
                        onClick: () => onMetadataChange({
                            created_by_user_id: null,
                            my_posts: null,
                            other_posts: null,
                            page: 0,
                        }, false),
                        text: "All Deals"
                    }, {
                        isActive: dealsMetadata.created_by_user_id === myUserId && dealsMetadata.my_posts === true,
                        onClick: () => onMetadataChange({
                            created_by_user_id: myUserId,
                            my_posts: true,
                            other_posts: null,
                            page: 0,
                        }, false),
                        text: "My Deals"
                    }, {
                        isActive: dealsMetadata.created_by_user_id === myUserId && dealsMetadata.other_posts === true,
                        onClick: () => onMetadataChange({
                            created_by_user_id: myUserId,
                            other_posts: true,
                            my_posts: null,
                            page: 0,
                        }, false),
                        text: "Other Deals"
                    }]}
                />

                <TableFilterRowDivider />

                <ListTypeSelector
                    onListTypeSelected={(listType) => setSelectedListType(listType)}
                    selected={selectedListType}
                />

                <TableFilterRowDivider />

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0, search: searchTerm}, false)}
                    placeholder="Search Deals"
                    searchTerm={searchTerm}
                    size="small"
                />

                <div className="school-deals__header-right-col">
                    {/* <ToggleSwitch
                        checked={dealsMetadata.created_by_user_id === myUserId}
                        label="My Deals"
                        labelPosition="left"
                        name="created_by_user_id"
                        offText=" "
                        onClick={() => onMetadataChange(dealsMetadata.created_by_user_id === myUserId ? {created_by_user_id: null, my_posts: null} : {created_by_user_id: myUserId, my_posts: true})}
                        onText=" "
                    /> */}

                    {!onRowClick && (
                        <Button
                            onClick={() => setShowNewContentModal(true)}
                            size={ButtonSizes.Small}
                        >
                            New Deal
                        </Button>
                    )}
                </div>
            </TableFilterRow>



            {isGettingDeals ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                            No deals to display. <Button
                                onClick={() => setShowNewContentModal(true)}
                                theme={ButtonThemes.Link}
                            >
                                Create One?
                            </Button>
                        </>
                    ) : (
                        <>
                            {selectedListType === LIST_TYPES.TABLE ? (
                                <Table
                                    columns={columns}
                                    data={data}
                                    disablePending={disablePending}
                                    getData={onMetadataChange}
                                    onRowClick={onRowClick && handleRowClick}
                                    pageNumber={dealsMetadata.page_num}
                                    pageSize={dealsMetadata.page_size}
                                    sortBy={dealsMetadata.sort}
                                    sortOrder={dealsMetadata.order}
                                    totalNumberOfItems={dealsMetadata.total}
                                />
                            ) : (
                                <GridList
                                    createCardButtonOnClick={() => setShowNewContentModal(true)}
                                    createCardButtonText="Create a Deal"
                                    createCardText="Offer discounts, promotions, and other specials to students around campus"
                                    disablePending={disablePending}
                                    isLastPage={Math.ceil(dealsMetadata.total / dealsMetadata.page_size) === dealsMetadata.page_num + 1}
                                    items={data}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: dealsMetadata.page_num + 1})}
                                    onOverlayClick={onRowClick ? (item) => onRowClick(item) : null}
                                    onPreviousPageClick={() => onMetadataChange({page_num: dealsMetadata.page_num - 1})}
                                    overlayButtonText={onRowClick ? 'Select' : 'Edit'}
                                    pageNumber={dealsMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                <CreateEditDeal
                    close={() => setShowNewContentModal(false)}
                />
            </NewContentModal>
        </div>
    );
};

export default SchoolDeals;
