import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import IError from "../../types/IError";
import Resource from "../../types/Resource";

import { buildErrorObject } from "../../utils/errors";
import { generateResource } from '../../utils/generators';
import PATHS from "../../utils/paths";
import { ProfileTypes } from "../../utils/enums";
import Request from "../../utils/request";

import { addError } from "./errors";
import { RootState } from '../reducers';

type DeleteResourceProps = {
    resource?: Resource
}

export const deleteResource = createAsyncThunk(
    'resources/deleteResource',
    async ({resource}: DeleteResourceProps = {}, {dispatch, getState, rejectWithValue}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!resource) {
                resource = (getState() as RootState).resources.resource;
            }

            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.resources.delete(tenantId, resource.tenantResourceId));
            return {
                ...res,
                tenantResourceId: resource.tenantResourceId,
            };
        } catch(err) {
            console.log('delete resource err', err);
            let friendlyMessage = 'Error deleting this resource. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
)

type GetResourcesProps = {
    profileType: ProfileTypes
    schoolId?: number | string
}

export const getResources = createAsyncThunk(
    'resources/getResources',
    async ({profileType, schoolId}: GetResourcesProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.resources.get(schoolId, `${profileType}_visibility=true&page_size=10000&page_num=0`));
            let resources = res.data.data.items;
            return { resources };
        } catch(err) {
            console.log('getResources err', err);
            let friendlyMessage = 'Error getting the list of resources. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(err));
            return rejectWithValue(errorObject);
        }
    }
);

type ReorderResourcesProps = {
    profileType: ProfileTypes
    resourceIds?: Array<number>
}

export const reorderResources = createAsyncThunk(
    'resources/reorderResources',
    async({ profileType, resourceIds }: ReorderResourcesProps, {dispatch, getState, rejectWithValue}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!resourceIds) {
                resourceIds = (getState() as RootState).resources.resources.map((q: Resource) => q.tenantResourceId);
            }
            const res = await new Request((getState() as RootState).auth.token).put(PATHS.resources.reorder(tenantId), {ids: resourceIds, visibilityType: profileType});
            return res;
        } catch(err) {
            console.log('reorder resources error', err);
            let friendlyMessage = 'Error reordering your resources. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(err));
            return rejectWithValue(errorObject);
        }
    }
)

type SaveResourceProps = {
    resource?: Resource
}

export const saveResource = createAsyncThunk(
    'resources/saveResource',
    async({resource}: SaveResourceProps, {dispatch, getState, rejectWithValue}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId } } } = (getState() as RootState);

        if(!resource) {
            resource = (getState() as RootState).resources.resource;
        }

        let path = PATHS.resources.create(tenantId);
        let request = new Request(token);
        let reqFunc = request.post;
        let isUpdate = false;

        if(resource.tenantResourceId) {
            path = PATHS.resources.update(tenantId, resource.tenantResourceId);
            reqFunc = request.put;
            isUpdate = true;
        }

        try {
            const res = await reqFunc(path, resource);
            return { resource: res.data.data, isUpdate };
        } catch(err) {
            console.log('save resource error', err);
            let friendlyMessage = 'There was an error saving your resource. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
)

interface ResourceState {
    deleteResourceError?: IError
    getResourceError?: IError
    getResourcesError?: IError
    isDeletingResource: boolean
    isGettingResource: boolean
    isGettingResources: boolean
    isReorderingResources: boolean
    isSavingResource: boolean
    reorderResourcesError?: IError
    resource: Resource
    resources: Array<Resource>
    saveResourceError?: IError
}

const initialState: ResourceState = {
    resource: generateResource(),
    resources: [],
    isDeletingResource: false,
    isGettingResource: false,
    isGettingResources: false,
    isReorderingResources: false,
    isSavingResource: false,
    deleteResourceError: undefined,
    getResourceError: undefined,
    getResourcesError: undefined,
    reorderResourcesError: undefined,
    saveResourceError: undefined,
};

export const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        clearResource: (state) => {
            state.resource = generateResource();
        },
        setResource: (state, action) => {
            state.resource = action.payload;
        },
        setResources: (state, action) => {
            state.resources = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteResource.pending, (state) => {
            state.deleteResourceError = undefined;
            state.isDeletingResource = true;
        });
        addCase(deleteResource.fulfilled, (state, action) => {
            state.isDeletingResource = false;
            state.resource = generateResource();
            state.resources = state.resources.filter((q) => q.tenantResourceId !== action.payload.tenantResourceId);
        });
        addCase(deleteResource.rejected, (state, action) => {
            state.deleteResourceError = action.error as IError;
            state.isDeletingResource = false;
        });

        addCase(getResources.pending, (state, action) => {
            state.getResourcesError = undefined;
            state.isGettingResources = true;
        });
        addCase(getResources.fulfilled, (state, action) => {
            state.resources = action.payload.resources;
            state.isGettingResources = false;
        });
        addCase(getResources.rejected, (state, action) => {
            state.getResourcesError = action.error as IError;
            state.isGettingResources = false;
        });

        addCase(saveResource.pending, (state) => {
            state.saveResourceError = undefined;
            state.isSavingResource = true;
        });
        addCase(saveResource.fulfilled, (state, action) => {
            state.isSavingResource = false;
            if(action.payload.isUpdate) {
                const foundIndex = state.resources.findIndex((q) => q.tenantResourceId === action.payload.resource.tenantResourceId);
                if(foundIndex > -1) {
                    state.resources[foundIndex] = action.payload.resource;
                }
            } else {
                state.resources = [
                    ...state.resources,
                    action.payload.resource,
                ]
            }
            state.resource = generateResource();
        });
        addCase(saveResource.rejected, (state, action) => {
            state.saveResourceError = action.error as IError;
            state.isSavingResource = false;
        });
    }
});

export const { clearResource, setResource, setResources } = resourcesSlice.actions;

export default resourcesSlice.reducer;
