import './Sponsorships.scss';

import React, { useEffect, useMemo, useState } from 'react';
import clone from 'clone';

import { SPONSORSHIP_TYPES } from "../../../utils/constants";
import { isArrayNullOrEmpty } from "../../../utils/utils";

import { clearSponsorship, clearSponsorshipsMetadata, deleteSponsorship, getSponsorships, setSponsorship } from "../../../store/slices/sponsorships";
import { useAppDispatch } from "../../../store";
import { useTypedSelector } from "../../../store/reducers";

import Button, { ButtonSizes, ButtonThemes } from "../../../components/Button";
import ActivityIndicator from "../../../components/ActivityIndicator";
import Card from "../../../components/Card";
import CreateEditSponsorship from "./CreateEditSponsorship";
import H5 from "../../../components/H5";
import Icon from "../../../components/Icon";
import NewContentModal from "../../../components/NewContentModal";
import PageHeader from "../../../components/PageHeader";
import Table from "../../../components/Table";
import TableColumn from "../../../types/TableColumn";
import TableFilterRow from "../../../components/Table/TableFilterRow";
import TableFilterButtons from "../../../components/Table/TableFilterButtons";
import TableMoreMenu from "../../../components/Table/TableMoreMenu";
import TableRowImage from "../../../components/Table/TableRowImage";

const Sponsorships: React.FC = () => {
    const dispatch = useAppDispatch();

    const [initialized, setInitialized] = useState(false);
    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const { isDeletingSponsorship, isGettingSponsorships, sponsorships, sponsorshipsMetadata } = useTypedSelector((state) => state.sponsorships);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getSponsorships({})).unwrap();
            } catch(err) {
                console.log('Sponsorships initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            setShowNewContentModal(false);
            dispatch(clearSponsorship());
            dispatch(clearSponsorshipsMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Name',
                    accessor: 'name',
                    sortBy: 'name',
                }, {
                    Header: 'Link',
                    accessor: 'linkUrl',
                }, {
                    Header: 'Tenant',
                    accessor: (d) => {
                        if(d.global) {
                            return 'Global';
                        } else if(d.walletSponsorshipTenants && d.walletSponsorshipTenants[0]) {
                            return d.walletSponsorshipTenants[0].tenant.name;
                        } else {
                            return '';
                        }
                    },
                }, {
                    Header: 'Image',
                    Cell: ({row: { original }}) => (
                        <TableRowImage
                            artifact={!isArrayNullOrEmpty(original.artifacts) ? original.artifacts[0] : original.artifact}
                        />
                    ),
                    hasImage: true,
                }, {
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original),
                    hasImage: true
                }
            ];

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleDelete = async (sponsorship) => {
        try {
            await dispatch(deleteSponsorship({sponsorship})).unwrap();
            onMetadataChange({}, false);
        } catch(err) {
            console.log('ListGroups handleDelete err', err);
        }
    };

    const handleEdit = (sponsorship) => {
        dispatch(setSponsorship(sponsorship));
        setShowNewContentModal(true);
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(sponsorshipsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getSponsorships({isUpdate, sponsorshipsMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('SchoolDeals onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item) => {
        return (
            <TableMoreMenu
                hideModerationOptions
                isDeleting={isDeletingSponsorship}
                item={item}
                onDeleteClick={() => handleDelete(item)}
                onEditClick={() => handleEdit(item)}
            />
        );
    };

    return (
        <div className="sponsorships">
            <PageHeader>
                <H5>
                    <Icon type="navigation-sponsorships" />

                    Sponsorships
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableFilterButtons
                    buttons={[{
                        isActive: !sponsorshipsMetadata.type,
                        onClick: () => onMetadataChange({
                            type: null,
                        }, false),
                        text: "All"
                    }, {
                        isActive: sponsorshipsMetadata.type === SPONSORSHIP_TYPES.HEADLINE,
                        onClick: () => onMetadataChange({
                            type: SPONSORSHIP_TYPES.HEADLINE,
                        }, false),
                        text: "Headline"
                    }, {
                        isActive: sponsorshipsMetadata.type === SPONSORSHIP_TYPES.FEATURED,
                        onClick: () => onMetadataChange({
                            type: SPONSORSHIP_TYPES.FEATURED,
                        }, false),
                        text: "Featured"
                    }, {
                        isActive: sponsorshipsMetadata.type === SPONSORSHIP_TYPES.CATEGORY,
                        onClick: () => onMetadataChange({
                            type: SPONSORSHIP_TYPES.CATEGORY,
                        }, false),
                        text: "Category"
                    }]}
                />

                {/* This is here as a spacer to adjust alignment of button */}
                <div className="c-table-search" />

                <Button
                    onClick={() => setShowNewContentModal(true)}
                    size={ButtonSizes.Small}
                >
                    New Sponsorship
                </Button>
            </TableFilterRow>

            <Card>
                {isGettingSponsorships ? (
                    <ActivityIndicator size="large" />
                ) : (
                    <>
                        {isArrayNullOrEmpty(sponsorships) && initialized ? (
                            <>
                                No sponsorships to display. <Button
                                onClick={() => setShowNewContentModal(true)}
                                theme={ButtonThemes.Link}
                            >
                                Create One?
                            </Button>
                            </>
                        ) : (
                            <Table
                                columns={columns}
                                data={sponsorships}
                                getData={onMetadataChange}
                                pageNumber={sponsorshipsMetadata.page_num}
                                pageSize={sponsorshipsMetadata.page_size}
                                sortBy={sponsorshipsMetadata.sort}
                                sortOrder={sponsorshipsMetadata.order}
                                totalNumberOfItems={sponsorshipsMetadata.total}
                            />
                        )}
                    </>
                )}
            </Card>

            <NewContentModal
                close={() => {
                    setShowNewContentModal(false);
                    setTimeout(() => dispatch(clearSponsorship()), 500);
                }}
                show={showNewContentModal}
                width="wide"
            >
                <CreateEditSponsorship
                    close={() => {
                        setShowNewContentModal(false);
                        setTimeout(() => dispatch(clearSponsorship()), 500);
                    }}
                />
            </NewContentModal>
        </div>
    );
};

export default Sponsorships;
