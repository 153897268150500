import './Engineering.scss';

import React, {useState} from 'react';

import ApiRequestTable from './ApiRequests/ApiRequestTable';
import CronJobsTable from './CronJobs/CronJobsTable';
import H5 from '../../components/H5';
import H6 from '../../components/H6';
import SqsMessageTable from './SqsMessages/SqsMessageTable';
import SystemHealthTable from './SystemHealth/SystemHealthTable';
import ToggleSwitch from '../../components/ToggleSwitch';

const Engineering: React.FC = () => {
    const [showCronJobsTable, setShowCronJobsTable] = useState(false);
    const [showApiRoutesTable, setShowApiRoutesTable] = useState(false);
    const [showSqsMessagesTable, setShowSqsMessagesTable] = useState(false);
    const [showSystemHealth, setShowSystemHealth] = useState(true);

    return (
        <div className="engineering">
            <H5>
                Slide One Or More Toggles To View Dashboard Details
            </H5>

            <div className="engineering__toggle-switch-row">
                <ToggleSwitch
                    checked={showSystemHealth}
                    label={'System Health Overview'}
                    name={'SystemHealthToggle'}
                    onClick={() => setShowSystemHealth(!showSystemHealth)}
                />
                <ToggleSwitch
                    checked={showCronJobsTable}
                    label={'Cron Job Dashboard'}
                    name={'CronJobToggle'}
                    onClick={() => setShowCronJobsTable(!showCronJobsTable)}
                />
                <ToggleSwitch
                    checked={showApiRoutesTable}
                    label={'API Request Dashboard'}
                    name={'ApiRequestToggle'}
                    onClick={() => setShowApiRoutesTable(!showApiRoutesTable)}
                />
                <ToggleSwitch
                    checked={showSqsMessagesTable}
                    label={'SQS Message Dashboard'}
                    name={'SqsMessagesToggle'}
                    onClick={() => setShowSqsMessagesTable(!showSqsMessagesTable)}
                />
            </div>

            {showSystemHealth && (
                <SystemHealthTable />
            )}
            {showCronJobsTable && (
                <CronJobsTable />
            )}
            {showApiRoutesTable && (
                <ApiRequestTable />
            )}
            {showSqsMessagesTable && (
                <SqsMessageTable />
            )}

            {!showCronJobsTable && !showApiRoutesTable && !showSqsMessagesTable && !showSystemHealth && (
                <H6 className="engineering__no-toggle-selected">
                    Please select a toggle to view a dashboard
                </H6>
            )}
        </div>
    );
};

export default Engineering;
