import './UserListCard.scss';

import React, { useState } from 'react';

import Group from '../../types/Group';
import IError from '../../types/IError';
import Profile from '../../types/Profile';

import { isObjectNullOrEmpty } from '../../utils/utils';
import { PRIVACY_TYPES } from '../../utils/constants';

import ActivityIndicator from '../../components/ActivityIndicator';
import Avatar from '../../components/Avatar';
import Button, { ButtonSizes, ButtonThemes } from '../Button';
import Card from '../../components/Card';
import ErrorInline from '../../containers/Errors/ErrorInline';
import Icon from '../Icon';

type Props = {
    error?: IError,
    group?: Group
    overridePrivacySetting?: boolean
    highlightUserId?: number
    limit?: number
    onClickUser?: Function
    showActivityIndicator?: boolean
    title: string,
    users: Array<Profile>
}

const UserListCard: React.FC<Props> = (
    {
        error,
        group,
        overridePrivacySetting,
        highlightUserId,
        limit,
        onClickUser,
        showActivityIndicator = false,
        title,
        users = [],
    },
) => {
    const [truncateUsers, setTruncateUsers] = useState(true);

    const handleUserClick = (user) => {
        if (onClickUser) {
            onClickUser(user);
        }
    };

    const filteredUsers = () => {
        if (truncateUsers) {
            return users.slice(0, limit);
        }
        return users;
    };

    return (
        <Card
            className="c-online-students"
            title={title}
        >
            {group.privacyType === PRIVACY_TYPES.PUBLIC || group.isFollowing || overridePrivacySetting ? (
                <>
                    {showActivityIndicator ? (
                        <ActivityIndicator/>
                    ) : (
                        <>

                            {!isObjectNullOrEmpty(error) && (
                                <ErrorInline error={error}/>
                            )}

                            {users.length > 0 && (
                                <>
                                    {filteredUsers().map((user, index) => (
                                        <div
                                            key={index}
                                            className={`c-user-list-item ${(highlightUserId && highlightUserId !== user.userId) ? 'c-user-list-item--faded' : ''}`}
                                            onClick={() => handleUserClick(user)}
                                        >
                                            <Avatar artifact={user.artifact}/>
                                            <div>
                                                <div
                                                    className="c-user-list-item__name">{user.firstName} {user.lastName}</div>
                                                {user.position && (
                                                    <div className="c-user-list-item__position">{user.position}</div>
                                                )}
                                            </div>


                                        </div>
                                    ))}
                                </>
                            )}

                            {(limit > 0 && users.length > limit) && (
                                <Button
                                    className="c-online-students__more-button"
                                    theme={ButtonThemes.Light}
                                    size={ButtonSizes.Small}
                                    onClick={() => setTruncateUsers(!truncateUsers)}
                                >
                                    View {truncateUsers ? 'More' : 'Less'} <Icon type="caret-down"/>
                                </Button>
                            )}

                        </>
                    )}
                </>
            ) : (
                <>
                    Join this group to view recent members
                </>
            )}
        </Card>
    );
};

export default UserListCard;
