import './Tooltip.scss';

import React from 'react';

import Icon from "../Icon";

export enum TooltipSide {
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right',
};

type Props = {
    children: React.ReactNode
    icon?: string
    side?: TooltipSide
}

const Tooltip: React.FC<Props> = ({
    children,
    icon,
    side= TooltipSide.Bottom,
}) => (
    <div className={`c-tooltip ${side ? `a-tooltip--${side}` : ''}`}>
        {icon && (
            <div className="c-tooltip__trigger">
                <Icon type={icon} />
            </div>
        )}

        <div className="c-tooltip__content">
            {children}
        </div>
    </div>
);

export default Tooltip;
