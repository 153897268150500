import './NewContentMenu.scss';

import React from 'react';
import Button from "../Button";
import Icon from "../Icon";

type Props = {
    className?: string
    close: Function
    options: Array<NewContentMenuOption>
    runCloseOnClick?: boolean
    show: boolean
}

const NewContentMenu: React.FC<Props> = ({
    className = '',
    close,
    options,
    runCloseOnClick,
    show,
}) => {
    return (
        <div className={`o-new-content-menu ${show ? 'a-new-content-menu--show' : ''} ${className}`}>
            <div
                className="c-new-content-menu__background"
                onClick={(e) => {
                    close(e);
                }}
            />

            <div className="c-new-content-menu">
                {options.map((option) => (
                    <Button
                        className="c-new-content-menu__option"
                        disabled={option.disabled}
                        key={option.text}
                        noStyles
                        onClick={(e) => {
                            option.onClick();

                            if(runCloseOnClick) {
                                close(e);
                            }
                        }}
                    >
                        <div className="c-new-content-menu__icon-container">
                            <Icon type={option.icon} />
                        </div>

                        <div className="c-new-content-menu__text">
                            <div className="c-new-content-menu__text">
                                {option.text}
                            </div>

                            <div className="c-new-content-menu__subtext">
                                {option.subtext}
                            </div>
                        </div>
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default NewContentMenu;
