import './Icon.scss';

import React from 'react';

type Props = {
    className?: string
    theme?: 'success' | 'destructive'
    type: string //'caret-down' | 'arrow-left' | 'arrow-right' | 'avatar' | 'more' | 'plus' | 'search' | 'user' | 'x'
}

const Icon: React.FC<Props> = ({
    className = '',
    theme,
    type
}) => {
    const generateClass = (type, className) => {
        let classString = `c-icon a-icon--${type}`;
        if (theme != null) {
            classString += ` a-icon--${theme}`;
        }
        if (className != null) {
            classString += ` ${className}`;
        }
        return classString;
    };

    switch (type) {
        case 'alert':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 24 24">
                    <path d="M12.8661 2.99996L22.3921 19.5C22.4799 19.652 22.5261 19.8244 22.5261 20C22.5261 20.1755 22.4799 20.3479 22.3921 20.4999C22.3043 20.652 22.1781 20.7782 22.0261 20.866C21.8741 20.9537 21.7016 21 21.5261 21H2.4741C2.29856 21 2.12612 20.9537 1.9741 20.866C1.82209 20.7782 1.69585 20.652 1.60809 20.4999C1.52032 20.3479 1.47412 20.1755 1.47412 20C1.47412 19.8244 1.52033 19.652 1.6081 19.5L11.1341 2.99996C11.2219 2.84795 11.3481 2.72172 11.5001 2.63396C11.6521 2.5462 11.8246 2.5 12.0001 2.5C12.1756 2.5 12.3481 2.5462 12.5001 2.63396C12.6521 2.72172 12.7783 2.84795 12.8661 2.99996ZM11.0001 16V18H13.0001V16H11.0001ZM11.0001 8.99996V14H13.0001V8.99996H11.0001Z" />
                </svg>
            );
        case 'arrow-left':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 12">
                    <path d="M3.21866 5.33349H11.3333V6.66682H3.21866L6.79466 10.2428L5.852 11.1855L0.666664 6.00015L5.852 0.814819L6.79466 1.75749L3.21866 5.33349Z" />
                </svg>
            );
        case 'arrow-right':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 14">
                    <path d="M10.4763 6.1668L6.00634 1.6968L7.18467 0.518463L13.6663 7.00013L7.18467 13.4818L6.00634 12.3035L10.4763 7.83346H0.333008V6.1668H10.4763Z" />
                </svg>
            );
        case 'avatar':
        case 'user':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 22 29">
                    <path
                        d="M0.333374 28.3335C0.333374 25.5045 1.45718 22.7914 3.45757 20.791C5.45796 18.7906 8.17106 17.6668 11 17.6668C13.829 17.6668 16.5421 18.7906 18.5425 20.791C20.5429 22.7914 21.6667 25.5045 21.6667 28.3335H0.333374ZM11 16.3335C6.58004 16.3335 3.00004 12.7535 3.00004 8.3335C3.00004 3.9135 6.58004 0.333496 11 0.333496C15.42 0.333496 19 3.9135 19 8.3335C19 12.7535 15.42 16.3335 11 16.3335Z"
                        fill="#637888"/>
                </svg>
            );
        case 'caret-down':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 8">
                    <path d="M7 5.172L11.95 0.222L13.364 1.636L7 8L0.636002 1.636L2.05 0.222L7 5.172Z" fill="#14171A"/>
                </svg>

            );
        case 'caret-left':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 8 14">
                    <path d="M2.828 7L7.778 11.95L6.364 13.364L0 7L6.364 0.636002L7.778 2.05L2.828 7Z" fill="#14171A"/>
                </svg>
            );
        case 'caret-right':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 8 14">
                    <path d="M5.17198 7L0.221985 2.05L1.63598 0.636002L7.99998 7L1.63598 13.364L0.221985 11.95L5.17198 7Z" fill="#14171A"/>
                </svg>
            );
        case 'check':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 18 13">
                    <path d="M6.99974 10.172L16.1917 0.979004L17.6067 2.393L6.99974 13L0.635742 6.636L2.04974 5.222L6.99974 10.172Z" />
                </svg>
            );
        case 'clock':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 512 512">
                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                </svg>
            );
        case 'comments':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 22 21">
                    <path d="M13 20.5L10.2 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V5.103C4 4.83778 4.10536 4.58343 4.29289 4.39589C4.48043 4.20836 4.73478 4.103 5 4.103H21C21.2652 4.103 21.5196 4.20836 21.7071 4.39589C21.8946 4.58343 22 4.83778 22 5.103V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17H15.8L13 20.5ZM14.839 15H20V6.103H6V15H11.161L13 17.298L14.839 15ZM1 0H18V2H2V13H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0Z" fill="#637888"/>
                </svg>
            );
        case 'delete':
        case 'trash':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 14">
                    <path d="M10.3333 3.00004H13.6667V4.33337H12.3333V13C12.3333 13.1769 12.2631 13.3464 12.1381 13.4714C12.0131 13.5965 11.8435 13.6667 11.6667 13.6667H2.33334C2.15653 13.6667 1.98696 13.5965 1.86194 13.4714C1.73691 13.3464 1.66668 13.1769 1.66668 13V4.33337H0.333344V3.00004H3.66668V1.00004C3.66668 0.82323 3.73691 0.65366 3.86194 0.528636C3.98696 0.403612 4.15653 0.333374 4.33334 0.333374H9.66668C9.84349 0.333374 10.0131 0.403612 10.1381 0.528636C10.2631 0.65366 10.3333 0.82323 10.3333 1.00004V3.00004ZM11 4.33337H3.00001V12.3334H11V4.33337ZM5.00001 6.33337H6.33334V10.3334H5.00001V6.33337ZM7.66668 6.33337H9.00001V10.3334H7.66668V6.33337ZM5.00001 1.66671V3.00004H9.00001V1.66671H5.00001Z" />
                </svg>

            );
        case 'document-add':
        case 'document-plus':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 14">
                    <path d="M8.66667 0.333008L12 3.66634V13.005C11.9998 13.1805 11.93 13.3487 11.8059 13.4727C11.6817 13.5967 11.5135 13.6663 11.338 13.6663H0.662C0.486919 13.6651 0.319345 13.5951 0.195478 13.4713C0.071612 13.3476 0.00139643 13.1801 0 13.005V0.994341C0 0.629008 0.296667 0.333008 0.662 0.333008H8.66667ZM5.33333 6.33301H3.33333V7.66634H5.33333V9.66634H6.66667V7.66634H8.66667V6.33301H6.66667V4.33301H5.33333V6.33301Z" fill="#0057FF"/>
                </svg>
            );
        case 'document-regular':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 384 512">
                    <path d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"/>
                </svg>
            );
        case 'document-x':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 14">
                    <path d="M8.66667 0.333008L12 3.66634V13.005C11.9998 13.1805 11.93 13.3487 11.8059 13.4727C11.6817 13.5967 11.5135 13.6663 11.338 13.6663H0.662C0.486919 13.6651 0.319345 13.5951 0.195478 13.4713C0.071612 13.3476 0.00139643 13.1801 0 13.005V0.994341C0 0.629008 0.296667 0.333008 0.662 0.333008H8.66667ZM6.8 6.99967L8.66667 4.33301H7.06667L6 5.85701L4.93333 4.33301H3.33333L5.2 6.99967L3.33333 9.66634H4.93333L6 8.14234L7.06667 9.66634H8.66667L6.8 6.99967Z" fill="black"/>
                </svg>
            );
        case 'drag':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 10 16">
                    <path d="M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z" fill="#637888"/>
                </svg>
            );
        case 'edit':
        case 'pencil':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 12">
                    <path d="M4.162 10.6666H12V11.9999H0V9.17127L6.6 2.57127L9.428 5.4006L4.16133 10.6666H4.162ZM7.542 1.62927L8.95667 0.2146C9.08169 0.0896195 9.25122 0.0194092 9.428 0.0194092C9.60478 0.0194092 9.77432 0.0896195 9.89933 0.2146L11.7853 2.1006C11.9103 2.22562 11.9805 2.39516 11.9805 2.57193C11.9805 2.74871 11.9103 2.91825 11.7853 3.04327L10.3707 4.45727L7.54267 1.62927H7.542Z" />
                </svg>
            );
        case 'envelope':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 12">
                    <path d="M0.999674 0H12.9997C13.1765 0 13.3461 0.0702379 13.4711 0.195262C13.5961 0.320286 13.6663 0.489856 13.6663 0.666667V11.3333C13.6663 11.5101 13.5961 11.6797 13.4711 11.8047C13.3461 11.9298 13.1765 12 12.9997 12H0.999674C0.822863 12 0.653294 11.9298 0.52827 11.8047C0.403246 11.6797 0.333008 11.5101 0.333008 11.3333V0.666667C0.333008 0.489856 0.403246 0.320286 0.52827 0.195262C0.653294 0.0702379 0.822863 0 0.999674 0V0ZM7.03967 5.78867L2.76501 2.15867L1.90167 3.17467L7.04834 7.54467L12.1023 3.17133L11.2303 2.16267L7.04034 5.78867H7.03967Z" fill="#14171A"/>
                </svg>
            );
        case 'external-link':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 512 512">
                    <path d="M432 288H416a16 16 0 0 0 -16 16V458a6 6 0 0 1 -6 6H54a6 6 0 0 1 -6-6V118a6 6 0 0 1 6-6H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V304A16 16 0 0 0 432 288zM500 0H364a12 12 0 0 0 -8.5 20.5l48.2 48.2L131.5 340.9a12 12 0 0 0 0 17l22.6 22.6a12 12 0 0 0 17 0l272.2-272.2 48.2 48.2A12 12 0 0 0 512 148V12A12 12 0 0 0 500 0z"/>
                </svg>
            )
        case 'film':
            return (
                <svg className={generateClass(type, className)} strokeWidth="1.5"  viewBox="0 0 24 24">
                    <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                </svg>
            );
        case 'flag':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 13 13">
                    <path d="M0.996094 0H7.25076C7.37454 6.60991e-05 7.49585 0.0345888 7.60111 0.0997016C7.70638 0.164814 7.79143 0.257946 7.84676 0.368667L8.32943 1.33333H12.3294C12.5062 1.33333 12.6758 1.40357 12.8008 1.5286C12.9259 1.65362 12.9961 1.82319 12.9961 2V9.33333C12.9961 9.51015 12.9259 9.67972 12.8008 9.80474C12.6758 9.92976 12.5062 10 12.3294 10H8.07476C7.95099 9.99994 7.82967 9.96541 7.72441 9.9003C7.61915 9.83519 7.53409 9.74206 7.47876 9.63133L6.99609 8.66667H2.32943V12.6667H0.996094V0Z" />
                </svg>
            );
        case 'image':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 15 14">
                    <path d="M13.0002 8V10H15.0002V11.3333H13.0002V13.3333H11.6668V11.3333H9.66683V10H11.6668V8H13.0002ZM13.0055 0C13.3708 0 13.6668 0.296667 13.6668 0.662V6.66667H12.3335V1.33333H1.66683V10.666L8.3335 4L10.3335 6V7.886L8.3335 5.886L3.5515 10.6667H8.3335V12H0.99483C0.819372 11.9998 0.651161 11.93 0.527156 11.8059C0.403151 11.6817 0.333496 11.5135 0.333496 11.338V0.662C0.334716 0.486919 0.404763 0.319345 0.528505 0.195478C0.652246 0.0716119 0.81975 0.00139643 0.99483 0H13.0055ZM4.3335 2.66667C4.68712 2.66667 5.02626 2.80714 5.27631 3.05719C5.52635 3.30724 5.66683 3.64638 5.66683 4C5.66683 4.35362 5.52635 4.69276 5.27631 4.94281C5.02626 5.19286 4.68712 5.33333 4.3335 5.33333C3.97987 5.33333 3.64074 5.19286 3.39069 4.94281C3.14064 4.69276 3.00016 4.35362 3.00016 4C3.00016 3.64638 3.14064 3.30724 3.39069 3.05719C3.64074 2.80714 3.97987 2.66667 4.3335 2.66667Z" />
                </svg>
            );
        case 'info':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 24 24">
                    <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11 11V17H13V11H11ZM11 7V9H13V7H11Z" />
                </svg>
            );
        case 'integration':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 384 512">
                    <path d="M352,128H304V16a16,16,0,0,0-32,0V128H112V16a16,16,0,0,0-32,0V128H32A32,32,0,0,0,0,160v32a32,32,0,0,0,32,32v32c0,82.75,63.37,150.22,144,158.38V512h32V414.38c80.63-8.16,144-75.63,144-158.38V224a32,32,0,0,0,32-32V160A32,32,0,0,0,352,128ZM320,256a128,128,0,0,1-256,0V224H320Zm32-64H32V160H352Z" />
                </svg>
            );
        case 'landing-page':
        case 'template':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 20 18">
                    <path d="M5 7V18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V7H5ZM20 7V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H7V7H20ZM19 0C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V5H0V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19Z" fill="#14171A"/>
                </svg>
            );
        case 'list-type-grid':
        case 'grid':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 12">
                    <path d="M13.6666 6.666V11.3333C13.6666 11.5101 13.5963 11.6797 13.4713 11.8047C13.3463 11.9298 13.1767 12 12.9999 12H7.66659V6.666H13.6666ZM6.33325 6.666V12H0.999919C0.823108 12 0.653538 11.9298 0.528514 11.8047C0.40349 11.6797 0.333252 11.5101 0.333252 11.3333V6.666H6.33325V6.666ZM6.33325 0V5.33267H0.333252V0.666667C0.333252 0.489856 0.40349 0.320286 0.528514 0.195262C0.653538 0.0702379 0.823108 0 0.999919 0H6.33325ZM12.9999 0C13.1767 0 13.3463 0.0702379 13.4713 0.195262C13.5963 0.320286 13.6666 0.489856 13.6666 0.666667V5.33267H7.66659V0H12.9999Z" />
                </svg>
            );
        case 'list-type-table':
        case 'table':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 12">
                    <path d="M3.33333 0.666829H12V2.00016H3.33333V0.666829ZM0 0.333496H2V2.3335H0V0.333496ZM0 5.00016H2V7.00016H0V5.00016ZM0 9.66683H2V11.6668H0V9.66683ZM3.33333 5.3335H12V6.66683H3.33333V5.3335ZM3.33333 10.0002H12V11.3335H3.33333V10.0002Z" />
                </svg>
            );
        case 'lock':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 14">
                    <path d="M10.6667 5.66659H11.3333C11.5101 5.66659 11.6797 5.73682 11.8047 5.86185C11.9298 5.98687 12 6.15644 12 6.33325V12.9999C12 13.1767 11.9298 13.3463 11.8047 13.4713C11.6797 13.5963 11.5101 13.6666 11.3333 13.6666H0.666667C0.489856 13.6666 0.320286 13.5963 0.195262 13.4713C0.0702379 13.3463 0 13.1767 0 12.9999V6.33325C0 6.15644 0.0702379 5.98687 0.195262 5.86185C0.320286 5.73682 0.489856 5.66659 0.666667 5.66659H1.33333V4.99992C1.33333 4.38708 1.45404 3.78025 1.68856 3.21406C1.92308 2.64788 2.26683 2.13343 2.70017 1.70009C3.13351 1.26675 3.64796 0.923003 4.21414 0.688481C4.78033 0.453959 5.38716 0.333252 6 0.333252C6.61284 0.333252 7.21967 0.453959 7.78586 0.688481C8.35204 0.923003 8.86649 1.26675 9.29983 1.70009C9.73317 2.13343 10.0769 2.64788 10.3114 3.21406C10.546 3.78025 10.6667 4.38708 10.6667 4.99992V5.66659ZM9.33333 5.66659V4.99992C9.33333 4.11586 8.98214 3.26802 8.35702 2.6429C7.7319 2.01777 6.88406 1.66659 6 1.66659C5.11595 1.66659 4.2681 2.01777 3.64298 2.6429C3.01786 3.26802 2.66667 4.11586 2.66667 4.99992V5.66659H9.33333ZM5.33333 8.33325V10.9999H6.66667V8.33325H5.33333Z" />
                </svg>
            );
        case 'minus-circle':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232H328h24v48H328 184 160V232h24z"/>
                </svg>
            )
        case 'more':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 4 16">
                    <path d="M2 0.5C1.3125 0.5 0.75 1.0625 0.75 1.75C0.75 2.4375 1.3125 3 2 3C2.6875 3 3.25 2.4375 3.25 1.75C3.25 1.0625 2.6875 0.5 2 0.5ZM2 13C1.3125 13 0.75 13.5625 0.75 14.25C0.75 14.9375 1.3125 15.5 2 15.5C2.6875 15.5 3.25 14.9375 3.25 14.25C3.25 13.5625 2.6875 13 2 13ZM2 6.75C1.3125 6.75 0.75 7.3125 0.75 8C0.75 8.6875 1.3125 9.25 2 9.25C2.6875 9.25 3.25 8.6875 3.25 8C3.25 7.3125 2.6875 6.75 2 6.75Z" />
                </svg>
            );
        case 'navigation-activity-feed':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 12">
                    <path d="M0 0C6.62733 0 12 5.37267 12 12H10C10 6.47733 5.52267 2 0 2V0ZM0 4.66667C4.05 4.66667 7.33333 7.95 7.33333 12H5.33333C5.33333 10.5855 4.77143 9.22896 3.77124 8.22876C2.77104 7.22857 1.41449 6.66667 0 6.66667V4.66667ZM0 9.33333C0.707244 9.33333 1.38552 9.61429 1.88562 10.1144C2.38572 10.6145 2.66667 11.2928 2.66667 12H0V9.33333Z" />
                </svg>
            );
        case 'navigation-admins':
        case 'admins':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 13 15">
                    <path d="M6.00008 9.33332V14.6667H0.666748C0.666748 13.2522 1.22865 11.8956 2.22885 10.8954C3.22904 9.89523 4.58559 9.33332 6.00008 9.33332ZM6.00008 8.66666C3.79008 8.66666 2.00008 6.87666 2.00008 4.66666C2.00008 2.45666 3.79008 0.666656 6.00008 0.666656C8.21008 0.666656 10.0001 2.45666 10.0001 4.66666C10.0001 6.87666 8.21008 8.66666 6.00008 8.66666ZM12.0001 11.3333H12.6667V14.6667H7.33342V11.3333H8.00008V10.6667C8.00008 10.1362 8.21079 9.62752 8.58587 9.25244C8.96094 8.87737 9.46965 8.66666 10.0001 8.66666C10.5305 8.66666 11.0392 8.87737 11.4143 9.25244C11.7894 9.62752 12.0001 10.1362 12.0001 10.6667V11.3333ZM10.6667 11.3333V10.6667C10.6667 10.4898 10.5965 10.3203 10.4715 10.1953C10.3465 10.0702 10.1769 9.99999 10.0001 9.99999C9.82327 9.99999 9.6537 10.0702 9.52868 10.1953C9.40365 10.3203 9.33342 10.4898 9.33342 10.6667V11.3333H10.6667Z" />
                </svg>
            );
        case 'navigation-classnote':
        case 'classnote':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 12">
                    <path d="M12 8L8 11.9973L0.668 12C0.491657 12.0007 0.322246 11.9314 0.19699 11.8073C0.071735 11.6831 0.000882063 11.5143 0 11.338V0.662C0 0.296667 0.296667 0 0.662 0H11.338C11.7033 0 12 0.304 12 0.668V8ZM10.6667 1.33333H1.33333V10.6667H6.66667V7.33333C6.66669 7.17004 6.72664 7.01244 6.83514 6.89042C6.94365 6.76839 7.09317 6.69044 7.25533 6.67133L7.33333 6.66667L10.6667 6.666V1.33333ZM10.114 7.99933L8 8V10.1127L10.114 7.99933Z" />
                </svg>
            );
        case 'navigation-dashboard':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 12">
                    <path d="M0 6.66667H5.33333V0H0V6.66667ZM0 12H5.33333V8H0V12ZM6.66667 12H12V5.33333H6.66667V12ZM6.66667 0V4H12V0H6.66667Z" />
                </svg>
            );
        case 'navigation-deals':
        case 'deal':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 15 15">
                    <path d="M7.26624 0.400391L13.8656 1.34372L14.8082 7.94372L8.68024 14.0717C8.55522 14.1967 8.38568 14.2669 8.20891 14.2669C8.03213 14.2669 7.86259 14.1967 7.73757 14.0717L1.13757 7.47172C1.01259 7.34671 0.942383 7.17717 0.942383 7.00039C0.942383 6.82361 1.01259 6.65408 1.13757 6.52906L7.26624 0.400391ZM7.73757 1.81506L2.55157 7.00039L8.20891 12.6571L13.3942 7.47172L12.6876 2.52172L7.73757 1.81506ZM9.15091 6.05772C8.90081 5.80754 8.76034 5.46824 8.7604 5.11449C8.76043 4.93933 8.79496 4.76589 8.86202 4.60407C8.92908 4.44225 9.02736 4.29523 9.15124 4.17139C9.27512 4.04755 9.42218 3.94933 9.58402 3.88233C9.74586 3.81532 9.91931 3.78085 10.0945 3.78089C10.4482 3.78095 10.7875 3.92154 11.0376 4.17172C11.2877 4.42191 11.4281 4.7612 11.4281 5.11496C11.428 5.46872 11.2874 5.80796 11.0372 6.05806C10.7871 6.30816 10.4478 6.44863 10.094 6.44856C9.74025 6.4485 9.40101 6.30791 9.15091 6.05772Z" />
                </svg>
            );
        case 'navigation-discussions':
        case 'discussion':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 16 14">
                    <path d="M11.2001 11.6666L9.33341 13.9999L7.46675 11.6666H4.00008C3.82327 11.6666 3.6537 11.5963 3.52868 11.4713C3.40365 11.3463 3.33341 11.1767 3.33341 10.9999V3.73525C3.33341 3.55844 3.40365 3.38887 3.52868 3.26385C3.6537 3.13882 3.82327 3.06859 4.00008 3.06859H14.6667C14.8436 3.06859 15.0131 3.13882 15.1382 3.26385C15.2632 3.38887 15.3334 3.55844 15.3334 3.73525V10.9999C15.3334 11.1767 15.2632 11.3463 15.1382 11.4713C15.0131 11.5963 14.8436 11.6666 14.6667 11.6666H11.2001ZM1.33341 0.333252H12.6667V1.66659H2.00008V8.99992H0.666748V0.999919C0.666748 0.823108 0.736986 0.653538 0.86201 0.528514C0.987034 0.40349 1.1566 0.333252 1.33341 0.333252V0.333252Z" />
                </svg>
            );
        case 'navigation-dms':
        case 'dms':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 19 20">
                    <path d="M18.5631 0.340004C18.2711 0.141004 17.8961 0.111004 17.5741 0.261004L0.574082 8.261C0.219082 8.429 -0.00491799 8.788 8.20057e-05 9.18C0.00608201 9.572 0.240082 9.925 0.600082 10.082L6.00008 12.445V19.167L11.8361 14.999L16.6001 17.083C16.7281 17.14 16.8651 17.167 17.0001 17.167C17.1811 17.167 17.3601 17.118 17.5201 17.021C17.7981 16.852 17.9771 16.558 17.9991 16.233L18.9991 1.233C19.0211 0.879004 18.8561 0.540004 18.5631 0.340004ZM16.0971 14.68L10.8281 12.374L14.0001 6.167L6.35108 10.417L3.41908 9.134L16.8901 2.794L16.0971 14.68Z" />
                </svg>
            );
        case 'navigation-events':
        case 'calendar':
        case 'event':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 14">
                    <path d="M10.3335 1.99984H13.0002C13.177 1.99984 13.3465 2.07008 13.4716 2.1951C13.5966 2.32012 13.6668 2.48969 13.6668 2.6665V13.3332C13.6668 13.51 13.5966 13.6796 13.4716 13.8046C13.3465 13.9296 13.177 13.9998 13.0002 13.9998H1.00016C0.823352 13.9998 0.653782 13.9296 0.528758 13.8046C0.403734 13.6796 0.333496 13.51 0.333496 13.3332V2.6665C0.333496 2.48969 0.403734 2.32012 0.528758 2.1951C0.653782 2.07008 0.823352 1.99984 1.00016 1.99984H3.66683V0.666504H5.00016V1.99984H9.00016V0.666504H10.3335V1.99984ZM9.00016 3.33317H5.00016V4.6665H3.66683V3.33317H1.66683V5.99984H12.3335V3.33317H10.3335V4.6665H9.00016V3.33317ZM12.3335 7.33317H1.66683V12.6665H12.3335V7.33317Z" />
                </svg>
            );
        case 'navigation-engineering':
        case 'navigation-glasses':
        case 'glasses':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 576 512">
                    <path d="M574.21 280.58l-45.49-188c-5.38-21.55-19.62-40.05-39.09-50.73-19.47-10.66-42.66-12.8-63.81-5.77l-22.85 7.62c-4.19 1.4-6.46 5.93-5.06 10.12L402.97 69c1.4 4.19 5.93 6.46 10.12 5.06l21.79-7.27c10.1-3.37 21.16-3.95 31.23-.48 16.15 5.56 27.57 18.12 31.51 33.84l40.61 171.44c-22.35-7.63-53.24-15.06-89.57-15.06-37.34 0-80.26 8-125 31.48h-71.31c-44.74-23.48-87.66-31.49-125-31.48-36.33 0-67.22 7.44-89.58 15.07l40.57-171.24c3.36-13.44 11.99-24.44 24.29-30.9 12.24-6.43 26.88-6.51 39.99-2.14l20.28 6.77c4.19 1.4 8.72-.87 10.12-5.06l5.06-15.18c1.4-4.19-.87-8.72-5.06-10.12l-22.85-7.61c-21.09-7.06-44.31-4.95-63.81 5.75-19.45 10.68-33.7 29.18-39.11 50.91L1.8 280.58C.6 285.51 0 290.56 0 295.64v73.64C0 430.43 50.14 480 112 480h36.08c58.61 0 107.3-44.67 111.69-102.44l4.37-57.56h47.72l4.37 57.56C320.61 435.34 369.31 480 427.92 480H464c61.86 0 112-49.57 112-110.72v-73.65c0-5.07-.6-10.12-1.79-15.05zm-346.35 94.56C224.76 416 189.72 448 148.08 448H112c-44.11 0-80-35.31-80-78.72v-61.3c18.79-7.97 53.21-19.46 95.37-19.46 36.35 0 71.64 8.44 105.16 25.13l-4.67 61.49zM544 369.28c0 43.41-35.89 78.72-80 78.72h-36.08c-41.63 0-76.68-32-79.78-72.86l-4.67-61.49c33.53-16.68 68.83-25.13 105.19-25.13 42.16 0 76.56 11.49 95.34 19.46v61.3z"></path>
                </svg>
            );
        case 'navigation-faq':
        case 'faq':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 20 20">
                    <path d="M3.763 14H18V2H2V15.385L3.763 14ZM4.455 16L0 19.5V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V15C20 15.2652 19.8946 15.5196 19.7071 15.7071C19.5196 15.8946 19.2652 16 19 16H4.455ZM9 11H11V13H9V11ZM6.567 5.813C6.69692 5.16279 7.00882 4.56285 7.4664 4.08298C7.92397 3.60311 8.50841 3.26304 9.15171 3.10236C9.79501 2.94167 10.4707 2.96697 11.1002 3.17532C11.7297 3.38366 12.287 3.76647 12.7075 4.27922C13.1279 4.79196 13.394 5.41355 13.475 6.07166C13.5559 6.72976 13.4483 7.39732 13.1646 7.99665C12.881 8.59598 12.433 9.10244 11.8727 9.4571C11.3125 9.81176 10.6631 10 10 10H9V8H10C10.2841 7.99998 10.5623 7.9193 10.8023 7.76733C11.0423 7.61536 11.2343 7.39836 11.3558 7.14158C11.4773 6.8848 11.5234 6.5988 11.4887 6.31684C11.454 6.03489 11.34 5.76858 11.1598 5.54891C10.9797 5.32924 10.7409 5.16523 10.4712 5.07597C10.2015 4.98671 9.91204 4.97587 9.63643 5.04471C9.36081 5.11354 9.11042 5.25923 8.91437 5.46482C8.71832 5.6704 8.58468 5.92743 8.529 6.206L6.567 5.813Z" />
                </svg>
            );
        case 'navigation-groups':
        case 'groups':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 14">
                    <path d="M6.99998 6.33337C7.88403 6.33337 8.73188 6.68456 9.357 7.30968C9.98212 7.93481 10.3333 8.78265 10.3333 9.66671V13.6667H3.66665V9.66671C3.66665 8.78265 4.01784 7.93481 4.64296 7.30968C5.26808 6.68456 6.11592 6.33337 6.99998 6.33337ZM2.52531 8.33737C2.41922 8.69523 2.35655 9.06455 2.33865 9.43737L2.33331 9.66671V13.6667H0.333313V10.6667C0.333182 10.0918 0.545332 9.537 0.929069 9.10885C1.31281 8.68069 1.84111 8.4093 2.41265 8.34671L2.52598 8.33737H2.52531ZM11.4746 8.33737C12.0679 8.37354 12.625 8.63468 13.0324 9.06753C13.4397 9.50038 13.6666 10.0723 13.6666 10.6667V13.6667H11.6666V9.66671C11.6666 9.20471 11.6 8.75871 11.4746 8.33737ZM2.66665 4.33337C3.10867 4.33337 3.5326 4.50897 3.84516 4.82153C4.15772 5.13409 4.33331 5.55801 4.33331 6.00004C4.33331 6.44207 4.15772 6.86599 3.84516 7.17855C3.5326 7.49111 3.10867 7.66671 2.66665 7.66671C2.22462 7.66671 1.8007 7.49111 1.48814 7.17855C1.17557 6.86599 0.99998 6.44207 0.99998 6.00004C0.99998 5.55801 1.17557 5.13409 1.48814 4.82153C1.8007 4.50897 2.22462 4.33337 2.66665 4.33337V4.33337ZM11.3333 4.33337C11.7753 4.33337 12.1993 4.50897 12.5118 4.82153C12.8244 5.13409 13 5.55801 13 6.00004C13 6.44207 12.8244 6.86599 12.5118 7.17855C12.1993 7.49111 11.7753 7.66671 11.3333 7.66671C10.8913 7.66671 10.4674 7.49111 10.1548 7.17855C9.84224 6.86599 9.66665 6.44207 9.66665 6.00004C9.66665 5.55801 9.84224 5.13409 10.1548 4.82153C10.4674 4.50897 10.8913 4.33337 11.3333 4.33337V4.33337ZM6.99998 0.333374C7.70722 0.333374 8.3855 0.614326 8.8856 1.11442C9.3857 1.61452 9.66665 2.2928 9.66665 3.00004C9.66665 3.70728 9.3857 4.38556 8.8856 4.88566C8.3855 5.38576 7.70722 5.66671 6.99998 5.66671C6.29274 5.66671 5.61446 5.38576 5.11436 4.88566C4.61426 4.38556 4.33331 3.70728 4.33331 3.00004C4.33331 2.2928 4.61426 1.61452 5.11436 1.11442C5.61446 0.614326 6.29274 0.333374 6.99998 0.333374V0.333374Z" />
                </svg>
            );
        case 'navigation-marketplace':
        case 'marketplace':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 16 14">
                    <path d="M13.9998 7.82816V12.3335H14.6665V13.6668H1.33317V12.3335H1.99984V7.82816C1.58942 7.55422 1.25298 7.18319 1.02038 6.74801C0.787788 6.31282 0.666232 5.82694 0.666504 5.3335C0.666504 4.78216 0.815838 4.25083 1.0885 3.79816L2.8965 0.666829C2.95501 0.565486 3.03917 0.481329 3.14051 0.422817C3.24186 0.364305 3.35682 0.333499 3.47384 0.333496H12.5265C12.6435 0.333499 12.7585 0.364305 12.8598 0.422817C12.9612 0.481329 13.0453 0.565486 13.1038 0.666829L14.9052 3.78816C15.3029 4.44874 15.4307 5.23718 15.2621 5.98959C15.0935 6.74199 14.6414 7.40048 13.9998 7.82816ZM12.6665 8.31483C12.2081 8.36625 11.7441 8.31119 11.3104 8.15393C10.8768 7.99667 10.4854 7.74146 10.1665 7.40816C9.88664 7.70088 9.55037 7.93384 9.17798 8.093C8.80559 8.25215 8.40481 8.33419 7.99984 8.33416C7.59496 8.33436 7.19424 8.25253 6.82186 8.09361C6.44947 7.93469 6.11314 7.70197 5.83317 7.4095C5.51425 7.74267 5.12275 7.99776 4.68914 8.1549C4.25552 8.31204 3.7915 8.367 3.33317 8.3155V12.3335H12.6665V8.3155V8.31483ZM3.85917 1.66683L2.23717 4.4755C2.07956 4.86493 2.07482 5.29951 2.22392 5.69228C2.37303 6.08505 2.66496 6.40702 3.04129 6.59376C3.41763 6.78049 3.8506 6.81821 4.25356 6.69937C4.65652 6.58053 4.99973 6.3139 5.2145 5.95283C5.43784 5.39483 6.22784 5.39483 6.45184 5.95283C6.57537 6.26244 6.7889 6.52789 7.06485 6.7149C7.34081 6.9019 7.66649 7.00186 7.99984 7.00186C8.33318 7.00186 8.65887 6.9019 8.93482 6.7149C9.21077 6.52789 9.42431 6.26244 9.54784 5.95283C9.77117 5.39483 10.5612 5.39483 10.7852 5.95283C10.8717 6.16579 11.0013 6.35856 11.1659 6.51901C11.3305 6.67946 11.5266 6.80413 11.7417 6.88518C11.9568 6.96622 12.1863 7.00189 12.4159 6.98995C12.6454 6.97801 12.87 6.91871 13.0756 6.81578C13.2811 6.71285 13.4631 6.56852 13.6102 6.39185C13.7573 6.21518 13.8662 6.01001 13.9302 5.78921C13.9941 5.56842 14.0117 5.33678 13.9818 5.10886C13.9519 4.88095 13.8752 4.66167 13.7565 4.46483L12.1398 1.66683H3.85984H3.85917Z" fill="#14171A"/>
                </svg>
            );
        case 'navigation-news':
        case 'news':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 512 512">
                    <path d="M96 32h24H488h24V56 456v24H488 144 120 72 48 24 0V456 112 88H48v24V432H72 96V56 32zm48 400H464V80H144V432zm32-320H320V240H176V112zm200 0h32 24v48H408 376 352V112h24zm0 80h32 24v48H408 376 352V192h24zM200 272H408h24v48H408 200 176V272h24zm0 80H408h24v48H408 200 176V352h24z"/>
                </svg>
            );
        case 'navigation-notifications':
        case 'notifications':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 15">
                    <path d="M6 0.333313C9.31333 0.333313 12 3.02865 12 6.35398V12.3333H0V6.35398C0 3.02865 2.68667 0.333313 6 0.333313ZM4.33333 13H7.66667C7.66667 13.442 7.49107 13.8659 7.17851 14.1785C6.86595 14.4911 6.44203 14.6666 6 14.6666C5.55797 14.6666 5.13405 14.4911 4.82149 14.1785C4.50893 13.8659 4.33333 13.442 4.33333 13V13Z" />
                </svg>
            );
        case 'navigation-resources':
        case 'resources':
                return (
                    <svg className={generateClass(type, className)} viewBox="0 0 512 512">
                        <path d="M48 80H80v32H48V80zM16 48V80v32 32H48 80h32V112 80 48H80 48 16zM176 80H160v32h16H496h16V80H496 176zm0 160H160v32h16H496h16V240H496 176zm0 160H160v32h16H496h16V400H496 176zM80 240v32H48V240H80zM48 208H16v32 32 32H48 80h32V272 240 208H80 48zm0 192H80v32H48V400zM16 368v32 32 32H48 80h32V432 400 368H80 48 16z"/>
                    </svg>
                );
        case 'navigation-sponsorships':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 576 512">
                    <path d="M576 32H0V480H576V32zM368 280c0 13.3 10.7 24 24 24s24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24zm24-72c8.4 0 16.5 1.4 24 4.1V184 160h48v24 96 48 24H416v-4.1c-7.5 2.7-15.6 4.1-24 4.1c-39.8 0-72-32.2-72-72s32.2-72 72-72zM192.3 160H208h4 16.1l6.1 14.8L307.4 352H255.4l-9.9-24h-74l-10.4 24H108.8L186 174.4l6.3-14.4zm.2 120h33.3l-16.2-39.2L192.4 280z"/>
                </svg>
            )
        case 'navigation-tenants':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 16 12">
                    <path d="M1.33332 10.6667V3.33333H0.666656V2H2.66666V0.666667C2.66666 0.489856 2.73689 0.320286 2.86192 0.195262C2.98694 0.0702379 3.15651 0 3.33332 0H12.6667C12.8435 0 13.013 0.0702379 13.1381 0.195262C13.2631 0.320286 13.3333 0.489856 13.3333 0.666667V2H15.3333V3.33333H14.6667V10.6667H15.3333V12H0.666656V10.6667H1.33332ZM8.66666 10.6667V6H7.33332V10.6667H8.66666ZM5.33332 10.6667V6H3.99999V10.6667H5.33332ZM12 10.6667V6H10.6667V10.6667H12ZM3.99999 1.33333V2H12V1.33333H3.99999Z" />
                </svg>
            );
        case 'navigation-users':
        case 'users':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 15 15">
                    <path d="M0.333252 14.6667C0.333252 13.2523 0.895155 11.8957 1.89535 10.8955C2.89554 9.89532 4.2521 9.33342 5.66659 9.33342C7.08107 9.33342 8.43763 9.89532 9.43782 10.8955C10.438 11.8957 10.9999 13.2523 10.9999 14.6667H0.333252ZM5.66659 8.66675C3.45659 8.66675 1.66659 6.87675 1.66659 4.66675C1.66659 2.45675 3.45659 0.666748 5.66659 0.666748C7.87659 0.666748 9.66659 2.45675 9.66659 4.66675C9.66659 6.87675 7.87659 8.66675 5.66659 8.66675ZM10.5753 10.1554C11.595 10.4175 12.5061 10.9946 13.1788 11.8046C13.8515 12.6146 14.2515 13.6162 14.3219 14.6667H12.3333C12.3333 12.9267 11.6666 11.3427 10.5753 10.1554ZM9.22659 8.63808C9.78525 8.1384 10.232 7.52631 10.5376 6.84191C10.8432 6.15752 11.0008 5.41627 10.9999 4.66675C11.0013 3.75572 10.7683 2.85966 10.3233 2.06475C11.0783 2.21647 11.7576 2.625 12.2456 3.22088C12.7335 3.81676 13.0001 4.56323 12.9999 5.33342C13.0001 5.80839 12.8987 6.27792 12.7027 6.71053C12.5066 7.14314 12.2203 7.52884 11.863 7.84178C11.5056 8.15471 11.0856 8.38765 10.6309 8.52499C10.1762 8.66233 9.69739 8.70089 9.22659 8.63808Z" />
                </svg>
            );
        case 'notification-both':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 18 20">
                    <path className="st0" d="M11.6,1.3c-0.3,0.7-0.3,1.5-0.1,2.2C11.6,4.3,12,5,12.5,5.5C13,6,13.7,6.4,14.4,6.6c0.7,0.2,1.5,0.1,2.2-0.1v6.2c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2H5.3c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5V2c0-0.2,0.1-0.3,0.2-0.5C5,1.4,5.2,1.3,5.3,1.3H11.6z M15.3,5.3c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.2-0.8-1.9s0.3-1.4,0.8-1.9C13.9,0.3,14.6,0,15.3,0c0.7,0,1.4,0.3,1.9,0.8C17.7,1.3,18,2,18,2.7s-0.3,1.4-0.8,1.9C16.7,5.1,16,5.3,15.3,5.3z"/>
                    <path className="st0" d="M8,5.3H1.3C0.6,5.3,0,5.9,0,6.7v12C0,19.4,0.6,20,1.3,20H8c0.7,0,1.3-0.6,1.3-1.3v-12C9.3,5.9,8.7,5.3,8,5.3zM8,17.3H1.3V8H8V17.3z M2,8.7h5.3V10H2V8.7z"/>
                </svg>
            );
        case 'notification-in-app':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 14">
                    <path d="M6.894 1.66671C6.64173 2.37889 6.59633 3.14796 6.76305 3.88488C6.92976 4.6218 7.30178 5.29643 7.83603 5.83068C8.37027 6.36493 9.04491 6.73695 9.78183 6.90366C10.5187 7.07038 11.2878 7.02497 12 6.77271V13C12 13.1769 11.9298 13.3464 11.8047 13.4714C11.6797 13.5965 11.5101 13.6667 11.3333 13.6667H0.666667C0.489856 13.6667 0.320286 13.5965 0.195262 13.4714C0.0702379 13.3464 0 13.1769 0 13V2.33337C0 2.15656 0.0702379 1.98699 0.195262 1.86197C0.320286 1.73695 0.489856 1.66671 0.666667 1.66671H6.894ZM10.6667 5.66671C9.95942 5.66671 9.28115 5.38576 8.78105 4.88566C8.28095 4.38556 8 3.70728 8 3.00004C8 2.2928 8.28095 1.61452 8.78105 1.11442C9.28115 0.614326 9.95942 0.333374 10.6667 0.333374C11.3739 0.333374 12.0522 0.614326 12.5523 1.11442C13.0524 1.61452 13.3333 2.2928 13.3333 3.00004C13.3333 3.70728 13.0524 4.38556 12.5523 4.88566C12.0522 5.38576 11.3739 5.66671 10.6667 5.66671Z" fill="#14171A"/>
                </svg>
            );
        case 'notification-push':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 10 16">
                    <path d="M8.33334 0.666626H1.66668C0.933344 0.666626 0.333344 1.26663 0.333344 1.99996V14C0.333344 14.7333 0.933344 15.3333 1.66668 15.3333H8.33334C9.06668 15.3333 9.66668 14.7333 9.66668 14V1.99996C9.66668 1.26663 9.06668 0.666626 8.33334 0.666626ZM8.33334 12.6666H1.66668V3.33329H8.33334V12.6666ZM2.33334 3.99996H7.66668V5.33329H2.33334V3.99996Z" fill="#14171A"/>
                </svg>
            );
        case 'paperclip':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 448 512">
                    <path d="M375 73c-26-26-68.1-26-94.1 0L89 265C45.3 308.6 45.3 379.4 89 423s114.4 43.6 158.1 0L399 271c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L281 457c-62.4 62.4-163.5 62.4-225.9 0S-7.4 293.4 55 231L247 39C291.7-5.7 364.2-5.7 409 39s44.7 117.2 0 161.9L225.2 384.7c-31.6 31.6-83.6 28.7-111.5-6.2c-23.8-29.8-21.5-72.8 5.5-99.8L271 127c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L153.2 312.7c-9.7 9.7-10.6 25.1-2 35.8c10 12.5 28.7 13.6 40 2.2L375 167c26-26 26-68.1 0-94.1z"/>
                </svg>
            );
        case 'plus':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 9.3 9.3" >
                    <path d="M4,4V0h1.3v4h4v1.3h-4v4H4v-4H0V4H4z" />
                </svg>
            );
        case 'navigation-giving':
        case 'plus-circle':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 20 20">
                    <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 9H5V11H9V15H11V11H15V9H11V5H9V9Z" />
                </svg>
            );
        case 'poll':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 20 20">
                    <path d="M19 0C19.552 0 20 0.448 20 1V15C20 15.552 19.552 16 19 16H4.455L0 19.5V1C0 0.448 0.448 0 1 0H19ZM11 4H9V12H11V4ZM15 6H13V12H15V6ZM7 8H5V12H7V8Z" />
                </svg>
            )
        case 'private':
        case 'locked':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 14">
                    <path d="M10.6667 5.66659H11.3333C11.5101 5.66659 11.6797 5.73682 11.8047 5.86185C11.9298 5.98687 12 6.15644 12 6.33325V12.9999C12 13.1767 11.9298 13.3463 11.8047 13.4713C11.6797 13.5963 11.5101 13.6666 11.3333 13.6666H0.666667C0.489856 13.6666 0.320286 13.5963 0.195262 13.4713C0.0702379 13.3463 0 13.1767 0 12.9999V6.33325C0 6.15644 0.0702379 5.98687 0.195262 5.86185C0.320286 5.73682 0.489856 5.66659 0.666667 5.66659H1.33333V4.99992C1.33333 4.38708 1.45404 3.78025 1.68856 3.21406C1.92308 2.64788 2.26683 2.13343 2.70017 1.70009C3.13351 1.26675 3.64796 0.923003 4.21414 0.688481C4.78033 0.453959 5.38716 0.333252 6 0.333252C6.61284 0.333252 7.21967 0.453959 7.78586 0.688481C8.35204 0.923003 8.86649 1.26675 9.29983 1.70009C9.73317 2.13343 10.0769 2.64788 10.3114 3.21406C10.546 3.78025 10.6667 4.38708 10.6667 4.99992V5.66659ZM9.33333 5.66659V4.99992C9.33333 4.11586 8.98214 3.26802 8.35702 2.6429C7.7319 2.01777 6.88406 1.66659 6 1.66659C5.11595 1.66659 4.2681 2.01777 3.64298 2.6429C3.01786 3.26802 2.66667 4.11586 2.66667 4.99992V5.66659H9.33333ZM5.33333 8.33325V10.9999H6.66667V8.33325H5.33333Z" />
                </svg>
            )
        case 'public':
        case 'unlocked':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 14">
                    <path d="M2.66667 5.66681H11.3333C11.5101 5.66681 11.6797 5.73705 11.8047 5.86207C11.9298 5.9871 12 6.15666 12 6.33348V13.0001C12 13.177 11.9298 13.3465 11.8047 13.4715C11.6797 13.5966 11.5101 13.6668 11.3333 13.6668H0.666667C0.489856 13.6668 0.320286 13.5966 0.195262 13.4715C0.0702379 13.3465 0 13.177 0 13.0001V6.33348C0 6.15666 0.0702379 5.9871 0.195262 5.86207C0.320286 5.73705 0.489856 5.66681 0.666667 5.66681H1.33333V5.00014C1.33295 3.94861 1.68772 2.92777 2.34013 2.1031C2.99253 1.27843 3.90434 0.698261 4.92773 0.45665C5.95113 0.215038 7.02613 0.326141 7.97848 0.771949C8.93084 1.21776 9.70471 1.97214 10.1747 2.91281L8.982 3.50881C8.64633 2.83682 8.09353 2.29791 7.41322 1.97944C6.73291 1.66097 5.96497 1.58162 5.23392 1.75426C4.50287 1.92689 3.85155 2.34139 3.38557 2.93055C2.91959 3.5197 2.66627 4.24898 2.66667 5.00014V5.66681ZM4.66667 9.00014V10.3335H7.33333V9.00014H4.66667Z" />
                </svg>
            );
        case 'refresh':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 512 512">
                    <path d="M69.4 210.6C89.8 126.5 165.6 64 256 64c71.1 0 133.1 38.6 166.3 96H368c-8.8 0-16 7.2-16 16s7.2 16 16 16h80.7H464c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v60.6C408.8 75.5 337.5 32 256 32C149.6 32 60.5 106.2 37.7 205.6C35.5 215.2 43.1 224 53 224c7.9 0 14.6-5.7 16.5-13.4zm373.2 90.9C422.2 385.5 346.4 448 256 448c-71.1 0-133.1-38.6-166.3-96h54.5c8.8 0 16-7.2 16-16s-7.2-16-16-16H63.3 48.2c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V371.8C103.4 436.6 174.7 480 256 480c106.4 0 195.5-74.2 218.3-173.6c2.2-9.6-5.4-18.4-15.3-18.4c-7.9 0-14.6 5.7-16.5 13.4z"/>
                </svg>
            );
        case 'resources-disability_services':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 17 20">
                    <path d="M4 8.341V10.535C2.804 11.227 2 12.52 2 14C2 16.21 3.79 18 6 18C7.48 18 8.773 17.196 9.465 16H11.658C10.835 18.33 8.612 20 6 20C2.686 20 0 17.314 0 14C0 11.387 1.67 9.165 4 8.341ZM8 15C6.343 15 5 13.657 5 12V8C5 6.956 5.534 6.036 6.343 5.499C5.533 4.964 5 4.044 5 3C5 1.343 6.343 0 8 0C9.657 0 11 1.343 11 3C11 4.044 10.466 4.964 9.657 5.501C10.467 6.036 11 6.956 11 8V12.999L12.434 13C13.082 13 13.687 13.314 14.06 13.836L14.149 13.971L16.857 18.486L15.143 19.514L12.433 15L11 14.999L8 15ZM8 7C7.448 7 7 7.448 7 8V12C7 12.552 7.448 13 8 13H8.999L9 8C9 7.448 8.552 7 8 7ZM8 2C7.448 2 7 2.448 7 3C7 3.552 7.448 4 8 4C8.552 4 9 3.552 9 3C9 2.448 8.552 2 8 2Z" />
                </svg>
            );

        case 'resources-employment':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 20 18">
                    <path d="M13 0C13.552 0 14 0.448 14 1V3H19C19.552 3 20 3.448 20 4V17C20 17.552 19.552 18 19 18H1C0.448 18 0 17.552 0 17V4C0 3.448 0.448 3 1 3H6V1C6 0.448 6.448 0 7 0H13ZM14 5H6V16H14V5ZM2 5V16H4V5H2ZM12 2H8V3H12V2ZM16 5V16H18V5H16Z" />
                </svg>
            );
        case 'resources-financial_aid':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 20 20">
                    <path d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM6.5 12H12C12.1326 12 12.2598 11.9473 12.3536 11.8536C12.4473 11.7598 12.5 11.6326 12.5 11.5C12.5 11.3674 12.4473 11.2402 12.3536 11.1464C12.2598 11.0527 12.1326 11 12 11H8C7.33696 11 6.70107 10.7366 6.23223 10.2678C5.76339 9.79893 5.5 9.16304 5.5 8.5C5.5 7.83696 5.76339 7.20107 6.23223 6.73223C6.70107 6.26339 7.33696 6 8 6H9V4H11V6H13.5V8H8C7.86739 8 7.74021 8.05268 7.64645 8.14645C7.55268 8.24021 7.5 8.36739 7.5 8.5C7.5 8.63261 7.55268 8.75979 7.64645 8.85355C7.74021 8.94732 7.86739 9 8 9H12C12.663 9 13.2989 9.26339 13.7678 9.73223C14.2366 10.2011 14.5 10.837 14.5 11.5C14.5 12.163 14.2366 12.7989 13.7678 13.2678C13.2989 13.7366 12.663 14 12 14H11V16H9V14H6.5V12Z" />
                </svg>
            );
        case 'resources-health_forms':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 18 20">
                    <path d="M17 0C17.552 0 18 0.448 18 1V19C18 19.552 17.552 20 17 20H3C2.448 20 2 19.552 2 19V17H0V15H2V13H0V11H2V9H0V7H2V5H0V3H2V1C2 0.448 2.448 0 3 0H17ZM16 2H4V18H16V2ZM11 6V9H14V11H10.999L11 14H9L8.999 11H6V9H9V6H11Z" />
                </svg>
            );
        case 'resources-housing':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 20 18">
                    <path d="M19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 5.98703e-09 17.2652 5.98703e-09 17V9.487C-1.56288e-05 9.34411 0.0305909 9.20288 0.0897577 9.07281C0.148924 8.94275 0.235277 8.82688 0.343 8.733L4 5.544V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18ZM7 16H10V9.942L6 6.454L2 9.942V16H5V12H7V16ZM12 16H18V2H6V4.127C6.234 4.127 6.469 4.209 6.657 4.374L11.657 8.733C11.7647 8.82688 11.8511 8.94275 11.9102 9.07281C11.9694 9.20288 12 9.34411 12 9.487V16ZM14 8H16V10H14V8ZM14 12H16V14H14V12ZM14 4H16V6H14V4ZM10 4H12V6H10V4Z" />
                </svg>
            );
        case 'resources-key_dates':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 21 20">
                    <path d="M7.5 0V2H13.5V0H15.5V2H19.5C19.7652 2 20.0196 2.10536 20.2071 2.29289C20.3946 2.48043 20.5 2.73478 20.5 3V19C20.5 19.2652 20.3946 19.5196 20.2071 19.7071C20.0196 19.8946 19.7652 20 19.5 20H1.5C1.23478 20 0.98043 19.8946 0.792893 19.7071C0.605357 19.5196 0.5 19.2652 0.5 19V3C0.5 2.73478 0.605357 2.48043 0.792893 2.29289C0.98043 2.10536 1.23478 2 1.5 2H5.5V0H7.5ZM18.5 9H2.5V18H18.5V9ZM13.536 10.136L14.95 11.55L10 16.5L6.464 12.964L7.88 11.55L10.001 13.672L13.537 10.136H13.536ZM5.5 4H2.5V7H18.5V4H15.5V5H13.5V4H7.5V5H5.5V4Z" />
                </svg>
            );
        case 'resources-meal_plans':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 16 20">
                    <path d="M3.625 20V10.8C2.75833 10.6167 2.02083 10.1792 1.4125 9.4875C0.804167 8.79583 0.5 7.96667 0.5 7V0H2V7H3.625V0H5.125V7H6.75V0H8.25V7C8.25 7.96667 7.94583 8.79583 7.3375 9.4875C6.72917 10.1792 5.99167 10.6167 5.125 10.8V20H3.625ZM14 20V12H11.125V4.375C11.125 3.05833 11.525 2 12.325 1.2C13.125 0.4 14.1833 0 15.5 0V20H14Z" />
                </svg>
            );
        case 'resources-safety_information':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 19 22">
                    <path d="M9.5 0L17.717 1.826C18.174 1.928 18.5 2.333 18.5 2.802V12.789C18.5 14.795 17.497 16.669 15.828 17.781L9.5 22L3.172 17.781C1.502 16.668 0.5 14.795 0.5 12.79V2.802C0.5 2.333 0.826 1.928 1.283 1.826L9.5 0ZM9.5 2.049L2.5 3.604V12.789C2.5 14.126 3.168 15.375 4.281 16.117L9.5 19.597L14.719 16.117C15.832 15.375 16.5 14.127 16.5 12.79V3.604L9.5 2.05V2.049ZM13.952 7.222L15.367 8.636L9.003 15L4.76 10.757L6.174 9.343L9.002 12.171L13.952 7.221V7.222Z" />
                </svg>
            );
        case 'search':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 14">
                    <path d="M6.33301 0.333313C9.64501 0.333313 12.333 3.02131 12.333 6.33331C12.333 9.64531 9.64501 12.3333 6.33301 12.3333C3.02101 12.3333 0.333008 9.64531 0.333008 6.33331C0.333008 3.02131 3.02101 0.333313 6.33301 0.333313ZM6.33301 11C8.91101 11 10.9997 8.91131 10.9997 6.33331C10.9997 3.75465 8.91101 1.66665 6.33301 1.66665C3.75434 1.66665 1.66634 3.75465 1.66634 6.33331C1.66634 8.91131 3.75434 11 6.33301 11ZM11.9897 11.0473L13.8757 12.9326L12.9323 13.876L11.047 11.99L11.9897 11.0473Z" />
                </svg>
            );
        case 'send':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 23 20">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.21496 0.280415C1.50684 -0.00292557 1.94056 -0.0794365 2.31085 0.0870946L22.3228 9.08709C22.6807 9.24806 22.9111 9.60562 22.9111 10C22.9111 10.3944 22.6807 10.752 22.3228 10.913L2.31085 19.913C1.94056 20.0795 1.50684 20.003 1.21496 19.7196C0.923079 19.4363 0.831601 19.003 0.983819 18.6247L4.45486 10L0.983819 1.37531C0.831601 0.997084 0.923079 0.563756 1.21496 0.280415ZM6.19719 11L3.75282 17.0737L17.258 11H6.19719ZM17.258 9.00002H6.19719L3.75282 2.92634L17.258 9.00002Z" />
                </svg>
            );
        case 'signout':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 15 14">
                    <path d="M3.33333 6.33325H8.66667V7.66659H3.33333V9.66659L0 6.99992L3.33333 4.33325V6.33325ZM2.66667 10.9999H4.472C5.24179 11.6788 6.19112 12.1212 7.20608 12.2739C8.22105 12.4266 9.25852 12.2832 10.194 11.8609C11.1295 11.4386 11.9233 10.7554 12.48 9.89313C13.0368 9.03089 13.333 8.02631 13.333 6.99992C13.333 5.97353 13.0368 4.96895 12.48 4.10671C11.9233 3.24447 11.1295 2.56121 10.194 2.13892C9.25852 1.71662 8.22105 1.57324 7.20608 1.72596C6.19112 1.87868 5.24179 2.32103 4.472 2.99992H2.66667C3.28711 2.17137 4.09222 1.49893 5.01805 1.03602C5.94387 0.573102 6.9649 0.332476 8 0.333254C11.682 0.333254 14.6667 3.31792 14.6667 6.99992C14.6667 10.6819 11.682 13.6666 8 13.6666C6.9649 13.6674 5.94387 13.4267 5.01805 12.9638C4.09222 12.5009 3.28711 11.8285 2.66667 10.9999Z" />
                </svg>
            );
        case 'star':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 24 24" fill="currentColor">
                    <path d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"  />
                </svg>
            );
        case 'tag':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 15 15">
                    <path d="M7.26673 0.399414L13.8661 1.34275L14.8087 7.94275L8.68073 14.0707C8.55571 14.1957 8.38617 14.2659 8.2094 14.2659C8.03262 14.2659 7.86308 14.1957 7.73806 14.0707L1.13806 7.47075C1.01308 7.34573 0.942871 7.17619 0.942871 6.99941C0.942871 6.82264 1.01308 6.6531 1.13806 6.52808L7.26673 0.399414ZM9.15206 6.05675C9.2759 6.18054 9.42291 6.27873 9.58469 6.34571C9.74647 6.41269 9.91986 6.44715 10.095 6.44712C10.2701 6.44708 10.4434 6.41257 10.6052 6.34553C10.767 6.27849 10.9139 6.18025 11.0377 6.05641C11.1615 5.93258 11.2597 5.78557 11.3267 5.62379C11.3937 5.462 11.4281 5.28861 11.4281 5.11351C11.4281 4.93841 11.3935 4.76503 11.3265 4.60327C11.2595 4.44151 11.1612 4.29454 11.0374 4.17075C10.9136 4.04695 10.7666 3.94877 10.6048 3.88179C10.443 3.81481 10.2696 3.78035 10.0945 3.78038C9.74086 3.78044 9.40174 3.92098 9.15173 4.17108C8.90172 4.42118 8.7613 4.76035 8.76136 5.11398C8.76142 5.46761 8.90196 5.80674 9.15206 6.05675Z" fill="#0057FF"/>
                </svg>
            );
        case 'team':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 20 20">
                    <path d="M10 9C11.3261 9 12.5979 9.52678 13.5355 10.4645C14.4732 11.4021 15 12.6739 15 14V20H5V14C5 12.6739 5.52678 11.4021 6.46447 10.4645C7.40215 9.52678 8.67392 9 10 9ZM3.288 12.006C3.12886 12.5428 3.03485 13.0968 3.008 13.656L3 14V20H9.13169e-08V15.5C-0.000196807 14.6376 0.318028 13.8054 0.893635 13.1632C1.46924 12.521 2.2617 12.1139 3.119 12.02L3.289 12.006H3.288ZM16.712 12.006C17.6019 12.0602 18.4376 12.452 19.0486 13.1012C19.6596 13.7505 19.9999 14.6084 20 15.5V20H17V14C17 13.307 16.9 12.638 16.712 12.006ZM3.5 6C4.16304 6 4.79893 6.26339 5.26777 6.73223C5.73661 7.20107 6 7.83696 6 8.5C6 9.16304 5.73661 9.79893 5.26777 10.2678C4.79893 10.7366 4.16304 11 3.5 11C2.83696 11 2.20107 10.7366 1.73223 10.2678C1.26339 9.79893 1 9.16304 1 8.5C1 7.83696 1.26339 7.20107 1.73223 6.73223C2.20107 6.26339 2.83696 6 3.5 6ZM16.5 6C17.163 6 17.7989 6.26339 18.2678 6.73223C18.7366 7.20107 19 7.83696 19 8.5C19 9.16304 18.7366 9.79893 18.2678 10.2678C17.7989 10.7366 17.163 11 16.5 11C15.837 11 15.2011 10.7366 14.7322 10.2678C14.2634 9.79893 14 9.16304 14 8.5C14 7.83696 14.2634 7.20107 14.7322 6.73223C15.2011 6.26339 15.837 6 16.5 6ZM10 0C11.0609 0 12.0783 0.421427 12.8284 1.17157C13.5786 1.92172 14 2.93913 14 4C14 5.06087 13.5786 6.07828 12.8284 6.82843C12.0783 7.57857 11.0609 8 10 8C8.93913 8 7.92172 7.57857 7.17157 6.82843C6.42143 6.07828 6 5.06087 6 4C6 2.93913 6.42143 1.92172 7.17157 1.17157C7.92172 0.421427 8.93913 0 10 0Z" />
                </svg>
            );
        case 'user-remove':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 15">
                    <path d="M7.33366 9.50166V14.667H0.666992C0.666785 13.8529 0.85294 13.0496 1.21119 12.3186C1.56944 11.5876 2.09028 10.9483 2.7338 10.4497C3.37732 9.95108 4.12644 9.60641 4.92376 9.44209C5.72108 9.27776 6.54543 9.29814 7.33366 9.50166V9.50166ZM6.00033 8.66699C3.79033 8.66699 2.00033 6.87699 2.00033 4.66699C2.00033 2.45699 3.79033 0.666992 6.00033 0.666992C8.21033 0.666992 10.0003 2.45699 10.0003 4.66699C10.0003 6.87699 8.21033 8.66699 6.00033 8.66699ZM10.667 11.0577L12.081 9.64299L13.0243 10.5863L11.6097 12.0003L13.0243 13.4143L12.081 14.3577L10.667 12.943L9.25299 14.3577L8.30966 13.4143L9.72433 12.0003L8.30966 10.5863L9.25299 9.64299L10.667 11.0577Z" fill="#0057FF"/>
                </svg>
            );
        case 'x':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 9.3 9.3">
                    <path d="M3.5,4.6L0,1.1L1.1,0l3.5,3.5L8.1,0l1.2,1.2L5.8,4.6l3.5,3.5L8.1,9.3L4.6,5.8L1.1,9.3L0,8.1L3.5,4.6z"/>
                </svg>
            );
        default:
            return null;
    }
};

export default Icon;
