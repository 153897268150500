import './CreateEditGroup.scss';

import React from 'react';

import { useTypedSelector } from "../../../../../store/reducers";

import CreateEditGroupForm from "./CreateEditGroupForm";

type Props = {
    close?: Function
}

const CreateEditGroup: React.FC<Props> = ({
    close
}) => {
    const { group } = useTypedSelector((store) => store.groups);

    return (
        <div className="create-edit-group">
            <CreateEditGroupForm
                close={close}
                group={group}
            />
        </div>
    );
};

export default CreateEditGroup;
