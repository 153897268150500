import './Logout.scss';

import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {clearLocalStorageData} from "../../../utils/auth";

import { resetGlobalState } from "../../../store/slices/auth";
import { clearActiveSchool } from "../../../store/slices/schools";

import ActivityIndicator from '../../../components/ActivityIndicator';

const Logout = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(clearActiveSchool());
        dispatch(resetGlobalState());

        clearLocalStorageData();

        // Force the browser to refresh to cancel any pending requests
        window.location.href = '/auth/login';
    }, [dispatch, history]);

    return (
        <>
            <Helmet>
                <title>Logging Out</title>
            </Helmet>

            <div className="auth-form">
                <div className="logout-indicator">
                    <ActivityIndicator />
                </div>
            </div>
        </>
    );
};

export default Logout;
