import './SchoolMarketplaceItems.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from "react-router";
import clone from "clone";

import TableColumn from "../../../../types/TableColumn";

import { formatAsDollarAmount, isArrayNullOrEmpty } from "../../../../utils/utils";
import { LIST_TYPES } from "../../../../utils/constants";

import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';
import {
    clearMarketplaceItem,
    clearMarketplaceItemsMetadata,
    getMarketplaceItems,
    setSearchTerm
} from "../../../../store/slices/marketplaceItems";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import Button, { ButtonSizes, ButtonThemes } from "../../../../components/Button";
import CreateEditMarketplaceItem from "../../../Content/Marketplace/CreateEditMarketplaceItem";
import GridList from "../../../../components/GridList";
import H5 from "../../../../components/H5";
import Icon from "../../../../components/Icon";
import ListTypeSelector from "../../../../components/ListTypeSelector";
import MarketplaceItemTableMoreMenu from "./MarketplaceItemTableMoreMenu";
import NewContentModal from "../../../../components/NewContentModal";
import PageHeader from "../../../../components/PageHeader";
import Table from "../../../../components/Table";
import TableAuthorFilter from "../../../../components/Table/TableAuthorFilter";
import TableFilterRow from "../../../../components/Table/TableFilterRow";
import TableFilterRowDivider from "../../../../components/Table/TableFilterRow/TableFilterRowDivider";
import TableFlaggedOnlyToggle from "../../../../components/Table/TableFlaggedOnlyToggle";
import TablePublishedAt from '../../../../components/Table/TablePublishedAt';
import TableRowImage from "../../../../components/Table/TableRowImage";
import TableSearch from '../../../../components/TableSearch';

type Props = {
    disablePending?: boolean
    onRowClick?: Function
}

const SchoolMarketplaceItems: React.FC<Props> = ({
    disablePending,
    onRowClick,
}) => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const [initialized, setInitialized] = useState(false);
    const [selectedListType, setSelectedListType] = useState(LIST_TYPES.TABLE);
    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const { isGettingMarketplaceItems, marketplaceItems, marketplaceItemsMetadata, searchTerm } = useTypedSelector((state) => state.marketplace);
    const { activeSchool } = useTypedSelector((state) => state.schools);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getMarketplaceItems({schoolId: match?.params?.schoolId}));
            } catch(err) {
                console.log('SchoolMarketplaceItems initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            setShowNewContentModal(false);
            dispatch(clearMarketplaceItem());
            dispatch(clearMarketplaceItemsMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> =  [
                {
                    Header: 'Image',
                    Cell: ({row: { original }}) => (
                        <TableRowImage
                            artifact={!isArrayNullOrEmpty(original.artifacts) ? original.artifacts[0] : original.artifact}
                            flagged={original.flagged}
                        />
                    ),
                    hasImage: true
                }, {
                    Header: 'Title',
                    accessor: 'title',
                    sortBy: 'title'
                }, {
                    Header: 'Author',
                    accessor: (d) => d.profile ? `${d.profile.firstName} ${d.profile.lastName}` : null,
                    sortBy: 'profileFirstName'
                }, {
                    Header: 'Price',
                    accessor: (d) => d.freeItem === 'Y' ? 'FREE' : formatAsDollarAmount(d.price)
                }, {
                    Header: 'Date',
                    accessor: (d) => <TablePublishedAt post={d} />,
                    sortBy: 'publish'
                }, {
                    Header: 'Views',
                    accessor: 'totalViews',
                },
            ];

            if(!onRowClick) {
                columns.push({
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original)
                });
            }

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onRowClick]
    );

    const data = useMemo(
        () => {
            return marketplaceItems;
        },
        [marketplaceItems]
    );

    const handleRowClick = ({original}) => {
        if(!onRowClick) {
            return null;
        }

        onRowClick(original);
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(marketplaceItemsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getMarketplaceItems({isUpdate, marketplaceItemsMetadata: clonedMetadata}));
        } catch(err) {
            console.log('SchoolMarketplaceItems onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item) => {
        if(item.removedByMods) {
            return null;
        }

        return (
            <MarketplaceItemTableMoreMenu
                getAfterDelete={() => onMetadataChange({}, false)}
                item={item}
                setShowNewContentModal={setShowNewContentModal}
            />
        );
    }

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="school-marketplaceItems">
            <PageHeader>
                <H5>
                    <Icon type="navigation-marketplace" />

                    Marketplace
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableAuthorFilter
                    metadata={marketplaceItemsMetadata}
                    onMetadataChange={onMetadataChange}
                />

                <TableFlaggedOnlyToggle
                    label="Flagged only"
                    onToggleChange={() => onMetadataChange({flagged: !marketplaceItemsMetadata.flagged, page_num: 0}, false)}
                    value={marketplaceItemsMetadata.flagged}
                />

                <TableFilterRowDivider />

                <ListTypeSelector
                    onListTypeSelected={(listType) => setSelectedListType(listType)}
                    selected={selectedListType}
                />

                <TableFilterRowDivider />

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search Marketplace"
                    searchTerm={searchTerm}
                    size="small"
                />

                {activeSchool.postAsProfile && (
                    <Button
                        onClick={() => setShowNewContentModal(true)}
                        size={ButtonSizes.Small}
                    >
                        New Marketplace Item
                    </Button>
                )}
            </TableFilterRow>

            {isGettingMarketplaceItems ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                            No listings to display. <Button
                                onClick={() => setShowNewContentModal(true)}
                                theme={ButtonThemes.Link}
                            >
                                Create One?
                            </Button>
                        </>
                    ) : (
                        <>
                            {selectedListType === LIST_TYPES.TABLE ? (
                                <Table
                                    columns={columns}
                                    data={data}
                                    disablePending={disablePending}
                                    getData={onMetadataChange}
                                    onRowClick={onRowClick && handleRowClick}
                                    pageNumber={marketplaceItemsMetadata.page_num}
                                    pageSize={marketplaceItemsMetadata.page_size}
                                    sortBy={marketplaceItemsMetadata.sort}
                                    sortOrder={marketplaceItemsMetadata.order}
                                    totalNumberOfItems={marketplaceItemsMetadata.total}
                                />
                            ) : (
                                <GridList
                                    createCardButtonOnClick={() => setShowNewContentModal(true)}
                                    createCardButtonText="Create a Marketplace Item"
                                    createCardText="Sell a new or used item in the Abuzz marketplace"
                                    disablePending={disablePending}
                                    isLastPage={Math.ceil(marketplaceItemsMetadata.total / marketplaceItemsMetadata.page_size) === marketplaceItemsMetadata.page_num + 1}
                                    items={data}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: marketplaceItemsMetadata.page_num + 1})}
                                    onOverlayClick={onRowClick ? (item) => onRowClick(item) : null}
                                    onPreviousPageClick={() => onMetadataChange({page_num: marketplaceItemsMetadata.page_num - 1})}
                                    overlayButtonText={onRowClick ? 'Select' : 'Edit'}
                                    pageNumber={marketplaceItemsMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                <CreateEditMarketplaceItem
                    close={() => setShowNewContentModal(false)}
                />
            </NewContentModal>
        </div>
    );
};

export default SchoolMarketplaceItems;
