import './Badge.scss';

import React from 'react';

type Props = {
    className?: string
    color?: 'green' | 'red'
    text: string,
}

const Badge: React.FC<Props> = ({
    className= '',
    color = 'green',
    text
                                }) => {
    return (
        <div className={`c-badge a-badge--${color} ${className}`}>
            {text}
        </div>
    );
};

export default Badge;
