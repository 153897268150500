import React from 'react';

import { useTypedSelector } from '../../../../store/reducers';

import CreateEditGivingCategoryForm from "./CreateEditGivingCategoryForm";

type Props = {
    close?: Function
}

const CreateEditGivingCategory: React.FC<Props> = ({
                                                        close
                                                    }) => {
    const { givingCategory } = useTypedSelector(store => store.giving);

    return (
        <>
            <CreateEditGivingCategoryForm
                close={close}
                givingCategory={givingCategory}
            />
        </>
    )
}
export default CreateEditGivingCategory;
