import React from 'react';

import { useTypedSelector } from "../../../../../../store/reducers";

import H4 from "../../../../../../components/H4";
import InviteUsersViaEmailForm from "./InviteUsersViaEmailForm";

type Props = {
    closeModal: Function
    onSuccess: Function
};

const InviteUsersViaEmail: React.FC<Props> = ({
    closeModal,
    onSuccess,
}) => {
    const { inviteEmailListCsv } = useTypedSelector((state) => state.groups);

    return (
        <div>
            <H4>Invite Users</H4>

            <p>Enter a comma-separated list of email addresses to invite to this group.</p>

            <InviteUsersViaEmailForm onCancel={closeModal} emails={inviteEmailListCsv} onSuccess={onSuccess} />
        </div>
    );
};

export default InviteUsersViaEmail;
