import './TableRowImage.scss';

import React from 'react';

import { isObjectNullOrEmpty } from "../../../utils/utils";
import buildImageSrc from "../../../utils/buildImageSrc";
import Artifact from "../../../types/Artifact";
import FlaggedIcon from "../../Icon/FlaggedIcon";
import VideoIcon from "../../Icon/VideoIcon";

type Props = {
    artifact?: Artifact
    className?: string
    flagged?: boolean
    placeholder?: string
}

const TableRowImage: React.FC<Props> = ({
    artifact,
    className,
    flagged,
    placeholder
}) => {
    let bgUrl = null;
    if(!isObjectNullOrEmpty(artifact)) {
        bgUrl = `url(${buildImageSrc(artifact, '220')})`
    } else if(placeholder) {
        bgUrl = `url(${placeholder})`
    }

    return (
        <div
            className={`c-table-row-image ${className || ''}`}
            style={bgUrl ? {backgroundImage: bgUrl} : null}
        >
            {flagged && <FlaggedIcon />}
            {(artifact?.type && artifact.type === 'video') && <VideoIcon />}
        </div>
    );
};

export default TableRowImage;
