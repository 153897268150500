import './PreviewLandingPageGroup.scss';

import React from 'react';

import buildImageSrc from "../../../../../../../../../utils/buildImageSrc";
import { isObjectNullOrEmpty } from "../../../../../../../../../utils/utils";

import Button, { ButtonSizes, ButtonThemes } from "../../../../../../../../../components/Button";
import Group from "../../../../../../../../../types/Group";

type Props = {
    item: Group
}

const PreviewLandingPageGroup: React.FC<Props> = ({item}) => {
    let group = item;
    return (
        <div className="c-preview-landing-page__group">
            <div className="c-preview-landing-page__group-artifact" style={{backgroundImage: !isObjectNullOrEmpty(group.artifact) ? `url(${buildImageSrc(group.artifact, '220')})` : `url(/img/abuzz-group-placeholder.svg)`}} />

            <div className="c-preview-landing-page__group-content">
                <div className="c-preview-landing-page__group-name">
                    {group.name}
                </div>

                <div className="c-preview-landing-page__group-description">
                    {group.description}
                </div>

                <div className="c-preview-landing-page__group-join-button-container">
                    <Button
                        size={ButtonSizes.Small}
                        theme={ButtonThemes.Light}
                    >
                        Join
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PreviewLandingPageGroup;
