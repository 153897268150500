import './TenantActivityReportGenerator.scss';

import React, { useEffect } from 'react';

import { clearConfig } from '../../../../store/slices/activityReportGenerator';
import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';

import Card from '../../../../components/Card';
import H5 from '../../../../components/H5';
import H6 from '../../../../components/H6';
import Icon from '../../../../components/Icon';
import PageHeader from '../../../../components/PageHeader';
import TenantActivityReportGeneratorForm from './TenantActivityReportGeneratorForm';
import Tooltip from '../../../../components/Tooltip';


const TenantActivityReportGenerator: React.FC = () => {
    const dispatch = useAppDispatch();

    const { config } = useTypedSelector(state => state.activityReportGenerator);

    useEffect(() => {
        return () => {
            dispatch(clearConfig());
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="tenant-activity-report-generator">
            <PageHeader>
                <H5 className="tenant-activity-report-generator__header-title">
                    <Icon type="document-plus" />

                    User Activity Report Generator
                </H5>

                <Tooltip icon="info">
                    <H6>
                        Usage Instructions:
                    </H6>

                    <p>
                        Select start and end dates for the report, as well as email addresses for whom to send the report to.
                    </p>

                    <p>
                        Our server will process the request, and send an email to each one listed that includes the CSV
                        files containing the activity counts for the selected date range as an attachment.
                    </p>
                </Tooltip>
            </PageHeader>

            <Card>
                <TenantActivityReportGeneratorForm
                    config={config}
                />
            </Card>
        </div>

    );
};

export default TenantActivityReportGenerator;
