import './Dropdown.scss';

import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';

import DropdownOption from "../../types/DropdownOption";
import Validation from "../../types/Validation";

import FormValidationMessage from '../FormValidationMessage';
import Label from '../Label';

const getValue = (options, value) => {
    if(value == null || typeof value === 'object') {
        return value;
    } else {
        return options.find(o => value === o.value);
    }
};

type Props = {
    className?: string
    components?: any
    disabled?: boolean
    hiddenLabel?: boolean
    id?: string
    label?: string | React.ReactNode
    isMulti?: boolean
    isSearchable?: boolean
    menuPlacement?: 'auto' | 'bottom' | 'top'
    naked?: boolean
    name: string
    onChange: Function
    options: Array<DropdownOption>
    placeholder?: string
    required?: boolean
    validation?: Validation
    value?: number | object | string
}

const Dropdown: React.FC<Props> = ({
    className,
    disabled,
    hiddenLabel,
    isSearchable,
    label,
    naked,
    required,
    validation,
    value,
    ...otherProps
}) => {
    let classes = classNames(
        'o-dropdown',
        className,
        {
            'has-error': validation != null && validation.show === true && validation.isValid === false,
            'a-dropdown--disabled': disabled === true,
            'a-dropdown--hidden-label': hiddenLabel === true,
            'a-dropdown--naked': naked === true
        }
    );

    return (
        <div className={classes}>
            {label != null ? (
                <Label htmlFor={otherProps.id}>
                    {label} {otherProps.isMulti === true ? '(select all that apply)' : ''} {required === true ? <span className="c-label__required-asterisk">*</span> : ''}
                </Label>
            ) : null}

            <Select
                className="c-dropdown"
                classNamePrefix="c-dropdown"
                isDisabled={disabled || false}
                isSearchable={isSearchable || false}
                value={getValue(otherProps.options, value)}
                {...otherProps}
            />

            {validation?.show && <FormValidationMessage>{validation?.error.reason}</FormValidationMessage>}
        </div>
    )
}

export default Dropdown;
