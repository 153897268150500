import React from "react";

import {useTypedSelector} from "../../../../../../../store/reducers";
import {useAppDispatch} from "../../../../../../../store";
import {setShowChartCards} from "../../../../../../../store/slices/superAdminDashboard";

import ActivityIndicator from "../../../../../../../components/ActivityIndicator";
import Button from "../../../../../../../components/Button";
import Card from "../../../../../../../components/Card";
import FormColumn from "../../../../../../../components/FormColumn";
import FormRow from "../../../../../../../components/FormRow";
import H5 from "../../../../../../../components/H5";
import H6 from "../../../../../../../components/H6";

type SlimDashDataProps = {
    type: string
    todayCount: number
    yesterdayCount: number
    average: number
};

type Props = {
    isGettingDashboard: boolean
    initialized: boolean
    slimDashData: Array<SlimDashDataProps>
};

const DashboardSlimChartCard: React.FC<Props> = ({slimDashData, isGettingDashboard, initialized}) => {
    const dispatch = useAppDispatch()
    const {showChartCards} = useTypedSelector(state => state.superAdminDashboard);

    const handleClick = () => {
        dispatch(setShowChartCards(!showChartCards));
    };

    return (
        <Card className='dashboard-chart__card-slim'>
            <H5 className='dashboard-card__row'>
                Active User Stats
            </H5>
            {isGettingDashboard && !initialized ? (
                <ActivityIndicator size={"large"} />
            ) : (
                <div>
                    <FormRow
                        columnCount={4}
                        className='dashboard-card__detail-row'
                    >
                        <FormColumn className='dashboard-card__detail-subtitle-col--primary'>
                            TYPE
                        </FormColumn>

                        <FormColumn className='dashboard-card__detail-subtitle-col'>
                            TODAY
                        </FormColumn>

                        <FormColumn className='dashboard-card__detail-subtitle-col'>
                            YESTERDAY
                        </FormColumn>

                        <FormColumn className='dashboard-card__detail-subtitle-col'>
                            AVERAGE
                        </FormColumn>
                    </FormRow>
                    {isGettingDashboard && !initialized ? (
                        <ActivityIndicator />
                    ) : (slimDashData.map((data, i) => (
                        <FormRow
                            key={i}
                            columnCount={4}
                            className='dashboard-card__detail-row'
                        >
                            <FormColumn className='dashboard-card__detail-main-col'>
                                <H6>
                                    {data.type}
                                </H6>
                            </FormColumn>

                            <FormColumn className='dashboard-card__detail-stat-col'>
                                {data.todayCount.toLocaleString('en-US')}
                            </FormColumn>

                            <FormColumn className='dashboard-card__detail-stat-col'>
                                {data.yesterdayCount.toLocaleString('en-US')}
                            </FormColumn>

                            <FormColumn className='dashboard-card__detail-stat-col'>
                                {data.average && data.average.toLocaleString('en-US',  { maximumFractionDigits: 0 })}
                            </FormColumn>
                        </FormRow>
                        ))
                    )}
                    {!isGettingDashboard && initialized && (
                        <div className='dashboard-chart__card-button'>
                            <Button
                                type='reset'
                                onClick={() => handleClick()}
                            >
                                {showChartCards ? 'Hide Charts' : 'Show Charts'}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </Card>
    )
};

export default DashboardSlimChartCard;
