import '../../ContentForms.scss'

import React, { useEffect, useState } from 'react';
import { validated } from "react-custom-validation";
import clone from "clone";

import GivingCategory from "../../../../types/GivingCategory";
import Validation from "../../../../types/Validation";

import { isRequired } from "../../../../utils/validations";
import {handleTextChange} from "../../../../utils/handle-changes";

import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from "../../../../store/reducers";
import { saveGivingCategory, deleteGivingCategory, setGivingCategory, clearGivingCategory, getGivingCategories } from "../../../../store/slices/giving";

import Button, { ButtonThemes, ButtonTypes } from "../../../../components/Button";
import ButtonRow from "../../../../components/ButtonRow";
import FileUpload from "../../../../components/FileUpload";
import Form from "../../../../components/Form";
import FormRow from "../../../../components/FormRow";
import H4 from "../../../../components/H4";
import Modal from "../../../../components/Modal";
import SuccessModal from "../../../../components/Modal/SuccessModal";
import Textbox from "../../../../components/Textbox";


type Props = {
    close?: Function
    givingCategory: GivingCategory
    $field: Function
    $fieldEvent: Function
    $validation: {
        name: Validation
    }
    $submit: Function
}

const CreateEditGivingCategoryForm: React.FC<Props> = ({
    close,
    givingCategory,
    $field,
    $fieldEvent,
    $validation,
    $submit
}) => {
    const dispatch = useAppDispatch();

    const [isUploadingMainArtifact, setIsUploadingMainArtifact] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const { isSavingGivingCategory, isDeletingGivingCategory } = useTypedSelector(store => store.giving);

    useEffect(() => {
        return () => {
            setTimeout(() => dispatch(clearGivingCategory()), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (name, value) => {
        let clonedGivingCategory = clone(givingCategory);
        clonedGivingCategory[name] = value;

        if (name === 'artifactId') {
            clonedGivingCategory.artifact = undefined;
        }

        dispatch(setGivingCategory(clonedGivingCategory));
    };

    const handleDelete = async () => {
        try {
            await dispatch(deleteGivingCategory({givingCategory})).unwrap();
            $fieldEvent('reset');
            dispatch(getGivingCategories({}));
            close && close();
        } catch (err) {
            console.warn('CreateEditGivingCategoryForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const onUploadStart = () => {
        setIsUploadingMainArtifact(true);
    };

    const onUploadSuccess = (file, artifactId) => {
        if (artifactId) {
            handleChange('artifactId', artifactId);
        }
        setIsUploadingMainArtifact(false);
    };

    const onUploadFailure = () => {
        setIsUploadingMainArtifact(false);
    };

    const onDelete = () => {
        handleChange('artifactId', null);
    };

    const handleSave = async (isValid) => {
        if(isValid) {
            try {
                await dispatch(saveGivingCategory({givingCategory})).unwrap()
                setShowSuccessModal(true);
                setTimeout(() => {
                    dispatch(clearGivingCategory());
                    $fieldEvent('reset');
                })
            } catch(err) {
                console.warn('err from creating post', err);
            }
        }
    };

    return (
        <Form className="content-form">
            <H4>
                {givingCategory.givingCategoryId ? 'Edit ' : 'Create a '}Giving Category
            </H4>

            <FormRow>
                <FileUpload
                    acceptsVideo={false}
                    currentArtifact={givingCategory.artifact || null}
                    onDelete={onDelete}
                    name="artifactId"
                    onStart={onUploadStart}
                    onSuccess={onUploadSuccess}
                    onFailure={onUploadFailure}
                />
            </FormRow>
            <FormRow>
                <Textbox
                    disabled={isUploadingMainArtifact}
                    id="txtName"
                    label="Name"
                    name="name"
                    type="text"
                    validation={$validation.name}
                    value={givingCategory.name || ''}
                    {...$field('title', event => handleTextChange(handleChange, event))}
                />
            </FormRow>
            <FormRow>
                <Textbox
                    disabled={isUploadingMainArtifact}
                    id="txtDescription"
                    label="Description"
                    name="description"
                    type="textarea"
                    value={givingCategory.description || ''}
                    {...$field('description', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <ButtonRow>
                <Button
                    disabled={isUploadingMainArtifact}
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSave(true), () => handleSave(false));
                    }}
                    showActivityIndicator={isSavingGivingCategory}
                    type={ButtonTypes.Submit}
                >
                    Save
                </Button>

                {givingCategory.givingCategoryId && (
                    <Button
                        className="destructive"
                        disabled={isUploadingMainArtifact}
                        onClick={() => setShowDeleteConfirmationModal(true)}
                        theme={ButtonThemes.Link}
                    >
                        Delete
                    </Button>
                )}
            </ButtonRow>

            <SuccessModal
                buttonOnClick={() => {
                    dispatch(getGivingCategories({}));
                    setShowSuccessModal(false);
                    close && close();
                }}
                show={showSuccessModal}
            >
                <p>
                    Your category has been saved.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingGivingCategory}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this category?
            </Modal>

        </Form>
    );
}

function createEditGivingCategoryFormValidationConfig(props) {
    const { name }: GivingCategory = props.givingCategory;

    return {
        fields: ['name'],
        validations: {
            name: [
                [isRequired, name]
            ],


        }
    }
}
export default validated(createEditGivingCategoryFormValidationConfig)(CreateEditGivingCategoryForm);
