import './Feed.scss';

import React, { useState } from 'react';

import Deal from "../../types/Deal";
import EventPost from "../../types/EventPost";
import Group from "../../types/Group";
import ChatMessage from "../../types/ChatMessage";
import FlaggedContentItem from "../../types/FlaggedContentItem";
import MarketplaceItem from "../../types/MarketplaceItem";
import Thread from "../../types/Thread";

import { PostTypes } from "../../utils/enums";

import CreateEditDeal from "../../containers/Content/Deals/CreateEditDeal";
import CreateEditEvent from "../../containers/Content/Events/CreateEditEvent";
import CreateEditGroup from "../../containers/Schools/School/SchoolGroups/CreateEditGroup";
import CreateEditMarketplaceItem from "../../containers/Content/Marketplace/CreateEditMarketplaceItem";
import CreateEditThread from "../../containers/Content/Threads/CreateEditThread";
import FeedClassNote from "./FeedClassNote";
import FeedDeal from './FeedDeal';
import FeedEvent from './FeedEvent';
import FeedGroup from "./FeedGroup";
import FeedMarketplaceItem from './FeedMarketplaceItem';
import FeedThread from './FeedThread';
import NewContentModal from "../NewContentModal";
import FeedGroupChatMessage from "./FeedGroupChatMessage";
import FeedFlaggedBanner from "./FeedFlaggedBanner";
import FeedProfile from "./FeedProfile";
import { isArrayNullOrEmpty } from "../../utils/utils";
import FeedNewsStory from "./FeedNewsStory";
import CreateEditNewsStory from "../../containers/Schools/School/NewsStories/CreateEditNewsStory";

type Props = {
    className?: string,
    disableCommenting?: boolean
    flaggedContent?: Array<FlaggedContentItem>
    items?: Array<Deal | EventPost | Group | ChatMessage | MarketplaceItem | Thread>
}

const Feed: React.FC<Props> = ({
    className = '',
    disableCommenting,
    flaggedContent,
    items,
}) => {
    const [showNewContentModal, setShowNewContentModal] = useState<boolean>(false);
    const [newContentType, setContentType] = useState<PostTypes | 'G'>(null);

    const determineType = (item) => {
        if(item.type) {
            return item.type;
        }

        if(item.post?.type) {
            return item.post.type;
        }

        if(item.forumTopicMessage || item.forumTopicMessageId) {
            return 'groupChatMessage';
        }

        if(item.forumTopic || item.forumTopicId) {
            return 'group';
        }

        if(item.profile || item.profileId) {
            return 'profile';
        }

        return null;
    };

    const generateKey = (item) => {
        return item.postId || item.forumTopicId || item.profileId;
    };

    const renderItem = (item) => {
        let key = generateKey(item);
        const type = determineType(item);
        const post = item.post || item;

        switch(type) {
            case 'D':
                return (
                    <FeedDeal
                        key={key}
                        onEdit={() => {
                            setShowNewContentModal(true);
                            setContentType(PostTypes.Deal);
                        }}
                        post={post}
                    />
                );
            case 'E':
                return (
                    <FeedEvent
                        key={key}
                        onEdit={() => {
                            setShowNewContentModal(true);
                            setContentType(PostTypes.Event);
                        }}
                        post={post}
                    />
                );
            case 'I':
                return (
                    <FeedMarketplaceItem
                        key={key}
                        onEdit={() => {
                            setShowNewContentModal(true);
                            setContentType(PostTypes.MarketplaceItem);
                        }}
                        post={post}
                    />
                );
            case 'N':
                return (
                    <FeedNewsStory
                        key={key}
                        onEdit={() => {
                            setShowNewContentModal(true);
                            setContentType(PostTypes.NewsStory);
                        }}
                        post={post}
                    />
                )
            case 'T':
                return (
                    <FeedThread
                        disableCommenting={disableCommenting}
                        key={key}
                        inFlaggedContent={!isArrayNullOrEmpty(flaggedContent)}
                        onEdit={() => {
                            setShowNewContentModal(true);
                            setContentType(PostTypes.Thread);
                        }}
                        post={post}
                    />
                );
            case 'S':
                return (
                    <FeedClassNote
                        disableCommenting={disableCommenting}
                        key={key}
                        inFlaggedContent={!isArrayNullOrEmpty(flaggedContent)}
                        onEdit={() => {
                            setShowNewContentModal(true);
                            setContentType(PostTypes.ClassNote);
                        }}
                        post={post}
                    />
                );
            case 'group':
                return (
                    <FeedGroup
                        key={key}
                        group={item.forumTopic || item}
                        onEdit={() => {
                            setShowNewContentModal(true);
                            setContentType('G');
                        }}
                    />
                );
            case 'groupChatMessage':
                return (
                    <FeedGroupChatMessage
                        key={key}
                        message={item.forumTopicMessage || item}
                    />
                );
            case 'profile':
                return (
                    <FeedProfile
                        key={key}
                        profile={item.profile || item}
                    />
                );
            default:
                return null;
        }
    };

    const renderForm = () => {
        const closeForm = () => {
            setShowNewContentModal(false);
            setContentType(null);
        };

        switch(newContentType) {
            case 'D':
                return <CreateEditDeal close={closeForm} />;
            case 'E':
                return <CreateEditEvent close={closeForm} />;
            case 'G':
                return <CreateEditGroup close={closeForm} />
            case 'I':
                return <CreateEditMarketplaceItem close={closeForm} />;
            case 'N':
                return <CreateEditNewsStory close={closeForm} />;
            case 'T':
                return <CreateEditThread close={closeForm} />;

            default:
                return null;
        }
    }

    const itemsToDisplay = flaggedContent || items;

    return (
        <>
            <div className={`c-feed ${className}`}>
                {itemsToDisplay.map((item, i) => (
                    <div className="c-feed__item">
                        <FeedFlaggedBanner
                            item={item}
                        />

                        {/*@ts-ignore*/}
                        {renderItem(item)}
                    </div>
                ))}
            </div>

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                {renderForm()}
            </NewContentModal>
        </>
    );
};

export default Feed;
