import './FeedEvent.scss';

import React from 'react';
import moment from 'moment';
import classNames from "classnames";

import {useHistory, useRouteMatch} from "react-router";

import EventPost from "../../../types/EventPost";

import buildImageSrc from '../../../utils/buildImageSrc';

import Card from '../../Card';
import Comments from "../components/Comments";
import EventTableMoreMenu from "../../../containers/Schools/School/SchoolEvents/EventTableMoreMenu";
import FeedViewsRow from '../components/FeedViewsRow';
import Icon from '../../Icon';

type Props = {
    className?: string
    disableCommenting?: boolean
    inFlaggedContent?: boolean
    onEdit?: (shouldShow?: boolean) => void
    post: EventPost
}

const FeedEvent: React.FC<Props> = ({
    className = '',
    disableCommenting,
    inFlaggedContent,
    onEdit,
    post,
}) => {
    const match = useRouteMatch();
    const history = useHistory();

    const renderDate = () => {
        let dateString = moment(post.startAt).format('ddd, MMM DD, YYYY [•] hh:mm A');

        if(post.endAt) {
            if(moment(post.startAt).format('DD-MM') === moment(post.endAt).format('DD-MM')) {
                dateString += ` - ${moment(post.endAt).format('hh:mm A')}`;
            } else {
                dateString += ` - ${moment(post.endAt).format('ddd, MMM DD, YYYY [•] hh:mm A')}`;
            }
        }

        return (
            <div className="c-feed-event__date c-feed-post__date">
                <Icon type="calendar" />

                {dateString}
            </div>
        )
    };

    const renderImage = () => {
        const artifact = post.artifacts && post.artifacts[0] ? post.artifacts[0] : post.artifact;

        return (
            <div className="c-feed-event__image" role="link"  onClick={() => post.profile?.profileId && history.push(`/school/${match?.params?.schoolId}/students/${post.profile.profileId}/posts`)} style={artifact ? { backgroundImage: `url(${buildImageSrc(artifact, '220')})` } : null} />
        )
    };

    const classes = classNames(
        'c-feed-event c-feed-item',
        className,
        {
            'a-feed-item--flagged': post.flagged,
            'a-feed-item--removed-by-mods': post.removedByMods,
            'a-feed-item--super-approved': post.superApproval,
        }
    );

    return (
        <Card className={classes}>
            <div className="c-feed-event__content">
                {renderImage()}

                <div className="c-feed-event__data c-feed-post__data">
                    {renderDate()}

                    <div className="c-feed-event__title c-feed-post__title">
                        {post.title}
                    </div>

                    {post.location && (
                        <div className="c-feed-event__location c-feed-post__location">
                            {post.location.name || post.location.formattedAddress}{post.location.name && post.location.formattedAddress ? <><br />{post.location.formattedAddress}</> : ''}
                        </div>
                    )}

                    <FeedViewsRow viewCount={post.totalViews} saveCount={post.saveCount} rsvpCount={post.rsvpCount} />
                </div>

                {onEdit && (
                    <EventTableMoreMenu
                        item={post}
                        setShowNewContentModal={onEdit}
                    />
                )}
            </div>
            <Comments post={post} disableCommenting={disableCommenting} inFlaggedContent={inFlaggedContent} />
        </Card>
    );
};

export default FeedEvent;
