import './LocationSearch.scss';

import React  from 'react';
import Autocomplete from 'react-google-autocomplete';
import clone from 'clone';

import GoogleLocation from "../../types/GoogleLocation";
import Validation from "../../types/Validation";

import { handleTextChange } from '../../utils/handle-changes';

import FormColumn from "../FormColumn";
import FormRow from '../FormRow';
import FormValidationMessage from '../FormValidationMessage';
import Label from '../Label';
import Textbox from '../Textbox';

type Ref = { current: { refs: { input: HTMLInputElement } } };

type Props = {
    disabled?: boolean
    label?: string
    name: string
    onPlaceSelected: Function
    placeholder?: string
    required?: boolean
    selectedLocation?: GoogleLocation
    thisRef: Ref
    validation?: Validation
}

const LocationSearch: React.FC<Props> = ({
    disabled,
    label = 'Location',
    name,
    onPlaceSelected,
    placeholder ,
    required,
    selectedLocation ,
    thisRef,
    validation
}) => {
    const formatSelectedLocation = (loc) => {
        if(thisRef?.current?.refs?.input) {
            thisRef.current.refs.input.value = '';
        }

        let location: GoogleLocation = {
            name: loc.name,
            formattedAddress: loc.formatted_address,
            lat: loc.geometry.location.lat().toString(),
            lng: loc.geometry.location.lng().toString(),
            url: loc.website,
            placeId: loc.place_id,
            phoneNumber: loc.formatted_phone_number,
            artifact: loc.artifact,
            artifactId: loc.artifactId
        };

        let streetNumber,
            streetName;
        loc.address_components.forEach((comp) => {
            if(comp.types.indexOf('street_number') > -1) {
                streetNumber = comp.long_name;
            } else if(comp.types.indexOf('route') > -1) {
                streetName = comp.short_name;
            } else if(comp.types.indexOf('subpremise') > -1) {
                location.address2 = comp.long_name;
            } else if(comp.types.indexOf('locality') > -1) {
                location.city = comp.long_name;
            } else if(comp.types.indexOf('administrative_area_level_1') > -1) {
                location.state = comp.short_name;
            } else if(comp.types.indexOf('country') > -1) {
                location.country = comp.short_name;
            } else if(comp.types.indexOf('"postal_code"') > -1) {
                location.zip = comp.long_name;
            }
        });
        if(streetNumber && streetName) {
            location.address1 = `${streetNumber} ${streetName}`;

            if(!location.formattedAddress) {
                location.formattedAddress = location.address1;
            }
        }
        onPlaceSelected(location);
    };

    const handleChange = (name, value) => {
        let clonedLocation = clone(selectedLocation);
        if(!clonedLocation) {
            clonedLocation = {};
        }
        clonedLocation[name] = value;
        onPlaceSelected(clonedLocation);
    };

    return (
        <div className="o-location">
            <div className="o-textbox">
                <Label>{label} {required && <span className="c-label__required-asterisk">*</span>}</Label>

                {/*@ts-ignore*/}
                <Autocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    className="c-textbox c-location"
                    disabled={disabled}
                    fields={[
                        'address_components',
                        'formatted_address',
                        'formatted_phone_number',
                        'geometry.location',
                        'name',
                        'place_id',
                        'website'
                    ]}
                    onPlaceSelected={formatSelectedLocation}
                    onKeyDown={(e) => {
                        if(e.keyCode === 13) {
                            e.preventDefault();
                        }
                    }}
                    onKeyUp={(e) => {
                        if(e.keyCode === 13) {
                            e.preventDefault();
                        }
                    }}
                    placeholder={placeholder}
                    ref={thisRef}
                    types={[
                        'geocode',
                        'establishment'
                    ]}
                />
            </div>

            {selectedLocation?.placeId && (
                <FormRow columnCount={2} layout="one-third">
                    <FormColumn>
                        <Textbox
                            id="txtName"
                            label="Location Name"
                            name="name"
                            onChange={(e) => handleTextChange(handleChange, e)}
                            value={selectedLocation?.name || ''}
                        />
                    </FormColumn>

                    <FormColumn>
                        <Textbox
                            id="txtFormattedAddress"
                            label="Location Address"
                            name="formattedAddress"
                            onChange={(e) => handleTextChange(handleChange, e)}
                            value={selectedLocation?.formattedAddress || ''}
                        />
                    </FormColumn>
                </FormRow>
            )}

            {validation && validation.isValid === false && validation.show === true ? (
                <FormValidationMessage>
                    {validation.error.reason}
                </FormValidationMessage>
            ) : null}
        </div>
    );
};

export default LocationSearch;
