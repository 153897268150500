import './FeedThread.scss';

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import clone from 'clone';
import classNames from "classnames";

import {useHistory, useRouteMatch} from "react-router";

import Thread from "../../../types/Thread";

import buildImageSrc from '../../../utils/buildImageSrc';

import Avatar from '../../Avatar';
import Card from '../../Card';
import FeedViewsRow from '../components/FeedViewsRow';
import Icon from '../../Icon';
import ParagraphsFromNewlines from '../../ParagraphsFromNewlines';
import ThreadTableMoreMenu from "../../../containers/Schools/School/SchoolThreads/ThreadTableMoreMenu";
import Comments from "../components/Comments";

type Props = {
    className?: string
    disableCommenting?: boolean
    inFlaggedContent?: boolean
    onEdit?: Function
    post: Thread
}

const FeedThread: React.FC<Props> = ({
    className = '',
    disableCommenting,
    inFlaggedContent,
    onEdit,
    post,
}) => {
    const match = useRouteMatch();
    const history = useHistory();

    const [parsedPollOptions, setParsedPollOptions] = useState([]);
    const [totalPollResponses, setTotalPollResponses] = useState(0);
    const [pollExpiration, setPollExpiration] = useState(null);
    const [isPollExpired, setIsPollExpired] = useState(false);

    const generateTimeStampForPost = (status: 'X' | 'A' | 'W') => {
        switch(status) {
            case "W":
                return (
                    <div className="c-feed-thread__date">
                        <div className="c-feed-thread__date-text">
                            Drafted {moment(post.createdAt).format('ddd, MMM DD, YYYY [•] hh:mm A')}
                        </div>
                        <Icon type="clock" className="c-feed-thread__date-icon"/>
                    </div>
                );
            case "X":
                return (

                <div className="c-feed-thread__date">
                    <div className="c-feed-thread__date-text">
                        Scheduled {moment(post.publishAt).format('ddd, MMM DD, YYYY [•] hh:mm A')}
                    </div>
                    <Icon type="document-regular" className="c-feed-thread__date-icon"/>
                </div>
                );
            case "A":
            default:
                return (
                    <div className="c-feed-thread__date">
                        <div className="c-feed-thread__date-text">
                            Published {moment(post.publishedAt || post.createdAt).format('ddd, MMM DD, YYYY [•] hh:mm A')}
                        </div>
                    </div>
                );
        }
    }

    useEffect(() => {
        if (post.poll) {

            setTotalPollResponses(post.poll.responses.length);

            let options = clone(post.poll.options);
            options.forEach(option => {
                option.selected = 0;
            })

            post.poll.responses.forEach(response => {
                let match = options.find(option => option.pollOptionId === response.pollOptionId);
                match.selected ++;
            })

            setParsedPollOptions(options);

            let expiration;
            if(post.poll?.endAt != null) {
                expiration = moment(post.poll.endAt);
            } else {
                expiration = moment(post.publishedAt || post.createdAt).add(2, 'weeks');
            }
            setPollExpiration(expiration)
            setIsPollExpired(expiration.isSameOrBefore(moment()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post.poll]);

    const classes = classNames(
        'c-feed-thread c-feed-item',
        className,
        {
            'a-feed-item--flagged': post.flagged,
            'a-feed-item--removed-by-mods': post.removedByMods,
            'a-feed-item--super-approved': post.superApproval,
        }
    );


    return (
        <Card className={classes}>
            <div className="c-feed-thread__header" >
                {post.profile && (
                    <div role="link"  onClick={() => history.push(`/school/${match?.params?.schoolId}/students/${post.profile.profileId}/posts`)}>
                        <Avatar artifact={post.profile.artifact} />
                    </div>
                )}

                <div className="c-feed-thread__data c-feed-post__data">
                    {post.profile && (
                        <div
                            className="c-feed-thread__name"
                            role="link"
                            onClick={() => history.push(`/school/${match?.params?.schoolId}/${match?.params?.profileType}/students/${post.profile.profileId}/posts`)}
                        >
                            {post.profile.firstName} {post.profile.lastName}
                        </div>
                    )}

                    {post.forumTopic && (
                        <div
                            className="c-feed-thread__forum-topic"
                            role="link"
                            onClick={() => history.push(`/school/${match?.params?.schoolId}/${match?.params?.profileType}/group/${post?.forumTopic?.forumTopicId}/activity`)}
                        >
                            in <strong>{post.forumTopic.name}</strong>
                        </div>
                    )}

                    <div className="c-feed-thread__date">
                        {generateTimeStampForPost(post.status)}
                    </div>
                </div>

                {onEdit && (
                    <ThreadTableMoreMenu
                        item={post}
                        setShowNewContentModal={onEdit}
                    />
                )}
            </div>

            <div className="c-feed-thread__content">
                <ParagraphsFromNewlines
                    className="c-feed-thread__title c-feed-post__title"
                    linkify
                    text={post.title}
                />

                <ParagraphsFromNewlines
                    className="c-feed-thread__description"
                    linkify
                    text={post.description}
                />

                {post.artifacts && post.artifacts[0] && (

                    <>
                        {post.artifacts[0].type === 'image' && (
                            <img className="c-feed-thread__image" src={buildImageSrc(post.artifacts[0], 'original')} alt={post.title} />
                        )}
                        {post.artifacts[0].type === 'video' && (
                            <video className="c-feed-thread__image" controls src={post.artifacts[0].videoMp4Url} />
                        )}
                    </>
                )}

                {post.poll && (
                    <div className="c-feed-thread__poll">
                        <div className="c-feed-poll__question">
                            {post.poll.questionText}
                        </div>
                        {parsedPollOptions.map((option, i) => (
                            <div className="c-feed-poll__option" key={option.pollOptionId}>
                                <div
                                    className="c-feed-poll__bar"
                                    style={{width: `${option.selected / totalPollResponses * 100}%`}}
                                />
                                <div className="c-feed-poll__option__text">
                                    {option.text}
                                </div>
                                <div className="c-feed-poll__option__percent">
                                    {totalPollResponses ? (option.selected / totalPollResponses * 100).toFixed(1) : '0.0'}%
                                </div>
                            </div>
                        ))}
                        <p className="c-feed-poll__info">
                            {totalPollResponses} vote{totalPollResponses !== 1 ? 's' : ''}
                            {pollExpiration && (
                                <> | Voting {isPollExpired ? 'ended' : 'ends in'} {pollExpiration.fromNow()}</>
                            )}
                        </p>

                    </div>

                )}
                <FeedViewsRow viewCount={post.totalViews} saveCount={post.saveCount} />
            </div>

            <Comments post={post} disableCommenting={disableCommenting} inFlaggedContent={inFlaggedContent} />

        </Card>
    );
};

export default FeedThread;
