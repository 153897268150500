import React, { useEffect, useState } from 'react';
import { validated } from "react-custom-validation";
import clone from 'clone';
import { useHistory, useRouteMatch } from 'react-router';

import PushNotification from "../../../../types/PushNotification";
import Validation from "../../../../types/Validation";

import { handleDateChange, handleTextChange } from '../../../../utils/handle-changes';
import { isRequired, isRequiredIfTrue } from '../../../../utils/validations';
import { PUSH_NOTIFICATION_DESTINATION_TYPES } from "../../../../utils/constants";
import { isObjectNullOrEmpty } from "../../../../utils/utils";

import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';
import {
    cancelNotification,
    getPushNotifications,
    sendPushNotification,
    setPushNotification
} from '../../../../store/slices/pushNotifications';

import AttachContentModal from "./components/AttachContentModal";
import AttachedForumTopic from "./components/AttachedForumTopic";
import AttachedNewsStory from "./components/AttachedNewsStory";
import Button, { ButtonThemes, ButtonTypes } from '../../../../components/Button';
import ButtonRow from '../../../../components/ButtonRow';
import CircularProgressBar from "../../../../components/CircularProgressBar";
import ContentSummary from "../../../../components/ContentSummary";
import Datepicker from '../../../../components/Datepicker';
import H4 from "../../../../components/H4";
import Form from '../../../../components/Form';
import FormRow from '../../../../components/FormRow';
import FormValidationMessage from "../../../../components/FormValidationMessage";
import Label from "../../../../components/Label";
import Modal from '../../../../components/Modal';
import ProfileTypesSelector from "../../../../components/ProfileTypesSelector";
import ResourceRow from "../../../Schools/School/Resources/ResourceRow";
import SelectAttachContentType, { PushNotificationContentTypes } from "./components/SelectAttachContentType";
import SelectDestination from "./components/SelectDestination";
import SelectDisplayType from "./components/SelectDisplayType";
import SuccessModal from "../../../../components/Modal/SuccessModal";
import Textbox from '../../../../components/Textbox';
import ToggleSwitch from "../../../../components/ToggleSwitch";
import { PostTypes } from "../../../../utils/enums";

type Props = {
    close?: Function
    pushNotification: PushNotification
    startWithAttachedContent?: boolean
    $field: Function
    $fieldEvent: Function
    $validation: {
        destination: Validation
        displayType: Validation
        message: Validation
    }
    $submit: Function
}

const CreateEditNotificationForm: React.FC<Props> = ({
    close,
    pushNotification,
    startWithAttachedContent,
    $field,
    $fieldEvent,
    $validation,
    $submit
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const [attachContent, setAttachContent] = useState<boolean>(startWithAttachedContent === true);
    const [disableAttachContent, setDisableAttachContent] = useState<boolean>(false);
    const [contentTypeToAttach, setContentTypeToAttach] = useState<PushNotificationContentTypes>(undefined);
    const [showAttachContentModal, setShowAttachContentModal] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [showSaveConfirmationModal, setShowSaveConfirmationModal] = useState<boolean>(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

    const { isCancellingPushNotification, isSendingPushNotification } = useTypedSelector((state) => state.pushNotifications);

    const MAX_PUSH_MESSAGE_LENGTH = 80;
    const MAX_INAPP_MESSAGE_LENGTH = 250;

    useEffect(() => {
        if(pushNotification.targetedNotificationId && pushNotification.post) {
            setAttachContent(true);
        }

        if(!pushNotification.targetedNotificationId) {
            handleChange(`${params.profileType}Visibility`, true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pushNotification.targetedNotificationId])

    const handleChange = (name: string, value: any) => {
        let clonedPushNotification = clone(pushNotification);
        clonedPushNotification[name] = value;

        if(name === 'destination') {
            if(value === PUSH_NOTIFICATION_DESTINATION_TYPES.PUSH) {
                delete clonedPushNotification.displayType;
            } else {
                clonedPushNotification.message = clonedPushNotification.message.substring(0, MAX_INAPP_MESSAGE_LENGTH);
            }
        }

        if(name === 'message') {
            if(pushNotification.destination !== PUSH_NOTIFICATION_DESTINATION_TYPES.PUSH && value.length > MAX_INAPP_MESSAGE_LENGTH) {
                clonedPushNotification.message = clonedPushNotification.message.substring(0, MAX_INAPP_MESSAGE_LENGTH);
            }
        }

        if(/admitVisibility|alumniVisibility|studentVisibility/.test(name)) {
            let visibilityCount = 0;
            if(clonedPushNotification.admitVisibility) {
                visibilityCount++;
            }

            if(clonedPushNotification.alumniVisibility) {
                visibilityCount++;
            }

            if(clonedPushNotification.studentVisibility) {
                visibilityCount++;
            }

            if(visibilityCount > 1) {
                delete clonedPushNotification.forumTopic;
                delete clonedPushNotification.newsStory;
                delete clonedPushNotification.post;
                delete clonedPushNotification.resource;
                setAttachContent(false);
                setDisableAttachContent(true);
            } else {
                setDisableAttachContent(false);
            }
        }

        dispatch(setPushNotification(clonedPushNotification));
    };

    const handleDelete = async () => {
        try {
            await dispatch(cancelNotification({notificationId: pushNotification.targetedNotificationId})).unwrap();
            $fieldEvent('reset');
            close && close();
            dispatch(getPushNotifications({
                schoolId: params.schoolId,
                profileType: params.profileType,
            }));
        } catch (err) {
            console.log('CreateEditNotificationForm handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleOpenAttachModal = (type: PushNotificationContentTypes) => {
        setContentTypeToAttach(type);
        setShowAttachContentModal(true);

        let clonedPushNotification = clone(pushNotification);

        delete clonedPushNotification.forumTopic;
        delete clonedPushNotification.newsStory;
        delete clonedPushNotification.post;
        delete clonedPushNotification.tenantResource;

        dispatch(setPushNotification(clonedPushNotification));
    };

    const handleSend = async (isValid: boolean) => {
        if(!isValid) {
            return;
        }

        if (pushNotification.sendAt) {
            if (!confirmed) {
                if (showSaveConfirmationModal) {
                    setShowSaveConfirmationModal(false);
                }
                else {
                    setShowSaveConfirmationModal(true);
                    return;
                }
            }
        }

        try {
            await dispatch(sendPushNotification({})).unwrap();
            setShowSuccessModal(true);
            $fieldEvent('reset');
        } catch(err) {
            console.log('CreateEditNotificationForm handleSend err', err);
        }
    };

    const renderAttachedResource = () => {
        const { tenantResource } = pushNotification;

        return (
            <ResourceRow
                editResource={() => setShowAttachContentModal(true)}
                hideDragIcon
                resource={tenantResource}
            />
        )
    }

    const renderAttachedTopic = () => {
        const { forumTopic } = pushNotification;

        return (
            <AttachedForumTopic
                forumTopic={forumTopic}
                handleEditButtonClick={() => setShowAttachContentModal(true)}
            />
        );
    };

    return (
        <>
            <H4>
                Create Notification
            </H4>

            <Form className="push-notification-form">
                {!isObjectNullOrEmpty(pushNotification.targetForumTopic) && (
                    <FormRow className="push-notification-form__attached-content-container">
                        <Label>
                            Notification will be sent to members of this group:
                        </Label>

                        <AttachedForumTopic
                            forumTopic={pushNotification.targetForumTopic}
                            handleDeleteButtonClick={() => handleChange('targetForumTopic', null)}
                            hideEditButton
                        />
                    </FormRow>
                )}

                <FormRow>
                    <Textbox
                        id="txtMessage"
                        label="Message (the text users see in the notification)"
                        name="message"
                        required
                        type="textarea"
                        validation={$validation.message}
                        value={pushNotification.message || ''}
                        {...$field('message', (event) => handleTextChange(handleChange, event))}
                    />

                    {(pushNotification.destination === PUSH_NOTIFICATION_DESTINATION_TYPES.BOTH || pushNotification.destination === PUSH_NOTIFICATION_DESTINATION_TYPES.PUSH) && pushNotification.message.length > 0 && (
                        <>
                            <CircularProgressBar
                                length={pushNotification.message.length}
                                limit={MAX_PUSH_MESSAGE_LENGTH}
                                postCountText="allowed characters in push notifications"
                                warningText="You have exceeded the character limit. You may proceed, but extra characters will only be included in the in-app notification."
                            />
                        </>
                    )}

                    {(pushNotification.destination === PUSH_NOTIFICATION_DESTINATION_TYPES.BOTH || pushNotification.destination === PUSH_NOTIFICATION_DESTINATION_TYPES.INAPP) && pushNotification.message.length > 0 && (
                        <>
                            <CircularProgressBar
                                length={pushNotification.message.length}
                                limit={MAX_INAPP_MESSAGE_LENGTH}
                                postCountText="allowed characters in in-app notifications"
                            />
                        </>
                    )}
                </FormRow>

                <FormRow>
                    <Textbox
                        id="txtNotes"
                        label="Notes (for internal use only)"
                        name="title"
                        onChange={(event) => handleTextChange(handleChange, event)}
                        type="textarea"
                        value={pushNotification.title || ''}
                    />
                </FormRow>

                <FormRow>
                    <Datepicker
                        dateFormat="MM/dd/yyyy hh:mma"
                        id="dateSendAt"
                        label="Send At (Leave blank to send immediately)"
                        minDate={new Date()}
                        name="sendAt"
                        onChange={(newDate) => handleDateChange(handleChange, 'sendAt', null, newDate)}
                        selected={pushNotification.sendAt || null}
                        showMonthDropdown
                        showTimeSelect
                        showYearDropdown
                        timeIntervals={15}
                    />
                    {pushNotification.sendAt && (
                        <p>
                            A scheduled notification will be sent at the time you specify <strong>in your timezone</strong>.
                            For example: if you are on mountain time, and schedule a notification for 1pm, it will be sent
                            at 1pm mountain time, 2pm central time, etc.
                        </p>
                    )}
                </FormRow>

                <FormRow>
                    <SelectDestination
                        destination={pushNotification.destination}
                        handleChange={handleChange}
                        validation={$validation.destination}
                    />
                </FormRow>

                <FormRow>
                    <SelectDisplayType
                        destination={pushNotification.destination}
                        displayType={pushNotification.displayType}
                        onClick={(selected) => handleChange('displayType', selected)}
                    />

                    {$validation.displayType.show && <FormValidationMessage>{$validation.displayType.error.reason}</FormValidationMessage>}
                </FormRow>

                {params?.schoolId && (
                    <FormRow>
                        <ToggleSwitch
                            checked={attachContent}
                            className="push-notification-form__attach-content-toggle"
                            disabled={disableAttachContent}
                            label={(
                                <div className="push-notification-form__attach-content-label">
                                    <div className="push-notification-form__attach-content-label-text">
                                        Attach content
                                    </div>

                                    <div className="push-notification-form__attach-content-label-subtext">
                                        You can attach a deal, discussion, event, or marketplace item to notifications
                                    </div>
                                </div>
                            )}
                            labelPosition="left"
                            name="attachContent"
                            onClick={() => {
                                if(attachContent) {
                                    let clonedPushNotification = clone(pushNotification);
                                    delete clonedPushNotification.forumTopic
                                    delete clonedPushNotification.newsStory;
                                    delete clonedPushNotification.post;
                                    delete clonedPushNotification.tenantResource;
                                    dispatch(setPushNotification(clonedPushNotification));
                                    setContentTypeToAttach(undefined);
                                }
                                setAttachContent(!attachContent);
                            }}
                        />

                        {disableAttachContent && (
                            <FormValidationMessage>
                                Cannot attach content when sending push to more than one user type.
                            </FormValidationMessage>
                        )}
                    </FormRow>
                )}

                {attachContent && (
                    <SelectAttachContentType handleChange={handleOpenAttachModal} value={contentTypeToAttach} />
                )}

                {((attachContent && pushNotification.post && pushNotification.post.type !== PostTypes.NewsStory) || (pushNotification.targetedNotificationId && pushNotification.post && pushNotification.post.type !== PostTypes.NewsStory)) && (
                    <FormRow className="push-notification-form__attached-content-container">
                        <ContentSummary
                            handleEditClick={() => setShowAttachContentModal(true)}
                            post={pushNotification.post}
                        />
                    </FormRow>
                )}

                {((attachContent && pushNotification.post?.type === PostTypes.NewsStory) || (pushNotification.targetedNotificationId && pushNotification.post?.type === PostTypes.NewsStory)) && (
                    <FormRow className="push-notification-form__attached-content-container">
                        <AttachedNewsStory
                            handleEditClick={() => setShowAttachContentModal(true)}
                            newsStory={pushNotification.post}
                        />
                    </FormRow>
                )}

                {((attachContent && pushNotification.tenantResource) || (pushNotification.targetedNotificationId && pushNotification.tenantResource)) && (
                    <FormRow className="push-notification-form__attached-content-container">
                        {renderAttachedResource()}
                    </FormRow>
                )}

                {((attachContent && pushNotification.forumTopic) || (pushNotification.targetedNotificationId && pushNotification.forumTopic)) && (
                    <FormRow className="push-notification-form__attached-content-container">
                        {renderAttachedTopic()}
                    </FormRow>
                )}

                <FormRow>
                    <ProfileTypesSelector item={pushNotification} onChange={handleChange} />
                </FormRow>

                <ButtonRow>
                    <Button
                        onClick={(event) => {
                            event.preventDefault();
                            $submit(() => handleSend(true), () => handleSend(false));
                        }}
                        showActivityIndicator={isSendingPushNotification}
                        type={ButtonTypes.Submit}
                    >
                        {pushNotification.targetedNotificationId ? 'Save' : 'Create'} Notification
                    </Button>

                    {pushNotification.targetedNotificationId && (
                        <Button
                            className="destructive"
                            onClick={() => setShowDeleteConfirmationModal(true)}
                            theme={ButtonThemes.Link}
                        >
                            Delete
                        </Button>
                    )}
                </ButtonRow>
            </Form>

            <AttachContentModal
                close={() => setShowAttachContentModal(false)}
                show={showAttachContentModal}
                type={contentTypeToAttach}
            />

            <Modal
                title="Reminder"
                show={showSaveConfirmationModal}
                confirmButtonOnClick={() => {
                    setShowSaveConfirmationModal(false);
                    setConfirmed(true);
                    handleSend(true);
                }}
                confirmButtonText="Yes, Save"
                showActivityIndicator={isSendingPushNotification}
                declineButtonOnClick={() => {
                    setConfirmed(false);
                    setShowSaveConfirmationModal(false);
                }}
                declineButtonText="Cancel"
            >
                Ready to save? Scheduled notifications cannot be edited or deleted within 30 minutes of their scheduled time due to their placement in the queue
            </Modal>

            <SuccessModal
                buttonOnClick={() => {
                    setShowSuccessModal(false);
                    dispatch(getPushNotifications({
                        schoolId: params.schoolId,
                        profileType: params.profileType,
                    }));
                    close && close();
                }}
                buttonText="Done"
                show={showSuccessModal}
                title="Nice job!"
            >
                <p>
                    Your push notification has been added to the queue.
                </p>
                <p>
                    If you didn't select a "Send At" time, it will send as soon as it can be processed.
                </p>
            </SuccessModal>

            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isCancellingPushNotification}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to cancel this push notification?
            </Modal>
        </>
    );
};

function createEditPushNotificationFormValidationConfig(props) {
    const { destination, displayType, message } = props.pushNotification;

    return {
        fields: ['destination', 'displayType', 'message'],
        validations: {
            destination: [
                [isRequired, destination]
            ],
            displayType: [
                [isRequiredIfTrue, displayType, destination != null && destination !== PUSH_NOTIFICATION_DESTINATION_TYPES.PUSH]
            ],
            message: [
                [isRequired, message]
            ]
        }
    }
}
export default validated(createEditPushNotificationFormValidationConfig)(CreateEditNotificationForm);
