import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";

import { addError } from "./errors";
import { RootState } from '../reducers';
import EventPost from '../../types/EventPost';
import Metadata from '../../types/Metadata';
import Deal from '../../types/Deal';
import IError from '../../types/IError';
import MarketplaceItem from '../../types/MarketplaceItem';
import SystemHealth from '../../types/SystemHealth';
import Thread from '../../types/Thread';


type GetApiRequestProps = {
    isUpdate?: boolean
    apiRequestsMetadata?: Metadata
}

export const getApiRequests = createAsyncThunk(
    'engineering/getApiRequests',
    async ({isUpdate, apiRequestsMetadata}: GetApiRequestProps, {dispatch, getState}) => {
        try {
            if(!apiRequestsMetadata) {
                apiRequestsMetadata = clone((getState() as RootState).engineering.apiRequestsMetadata);
            } else {
                apiRequestsMetadata = {...apiRequestsMetadata}
            }


            const res = await new Request((getState() as RootState).auth.token).get(PATHS.engineering.getApiRequests(buildQueryString(apiRequestsMetadata)));

            const apiRequests = res.data.data.items;

            apiRequestsMetadata.total = res.data.data.meta.total;

            return {apiRequests, apiRequestsMetadata, isAtEnd: res.data.data.items.length === 0};
        } catch(err) {
            console.log('getApiRequests', err);
            err.friendlyMessage = 'Error getting the list of API Requests. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetCronJobsProps = {
    isUpdate?: boolean
    cronJobsMetadata?: Metadata
}

export const getCronJobStatus = createAsyncThunk(
    'engineering/getCronJobStatus',
    async (_: void, {dispatch, getState}) => {
        try {

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.engineering.getCronJobStatus());

            return res.data.data
        } catch(err) {
            console.log('getCronJobStatus', err);
            err.friendlyMessage = 'Error getting the status of cron jobs. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetSqsMessagesProps = {
    isUpdate?: boolean
    sqsMessagesMetadata?: Metadata
}

export const getSqsMessages = createAsyncThunk(
    'engineering/getSqsMessages',
    async ({isUpdate, sqsMessagesMetadata}: GetSqsMessagesProps, {dispatch, getState}) => {
        try {
            if(!sqsMessagesMetadata) {
                sqsMessagesMetadata = clone((getState() as RootState).engineering.sqsMessagesMetadata);
            } else {
                sqsMessagesMetadata = {...sqsMessagesMetadata}
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.engineering.getSqsMessages(buildQueryString(sqsMessagesMetadata)));

            const sqsMessages = res.data.data.items;

            sqsMessagesMetadata.total = res.data.data.meta.total;

            return {sqsMessages, sqsMessagesMetadata, isAtEnd: res.data.data.items.length === 0};
        } catch(err) {
            console.log('getSqsMessagesError', err);
            err.friendlyMessage = 'Error getting the list of SQS Messages. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type TriggerCronJobProp = {
    name: string
}

export const triggerConJob = createAsyncThunk(
    'engineering/triggerConJob',
    async ({name}: TriggerCronJobProp, {dispatch, getState}) => {
        try {
            const token = (getState() as RootState).auth.token;
            let request = new Request(token);
            let reqFunc = request.post;
            let path = PATHS.engineering.triggerCronJob();

            await reqFunc(path, {name});
        } catch(err) {
            console.log('triggerConJob', err);
            err.friendlyMessage = 'Error attempting to manually trigger cron job. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

export const getCronJobs = createAsyncThunk(
    'engineering/getCronJobs',
    async ({isUpdate, cronJobsMetadata}: GetCronJobsProps, {dispatch, getState}) => {
        try {
            if(!cronJobsMetadata) {
                cronJobsMetadata = clone((getState() as RootState).engineering.cronJobsMetadata);
            } else {
                cronJobsMetadata = {...cronJobsMetadata}
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.engineering.getCronJobs(buildQueryString(cronJobsMetadata)));

            const cronJobs = res.data.data.items;

            cronJobsMetadata.total = res.data.data.meta.total;

            return {cronJobs, cronJobsMetadata, isAtEnd: res.data.data.items.length === 0};
        } catch(err) {
            console.log('getCronJobs', err);
            err.friendlyMessage = 'Error getting the list of cron jobs. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);


type GetPaginatedSystemHealthProps = {
    isUpdate?: boolean
    systemHealthMetadata?: Metadata
}

export const getPaginatedSystemHealth = createAsyncThunk(
    'engineering/getPaginatedSystemHealth',
    async ({isUpdate, systemHealthMetadata}: GetPaginatedSystemHealthProps, { dispatch, getState }) => {
        try {
            if(!systemHealthMetadata) {
                systemHealthMetadata = clone((getState() as RootState).engineering.systemHealthMetadata);
            } else {
                systemHealthMetadata = {...systemHealthMetadata}
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.engineering.getSystemHealth(buildQueryString(systemHealthMetadata)));

            systemHealthMetadata.total = res.data.data.meta.total;

            return {systemHealth: res.data.data.items, systemHealthMetadata};
        } catch (err) {
            console.log('getSystemHealth', err);
            err.friendlyMessage = 'Error getting system health. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

interface EngineeringState {
    apiRequests: Array<any>
    apiRequestsMetadata: Metadata
    cronJobs: Array<Deal | EventPost | MarketplaceItem | Thread>
    cronJobsMetadata: Metadata
    cronJobStatus: {
        overallStatus?: 'ok' | 'error'
        error?: any
    }
    getApiRequestsError?: IError
    getCronJobsError?: IError
    getCronJobStatusError?: IError
    getSqsMessagesError?: IError
    getSystemHealthError?: IError
    isGettingApiRequests: boolean
    isGettingCronJobs: boolean
    isGettingCronJobStatus: boolean
    isGettingSqsMessages: boolean
    isGettingSystemHealth: boolean
    isTriggeringCronJob: boolean
    showSqsMessagesTable: boolean
    sqsMessages: Array<any>
    sqsMessagesMetadata: Metadata
    systemHealth: Array<SystemHealth>
    systemHealthMetadata: Metadata
    triggerCronJobError?: IError
}

const initialState: EngineeringState = {
    apiRequests: [],
    apiRequestsMetadata: {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'name',
        search: ''
    },
    cronJobs: [],
    cronJobsMetadata: {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'name',
        search: ''
    },
    cronJobStatus: {},
    getApiRequestsError: undefined,
    getCronJobsError: undefined,
    getCronJobStatusError: undefined,
    getSqsMessagesError: undefined,
    isGettingApiRequests: false,
    isGettingCronJobs: false,
    isGettingCronJobStatus: false,
    isGettingSqsMessages: false,
    isGettingSystemHealth: false,
    isTriggeringCronJob: false,
    showSqsMessagesTable: false,
    sqsMessages: [],
    sqsMessagesMetadata: {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'name',
        search: ''
    },
    systemHealth: [],
    systemHealthMetadata: {
        order: 'desc',
        page_size: 10,
        page_num: 0,
        sort: '',
        search: ''
    },
    triggerCronJobError: undefined,
};

export const engineeringSlice = createSlice({
    name: 'engineering',
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(getApiRequests.pending, (state, action) => {
            state.getApiRequestsError = undefined;
            state.isGettingApiRequests = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.apiRequestsMetadata) {
                state.apiRequestsMetadata = action.meta.arg.apiRequestsMetadata;
            }
        });
        addCase(getApiRequests.fulfilled, (state, action) => {
            state.apiRequests = action.payload.apiRequests;
            state.apiRequestsMetadata = action.payload.apiRequestsMetadata;
            state.isGettingApiRequests = false;
        });
        addCase(getApiRequests.rejected, (state, action) => {
            state.getApiRequestsError = action.error;
            state.isGettingApiRequests = false;
        });

        addCase(getCronJobs.pending, (state, action) => {
            state.getCronJobsError = undefined;
            state.isGettingCronJobs = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.cronJobsMetadata) {
                state.cronJobsMetadata = action.meta.arg.cronJobsMetadata;
            }
        });
        addCase(getCronJobs.fulfilled, (state, action) => {
            state.cronJobs = action.payload.cronJobs;
            state.cronJobsMetadata = action.payload.cronJobsMetadata;
            state.isGettingCronJobs = false;
        });
        addCase(getCronJobs.rejected, (state, action) => {
            state.getCronJobsError = action.error;
            state.isGettingCronJobs = false;
        });

        addCase(getCronJobStatus.pending, (state, action) => {
            state.getCronJobStatusError = undefined;
            state.isGettingCronJobStatus = true;
        });
        addCase(getCronJobStatus.fulfilled, (state, action) => {
            state.cronJobStatus = action.payload;
            state.isGettingCronJobStatus = false;
        });
        addCase(getCronJobStatus.rejected, (state, action) => {
            state.getCronJobStatusError = action.error;
            state.isGettingCronJobStatus = false;
        });

        addCase(triggerConJob.pending, (state, action) => {
            state.triggerCronJobError = undefined;
            state.isTriggeringCronJob =  true;
        });
        addCase(triggerConJob.fulfilled, (state, action) => {
            state.isTriggeringCronJob = false;
        });
        addCase(triggerConJob.rejected, (state, action) => {
            state.triggerCronJobError = action.error;
            state.isTriggeringCronJob = false;
        });

        addCase(getSqsMessages.pending, (state, action) => {
            state.getSqsMessagesError = undefined;
            state.isGettingSqsMessages = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.sqsMessagesMetadata) {
                state.sqsMessagesMetadata = action.meta.arg.sqsMessagesMetadata;
            }
        });
        addCase(getSqsMessages.fulfilled, (state, action) => {
            state.sqsMessages = action.payload.sqsMessages;
            state.sqsMessagesMetadata = action.payload.sqsMessagesMetadata;
            state.isGettingSqsMessages = false;
        });
        addCase(getSqsMessages.rejected, (state, action) => {
            state.getSqsMessagesError = action.error;
            state.isGettingSqsMessages = false;
        });

        addCase(getPaginatedSystemHealth.pending, (state, action) => {
            state.isGettingSystemHealth = true;
        });
        addCase(getPaginatedSystemHealth.fulfilled, (state, action) => {
            state.systemHealth = action.payload.systemHealth;
            state.systemHealthMetadata = action.payload.systemHealthMetadata;
            state.isGettingSystemHealth = false;
        });
        addCase(getPaginatedSystemHealth.rejected, (state, action) => {
            state.getSystemHealthError = action.error;
            state.isGettingSystemHealth = false;
        });
    }
});

export default engineeringSlice.reducer;
