import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Request from "../../utils/request";
import {RootState} from "../reducers";
import PATHS from "../../utils/paths";
import {addError} from './errors';
import IError from '../../types/IError';

type getPresentationDetailDataProps = {
    tenantId: number
    startDate: string
    endDate?: string
}

export const getPresentationDetailData = createAsyncThunk(
    'presentationCharts/getPresentationDetailData',
    async ({tenantId, startDate, endDate}: getPresentationDetailDataProps, {dispatch, getState}) => {
        try {
            let query = `tenant_id=${tenantId}&start_date=${startDate}`;
            if (endDate) {
                query = query + `&end_date=${endDate}`;
            }
            const res = await new Request((getState() as RootState).auth.token).get(PATHS.presentationData.getPresentationDetailData(query));
            const details = res.data;

            return {details};
        }
        catch (err: any) {
            console.log('getPresentationDetailData', err);
            err.friendlyMessage = 'Error getting details for the selected day. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    });

interface PresentationDetailDataState {
    data: PresentationDetailData
    getPresentationDetailDataError?: IError
    isGettingPresentationDetailData?: boolean
}

const initialState: PresentationDetailDataState = {
    data: {
        posts: [],
        notifications: [],
        foregroundCount: 0,
        screenViewCount: 0,
    },
    getPresentationDetailDataError: undefined,
    isGettingPresentationDetailData: false
}

export const presentationDataDetailSlice = createSlice({
    name: 'presentationDataDetailSlice',
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(getPresentationDetailData.pending, (state, action) => {
            state.getPresentationDetailDataError = undefined;
            state.isGettingPresentationDetailData = true;
        });
        addCase(getPresentationDetailData.fulfilled, (state, action) => {
            state.data = action.payload.details.data;
            state.isGettingPresentationDetailData = false;
        });
        addCase(getPresentationDetailData.rejected, (state, action) => {
            state.getPresentationDetailDataError = action.error;
            state.isGettingPresentationDetailData = false;
        });
    }
});

export default presentationDataDetailSlice.reducer;
