import './SessionExpired.scss';

import React from 'react';
import Helmet from 'react-helmet';

import Button from "../../../components/Button";

const SessionExpired = () => {

    return (
        <>
            <Helmet>
                <title>Session Expired</title>
            </Helmet>

            <div className="c-session-expired">
                <p>Your session has expired.</p>
                <Button to="/auth/login">
                    Log In
                </Button>
            </div>


        </>
    );
};

export default SessionExpired;
