import './FeedFlaggedBanner.scss';

import React from 'react';

import ChatMessage from "../../../types/ChatMessage";
import Comment from '../../../types/Comment';
import Deal from "../../../types/Deal";
import EventPost from "../../../types/EventPost";
import Group from "../../../types/Group";
import MarketplaceItem from "../../../types/MarketplaceItem";
import Thread from "../../../types/Thread";

import FlaggedIcon from "../../Icon/FlaggedIcon";

type Props = {
    item: Comment | Deal | EventPost | Group | ChatMessage | MarketplaceItem | Thread
}

const FeedFlaggedBanner: React.FC<Props> = ({
    item,
}) => {
    //@ts-ignore
    const itemIsFlagged = (item.flaggedCount > 0 && !item.removedByMods && !item.superApproval) || (item.focusComment ? item.focusComment.flagged : item.flagged);

    if(!itemIsFlagged && !item.removedByMods && !item.superApproval) {
        return null;
    }

    const renderText = () => {
        if(item.removedByMods) {
            //@ts-ignore
            if(item.focusComment) {
                //@ts-ignore
                return item.focusComment.removedByMods ? 'This content has been removed by a moderator.' : 'This comment is not visible in the app because the parent post has been removed.';
            } else {
                //@ts-ignore
                if(item.profile?.removedByMods) {
                    return 'This content is not visible in the app because the posting student has been removed by a moderator.';
                } else {
                    return 'This content has been removed by a moderator.';
                }
            }
        } else if(itemIsFlagged) {
            return 'Flagged as potentially harmful or inappropriate.';
        } else if(item.superApproval) {
            return 'This content has been permanently approved by a moderator.';
        }
    };

    return (
        <div className={`c-feed-flagged-banner ${item.superApproval ? 'a-feed-flagged-banner--super-approved' : ''}`}>
            <FlaggedIcon />

            <div className="c-feed-flagged-banner__text">
                {renderText()}
            </div>

            {item.flaggedCount != null && item.flaggedCount > 0 && (
                <div className="c-feed-flagged-banner__count">
                    {item.flaggedCount}
                </div>
            )}
        </div>
    );
};

export default FeedFlaggedBanner;
