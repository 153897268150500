import './FeedPostAndComment.scss';

import React from 'react';
import { useHistory, useRouteMatch } from "react-router";
import classNames from "classnames";
import moment from "moment";

import Comment from "../../../types/Comment";
import Thread from "../../../types/Thread";

import buildImageSrc from "../../../utils/buildImageSrc";
import { isObjectNullOrEmpty } from "../../../utils/utils";
import { PostTypeLabels } from "../../../utils/enums";

import Card from "../../Card";
import H6 from "../../H6";
import ParagraphsFromNewlines from "../../ParagraphsFromNewlines";
import TableMoreMenu from "../../Table/TableMoreMenu";
import ThreadComment from "../../ThreadComment";

type Props = {
    className?: string
    comment?: Comment
    post: Thread
}

const FeedPostAndComment: React.FC<Props> = ({
    className,
    comment,
    post,
}) => {
    const match = useRouteMatch();
    const history = useHistory();

    if(isObjectNullOrEmpty(post)) {
        return null;
    }

    const classes = classNames(
        'c-feed-post-and-comment c-feed-item',
        className,
        {
            'a-feed-item--flagged': post?.flagged,
            'a-feed-item--removed-by-mods': post?.removedByMods,
            'a-feed-item--super-approved': post?.superApproval,
            'a-feed-item--comment': !isObjectNullOrEmpty(comment),
        }
    );

    const flaggedCount = post?.flaggedCount;
    const lastFlaggedAt = post?.lastFlaggedAt;

    return (
        <Card className={classes}>
            <header className="c-feed-post-and-comment__header">
                <div className="c-feed-post-and-comment__group">
                    <strong>{!isObjectNullOrEmpty(comment) && 'Commented on '}{isObjectNullOrEmpty(comment) ? 'Post' : PostTypeLabels[post.type]}</strong>{post.forumTopic && <> in <strong onClick={() => history.push(`/school/${match?.params?.schoolId}/group/${post.forumTopic.forumTopicId}/activity`)}>{post.forumTopic.name}</strong></>} on {moment(comment?.createdAt || post.publishedAt).format('MMM DD, YYYY [at] h:mm A')}
                </div>

                <div className="c-feed-post-and-comment__flagged-at">
                    {flaggedCount > 0 && (
                        <>
                            Flagged {moment(lastFlaggedAt).format('MMM DD, YYYY [at] h:mm A')}
                        </>
                    )}

                    <TableMoreMenu
                        item={comment || post}
                        onlyModerationOptions
                    />
                </div>
            </header>

            {post.artifacts && post.artifacts[0] && (
                <img className="c-feed-post-and-comment__artifact" src={buildImageSrc(post.artifacts[0], '667')} alt="posted on abuzz" />
            )}

            {post.title != null && (
                <H6>
                    {post.title}
                </H6>
            )}

            <ParagraphsFromNewlines
                text={post.description}
            />

            {!isObjectNullOrEmpty(comment) && (
                <ThreadComment
                    comment={comment}
                    disableReply
                    isReply={false}
                    showOneLevel
                    thread={post}
                />
            )}
        </Card>
    );
};

export default FeedPostAndComment;
