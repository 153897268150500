import './SchoolThreads.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from "react-router";
import clone from "clone";

import TableColumn from "../../../../types/TableColumn";

import { isArrayNullOrEmpty } from "../../../../utils/utils";
import { LIST_TYPES } from "../../../../utils/constants";
import { ListTypes, ProfileTypes } from "../../../../utils/enums";

import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';
import { clearThread, clearThreadsMetadata, getThreads, setSearchTerm, } from "../../../../store/slices/threads";

import ActivityIndicator from "../../../../components/ActivityIndicator";
import Button, { ButtonSizes, ButtonThemes } from "../../../../components/Button";
import CreateEditThread from "../../../Content/Threads/CreateEditThread";
import GridList from "../../../../components/GridList";
import H5 from "../../../../components/H5";
import Icon from "../../../../components/Icon";
import ListTypeSelector from "../../../../components/ListTypeSelector";
import NewContentModal from "../../../../components/NewContentModal";
import PageHeader from "../../../../components/PageHeader";
import Table from "../../../../components/Table";
import TableAuthorFilter from "../../../../components/Table/TableAuthorFilter";
import TableFilterRow from "../../../../components/Table/TableFilterRow";
import TableFilterRowDivider from "../../../../components/Table/TableFilterRow/TableFilterRowDivider";
import TableFlaggedOnlyToggle from "../../../../components/Table/TableFlaggedOnlyToggle";
import TablePublishedAt from '../../../../components/Table/TablePublishedAt';
import TableRowImage from "../../../../components/Table/TableRowImage";
import TableSearch from '../../../../components/TableSearch';
import ThreadTableMoreMenu from "./ThreadTableMoreMenu";

type Props = {
    appliedFilters?: any
    disablePending?: boolean
    onRowClick?: Function
}

const SchoolThreads: React.FC<Props> = ({
    appliedFilters,
    disablePending,
    onRowClick,
}) => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState(false);
    const [selectedListType, setSelectedListType] = useState<ListTypes>(ListTypes.Table);
    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const { isGettingThreads, searchTerm, threads, threadsMetadata } = useTypedSelector((state) => state.threads);
    const { activeSchool } = useTypedSelector((state) => state.schools);

    useEffect(() => {
        const initialize = async () => {
            try {
                let clonedMetadata = clone(threadsMetadata);
                clonedMetadata.forum_topic_privacy_type = null;
                //clonedMetadata = setProfileTypeVisibilityInMetadata(clonedMetadata, params.profileType);
                if(appliedFilters) {
                    clonedMetadata = {
                        ...clonedMetadata,
                        ...appliedFilters,
                    };
                }

                if (params.profileType === ProfileTypes.Student) {
                    clonedMetadata.student_visibility = true;
                }

                if (params.profileType === ProfileTypes.Admit) {
                    clonedMetadata.admit_visibility = true;
                }

                await dispatch(getThreads({schoolId: params?.schoolId, threadsMetadata: clonedMetadata})).unwrap();
            } catch(err) {
                console.log('SchoolThreads initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            setShowNewContentModal(false);
            dispatch(clearThread());
            dispatch(clearThreadsMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: Array<TableColumn> = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: '',
                    id: 'artifact',
                    Cell: ({row: { original }}) => (
                        <TableRowImage
                            artifact={!isArrayNullOrEmpty(original.artifacts) ? original.artifacts[0] : original.artifact}
                            flagged={original.flagged}
                        />
                    ),
                    hasImage: true,
                }, {
                    Header: 'Title',
                    accessor: 'title',
                    sortBy: 'title',
                }, {
                    Header: 'Author',
                    accessor: (d) => `${d.profile.firstName} ${d.profile.lastName}`,
                    sortBy: 'profileFirstName',
                }, {
                    Header: 'Group',
                    accessor: 'forumTopic.name',
                    sortBy: 'forumTopicName',
                }, {
                    Header: 'Date',
                    accessor: (d) => <TablePublishedAt post={d} />,
                    sortBy: 'publish',
                }, {
                    Header: 'Comments',
                    accessor: 'commentCount',
                }, {
                    Header: 'Views',
                    accessor: 'totalViews',
                }
            ];

            if(!onRowClick) {
                columns.push({
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original),
                });
            }

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onRowClick]
    );

    const data = useMemo(
        () => {
            return threads;
        },
        [threads]
    );

    const handleRowClick = ({original}) => {
        if(!onRowClick) {
            return null;
        }

        onRowClick(original);
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(threadsMetadata);

            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getThreads({isUpdate, threadsMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('SchoolThreads onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item) => {
        if(item.removedByMods) {
            return null;
        }

        return (
            <ThreadTableMoreMenu
                getAfterDelete={() => onMetadataChange({}, false)}
                item={item}
                setShowNewContentModal={setShowNewContentModal}
            />
        );
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="school-threads">
            <PageHeader>
                <H5>
                    <Icon type="navigation-discussions" />

                    Discussions
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableAuthorFilter
                    metadata={threadsMetadata}
                    onMetadataChange={onMetadataChange}
                />

                <TableFlaggedOnlyToggle
                    label="Flagged only"
                    onToggleChange={() => onMetadataChange({flagged: !threadsMetadata.flagged, page_num: 0}, false)}
                    value={threadsMetadata.flagged}
                />

                <TableFilterRowDivider />

                <ListTypeSelector
                    onListTypeSelected={(listType) => setSelectedListType(listType)}
                    selected={selectedListType}
                />

                <TableFilterRowDivider />

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search..."
                    searchTerm={searchTerm}
                    size="small"
                />

                {activeSchool.postAsProfile && !onRowClick && (
                    <Button
                        onClick={() => setShowNewContentModal(true)}
                        size={ButtonSizes.Small}
                    >
                        Create Discussion
                    </Button>
                )}
            </TableFilterRow>

            {isGettingThreads ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                                No threads to display. <Button
                                onClick={() => setShowNewContentModal(true)}
                                theme={ButtonThemes.Link}
                            >
                                Create One?
                            </Button>
                        </>
                    ) : (
                        <>
                            {selectedListType === LIST_TYPES.TABLE ? (
                                <Table
                                    columns={columns}
                                    data={data}
                                    disablePending={disablePending}
                                    getData={onMetadataChange}
                                    onRowClick={onRowClick && handleRowClick}
                                    pageNumber={threadsMetadata.page_num}
                                    pageSize={threadsMetadata.page_size}
                                    sortBy={threadsMetadata.sort}
                                    sortOrder={threadsMetadata.order}
                                    totalNumberOfItems={threadsMetadata.total}
                                />
                            ) : (
                                <GridList
                                    createCardButtonOnClick={() => setShowNewContentModal(true)}
                                    createCardButtonText="Start a Discussion"
                                    createCardText="Have a conversation, start a poll, ask students a question or opinion"
                                    disablePending={disablePending}
                                    isLastPage={Math.ceil(threadsMetadata.total / threadsMetadata.page_size) === threadsMetadata.page_num + 1}
                                    items={data}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: threadsMetadata.page_num + 1})}
                                    onOverlayClick={onRowClick ? (item) => onRowClick(item) : null}
                                    onPreviousPageClick={() => onMetadataChange({page_num: threadsMetadata.page_num - 1})}
                                    overlayButtonText={onRowClick ? 'Select' : 'Edit'}
                                    pageNumber={threadsMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                <CreateEditThread
                    close={() => setShowNewContentModal(false)}
                />
            </NewContentModal>
        </div>
    );
};

export default SchoolThreads;
