import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import Activity from "../../types/Activity";
import IError from "../../types/IError";
import Metadata from "../../types/Metadata";
import RecentActivityMetadata from "../../types/RecentActivityMetadata";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";

import { addError } from "./errors";
import { RootState } from '../reducers';

type GetRecentActivityProps = {
    isUpdate?: boolean
    schoolId?: number
    recentActivityMetadata?: Metadata
}

export const getRecentActivity = createAsyncThunk(
    'students/getRecentActivity',
    async ({isUpdate, schoolId, recentActivityMetadata}: GetRecentActivityProps, {dispatch, getState}) => {
        try {
            if(!recentActivityMetadata) {
                recentActivityMetadata = clone((getState() as RootState).recentActivity.recentActivityMetadata);
            } else {
                recentActivityMetadata = {...recentActivityMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            if(isUpdate) {
                recentActivityMetadata.page_num = recentActivityMetadata.page_num + 1;
            } else {
                recentActivityMetadata.page_num = 0;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.students.getRecentActivity(schoolId, buildQueryString(recentActivityMetadata)));
            let recentActivity = res.data.data.items;
            if(isUpdate) {
                recentActivity = (getState() as RootState).recentActivity.recentActivity.concat(recentActivity);
            }

            return {recentActivity, recentActivityMetadata, isAtEnd: true};
        } catch(err) {
            console.log('getRecentActivity Error:', err);
            err.friendlyMessage = 'Error getting the list of recent activity. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

interface ActivityState {
    recentActivity: Array<Activity>
    recentActivityMetadata: RecentActivityMetadata
    getRecentActivityError?: IError
    isGettingRecentActivity: boolean
}

const initialState: ActivityState = {
    recentActivity: [],
    recentActivityMetadata: {
        page_size: 10,
        page_num: 0,
        search: ''
    },
    isGettingRecentActivity: false,
    getRecentActivityError: undefined,
};

export const recentActivitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(getRecentActivity.pending, (state, action) => {
            state.getRecentActivityError = undefined;
            state.isGettingRecentActivity = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.recentActivityMetadata) {
                state.recentActivityMetadata = action.meta.arg.recentActivityMetadata;
            }
        });
        addCase(getRecentActivity.fulfilled, (state, action) => {
            state.recentActivity = action.payload.recentActivity;
            state.recentActivityMetadata = action.payload.recentActivityMetadata;
            state.isGettingRecentActivity = false;
        });
        addCase(getRecentActivity.rejected, (state, action) => {
            state.getRecentActivityError = action.error;
            state.isGettingRecentActivity = false;
        });
    }
});

export default recentActivitySlice.reducer;
