import browserImageSize from "browser-image-size";

import IFile from "../types/IFile";

import { SPONSORSHIP_TYPES } from "../utils/constants";

const useSponsorships = () => {
    const testBannerSize = async (file: IFile, sponsorshipType: SPONSORSHIP_TYPES) => {
        const {height, width} = await browserImageSize(file);

        if(!height || !width) {
            return false;
        }

        switch(sponsorshipType) {
            case SPONSORSHIP_TYPES.CATEGORY:
                return width === 1184 && height === 264;
            case SPONSORSHIP_TYPES.FEATURED:
                return width === 1122 && height === 264;
            case SPONSORSHIP_TYPES.HEADLINE:
                return width === 1242 && height === 168;
            default:
                return false;
        }
    }

    return { testBannerSize };
}

export default useSponsorships;
