import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import clone from 'clone';

import { useTypedSelector } from '../../../../../store/reducers';
import { useAppDispatch } from '../../../../../store';
import {
    approveProfilePromotions,
    clearProfilePromotions,
    clearProfilePromotionsMetadata,
    getPendingStudentsForPromotion,
} from '../../../../../store/slices/profilePromotion';

import TableColumn from '../../../../../types/TableColumn';
import TableFilterMenuOption from '../../../../../types/TableFilterMenuOption';

import { isArrayNullOrEmpty } from '../../../../../utils/utils';
import { LIST_TYPES } from '../../../../../utils/constants';
import { ListTypes, ProfileTypes, PromotionStatuses } from '../../../../../utils/enums';

import Avatar from '../../../../../components/Avatar';
import ActivityIndicator from '../../../../../components/ActivityIndicator';
import Button, { ButtonSizes } from '../../../../../components/Button';
import GridList, { GridListTypes } from '../../../../../components/GridList';
import H5 from '../../../../../components/H5';
import Icon from '../../../../../components/Icon';
import ListTypeSelector from '../../../../../components/ListTypeSelector';
import Modal from '../../../../../components/Modal';
import PageHeader from '../../../../../components/PageHeader';
import Table from '../../../../../components/Table';
import TableFilterRowDivider from '../../../../../components/Table/TableFilterRow/TableFilterRowDivider';
import TableFilterRow from '../../../../../components/Table/TableFilterRow';
import TableFilterMenu from '../../../../../components/Table/TableFilterMenu';
import TableMoreMenu from '../../../../../components/Table/TableMoreMenu';
import TableSearch from '../../../../../components/TableSearch';
import { setSearchTerm } from "../../../../../store/slices/schools";

const ProfilePromotionTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const {
        profilePromotions,
        profilePromotionsMetadata,
        isGettingProfilePromotions,
    } = useTypedSelector((state) => state.profilePromotion);

    const [initialized, setInitialized] = useState(false);
    const [profileIdsToPromote, setProfileIdsToPromote] = useState<number[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedListType, setSelectedListType] = useState<ListTypes>(ListTypes.Table);
    const [showPromoteUsersModal, setShowPromoteUsersModal] = useState(false);
    const [showPromotedUserConfirmationModal, setShowPromotedUserConfirmationModal] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getPendingStudentsForPromotion({schoolId: match?.params?.schoolId})).unwrap();
            } catch (err) {
                console.log('SchoolUsers initialize err', err);
            } finally {
                setInitialized(true);
            }
        };

        initialize();

        return () => {
            dispatch(clearProfilePromotions());
            dispatch(clearProfilePromotionsMetadata());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'User',
                    id: 'profile',
                    Cell: ({row: {original}}) => (
                        <div className="users-table__user">
                            <Avatar
                                artifact={original.profile.artifact}
                            />

                            <div className="users-table__name">
                                {original.profile.firstName} {original.profile.lastName}
                            </div>
                        </div>
                    ),
                }, {
                    Header: 'Current Type',
                    accessor: (d) => renderProfileType(d.currentType),
                }, {
                    Header: 'Requested Type',
                    accessor: (d) => renderProfileType(d.updatedType),
                }, {
                    Header: 'Promotion Status',
                    accessor: (d) => renderPromotionStatus(d.promotionStatus),
                }, {
                    Header: 'Email',
                    accessor: 'currentEmail',
                    // sortBy: 'emailAddress'
                }, {
                    Header: 'Captured Alternate Email',
                    accessor: (d) => d.alternateEmail || '--',
                    // sortBy: 'emailAddress'
                }, {
                    Header: '',
                    id: 'actions',
                    Cell: ({row: {original}}) => renderMoreMenu(original),
                },
            ];
            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const renderProfileType = (profileType: ProfileTypes) => profileType.charAt(0).toUpperCase() + profileType.slice(1);
    const renderPromotionStatus = (promotionStatus: PromotionStatuses) => promotionStatus.split('_').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');

    const data = useMemo(
        () => {
            return profilePromotions;
        },
        [profilePromotions],
    );

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(profilePromotionsMetadata);
            console.log('clonedMetadata', clonedMetadata);

            clonedMetadata = {
                ...clonedMetadata,
                ...changes,
            };

            console.log('clonedMetadata after changes: ', clonedMetadata);
            return await dispatch(getPendingStudentsForPromotion({
                isUpdate,
                profilePromotionMetadata: clonedMetadata,
            })).unwrap();
        } catch (err) {
            console.log('ProfilePromotionTable onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item) => {
        const CUSTOM_TABLE_MORE_MENU_OPTIONS = [
            {
                icon: 'alert',
                onClick: () => {
                    setProfileIdsToPromote([item.profileId]);
                    handlePromoteProfileIds();
                },
                text: 'Promote User',
            },
        ];

        return (
            <TableMoreMenu
                customOptions={CUSTOM_TABLE_MORE_MENU_OPTIONS}
                hideModerationOptions
                item={item}
            />
        );
    };

    const generateTableFilterOptions = (): TableFilterMenuOption[] => {
        return [
            {
                isActive: !profilePromotionsMetadata.status,
                onSelect: () => onMetadataChange({status: undefined}),
                text: 'All',
            }, {
                isActive: profilePromotionsMetadata.status && profilePromotionsMetadata.status === PromotionStatuses.Promoted,
                onSelect: () => onMetadataChange({status: PromotionStatuses.Promoted}),
                text: renderPromotionStatus(PromotionStatuses.Promoted),
            },
            {
                isActive: profilePromotionsMetadata.status && profilePromotionsMetadata.status === PromotionStatuses.PendingAcceptance,
                onSelect: () => onMetadataChange({status: PromotionStatuses.PendingAcceptance}),
                text: renderPromotionStatus(PromotionStatuses.PendingAcceptance),
            },
            {
                isActive: profilePromotionsMetadata.status && profilePromotionsMetadata.status === PromotionStatuses.PendingApproval,
                onSelect: () => onMetadataChange({status: PromotionStatuses.PendingApproval}),
                text: renderPromotionStatus(PromotionStatuses.PendingApproval),
            },
        ];
    };

    const handleClearSearchTerm = useCallback(() => {
        setSearchTerm('');
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    const handlePromoteAll = () => {
        setProfileIdsToPromote(data.map((d) => d.profileId));
        handlePromoteProfileIds();
    };

    const handlePromoteProfileIds = () => {
        try {
            const submit = async () => {
                await dispatch(approveProfilePromotions({profileIds: profileIdsToPromote})).unwrap();
            };

            submit();
        } catch (err) {
            console.log('ProfilePromotionTable handlePromoteProfileIds err', err);
        } finally {
            setShowPromoteUsersModal(false);
            setShowPromotedUserConfirmationModal(true);
        }
    }

    return (
        <div>
            <PageHeader>
                <H5>
                    <Icon type="navigation-users"/>

                    Students Pending Promotion
                </H5>
            </PageHeader>

            <TableFilterRow>
                <ListTypeSelector
                    onListTypeSelected={(listType) => setSelectedListType(listType)}
                    selected={selectedListType}
                />

                <TableFilterRowDivider/>

                <TableFilterMenu options={generateTableFilterOptions()} label={'Promotion Status'}/>

                <TableFilterRowDivider/>

                <TableSearch
                    handleChange={(value) => setSearchTerm(value)}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0, search: searchTerm}, false)}
                    placeholder="Search Pending Promotions"
                    searchTerm={searchTerm}
                    size="small"
                />

                <Button
                    disabled={profilePromotionsMetadata.status === PromotionStatuses.Promoted}
                    onClick={() => {
                        setShowPromoteUsersModal(true);
                    }}
                    size={ButtonSizes.Small}
                >
                    Promote All
                </Button>
            </TableFilterRow>

            {isGettingProfilePromotions ? (
                <ActivityIndicator size="large"/>
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                            No pending promotions to display.
                        </>
                    ) : (
                        <>
                            {selectedListType === LIST_TYPES.TABLE ? (
                                <Table
                                    columns={columns}
                                    data={data}
                                    getData={onMetadataChange}
                                    pageNumber={profilePromotionsMetadata.page_num}
                                    pageSize={profilePromotionsMetadata.page_size}
                                    sortBy={profilePromotionsMetadata.sort}
                                    sortOrder={profilePromotionsMetadata.order}
                                    totalNumberOfItems={profilePromotionsMetadata.total}
                                />
                            ) : (
                                <GridList
                                    // createCardButtonOnClick={() => set(true)}
                                    // createCardButtonText="Create an Admin"
                                    isLastPage={Math.ceil(profilePromotionsMetadata.total / profilePromotionsMetadata.page_size) === profilePromotionsMetadata.page_num + 1}
                                    items={data}
                                    listType={GridListTypes.ProfilePromotion}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: profilePromotionsMetadata.page_num + 1})}
                                    onPreviousPageClick={() => onMetadataChange({page_num: profilePromotionsMetadata.page_num - 1})}
                                    overlayButtonText="Edit"
                                    pageNumber={profilePromotionsMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <Modal
                declineButtonOnClick={() => setShowPromotedUserConfirmationModal(false)}
                declineButtonText="OK"
                show={showPromotedUserConfirmationModal}
                title="Success!"
            >
                We've promoted the selected users. Next time they open the app, they will be be able to enjoy a new app
                experience.
            </Modal>

            <Modal
                declineButtonOnClick={() => setShowPromoteUsersModal(false)}
                declineButtonText="Cancel"
                confirmButtonText={'Promote All'}
                confirmButtonOnClick={handlePromoteAll}
                show={showPromoteUsersModal}
                title="Almost there!"
            >
                Are you sure you want to promote all students pending approval and acceptance on this page?
            </Modal>
        </div>
    );
};

export default ProfilePromotionTable;
